// src/theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#003366', // Blue color for primary actions
    },
    secondary: {
      main: '#f50057', // Hot pink for secondary actions
    },
    default: {
      main: '#e0e0e0',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8, // 버튼 모서리 둥글게
          padding: '8px 16px', // 패딩 조절
          fontSize: '1rem', // 글꼴 크기 조절
        },
      },
    },
  },
});

export default theme;
