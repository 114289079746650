// import React, { useState, useEffect } from 'react';
// import {
//   db,
//   doc,
//   updateDoc,
//   collection,
//   query,
//   where,
//   getDocs,
//   getDoc,
// } from '../../firebaseConfig';
// import { useParams } from 'react-router-dom';
// import OrderDetails from './OrderDetails';
// import TrackShipment from './TrackShipment';
// import {
//   Container,
//   Typography,
//   CircularProgress,
//   Alert,
//   Table,
//   TableBody,
//   TableCell,
//   TableHead,
//   TableRow,
//   Box,
//   Button,
//   Accordion,
//   AccordionSummary,
//   AccordionDetails,
//   Select,
//   MenuItem,
//   FormControl,
//   InputLabel,
// } from '@mui/material';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// function StoreOrders() {
//   const [orders, setOrders] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const { storeId } = useParams();
//   const [store, setStore] = useState(null);
//   const [selectedOrder, setSelectedOrder] = useState(null);
//   const [collapsedOrders, setCollapsedOrders] = useState({});

//   function toggleCollapse(orderId) {
//     setCollapsedOrders((prevState) => ({
//       ...prevState,
//       [orderId]: !prevState[orderId],
//     }));
//   }

//   useEffect(() => {
//     fetchStoreDetails(storeId);
//     fetchOrders();
//   }, [storeId]);

//   const fetchOrders = async () => {
//     setLoading(true);
//     try {
//       const fetchedOrders = await getOrdersByStore(storeId);
//       setOrders(fetchedOrders);
//     } catch (err) {
//       setError(`Failed to fetch orders: ${err.message}`);
//     } finally {
//       setLoading(false);
//     }
//   };
//   const fetchStoreDetails = async (storeId) => {
//     try {
//       const storeRef = doc(db, 'stores', storeId);
//       const storeSnapshot = await getDoc(storeRef);

//       if (storeSnapshot.exists()) {
//         const rawData = storeSnapshot.data();
//         const formattedStore = {
//           id: storeSnapshot.id,
//           ...rawData,
//         };

//         setStore(formattedStore);
//       }
//     } catch (error) {
//       console.error('Error fetching store details:', error);
//       setError(`Failed to fetch store details: ${error.message}`);
//     }
//   };
//   const getOrdersByStore = async (storeId) => {
//     try {
//       const ordersRef = collection(db, 'stores', storeId, 'orders');
//       const q = query(ordersRef);
//       const querySnapshot = await getDocs(q);
//       const orders = querySnapshot.docs.map((doc) => ({
//         id: doc.id,
//         ...doc.data(),
//       }));
//       return orders;
//     } catch (error) {
//       console.error('Error fetching orders:', error);
//       throw error;
//     }
//   };

//   const changeOrderStatus = async (storeId, orderId, newStatus) => {
//     try {
//       const orderRef = doc(db, 'stores', storeId, 'orders', orderId);
//       await updateDoc(orderRef, {
//         orderStatus: newStatus,
//       });
//       setOrders(
//         orders.map((order) =>
//           order.id === orderId ? { ...order, orderStatus: newStatus } : order
//         )
//       );
//     } catch (error) {
//       console.error('Error updating order status:', error);
//       setError(`Failed to change order status: ${error.message}`);
//     }
//   };

//   if (loading) {
//     return (
//       <Container>
//         <CircularProgress />
//       </Container>
//     );
//   }

//   if (error) {
//     return (
//       <Container>
//         <Alert severity="error">{error}</Alert>
//       </Container>
//     );
//   }

//   return (
//     <Container className="orders">
//       <Typography variant="h4" gutterBottom>
//         {store.storeName
//           ? `${store.storeName} 고객 주문 목록`
//           : ' 스토어 주문 목록'}
//       </Typography>
//       {selectedOrder ? (
//         <OrderDetails
//           order={selectedOrder}
//           onBack={() => setSelectedOrder(null)}
//         />
//       ) : orders.length > 0 ? (
//         orders.map((order) => (
//           <Accordion
//             key={order.id}
//             expanded={collapsedOrders[order.id]}
//             onChange={() => toggleCollapse(order.id)}
//           >
//             <AccordionSummary expandIcon={<ExpandMoreIcon />}>
//               <Typography>
//                 주문번호: {order.id} - 주문 날짜: {order.date}
//               </Typography>
//             </AccordionSummary>
//             <AccordionDetails>
//               <Table size="small">
//                 <TableHead>
//                   <TableRow>
//                     <TableCell>주문 고객명</TableCell>
//                     <TableCell>제품 이미지</TableCell>
//                     <TableCell>제품명</TableCell>
//                     <TableCell>배송 상태</TableCell>
//                     <TableCell>수량</TableCell>
//                     <TableCell>원래 가격</TableCell>
//                     <TableCell>쿠폰 금액</TableCell>
//                     <TableCell>할인 금액</TableCell>
//                     <TableCell>최종 금액</TableCell>
//                   </TableRow>
//                 </TableHead>
//                 <TableBody>
//                   {order.items?.length > 0 ? (
//                     order.items.map((item) => (
//                       <TableRow key={item.productId}>
//                         <TableCell>{item.userId}</TableCell>
//                         <TableCell>
//                           <img
//                             src={
//                               item.productDetails?.imageUrl ||
//                               '/path/to/placeholder.jpg'
//                             }
//                             alt={item.productDetails?.name || 'Placeholder'}
//                             style={{ width: 50 }}
//                           />
//                         </TableCell>
//                         <TableCell>{item.productDetails?.name}</TableCell>
//                         <TableCell>
//                           <TrackShipment trackingNumber={order.invoiceNumber} />
//                         </TableCell>
//                         <TableCell>{item.quantity}</TableCell>
//                         <TableCell>{item.price}</TableCell>
//                         <TableCell>{item.couponPrice}</TableCell>
//                         <TableCell>{item.discountedPrice}</TableCell>
//                         <TableCell>{item.finalPrice}</TableCell>
//                       </TableRow>
//                     ))
//                   ) : (
//                     <TableRow>
//                       <TableCell colSpan={7} style={{ textAlign: 'center' }}>
//                         상품 정보가 없습니다.
//                       </TableCell>
//                     </TableRow>
//                   )}
//                 </TableBody>
//               </Table>
//               <Box sx={{ mt: 2 }}>
//                 <Typography variant="body2">
//                   총결제금액: {order.finalAmount} 원
//                 </Typography>
//                 <FormControl size="small" sx={{ m: 1, minWidth: 120 }}>
//                   <InputLabel>주문 상태</InputLabel>
//                   <Select
//                     value={order.orderStatus}
//                     label="주문 상태"
//                     onChange={(e) =>
//                       changeOrderStatus(storeId, order.id, e.target.value)
//                     }
//                   >
//                     <MenuItem value="pending">준비중</MenuItem>
//                     <MenuItem value="shipped">배송중</MenuItem>
//                     <MenuItem value="delivered">배송완료</MenuItem>
//                   </Select>
//                 </FormControl>
//                 <Button
//                   onClick={() => setSelectedOrder(order)}
//                   variant="contained"
//                 >
//                   상세보기
//                 </Button>
//               </Box>
//             </AccordionDetails>
//           </Accordion>
//         ))
//       ) : (
//         <Typography>No orders available.</Typography>
//       )}
//     </Container>
//   );
// }

// export default StoreOrders;

// import React, { useState, useEffect } from 'react';
// import {
//   db,
//   doc,
//   updateDoc,
//   collection,
//   query,
//   where,
//   getDocs,
//   getDoc,
// } from '../../firebaseConfig';
// import { useParams } from 'react-router-dom';
// import OrderDetails from './OrderDetails';
// import TrackShipment from './TrackShipment';
// import {
//   Container,
//   Typography,
//   CircularProgress,
//   Alert,
//   Table,
//   TableBody,
//   TableCell,
//   TableHead,
//   TableRow,
//   Box,
//   Button,
//   Accordion,
//   AccordionSummary,
//   AccordionDetails,
//   Select,
//   MenuItem,
//   FormControl,
//   InputLabel,
//   useMediaQuery,
//   useTheme,
// } from '@mui/material';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// function StoreOrders() {
//   const [orders, setOrders] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const { storeId } = useParams();
//   const [store, setStore] = useState(null);
//   const [selectedOrder, setSelectedOrder] = useState(null);
//   const [collapsedOrders, setCollapsedOrders] = useState({});

//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

//   function toggleCollapse(orderId) {
//     setCollapsedOrders((prevState) => ({
//       ...prevState,
//       [orderId]: !prevState[orderId],
//     }));
//   }

//   useEffect(() => {
//     fetchStoreDetails(storeId);
//     fetchOrders();
//   }, [storeId]);

//   const fetchOrders = async () => {
//     setLoading(true);
//     try {
//       const fetchedOrders = await getOrdersByStore(storeId);
//       setOrders(fetchedOrders);
//     } catch (err) {
//       setError(`Failed to fetch orders: ${err.message}`);
//     } finally {
//       setLoading(false);
//     }
//   };
//   const fetchStoreDetails = async (storeId) => {
//     try {
//       const storeRef = doc(db, 'stores', storeId);
//       const storeSnapshot = await getDoc(storeRef);

//       if (storeSnapshot.exists()) {
//         const rawData = storeSnapshot.data();
//         const formattedStore = {
//           id: storeSnapshot.id,
//           ...rawData,
//         };

//         setStore(formattedStore);
//       }
//     } catch (error) {
//       console.error('Error fetching store details:', error);
//       setError(`Failed to fetch store details: ${error.message}`);
//     }
//   };
//   const getOrdersByStore = async (storeId) => {
//     try {
//       const ordersRef = collection(db, 'stores', storeId, 'orders');
//       const q = query(ordersRef);
//       const querySnapshot = await getDocs(q);
//       const orders = querySnapshot.docs.map((doc) => ({
//         id: doc.id,
//         ...doc.data(),
//       }));
//       return orders;
//     } catch (error) {
//       console.error('Error fetching orders:', error);
//       throw error;
//     }
//   };

//   const changeOrderStatus = async (storeId, orderId, newStatus) => {
//     try {
//       const orderRef = doc(db, 'stores', storeId, 'orders', orderId);
//       await updateDoc(orderRef, {
//         orderStatus: newStatus,
//       });
//       setOrders(
//         orders.map((order) =>
//           order.id === orderId ? { ...order, orderStatus: newStatus } : order
//         )
//       );
//     } catch (error) {
//       console.error('Error updating order status:', error);
//       setError(`Failed to change order status: ${error.message}`);
//     }
//   };

//   if (loading) {
//     return (
//       <Container>
//         <CircularProgress />
//       </Container>
//     );
//   }

//   if (error) {
//     return (
//       <Container>
//         <Alert severity="error">{error}</Alert>
//       </Container>
//     );
//   }

//   return (
//     <Container className="orders">
//       <Typography variant="h4" gutterBottom>
//         {store?.storeName
//           ? `${store.storeName} 고객 주문 목록`
//           : ' 스토어 주문 목록'}
//       </Typography>
//       {selectedOrder ? (
//         <OrderDetails
//           order={selectedOrder}
//           onBack={() => setSelectedOrder(null)}
//         />
//       ) : orders.length > 0 ? (
//         orders.map((order) => (
//           <Accordion
//             key={order.id}
//             expanded={collapsedOrders[order.id]}
//             onChange={() => toggleCollapse(order.id)}
//           >
//             <AccordionSummary expandIcon={<ExpandMoreIcon />}>
//               <Typography variant={isMobile ? 'body1' : 'h6'}>
//                 주문번호: {order.id} - 주문 날짜: {order.date}
//               </Typography>
//             </AccordionSummary>
//             <AccordionDetails>
//               <Table size="small">
//                 <TableHead>
//                   <TableRow>
//                     <TableCell>주문 고객명</TableCell>
//                     <TableCell>제품 이미지</TableCell>
//                     <TableCell>제품명</TableCell>
//                     <TableCell>배송 상태</TableCell>
//                     <TableCell>수량</TableCell>
//                     <TableCell>원래 가격</TableCell>
//                     <TableCell>쿠폰 금액</TableCell>
//                     <TableCell>할인 금액</TableCell>
//                     <TableCell>최종 금액</TableCell>
//                   </TableRow>
//                 </TableHead>
//                 <TableBody>
//                   {order.items?.length > 0 ? (
//                     order.items.map((item) => (
//                       <TableRow key={item.productId}>
//                         <TableCell>{item.userId}</TableCell>
//                         <TableCell>
//                           <img
//                             src={
//                               item.productDetails?.imageUrl ||
//                               '/path/to/placeholder.jpg'
//                             }
//                             alt={item.productDetails?.name || 'Placeholder'}
//                             style={{ width: isMobile ? 30 : 50 }}
//                           />
//                         </TableCell>
//                         <TableCell>{item.productDetails?.name}</TableCell>
//                         <TableCell>
//                           <TrackShipment trackingNumber={order.invoiceNumber} />
//                         </TableCell>
//                         <TableCell>{item.quantity}</TableCell>
//                         <TableCell>{item.price}</TableCell>
//                         <TableCell>{item.couponPrice}</TableCell>
//                         <TableCell>{item.discountedPrice}</TableCell>
//                         <TableCell>{item.finalPrice}</TableCell>
//                       </TableRow>
//                     ))
//                   ) : (
//                     <TableRow>
//                       <TableCell colSpan={7} style={{ textAlign: 'center' }}>
//                         상품 정보가 없습니다.
//                       </TableCell>
//                     </TableRow>
//                   )}
//                 </TableBody>
//               </Table>
//               <Box sx={{ mt: 2 }}>
//                 <Typography variant="body2">
//                   총결제금액: {order.finalAmount} 원
//                 </Typography>
//                 <FormControl size="small" sx={{ m: 1, minWidth: 120 }}>
//                   <InputLabel>주문 상태</InputLabel>
//                   <Select
//                     value={order.orderStatus}
//                     label="주문 상태"
//                     onChange={(e) =>
//                       changeOrderStatus(storeId, order.id, e.target.value)
//                     }
//                   >
//                     <MenuItem value="pending">준비중</MenuItem>
//                     <MenuItem value="shipped">배송중</MenuItem>
//                     <MenuItem value="delivered">배송완료</MenuItem>
//                   </Select>
//                 </FormControl>
//                 <Button
//                   onClick={() => setSelectedOrder(order)}
//                   variant="contained"
//                   size={isMobile ? 'small' : 'medium'}
//                 >
//                   상세보기
//                 </Button>
//               </Box>
//             </AccordionDetails>
//           </Accordion>
//         ))
//       ) : (
//         <Typography>No orders available.</Typography>
//       )}
//     </Container>
//   );
// }

// export default StoreOrders;

import React, { useState, useEffect } from 'react';
import {
  db,
  doc,
  updateDoc,
  collection,
  query,
  getDocs,
  getDoc,
} from '../firebaseConfig';
import { useParams } from 'react-router-dom';
import OrderDetails from './OrderDetails';
import TrackShipment from './StoreAdmin/TrackShipment';
import {
  Container,
  Typography,
  CircularProgress,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';

function StoreOrders() {
  const { t } = useTranslation();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { storeId } = useParams();
  const [store, setStore] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [collapsedOrders, setCollapsedOrders] = useState({});

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  function toggleCollapse(orderId) {
    setCollapsedOrders((prevState) => ({
      ...prevState,
      [orderId]: !prevState[orderId],
    }));
  }

  useEffect(() => {
    fetchStoreDetails(storeId);
    fetchOrders();
  }, [storeId]);

  const fetchOrders = async () => {
    setLoading(true);
    try {
      const fetchedOrders = await getOrdersByStore(storeId);
      setOrders(fetchedOrders);
    } catch (err) {
      setError(`${t('storeOrders.fetchOrdersError')}: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  const fetchStoreDetails = async (storeId) => {
    try {
      const storeRef = doc(db, 'stores', storeId);
      const storeSnapshot = await getDoc(storeRef);

      if (storeSnapshot.exists()) {
        const rawData = storeSnapshot.data();
        const formattedStore = {
          id: storeSnapshot.id,
          ...rawData,
        };

        setStore(formattedStore);
      }
    } catch (error) {
      console.error(t('storeOrders.fetchStoreDetailsError'), error);
      setError(`${t('storeOrders.fetchStoreDetailsError')}: ${error.message}`);
    }
  };

  const getOrdersByStore = async (storeId) => {
    try {
      const ordersRef = collection(db, 'stores', storeId, 'orders');
      const q = query(ordersRef);
      const querySnapshot = await getDocs(q);
      const orders = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      return orders;
    } catch (error) {
      console.error(t('storeOrders.fetchOrdersError'), error);
      throw error;
    }
  };

  const changeOrderStatus = async (storeId, orderId, newStatus) => {
    try {
      const orderRef = doc(db, 'stores', storeId, 'orders', orderId);
      await updateDoc(orderRef, {
        orderStatus: newStatus,
      });
      setOrders(
        orders.map((order) =>
          order.id === orderId ? { ...order, orderStatus: newStatus } : order
        )
      );
    } catch (error) {
      console.error(t('storeOrders.changeOrderStatusError'), error);
      setError(`${t('storeOrders.changeOrderStatusError')}: ${error.message}`);
    }
  };

  if (loading) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  return (
    <Container className="orders">
      <Typography variant="h4" gutterBottom>
        {store?.storeName
          ? `${store.storeName} ${t('storeOrders.customerOrderList')}`
          : t('storeOrders.storeOrderList')}
      </Typography>
      {selectedOrder ? (
        <OrderDetails
          order={selectedOrder}
          onBack={() => setSelectedOrder(null)}
        />
      ) : orders.length > 0 ? (
        orders.map((order) => (
          <Accordion
            key={order.id}
            expanded={collapsedOrders[order.id]}
            onChange={() => toggleCollapse(order.id)}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant={isMobile ? 'body1' : 'h6'}>
                {t('storeOrders.orderNumber')}: {order.id} -{' '}
                {t('storeOrders.orderDate')}: {order.date}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>{t('storeOrders.customerName')}</TableCell>
                    <TableCell>{t('storeOrders.productImage')}</TableCell>
                    <TableCell>{t('storeOrders.productName')}</TableCell>
                    <TableCell>{t('storeOrders.shippingStatus')}</TableCell>
                    <TableCell>{t('storeOrders.quantity')}</TableCell>
                    <TableCell>{t('storeOrders.originalPrice')}</TableCell>
                    <TableCell>{t('storeOrders.couponAmount')}</TableCell>
                    <TableCell>{t('storeOrders.discountAmount')}</TableCell>
                    <TableCell>{t('storeOrders.finalAmount')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {order.items?.length > 0 ? (
                    order.items.map((item) => (
                      <TableRow key={item.productId}>
                        <TableCell>{item.userId}</TableCell>
                        <TableCell>
                          <img
                            src={
                              item.productDetails?.imageUrl ||
                              '/path/to/placeholder.jpg'
                            }
                            alt={item.productDetails?.name || 'Placeholder'}
                            style={{ width: isMobile ? 30 : 50 }}
                          />
                        </TableCell>
                        <TableCell>{item.productDetails?.name}</TableCell>
                        <TableCell>
                          <TrackShipment trackingNumber={order.invoiceNumber} />
                        </TableCell>
                        <TableCell>{item.quantity}</TableCell>
                        <TableCell>{item.price}</TableCell>
                        <TableCell>{item.couponPrice}</TableCell>
                        <TableCell>{item.discountedPrice}</TableCell>
                        <TableCell>{item.finalPrice}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={7} style={{ textAlign: 'center' }}>
                        {t('storeOrders.noProductInfo')}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <Box sx={{ mt: 2 }}>
                <Typography variant="body2">
                  {t('storeOrders.totalPaymentAmount')}: {order.finalAmount} 원
                </Typography>
                <FormControl size="small" sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel>{t('storeOrders.orderStatus')}</InputLabel>
                  <Select
                    value={order.orderStatus}
                    label={t('storeOrders.orderStatus')}
                    onChange={(e) =>
                      changeOrderStatus(storeId, order.id, e.target.value)
                    }
                  >
                    <MenuItem value="pending">
                      {t('storeOrders.pending')}
                    </MenuItem>
                    <MenuItem value="shipped">
                      {t('storeOrders.shipped')}
                    </MenuItem>
                    <MenuItem value="delivered">
                      {t('storeOrders.delivered')}
                    </MenuItem>
                  </Select>
                </FormControl>
                <Button
                  onClick={() => setSelectedOrder(order)}
                  variant="contained"
                  size={isMobile ? 'small' : 'medium'}
                >
                  {t('storeOrders.viewDetails')}
                </Button>
              </Box>
            </AccordionDetails>
          </Accordion>
        ))
      ) : (
        <Typography>{t('storeOrders.noOrdersAvailable')}</Typography>
      )}
    </Container>
  );
}

export default StoreOrders;
