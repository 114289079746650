import React, { useState, useEffect } from 'react';
import { db, functions } from './firebaseConfig';
import { httpsCallable } from 'firebase/functions';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

function SimilarStoreProducts({ storeId, productId }) {
  const [similarStoreProducts, setSimilarStoreProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (storeId && productId) {
      fetchSimilarStoreProducts(productId);
    }
  }, [storeId, productId]);

  const fetchSimilarStoreProducts = async (prodId) => {
    setLoading(true);
    try {
      const getNearestStoreProducts = httpsCallable(
        functions,
        'getNearestStoreProducts'
      );
      const result = await getNearestStoreProducts({
        storeId: storeId,
        productId: prodId,
      });
      setSimilarStoreProducts(result.data);
    } catch (error) {
      console.error('Error fetching similar store products:', error);
      setSimilarStoreProducts([]);
    }
    setLoading(false);
  };

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      alignItems="flex-start" // Changed from 'left' to 'flex-start' which is a valid value
      justifyContent="center" // Correct property name in the latest MUI versions
    >
      {loading ? (
        <Grid item xs={12}>
          <CircularProgress />
        </Grid>
      ) : similarStoreProducts.length > 0 ? ( // Correctly placed the conditional operator outside the JSX block
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" component="h6" gutterBottom>
              비슷한 상품들
            </Typography>
          </Grid>
          {similarStoreProducts.map(({ prodId, similarity }) => (
            <Grid item xs={12} sm={6} md={4} key={prodId}>
              <Paper
                elevation={3}
                style={{ padding: '20px', textAlign: 'center' }}
              >
                <Typography variant="body1">Product ID: {prodId}</Typography>
                <Typography variant="body2">
                  Similarity: {similarity.toFixed(2)}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Typography></Typography>
        </Grid>
      )}
    </Grid>
  );
}

export default SimilarStoreProducts;
