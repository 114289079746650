const RealTimeSearchList = ({ items, activeTab, onSelectRealTimeSearch }) => {
  const startIndex = activeTab === 1 ? 0 : 10;

  return (
    <div
      style={{
        padding: '10px',
        backgroundColor: '#f5f5f5',
        borderRadius: '5px',
      }}
    >
      {items.slice(startIndex, startIndex + 10).map((item, index) => (
        <div
          key={index}
          style={{
            padding: '5px 0',
            borderBottom: '1px solid #ddd',
            display: 'flex',
            justifyContent: 'space-between',
            cursor: 'pointer',
          }}
          onClick={() => onSelectRealTimeSearch(item.keyword)}
        >
          <span>
            {startIndex + index + 1}. {item.keyword}
          </span>
          <span>{item.status}</span>
        </div>
      ))}
    </div>
  );
};

export default RealTimeSearchList;
