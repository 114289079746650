import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  db,
  storage,
  doc,
  getDoc,
  setDoc,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from '../firebaseConfig';
import {
  Container,
  Typography,
  Button,
  Box,
  LinearProgress,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  IconButton,
  Avatar,
  Grid,
  Tooltip,
  Modal,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import {
  Cancel as CancelIcon,
  CloudUpload as CloudUploadIcon,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/system';
import DaumPostcode from 'react-daum-postcode';
import useMessageBox from '../Common/useMessageBox';
import { resizeImage } from '../Utils/ImageHelper';

const MAX_IMAGE_UPLOAD = 5;

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const Input = styled('input')({
  display: 'none',
});

function StoreProfile({ onPrev, onNext, setIsStepCompleted }) {
  const { t, i18n } = useTranslation();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const fileInputRef = useRef(null);
  const [MessageBoxInform, MessageBoxConfirm, MessageBoxRender] =
    useMessageBox();
  const [storeName, setStoreName] = useState('');
  const [description, setDescription] = useState('');
  const [zoneCode, setZoneCode] = useState('');
  const [address, setAddress] = useState('');
  const [extraAddress, setExtraAddress] = useState('');
  const [showDaumPostcode, setShowDaumPostcode] = useState(false);
  const [mainImageFiles, setMainImageFiles] = useState([]);
  const [subImageFiles, setSubImageFiles] = useState([]);
  const [mainProgress, setMainProgress] = useState({});
  const [subProgress, setSubProgress] = useState({});
  const [mainPreviews, setMainPreviews] = useState([]);
  const [subPreviews, setSubPreviews] = useState([]);
  const [progressModalOpen, setProgressModalOpen] = useState(false);
  const [uploadingFiles, setUploadingFiles] = useState([]);

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const userRef = doc(db, 'users', currentUser.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          const data = userDoc.data();
          setStoreName(data.storeName || '');
          setDescription(data.description || '');
          setZoneCode(data.zoneCode || '');
          setAddress(data.address || '');
          setExtraAddress(data.extraAddress || '');
        }
      } catch (error) {
        console.error('Error fetching profile data: ', error);
      }
    };

    if (currentUser) {
      fetchProfileData();
    }
  }, [currentUser]);

  const handleSaveProfile = async () => {
    if (!storeName || !address) {
      alert(t('fillRequiredFields'));
      return;
    }
    try {
      const userProfileData = {
        storeName,
        description,
        zoneCode,
        address,
        extraAddress,
        step: process.env.REACT_APP_STORE_PROFILE_VERIFIED,
        createDate: new Date(),
        updateDate: new Date(),
      };

      setProgressModalOpen(true);
      setUploadingFiles([...mainImageFiles, ...subImageFiles]);

      const mainImageUrls = await uploadImageFiles(
        currentUser.uid,
        800,
        600,
        'main_',
        'main'
      );
      const subImageUrls = await uploadImageFiles(
        currentUser.uid,
        800,
        600,
        'sub_',
        'sub'
      );

      userProfileData.mainImages = mainImageUrls;
      userProfileData.subImages = subImageUrls;

      const userRef = doc(db, 'users', currentUser.uid);
      await setDoc(userRef, userProfileData, { merge: true });

      const storeRef = doc(db, 'stores', currentUser.uid);
      await setDoc(storeRef, userProfileData, { merge: true });

      if (setIsStepCompleted) {
        setIsStepCompleted(true);
      }
      if (onNext) {
        onNext();
      }
    } catch (error) {
      console.error(t('errorSavingProfile'), error);
    } finally {
      setProgressModalOpen(false);
      setUploadingFiles([]);
    }
  };

  const handleAddressComplete = (data) => {
    let fullAddress = data.address;
    const extraAddress = `${data.bname} ${
      data.buildingName ? `, ${data.buildingName}` : ''
    }`;
    setZoneCode(data.zonecode);
    setAddress(fullAddress);
    setExtraAddress(extraAddress);
    setShowDaumPostcode(false);
  };

  const resetFileInput = () => {
    if (fileInputRef.current) fileInputRef.current.value = '';
  };

  const handleImageChange = useCallback(
    async (event, type) => {
      const tmpSelectedFiles = Array.from(event.target.files);

      if (!tmpSelectedFiles.length) {
        resetFileInput();
        return;
      }

      if (
        tmpSelectedFiles.length +
          (type === 'main' ? mainImageFiles.length : subImageFiles.length) >
        MAX_IMAGE_UPLOAD
      ) {
        await MessageBoxInform(
          `최대 ${MAX_IMAGE_UPLOAD}개의 이미지를 선택할 수 있습니다.`
        );
        resetFileInput();
        return;
      }

      let totalSize = 0;
      tmpSelectedFiles.forEach((file) => {
        totalSize += file.size;
        const reader = new FileReader();
        reader.onloadend = () => {
          if (type === 'main') {
            setMainPreviews((prevPreviews) => [...prevPreviews, reader.result]);
            setMainImageFiles((prevImages) => [...prevImages, file]);
          } else {
            setSubPreviews((prevPreviews) => [...prevPreviews, reader.result]);
            setSubImageFiles((prevImages) => [...prevImages, file]);
          }
        };
        reader.onerror = async () => {
          await MessageBoxInform('파일을 읽는 도중 오류가 발생했습니다.');
        };
        reader.readAsDataURL(file);
      });
    },
    [mainImageFiles, subImageFiles]
  );

  const handleRemoveImage = useCallback((indexToRemove, type) => {
    if (type === 'main') {
      setMainImageFiles((prevImages) =>
        prevImages.filter((_, index) => index !== indexToRemove)
      );
      setMainPreviews((prevPreviews) =>
        prevPreviews.filter((_, index) => index !== indexToRemove)
      );
    } else {
      setSubImageFiles((prevImages) =>
        prevImages.filter((_, index) => index !== indexToRemove)
      );
      setSubPreviews((prevPreviews) =>
        prevPreviews.filter((_, index) => index !== indexToRemove)
      );
    }
  }, []);

  const uploadImageFiles = async (storeId, width, height, prefix, type) => {
    let paths = [];

    const files = type === 'main' ? mainImageFiles : subImageFiles;
    for (const file of files) {
      const resizedFile = await resizeImage(file, width, height);
      const filePath = `store/${storeId}/${prefix}${Date.now()}_${file.name}`;
      const uploadUrl = await uploadImageFile(
        storeId,
        resizedFile,
        filePath,
        type
      );
      paths.push(uploadUrl);
    }
    return paths;
  };

  const uploadImageFile = async (storeId, file, filePath, type) => {
    const fileRef = ref(storage, filePath);
    const metadata = { customMetadata: { storeId } };
    const uploadTask = uploadBytesResumable(fileRef, file, metadata);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          if (type === 'main') {
            setMainProgress((prevProgress) => ({
              ...prevProgress,
              [file.name]: { progress, fileSize: snapshot.totalBytes },
            }));
          } else {
            setSubProgress((prevProgress) => ({
              ...prevProgress,
              [file.name]: { progress, fileSize: snapshot.totalBytes },
            }));
          }
        },
        reject,
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then(resolve, reject);
        }
      );
    });
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h6" gutterBottom>
        {t('storeProfile')}
      </Typography>
      <TextField
        fullWidth
        label={t('storeName')}
        value={storeName || ''}
        onChange={(e) => setStoreName(e.target.value)}
        margin="normal"
        variant="outlined"
      />
      <TextField
        fullWidth
        label={t('description')}
        value={description || ''}
        onChange={(e) => setDescription(e.target.value)}
        margin="normal"
        variant="outlined"
        multiline
        rows={4}
      />
      <TextField
        fullWidth
        label={t('zoneCode')}
        value={zoneCode || ''}
        onClick={() => setShowDaumPostcode(true)}
        margin="normal"
        variant="outlined"
        InputProps={{
          readOnly: true,
        }}
      />
      <TextField
        fullWidth
        label={t('address')}
        value={address || ''}
        onClick={() => setShowDaumPostcode(true)}
        margin="normal"
        variant="outlined"
        InputProps={{
          readOnly: true,
        }}
      />
      <TextField
        fullWidth
        label={t('extraAddress')}
        value={extraAddress || ''}
        onChange={(e) => setExtraAddress(e.target.value)}
        margin="normal"
        variant="outlined"
      />
      <Box sx={{ width: '100%', padding: 2 }}>
        <Typography variant="h6">{t('uploadMainImage')}</Typography>
        <Button
          variant="contained"
          component="label"
          startIcon={<CloudUploadIcon />}
        >
          {t('uploadImage')}
          <input
            type="file"
            hidden
            multiple
            onChange={(event) => handleImageChange(event, 'main')}
            ref={fileInputRef}
          />
        </Button>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          {mainPreviews.map((preview, index) => (
            <Grid item xs={6} sm={4} md={3} key={index}>
              <Box position="relative">
                <Avatar
                  src={preview}
                  variant="rounded"
                  sx={{ width: '100%', height: 'auto' }}
                />
                <Tooltip title={t('remove')}>
                  <IconButton
                    size="small"
                    onClick={() => handleRemoveImage(index, 'main')}
                    sx={{ position: 'absolute', top: 0, right: 0 }}
                  >
                    <CancelIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
          ))}
        </Grid>
        <Typography variant="h6" sx={{ mt: 2 }}>
          {t('uploadSubImages')}
        </Typography>
        <Button
          variant="contained"
          component="label"
          startIcon={<CloudUploadIcon />}
        >
          {t('uploadImage')}
          <input
            type="file"
            hidden
            multiple
            onChange={(event) => handleImageChange(event, 'sub')}
            ref={fileInputRef}
          />
        </Button>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          {subPreviews.map((preview, index) => (
            <Grid item xs={6} sm={4} md={3} key={index}>
              <Box position="relative">
                <Avatar
                  src={preview}
                  variant="rounded"
                  sx={{ width: '100%', height: 'auto' }}
                />
                <Tooltip title={t('remove')}>
                  <IconButton
                    size="small"
                    onClick={() => handleRemoveImage(index, 'sub')}
                    sx={{ position: 'absolute', top: 0, right: 0 }}
                  >
                    <CancelIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
        <Button variant="contained" color="primary" onClick={handleSaveProfile}>
          {t('saveProfile')}
        </Button>
      </Box>
      <Dialog
        open={showDaumPostcode}
        onClose={() => setShowDaumPostcode(false)}
      >
        <DialogTitle>{t('addressSearch')}</DialogTitle>
        <DialogContent>
          <DaumPostcode onComplete={handleAddressComplete} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDaumPostcode(false)}>
            {t('close')}
          </Button>
        </DialogActions>
      </Dialog>
      <Modal
        open={progressModalOpen}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            setProgressModalOpen(false);
          }
        }}
        aria-labelledby="progress-modal-title"
        aria-describedby="progress-modal-description"
        BackdropProps={{
          onClick: (event) => event.stopPropagation(),
        }}
      >
        <Box
          sx={{
            ...modalStyle,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <List>
            {uploadingFiles.map((file, index) => (
              <ListItem key={index}>
                <ListItemText
                  primary={file.name}
                  secondary={`${Math.round(
                    mainProgress[file.name]?.progress ||
                      subProgress[file.name]?.progress ||
                      0
                  )}%`}
                />
                <LinearProgress
                  variant="determinate"
                  value={
                    mainProgress[file.name]?.progress ||
                    subProgress[file.name]?.progress ||
                    0
                  }
                  sx={{ width: '100px', marginLeft: '10px' }}
                />
              </ListItem>
            ))}
          </List>
          <Button
            variant="contained"
            onClick={() => setUploadingFiles([])}
            disabled={uploadingFiles.some(
              ({ name }) =>
                (mainProgress[name]?.progress ||
                  subProgress[name]?.progress ||
                  0) < 100
            )}
          >
            {t('close')}
          </Button>
        </Box>
      </Modal>
      {MessageBoxRender()}
    </Container>
  );
}

export default StoreProfile;
