import React, { useState, useEffect } from 'react';
import {
  firebaseApp,
  db,
  auth,
  analytics,
  storage,
  functions,
  collection,
  query,
  where,
  orderBy,
  getDocs,
  doc,
  addDoc,
  onSnapshot,
} from './firebaseConfig';

import {
  Navbar,
  Nav,
  Container,
  ListGroup,
  InputGroup,
  Button,
  FormControl,
} from 'react-bootstrap';
import './css/home.module.css';

function Chat() {
  const [chatRooms, setChatRooms] = useState([]);
  const [chatContent, setChatContent] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    const uid = JSON.parse(localStorage.getItem('user')).uid;

    collection(db, 'chatroom')
      .where('who', 'array-contains', uid)
      .onSnapshot((snapshot) => {
        const chatroomData = [];
        snapshot.forEach((doc) =>
          chatroomData.push({ ...doc.data(), id: doc.id })
        );
        setChatRooms(chatroomData);
      });
  }, []);

  // const handleChatRoomClick = (chatRoomId) => {
  //   setSelectedChat(chatRoomId);

  //   const q = query(collection(doc(db, 'chatroom', chatRoomId), 'messages'), orderBy('date', 'asc'));

  //   const unsubscribe = onSnapshot(q, (snapshot) => {
  //     const messagesData = snapshot.docs.map(doc => doc.data());
  //     setChatContent(messagesData);
  //   });
  // };

  const handleChatRoomClick = (chatRoomId) => {
    setSelectedChat(chatRoomId);

    const q = query(
      collection(doc(db, 'chatroom', chatRoomId), 'messages'),
      orderBy('date', 'asc')
    );

    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        const messagesData = snapshot.docs.map((doc) => doc.data());
        setChatContent(messagesData);
      },
      (err) => {
        if (err.code === 'failed-precondition' || err.code === 'unavailable') {
          console.error('Firestore index error:', err);
          setError((prevErrors) => [
            ...prevErrors,
            '필요한 인덱스가 없습니다. Firestore 콘솔에서 인덱스를 생성하세요.',
          ]);
        } else {
          console.error('Error fetching messages:', err);
          setError((prevErrors) => [
            ...prevErrors,
            `Error fetching messages: ${err.message}`,
          ]);
        }
      }
    );

    return () => unsubscribe(); // cleanup subscription on component unmount
  };

  const handleSend = () => {
    const uid = JSON.parse(localStorage.getItem('user')).uid;

    if (selectedChat && inputValue) {
      const messageData = {
        content: inputValue,
        date: new Date(),
        uid,
      };

      const messagesRef = collection(
        doc(db, 'chatroom', selectedChat),
        'messages'
      );

      addDoc(messagesRef, messageData)
        .then(() => {
          setInputValue('');
        })
        .catch((error) => {
          console.error('Error adding document: ', error);
        });
    }
  };

  return (
    <div>
      <Container className="p-4 detail">
        <div className="row">
          <div className="col-3 p-0">
            <ListGroup className="chat-list">
              {chatRooms.map((chat) => (
                <ListGroup.Item
                  key={chat.id}
                  onClick={() => handleChatRoomClick(chat.id)}
                >
                  <h6>{chat.product}</h6>
                  <h6 className="text-small">{chat.id}</h6>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </div>
          <div className="col-9 p-0">
            <div className="chat-room">
              <ListGroup className="chat-content">
                {chatContent.map((msg, index) => (
                  <ListGroup.Item key={index}>
                    <span className="chat-box">{msg.content}</span>
                  </ListGroup.Item>
                ))}
              </ListGroup>

              <InputGroup className="mt-3">
                <FormControl
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  placeholder="메시지 입력"
                  aria-label="메시지 입력"
                />
                <InputGroup.Append>
                  <Button variant="secondary" onClick={handleSend}>
                    전송
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default Chat;
