// import React, { useState, useEffect, useRef } from 'react';
// import { Navbar, Nav, NavItem } from 'react-bootstrap';
// import { Link, NavLink, useNavigate } from 'react-router-dom';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import '../css/navbar.css';
// import { useTranslation } from 'react-i18next';
// import UserMenu from '../UserMenu';
// import LanguageSwitcher from '../LanguageSwitcher';
// import categoryIcon from '../assets/images/category.png';
// import logoIcon from '../assets/images/logo.png';
// import recycleIcon from '../assets/images/recycle.png';
// import uploadIcon from '../assets/images/upload.png';
// import chatIcon from '../assets/images/chat.png';
// import watchListIcon from '../assets/images/watchlist.png';
// import search_icon from '../assets/images/search_icon.png';
// import mic_icon from '../assets/images/mic.png';
// import myPageIcon from '../assets/images/header-mypage.svg';
// import cartIcon from '../assets/images/header-cart.svg';
// import signinIcon from '../assets/images/signin.png';
// import signupIcon from '../assets/images/signup.png';
// import settingIcon from '../assets/images/settings.png';
// import { useGlobalContext } from '../GlobalContext';
// import { useDispatch, useSelector } from 'react-redux';
// import {
//   setSearchCategory,
//   setSearchCategoryPath,
//   setSearchKeyword,
// } from '../Redux/reducers/searchReducer';
// import Modal from 'react-modal';
// import ModalStyles from '../Common/ModalStyles';
// import UserSignUpFlow from '../SignUp/UserSignUpFlow';
// import StoreSignUpFlow from '../SignUp/StoreSignUpFlow';
// import { setEditStep } from '../Redux/reducers/editUserReducer';
// import SearchBar from './SearchBar';
// import SearchTabBar from './SearchTabBar';
// import AutoCompleteSearch from '../Search/AutoCompleteSearch';
// import useUserActivity from '../useUserActivity';
// import useStoreUserActivity from '../Stores/useStoreUserActivity';
// import useOutsideClick from '../useOutsideClick';
// import ProductCategories from '../Stores/ProductCategories';
// import SearchContainer from './SearchContainer';
// import SearchListContainer from './SearchListContainer';
// import MiniNavBar from './MiniNavBar';
// import {
//   db,
//   auth,
//   collection,
//   query,
//   orderBy,
//   limit,
//   getDocs,
//   where,
//   signOut,
//   onAuthStateChanged,
// } from '../firebaseConfig';
// import { Box, Button } from '@mui/material';
// import useMessageBox from '../Common/useMessageBox';
// import ProductBrandSelector from '../Stores/ProductBrandSelector';

// function NavBar({ children, setActiveMenu }) {
//   const [activeDropdown, setActiveDropdown] = useState(false);
//   const [MessageBoxInform, MessageBoxConfirm, MessageBoxRender] =
//     useMessageBox();
//   const [showPopup, setShowPopup] = useState(false);
//   const searchRef = useRef(); // SearchBar를 위한 ref
//   const popupRef = useRef(); // Popup을 위한 ref
//   const categoryRef = useRef();
//   const blurTimeoutRef = useRef();
//   const [inputValue, setInputValue] = useState(''); // 입력 필드 값
//   const [recentSearches, setRecentSearches] = useState(() => {
//     // localStorage에서 최근 검색어 가져오기
//     const savedSearches = localStorage.getItem('recentSearches');
//     return savedSearches ? JSON.parse(savedSearches) : [];
//   });
//   const [realTimeSearches, setRealTimeSearches] = useState([]); // 예시 데이터
//   const [showNav, setShowNav] = useState(true);
//   const { navbarHeight } = useGlobalContext();
//   const currentUser = useSelector((state) => state.auth.currentUser);
//   const { t, i18n } = useTranslation();
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const [searchTerm, setSearchTerm] = useState(''); // 검색어를 위한 state
//   const [speechQuery, setSpeechQuery] = useState('');
//   const recognitionRef = useRef(null);
//   //드랍다운 메뉴 사라지게 처리
//   const [isNavExpanded, setIsNavExpanded] = useState(false);

//   const [mode, setMode] = useState(1);
//   const [isUserModal, setIsUserModal] = useState(false);
//   const [isStoreModal, setIsStoreModal] = useState(false);

//   const {
//     logViewedProduct,
//     logPurchasedProduct,
//     logSearchedKeyword,
//     logSearchedCategory,
//   } = useUserActivity(currentUser?.uid);
//   const {
//     logViewedStoreProduct,
//     logPurchasedStoreProduct,
//     logSearchedStoreKeyword,
//     logSearchedStoreCategory,
//   } = useStoreUserActivity(currentUser?.uid);

//   const [showCategories, setShowCategories] = useState(false);
//   const [showBrands, setShowBrands] = useState(false);
//   const [categoryButtonPosition, setCategoryButtonPosition] = useState({
//     top: 0,
//     left: 0,
//   });
//   const categoryButtonRef = useRef(null);

//   const handleDropdownPosition = (element) => {
//     const rect = element.getBoundingClientRect();
//     if (rect.bottom > window.innerHeight) {
//       element.style.top = 'auto';
//       element.style.marginRight = '0px'; // 화면을 벗어나는 경우 위로 이동
//     } else {
//       element.style.top = '100%'; // 기본 위치는 아래쪽
//     }
//   };

//   useEffect(() => {
//     const dropdowns = document.querySelectorAll('.dropdown-content');
//     dropdowns.forEach((dropdown) => handleDropdownPosition(dropdown));
//   }, [activeDropdown]);

//   const handleMouseEnter = (event) => {
//     //event.preventDefault();
//     // setShowCategories(true);
//     navigate('/');
//   };

//   const handleCategoryMouseOver = (event) => {
//     if (categoryButtonRef.current) {
//       const rect = categoryButtonRef.current.getBoundingClientRect();
//       setCategoryButtonPosition({
//         top: rect.bottom + window.scrollY,
//         left: rect.left + window.scrollX,
//       });
//     }
//     setShowCategories(true);
//   };
//   const handleCategoryClick = (event) => {
//     if (categoryButtonRef.current) {
//       const rect = categoryButtonRef.current.getBoundingClientRect();
//       setCategoryButtonPosition({
//         top: rect.bottom + window.scrollY,
//         left: rect.left + window.scrollX,
//       });
//     }
//     setShowCategories(true);
//   };

//   const openUserModal = () => {
//     setMode(1);
//     setIsUserModal(true);
//     dispatch(setEditStep(1));
//   };
//   const closeUserModal = () => {
//     setIsUserModal(false);
//   };
//   const openStoreModal = () => {
//     setMode(1);
//     setIsStoreModal(true);
//     dispatch(setEditStep(1));
//   };
//   const closeStoreModal = () => {
//     setIsStoreModal(false);
//   };

//   // const dispatch = useDispatch();
//   // const currentUser = useSelector(state => state.auth.currentUser);

//   // useEffect(() => {
//   //     const unsubscribe = onAuthStateChanged(auth, (user) => {
//   //         if (!user) {
//   //             dispatch(setCurrentUser(null));
//   //             alert("로그아웃되었습니다.");
//   //         } else {
//   //             dispatch(setCurrentUser(user));
//   //         }
//   //     });

//   //     // Cleanup listener on component unmount
//   //     return () => unsubscribe();
//   // }, [dispatch]);

//   // useEffect(() => {
//   //     let lastScrollTop = 0;
//   //     const handleScroll = () => {
//   //         const currentScrollTop = window.scrollY || document.documentElement.scrollTop;
//   //         setShowNav(currentScrollTop < lastScrollTop || currentScrollTop === 0);
//   //         lastScrollTop = Math.max(currentScrollTop, 0);
//   //     };
//   //     window.addEventListener('scroll', handleScroll);
//   //     return () => window.removeEventListener('scroll', handleScroll);
//   // }, []);
//   // 컴포넌트 마운트 시 localStorage에서 최근 검색어 불러오기
//   useEffect(() => {
//     const savedSearches = localStorage.getItem('recentSearches');
//     if (savedSearches) {
//       setRecentSearches(JSON.parse(savedSearches));
//     }
//     setInterval(updateTopSearchRanks, 300000);
//     updateTopSearchRanks();
//   }, []);

//   function updateTopSearchRanks() {
//     getTop20SearchRanks().then((topSearchRanks) => {
//       const realTimeSearches = topSearchRanks.map((rank) => ({
//         currentRank: rank.currentRank,
//         keyword: rank.keyword,
//         status: rank.status,
//       }));

//       realTimeSearches.forEach((search) => {
//         console.log(
//           `${search.currentRank}위: ${search.keyword}, 상태: ${search.status}`
//         );
//       });
//       setRealTimeSearches(realTimeSearches);
//       // Additional processing or logging can be added here
//       console.log('Updated search ranks:', realTimeSearches);
//     });
//   }

//   // useEffect(() => {
//   //     let lastScrollTop = 0;

//   //     const handleScroll = () => {
//   //         const currentScrollTop = window.scrollY || document.documentElement.scrollTop;
//   //         if (window.innerHeight + window.scrollY >= document.body.offsetHeight || currentScrollTop === 0) {
//   //             setShowNav(true);
//   //         } else
//   //         if (currentScrollTop < lastScrollTop) {
//   //             setShowNav(true);
//   //         } else {
//   //             setShowNav(false);
//   //         }

//   //         lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop;
//   //     };

//   //     window.addEventListener('scroll', handleScroll);

//   //     return () => {
//   //         window.removeEventListener('scroll', handleScroll);
//   //     };
//   // }, []);

//   const startListening = () => {
//     if ('SpeechRecognition' in window || 'webkitSpeechRecognition' in window) {
//       const SpeechRecognition =
//         window.SpeechRecognition || window.webkitSpeechRecognition;
//       recognitionRef.current = new SpeechRecognition();
//       recognitionRef.current.continuous = false;
//       recognitionRef.current.interimResults = true;
//       recognitionRef.current.onresult = (event) => {
//         const text = event.results[0][0].transcript;
//         setSpeechQuery(text);
//       };

//       recognitionRef.current.start();
//     } else {
//       console.error('Your browser does not support the Web Speech API');
//     }
//   };

//   const handleUserProfile = async () => {
//     const userId = currentUser?.uid;
//     if (userId) {
//       navigate(`user_profile/${userId}`);
//     } else {
//       console.error('User ID not found');
//     }
//   };

//   const handleGoTerms = async () => {
//     const userId = currentUser?.uid;
//     if (userId) {
//       navigate('/terms_list');
//     } else {
//       console.error('User ID not found');
//     }
//   };
//   const handleGoPrivacy = async () => {
//     const userId = currentUser?.uid;
//     if (userId) {
//       navigate('/privacy_list');
//     } else {
//       console.error('User ID not found');
//     }
//   };
//   const clearAuthState = async () => {
//     await signOut(auth);
//     // Clear local storage or any other storage you are using to save authentication state
//     localStorage.clear();
//     sessionStorage.clear();
//     // Optionally, clear cookies if used
//     document.cookie.split(';').forEach((c) => {
//       document.cookie = c
//         .replace(/^ +/, '')
//         .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
//     });
//     // Introduce a delay to ensure sign out is processed completely
//     return new Promise((resolve) => setTimeout(resolve, 1000));
//   };
//   const handleLogout = async () => {
//     const isConfirmed = await MessageBoxConfirm(
//       t('logout'),
//       t('settings.logout-confirm')
//     );
//     if (isConfirmed) {
//       try {
//         await clearAuthState();
//         navigate('/');
//       } catch (error) {
//         console.error('Error during sign out:', error);
//       }
//     }
//   };
//   const handleSearchCategory = (category) => {
//     dispatch(setSearchCategory(category));
//     //navigate('/category_search_results');
//     logSearchedCategory(category.id);
//     //setShowCategories(false);
//   };
//   const handleSearchCategoryPath = (categoryPath) => {
//     dispatch(setSearchCategoryPath(categoryPath));
//     setShowCategories(false);
//   };
//   const handleSearchKeyword = (searchTerm) => {
//     //event.preventDefault();
//     //const queryVal = event.target.query.value;
//     // dispatch(setSearchKeyword(queryVal));

//     dispatch(setSearchKeyword(searchTerm));

//     navigate('/keyword_search_results');

//     setRecentSearches((prevSearches) => {
//       const updatedSearches = [
//         searchTerm,
//         ...prevSearches.filter((item) => item !== searchTerm),
//       ];
//       const slicedSearches = updatedSearches.slice(0, 20); // 최근 5개의 검색어만 유지
//       localStorage.setItem('recentSearches', JSON.stringify(slicedSearches)); // 업데이트된 검색어 목록을 localStorage에 저장
//       return slicedSearches;
//     });

//     logSearchedKeyword(searchTerm);
//     logSearchedStoreKeyword(currentUser?.uid, searchTerm);
//     //setShowPopup(false);
//   };
//   const handleFocus = () => {
//     setShowPopup(true);
//     if (blurTimeoutRef.current) {
//       clearTimeout(blurTimeoutRef.current);
//     }
//   };

//   const handleBlur = () => {
//     blurTimeoutRef.current = setTimeout(() => {
//       setShowPopup(false);
//     }, 100); // 팝업 내부 클릭을 위한 충분한 지연 시간
//   };

//   const handleSelectSearchKeyword = (searchTerm) => {
//     // SearchBar의 검색 함수 호출
//     setInputValue(searchTerm);
//     handleSearchKeyword(searchTerm);
//     dispatch(setSearchKeyword(searchTerm));
//     blurTimeoutRef.current = setTimeout(() => {
//       setShowPopup(false);
//     }, 100);
//   };

//   const getTop20SearchRanks = async () => {
//     const searchRanksRef = collection(db, 'searchRanks');
//     const q = query(searchRanksRef, orderBy('currentRank'), limit(20));
//     const querySnapshot = await getDocs(q);

//     const topSearchRanks = querySnapshot.docs.map((docSnapshot) => {
//       const data = docSnapshot.data();
//       let status;
//       const rankChange = data.previousRank - data.currentRank;

//       if (rankChange >= 3) {
//         status = '↑↑';
//       } else if (rankChange >= 1) {
//         status = '↑';
//       } else if (rankChange === 0) {
//         status = '-';
//       } else {
//         status = '↓';
//       }

//       return {
//         keyword: data.keyword,
//         currentRank: data.currentRank,
//         previousRank: data.previousRank,
//         status: status,
//       };
//     });

//     return topSearchRanks;
//   };

//   // useOutsideClick(searchRef, () => setShowPopup(false));
//   // useOutsideClick(popupRef, () => setShowPopup(false));
//   useOutsideClick(categoryRef, () => setShowCategories(false));

//   const [isCollapsed, setIsCollapsed] = useState(
//     window.innerWidth < process.env.REACT_APP_NAVBAR_COLLAPSE_LEN
//   );

//   useEffect(() => {
//     const handleResize = () => {
//       setIsCollapsed(
//         window.innerWidth < process.env.REACT_APP_NAVBAR_COLLAPSE_LEN
//       );
//     };

//     window.addEventListener('resize', handleResize);

//     return () => {
//       window.removeEventListener('resize', handleResize);
//     };
//   }, []);

//   const onSelectCategory = async (category) => {
//     // await MessageBoxInform(`Selected category: ${category.id}`);
//     handleSearchCategory(category);
//   };
//   const onSelectedCategoryPath = async (categoryPath) => {
//     // await MessageBoxInform(`Selected category: ${categoryPath}`);
//     handleSearchCategoryPath(categoryPath);
//   };
//   const onSelectBrand = async (brand) => {
//     // handleSearchBrand(brand);
//   };
//   const onSelectedBrandPath = async (brandPath) => {
//     // handleSearchBrandPath(categoryPath);
//   };

//   return (
//     <div class="navbar">
//       {MessageBoxRender()}
//       <MiniNavBar currentUser={currentUser} />

//       {/* <Modal
//         isOpen={isUserModal}
//         onRequestClose={closeUserModal}
//         contentLabel="User SignUp"
//         style={ModalStyles}
//       > */}
//       {isUserModal && (
//         <UserSignUpFlow mode={mode} closeModal={closeUserModal} />
//       )}
//       {/* </Modal> */}

//       {/* <Modal
//         isOpen={isStoreModal}
//         onRequestClose={closeStoreModal}
//         contentLabel="Store SignUp"
//         style={ModalStyles}
//       > */}
//       {isStoreModal && (
//         <StoreSignUpFlow mode={mode} closeModal={closeStoreModal} />
//       )}
//       {/* </Modal> */}

//       {/* <Modal
//                 isOpen={showCategories}
//                 onRequestClose={() => setShowCategories(false)}
//                 style={ModalStyles}
//                 contentLabel="Categories"
//             >
//                 <h2>카테고리</h2>
//                 <button className="close-button" onClick={() => setShowCategories(false)}>x</button>
//                 <div>
//                     <ProductCategories showMode = 'multiLine' onSelectCategory = {onSelectCategory} popupPosition={categoryButtonPosition}/>
//                 </div>
//             </Modal> */}

//       <Navbar
//         className="nav-bar"
//         bg="dark"
//         variant="white"
//         expand="lg"
//         expanded={isNavExpanded}
//         onSelect={() => setIsNavExpanded(false)}
//         onToggle={() => setIsNavExpanded(!isNavExpanded)}
//         style={{
//           top: showNav ? '0' : `-${navbarHeight}`,
//           transition: 'top 0.3s',
//         }}
//       >
//         {/* <Navbar.Brand as={Link} to="/" style={{ marginRight: 'auto' }}>{t('appTitle')}</Navbar.Brand> */}
//         <Navbar.Brand
//           as={Link}
//           to="/"
//           ref={categoryButtonRef}
//           onMouseOver={(event) => {
//             // event.stopPropagation();
//             //handleCategoryMouseOver();
//           }}
//           style={{ marginRight: 'auto' }}
//           onClick={(event) => {
//             // event.stopPropagation();
//             navigate('/');
//           }}
//         >
//           {/* <img
//             src={logoIcon}
//             width="50"
//             height="50"
//             className="d-inline-block  align-top"
//             alt={t('appTitle')}
//           /> */}

//           {t('appTitle')}
//         </Navbar.Brand>

//         {/* <Navbar.Toggle aria-controls="basic-navbar-nav" />
//         {!isCollapsed && (
//           <>
//             <LanguageSwitcher />
//           </>
//         )} */}
//         <Navbar.Collapse id="basic-navbar-nav">
//           <Nav className={isCollapsed ? 'ms-auto' : 'nav-centered'}>
//             {!isCollapsed && (
//               <>
//                 <button
//                   className="category-menu-button"
//                   ref={categoryButtonRef}
//                   onClick={() => handleCategoryClick()}
//                   //onMouseOver={(event) => {
//                   // event.stopPropagation();
//                   //handleCategoryMouseOver();
//                   //</>}}
//                 >
//                   <img
//                     src={categoryIcon}
//                     alt="Categories"
//                     style={{ width: 40, height: 40 }}
//                   />
//                 </button>
//                 {showCategories && (
//                   <div
//                     ref={categoryRef}
//                     style={{
//                       position: 'fixed', // 고정 위치로 설정
//                       top: 0, // 화면의 맨 위로 설정
//                       left: 0, // 화면의 왼쪽으로 설정
//                       height: '100vh', // 세로 전체 크기로 설정
//                       width: '300px', // 원하는 너비로 설정
//                       backgroundColor: 'white', // 배경색 설정
//                       overflowY: 'auto', // 세로 스크롤 가능하도록 설정
//                       boxShadow: '2px 0 5px rgba(0, 0, 0, 0.1)', // 약간의 그림자 추가
//                       zIndex: 1000, // 다른 요소들 위에 표시되도록 설정
//                     }}
//                   >
//                     <ProductCategories
//                       showMode="multiLine"
//                       onSelectedCategoryPath={onSelectedCategoryPath}
//                       onSelectCategory={onSelectCategory}
//                       onClose={() => setShowCategories(false)}
//                     />
//                   </div>
//                 )}
//                 {showBrands && (
//                   <div
//                     ref={categoryRef}
//                     style={{
//                       position: 'fixed', // 고정 위치로 설정
//                       top: 0, // 화면의 맨 위로 설정
//                       left: 0, // 화면의 왼쪽으로 설정
//                       height: '100vh', // 세로 전체 크기로 설정
//                       width: '300px', // 원하는 너비로 설정
//                       backgroundColor: 'white', // 배경색 설정
//                       overflowY: 'auto', // 세로 스크롤 가능하도록 설정
//                       boxShadow: '2px 0 5px rgba(0, 0, 0, 0.1)', // 약간의 그림자 추가
//                       zIndex: 1000, // 다른 요소들 위에 표시되도록 설정
//                     }}
//                   >
//                     <ProductBrandSelector
//                       showMode="multiLine"
//                       onSelectedBrandPath={onSelectedBrandPath}
//                       onSelectBrand={onSelectBrand}
//                       onClose={() => setShowBrands(false)}
//                     />
//                   </div>
//                 )}
//                 <div className="search-bar">
//                   <SearchContainer>
//                     <div ref={searchRef}>
//                       <SearchBar
//                         onFocus={handleFocus}
//                         onBlur={handleBlur}
//                         onSearch={handleSearchKeyword}
//                         inputValue={inputValue}
//                       />
//                     </div>
//                     <AutoCompleteSearch query={inputValue} />
//                     {showPopup && (
//                       <div ref={popupRef}>
//                         {' '}
//                         <SearchListContainer
//                           recentSearches={recentSearches}
//                           realTimeSearches={realTimeSearches}
//                           setRecentSearches={setRecentSearches} // 최근 검색어 업데이트 함수
//                           onSelectSearchKeyword={handleSelectSearchKeyword}
//                         />
//                       </div>
//                     )}
//                   </SearchContainer>
//                 </div>

//                 {/* <NavLink  className="nav-link" onClick={startListening}>
//                                     <img src={mic_icon} alt={t('Start Listening')} />
//                                 </NavLink> */}
//               </>
//             )}
//             <NavLink to="/" className="nav-link">
//               <img src={recycleIcon} alt={t('productList')} />
//             </NavLink>
//             {!currentUser ? (
//               <>
//                 <NavLink to="/login" className="nav-link">
//                   <img src={signinIcon} alt={t('login')} />
//                 </NavLink>

//                 <div
//                   className={`navbar-item dropdown ${
//                     activeDropdown ? 'active-link' : ''
//                   }`}
//                 >
//                   <NavLink
//                     to="#"
//                     className="nav-link"
//                     onClick={() => setActiveDropdown(!activeDropdown)}
//                   >
//                     <img src={signupIcon} alt={t('uploadProduct')} />
//                   </NavLink>
//                   <div className="dropdown-content" style={{ zIndex: 2000 }}>
//                     <NavLink
//                       to="/user_signup"
//                       className="nav-link"
//                       onClick={() => {
//                         openUserModal();
//                         setActiveDropdown(false);
//                       }}
//                     >
//                       {t('userSignUp')}
//                     </NavLink>
//                     <NavLink
//                       to="/store_signup"
//                       className="nav-link"
//                       onClick={() => {
//                         openStoreModal();
//                         setActiveDropdown(false);
//                       }}
//                     >
//                       {t('storeSignUp')}
//                     </NavLink>
//                   </div>
//                 </div>
//               </>
//             ) : (
//               <>
//                 <NavLink to="/upload" className="nav-link">
//                   <img src={uploadIcon} alt={t('uploadProduct')} />
//                 </NavLink>
//                 <NavLink to="/heartlist" className="nav-link">
//                   <img src={watchListIcon} alt={t('watchlist')} />
//                 </NavLink>

//                 <NavLink to="/chatlist" className="nav-link">
//                   <img src={chatIcon} alt={t('chat')} />
//                 </NavLink>
//                 <NavLink
//                   to={`/allcartlist/${currentUser.uid}`}
//                   className="nav-link"
//                 >
//                   <img src={cartIcon} alt={t('cart')} />
//                 </NavLink>
//                 <div className="navbar-item">
//                   <NavLink className="nav-link">
//                     <img src={myPageIcon} alt={t('mypage')} />
//                   </NavLink>
//                   <div className="dropdown-content" style={{ zIndex: 2000 }}>
//                     <NavLink
//                       to={`/mypage/${currentUser.uid}/${currentUser.uid}`}
//                       className="nav-link"
//                     >
//                       {t('mypage')}
//                     </NavLink>
//                     <NavLink to="/storelist" className="nav-link">
//                       {t('storelist')}
//                     </NavLink>
//                     <NavLink
//                       to={`/mystore/${currentUser.uid}`}
//                       className="nav-link"
//                     >
//                       {t('mystore')}
//                     </NavLink>
//                   </div>
//                 </div>
//                 <NavLink to="settings" className="nav-link">
//                   <img src={settingIcon} alt={t('settings.title')} />
//                 </NavLink>
//                 <UserMenu
//                   user={currentUser}
//                   handleUserProfile={handleUserProfile}
//                   handleGoTerms={handleGoTerms}
//                   handleGoPrivacy={handleGoPrivacy}
//                   handleLogout={handleLogout}
//                 />
//               </>
//             )}
//           </Nav>
//         </Navbar.Collapse>
//       </Navbar>
//       <main>{children}</main>
//     </div>
//   );
// }
// export default NavBar;

// import React, { useState, useRef, useEffect } from 'react';
// import {
//   AppBar,
//   Toolbar,
//   IconButton,
//   Menu,
//   MenuItem,
//   Button,
//   Typography,
//   Box,
//   Drawer,
//   List,
//   ListItem,
//   ListItemText,
//   Divider,
// } from '@mui/material';
// import { Link, NavLink, useNavigate } from 'react-router-dom';
// import MenuIcon from '@mui/icons-material/Menu';
// import { useTranslation } from 'react-i18next';
// import UserMenu from '../UserMenu';
// import LanguageSwitcher from '../LanguageSwitcher';
// import categoryIcon from '../assets/images/category.png';
// import logoIcon from '../assets/images/logo.png';
// import recycleIcon from '../assets/images/recycle.png';
// import uploadIcon from '../assets/images/upload.png';
// import chatIcon from '../assets/images/chat.png';
// import watchListIcon from '../assets/images/watchlist.png';
// import search_icon from '../assets/images/search_icon.png';
// import mic_icon from '../assets/images/mic.png';
// import myPageIcon from '../assets/images/header-mypage.svg';
// import cartIcon from '../assets/images/header-cart.svg';
// import signinIcon from '../assets/images/signin.png';
// import signupIcon from '../assets/images/signup.png';
// import settingIcon from '../assets/images/settings.png';
// import { useGlobalContext } from '../GlobalContext';
// import { useDispatch, useSelector } from 'react-redux';
// import {
//   setSearchCategory,
//   setSearchCategoryPath,
//   setSearchKeyword,
// } from '../Redux/reducers/searchReducer';
// import Modal from 'react-modal';
// import ModalStyles from '../Common/ModalStyles';
// import UserSignUpFlow from '../SignUp/UserSignUpFlow';
// import StoreSignUpFlow from '../SignUp/StoreSignUpFlow';
// import { setEditStep } from '../Redux/reducers/editUserReducer';
// import SearchBar from './SearchBar';
// import AutoCompleteSearch from '../Search/AutoCompleteSearch';
// import useUserActivity from '../useUserActivity';
// import useStoreUserActivity from '../Stores/useStoreUserActivity';
// import useOutsideClick from '../useOutsideClick';
// import ProductCategories from '../Stores/ProductCategories';
// import SearchContainer from './SearchContainer';
// import SearchListContainer from './SearchListContainer';
// import MiniNavBar from './MiniNavBar';
// import {
//   db,
//   auth,
//   collection,
//   query,
//   orderBy,
//   limit,
//   getDocs,
//   signOut,
//   onAuthStateChanged,
// } from '../firebaseConfig';
// import useMessageBox from '../Common/useMessageBox';
// import ProductBrandSelector from '../Stores/ProductBrandSelector';

// function NavBar({ children, setActiveMenu }) {
//   const [activeDropdown, setActiveDropdown] = useState(false);
//   const [MessageBoxInform, MessageBoxConfirm, MessageBoxRender] =
//     useMessageBox();
//   const [showPopup, setShowPopup] = useState(false);
//   const searchRef = useRef(); // SearchBar를 위한 ref
//   const popupRef = useRef(); // Popup을 위한 ref
//   const categoryRef = useRef();
//   const blurTimeoutRef = useRef();
//   const [inputValue, setInputValue] = useState(''); // 입력 필드 값
//   const [recentSearches, setRecentSearches] = useState(() => {
//     // localStorage에서 최근 검색어 가져오기
//     const savedSearches = localStorage.getItem('recentSearches');
//     return savedSearches ? JSON.parse(savedSearches) : [];
//   });
//   const [realTimeSearches, setRealTimeSearches] = useState([]); // 예시 데이터
//   const [showNav, setShowNav] = useState(true);
//   const { navbarHeight } = useGlobalContext();
//   const currentUser = useSelector((state) => state.auth.currentUser);
//   const { t, i18n } = useTranslation();
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const [searchTerm, setSearchTerm] = useState(''); // 검색어를 위한 state
//   const [speechQuery, setSpeechQuery] = useState('');
//   const recognitionRef = useRef(null);
//   //드랍다운 메뉴 사라지게 처리
//   const [isNavExpanded, setIsNavExpanded] = useState(false);

//   const [mode, setMode] = useState(1);
//   const [isUserModal, setIsUserModal] = useState(false);
//   const [isStoreModal, setIsStoreModal] = useState(false);

//   const {
//     logViewedProduct,
//     logPurchasedProduct,
//     logSearchedKeyword,
//     logSearchedCategory,
//   } = useUserActivity(currentUser?.uid);
//   const {
//     logViewedStoreProduct,
//     logPurchasedStoreProduct,
//     logSearchedStoreKeyword,
//     logSearchedStoreCategory,
//   } = useStoreUserActivity(currentUser?.uid);

//   const [showCategories, setShowCategories] = useState(false);
//   const [showBrands, setShowBrands] = useState(false);
//   const [categoryButtonPosition, setCategoryButtonPosition] = useState({
//     top: 0,
//     left: 0,
//   });
//   const categoryButtonRef = useRef(null);

//   const handleDropdownPosition = (element) => {
//     const rect = element.getBoundingClientRect();
//     if (rect.bottom > window.innerHeight) {
//       element.style.top = 'auto';
//       element.style.bottom = '100%'; // 화면을 벗어나는 경우 위로 이동
//     } else {
//       element.style.top = '100%'; // 기본 위치는 아래쪽
//       element.style.bottom = 'auto';
//     }
//   };

//   useEffect(() => {
//     const dropdowns = document.querySelectorAll('.dropdown-content');
//     dropdowns.forEach((dropdown) => handleDropdownPosition(dropdown));
//   }, [activeDropdown]);

//   const handleMouseEnter = (event) => {
//     //event.preventDefault();
//     // setShowCategories(true);
//     navigate('/');
//   };

//   const handleCategoryMouseOver = (event) => {
//     if (categoryButtonRef.current) {
//       const rect = categoryButtonRef.current.getBoundingClientRect();
//       setCategoryButtonPosition({
//         top: rect.bottom + window.scrollY,
//         left: rect.left + window.scrollX,
//       });
//     }
//     setShowCategories(true);
//   };
//   const handleCategoryClick = (event) => {
//     if (categoryButtonRef.current) {
//       const rect = categoryButtonRef.current.getBoundingClientRect();
//       setCategoryButtonPosition({
//         top: rect.bottom + window.scrollY,
//         left: rect.left + window.scrollX,
//       });
//     }
//     setShowCategories(true);
//   };

//   const openUserModal = () => {
//     setMode(1);
//     setIsUserModal(true);
//     dispatch(setEditStep(1));
//   };
//   const closeUserModal = () => {
//     setIsUserModal(false);
//   };
//   const openStoreModal = () => {
//     setMode(1);
//     setIsStoreModal(true);
//     dispatch(setEditStep(1));
//   };
//   const closeStoreModal = () => {
//     setIsStoreModal(false);
//   };

//   useEffect(() => {
//     const savedSearches = localStorage.getItem('recentSearches');
//     if (savedSearches) {
//       setRecentSearches(JSON.parse(savedSearches));
//     }
//     setInterval(updateTopSearchRanks, 300000);
//     updateTopSearchRanks();
//   }, []);

//   function updateTopSearchRanks() {
//     getTop20SearchRanks().then((topSearchRanks) => {
//       const realTimeSearches = topSearchRanks.map((rank) => ({
//         currentRank: rank.currentRank,
//         keyword: rank.keyword,
//         status: rank.status,
//       }));

//       realTimeSearches.forEach((search) => {
//         console.log(
//           `${search.currentRank}위: ${search.keyword}, 상태: ${search.status}`
//         );
//       });
//       setRealTimeSearches(realTimeSearches);
//       console.log('Updated search ranks:', realTimeSearches);
//     });
//   }

//   const startListening = () => {
//     if ('SpeechRecognition' in window || 'webkitSpeechRecognition' in window) {
//       const SpeechRecognition =
//         window.SpeechRecognition || window.webkitSpeechRecognition;
//       recognitionRef.current = new SpeechRecognition();
//       recognitionRef.current.continuous = false;
//       recognitionRef.current.interimResults = true;
//       recognitionRef.current.onresult = (event) => {
//         const text = event.results[0][0].transcript;
//         setSpeechQuery(text);
//       };

//       recognitionRef.current.start();
//     } else {
//       console.error('Your browser does not support the Web Speech API');
//     }
//   };

//   const handleUserProfile = async () => {
//     const userId = currentUser?.uid;
//     if (userId) {
//       navigate(`user_profile/${userId}`);
//     } else {
//       console.error('User ID not found');
//     }
//   };

//   const handleGoTerms = async () => {
//     const userId = currentUser?.uid;
//     if (userId) {
//       navigate('/terms_list');
//     } else {
//       console.error('User ID not found');
//     }
//   };
//   const handleGoPrivacy = async () => {
//     const userId = currentUser?.uid;
//     if (userId) {
//       navigate('/privacy_list');
//     } else {
//       console.error('User ID not found');
//     }
//   };
//   const clearAuthState = async () => {
//     await signOut(auth);
//     localStorage.clear();
//     sessionStorage.clear();
//     document.cookie.split(';').forEach((c) => {
//       document.cookie = c
//         .replace(/^ +/, '')
//         .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
//     });
//     return new Promise((resolve) => setTimeout(resolve, 1000));
//   };
//   const handleLogout = async () => {
//     const isConfirmed = await MessageBoxConfirm(
//       t('logout'),
//       t('settings.logout-confirm')
//     );
//     if (isConfirmed) {
//       try {
//         await clearAuthState();
//         navigate('/');
//       } catch (error) {
//         console.error('Error during sign out:', error);
//       }
//     }
//   };
//   const handleSearchCategory = (category) => {
//     dispatch(setSearchCategory(category));
//     logSearchedCategory(category.id);
//   };
//   const handleSearchCategoryPath = (categoryPath) => {
//     dispatch(setSearchCategoryPath(categoryPath));
//     setShowCategories(false);
//   };
//   const handleSearchKeyword = (searchTerm) => {
//     dispatch(setSearchKeyword(searchTerm));

//     navigate('/keyword_search_results');

//     setRecentSearches((prevSearches) => {
//       const updatedSearches = [
//         searchTerm,
//         ...prevSearches.filter((item) => item !== searchTerm),
//       ];
//       const slicedSearches = updatedSearches.slice(0, 20);
//       localStorage.setItem('recentSearches', JSON.stringify(slicedSearches));
//       return slicedSearches;
//     });

//     logSearchedKeyword(searchTerm);
//     logSearchedStoreKeyword(currentUser?.uid, searchTerm);
//   };
//   const handleFocus = () => {
//     setShowPopup(true);
//     if (blurTimeoutRef.current) {
//       clearTimeout(blurTimeoutRef.current);
//     }
//   };

//   const handleBlur = () => {
//     blurTimeoutRef.current = setTimeout(() => {
//       setShowPopup(false);
//     }, 100);
//   };

//   const handleSelectSearchKeyword = (searchTerm) => {
//     setInputValue(searchTerm);
//     handleSearchKeyword(searchTerm);
//     dispatch(setSearchKeyword(searchTerm));
//     blurTimeoutRef.current = setTimeout(() => {
//       setShowPopup(false);
//     }, 100);
//   };

//   const getTop20SearchRanks = async () => {
//     const searchRanksRef = collection(db, 'searchRanks');
//     const q = query(searchRanksRef, orderBy('currentRank'), limit(20));
//     const querySnapshot = await getDocs(q);

//     const topSearchRanks = querySnapshot.docs.map((docSnapshot) => {
//       const data = docSnapshot.data();
//       let status;
//       const rankChange = data.previousRank - data.currentRank;

//       if (rankChange >= 3) {
//         status = '↑↑';
//       } else if (rankChange >= 1) {
//         status = '↑';
//       } else if (rankChange === 0) {
//         status = '-';
//       } else {
//         status = '↓';
//       }

//       return {
//         keyword: data.keyword,
//         currentRank: data.currentRank,
//         previousRank: data.previousRank,
//         status: status,
//       };
//     });

//     return topSearchRanks;
//   };

//   useOutsideClick(categoryRef, () => setShowCategories(false));

//   const [isCollapsed, setIsCollapsed] = useState(
//     window.innerWidth < process.env.REACT_APP_NAVBAR_COLLAPSE_LEN
//   );

//   useEffect(() => {
//     const handleResize = () => {
//       setIsCollapsed(
//         window.innerWidth < process.env.REACT_APP_NAVBAR_COLLAPSE_LEN
//       );
//     };

//     window.addEventListener('resize', handleResize);

//     return () => {
//       window.removeEventListener('resize', handleResize);
//     };
//   }, []);

//   const onSelectCategory = async (category) => {
//     handleSearchCategory(category);
//   };
//   const onSelectedCategoryPath = async (categoryPath) => {
//     handleSearchCategoryPath(categoryPath);
//   };
//   const onSelectBrand = async (brand) => {};
//   const onSelectedBrandPath = async (brandPath) => {};

//   const [drawerOpen, setDrawerOpen] = useState(false);
//   const toggleDrawer = (open) => (event) => {
//     if (
//       event.type === 'keydown' &&
//       (event.key === 'Tab' || event.key === 'Shift')
//     ) {
//       return;
//     }
//     setDrawerOpen(open);
//   };

//   const drawerList = (
//     <Box
//       sx={{ width: 250 }}
//       role="presentation"
//       onClick={toggleDrawer(false)}
//       onKeyDown={toggleDrawer(false)}
//     >
//       <List>
//         <ListItem button component={NavLink} to="/">
//           <ListItemText primary={t('appTitle')} />
//         </ListItem>
//         <ListItem button component={NavLink} to="/login">
//           <ListItemText primary={t('login')} />
//         </ListItem>
//         <ListItem button component={NavLink} to="/user_signup">
//           <ListItemText primary={t('userSignUp')} />
//         </ListItem>
//         <ListItem button component={NavLink} to="/store_signup">
//           <ListItemText primary={t('storeSignUp')} />
//         </ListItem>
//         {currentUser && (
//           <>
//             <ListItem button component={NavLink} to="/upload">
//               <ListItemText primary={t('uploadProduct')} />
//             </ListItem>
//             <ListItem button component={NavLink} to="/heartlist">
//               <ListItemText primary={t('watchlist')} />
//             </ListItem>
//             <ListItem button component={NavLink} to="/chatlist">
//               <ListItemText primary={t('chat')} />
//             </ListItem>
//             <ListItem
//               button
//               component={NavLink}
//               to={`/allcartlist/${currentUser.uid}`}
//             >
//               <ListItemText primary={t('cart')} />
//             </ListItem>
//             <ListItem
//               button
//               component={NavLink}
//               to={`/mypage/${currentUser.uid}/${currentUser.uid}`}
//             >
//               <ListItemText primary={t('mypage')} />
//             </ListItem>
//             <ListItem button component={NavLink} to="/storelist">
//               <ListItemText primary={t('storelist')} />
//             </ListItem>
//             <ListItem
//               button
//               component={NavLink}
//               to={`/mystore/${currentUser.uid}`}
//             >
//               <ListItemText primary={t('mystore')} />
//             </ListItem>
//             <ListItem button component={NavLink} to="settings">
//               <ListItemText primary={t('settings.title')} />
//             </ListItem>
//             <ListItem button onClick={handleLogout}>
//               <ListItemText primary={t('logout')} />
//             </ListItem>
//           </>
//         )}
//       </List>
//     </Box>
//   );

//   return (
//     <div className="navbar">
//       {MessageBoxRender()}
//       <MiniNavBar currentUser={currentUser} />

//       {isUserModal && (
//         <UserSignUpFlow mode={mode} closeModal={closeUserModal} />
//       )}
//       {isStoreModal && (
//         <StoreSignUpFlow mode={mode} closeModal={closeStoreModal} />
//       )}

//       <AppBar position="static" color="default">
//         <Toolbar>
//           <IconButton
//             edge="start"
//             color="inherit"
//             aria-label="menu"
//             onClick={toggleDrawer(true)}
//           >
//             <MenuIcon />
//           </IconButton>
//           <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
//             {drawerList}
//           </Drawer>
//           <Typography
//             variant="h6"
//             style={{ flexGrow: 1 }}
//             component={NavLink}
//             to="/"
//             className="nav-link"
//           >
//             {t('appTitle')}
//           </Typography>
//           <Box
//             sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}
//           >
//             <Button component={NavLink} to="/" className="nav-link">
//               <img src={recycleIcon} alt={t('productList')} />
//             </Button>
//             {!currentUser ? (
//               <>
//                 <Button component={NavLink} to="/login" className="nav-link">
//                   <img src={signinIcon} alt={t('login')} />
//                 </Button>
//                 <div
//                   className={`navbar-item dropdown ${
//                     activeDropdown ? 'active-link' : ''
//                   }`}
//                 >
//                   <Button
//                     className="nav-link"
//                     onClick={() => setActiveDropdown(!activeDropdown)}
//                   >
//                     <img src={signupIcon} alt={t('uploadProduct')} />
//                   </Button>
//                   <div className="dropdown-content" style={{ zIndex: 2000 }}>
//                     <Button
//                       component={NavLink}
//                       to="/user_signup"
//                       className="nav-link"
//                       onClick={() => openUserModal()}
//                     >
//                       {t('userSignUp')}
//                     </Button>
//                     <Button
//                       component={NavLink}
//                       to="/store_signup"
//                       className="nav-link"
//                       onClick={() => openStoreModal()}
//                     >
//                       {t('storeSignUp')}
//                     </Button>
//                   </div>
//                 </div>
//               </>
//             ) : (
//               <>
//                 <Button component={NavLink} to="/upload" className="nav-link">
//                   <img src={uploadIcon} alt={t('uploadProduct')} />
//                 </Button>
//                 <Button
//                   component={NavLink}
//                   to="/heartlist"
//                   className="nav-link"
//                 >
//                   <img src={watchListIcon} alt={t('watchlist')} />
//                 </Button>
//                 <Button component={NavLink} to="/chatlist" className="nav-link">
//                   <img src={chatIcon} alt={t('chat')} />
//                 </Button>
//                 <Button
//                   component={NavLink}
//                   to={`/allcartlist/${currentUser.uid}`}
//                   className="nav-link"
//                 >
//                   <img src={cartIcon} alt={t('cart')} />
//                 </Button>
//                 <div className="navbar-item">
//                   <Button className="nav-link">
//                     <img src={myPageIcon} alt={t('mypage')} />
//                   </Button>
//                   <div className="dropdown-content" style={{ zIndex: 2000 }}>
//                     <Button
//                       component={NavLink}
//                       to={`/mypage/${currentUser.uid}/${currentUser.uid}`}
//                       className="nav-link"
//                     >
//                       {t('mypage')}
//                     </Button>
//                     <Button
//                       component={NavLink}
//                       to="/storelist"
//                       className="nav-link"
//                     >
//                       {t('storelist')}
//                     </Button>
//                     <Button
//                       component={NavLink}
//                       to={`/mystore/${currentUser.uid}`}
//                       className="nav-link"
//                     >
//                       {t('mystore')}
//                     </Button>
//                   </div>
//                 </div>
//                 <Button component={NavLink} to="settings" className="nav-link">
//                   <img src={settingIcon} alt={t('settings.title')} />
//                 </Button>
//                 <UserMenu
//                   user={currentUser}
//                   handleUserProfile={handleUserProfile}
//                   handleGoTerms={handleGoTerms}
//                   handleGoPrivacy={handleGoPrivacy}
//                   handleLogout={handleLogout}
//                 />
//               </>
//             )}
//           </Box>
//         </Toolbar>
//       </AppBar>
//       <main>{children}</main>
//     </div>
//   );
// }

// export default NavBar;

// import React, { useState, useEffect, useRef } from 'react';
// import {
//   AppBar,
//   Toolbar,
//   IconButton,
//   Button,
//   Menu,
//   MenuItem,
//   Typography,
//   Box,
//   InputBase,
//   Divider,
//   Avatar,
//   TextField,
// } from '@mui/material';
// import { alpha, styled } from '@mui/material/styles';
// import MenuIcon from '@mui/icons-material/Menu';
// import SearchIcon from '@mui/icons-material/Search';
// import AccountCircle from '@mui/icons-material/AccountCircle';
// import { Link, NavLink, useNavigate } from 'react-router-dom';
// import { useDispatch, useSelector } from 'react-redux';
// import { useTranslation } from 'react-i18next';
// import {
//   setSearchCategory,
//   setSearchCategoryPath,
//   setSearchKeyword,
// } from '../Redux/reducers/searchReducer';
// import useOutsideClick from '../useOutsideClick';
// import SearchListContainer from './SearchListContainer';
// import AutoCompleteSearch from '../Search/AutoCompleteSearch';
// import UserMenu from '../UserMenu';
// import MiniNavBar from './MiniNavBar';
// import categoryIcon from '../assets/images/category.png';
// import recycleIcon from '../assets/images/recycle.png';
// import uploadIcon from '../assets/images/upload.png';
// import chatIcon from '../assets/images/chat.png';
// import watchListIcon from '../assets/images/watchlist.png';
// import micIcon from '../assets/images/mic.png';
// import myPageIcon from '../assets/images/header-mypage.svg';
// import cartIcon from '../assets/images/header-cart.svg';
// import signinIcon from '../assets/images/signin.png';
// import signupIcon from '../assets/images/signup.png';
// import settingIcon from '../assets/images/settings.png';
// import '../css/navbar.css';
// import UserSignUpFlow from '../SignUp/UserSignUpFlow';
// import StoreSignUpFlow from '../SignUp/StoreSignUpFlow';
// import { setEditStep } from '../Redux/reducers/editUserReducer';
// import {
//   db,
//   signOut,
//   auth,
//   collection,
//   query,
//   orderBy,
//   limit,
//   getDocs,
//   where,
// } from '../firebaseConfig';
// import useMessageBox from '../Common/useMessageBox';
// import ProductCategories from '../Stores/ProductCategories';
// import ProductBrandSelector from '../Stores/ProductBrandSelector';
// import useUserActivity from '../useUserActivity';
// import useStoreUserActivity from '../Stores/useStoreUserActivity';

// // const Search = styled('div')(({ theme }) => ({
// //   position: 'relative',
// //   borderRadius: '22px', // 라운드 처리
// //   backgroundColor: 'transparent', // 내부 컬러 제거
// //   border: `1px solid ${alpha(theme.palette.common.black, 0.5)}`, // 테두리 스타일 추가
// //   '&:hover': {
// //     border: `1px solid ${alpha(theme.palette.common.black, 0.75)}`, // 호버 시 테두리 스타일
// //   },
// //   marginRight: theme.spacing(2),
// //   marginLeft: 0,
// //   width: '100%',
// //   [theme.breakpoints.up('sm')]: {
// //     marginLeft: theme.spacing(3),
// //     width: '40%',
// //   },
// // }));

// // const SearchIconWrapper = styled('div')(({ theme }) => ({
// //   padding: theme.spacing(0, 1),
// //   height: '100%',
// //   display: 'flex',
// //   alignItems: 'center',
// //   justifyContent: 'center',
// // }));

// // const StyledInputBase = styled(InputBase)(({ theme }) => ({
// //   color: 'inherit',
// //   '& .MuiInputBase-input': {
// //     padding: theme.spacing(1, 1, 1, 0),
// //     paddingLeft: `calc(1em + ${theme.spacing(4)})`,
// //     transition: theme.transitions.create('width'),
// //     width: '100%',
// //     [theme.breakpoints.up('md')]: {
// //       width: '100%',
// //     },
// //   },
// // }));

// const Search = styled('div')(({ theme }) => ({
//   position: 'relative',
//   borderRadius: '22px', // 라운드 처리
//   backgroundColor: 'transparent', // 내부 컬러 제거
//   border: `1px solid ${alpha(theme.palette.common.black, 0.5)}`, // 테두리 스타일 추가
//   '&:hover': {
//     border: `1px solid ${alpha(theme.palette.common.black, 0.75)}`, // 호버 시 테두리 스타일
//   },
//   marginRight: theme.spacing(2),
//   marginLeft: 0,
//   width: '100%',
//   display: 'flex',
//   alignItems: 'center',
//   [theme.breakpoints.up('sm')]: {
//     marginLeft: theme.spacing(3),
//     width: '40%',
//   },
// }));

// const SearchIconWrapper = styled('div')(({ theme }) => ({
//   padding: theme.spacing(0, 1),
//   height: '100%',
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',
// }));

// const StyledInputBase = styled(InputBase)(({ theme }) => ({
//   color: 'inherit',
//   flexGrow: 1,
//   '& .MuiInputBase-input': {
//     padding: theme.spacing(1),
//     paddingLeft: `calc(1em + ${theme.spacing(2)})`,
//     transition: theme.transitions.create('width'),
//     width: '100%',
//     [theme.breakpoints.up('md')]: {
//       width: '100%',
//     },
//   },
// }));

// function NavBar({ children }) {
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [showPopup, setShowPopup] = useState(false);
//   const [inputValue, setInputValue] = useState('');
//   const [activeDropdown, setActiveDropdown] = useState(false);
//   const [isUserModal, setIsUserModal] = useState(false);
//   const [isStoreModal, setIsStoreModal] = useState(false);
//   const [recentSearches, setRecentSearches] = useState([]);
//   const [realTimeSearches, setRealTimeSearches] = useState([]);
//   const [mode, setMode] = useState(1);
//   const [MessageBoxInform, MessageBoxConfirm, MessageBoxRender] =
//     useMessageBox();
//   const [showCategories, setShowCategories] = useState(false);
//   const [showBrands, setShowBrands] = useState(false);
//   const [categoryButtonPosition, setCategoryButtonPosition] = useState({
//     top: 0,
//     left: 0,
//   });
//   const [activeTab, setActiveTab] = useState(1);
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const currentUser = useSelector((state) => state.auth.currentUser);
//   const { t } = useTranslation();
//   const searchRef = useRef();
//   const popupRef = useRef();
//   const blurTimeoutRef = useRef();
//   const categoryButtonRef = useRef(null);
//   const categoryRef = useRef();

//   const {
//     logViewedProduct,
//     logPurchasedProduct,
//     logSearchedKeyword,
//     logSearchedCategory,
//   } = useUserActivity(currentUser?.uid);

//   const {
//     logViewedStoreProduct,
//     logPurchasedStoreProduct,
//     logSearchedStoreKeyword,
//     logSearchedStoreCategory,
//   } = useStoreUserActivity(currentUser?.uid);

//   const handleMenu = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleClose = () => {
//     setAnchorEl(null);
//   };

//   const handleFocus = () => {
//     setShowPopup(true);
//     if (blurTimeoutRef.current) {
//       clearTimeout(blurTimeoutRef.current);
//     }
//   };

//   const handleBlur = () => {
//     blurTimeoutRef.current = setTimeout(() => {
//       // setShowPopup(false);
//     }, 100);
//   };
//   // 팝업 외부 클릭 시 팝업 닫기
//   useOutsideClick(popupRef, () => {
//     setShowPopup(false);
//   });

//   // 검색바 내부 클릭 시 팝업 유지
//   useOutsideClick(searchRef, () => {
//     // setShowPopup(true);
//   });
//   const handleSearchKeyword = (searchTerm) => {
//     dispatch(setSearchKeyword(searchTerm));
//     navigate('/keyword_search_results');
//     setRecentSearches((prevSearches) => {
//       const updatedSearches = [
//         searchTerm,
//         ...prevSearches.filter((item) => item !== searchTerm),
//       ];
//       const slicedSearches = updatedSearches.slice(0, 20);
//       localStorage.setItem('recentSearches', JSON.stringify(slicedSearches));
//       return slicedSearches;
//     });

//     logSearchedKeyword(searchTerm);
//     logSearchedStoreKeyword(currentUser?.uid, searchTerm);
//   };

//   const openUserModal = () => {
//     setIsUserModal(true);
//     dispatch(setEditStep(1));
//   };

//   const openStoreModal = () => {
//     setIsStoreModal(true);
//     dispatch(setEditStep(1));
//   };

//   const handleUserProfile = async () => {
//     const userId = currentUser?.uid;
//     if (userId) {
//       navigate(`user_profile/${userId}`);
//     } else {
//       console.error('User ID not found');
//     }
//   };

//   const handleGoTerms = async () => {
//     const userId = currentUser?.uid;
//     if (userId) {
//       navigate('/terms_list');
//     } else {
//       console.error('User ID not found');
//     }
//   };

//   const handleGoPrivacy = async () => {
//     const userId = currentUser?.uid;
//     if (userId) {
//       navigate('/privacy_list');
//     } else {
//       console.error('User ID not found');
//     }
//   };

//   const clearAuthState = async () => {
//     await signOut(auth);
//     localStorage.clear();
//     sessionStorage.clear();
//     document.cookie.split(';').forEach((c) => {
//       document.cookie = c
//         .replace(/^ +/, '')
//         .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
//     });
//     return new Promise((resolve) => setTimeout(resolve, 1000));
//   };

//   const handleLogout = async () => {
//     const isConfirmed = await MessageBoxConfirm(
//       t('logout'),
//       t('settings.logout-confirm')
//     );
//     if (isConfirmed) {
//       try {
//         await clearAuthState();
//         navigate('/');
//       } catch (error) {
//         console.error('Error during sign out:', error);
//       }
//     }
//   };

//   const getTop20SearchRanks = async () => {
//     const searchRanksRef = collection(db, 'searchRanks');
//     const q = query(searchRanksRef, orderBy('currentRank'), limit(20));
//     const querySnapshot = await getDocs(q);

//     const topSearchRanks = querySnapshot.docs.map((docSnapshot) => {
//       const data = docSnapshot.data();
//       let status;
//       const rankChange = data.previousRank - data.currentRank;

//       if (rankChange >= 3) {
//         status = '↑↑';
//       } else if (rankChange >= 1) {
//         status = '↑';
//       } else if (rankChange === 0) {
//         status = '-';
//       } else {
//         status = '↓';
//       }

//       return {
//         keyword: data.keyword,
//         currentRank: data.currentRank,
//         previousRank: data.previousRank,
//         status: status,
//       };
//     });

//     return topSearchRanks;
//   };

//   const handleDropdownPosition = (element) => {
//     const rect = element.getBoundingClientRect();
//     if (rect.bottom > window.innerHeight) {
//       element.style.top = 'auto';
//       element.style.bottom = '100%';
//     } else {
//       element.style.top = '100%';
//       element.style.bottom = 'auto';
//     }
//   };

//   useEffect(() => {
//     const dropdowns = document.querySelectorAll('.dropdown-content');
//     dropdowns.forEach((dropdown) => handleDropdownPosition(dropdown));
//   }, [activeDropdown]);

//   useEffect(() => {
//     const savedSearches = localStorage.getItem('recentSearches');
//     if (savedSearches) {
//       setRecentSearches(JSON.parse(savedSearches));
//     }
//     setInterval(updateTopSearchRanks, 300000);
//     updateTopSearchRanks();
//   }, []);

//   function updateTopSearchRanks() {
//     getTop20SearchRanks().then((topSearchRanks) => {
//       const realTimeSearches = topSearchRanks.map((rank) => ({
//         currentRank: rank.currentRank,
//         keyword: rank.keyword,
//         status: rank.status,
//       }));
//       setRealTimeSearches(realTimeSearches);
//       console.log('Updated search ranks:', realTimeSearches);
//     });
//   }

//   useOutsideClick(categoryRef, () => setShowCategories(false));

//   const onSelectCategory = async (category) => {
//     handleSearchCategory(category);
//   };
//   const onSelectedCategoryPath = async (categoryPath) => {
//     handleSearchCategoryPath(categoryPath);
//   };

//   const handleSearchCategory = (category) => {
//     dispatch(setSearchCategory(category));
//     logSearchedCategory(category.id);
//   };

//   const handleSearchCategoryPath = (categoryPath) => {
//     dispatch(setSearchCategoryPath(categoryPath));
//     setShowCategories(false);
//   };

//   const handleSelectSearchKeyword = (searchTerm) => {
//     // setInputValue(searchTerm);
//     // handleSearchKeyword(searchTerm);
//     // dispatch(setSearchKeyword(searchTerm));
//     // blurTimeoutRef.current = setTimeout(() => {
//     //   setShowPopup(false);
//     // }, 100);
//     setInputValue(searchTerm); // 선택한 검색어를 입력창에 표시
//     handleSearchKeyword(searchTerm);
//     dispatch(setSearchKeyword(searchTerm));
//     setRecentSearches((prevSearches) => {
//       const updatedSearches = [
//         searchTerm,
//         ...prevSearches.filter((item) => item !== searchTerm),
//       ];
//       localStorage.setItem(
//         'recentSearches',
//         JSON.stringify(updatedSearches.slice(0, 20))
//       ); // 최근 검색어를 로컬 스토리지에 저장
//       return updatedSearches.slice(0, 20);
//     });
//     blurTimeoutRef.current = setTimeout(() => {
//       setShowPopup(false);
//     }, 100);
//   };

//   return (
//     <div>
//       {MessageBoxRender()}

//       <AppBar
//         position="static"
//         // style={{ backgroundColor: 'whitesmoke', color: '#2E3A59' }}
//         style={{ backgroundColor: 'white', color: '#2E3A59' }}
//         sx={{ height: '100px', display: 'flex', justifyContent: 'center' }}
//       >
//         <Box
//           display="flex"
//           justifyContent="center"
//           alignItems="center"
//           sx={{ height: '10px' }}
//         >
//           <MiniNavBar currentUser={currentUser} />
//         </Box>
//         <Toolbar
//           sx={{
//             padding: '20px 10px',
//             display: 'flex',
//             alignItems: 'center',
//             justifyContent: 'space-between',
//           }}
//         >
//           <Box sx={{ display: 'flex', alignItems: 'center' }}>
//             <IconButton
//               edge="start"
//               color="inherit"
//               aria-label="categories"
//               onClick={() => setShowCategories(!showCategories)}
//               ref={categoryButtonRef}
//             >
//               <img
//                 src={categoryIcon}
//                 alt="Categories"
//                 style={{ height: 40, width: 40 }}
//               />
//             </IconButton>
//             <Typography
//               variant="h6"
//               component={Link}
//               to="/"
//               sx={{
//                 flexGrow: 1,
//                 textDecoration: 'none', // 언더바 제거
//                 color: 'inherit',
//                 marginRight: 'auto',
//                 fontSize: '1.5rem', // 폰트 크기 설정
//                 fontWeight: 'bold', // 폰트 두께 설정
//                 ml: '20px',
//               }}
//               onClick={(event) => {
//                 navigate('/');
//               }}
//             >
//               {t('appTitle')}
//             </Typography>
//           </Box>
//           {showCategories && (
//             <div
//               ref={categoryRef}
//               style={{
//                 position: 'fixed',
//                 top: 0,
//                 left: 0,
//                 height: '100vh',
//                 width: '300px',
//                 backgroundColor: 'white',
//                 overflowY: 'auto',
//                 boxShadow: '2px 0 5px rgba(0, 0, 0, 0.1)',
//                 zIndex: 1000,
//               }}
//             >
//               <ProductCategories
//                 showMode="multiLine"
//                 onSelectedCategoryPath={onSelectedCategoryPath}
//                 onSelectCategory={onSelectCategory}
//                 onClose={() => setShowCategories(false)}
//               />
//             </div>
//           )}
//           <Search>
//             <StyledInputBase
//               placeholder={t('search')}
//               inputProps={{ 'aria-label': 'search' }}
//               value={inputValue}
//               onFocus={handleFocus}
//               onBlur={handleBlur}
//               onChange={(e) => setInputValue(e.target.value)}
//             />

//             <IconButton
//               type="button"
//               edge="end"
//               sx={{ p: '10px', mr: '10px' }}
//               aria-label="search"
//               onClick={() => handleSearchKeyword(inputValue)}
//             >
//               <SearchIconWrapper>
//                 <SearchIcon />
//               </SearchIconWrapper>
//             </IconButton>

//             {showPopup && (
//               <div ref={popupRef}>
//                 <SearchListContainer
//                   recentSearches={recentSearches}
//                   realTimeSearches={realTimeSearches}
//                   setRecentSearches={setRecentSearches}
//                   onSelectSearchKeyword={handleSelectSearchKeyword}
//                   activeTab={activeTab}
//                   setActiveTab={setActiveTab}
//                 />
//                 <AutoCompleteSearch query={inputValue} />
//               </div>
//             )}
//           </Search>
//           <Box sx={{ display: 'flex', alignItems: 'center' }}>
//             <IconButton component={NavLink} to="/" color="inherit">
//               <img
//                 src={recycleIcon}
//                 alt={t('productList')}
//                 style={{ height: 50, width: 50 }}
//               />
//             </IconButton>
//             {!currentUser ? (
//               <>
//                 <IconButton component={NavLink} to="/login" color="inherit">
//                   <img
//                     src={signinIcon}
//                     alt={t('login')}
//                     style={{ height: 50, width: 50 }}
//                   />
//                 </IconButton>
//                 <IconButton
//                   color="inherit"
//                   onClick={() => setActiveDropdown(!activeDropdown)}
//                 >
//                   <img
//                     src={signupIcon}
//                     alt={t('uploadProduct')}
//                     style={{ height: 50, width: 50 }}
//                   />
//                 </IconButton>
//                 {activeDropdown && (
//                   <Box
//                     sx={{
//                       position: 'absolute',
//                       right: 0,
//                       top: '100%',
//                       zIndex: 2000,
//                       backgroundColor: 'white',
//                       boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.2)',
//                     }}
//                   >
//                     <MenuItem
//                       onClick={() => {
//                         openUserModal();
//                         setActiveDropdown(false);
//                       }}
//                     >
//                       {t('userSignUp')}
//                     </MenuItem>
//                     <MenuItem
//                       onClick={() => {
//                         openStoreModal();
//                         setActiveDropdown(false);
//                       }}
//                     >
//                       {t('storeSignUp')}
//                     </MenuItem>
//                   </Box>
//                 )}
//               </>
//             ) : (
//               <>
//                 <IconButton component={NavLink} to="/upload" color="inherit">
//                   <img
//                     src={uploadIcon}
//                     alt={t('uploadProduct')}
//                     style={{ height: 50, width: 50 }}
//                   />
//                 </IconButton>
//                 <IconButton component={NavLink} to="/heartlist" color="inherit">
//                   <img
//                     src={watchListIcon}
//                     alt={t('watchlist')}
//                     style={{ height: 50, width: 50 }}
//                   />
//                 </IconButton>
//                 <IconButton component={NavLink} to="/chatlist" color="inherit">
//                   <img
//                     src={chatIcon}
//                     alt={t('chat')}
//                     style={{ height: 50, width: 50 }}
//                   />
//                 </IconButton>
//                 <IconButton
//                   component={NavLink}
//                   to={`/allcartlist/${currentUser.uid}`}
//                   color="inherit"
//                 >
//                   <img
//                     src={cartIcon}
//                     alt={t('cart')}
//                     style={{ height: 50, width: 50 }}
//                   />
//                 </IconButton>
//                 <IconButton color="inherit" onClick={handleMenu}>
//                   <Avatar
//                     src={myPageIcon}
//                     alt={t('mypage')}
//                     sx={{ height: 50, width: 50 }}
//                   />
//                 </IconButton>
//                 <Menu
//                   anchorEl={anchorEl}
//                   anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
//                   keepMounted
//                   transformOrigin={{ vertical: 'top', horizontal: 'right' }}
//                   open={Boolean(anchorEl)}
//                   onClose={handleClose}
//                 >
//                   <MenuItem
//                     component={NavLink}
//                     to={`/mypage/${currentUser.uid}/${currentUser.uid}`}
//                   >
//                     {t('mypage')}
//                   </MenuItem>
//                   <MenuItem component={NavLink} to="/storelist">
//                     {t('storelist')}
//                   </MenuItem>
//                   <MenuItem
//                     component={NavLink}
//                     to={`/mystore/${currentUser.uid}`}
//                   >
//                     {t('mystore')}
//                   </MenuItem>
//                   <Divider />
//                 </Menu>

//                 <IconButton color="inherit" component={NavLink} to="/settings">
//                   <Avatar
//                     src={settingIcon}
//                     alt={t('settings.title')}
//                     sx={{ height: 50, width: 50 }}
//                   />
//                 </IconButton>

//                 <UserMenu
//                   user={currentUser}
//                   handleUserProfile={handleUserProfile}
//                   handleGoTerms={handleGoTerms}
//                   handleGoPrivacy={handleGoPrivacy}
//                   handleLogout={handleLogout}
//                 />
//               </>
//             )}
//           </Box>
//         </Toolbar>
//       </AppBar>
//       <main>{children}</main>
//       {MessageBoxRender()}
//       {isUserModal && (
//         <UserSignUpFlow mode={mode} closeModal={() => setIsUserModal(false)} />
//       )}
//       {isStoreModal && (
//         <StoreSignUpFlow
//           mode={mode}
//           closeModal={() => setIsStoreModal(false)}
//         />
//       )}
//     </div>
//   );
// }

// export default NavBar;

// import React, { useState, useEffect, useRef } from 'react';
// import {
//   AppBar,
//   Toolbar,
//   IconButton,
//   Button,
//   Menu,
//   MenuItem,
//   Typography,
//   Box,
//   InputBase,
//   Divider,
//   Avatar,
//   TextField,
// } from '@mui/material';
// import { alpha, styled } from '@mui/material/styles';
// import MenuIcon from '@mui/icons-material/Menu';
// import SearchIcon from '@mui/icons-material/Search';
// import AccountCircle from '@mui/icons-material/AccountCircle';
// import { Link, NavLink, useNavigate } from 'react-router-dom';
// import { useDispatch, useSelector } from 'react-redux';
// import { useTranslation } from 'react-i18next';
// import {
//   setSearchCategory,
//   setSearchCategoryPath,
//   setSearchKeyword,
// } from '../Redux/reducers/searchReducer';
// import useOutsideClick from '../useOutsideClick';
// import SearchListContainer from './SearchListContainer';
// import AutoCompleteSearch from '../Search/AutoCompleteSearch';
// import UserMenu from '../UserMenu';
// import MiniNavBar from './MiniNavBar';
// import categoryIcon from '../assets/images/category.png';
// import recycleIcon from '../assets/images/recycle.png';
// import uploadIcon from '../assets/images/upload.png';
// import chatIcon from '../assets/images/chat.png';
// import watchListIcon from '../assets/images/watchlist.png';
// import micIcon from '../assets/images/mic.png';
// import myPageIcon from '../assets/images/header-mypage.svg';
// import cartIcon from '../assets/images/header-cart.svg';
// import signinIcon from '../assets/images/signin.png';
// import signupIcon from '../assets/images/signup.png';
// import settingIcon from '../assets/images/settings.png';
// import '../css/navbar.css';
// import UserSignUpFlow from '../SignUp/UserSignUpFlow';
// import StoreSignUpFlow from '../SignUp/StoreSignUpFlow';
// import { setEditStep } from '../Redux/reducers/editUserReducer';
// import {
//   db,
//   signOut,
//   auth,
//   collection,
//   query,
//   orderBy,
//   limit,
//   getDocs,
//   where,
// } from '../firebaseConfig';
// import useMessageBox from '../Common/useMessageBox';
// import ProductCategories from '../Stores/ProductCategories';
// import ProductBrandSelector from '../Stores/ProductBrandSelector';
// import useUserActivity from '../useUserActivity';
// import useStoreUserActivity from '../Stores/useStoreUserActivity';

// const Search = styled('div')(({ theme }) => ({
//   position: 'relative',
//   borderRadius: '22px', // 라운드 처리
//   backgroundColor: 'transparent', // 내부 컬러 제거
//   border: `1px solid ${alpha(theme.palette.common.black, 0.5)}`, // 테두리 스타일 추가
//   '&:hover': {
//     border: `1px solid ${alpha(theme.palette.common.black, 0.75)}`, // 호버 시 테두리 스타일
//   },
//   marginRight: theme.spacing(2),
//   marginLeft: 0,
//   width: '100%',
//   display: 'flex',
//   alignItems: 'center',
//   [theme.breakpoints.up('sm')]: {
//     marginLeft: theme.spacing(3),
//     width: '40%',
//   },
// }));

// const SearchIconWrapper = styled('div')(({ theme }) => ({
//   padding: theme.spacing(0, 1),
//   height: '100%',
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',
// }));

// const StyledInputBase = styled(InputBase)(({ theme }) => ({
//   color: 'inherit',
//   flexGrow: 1,
//   '& .MuiInputBase-input': {
//     padding: theme.spacing(1),
//     paddingLeft: `calc(1em + ${theme.spacing(2)})`,
//     transition: theme.transitions.create('width'),
//     width: '100%',
//     [theme.breakpoints.up('md')]: {
//       width: '100%',
//     },
//   },
// }));

// function NavBar({ children, setActiveMenu }) {
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [showPopup, setShowPopup] = useState(false);
//   const [inputValue, setInputValue] = useState('');
//   const [activeDropdown, setActiveDropdown] = useState(false);
//   const [isUserModal, setIsUserModal] = useState(false);
//   const [isStoreModal, setIsStoreModal] = useState(false);
//   const [recentSearches, setRecentSearches] = useState([]);
//   const [realTimeSearches, setRealTimeSearches] = useState([]);
//   const [mode, setMode] = useState(1);
//   const [MessageBoxInform, MessageBoxConfirm, MessageBoxRender] =
//     useMessageBox();
//   const [showCategories, setShowCategories] = useState(false);
//   const [showBrands, setShowBrands] = useState(false);
//   const [categoryButtonPosition, setCategoryButtonPosition] = useState({
//     top: 0,
//     left: 0,
//   });
//   const [activeTab, setActiveTab] = useState(1);
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const currentUser = useSelector((state) => state.auth.currentUser);
//   const { t } = useTranslation();
//   const searchRef = useRef();
//   const popupRef = useRef();
//   const blurTimeoutRef = useRef();
//   const categoryButtonRef = useRef(null);
//   const categoryRef = useRef();

//   const {
//     logViewedProduct,
//     logPurchasedProduct,
//     logSearchedKeyword,
//     logSearchedCategory,
//   } = useUserActivity(currentUser?.uid);

//   const {
//     logViewedStoreProduct,
//     logPurchasedStoreProduct,
//     logSearchedStoreKeyword,
//     logSearchedStoreCategory,
//   } = useStoreUserActivity(currentUser?.uid);

//   const handleMenuClick = (menu) => {
//     // setAnchorEl(menu.currentTarget);
//     setActiveMenu(menu);
//     // navigate(menu);
//   };
//   const handleDropdownMenuClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };
//   const handleClose = () => {
//     setAnchorEl(null);
//   };

//   const handleFocus = () => {
//     setShowPopup(true);
//     if (blurTimeoutRef.current) {
//       clearTimeout(blurTimeoutRef.current);
//     }
//   };

//   const handleBlur = () => {
//     blurTimeoutRef.current = setTimeout(() => {
//       setShowPopup(false);
//     }, 100);
//   };

//   // 팝업 외부 클릭 시 팝업 닫기
//   useOutsideClick(popupRef, () => {
//     setShowPopup(false);
//   });

//   // 검색바 내부 클릭 시 팝업 유지
//   useOutsideClick(searchRef, () => {
//     // setShowPopup(true);
//   });

//   const handleSearchKeyword = (searchTerm) => {
//     dispatch(setSearchKeyword(searchTerm));
//     navigate('/keyword_search_results');
//     setRecentSearches((prevSearches) => {
//       const updatedSearches = [
//         searchTerm,
//         ...prevSearches.filter((item) => item !== searchTerm),
//       ];
//       const slicedSearches = updatedSearches.slice(0, 20);
//       localStorage.setItem('recentSearches', JSON.stringify(slicedSearches));
//       return slicedSearches;
//     });

//     logSearchedKeyword(searchTerm);
//     logSearchedStoreKeyword(currentUser?.uid, searchTerm);
//   };

//   const openUserModal = () => {
//     setIsUserModal(true);
//     dispatch(setEditStep(1));
//   };

//   const openStoreModal = () => {
//     setIsStoreModal(true);
//     dispatch(setEditStep(1));
//   };

//   const handleUserProfile = async () => {
//     const userId = currentUser?.uid;
//     if (userId) {
//       navigate(`user_profile/${userId}`);
//     } else {
//       console.error('User ID not found');
//     }
//   };

//   const handleGoTerms = async () => {
//     const userId = currentUser?.uid;
//     if (userId) {
//       navigate('/terms_list');
//     } else {
//       console.error('User ID not found');
//     }
//   };

//   const handleGoPrivacy = async () => {
//     const userId = currentUser?.uid;
//     if (userId) {
//       navigate('/privacy_list');
//     } else {
//       console.error('User ID not found');
//     }
//   };

//   const clearAuthState = async () => {
//     await signOut(auth);
//     localStorage.clear();
//     sessionStorage.clear();
//     document.cookie.split(';').forEach((c) => {
//       document.cookie = c
//         .replace(/^ +/, '')
//         .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
//     });
//     return new Promise((resolve) => setTimeout(resolve, 1000));
//   };

//   const handleLogout = async () => {
//     const isConfirmed = await MessageBoxConfirm(
//       t('logout'),
//       t('settings.logout-confirm')
//     );
//     if (isConfirmed) {
//       try {
//         await clearAuthState();
//         navigate('/');
//       } catch (error) {
//         console.error('Error during sign out:', error);
//       }
//     }
//   };

//   const getTop20SearchRanks = async () => {
//     const searchRanksRef = collection(db, 'searchRanks');
//     const q = query(searchRanksRef, orderBy('currentRank'), limit(20));
//     const querySnapshot = await getDocs(q);

//     const topSearchRanks = querySnapshot.docs.map((docSnapshot) => {
//       const data = docSnapshot.data();
//       let status;
//       const rankChange = data.previousRank - data.currentRank;

//       if (rankChange >= 3) {
//         status = '↑↑';
//       } else if (rankChange >= 1) {
//         status = '↑';
//       } else if (rankChange === 0) {
//         status = '-';
//       } else {
//         status = '↓';
//       }

//       return {
//         keyword: data.keyword,
//         currentRank: data.currentRank,
//         previousRank: data.previousRank,
//         status: status,
//       };
//     });

//     return topSearchRanks;
//   };

//   const handleDropdownPosition = (element) => {
//     const rect = element.getBoundingClientRect();
//     if (rect.bottom > window.innerHeight) {
//       element.style.top = 'auto';
//       element.style.bottom = '100%';
//     } else {
//       element.style.top = '100%';
//       element.style.bottom = 'auto';
//     }
//   };

//   useEffect(() => {
//     const dropdowns = document.querySelectorAll('.dropdown-content');
//     dropdowns.forEach((dropdown) => handleDropdownPosition(dropdown));
//   }, [activeDropdown]);

//   useEffect(() => {
//     const savedSearches = localStorage.getItem('recentSearches');
//     if (savedSearches) {
//       setRecentSearches(JSON.parse(savedSearches));
//     }
//     setInterval(updateTopSearchRanks, 300000);
//     updateTopSearchRanks();
//   }, []);

//   function updateTopSearchRanks() {
//     getTop20SearchRanks().then((topSearchRanks) => {
//       const realTimeSearches = topSearchRanks.map((rank) => ({
//         currentRank: rank.currentRank,
//         keyword: rank.keyword,
//         status: rank.status,
//       }));
//       setRealTimeSearches(realTimeSearches);
//       console.log('Updated search ranks:', realTimeSearches);
//     });
//   }

//   useOutsideClick(categoryRef, () => setShowCategories(false));

//   const onSelectCategory = async (category) => {
//     handleSearchCategory(category);
//   };
//   const onSelectedCategoryPath = async (categoryPath) => {
//     handleSearchCategoryPath(categoryPath);
//   };

//   const handleSearchCategory = (category) => {
//     dispatch(setSearchCategory(category));
//     logSearchedCategory(category.id);
//   };

//   const handleSearchCategoryPath = (categoryPath) => {
//     dispatch(setSearchCategoryPath(categoryPath));
//     setShowCategories(false);
//   };

//   const handleSelectSearchKeyword = (searchTerm) => {
//     setInputValue(searchTerm); // 선택한 검색어를 입력창에 표시
//     handleSearchKeyword(searchTerm);
//     dispatch(setSearchKeyword(searchTerm));
//     setRecentSearches((prevSearches) => {
//       const updatedSearches = [
//         searchTerm,
//         ...prevSearches.filter((item) => item !== searchTerm),
//       ];
//       localStorage.setItem(
//         'recentSearches',
//         JSON.stringify(updatedSearches.slice(0, 20))
//       ); // 최근 검색어를 로컬 스토리지에 저장
//       return updatedSearches.slice(0, 20);
//     });
//     blurTimeoutRef.current = setTimeout(() => {
//       setShowPopup(false);
//     }, 100);
//   };

//   return (
//     <div>
//       {MessageBoxRender()}

//       <AppBar
//         position="static"
//         // style={{ backgroundColor: 'whitesmoke', color: '#2E3A59' }}
//         style={{ backgroundColor: 'white', color: '#2E3A59' }}
//         sx={{ height: '100px', display: 'flex', justifyContent: 'center' }}
//       >
//         <Box
//           display="flex"
//           justifyContent="center"
//           alignItems="center"
//           sx={{ height: '10px' }}
//         >
//           <MiniNavBar currentUser={currentUser} />
//         </Box>
//         <Toolbar
//           sx={{
//             padding: '20px 10px',
//             display: 'flex',
//             alignItems: 'center',
//             justifyContent: 'space-between',
//           }}
//         >
//           <Box sx={{ display: 'flex', alignItems: 'center' }}>
//             <IconButton
//               edge="start"
//               color="inherit"
//               aria-label="categories"
//               onClick={() => setShowCategories(!showCategories)}
//               ref={categoryButtonRef}
//             >
//               <img
//                 src={categoryIcon}
//                 alt="Categories"
//                 style={{ height: 40, width: 40 }}
//               />
//             </IconButton>
//             <Typography
//               variant="h6"
//               component={Link}
//               to="/"
//               sx={{
//                 flexGrow: 1,
//                 textDecoration: 'none', // 언더바 제거
//                 color: 'inherit',
//                 marginRight: 'auto',
//                 fontSize: '1.5rem', // 폰트 크기 설정
//                 fontWeight: 'bold', // 폰트 두께 설정
//                 ml: '20px',
//               }}
//               onClick={(event) => {
//                 navigate('/');
//               }}
//             >
//               {t('appTitle')}
//             </Typography>
//           </Box>
//           {showCategories && (
//             <div
//               ref={categoryRef}
//               style={{
//                 position: 'fixed',
//                 top: 0,
//                 left: 0,
//                 height: '100vh',
//                 width: '300px',
//                 backgroundColor: 'white',
//                 overflowY: 'auto',
//                 boxShadow: '2px 0 5px rgba(0, 0, 0, 0.1)',
//                 zIndex: 1000,
//               }}
//             >
//               <ProductCategories
//                 showMode="multiLine"
//                 onSelectedCategoryPath={onSelectedCategoryPath}
//                 onSelectCategory={onSelectCategory}
//                 onClose={() => setShowCategories(false)}
//               />
//             </div>
//           )}
//           <Search>
//             <StyledInputBase
//               placeholder={t('search')}
//               inputProps={{ 'aria-label': 'search' }}
//               value={inputValue}
//               onFocus={handleFocus}
//               onBlur={handleBlur}
//               onChange={(e) => setInputValue(e.target.value)}
//             />

//             <IconButton
//               type="button"
//               edge="end"
//               sx={{ p: '10px', mr: '10px' }}
//               aria-label="search"
//               onClick={() => handleSearchKeyword(inputValue)}
//             >
//               <SearchIconWrapper>
//                 <SearchIcon />
//               </SearchIconWrapper>
//             </IconButton>

//             {showPopup && (
//               <div ref={popupRef}>
//                 <SearchListContainer
//                   recentSearches={recentSearches}
//                   realTimeSearches={realTimeSearches}
//                   setRecentSearches={setRecentSearches}
//                   onSelectSearchKeyword={handleSelectSearchKeyword}
//                   activeTab={activeTab}
//                   setActiveTab={setActiveTab}
//                 />
//                 <AutoCompleteSearch query={inputValue} />
//               </div>
//             )}
//           </Search>
//           <Box sx={{ display: 'flex', alignItems: 'center' }}>
//             <IconButton
//               component={NavLink}
//               to="/"
//               color="inherit"
//               activeClassName="active"
//               onClick={() => handleMenuClick('/home')}
//             >
//               <img
//                 src={recycleIcon}
//                 alt={t('productList')}
//                 style={{ height: 50, width: 50 }}
//               />
//             </IconButton>
//             {!currentUser ? (
//               <>
//                 <IconButton
//                   component={NavLink}
//                   to="/login"
//                   color="inherit"
//                   activeClassName="active"
//                   onClick={() => handleMenuClick('/login')}
//                 >
//                   <img
//                     src={signinIcon}
//                     alt={t('login')}
//                     style={{ height: 50, width: 50 }}
//                   />
//                 </IconButton>
//                 <IconButton
//                   color="inherit"
//                   onClick={() => setActiveDropdown(!activeDropdown)}
//                 >
//                   <img
//                     src={signupIcon}
//                     alt={t('uploadProduct')}
//                     style={{ height: 50, width: 50 }}
//                   />
//                 </IconButton>
//                 {activeDropdown && (
//                   <Box
//                     sx={{
//                       position: 'absolute',
//                       right: 0,
//                       top: '100%',
//                       zIndex: 2000,
//                       backgroundColor: 'white',
//                       boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.2)',
//                     }}
//                   >
//                     <MenuItem
//                       onClick={() => {
//                         openUserModal();
//                         setActiveDropdown(false);
//                       }}
//                     >
//                       {t('userSignUp')}
//                     </MenuItem>
//                     <MenuItem
//                       onClick={() => {
//                         openStoreModal();
//                         setActiveDropdown(false);
//                       }}
//                     >
//                       {t('storeSignUp')}
//                     </MenuItem>
//                   </Box>
//                 )}
//               </>
//             ) : (
//               <>
//                 <IconButton
//                   component={NavLink}
//                   to="/upload"
//                   color="inherit"
//                   activeClassName="active"
//                   onClick={() => handleMenuClick('/upload')}
//                 >
//                   <img
//                     src={uploadIcon}
//                     alt={t('uploadProduct')}
//                     style={{ height: 50, width: 50 }}
//                   />
//                 </IconButton>
//                 <IconButton
//                   component={NavLink}
//                   to="/heartlist"
//                   color="inherit"
//                   activeClassName="active"
//                   onClick={() => handleMenuClick('/heartlist')}
//                 >
//                   <img
//                     src={watchListIcon}
//                     alt={t('watchlist')}
//                     style={{ height: 50, width: 50 }}
//                   />
//                 </IconButton>
//                 <IconButton
//                   component={NavLink}
//                   to="/chatlist"
//                   color="inherit"
//                   activeClassName="active"
//                   onClick={() => handleMenuClick('/chatlist')}
//                 >
//                   <img
//                     src={chatIcon}
//                     alt={t('chat')}
//                     style={{ height: 50, width: 50 }}
//                   />
//                 </IconButton>
//                 <IconButton
//                   component={NavLink}
//                   to={`/allcartlist/${currentUser.uid}`}
//                   color="inherit"
//                   activeClassName="active"
//                   onClick={() => handleMenuClick('/allcartlist')}
//                 >
//                   <img
//                     src={cartIcon}
//                     alt={t('cart')}
//                     style={{ height: 50, width: 50 }}
//                   />
//                 </IconButton>
//                 <IconButton
//                   color="inherit"
//                   onClick={handleDropdownMenuClick}
//                   activeClassName="active"
//                 >
//                   <Avatar
//                     src={myPageIcon}
//                     alt={t('mypage')}
//                     sx={{ height: 50, width: 50 }}
//                   />
//                 </IconButton>
//                 <Menu
//                   anchorEl={anchorEl}
//                   anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
//                   keepMounted
//                   transformOrigin={{ vertical: 'top', horizontal: 'right' }}
//                   open={Boolean(anchorEl)}
//                   onClose={handleClose}
//                 >
//                   <MenuItem
//                     component={NavLink}
//                     to={`/mypage/${currentUser.uid}/${currentUser.uid}`}
//                     activeClassName="active"
//                     onClick={() => handleMenuClick('/mypage')}
//                   >
//                     {t('mypage')}
//                   </MenuItem>
//                   <MenuItem
//                     component={NavLink}
//                     to="/storelist"
//                     activeClassName="active"
//                     onClick={() => handleMenuClick('/storelist')}
//                   >
//                     {t('storelist')}
//                   </MenuItem>
//                   <MenuItem
//                     component={NavLink}
//                     to={`/mystore/${currentUser.uid}`}
//                     activeClassName="active"
//                     onClick={() => handleMenuClick('/mystore')}
//                   >
//                     {t('mystore')}
//                   </MenuItem>
//                   <Divider />
//                 </Menu>

//                 <IconButton
//                   color="inherit"
//                   component={NavLink}
//                   to="/settings"
//                   activeClassName="active"
//                   onClick={() => handleMenuClick('/settings')}
//                 >
//                   <Avatar
//                     src={settingIcon}
//                     alt={t('settings.title')}
//                     sx={{ height: 50, width: 50 }}
//                   />
//                 </IconButton>

//                 <UserMenu
//                   user={currentUser}
//                   handleUserProfile={handleUserProfile}
//                   handleGoTerms={handleGoTerms}
//                   handleGoPrivacy={handleGoPrivacy}
//                   handleLogout={handleLogout}
//                 />
//               </>
//             )}
//           </Box>
//         </Toolbar>
//       </AppBar>
//       <main>{children}</main>
//       {MessageBoxRender()}
//       {isUserModal && (
//         <UserSignUpFlow mode={mode} closeModal={() => setIsUserModal(false)} />
//       )}
//       {isStoreModal && (
//         <StoreSignUpFlow
//           mode={mode}
//           closeModal={() => setIsStoreModal(false)}
//         />
//       )}
//     </div>
//   );
// }

// export default NavBar;

import React, { useState, useEffect, useRef } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Menu,
  MenuItem,
  Typography,
  Box,
  InputBase,
  Divider,
  Avatar,
  TextField,
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  setSearchCategory,
  setSearchCategoryPath,
  setSearchKeyword,
} from '../Redux/reducers/searchReducer';
import useOutsideClick from '../useOutsideClick';
import SearchListContainer from './SearchListContainer';
import AutoCompleteSearch from '../Search/AutoCompleteSearch';
import UserMenu from '../UserMenu';
import MiniNavBar from './MiniNavBar';
import categoryIcon from '../assets/images/category.png';
import recycleIcon from '../assets/images/recycle.png';
import uploadIcon from '../assets/images/upload.png';
import chatIcon from '../assets/images/chat.png';
import watchListIcon from '../assets/images/watchlist.png';
import micIcon from '../assets/images/mic.png';
import myPageIcon from '../assets/images/header-mypage.svg';
import cartIcon from '../assets/images/header-cart.svg';
import signinIcon from '../assets/images/signin.png';
import signupIcon from '../assets/images/signup.png';
import settingIcon from '../assets/images/settings.png';
import '../css/navbar.css';
import UserSignUpFlow from '../SignUp/UserSignUpFlow';
import StoreSignUpFlow from '../SignUp/StoreSignUpFlow';
import { setEditStep } from '../Redux/reducers/editUserReducer';
import {
  db,
  signOut,
  auth,
  collection,
  query,
  orderBy,
  limit,
  getDocs,
  where,
} from '../firebaseConfig';
import useMessageBox from '../Common/useMessageBox';
import ProductCategories from '../Stores/ProductCategories';
import ProductBrandSelector from '../Stores/ProductBrandSelector';
import useUserActivity from '../useUserActivity';
import useStoreUserActivity from '../Stores/useStoreUserActivity';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: '22px', // 라운드 처리
  backgroundColor: 'transparent', // 내부 컬러 제거
  border: `1px solid ${alpha(theme.palette.common.black, 0.5)}`, // 테두리 스타일 추가
  '&:hover': {
    border: `1px solid ${alpha(theme.palette.common.black, 0.75)}`, // 호버 시 테두리 스타일
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: '40%',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 1),
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  flexGrow: 1,
  '& .MuiInputBase-input': {
    padding: theme.spacing(1),
    paddingLeft: `calc(1em + ${theme.spacing(2)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
  },
}));

function NavBar({ children, setActiveMenu }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [activeDropdown, setActiveDropdown] = useState(false);
  const [isUserModal, setIsUserModal] = useState(false);
  const [isStoreModal, setIsStoreModal] = useState(false);
  const [recentSearches, setRecentSearches] = useState([]);
  const [realTimeSearches, setRealTimeSearches] = useState([]);
  const [mode, setMode] = useState(1);
  const [MessageBoxInform, MessageBoxConfirm, MessageBoxRender] =
    useMessageBox();
  const [showCategories, setShowCategories] = useState(false);
  const [showBrands, setShowBrands] = useState(false);
  const [categoryButtonPosition, setCategoryButtonPosition] = useState({
    top: 0,
    left: 0,
  });
  const [activeTab, setActiveTab] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const { t } = useTranslation();
  const searchRef = useRef();
  const popupRef = useRef();
  const blurTimeoutRef = useRef();
  const categoryButtonRef = useRef(null);
  const categoryRef = useRef();

  const {
    logViewedProduct,
    logPurchasedProduct,
    logSearchedKeyword,
    logSearchedCategory,
  } = useUserActivity(currentUser?.uid);

  const {
    logViewedStoreProduct,
    logPurchasedStoreProduct,
    logSearchedStoreKeyword,
    logSearchedStoreCategory,
  } = useStoreUserActivity(currentUser?.uid);

  const handleMenuClick = (menu) => {
    setActiveMenu(menu);
  };
  const handleDropdownMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFocus = () => {
    setShowPopup(true);
    if (blurTimeoutRef.current) {
      clearTimeout(blurTimeoutRef.current);
    }
  };

  const handleBlur = () => {
    blurTimeoutRef.current = setTimeout(() => {
      setShowPopup(false);
    }, 100);
  };

  useOutsideClick(popupRef, () => {
    setShowPopup(false);
  });

  useOutsideClick(searchRef, () => {
    // setShowPopup(true);
  });

  const handleSearchKeyword = (searchTerm) => {
    dispatch(setSearchKeyword(searchTerm));
    navigate('/keyword_search_results');
    setRecentSearches((prevSearches) => {
      const updatedSearches = [
        searchTerm,
        ...prevSearches.filter((item) => item !== searchTerm),
      ];
      const slicedSearches = updatedSearches.slice(0, 20);
      localStorage.setItem('recentSearches', JSON.stringify(slicedSearches));
      return slicedSearches;
    });

    logSearchedKeyword(searchTerm);
    logSearchedStoreKeyword(currentUser?.uid, searchTerm);
  };

  const openUserModal = () => {
    setIsUserModal(true);
    dispatch(setEditStep(1));
  };

  const openStoreModal = () => {
    setIsStoreModal(true);
    dispatch(setEditStep(1));
  };

  const handleUserProfile = async () => {
    const userId = currentUser?.uid;
    if (userId) {
      navigate(`my_profile/${userId}`);
    } else {
      console.error('User ID not found');
    }
  };

  const handleGoTerms = async () => {
    const userId = currentUser?.uid;
    if (userId) {
      navigate('/terms_list');
    } else {
      console.error('User ID not found');
    }
  };

  const handleGoPrivacy = async () => {
    const userId = currentUser?.uid;
    if (userId) {
      navigate('/privacy_list');
    } else {
      console.error('User ID not found');
    }
  };

  const clearAuthState = async () => {
    await signOut(auth);
    localStorage.clear();
    sessionStorage.clear();
    document.cookie.split(';').forEach((c) => {
      document.cookie = c
        .replace(/^ +/, '')
        .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
    });
    return new Promise((resolve) => setTimeout(resolve, 1000));
  };

  const handleLogout = async () => {
    const isConfirmed = await MessageBoxConfirm(
      t('logout'),
      t('settings.logout-confirm')
    );
    if (isConfirmed) {
      try {
        await clearAuthState();
        navigate('/');
      } catch (error) {
        console.error('Error during sign out:', error);
      }
    }
  };

  // const getTop20SearchRanks = async () => {
  //   const searchRanksRef = collection(db, 'searchRanks');
  //   const q = query(searchRanksRef, orderBy('currentRank'), limit(20));
  //   const querySnapshot = await getDocs(q);

  //   const topSearchRanks = querySnapshot.docs.map((docSnapshot) => {
  //     const data = docSnapshot.data();
  //     let status;
  //     const rankChange = data.previousRank - data.currentRank;

  //     if (rankChange >= 3) {
  //       status = '↑↑';
  //     } else if (rankChange >= 1) {
  //       status = '↑';
  //     } else if (rankChange === 0) {
  //       status = '-';
  //     } else {
  //       status = '↓';
  //     }

  //     return {
  //       keyword: data.keyword,
  //       currentRank: data.currentRank,
  //       previousRank: data.previousRank,
  //       status: status,
  //     };
  //   });

  //   return topSearchRanks;
  // };

  const getTop20SearchRanks = async () => {
    try {
      const searchRanksRef = collection(db, 'searchRanks');
      const q = query(searchRanksRef, orderBy('currentRank'), limit(20));
      const querySnapshot = await getDocs(q);

      const topSearchRanks = querySnapshot.docs.map((docSnapshot) => {
        const data = docSnapshot.data();
        let status;
        const rankChange = data.previousRank - data.currentRank;

        if (rankChange >= 3) {
          status = '↑↑';
        } else if (rankChange >= 1) {
          status = '↑';
        } else if (rankChange === 0) {
          status = '-';
        } else {
          status = '↓';
        }

        return {
          keyword: data.keyword,
          currentRank: data.currentRank,
          previousRank: data.previousRank,
          status: status,
        };
      });

      return topSearchRanks;
    } catch (error) {
      if (
        error.code === 'failed-precondition' ||
        error.code === 'unavailable'
      ) {
        console.error(
          'Firestore index error: Please create the required index in the Firestore console.',
          error
        );
      } else {
        console.error('Error fetching top 20 search ranks:', error);
      }
      return [];
    }
  };

  const handleDropdownPosition = (element) => {
    const rect = element.getBoundingClientRect();
    if (rect.bottom > window.innerHeight) {
      element.style.top = 'auto';
      element.style.bottom = '100%';
    } else {
      element.style.top = '100%';
      element.style.bottom = 'auto';
    }
  };

  useEffect(() => {
    const dropdowns = document.querySelectorAll('.dropdown-content');
    dropdowns.forEach((dropdown) => handleDropdownPosition(dropdown));
  }, [activeDropdown]);

  useEffect(() => {
    const savedSearches = localStorage.getItem('recentSearches');
    if (savedSearches) {
      setRecentSearches(JSON.parse(savedSearches));
    }
    setInterval(updateTopSearchRanks, 300000);
    updateTopSearchRanks();
  }, []);

  function updateTopSearchRanks() {
    getTop20SearchRanks().then((topSearchRanks) => {
      const realTimeSearches = topSearchRanks.map((rank) => ({
        currentRank: rank.currentRank,
        keyword: rank.keyword,
        status: rank.status,
      }));
      setRealTimeSearches(realTimeSearches);
      console.log('Updated search ranks:', realTimeSearches);
    });
  }

  useOutsideClick(categoryRef, () => setShowCategories(false));

  const onSelectCategory = async (category) => {
    handleSearchCategory(category);
  };
  const onSelectedCategoryPath = async (categoryPath) => {
    handleSearchCategoryPath(categoryPath);
  };

  const handleSearchCategory = (category) => {
    dispatch(setSearchCategory(category));
    logSearchedCategory(category.id);
  };

  const handleSearchCategoryPath = (categoryPath) => {
    dispatch(setSearchCategoryPath(categoryPath));
    setShowCategories(false);
  };

  const handleSelectSearchKeyword = (searchTerm) => {
    setInputValue(searchTerm); // 선택한 검색어를 입력창에 표시
    handleSearchKeyword(searchTerm);
    dispatch(setSearchKeyword(searchTerm));
    setRecentSearches((prevSearches) => {
      const updatedSearches = [
        searchTerm,
        ...prevSearches.filter((item) => item !== searchTerm),
      ];
      localStorage.setItem(
        'recentSearches',
        JSON.stringify(updatedSearches.slice(0, 20))
      ); // 최근 검색어를 로컬 스토리지에 저장
      return updatedSearches.slice(0, 20);
    });
    blurTimeoutRef.current = setTimeout(() => {
      setShowPopup(false);
    }, 100);
  };
  // 기존 스타일 정의 (스타일을 유지하기 위해 필요하면 추가할 수 있음)
  const navLinkStyle = {
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
  };

  const activeStyle = {
    borderBottom: '2px solid #000', // underline 효과
  };
  const CustomNavLink = styled(NavLink)(({ theme }) => ({
    '&.active': {
      borderBottom: `2px solid ${theme.palette.primary.main}`,
      paddingBottom: '2px', // Optional: Adjust space between icon and underline
    },
    display: 'inline-flex',
    alignItems: 'center',
    textDecoration: 'none', // Remove default underline from NavLink
  }));
  return (
    <div>
      {MessageBoxRender()}

      <AppBar
        position="static"
        style={{ backgroundColor: 'white', color: '#2E3A59' }}
        sx={{ height: '100px', display: 'flex', justifyContent: 'center' }}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ height: '10px' }}
        >
          <MiniNavBar currentUser={currentUser} />
        </Box>
        <Toolbar
          sx={{
            padding: '20px 10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="categories"
              onClick={() => setShowCategories(!showCategories)}
              ref={categoryButtonRef}
            >
              <img
                src={categoryIcon}
                alt="Categories"
                style={{ height: 40, width: 40 }}
              />
            </IconButton>
            <Typography
              variant="h6"
              component={Link}
              to="/"
              sx={{
                flexGrow: 1,
                textDecoration: 'none', // 언더바 제거
                color: 'inherit',
                marginRight: 'auto',
                fontSize: '1.8rem', // 폰트 크기 설정
                fontWeight: 'bold', // 폰트 두께 설정
                ml: '20px',
              }}
              onClick={(event) => {
                navigate('/');
              }}
            >
              {t('appTitle')}
            </Typography>
          </Box>
          {showCategories && (
            <div
              ref={categoryRef}
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                height: '100vh',
                width: '300px',
                backgroundColor: 'white',
                overflowY: 'auto',
                boxShadow: '2px 0 5px rgba(0, 0, 0, 0.1)',
                zIndex: 1000,
              }}
            >
              <ProductCategories
                showMode="multiLine"
                onSelectedCategoryPath={onSelectedCategoryPath}
                onSelectCategory={onSelectCategory}
                onClose={() => setShowCategories(false)}
              />
            </div>
          )}
          <Search>
            <StyledInputBase
              placeholder={t('search')}
              inputProps={{ 'aria-label': 'search' }}
              value={inputValue}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onChange={(e) => setInputValue(e.target.value)}
            />

            <IconButton
              type="button"
              edge="end"
              sx={{ p: '10px', mr: '10px' }}
              aria-label="search"
              onClick={() => handleSearchKeyword(inputValue)}
            >
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
            </IconButton>

            {showPopup && (
              <div ref={popupRef}>
                <SearchListContainer
                  recentSearches={recentSearches}
                  realTimeSearches={realTimeSearches}
                  setRecentSearches={setRecentSearches}
                  onSelectSearchKeyword={handleSelectSearchKeyword}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
                <AutoCompleteSearch query={inputValue} />
              </div>
            )}
          </Search>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {/* <IconButton
              component={NavLink}
              to="/"
              color="inherit"
              className={({ isActive }) => (isActive ? 'active' : '')}
              onClick={() => handleMenuClick('/home')}
            >
              <img
                src={recycleIcon}
                alt={t('productList')}
                style={{ height: 50, width: 50 }}
              />
            </IconButton> */}
            <CustomNavLink component={NavLink} to="/" activeClassName="active">
              <IconButton
                color="inherit"
                onClick={() => handleMenuClick('/home')}
              >
                <img
                  src={recycleIcon}
                  alt={t('productList')}
                  style={{ height: 50, width: 50 }}
                />
              </IconButton>
            </CustomNavLink>
            {!currentUser ? (
              <>
                <IconButton
                  component={NavLink}
                  to="/login"
                  color="inherit"
                  className={({ isActive }) => (isActive ? 'active' : '')}
                  onClick={() => handleMenuClick('/login')}
                >
                  <img
                    src={signinIcon}
                    alt={t('login')}
                    style={{ height: 50, width: 50 }}
                  />
                </IconButton>
                <IconButton
                  color="inherit"
                  onClick={() => setActiveDropdown(!activeDropdown)}
                >
                  <img
                    src={signupIcon}
                    alt={t('uploadProduct')}
                    style={{ height: 50, width: 50 }}
                  />
                </IconButton>
                {activeDropdown && (
                  <Box
                    sx={{
                      position: 'absolute',
                      right: 0,
                      top: '100%',
                      zIndex: 2000,
                      backgroundColor: 'white',
                      boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.2)',
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        openUserModal();
                        setActiveDropdown(false);
                      }}
                    >
                      {t('userSignUp')}
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        openStoreModal();
                        setActiveDropdown(false);
                      }}
                    >
                      {t('storeSignUp')}
                    </MenuItem>
                  </Box>
                )}
              </>
            ) : (
              <>
                <CustomNavLink
                  component={NavLink}
                  to="/upload"
                  activeClassName="active"
                >
                  <IconButton
                    color="inherit"
                    onClick={() => handleMenuClick('/upload')}
                  >
                    <img
                      src={uploadIcon}
                      alt={t('uploadProduct')}
                      style={{ height: 50, width: 50 }}
                    />
                  </IconButton>
                </CustomNavLink>

                <CustomNavLink
                  component={NavLink}
                  to="/heartlist"
                  activeClassName="active"
                >
                  <IconButton
                    color="inherit"
                    onClick={() => handleMenuClick('/heartlist')}
                  >
                    <img
                      src={watchListIcon}
                      alt={t('watchlist')}
                      style={{ height: 50, width: 50 }}
                    />
                  </IconButton>
                </CustomNavLink>

                <CustomNavLink
                  component={NavLink}
                  to="/chatlist"
                  activeClassName="active"
                >
                  <IconButton
                    color="inherit"
                    onClick={() => handleMenuClick('/chatlist')}
                  >
                    <img
                      src={chatIcon}
                      alt={t('chat')}
                      style={{ height: 50, width: 50 }}
                    />
                  </IconButton>
                </CustomNavLink>

                <CustomNavLink
                  component={NavLink}
                  to={`/allcartlist/${currentUser.uid}`}
                  activeClassName="active"
                >
                  <IconButton
                    color="inherit"
                    onClick={() => handleMenuClick('/allcartlist')}
                  >
                    <img
                      src={cartIcon}
                      alt={t('cart')}
                      style={{ height: 50, width: 50 }}
                    />
                  </IconButton>
                </CustomNavLink>

                <IconButton
                  component={NavLink}
                  color="inherit"
                  onClick={handleDropdownMenuClick}
                  className={({ isActive }) => (isActive ? 'active' : '')}
                >
                  <Avatar
                    src={myPageIcon}
                    alt={t('mypage')}
                    sx={{ height: 50, width: 50 }}
                  />
                </IconButton>

                <Menu
                  anchorEl={anchorEl}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  keepMounted
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem
                    component={NavLink}
                    to={`/mypage/${currentUser.uid}/${currentUser.uid}`}
                    className={({ isActive }) => (isActive ? 'active' : '')}
                    onClick={() => handleMenuClick('/mypage')}
                  >
                    {t('mypage')}
                  </MenuItem>
                  <MenuItem
                    component={NavLink}
                    to="/storelist"
                    className={({ isActive }) => (isActive ? 'active' : '')}
                    onClick={() => handleMenuClick('/storelist')}
                  >
                    {t('storelist')}
                  </MenuItem>
                  <MenuItem
                    component={NavLink}
                    to={`/mystore/${currentUser.uid}`}
                    className={({ isActive }) => (isActive ? 'active' : '')}
                    onClick={() => handleMenuClick('/mystore')}
                  >
                    {t('mystore')}
                  </MenuItem>
                  <Divider />
                </Menu>

                <CustomNavLink
                  component={NavLink}
                  to="/settings"
                  activeClassName="active"
                >
                  <IconButton
                    color="inherit"
                    onClick={() => handleMenuClick('/settings')}
                  >
                    <Avatar
                      src={settingIcon}
                      alt={t('settings.title')}
                      sx={{ height: 50, width: 50 }}
                    />
                  </IconButton>
                </CustomNavLink>
                <UserMenu
                  user={currentUser}
                  handleUserProfile={handleUserProfile}
                  handleGoTerms={handleGoTerms}
                  handleGoPrivacy={handleGoPrivacy}
                  handleLogout={handleLogout}
                />
              </>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <main>{children}</main>
      {MessageBoxRender()}
      {isUserModal && (
        <UserSignUpFlow mode={mode} closeModal={() => setIsUserModal(false)} />
      )}
      {isStoreModal && (
        <StoreSignUpFlow
          mode={mode}
          closeModal={() => setIsStoreModal(false)}
        />
      )}
    </div>
  );
}

export default NavBar;
