import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Container,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';

import DiscountCard from './DiscountCard';

const DiscountList = ({ discounts, onDelete, isSeller }) => {
  return (
    <Container>
      <Grid container spacing={2}>
        {discounts.length > 0 ? (
          discounts.map((discount) => (
            <Grid item xs={12} sm={6} md={4} key={discount.id}>
              <DiscountCard
                discount={discount}
                onDelete={onDelete}
                isSeller={isSeller}
              />
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography variant="subtitle1" textAlign="left">
              현재 사용 가능한 할인 정보가 없습니다.
            </Typography>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default DiscountList;
