// import React, { useState, useEffect } from 'react';
// import {
//   db,
//   collection,
//   getDocs,
//   getDoc,
//   doc,
//   updateDoc,
//   addDoc,
//   deleteDoc,
//   query,
//   where,
//   Timestamp,
//   orderBy,
//   limit,
// } from '../../../firebaseConfig';
// import { useParams } from 'react-router-dom';
// import Modal from 'react-modal';
// import ModalStyles from '../../../Common/ModalStyles';
// import '../../css/SignUpFlow.css';
// import useMessageBox from '../../../Common/useMessageBox';

// const DiscountManagement = ({ storeId }) => {
//   // const { id: storeId } = useParams();
//   const [MessageBoxInform, MessageBoxConfirm, MessageBoxRender] =
//     useMessageBox();
//   const [discountType, setDiscountType] = useState('percentage'); // 'percentage' 또는 'amount'
//   const [minPurchaseAmount, setMinPurchaseAmount] = useState(0);
//   const [customAmount, setCustomAmount] = useState('');
//   const [isCustomInput, setIsCustomInput] = useState(false);
//   const [discountName, setDiscountName] = useState('');
//   const [isModalOpen, setModalOpen] = useState(false);
//   const [product, setProduct] = useState({ name: '', id: null });
//   const [discount, setDiscount] = useState(0);
//   const [allProducts, setAllProducts] = useState(false);
//   const [applicableProducts, setApplicableProducts] = useState([]);
//   const [startDate, setStartDate] = useState('');
//   const [endDate, setEndDate] = useState('');
//   const [error, setError] = useState(null);
//   const [isIssuing, setIsIssuing] = useState(false);
//   const [products, setProducts] = useState([]);
//   const [selectedProduct, setSelectedProduct] = useState(null);

//   // Firestore에서 products 컬렉션 내의 데이터를 가져오는 함수
//   const fetchProducts = async () => {
//     const productsRef = collection(db, 'stores', storeId, 'products');
//     const productsSnapshot = await getDocs(productsRef);
//     const fetchedProducts = productsSnapshot.docs.map((doc) => ({
//       id: doc.id,
//       ...doc.data(),
//     }));
//     setProducts(fetchedProducts);
//   };

//   useEffect(() => {
//     fetchProducts();
//   }, []);

//   const [discounts, setDiscounts] = useState([]);

//   const fetchDiscounts = async () => {
//     const now = Timestamp.now();
//     const discountsQuery = query(
//       collection(db, 'stores', storeId, 'discounts'),
//       where('endDate', '>', now),
//       orderBy('endDate', 'asc')
//     );
//     //const discountsQuery = query(collection(db, 'stores', storeId, 'discounts'));
//     const discountsSnapshot = await getDocs(discountsQuery);

//     setDiscounts(
//       discountsSnapshot.docs.map((doc) => ({
//         id: doc.id,
//         ...doc.data(),
//         startDate: doc.data().startDate?.toDate(),
//         endDate: doc.data().endDate?.toDate(),
//       }))
//     );
//   };
//   useEffect(() => {
//     fetchDiscounts();
//   }, [storeId]);

//   function generateDiscountCode(length = 6) {
//     const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
//     let result = '';
//     for (let i = 0; i < length; i++) {
//       result += chars.charAt(Math.floor(Math.random() * chars.length));
//     }
//     return result;
//   }

//   const handleIssueDiscount = async () => {
//     // Check if the required values are set

//     if (!discountType) {
//       await MessageBoxInform('할인 유형을 선택해주세요.');
//       return;
//     }

//     const effectiveMinPurchase = isCustomInput
//       ? customAmount
//       : minPurchaseAmount;

//     if (!effectiveMinPurchase || effectiveMinPurchase <= 0) {
//       await MessageBoxInform('할인 가능한 최소 구매 금액을 설정해주세요.');
//       return;
//     }

//     if (!discount || !startDate || !endDate) {
//       setError('필요한 정보를 모두 입력해주세요.');
//       return;
//     }

//     if (new Date(startDate) >= new Date(endDate)) {
//       setError('시작 시간은 종료 시간보다 이전이어야 합니다.');
//       return;
//     }

//     setIsIssuing(true);
//     const discountCode = generateDiscountCode();
//     const applicableProductIds = applicableProducts.map(
//       (product) => product.id
//     );
//     const discountData = {
//       targetProducts: allProducts ? 'all' : applicableProductIds,
//       discountType: discountType,
//       minPurchaseAmount: effectiveMinPurchase,
//       discount,
//       discountCode,
//       discountName,
//       issuedAt: new Date(),
//       applicableProducts: applicableProducts.map((product) => ({
//         ...product,
//       })),
//       startDate: new Date(startDate),
//       endDate: new Date(endDate),
//     };
//     addDiscount(discountData);
//     applicableProducts.forEach((product) =>
//       applyDiscount(storeId, product.id, discountData)
//     );
//   };
//   async function addDiscount(discountData) {
//     try {
//       const discountsCollectionRef = collection(
//         db,
//         'stores',
//         storeId,
//         'discounts'
//       );

//       addDoc(discountsCollectionRef, discountData)
//         .then(() => {
//           console.log('할인이 등록되었습니다.');
//           handleCloseModal();
//           fetchDiscounts();
//         })
//         .catch((error) => {
//           console.error('Error adding document: ', error);
//         });
//     } catch (error) {
//       console.error('Error adding document: ', error);
//     }
//   }
//   async function applyDiscount(storeId, productId, discountDetails) {
//     const productRef = doc(db, 'stores', storeId, 'products', productId);

//     try {
//       await updateDoc(productRef, {
//         discountCode: discountDetails.discountCode,
//         //discoutType: discountDetails.discountType,
//         //discountPrice: discountDetails.discountPrice,
//         //discountStartDate: Timestamp.fromDate(new Date(discountDetails.discountStartDate)),
//         //discountEndDate: Timestamp.fromDate(new Date(discountDetails.discountEndDate))
//       });
//       console.log('Discount applied successfully to product:', productId);
//     } catch (error) {
//       console.error('Error applying discount:', error);
//     }
//   }

//   const resetFields = () => {
//     setProduct({ name: '', id: null });
//     setDiscount('');
//     setAllProducts(false);
//     setApplicableProducts([]);
//     setStartDate('');
//     setEndDate('');
//     setError(null);
//   };

//   const addApplicableProduct = async () => {
//     if (selectedProduct && selectedProduct.id) {
//       // Check if the product is already in the applicableProducts array
//       if (
//         !applicableProducts.some((product) => product.id === selectedProduct.id)
//       ) {
//         setApplicableProducts((prev) => [
//           ...prev,
//           { id: selectedProduct.id, name: selectedProduct.productName },
//         ]);
//         setSelectedProduct(null); // Reset the selected product
//       } else {
//         await MessageBoxInform('이 상품은 이미 추가되었습니다.'); // This product is already added.
//       }
//     }
//   };

//   const handleProductSelect = (e) => {
//     const selected = products.find((product) => product.id === e.target.value);
//     setSelectedProduct(selected);

//     const productId = e.target.value;
//     const chosenProduct = products.find((product) => product.id === productId);
//     setSelectedProduct(chosenProduct);
//     setProduct({ ...product, name: chosenProduct?.productName });
//   };

//   const handleOpenModal = () => {
//     setModalOpen(true);
//   };

//   const handleCloseModal = () => {
//     fetchDiscounts();
//     setModalOpen(false);
//   };

//   const handleFormSubmit = (e) => {
//     e.preventDefault(); // 폼 제출을 방지합니다.
//   };

//   return (
//     <div>
//       <h2>할인 관리 시스템</h2>
//       <div style={{ position: 'relative' }}>
//         <ul>
//           <div className="discount-display">
//             {discounts.map((discount) =>
//               Array.isArray(discount.applicableProducts)
//                 ? discount.applicableProducts.map((applicableProduct) => (
//                     <div key={discount.id} className="discount-item">
//                       <h1>상품이름: {applicableProduct.name}</h1>
//                       <h2>
//                         {discount.discountType === 'percentage'
//                           ? `할인율: ${discount.discount} %`
//                           : `할인 금액: ${discount.discount} 원`}
//                       </h2>

//                       <p>
//                         사용시작시간: {discount.startDate?.toLocaleString()}
//                       </p>
//                       <p>사용종료시간: {discount.endDate?.toLocaleString()}</p>
//                     </div>
//                   ))
//                 : null
//             )}
//             {discounts.length === 0 && (
//               <div className="coupon-item">
//                 <p>진행중인 할인이 없습니다.</p>{' '}
//                 {/* Assuming there's a description field in the promo object */}
//                 {/* If there are other details, add them here */}
//               </div>
//             )}
//           </div>
//         </ul>
//         <div style={{ position: 'absolute', right: '1px', bottom: '1px' }}>
//           <button className="button" onClick={() => handleOpenModal(null)}>
//             할인 생성
//           </button>
//         </div>
//       </div>
//       {MessageBoxRender()}
//       <Modal
//         isOpen={isModalOpen}
//         onRequestClose={handleCloseModal}
//         style={ModalStyles}
//       >
//         <form onSubmit={handleFormSubmit}>
//           <div>
//             <h2>할인 생성</h2>
//             {error && <p style={{ color: 'red' }}>{error}</p>}
//             <div>
//               <label>
//                 Discount 이름:
//                 <input
//                   type="text"
//                   maxLength={20}
//                   value={discountName}
//                   onChange={(e) => setDiscountName(e.target.value)}
//                 />
//               </label>
//             </div>
//             <div>
//               <label>
//                 <input
//                   type="checkbox"
//                   checked={allProducts}
//                   onChange={() => setAllProducts(!allProducts)}
//                 />
//                 전체 제품 대상
//               </label>
//             </div>
//             <div>
//               <label>
//                 할인 유형 선택:
//                 <select
//                   value={discountType}
//                   onChange={(e) => setDiscountType(e.target.value)}
//                 >
//                   <option value="percentage">퍼센트 할인</option>
//                   <option value="amount">금액 할인</option>
//                 </select>
//               </label>
//             </div>

//             <div>
//               <label>
//                 {discountType === 'percentage'
//                   ? '할인율 선택:'
//                   : '할인 금액 입력:'}
//                 {discountType === 'percentage' ? (
//                   <select
//                     value={discount}
//                     onChange={(e) => setDiscount(Number(e.target.value))}
//                   >
//                     {[...Array(100).keys()].map((num) => (
//                       <option key={num + 1} value={num + 1}>
//                         {num + 1}%
//                       </option>
//                     ))}
//                   </select>
//                 ) : (
//                   <input
//                     type="number"
//                     value={discount}
//                     onChange={(e) => setDiscount(Math.max(0, e.target.value))}
//                     min="0"
//                     placeholder="원"
//                   />
//                 )}
//               </label>
//             </div>
//             <div>
//               <label>
//                 할인 적용 가능한 최소 구매 금액:
//                 <select
//                   value={isCustomInput ? 'custom' : minPurchaseAmount}
//                   onChange={(e) => {
//                     if (e.target.value === 'custom') {
//                       setIsCustomInput(true);
//                     } else {
//                       setIsCustomInput(false);
//                       setMinPurchaseAmount(e.target.value);
//                     }
//                   }}
//                 >
//                   <option value="5000">5,000원</option>
//                   <option value="10000">10,000원</option>
//                   <option value="15000">15,000원</option>
//                   <option value="custom">직접 입력</option>
//                 </select>
//               </label>
//               {isCustomInput && (
//                 <input
//                   type="number"
//                   value={customAmount}
//                   onChange={(e) => setCustomAmount(e.target.value)}
//                   placeholder="원"
//                 />
//               )}
//             </div>

//             {!allProducts && (
//               <div>
//                 <div>
//                   <label>
//                     상품 선택:
//                     <select onChange={handleProductSelect}>
//                       <option value="">상품을 선택하세요</option>
//                       {products.map((product) => (
//                         <option key={product.id} value={product.id}>
//                           {product.productName
//                             ? product.productName
//                             : '제품 이름 없음'}{' '}
//                           -
//                           {product.images && product.images[0] ? (
//                             <img
//                               src={product.images[0]}
//                               alt={product.productName || '제품 이미지'}
//                               width="50"
//                             />
//                           ) : (
//                             '이미지 없음'
//                           )}
//                         </option>
//                       ))}
//                     </select>
//                     <button
//                       onClick={() => addApplicableProduct(selectedProduct)}
//                     >
//                       할인 상품 추가
//                     </button>
//                   </label>
//                 </div>
//                 <div>
//                   <label>
//                     재고:
//                     <input
//                       type="text"
//                       value={`${selectedProduct?.stock || 0}개 남음`}
//                       readOnly
//                     />
//                   </label>
//                 </div>
//                 <ul>
//                   {applicableProducts.map((product) => (
//                     <li key={product.id}>{product.productName}</li>
//                   ))}
//                 </ul>
//               </div>
//             )}

//             <div>
//               <label>
//                 시작 시간:
//                 <input
//                   type="datetime-local"
//                   value={startDate}
//                   onChange={(e) => setStartDate(e.target.value)}
//                 />
//               </label>
//             </div>
//             <div>
//               <label>
//                 종료 시간:
//                 <input
//                   type="datetime-local"
//                   value={endDate}
//                   onChange={(e) => setEndDate(e.target.value)}
//                 />
//               </label>
//             </div>

//             {isIssuing && <div className="spinner"></div>}
//           </div>

//           <div style={{ position: 'absolute', right: '20px', bottom: '20px' }}>
//             {/* <button className='button' type="submit">Save</button> */}
//             <button
//               className="button"
//               type="button"
//               onClick={handleIssueDiscount}
//             >
//               할인적용
//             </button>
//             <button className="button" type="button" onClick={handleCloseModal}>
//               닫기
//             </button>
//           </div>
//         </form>
//       </Modal>
//     </div>
//   );
// };
// export default DiscountManagement;

// import React, { useState, useEffect } from 'react';
// import {
//   db,
//   collection,
//   getDocs,
//   doc,
//   updateDoc,
//   addDoc,
//   query,
//   where,
//   orderBy,
//   Timestamp,
// } from '../../../firebaseConfig';
// import {
//   Button,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   TextField,
//   MenuItem,
//   Checkbox,
//   FormControlLabel,
//   Select,
//   InputLabel,
//   FormGroup,
//   FormControl,
//   Grid,
//   Typography,
//   Box,
//   List,
//   ListItem,
//   ListItemText,
//   IconButton,
// } from '@mui/material';
// import useMessageBox from '../../../Common/useMessageBox';
// import DiscountList from './DiscountList';
// import AddIcon from '@mui/icons-material/Add';
// import DeleteIcon from '@mui/icons-material/Delete';

// const DiscountManagement = ({ storeId }) => {
//   const [MessageBoxInform, MessageBoxConfirm, MessageBoxRender] =
//     useMessageBox();
//   const [discounts, setDiscounts] = useState([]); // Added this line to define the discounts state

//   const [discountType, setDiscountType] = useState('percentage');
//   const [minPurchaseAmount, setMinPurchaseAmount] = useState(0);
//   const [customAmount, setCustomAmount] = useState('');
//   const [isCustomInput, setIsCustomInput] = useState(false);
//   const [discountName, setDiscountName] = useState('');
//   const [isModalOpen, setModalOpen] = useState(false);
//   const [discount, setDiscount] = useState(0);
//   const [allProducts, setAllProducts] = useState(false);
//   const [applicableProducts, setApplicableProducts] = useState([]);
//   const [startDate, setStartDate] = useState('');
//   const [endDate, setEndDate] = useState('');
//   const [error, setError] = useState(null);
//   const [products, setProducts] = useState([]);
//   const [selectedProduct, setSelectedProduct] = useState(null);

//   const fetchProducts = async () => {
//     const productsRef = collection(db, 'stores', storeId, 'products');
//     const snapshot = await getDocs(productsRef);
//     setProducts(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
//   };

//   const fetchDiscounts = async () => {
//     const now = Timestamp.now();
//     const queryRef = query(
//       collection(db, 'stores', storeId, 'discounts'),
//       where('endDate', '>', now),
//       orderBy('endDate', 'asc')
//     );
//     const snapshot = await getDocs(queryRef);
//     setDiscounts(
//       snapshot.docs.map((doc) => ({
//         id: doc.id,
//         ...doc.data(),
//         startDate: doc.data().startDate.toDate(),
//         endDate: doc.data().endDate.toDate(),
//       }))
//     );
//   };
//   useEffect(() => {
//     fetchProducts();
//   }, [storeId]);

//   useEffect(() => {
//     fetchDiscounts();
//   }, [storeId]);

//   const handleAddProduct = () => {
//     if (
//       selectedProduct &&
//       !applicableProducts.some((p) => p.id === selectedProduct.id)
//     ) {
//       setApplicableProducts((prev) => [...prev, selectedProduct]);
//       setSelectedProduct(null); // Clear selection after adding
//     } else {
//       MessageBoxInform('이 상품은 이미 추가되었습니다.');
//     }
//   };
//   const handleRemoveProduct = (productId) => {
//     setApplicableProducts(applicableProducts.filter((p) => p.id !== productId));
//   };
//   const handleOpenModal = () => {
//     setModalOpen(true);
//   };

//   const handleCloseModal = () => {
//     setModalOpen(false);
//   };

//   const handleFormSubmit = (e) => {
//     e.preventDefault();
//   };

//   function generateDiscountCode(length = 6) {
//     const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
//     let result = '';
//     for (let i = 0; i < length; i++) {
//       result += chars.charAt(Math.floor(Math.random() * chars.length));
//     }
//     return result;
//   }

//   const handleIssueDiscount = async () => {
//     // Check if the required values are set

//     if (!discountType) {
//       await MessageBoxInform('할인 유형을 선택해주세요.');
//       return;
//     }

//     const effectiveMinPurchase = isCustomInput
//       ? customAmount
//       : minPurchaseAmount;

//     if (!effectiveMinPurchase || effectiveMinPurchase <= 0) {
//       await MessageBoxInform('할인 가능한 최소 구매 금액을 설정해주세요.');
//       return;
//     }

//     if (!discount || !startDate || !endDate) {
//       setError('필요한 정보를 모두 입력해주세요.');
//       return;
//     }

//     if (new Date(startDate) >= new Date(endDate)) {
//       setError('시작 시간은 종료 시간보다 이전이어야 합니다.');
//       return;
//     }

//     //setIsIssuing(true);
//     const discountCode = generateDiscountCode();
//     const applicableProductIds = applicableProducts.map(
//       (product) => product.id
//     );
//     const discountData = {
//       targetProducts: allProducts ? 'all' : applicableProductIds,
//       discountType: discountType,
//       minPurchaseAmount: effectiveMinPurchase,
//       discount,
//       discountCode,
//       discountName,
//       issuedAt: new Date(),
//       applicableProducts: applicableProducts.map((product) => ({
//         ...product,
//       })),
//       startDate: new Date(startDate),
//       endDate: new Date(endDate),
//     };
//     addDiscount(discountData);
//     applicableProducts.forEach((product) =>
//       applyDiscount(storeId, product.id, discountData)
//     );
//   };
//   async function addDiscount(discountData) {
//     try {
//       const discountsCollectionRef = collection(
//         db,
//         'stores',
//         storeId,
//         'discounts'
//       );

//       addDoc(discountsCollectionRef, discountData)
//         .then(() => {
//           console.log('할인이 등록되었습니다.');
//           handleCloseModal();
//           fetchDiscounts();
//         })
//         .catch((error) => {
//           console.error('Error adding document: ', error);
//         });
//     } catch (error) {
//       console.error('Error adding document: ', error);
//     }
//   }
//   async function applyDiscount(storeId, productId, discountDetails) {
//     const productRef = doc(db, 'stores', storeId, 'products', productId);

//     try {
//       await updateDoc(productRef, {
//         discountCode: discountDetails.discountCode,
//         //discoutType: discountDetails.discountType,
//         //discountPrice: discountDetails.discountPrice,
//         //discountStartDate: Timestamp.fromDate(new Date(discountDetails.discountStartDate)),
//         //discountEndDate: Timestamp.fromDate(new Date(discountDetails.discountEndDate))
//       });
//       console.log('Discount applied successfully to product:', productId);
//     } catch (error) {
//       console.error('Error applying discount:', error);
//     }
//   }
//   return (
//     <div>
//       <Typography variant="h4">할인 관리 시스템</Typography>

//       <Grid container spacing={2}>
//         {/* 할인 목록 표시 */}
//         <Grid item xs={12}>
//           <DiscountList discounts={discounts} />
//         </Grid>
//       </Grid>

//       <Box mt={2} display="flex" justifyContent="flex-end" pr={2}>
//         {/* 할인 생성 버튼, 아이콘 추가로 더 명확하게 표시 */}
//         <Button
//           variant="contained"
//           color="primary"
//           startIcon={<AddIcon />}
//           onClick={handleOpenModal}
//         >
//           할인 생성
//         </Button>
//       </Box>
//       {MessageBoxRender()}
//       <Dialog open={isModalOpen} onClose={handleCloseModal}>
//         <DialogTitle>할인 생성</DialogTitle>
//         <DialogContent>
//           <TextField
//             autoFocus
//             margin="dense"
//             id="name"
//             label="할인 이름"
//             type="text"
//             fullWidth
//             variant="outlined"
//             value={discountName}
//             onChange={(e) => setDiscountName(e.target.value)}
//           />
//           <FormControlLabel
//             control={
//               <Checkbox
//                 checked={allProducts}
//                 onChange={() => setAllProducts(!allProducts)}
//               />
//             }
//             label="전체 제품 대상"
//           />
//           {!allProducts && (
//             <FormControl fullWidth margin="normal">
//               <InputLabel id="product-select-label">상품 선택</InputLabel>
//               <Select
//                 labelId="product-select-label"
//                 value={selectedProduct ? selectedProduct.id : ''}
//                 label="상품 선택"
//                 onChange={(e) =>
//                   setSelectedProduct(
//                     products.find((p) => p.id === e.target.value)
//                   )
//                 }
//               >
//                 {products.map((product) => (
//                   <MenuItem key={product.id} value={product.id}>
//                     {product.productName}
//                   </MenuItem>
//                 ))}
//               </Select>
//               <Button onClick={handleAddProduct} sx={{ mt: 2 }}>
//                 할인 상품 추가
//               </Button>
//               <List dense>
//                 {applicableProducts.map((product) => (
//                   <ListItem
//                     key={product.id}
//                     secondaryAction={
//                       <IconButton
//                         edge="end"
//                         aria-label="delete"
//                         onClick={() => handleRemoveProduct(product.id)}
//                       >
//                         <DeleteIcon />
//                       </IconButton>
//                     }
//                   >
//                     <ListItemText primary={product.productName} />
//                   </ListItem>
//                 ))}
//               </List>
//             </FormControl>
//           )}
//           <FormControl fullWidth>
//             <InputLabel id="discount-type-label">할인 유형</InputLabel>
//             <Select
//               labelId="discount-type-label"
//               id="discount-type"
//               value={discountType}
//               label="할인 유형"
//               onChange={(e) => setDiscountType(e.target.value)}
//             >
//               <MenuItem value="percentage">퍼센트 할인</MenuItem>
//               <MenuItem value="amount">금액 할인</MenuItem>
//             </Select>
//           </FormControl>
//           <TextField
//             margin="dense"
//             id="discount"
//             label={
//               discountType === 'percentage' ? '할인율 (%)' : '할인 금액 (원)'
//             }
//             type="number"
//             fullWidth
//             variant="outlined"
//             value={discount}
//             onChange={(e) => setDiscount(Math.max(0, parseInt(e.target.value)))}
//           />
//           <TextField
//             margin="dense"
//             id="min-purchase-amount"
//             label="최소 구매 금액"
//             type="number"
//             fullWidth
//             variant="outlined"
//             value={isCustomInput ? customAmount : minPurchaseAmount}
//             onChange={(e) => {
//               if (e.target.value === 'custom') {
//                 setIsCustomInput(true);
//               } else {
//                 setIsCustomInput(false);
//                 setMinPurchaseAmount(e.target.value);
//               }
//             }}
//             select
//           >
//             <MenuItem value="5000">5,000원</MenuItem>
//             <MenuItem value="10000">10,000원</MenuItem>
//             <MenuItem value="15000">15,000원</MenuItem>
//             <MenuItem value="custom">직접 입력</MenuItem>
//           </TextField>
//           {isCustomInput && (
//             <TextField
//               margin="dense"
//               id="custom-amount"
//               label="사용자 정의 금액 (원)"
//               type="number"
//               fullWidth
//               variant="outlined"
//               value={customAmount}
//               onChange={(e) => setCustomAmount(e.target.value)}
//             />
//           )}
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleCloseModal} color="primary">
//             닫기
//           </Button>
//           <Button onClick={handleIssueDiscount} color="secondary">
//             발급
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </div>
//   );
// };

// export default DiscountManagement;

import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  db,
  collection,
  getDocs,
  orderBy,
  where,
  query,
  Timestamp,
  doc,
  addDoc,
  updateDoc,
  deleteDoc,
} from '../../../firebaseConfig';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Select,
  InputLabel,
  FormControl,
  Grid,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import useMessageBox from '../../../Common/useMessageBox';
import DiscountList from './DiscountList';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

const DiscountManagement = ({ storeId }) => {
  const { t, i18n } = useTranslation();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const [MessageBoxInform, MessageBoxConfirm, MessageBoxRender] =
    useMessageBox();
  const [discountType, setDiscountType] = useState('percentage');
  const [minPurchaseAmount, setMinPurchaseAmount] = useState(5000); // default value for min purchase amount
  const [customAmount, setCustomAmount] = useState('');
  const [isCustomInput, setIsCustomInput] = useState(false);
  const [discountName, setDiscountName] = useState('');
  const [isModalOpen, setModalOpen] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [discounts, setDiscounts] = useState([]); // Added this line to define the discounts state
  const [allProducts, setAllProducts] = useState(false);
  const [applicableProducts, setApplicableProducts] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isIssuing, setIsIssuing] = useState(false);

  // const fetchProducts = async () => {
  //   const productsRef = collection(db, 'stores', storeId, 'products');
  //   const snapshot = await getDocs(productsRef);
  //   setProducts(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
  // };

  // const fetchDiscounts = async () => {
  //   const now = Timestamp.now();
  //   const queryRef = query(
  //     collection(db, 'stores', storeId, 'discounts'),
  //     where('endDate', '>', now),
  //     orderBy('endDate', 'asc')
  //   );
  //   const snapshot = await getDocs(queryRef);
  //   setDiscounts(
  //     snapshot.docs.map((doc) => ({
  //       id: doc.id,
  //       ...doc.data(),
  //       startDate: doc.data().startDate.toDate(),
  //       endDate: doc.data().endDate.toDate(),
  //     }))
  //   );
  // };
  const fetchProducts = useCallback(async () => {
    try {
      const productsQuery = query(
        collection(db, 'stores', storeId, 'products'),
        orderBy('createDate', 'asc')
      );
      const productsSnapshot = await getDocs(productsQuery);
      const productsData = productsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setProducts(productsData);
    } catch (error) {
      console.error('Error fetching products:', error);
      setError('Failed to fetch products.');
    }
  }, [storeId]);

  const fetchDiscounts = useCallback(async () => {
    try {
      const now = Timestamp.now();
      const queryRef = query(
        collection(db, 'stores', storeId, 'discounts'),
        where('endDate', '>', now),
        orderBy('endDate', 'asc')
      );
      const snapshot = await getDocs(queryRef);
      const discountsData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        startDate: doc.data().startDate.toDate(),
        endDate: doc.data().endDate.toDate(),
      }));
      setDiscounts(discountsData);
    } catch (error) {
      if (
        error.code === 'failed-precondition' ||
        error.code === 'unavailable'
      ) {
        console.error('Firestore index error:', error.message);
        setError('Firestore index error. Please create a Firestore index.');
      } else {
        console.error('Error fetching discounts:', error);
        setError('Failed to fetch discounts.');
      }
    }
  }, [storeId]);
  // useEffect(() => {
  //   fetchDiscounts();
  // }, [storeId]);

  // useEffect(() => {
  //   fetchProducts();
  // }, [storeId]);
  useEffect(() => {
    if (storeId) {
      setLoading(true);
      const fetchData = async () => {
        await Promise.all([fetchDiscounts(), fetchProducts()]);
        setLoading(false);
      };
      fetchData();
    } else {
      console.error('Store ID is undefined');
      setLoading(false);
      setError('Store ID is required.');
    }
  }, [storeId, fetchDiscounts, fetchProducts]);

  function generateDiscountCode(length = 6) {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
  }

  const handleAddProduct = () => {
    if (
      selectedProduct &&
      !applicableProducts.some((p) => p.id === selectedProduct.id)
    ) {
      setApplicableProducts((prev) => [...prev, selectedProduct]);
      setSelectedProduct(null); // Clear selection after adding
    } else if (!selectedProduct) {
      MessageBoxInform(t('pleaseSelectProduct'));
    } else {
      MessageBoxInform(t('productAlreadyAdded'));
    }
  };

  const handleRemoveProduct = (productId) => {
    setApplicableProducts(applicableProducts.filter((p) => p.id !== productId));
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
  };

  const handleIssueDiscount = async () => {
    // Check if the required values are set

    if (!discountType) {
      MessageBoxInform(t('selectDiscountType'));
      return;
    }

    const effectiveMinPurchase = isCustomInput
      ? customAmount
      : minPurchaseAmount;

    if (!effectiveMinPurchase || effectiveMinPurchase <= 0) {
      MessageBoxInform(t('setMinimumPurchaseAmount'));
      return;
    }

    if (!discount || !startDate || !endDate) {
      setError(t('enterAllRequiredInfo'));
      return;
    }

    if (new Date(startDate) >= new Date(endDate)) {
      setError(t('startDateBeforeEndDate'));
      return;
    }

    setIsIssuing(true);
    const discountCode = generateDiscountCode();
    const applicableProductIds = applicableProducts.map(
      (product) => product.id
    );
    const discountData = {
      targetProducts: allProducts ? 'all' : applicableProductIds,
      discountType: discountType,
      minPurchaseAmount: effectiveMinPurchase,
      discount,
      discountCode,
      discountName,
      issuedAt: new Date(),
      applicableProducts: applicableProducts.map((product) => ({
        ...product,
      })),
      startDate: new Date(startDate),
      endDate: new Date(endDate),
    };
    addDiscount(discountData);
    applicableProducts.forEach((product) =>
      applyDiscount(storeId, product.id, discountData.discountCode)
    );
  };

  const addApplicableProduct = async () => {
    if (selectedProduct && selectedProduct.id) {
      // Check if the product is already in the applicableProducts array
      if (
        !applicableProducts.some((product) => product.id === selectedProduct.id)
      ) {
        setApplicableProducts((prev) => [
          ...prev,
          { id: selectedProduct.id, name: selectedProduct.productName },
        ]);
        setSelectedProduct(null); // Reset the selected product
      } else {
        MessageBoxInform(t('productAlreadyAdded')); // This product is already added.
      }
    }
  };

  async function addDiscount(discountData) {
    try {
      const discountsCollectionRef = collection(
        db,
        'stores',
        storeId,
        'discounts'
      );

      addDoc(discountsCollectionRef, discountData)
        .then(async () => {
          console.log(t('discountAdded'));
          await MessageBoxInform(t('discountAdded'));
          handleCloseModal();
          fetchDiscounts();
        })
        .catch((error) => {
          console.error('Error adding document: ', error);
        });
    } catch (error) {
      console.error('Error adding document: ', error);
    }
  }

  async function applyDiscount(storeId, productId, discountCode) {
    const productRef = doc(db, 'stores', storeId, 'products', productId);

    try {
      await updateDoc(productRef, {
        discountCode,
      });
      console.log('Discount applied successfully to product:', productId);
    } catch (error) {
      console.error('Error applying discount:', error);
    }
  }

  const resetFields = () => {
    setProducts({ name: '', id: null });
    setDiscount('');
    setAllProducts(false);
    setApplicableProducts([]);
    setStartDate('');
    setEndDate('');
    setError(null);
  };

  const handleDeleteCoupon = async (discountId) => {
    const isConfirmed = await MessageBoxConfirm(t('confirmDeleteDiscount'));
    if (isConfirmed) {
      try {
        // Create a reference to the specific coupon document
        const discountRef = doc(db, 'stores', storeId, 'discounts', discountId);
        // Delete the document
        await deleteDoc(discountRef);
        // Update local state to reflect the change
        setDiscounts(
          discounts.filter((discount) => discount.id !== discountId)
        );
        // Show confirmation message
        await MessageBoxInform(t('discountDeletedSuccessfully'));
      } catch (error) {
        console.error('Error deleting coupon:', error);
        await MessageBoxInform(t('errorDeletingDiscount'));
      }
    }
  };

  const handleProductSelect = (e) => {
    const selected = products.find((product) => product.id === e.target.value);
    setSelectedProduct(selected);

    const productId = e.target.value;
    const chosenProduct = products.find((product) => product.id === productId);
    setSelectedProduct(chosenProduct);
    setProducts({ ...products, name: chosenProduct?.productName });
  };

  return (
    <div>
      <Typography variant="h4">{t('discountManagementSystem')}</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DiscountList
            discounts={discounts}
            onDelete={handleDeleteCoupon}
            isSeller={currentUser?.uid === storeId}
          />
        </Grid>
      </Grid>

      <Box mt={2} display="flex" justifyContent="flex-end" pr={2}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleOpenModal}
        >
          {t('addDiscount')}
        </Button>
      </Box>
      {MessageBoxRender()}
      <Dialog open={isModalOpen} onClose={handleCloseModal}>
        <DialogTitle>{t('addDiscount')}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label={t('discountName')}
            type="text"
            fullWidth
            variant="outlined"
            value={discountName || ''}
            onChange={(e) => setDiscountName(e.target.value)}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel id="discount-type-label">
              {t('discountType')}
            </InputLabel>
            <Select
              labelId="discount-type-label"
              value={discountType || ''}
              onChange={(e) => {
                setDiscountType(e.target.value);
                if (e.target.value === 'percentage') {
                  setIsCustomInput(false); // Reset custom input if switch to percentage
                }
              }}
            >
              <MenuItem value="percentage">{t('percentageDiscount')}</MenuItem>
              <MenuItem value="amount">{t('amountDiscount')}</MenuItem>
            </Select>
          </FormControl>
          {discountType === 'amount' && (
            <FormControl fullWidth margin="normal">
              <InputLabel id="min-purchase-amount-label">
                {t('minPurchaseAmount')}
              </InputLabel>
              <Select
                labelId="min-purchase-amount-label"
                value={isCustomInput ? 'custom' : minPurchaseAmount}
                onChange={(e) => {
                  if (e.target.value === 'custom') {
                    setIsCustomInput(true);
                  } else {
                    setIsCustomInput(false);
                    setMinPurchaseAmount(e.target.value);
                  }
                }}
              >
                <MenuItem value="5000">
                  {t('currencyFormat', { value: 5000 })}
                </MenuItem>
                <MenuItem value="10000">
                  {t('currencyFormat', { value: 10000 })}
                </MenuItem>
                <MenuItem value="15000">
                  {t('currencyFormat', { value: 15000 })}
                </MenuItem>
                <MenuItem value="custom">{t('customInput')}</MenuItem>
              </Select>
              {isCustomInput && (
                <TextField
                  margin="dense"
                  id="custom-amount"
                  label={t('customAmount')}
                  type="number"
                  fullWidth
                  value={customAmount || ''}
                  onChange={(e) => setCustomAmount(e.target.value)}
                />
              )}
            </FormControl>
          )}
          <FormControlLabel
            control={
              <Checkbox
                checked={allProducts}
                onChange={() => setAllProducts(!allProducts)}
              />
            }
            label={t('allProducts')}
          />
          {!allProducts && (
            <FormControl fullWidth margin="normal">
              <InputLabel id="product-select-label">
                {t('selectProduct')}
              </InputLabel>
              <Select
                labelId="product-select-label"
                value={selectedProduct ? selectedProduct.id : ''}
                onChange={(e) =>
                  setSelectedProduct(
                    products.find((p) => p.id === e.target.value)
                  )
                }
              >
                {products.length > 0 ? (
                  products.map((product) => (
                    <MenuItem key={product.id} value={product.id}>
                      {product.productName}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>{t('loadingProducts')}</MenuItem>
                )}
              </Select>
              <Button onClick={handleAddProduct} sx={{ mt: 2 }}>
                {t('addDiscountProduct')}
              </Button>
              <List dense>
                {applicableProducts.length > 0 ? (
                  applicableProducts.map((product) => (
                    <ListItem
                      key={product.id}
                      secondaryAction={
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => handleRemoveProduct(product.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText primary={product.productName} />
                    </ListItem>
                  ))
                ) : (
                  <ListItem>
                    <ListItemText primary={t('noApplicableProducts')} />
                  </ListItem>
                )}
              </List>
            </FormControl>
          )}
          <TextField
            margin="dense"
            id="discount"
            label={
              discountType === 'percentage'
                ? t('discountRate')
                : t('discountAmount')
            }
            type="number"
            fullWidth
            variant="outlined"
            value={discount || ''}
            onChange={(e) =>
              setDiscount(Math.max(0, parseInt(e.target.value, 10)))
            }
          />
          {/* Date and Time Pickers for Start and End Date */}
          <TextField
            margin="dense"
            id="start-date"
            label={t('startDate')}
            type="datetime-local"
            fullWidth
            variant="outlined"
            value={startDate || ''}
            onChange={(e) => setStartDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            margin="dense"
            id="end-date"
            label={t('endDate')}
            type="datetime-local"
            fullWidth
            variant="outlined"
            value={endDate || ''}
            onChange={(e) => setEndDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          {error && <p style={{ color: 'red' }}>{error}</p>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            {t('close')}
          </Button>
          <Button onClick={handleIssueDiscount} color="secondary">
            {t('issue')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DiscountManagement;
