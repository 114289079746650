// import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import { db, doc, getDoc, updateDoc, setDoc } from '../../firebaseConfig';
// import {
//   Container,
//   Typography,
//   CircularProgress,
//   List,
//   ListItem,
//   ListItemText,
//   Box,
// } from '@mui/material';
// import { useTranslation } from 'react-i18next';

// function UserSignUpConfirm({ setIsStepCompleted }) {
//   const { t, i18n } = useTranslation();
//   const currentUser = useSelector((state) => state.auth.currentUser);
//   const [pendingAllData, setPendingAllData] = useState(null);
//   const [isLoading, setIsLoading] = useState(true);

//   useEffect(() => {
//     fetchAndUpdateUser();
//   }, [currentUser]);

//   const fetchAndUpdateUser = async () => {
//     setIsLoading(true);
//     try {
//       const userRef = doc(db, 'users', currentUser.uid);
//       const userDoc = await getDoc(userRef);
//       if (userDoc.exists() && userDoc.data().pendingRegistrations) {
//         const pendingData = userDoc.data().pendingRegistrations;
//         setPendingAllData(pendingData);
//         await updateDoc(userRef, {
//           'pendingRegistrations.step': 'signupCompleted',
//         });
//         await saveUser(pendingData);
//       }
//     } catch (error) {
//       console.error(t('errorFetchingAndUpdatingUser'), error);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const saveUser = async (pendingData) => {
//     try {
//       const userRef = doc(db, 'users', currentUser.uid);
//       await setDoc(
//         userRef,
//         { ...pendingData, userId: currentUser.uid },
//         { merge: true }
//       );
//       setIsStepCompleted(true);
//     } catch (error) {
//       console.error(t('errorUpdatingUser'), error);
//     }
//   };

//   if (isLoading) {
//     return (
//       <Container>
//         <CircularProgress />
//       </Container>
//     );
//   }

//   return (
//     <Container>
//       <Typography variant="h4" sx={{ mb: 2 }}>
//         {t('finalConfirmation')}
//       </Typography>
//       <Typography paragraph>{t('hereAreTheDetails')}</Typography>
//       {pendingAllData ? (
//         <List dense>
//           <ListItem>
//             <ListItemText primary={t('name')} secondary={pendingAllData.name} />
//           </ListItem>
//           <ListItem>
//             <ListItemText
//               primary={t('email')}
//               secondary={pendingAllData.email}
//             />
//           </ListItem>
//           <ListItem>
//             <ListItemText
//               primary={t('phoneNumber')}
//               secondary={pendingAllData.phoneNumber}
//             />
//           </ListItem>
//           <ListItem>
//             <ListItemText
//               primary={t('address')}
//               secondary={pendingAllData.address}
//             />
//           </ListItem>
//         </List>
//       ) : (
//         <Typography>{t('loadingData')}</Typography>
//       )}
//     </Container>
//   );
// }

// export default UserSignUpConfirm;
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { db, doc, getDoc, updateDoc } from '../firebaseConfig';
import {
  Container,
  Typography,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Box,
  Alert,
  Paper,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

function UserSignUpConfirm({ setIsStepCompleted }) {
  const { t, i18n } = useTranslation();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const [userAllData, setUserAllData] = useState(null);
  const [selectedTown, setSelectedTown] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchAndUpdateUser();
  }, [currentUser]);

  const fetchAndUpdateUser = async () => {
    if (!currentUser) {
      return;
    }

    setIsLoading(true);
    setError('');
    try {
      const userRef = doc(db, 'users', currentUser.uid);
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        setUserAllData(userData);
        setSelectedTown(userData.selectedTown);
        await updateDoc(userRef, {
          step: 'signupCompleted',
          modifiedAt: new Date(),
        });
      } else {
        setError(t('userDocumentNotExist'));
      }
    } catch (error) {
      console.error(t('errorFetchingAndUpdatingUser'), error);
      setError(t('errorFetchingAndUpdatingUser'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleTownChange = async (event, newTown) => {
    if (newTown !== null) {
      setSelectedTown(newTown);
      try {
        const userRef = doc(db, 'users', currentUser.uid);
        await updateDoc(userRef, {
          selectedTown: newTown,
          modifiedAt: new Date(),
        });
      } catch (error) {
        console.error(t('errorUpdatingSelectedTown'), error);
        setError(t('errorUpdatingSelectedTown'));
      }
    }
  };

  if (isLoading) {
    return (
      <Container>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
        <Typography align="center" sx={{ mt: 2 }}>
          {t('loadingData')}
        </Typography>
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Alert severity="error" sx={{ mt: 4 }}>
          {error}
        </Alert>
      </Container>
    );
  }

  return (
    <Container>
      <Paper elevation={3} sx={{ p: 3, mt: 4 }}>
        <Typography variant="h4" sx={{ mb: 2 }}>
          {t('finalConfirmation')}
        </Typography>
        <Typography paragraph>{t('hereAreTheDetails')}</Typography>
        {userAllData ? (
          <List dense>
            <ListItem>
              <ListItemText primary={t('name')} secondary={userAllData.name} />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={t('email')}
                secondary={userAllData.email}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={t('phoneNumber')}
                secondary={userAllData.phoneNumber}
              />
            </ListItem>
            <Box mt={4}>
              <Typography variant="h6" gutterBottom>
                {t('myTown.myTown')}
              </Typography>
              <Typography paragraph>
                {t('myTown.changeTownDescription')}
              </Typography>
              <ToggleButtonGroup
                value={selectedTown}
                exclusive
                onChange={handleTownChange}
                aria-label="town selection"
                fullWidth
              >
                <ToggleButton value={1} aria-label="town1">
                  {userAllData.town1 ? userAllData.town1.name : t('myTown.add')}
                </ToggleButton>
                <ToggleButton value={2} aria-label="town2">
                  {userAllData.town2 ? userAllData.town2.name : t('myTown.add')}
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </List>
        ) : (
          <Typography>{t('noPendingData')}</Typography>
        )}
      </Paper>
    </Container>
  );
}

export default UserSignUpConfirm;
