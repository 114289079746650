import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import {
  db,
  getDoc,
  setDoc,
  updateDoc,
  updateProfile,
  doc,
  auth,
  functions,
  getKakaoToken,
  signInWithCustomToken,
  linkWithCredential,
  EmailAuthProvider,
  serverTimestamp,
} from './firebaseConfig';
// import { httpsCallable } from 'firebase/functions';
import useMessageBox from './Common/useMessageBox';
import axios from 'axios';

function NaverRedirect() {
  const [MessageBoxInform, MessageBoxConfirm, MessageBoxRender] =
    useMessageBox();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    async function authenticateUser() {
      const authorizationCode = getParamAuthorizationCode(location.search);

      if (!authorizationCode) {
        console.error('Authorization code not found');
        await MessageBoxInform('Authorization code not found');
        return;
      }
      // await MessageBoxInform("authorizationCode:" + authorizationCode);
      try {
        const naverUserInfo = await fetchNaverToken(authorizationCode);
        // await MessageBoxInform("customToken:" + naverUserInfo.firebaseToken);
        await signInWithFirebase(naverUserInfo);
        navigate('/');
      } catch (error) {
        console.error('Error:', error);
        await MessageBoxInform('Error during authentication:' + error);
        //navigate('/');
      }
    }

    authenticateUser();
  }, [navigate, location.search]);

  function getParamAuthorizationCode(search) {
    const query = new URLSearchParams(search);
    return query.get('code');
  }

  async function fetchNaverToken(authorizationCode) {
    try {
      // await MessageBoxInform("authorizationCode:" + authorizationCode);
      // const getCustomTokenFunction = httpsCallable(functions, 'getCustomToken');

      // const result = await getKakaoToken({ code: authorizationCode });
      const result = await getNaverTokenFireFunc(authorizationCode);

      if (result) {
        return result;
      } else {
        throw new Error('Invalid result from getCustomToken function');
      }
    } catch (error) {
      console.error('Error retrieving custom token:', error);
      await MessageBoxInform('Error during token retrieval: ' + error);
      throw error; // 이 오류를 상위로 전파하여 호출하는 측에서도 처리할 수 있게 합니다.
    }
  }
  const getNaverTokenFireFunc = async (authorizationCode) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_NAVER_ACCESS_TOKEN_FIREFUNC_URL,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            code: authorizationCode,
          }),
        }
      );
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching Naver access token:', error);
      throw error; // or return some default/fallback value
    }
  };

  async function signInWithFirebase(userInfo) {
    try {
      // Sign in with custom token
      const firebaseCustomToken = userInfo.firebaseToken;
      const userCredential = await signInWithCustomToken(
        auth,
        firebaseCustomToken
      );
      const user = userCredential.user;

      await handleUserLogin(user, 'naver.com');
      // await linkNaverAccount(firebaseCustomToken);

      // Check if user has email/password provider
      // const emailProvider = (user.providerData || []).some(provider => provider.providerId === 'password');

      // if (!emailProvider) {
      //     // Prompt user for email and password
      //     const email = prompt("Enter email:");
      //     const password = prompt("Enter password:");

      //     // Create email and password and link to the user
      //     const emailCredential = EmailAuthProvider.credential(email, password);
      //     await linkWithCredential(user, emailCredential);

      //     console.log("Email and password linked successfully");
      // }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const handleUserLogin = async (user, providerId) => {
    const email = user.email;
    const displayName = user.displayName;

    const userRef = doc(db, 'users', user.uid);
    const updates = {
      email: email,
      displayName: displayName,
      providerId: providerId,
      setPassword: false,
    };

    const userDoc = await getDoc(userRef);
    if (userDoc.exists()) {
      await updateDoc(userRef, updates);
    } else {
      await setDoc(userRef, updates, { merge: true });
    }
    await updateProfile(user, {
      displayName: displayName,
      photoURL: user.photoURL,
    });
    // await updateUserProviderData(user, providerId);
    await updateLastLogin(user.uid);
    // Dispatch user data to Redux store
    // dispatch(setUser(user));
    navigate('/');
  };
  // 사용자 로그인 후 providerData 업데이트 함수
  const updateUserProviderData = async (user, providerId) => {
    try {
      await updateProfile(user, {
        displayName: user.displayName,
        photoURL: user.photoURL,
        providerData: [
          {
            providerId: providerId,
            uid: user.uid,
            displayName: user.displayName,
            email: user.email,
            phoneNumber: user.phoneNumber,
            photoURL: user.photoURL,
          },
        ],
      });
      console.log('User providerData updated successfully');
    } catch (error) {
      console.error('Error updating user providerData:', error);
    }
  };
  // 가정: 사용자가 이미 로그인되어 있고, 카카오 로그인으로부터 액세스 토큰을 획득했다고 가정
  async function linkNaverAccount(firebaseCustomToken) {
    try {
      // 백엔드 서버를 통해 카카오 액세스 토큰으로부터 Firebase 사용자 정의 토큰을 획득

      // Firebase 사용자 정의 토큰으로 인증
      const userCredential = await signInWithCustomToken(
        auth,
        firebaseCustomToken
      );

      // 기존 Firebase 계정에 카카오 계정 연결
      const kakaoCredential = auth
        .OAuthProvider('kakao.com')
        .credential(firebaseCustomToken);
      await auth.currentUser.linkWithCredential(kakaoCredential);

      console.log('카카오 계정이 기존 계정에 연결되었습니다.');
    } catch (error) {
      console.error('카카오 계정 연결 오류:', error);
    }
  }
  async function updateLastLogin(userId) {
    const userRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userRef);
    const updates = { lastLogin: serverTimestamp() };
    if (userDoc.exists()) {
      updateDoc(userRef, updates)
        .then(() => {
          console.log('Last login time updated');
        })
        .catch((error) => {
          console.error('Error updating last login time:', error);
        });
    } else {
      setDoc(userRef, updates, { merge: true })
        .then(() => {
          console.log('Last login time updated');
        })
        .catch((error) => {
          console.error('Error updating last login time:', error);
        });
    }
  }
  return (
    <div>
      {MessageBoxRender()}
      Naver 로그인 처리중...
    </div>
  );
}

export default NaverRedirect;
