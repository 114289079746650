import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Avatar, CircularProgress } from '@mui/material';
import { db, doc, getDoc } from './firebaseConfig';
import { useTranslation } from 'react-i18next';
import UserRatings from './UserRating';

function UserProfile() {
  const { t } = useTranslation();
  const { userId } = useParams();
  const [profile, setProfile] = useState(null);
  const [loadingProfile, setLoadingProfile] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const userRef = doc(db, 'users', userId);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          setProfile(userDoc.data());
        } else {
          setError(t('userProfileDetails.userNotFound'));
        }
      } catch (err) {
        setError(t('userProfileDetails.errorFetchingProfile') + err.message);
      } finally {
        setLoadingProfile(false);
      }
    };
    fetchProfile();
  }, [userId, t]);

  if (loadingProfile) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ maxWidth: 480, mx: 'auto', my: 4 }}>
      <Typography variant="h5" component="h2" align="center">
        {profile.email}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          position: 'relative',
          mt: 2,
          mb: 2,
        }}
      >
        <Avatar src={profile.photoURL} sx={{ width: 140, height: 140 }} />
      </Box>
      <Typography variant="h6" component="h2" align="center">
        {profile.displayName}
      </Typography>
      {profile.userType === 2 && (
        <Typography variant="body1" align="center">
          {t('userProfileDetails.storeUser')}
        </Typography>
      )}
      <UserRatings userId={userId} />
    </Box>
  );
}

export default UserProfile;
