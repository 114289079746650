import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, IconButton, Badge, CircularProgress } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useSelector, useDispatch } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { db, collection, getDocs } from './firebaseConfig'; // Adjust the import path as needed
import { updateCartItemsCount } from './Redux/reducers/cartReducer'; // Adjust the import path as needed

const FloatingCartButton = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const currentUser = useSelector((state) => state.auth.currentUser);
  const cartItemsCount = useSelector((state) => state.cart.itemsCount); // Replace with your selector
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [itemsCount, setItemsCount] = useState(0);

  const fetchCartItemsCount = async () => {
    if (!currentUser) return;

    setLoading(true);

    try {
      // Fetch list of stores
      const storesSnapshot = await getDocs(collection(db, 'stores'));
      const storeIds = storesSnapshot.docs.map((doc) => doc.id);

      let totalCartItemsCount = 0;

      // Iterate through each store and fetch cart items count
      for (const storeId of storeIds) {
        const cartCollection = collection(
          db,
          'stores',
          storeId,
          'users',
          currentUser.uid,
          'cart'
        );

        const cartSnapshot = await getDocs(cartCollection);
        totalCartItemsCount += cartSnapshot.size;
      }

      return totalCartItemsCount;
    } catch (error) {
      console.error('Error fetching cart items count:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const loadCartItemsCount = async () => {
      try {
        setLoading(true);
        const count = await fetchCartItemsCount(currentUser?.uid);
        dispatch(updateCartItemsCount(count));
        setItemsCount(count);
      } catch (error) {
        console.error('Error fetching cart items count:', error);
      } finally {
        setLoading(false);
      }
    };

    if (currentUser) {
      loadCartItemsCount();
    }
  }, [currentUser, dispatch]);

  useEffect(() => {
    // Synchronize local state with Redux state
    setItemsCount(cartItemsCount);
  }, [cartItemsCount]);

  if (!currentUser) {
    return null;
  }

  const handleClick = () => {
    navigate(`/allcartlist/${currentUser.uid}`);
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: isMobile ? 120 : 80, // Adjust the distance from the bottom
        right: 20,
        zIndex: 1000,
      }}
    >
      {loading ? (
        <CircularProgress size={24} />
      ) : (
        <IconButton
          onClick={handleClick}
          sx={{
            backgroundColor: 'white',
            boxShadow: 3,
            '&:hover': {
              backgroundColor: 'lightgray',
            },
          }}
        >
          <Badge badgeContent={itemsCount} color="secondary">
            <ShoppingCartIcon />
          </Badge>
        </IconButton>
      )}
    </Box>
  );
};

export default FloatingCartButton;
