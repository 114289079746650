// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { useSelector } from 'react-redux';
// import {
//   db,
//   collection,
//   getDocs,
//   query,
//   where,
//   orderBy,
//   doc,
//   getDoc,
// } from '../../firebaseConfig';
// import {
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   Button,
//   Typography,
// } from '@mui/material';
// import { useTranslation } from 'react-i18next';

// const PrivacyCheck = () => {
//   const navigate = useNavigate();
//   const { t } = useTranslation();
//   const currentUser = useSelector((state) => state.auth.currentUser);
//   const [showAgreementAlert, setShowAgreementAlert] = useState(false);
//   const [alertMessage, setAlertMessage] = useState('');

//   useEffect(() => {
//     if (currentUser) {
//       checkUserAgreementStatus();
//     }
//   }, [currentUser]);

//   const checkUserAgreementStatus = async () => {
//     if (currentUser && currentUser.uid) {
//       const privacyListCollection = collection(db, 'privacy');
//       const privacyListSnapshot = await getDocs(privacyListCollection);
//       const allVersions = privacyListSnapshot.docs.map(
//         (doc) => doc.data().version
//       );
//       const latestVersion = Math.max(...allVersions);

//       const latestPrivacyListDoc = privacyListSnapshot.docs.find(
//         (doc) => doc.data().version === latestVersion
//       );

//       if (!latestPrivacyListDoc) {
//         console.error('No latest privacyList document found');
//         return;
//       }

//       const latestPrivacyListDocId = latestPrivacyListDoc.id;

//       const latestUserPrivacyListCollection = collection(
//         db,
//         'users',
//         currentUser.uid,
//         'privacy',
//         latestPrivacyListDocId,
//         'items'
//       );
//       const latestUserPrivacyListSnapshot = await getDocs(
//         latestUserPrivacyListCollection
//       );

//       if (latestUserPrivacyListSnapshot.empty) {
//         setAlertMessage(t('newPrivacyPolicy'));
//         setShowAgreementAlert(true);
//       } else {
//         const agreements = {};
//         latestUserPrivacyListSnapshot.docs.forEach((itemDoc) => {
//           const itemId = itemDoc.id;
//           agreements[`${latestPrivacyListDocId}_${itemId}`] = true;
//         });

//         const latestPrivacyListData = {
//           ...latestPrivacyListDoc.data(),
//           id: latestPrivacyListDoc.id,
//           items: latestPrivacyListDoc.data().items.map((item, index) => ({
//             ...item,
//             id: `${latestPrivacyListDoc.id}_${index}`,
//           })),
//         };

//         const allRequiredAgreed = latestPrivacyListData.items
//           .filter((item) => item.type === 'required')
//           .every(
//             (item) => agreements[`${latestPrivacyListData.id}_${item.id}`]
//           );

//         if (!allRequiredAgreed) {
//           setAlertMessage(t('agreeToPrivacyPolicy'));
//           setShowAgreementAlert(true);
//         }
//       }
//     }
//   };

//   const handleAgreeClick = () => {
//     setShowAgreementAlert(false);
//     navigate('/privacy_agreement');
//   };

//   return (
//     <Dialog
//       open={showAgreementAlert}
//       onClose={() => setShowAgreementAlert(false)}
//     >
//       <DialogTitle>{t('alert')}</DialogTitle>
//       <DialogContent>
//         <Typography>{alertMessage}</Typography>
//       </DialogContent>
//       <DialogActions>
//         <Button onClick={handleAgreeClick} color="primary">
//           {t('confirm')}
//         </Button>
//         <Button onClick={() => setShowAgreementAlert(false)} color="secondary">
//           {t('cancel')}
//         </Button>
//       </DialogActions>
//     </Dialog>
//   );
// };

// export default PrivacyCheck;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { db, collection, getDocs, doc } from '../../firebaseConfig';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const PrivacyCheck = ({ onAgreementComplete }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const [showAgreementAlert, setShowAgreementAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  useEffect(() => {
    if (currentUser) {
      checkUserAgreementStatus();
    }
  }, [currentUser]);

  const checkUserAgreementStatus = async () => {
    if (currentUser && currentUser.uid) {
      const privacyListCollection = collection(db, 'privacy');
      const privacyListSnapshot = await getDocs(privacyListCollection);
      const allVersions = privacyListSnapshot.docs.map(
        (doc) => doc.data().version
      );
      const latestVersion = Math.max(...allVersions);

      const latestPrivacyListDoc = privacyListSnapshot.docs.find(
        (doc) => doc.data().version === latestVersion
      );

      if (!latestPrivacyListDoc) {
        console.error('No latest privacyList document found');
        return;
      }

      const latestPrivacyListDocId = latestPrivacyListDoc.id;

      const latestUserPrivacyListCollection = collection(
        db,
        'users',
        currentUser.uid,
        'privacy',
        latestPrivacyListDocId,
        'items'
      );
      const latestUserPrivacyListSnapshot = await getDocs(
        latestUserPrivacyListCollection
      );

      if (latestUserPrivacyListSnapshot.empty) {
        setAlertMessage(t('newPrivacyPolicy'));
        setShowAgreementAlert(true);
      } else {
        const agreements = {};
        latestUserPrivacyListSnapshot.docs.forEach((itemDoc) => {
          const itemId = itemDoc.id;
          agreements[`${latestPrivacyListDocId}_${itemId}`] = true;
        });

        const latestPrivacyListData = {
          ...latestPrivacyListDoc.data(),
          id: latestPrivacyListDoc.id,
          items: latestPrivacyListDoc.data().items.map((item, index) => ({
            ...item,
            id: `${latestPrivacyListDoc.id}_${index}`,
          })),
        };

        const allRequiredAgreed = latestPrivacyListData.items
          .filter((item) => item.type === 'required')
          .every(
            (item) => agreements[`${latestPrivacyListData.id}_${item.id}`]
          );

        if (!allRequiredAgreed) {
          setAlertMessage(t('agreeToPrivacyPolicy'));
          setShowAgreementAlert(true);
        } else {
          onAgreementComplete();
        }
      }
    }
  };

  const handleAgreeClick = () => {
    setShowAgreementAlert(false);
    navigate('/privacy_agreement');
  };

  return (
    <Dialog
      open={showAgreementAlert}
      onClose={() => setShowAgreementAlert(false)}
    >
      <DialogTitle>{t('alert')}</DialogTitle>
      <DialogContent>
        <Typography>{alertMessage}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleAgreeClick} color="primary">
          {t('confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

PrivacyCheck.propTypes = {
  onAgreementComplete: PropTypes.func.isRequired,
};

export default PrivacyCheck;
