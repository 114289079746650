// import React, { useState, useEffect, useRef } from 'react';
// import {
//   getAuth,
//   updateProfile,
//   updateEmail,
//   onAuthStateChanged,
// } from 'firebase/auth';
// import './css/UserMenu.css';

// function UserMenu({ user, handleUserProfile, handleLogout }) {
//   const [showMenu, setShowMenu] = useState(false);
//   const menuRef = useRef(null);

//   useEffect(() => {
//     const handleOutsideClick = (event) => {
//       if (menuRef.current && !menuRef.current.contains(event.target)) {
//         setShowMenu(false);
//       }
//     };

//     if (showMenu) {
//       window.addEventListener('mousedown', handleOutsideClick);
//     }

//     return () => {
//       window.removeEventListener('mousedown', handleOutsideClick);
//     };
//   }, [showMenu]);

//   const toggleMenu = () => {
//     setShowMenu(!showMenu);
//   };

//   return (
//     <div className="profile-container" ref={menuRef}>
//       <img
//         className="profile-image"
//         src={user.photoURL || 'https://via.placeholder.com/150'}
//         alt="User Profile"
//         onClick={toggleMenu}
//       />

//       {showMenu && (
//         <div className="profile-popup-menu">
//           <div className="profile-popup-item" onClick={handleUserProfile}>
//             {user.email}
//           </div>

//           <div className="profile-popup-item logout" onClick={handleLogout}>
//             로그아웃
//           </div>
//         </div>
//       )}
//     </div>
//   );
// }

// export default UserMenu;

import React, { useState, useRef } from 'react';
import {
  Avatar,
  Menu,
  MenuItem,
  IconButton,
  Tooltip,
  Typography,
  Divider,
} from '@mui/material';
import {
  getAuth,
  updateProfile,
  updateEmail,
  onAuthStateChanged,
} from 'firebase/auth';
import { useTranslation } from 'react-i18next';

function UserMenu({
  user,
  handleUserProfile,
  handleGoTerms,
  handleGoPrivacy,
  handleLogout,
}) {
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const getUserInitials = (displayName) => {
    if (!displayName) return '';
    const names = displayName.split(' ');
    const initials = names.map((name) => name.charAt(0)).join('');
    return initials.toUpperCase();
  };
  if (!user) {
    return null; // or a fallback UI for when user is not available
  }
  return (
    <div className="profile-container">
      <Tooltip title={t('userSettings')}>
        <IconButton onClick={handleClick}>
          {user.photoURL ? (
            <Avatar
              src={user.photoURL}
              alt={t('userProfile')}
              sx={{ width: 50, height: 50 }}
            />
          ) : (
            <Avatar alt={t('userProfile')} sx={{ width: 50, height: 50 }}>
              {getUserInitials(user.displayName)}
            </Avatar>
          )}
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 3,
          style: { minWidth: '150px' },
        }}
      >
        <MenuItem
          onClick={handleUserProfile}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Typography variant="body1" color="textPrimary" noWrap>
            {user.displayName || t('noNameSet')}
          </Typography>
          <Typography variant="body2" color="textSecondary" noWrap>
            {user.email || t('updateProfile')}
          </Typography>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={handleGoTerms}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          {t('termsOfService')}
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={handleGoPrivacy}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          {t('privacyPolicy')}
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout} style={{ color: 'red' }}>
          {t('logout')}
        </MenuItem>
      </Menu>
    </div>
  );
}

export default UserMenu;
