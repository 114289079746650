// import React, { useState, useEffect } from 'react';
// import { db, auth } from '../../firebaseConfig';
// import { collection, query, where, getDocs } from '../../firebaseConfig';
// import {
//   Container,
//   Typography,
//   List,
//   ListItem,
//   ListItemText,
// } from '@mui/material';

// const Returns = () => {
//   const [returns, setReturns] = useState([]);
//   const user = auth.currentUser;

//   useEffect(() => {
//     const fetchReturns = async () => {
//       if (user) {
//         const q = query(
//           collection(db, 'orders'),
//           where('userId', '==', user.uid),
//           where('status', '==', 'returned')
//         );
//         const querySnapshot = await getDocs(q);
//         setReturns(
//           querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
//         );
//       }
//     };

//     fetchReturns();
//   }, [user]);

//   return (
//     <React.Fragment>
//       <Container>
//         <Typography variant="h4" gutterBottom>
//           반품 목록
//         </Typography>
//         <List>
//           {returns.map((returnItem) => (
//             <ListItem key={returnItem.id}>
//               <ListItemText
//                 primary={`주문번호: ${returnItem.id}`}
//                 secondary={`상품명: ${returnItem.productName} - 반품일: ${returnItem.returnDate}`}
//               />
//             </ListItem>
//           ))}
//         </List>
//       </Container>
//     </React.Fragment>
//   );
// };

// export default Returns;

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  CircularProgress,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  Button,
  Box,
} from '@mui/material';
import {
  db,
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
} from '../../firebaseConfig';
import useOrder from '../StoreAdmin/useOrder';

function Returns() {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { returnOrder } = useOrder(userId);

  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      try {
        const orders = await getAllOrdersForUser(userId, 'delivered');
        setOrders(orders);
        setLoading(false);
      } catch (err) {
        setError(`Failed to fetch orders: ${err.message}`);
        setLoading(false);
      }
    };

    fetchOrders();
  }, [userId]);

  const getAllOrdersForUser = async (userId, status) => {
    try {
      const allOrders = [];
      const storesRef = collection(db, 'stores');
      const storesSnapshot = await getDocs(storesRef);

      for (let doc of storesSnapshot.docs) {
        const storeId = doc.id;
        const ordersRef = collection(db, 'stores', storeId, 'orders');
        const q = query(
          ordersRef,
          where('userId', '==', userId),
          where('orderStatus', '==', status)
        );
        const querySnapshot = await getDocs(q);
        const orders = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          storeName: doc.data().storeName,
        }));
        allOrders.push(...orders);
      }
      return allOrders;
    } catch (error) {
      console.error('Error fetching orders:', error);
      throw error;
    }
  };

  const handleReturnOrder = async (orderData) => {
    setLoading(true);
    try {
      await returnOrder(orderData);
      // Refresh the orders list to reflect the change
      const updatedOrders = await getAllOrdersForUser(userId, 'delivered');
      setOrders(updatedOrders);
    } catch (error) {
      console.error('Error during return:', error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  if (!orders.length) {
    return <Typography variant="h6">No eligible orders for return.</Typography>;
  }

  return (
    <Container>
      <Typography variant="h4" sx={{ my: 2 }}>
        Manage Returns
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Order ID</TableCell>
            <TableCell>Store Name</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map((order) => (
            <TableRow key={order.id}>
              <TableCell>{order.id}</TableCell>
              <TableCell>{order.storeName}</TableCell>
              <TableCell>{order.date}</TableCell>
              <TableCell>{order.orderStatus}</TableCell>
              <TableCell>
                <Button
                  color="error"
                  onClick={() => handleReturnOrder(order)}
                  disabled={loading}
                >
                  Initiate Return
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Container>
  );
}

export default Returns;
