// import React, { useRef, useState, useEffect, useCallback } from 'react';
// import { useSelector } from 'react-redux';
// import {
//   db,
//   storage,
//   collection,
//   addDoc,
//   updateDoc,
//   ref,
//   uploadBytesResumable,
//   getDownloadURL,
// } from '../firebaseConfig';

// import { useTranslation } from 'react-i18next';
// import GoogleMapRegion from '../Common/GoogleMapRegion';
// import { RADIUS, CIRCUMFERENCE } from '../Common/Constants';
// import { useNavigate } from 'react-router-dom';
// import {
//   formatPrice,
//   numberToKorean,
//   getByteLength,
// } from '../Utils/PriceFormatter';
// import useMessageBox from '../Common/useMessageBox';
// import ProductCategorySelector from './ProductCategorySelector';
// import ProductBrandSelector from './ProductBrandSelector';
// import { resizeImage } from '../Utils/ImageHelper';
// import PreviewImage from '../Common/PreviewImage';
// // const PreviewImage = ({ preview, onRemove }) => (
// //   <div className="image-container">
// //     <img src={preview} alt="Preview" className="preview-image" />
// //     <button className="remove-button" onClick={onRemove}>
// //       x
// //     </button>
// //   </div>
// // );

// const StoreProductUpload = () => {
//   const { t, i18n } = useTranslation();
//   const fileInputRef = useRef(null);
//   const [MessageBoxInform, MessageBoxConfirm, MessageBoxRender] =
//     useMessageBox();
//   const currentUser = useSelector((state) => state.auth.currentUser);
//   const navigate = useNavigate();

//   const [hasPromotion, setHasPromotion] = useState(false);
//   const [promotionName, setPromotionName] = useState('');
//   const [promotionContent, setPromotionContent] = useState('');
//   const [promotionStartDate, setPromotionStartDate] = useState('');
//   const [promotionEndDate, setPromotionEndDate] = useState('');

//   const [productName, setProductName] = useState('');
//   const [description, setDescription] = useState('');
//   const [price, setPrice] = useState(0);

//   const [previews, setPreviews] = useState([]); // 여러 이미지의 미리보기 URL을 위한 상태
//   const [koreanPrice, setKoreanPrice] = useState('0');
//   const [showMap, setShowMap] = useState(false);
//   const [selectedArea, setSelectedArea] = useState([]);

//   const [stock, setStock] = useState(0);
//   const [deliveryTimes, setDeliveryTimes] = useState([]);

//   const [selectedCategory, setSelectedCategory] = useState([]);
//   const [selectedBrand, setSelectedBrand] = useState([]);
//   const [selectedFiles, setSelectedFiles] = useState([]);
//   const [fileUrls, setFileUrls] = useState([]);
//   const [uploadProgress, setUploadProgress] = useState([]);
//   const [uploadingFiles, setUploadingFiles] = useState([]);
//   const [completedCount, setCompletedCount] = useState(0);
//   const [totalFileSize, setTotalFileSize] = useState(uploadingFiles.length);
//   const [uploadedFileSize, setUploadedFileSize] = useState(0);

//   const handleSaveArea = (savedArea) => {
//     // savedArea 값을 서버에 저장하는 로직
//     setSelectedArea([...savedArea], () => {
//       console.log('Updated selectedArea:', selectedArea);
//     });
//   };

//   // 가격이 변경될 때 한글로 된 가격도 함께 변경
//   useEffect(() => {
//     setKoreanPrice(numberToKorean(price));
//   }, [price]);

//   const handleStockChange = useCallback((e) => {
//     let stockValue = e.target.value.replace(/[^0-9]/g, '');
//     if (stockValue === '0' || stockValue === '') {
//       stockValue = '0';
//     } else {
//       while (stockValue.startsWith('0')) {
//         stockValue = stockValue.substring(1);
//       }
//     }
//     setStock(stockValue);
//   }, []);
//   // 가격 입력 관리
//   const handlePriceChange = useCallback((e) => {
//     let priceValue = e.target.value.replace(/[^0-9]/g, '');
//     if (priceValue === '0' || priceValue === '') {
//       priceValue = '0';
//     } else {
//       while (priceValue.startsWith('0')) {
//         priceValue = priceValue.substring(1);
//       }
//     }
//     setPrice(priceValue);
//   }, []);

//   const handleDescriptionChange = useCallback(
//     (e) => {
//       if (getByteLength(e.target.value) <= 200) {
//         setDescription(e.target.value);
//       }
//     },
//     [description]
//   );

//   const contentByteSize = getByteLength(description);

//   // 파일 입력 필드 초기화
//   const resetFileInput = () => {
//     if (fileInputRef.current) fileInputRef.current.value = '';
//   };

//   const handleImageChange = useCallback(
//     (event) => {
//       const tmpSelectedFiles = Array.from(event.target.files);

//       if (tmpSelectedFiles.length > process.env.REACT_APP_SEND_IMAGE_MAX_LEN) {
//         MessageBoxInform(
//           `최대 ${process.env.REACT_APP_SEND_IMAGE_MAX_LEN}개의 이미지를 선택할 수 있습니다.`
//         );
//         event.target.value = ''; // 이 부분이 이미지 선택을 취소합니다.
//         return;
//       }

//       const readerPromises = tmpSelectedFiles.map((file) => {
//         return new Promise((resolve, reject) => {
//           const reader = new FileReader();
//           reader.onloadend = () => {
//             resolve(reader.result);
//           };
//           reader.onerror = reject;
//           reader.readAsDataURL(file);
//         });
//       });

//       Promise.all(readerPromises).then((results) => {
//         setSelectedFiles((prevImages) => [...prevImages, ...tmpSelectedFiles]);
//         setPreviews((prevPreviews) => [...prevPreviews, ...results]);
//       });
//     },
//     [previews]
//   );

//   const handleRemoveImage = useCallback((indexToRemove) => {
//     setSelectedFiles((prevImages) =>
//       prevImages.filter((_, index) => index !== indexToRemove)
//     );
//     setPreviews((prevPreviews) =>
//       prevPreviews.filter((_, index) => index !== indexToRemove)
//     );
//   }, []);

//   // const uploadImageFiles = async () => {
//   //   const urls = [];
//   //   setUploadProgress([]); // 업로드 시작 전에 진행 상황 초기화

//   //   const totalSize = Array.from(selectedFiles).reduce(
//   //     (acc, file) => acc + file.size,
//   //     0
//   //   );
//   //   setTotalFileSize(totalSize);

//   //   for (const selectedFile of selectedFiles) {
//   //     const uploadStatus = {
//   //       file: selectedFile,
//   //       progress: 0,
//   //     };

//   //     setUploadingFiles((prev) => [...prev, uploadStatus]);

//   //     const url = await uploadImageFile(selectedFile);
//   //     urls.push(url);
//   //   }

//   //   return urls;
//   // };
//   // const uploadImageFile = async (file) => {
//   //   const fileRef = ref(
//   //     storage,
//   //     `${process.env.REACT_APP_CHAT_FILE_PATH}/${Date.now()}_${file.name}`
//   //   );
//   //   const uploadTask = uploadBytesResumable(fileRef, file);

//   //   uploadTask.on('state_changed', (snapshot) => {
//   //     const fileProgress =
//   //       (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

//   //     // 업로드 진행률 계산
//   //     setUploadingFiles((prev) => {
//   //       return prev.map((item) => {
//   //         if (item.file.name === file.name) {
//   //           return {
//   //             ...item,
//   //             progress: fileProgress,
//   //           };
//   //         }
//   //         return item;
//   //       });
//   //     });
//   //   });

//   //   await new Promise((resolve, reject) => {
//   //     uploadTask
//   //       .then(() => {
//   //         setCompletedCount((prev) => prev + 1);
//   //         setUploadedFileSize((prev) => prev + file.size); // 여기서 전체 업로드된 바이트를 누적합니다.
//   //         resolve();
//   //       })
//   //       .catch(reject);
//   //   });

//   //   return await getDownloadURL(fileRef);
//   // };

//   const uploadImageFiles = async (productId, width, height, prefix) => {
//     let paths = [];

//     for (const file of selectedFiles) {
//       const resizedFile = await resizeImage(file, width, height);
//       const filePath = `${
//         process.env.REACT_APP_USER_IMAGE_PATH
//       }/${productId}/${prefix}${Date.now()}_${file.name}`;
//       const uploadUrl = await uploadImageFile(productId, resizedFile, filePath);
//       paths.push(uploadUrl);
//     }
//     return paths;
//   };

//   const uploadImageFile = async (productId, file, filePath) => {
//     const fileRef = ref(storage, filePath);
//     const metadata = {
//       customMetadata: {
//         productId: productId,
//       },
//     };

//     try {
//       const uploadTask = uploadBytesResumable(fileRef, file, metadata);

//       uploadTask.on('state_changed', (snapshot) => {
//         const progress =
//           (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
//         updateProgress(file.name, progress);
//       });

//       await uploadTask;

//       // 업로드가 완료되면 상태를 업데이트합니다.
//       setCompletedCount((prev) => prev + 1);
//       setUploadedFileSize((prev) => prev + file.size);
//       const downloadURL = await getDownloadURL(fileRef);
//       return downloadURL;
//     } catch (error) {
//       console.error('Upload failed:', error);
//       throw new Error('Failed to upload image');
//     }
//   };
//   function updateProgress(fileName, progress) {
//     setUploadingFiles((prev) =>
//       prev.map((item) =>
//         item.file.name === fileName ? { ...item, progress: progress } : item
//       )
//     );
//   }

//   // const handleUpload = async () => {
//   //   try {
//   //     await addDoc(collection(db, 'products'), {
//   //       productName,
//   //       description,
//   //       price,
//   //       stock,
//   //       selectedArea,
//   //       deliveryTimes,
//   //     });
//   //     MessageBoxInform('Product uploaded successfully!');
//   //     resetFields();
//   //   } catch (error) {
//   //     console.error("Error adding document: ", error);
//   //     MessageBoxInform("Error uploading product.");
//   //   }
//   // };

//   // const resetFields = () => {
//   //   setProductName('');
//   //   setDescription('');
//   //   setPrice(0);
//   //   setStock(0);
//   //   setSelectedArea([]);
//   //   setDeliveryTimes([]);
//   // };
//   const handleUpload = useCallback(async () => {
//     // Helper function for input validation
//     const showAlertIfEmpty = (value, message) => {
//       if (!value.trim()) {
//         MessageBoxInform(message);
//         throw new Error(message); // To halt further execution
//       }
//     };

//     try {
//       showAlertIfEmpty(productName, t('enterProductName'));
//       showAlertIfEmpty(description, t('enterDescription'));
//       showAlertIfEmpty(stock, t('enterStock'));
//       showAlertIfEmpty(price, t('enterPrice'));
//       if (!selectedArea || selectedArea.length === 0) {
//         await MessageBoxInform(t('selectArea'));
//         throw new Error(t('selectArea'));
//       }
//       if (!selectedCategory || selectedCategory.length === 0) {
//         MessageBoxInform(t('selectCategory'));
//         throw new Error(t('selectCategory'));
//       }

//       // if (!selectedBrand || selectedBrand.length === 0) {
//       //   MessageBoxInform("브랜드를 선택해주세요.");
//       //   throw new Error("브랜드를 선택해주세요.");
//       // }

//       if (!selectedFiles.length) {
//         await MessageBoxInform(t('uploadImages'));
//         throw new Error(t('uploadImages'));
//       }

//       const isConfirmed = await MessageBoxConfirm(
//         t('confirmStoreUpload'),
//         `${t('title')}: ${productName}\n${t('content')}: ${description}\n${t(
//           'stock'
//         )}: ${stock}\n${t('price')}: ${formatPrice(price)} (${koreanPrice} 원)`
//       );

//       if (!isConfirmed) return;
//       // 진행률 초기화

//       const productData = {
//         productName,
//         categoryId:
//           selectedCategory && selectedCategory.length > 0
//             ? selectedCategory.id
//             : null,
//         brandId:
//           selectedBrand && selectedBrand.length > 0 ? selectedBrand.id : null,
//         price,
//         description,
//         stock: stock,
//         createDate: new Date(),
//         uid: currentUser.uid,
//         likes: 0,
//         views: 0,
//         chats: 0,
//         state: 0,
//         selectedArea: selectedArea,
//         // promotionStartDate: promotionStartDate ? new Date(promotionStartDate) : null,
//         // promotionEndDate: promotionEndDate ? new Date(promotionEndDate) : null,
//         // promotionContent,
//         // promotionName,
//       };

//       await addProductToStore(currentUser.uid, productData);

//       setUploadingFiles([]);
//       setSelectedFiles([]); // 파일들이 모두 업로드되면 상태 초기화
//       setUploadProgress([]); // Reset progress for all files
//       setTotalFileSize(0);
//       setUploadedFileSize(0);
//       setPreviews([]);
//       resetFileInput();

//       alert(t('storeProductUploaded'));
//       setShowMap(false);
//       navigate(`/mystore/${currentUser.uid}`);
//     } catch (error) {
//       console.error('Error:', error);
//     }
//   }, [
//     productName,
//     description,
//     selectedFiles,
//     formatPrice,
//     koreanPrice,
//     selectedArea,
//   ]);

//   const addProductToStore = async (storeId, productData) => {
//     try {
//       const productRef = await addDoc(
//         collection(db, 'stores', storeId, 'products'),
//         productData
//       );
//       console.log('Product added successfully!');

//       const imageSizes = [
//         {
//           width: 1920,
//           height: 1080,
//           collectionName: 'xlarge_images',
//           prefix: 'xlarge_',
//         }, // 매우 큰 화면용
//         {
//           width: 1366,
//           height: 768,
//           collectionName: 'large_images',
//           prefix: 'large_',
//         }, // 노트북 대형 화면용
//         {
//           width: 854,
//           height: 480,
//           collectionName: 'medium_images',
//           prefix: 'medium_',
//         }, // 일반적인 데스크탑 화면용
//         {
//           width: 640,
//           height: 360,
//           collectionName: 'small_images',
//           prefix: 'small_',
//         }, // 태블릿 대형 화면용
//         {
//           width: 426,
//           height: 240,
//           collectionName: 'xsmall_images',
//           prefix: 'xsmall_',
//         }, // 스마트폰 대형 화면용
//         {
//           width: 213,
//           height: 120,
//           collectionName: 'icon_images',
//           prefix: 'icon_',
//         }, // 아이콘 사이즈
//       ];

//       for (const imageSize of imageSizes) {
//         // 이미지 업로드
//         const fileUrls = await uploadImageFiles(
//           productRef.id,
//           imageSize.width,
//           imageSize.height,
//           imageSize.prefix
//         );
//         const imageUpdates = {};
//         imageUpdates[imageSize.collectionName] = fileUrls;
//         // Firestore 문서 업데이트
//         await updateDoc(productRef, imageUpdates);
//       }

//       // 프로모션 데이터 저장
//       if (promotionStartDate && promotionEndDate && promotionContent) {
//         const promotionsRef = collection(db, 'stores', storeId, 'promotions');
//         const promotionData = {
//           name: promotionName,
//           content: promotionContent,
//           startDate: promotionStartDate,
//           endDate: promotionEndDate,
//           // productId: docRef.id (만약 저장된 상품의 ID가 필요하다면 이와 같이 사용할 수 있습니다.)
//         };
//         await addDoc(promotionsRef, promotionData);
//       }

//       console.log('Product and Promotion added successfully!');
//     } catch (error) {
//       console.error('Error adding product:', error);
//     }
//   };

//   const handleCancel = () => {
//     navigate(-1);
//   };

//   const handlePromotionToggle = (e) => {
//     setHasPromotion(e.target.checked);
//   };

//   const onSelectCategory = async (selectedCategory) => {
//     setSelectedCategory(selectedCategory);
//     // MessageBoxInform(`Selected category: ${selectedCategory.name}`);
//   };

//   const onSelectBrand = async (selectedBrand) => {
//     setSelectedBrand(selectedBrand);
//   };

//   return (
//     <div className="upload">
//       <div className="container">
//         <div className="form-section">
//           <label className="title-label" for="title">
//             {t('productName')}
//           </label>
//           <input
//             type="text"
//             id="title"
//             name="title"
//             className="form-control mt-2"
//             value={productName || ''}
//             onChange={(e) => setProductName(e.target.value)}
//             placeholder={t('enterTitle')}
//           />
//         </div>

//         <div className="mt-2 position-relative">
//           <p className="mb-4"></p>
//           <div className="form-section">
//             <label className="content-label" for="content">
//               {t('description')}
//             </label>
//             <textarea
//               id="content"
//               name="content"
//               className="form-control"
//               rows="5"
//               value={description || ''}
//               onChange={handleDescriptionChange}
//               placeholder={t('enterContent')}
//             ></textarea>
//           </div>
//           <div className="position-absolute top-0 end-0">
//             <p>{contentByteSize}/200 바이트 입력됨</p>
//           </div>
//         </div>
//         {MessageBoxRender()}
//         <div className="form-section">
//           <label className="title-label">
//             <input
//               type="checkbox"
//               checked={hasPromotion}
//               onChange={handlePromotionToggle}
//             />
//             {t('applyPromotion')}
//           </label>

//           {hasPromotion && (
//             <>
//               <div className="mt-2">
//                 <label for="promotionContent">{t('promotionName')}:</label>
//                 <input
//                   type="text"
//                   id="promotionContent"
//                   value={promotionName || ''}
//                   onChange={(e) => setPromotionName(e.target.value)}
//                   placeholder={t('promotionNamePlaceholder')}
//                 />
//               </div>
//               <div className="mt-2">
//                 <label for="promotionContent">{t('promotionContent')}:</label>
//                 <input
//                   type="text"
//                   id="promotionContent"
//                   value={promotionContent || ''}
//                   onChange={(e) => setPromotionContent(e.target.value)}
//                   placeholder={t('promotionContentPlaceholder')}
//                 />
//               </div>
//               <div className="mt-2">
//                 <label for="startDate">{t('startDate')}:</label>
//                 <input
//                   type="datetime-local"
//                   id="startDate"
//                   value={promotionStartDate || ''}
//                   onChange={(e) => setPromotionStartDate(e.target.value)}
//                 />
//               </div>
//               <div className="mt-2">
//                 <label for="endDate">{t('endDate')}:</label>
//                 <input
//                   type="datetime-local"
//                   id="endDate"
//                   value={promotionEndDate || ''}
//                   onChange={(e) => setPromotionEndDate(e.target.value)}
//                 />
//               </div>
//             </>
//           )}
//         </div>

//         <div className="form-section">
//           <label className="price-label" for="price">
//             {t('stock')}
//           </label>
//           <input
//             type="text"
//             id="stock"
//             name="stock"
//             className="form-control mt-2"
//             placeholder={t('enterStock')}
//             value={stock || ''}
//             onChange={handleStockChange}
//           />
//         </div>
//         <div className="form-section">
//           <label className="title-label" for="title">
//             {t('setCategory')}
//           </label>
//           <ProductCategorySelector onSelectCategory={onSelectCategory} />
//         </div>

//         {selectedCategory && (
//           <div className="form-section">
//             <label className="title-label" for="title">
//               {t('setBrand')}
//             </label>
//             <ProductBrandSelector
//               selectedCategory={selectedCategory}
//               onSelectBrand={onSelectBrand}
//             />
//           </div>
//         )}
//         <div className="form-section">
//           <label className="price-label" for="price">
//             {t('price')}
//           </label>
//           <input
//             type="text"
//             id="price"
//             name="price"
//             className="form-control mt-2"
//             placeholder={t('pricePlaceholder')}
//             value={formatPrice(price) || ''}
//             onChange={handlePriceChange}
//           />
//           <span className="korean-price-label">
//             {koreanPrice !== '0' ? `${koreanPrice}원` : t('free')}
//           </span>
//         </div>

//         {uploadingFiles && uploadingFiles.length > 0 && (
//           <div className="progress-container">
//             {/* 전체 진행률 표시 */}
//             <div className="circular-progress">
//               <svg viewBox="0 0 100 100">
//                 <circle
//                   className="circle-bg"
//                   cx="50"
//                   cy="50"
//                   r={RADIUS}
//                 ></circle>
//                 <circle
//                   className="circle"
//                   cx="50"
//                   cy="50"
//                   r={RADIUS}
//                   strokeDasharray={`${
//                     (uploadedFileSize / totalFileSize) * CIRCUMFERENCE
//                   }, ${CIRCUMFERENCE}`}
//                   strokeDashoffset={`${
//                     CIRCUMFERENCE -
//                     (uploadedFileSize / totalFileSize) * CIRCUMFERENCE
//                   }`}
//                 ></circle>
//               </svg>
//               {/* <div className="progress-text">{`${uploadedFileSize}/${totalFileSize} bytes`}</div> */}
//               <div className="progress-text">{`${Math.round(
//                 (uploadedFileSize / totalFileSize) * 100
//               )}%`}</div>
//             </div>
//           </div>
//         )}

//         <div className="form-section">
//           <label className="title-label" for="title">
//             {t('uploadImage')}
//           </label>
//           <div className="mt-2">
//             {/* <progress value={uploadProgress} max="100" className="w-100"></progress> */}
//             <span>{Math.round(uploadProgress)}%</span>
//           </div>

//           <div className="image-input-container">
//             <input
//               className="form-control mt-2"
//               id="file-upload"
//               type="file"
//               multiple
//               onChange={handleImageChange}
//               ref={fileInputRef}
//             />
//             <span className="image-count">c: {selectedFiles.length}</span>
//           </div>

//           <div className="preview-container">
//             {previews &&
//               previews.map((preview, index) => (
//                 <PreviewImage
//                   key={index}
//                   preview={preview}
//                   onRemove={() => handleRemoveImage(index)}
//                 />
//               ))}
//           </div>
//         </div>
//         {/* <button onClick={() => setShowMap(true)}>보여질 동네 선택</button> */}
//         {/* {showMap && (< GoogleMapRegion onSave={handleSaveArea} />)} */}
//         <div className="form-section">
//           <label className="title-label" for="title">
//             {t('setLocation')}
//           </label>
//           {<GoogleMapRegion onSave={handleSaveArea} />}
//         </div>

//         <div className="bottom-buttons">
//           <button
//             className="btn btn-outline-primary action-button"
//             onClick={handleCancel}
//           >
//             {t('cancel')}
//           </button>
//           <button
//             className="btn btn-outline-danger action-button"
//             onClick={handleUpload}
//           >
//             {t('upload')}
//           </button>
//         </div>

//         <br />
//         {/* <button className="btn btn-danger mt-8 upload-btn" onClick={handleUpload}>올리기</button>       */}
//       </div>
//     </div>
//   );
// };

// export default StoreProductUpload;

// import React, { useRef, useState, useEffect, useCallback } from 'react';
// import { useSelector } from 'react-redux';
// import {
//   db,
//   storage,
//   collection,
//   addDoc,
//   updateDoc,
//   ref,
//   uploadBytesResumable,
//   getDownloadURL,
// } from '../firebaseConfig';

// import {
//   Container,
//   TextField,
//   Button,
//   Typography,
//   Box,
//   Checkbox,
//   FormControlLabel,
//   CircularProgress,
//   Avatar,
//   Grid,
//   IconButton,
//   InputAdornment,
//   Tooltip,
// } from '@mui/material';
// import {
//   Cancel as CancelIcon,
//   CloudUpload as CloudUploadIcon,
// } from '@mui/icons-material';
// import { useTranslation } from 'react-i18next';
// import GoogleMapRegion from '../Common/GoogleMapRegion';
// import { RADIUS, CIRCUMFERENCE } from '../Common/Constants';
// import { useNavigate } from 'react-router-dom';
// import {
//   formatPrice,
//   numberToKorean,
//   getByteLength,
// } from '../Utils/PriceFormatter';
// import useMessageBox from '../Common/useMessageBox';
// import ProductCategorySelector from './ProductCategorySelector';
// import ProductBrandSelector from './ProductBrandSelector';
// import { resizeImage } from '../Utils/ImageHelper';
// import PreviewImage from '../Common/PreviewImage';

// const StoreProductUpload = () => {
//   const { t, i18n } = useTranslation();
//   const fileInputRef = useRef(null);
//   const [MessageBoxInform, MessageBoxConfirm, MessageBoxRender] =
//     useMessageBox();
//   const currentUser = useSelector((state) => state.auth.currentUser);
//   const navigate = useNavigate();

//   const [hasPromotion, setHasPromotion] = useState(false);
//   const [promotionName, setPromotionName] = useState('');
//   const [promotionContent, setPromotionContent] = useState('');
//   const [promotionStartDate, setPromotionStartDate] = useState('');
//   const [promotionEndDate, setPromotionEndDate] = useState('');

//   const [productName, setProductName] = useState('');
//   const [description, setDescription] = useState('');
//   const [price, setPrice] = useState(0);

//   const [previews, setPreviews] = useState([]); // 여러 이미지의 미리보기 URL을 위한 상태
//   const [koreanPrice, setKoreanPrice] = useState('0');
//   const [showMap, setShowMap] = useState(false);
//   const [selectedArea, setSelectedArea] = useState([]);

//   const [stock, setStock] = useState(0);
//   const [deliveryTimes, setDeliveryTimes] = useState([]);

//   const [selectedCategory, setSelectedCategory] = useState([]);
//   const [selectedBrand, setSelectedBrand] = useState([]);
//   const [selectedFiles, setSelectedFiles] = useState([]);
//   const [fileUrls, setFileUrls] = useState([]);
//   const [uploadProgress, setUploadProgress] = useState([]);
//   const [uploadingFiles, setUploadingFiles] = useState([]);
//   const [completedCount, setCompletedCount] = useState(0);
//   const [totalFileSize, setTotalFileSize] = useState(uploadingFiles.length);
//   const [uploadedFileSize, setUploadedFileSize] = useState(0);

//   const handleSaveArea = (savedArea) => {
//     // savedArea 값을 서버에 저장하는 로직
//     setSelectedArea([...savedArea], () => {
//       console.log('Updated selectedArea:', selectedArea);
//     });
//   };

//   // 가격이 변경될 때 한글로 된 가격도 함께 변경
//   useEffect(() => {
//     setKoreanPrice(numberToKorean(price));
//   }, [price]);

//   const handleStockChange = useCallback((e) => {
//     let stockValue = e.target.value.replace(/[^0-9]/g, '');
//     if (stockValue === '0' || stockValue === '') {
//       stockValue = '0';
//     } else {
//       while (stockValue.startsWith('0')) {
//         stockValue = stockValue.substring(1);
//       }
//     }
//     setStock(stockValue);
//   }, []);
//   // 가격 입력 관리
//   const handlePriceChange = useCallback((e) => {
//     let priceValue = e.target.value.replace(/[^0-9]/g, '');
//     if (priceValue === '0' || priceValue === '') {
//       priceValue = '0';
//     } else {
//       while (priceValue.startsWith('0')) {
//         priceValue = priceValue.substring(1);
//       }
//     }
//     setPrice(priceValue);
//   }, []);

//   const handleDescriptionChange = useCallback(
//     (e) => {
//       if (getByteLength(e.target.value) <= 200) {
//         setDescription(e.target.value);
//       }
//     },
//     [description]
//   );

//   const contentByteSize = getByteLength(description);

//   // 파일 입력 필드 초기화
//   const resetFileInput = () => {
//     if (fileInputRef.current) fileInputRef.current.value = '';
//   };

//   const handleImageChange = useCallback(
//     (event) => {
//       const tmpSelectedFiles = Array.from(event.target.files);

//       if (tmpSelectedFiles.length > process.env.REACT_APP_SEND_IMAGE_MAX_LEN) {
//         MessageBoxInform(
//           `최대 ${process.env.REACT_APP_SEND_IMAGE_MAX_LEN}개의 이미지를 선택할 수 있습니다.`
//         );
//         event.target.value = ''; // 이 부분이 이미지 선택을 취소합니다.
//         return;
//       }

//       const readerPromises = tmpSelectedFiles.map((file) => {
//         return new Promise((resolve, reject) => {
//           const reader = new FileReader();
//           reader.onloadend = () => {
//             resolve(reader.result);
//           };
//           reader.onerror = reject;
//           reader.readAsDataURL(file);
//         });
//       });

//       Promise.all(readerPromises).then((results) => {
//         setSelectedFiles((prevImages) => [...prevImages, ...tmpSelectedFiles]);
//         setPreviews((prevPreviews) => [...prevPreviews, ...results]);
//       });
//     },
//     [previews]
//   );

//   const handleRemoveImage = useCallback((indexToRemove) => {
//     setSelectedFiles((prevImages) =>
//       prevImages.filter((_, index) => index !== indexToRemove)
//     );
//     setPreviews((prevPreviews) =>
//       prevPreviews.filter((_, index) => index !== indexToRemove)
//     );
//   }, []);

//   const uploadImageFiles = async (productId, width, height, prefix) => {
//     let paths = [];

//     for (const file of selectedFiles) {
//       const resizedFile = await resizeImage(file, width, height);
//       const filePath = `${
//         process.env.REACT_APP_USER_IMAGE_PATH
//       }/${productId}/${prefix}${Date.now()}_${file.name}`;
//       const uploadUrl = await uploadImageFile(productId, resizedFile, filePath);
//       paths.push(uploadUrl);
//     }
//     return paths;
//   };

//   const uploadImageFile = async (productId, file, filePath) => {
//     const fileRef = ref(storage, filePath);
//     const metadata = {
//       customMetadata: {
//         productId: productId,
//       },
//     };

//     try {
//       const uploadTask = uploadBytesResumable(fileRef, file, metadata);

//       uploadTask.on('state_changed', (snapshot) => {
//         const progress =
//           (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
//         updateProgress(file.name, progress);
//       });

//       await uploadTask;

//       // 업로드가 완료되면 상태를 업데이트합니다.
//       setCompletedCount((prev) => prev + 1);
//       setUploadedFileSize((prev) => prev + file.size);
//       const downloadURL = await getDownloadURL(fileRef);
//       return downloadURL;
//     } catch (error) {
//       console.error('Upload failed:', error);
//       throw new Error('Failed to upload image');
//     }
//   };

//   function updateProgress(fileName, progress) {
//     setUploadingFiles((prev) =>
//       prev.map((item) =>
//         item.file.name === fileName ? { ...item, progress: progress } : item
//       )
//     );
//   }

//   const handleUpload = useCallback(async () => {
//     const showAlertIfEmpty = (value, message) => {
//       if (!value.trim()) {
//         MessageBoxInform(message);
//         throw new Error(message);
//       }
//     };

//     try {
//       showAlertIfEmpty(productName, t('enterProductName'));
//       showAlertIfEmpty(description, t('enterDescription'));
//       showAlertIfEmpty(stock, t('enterStock'));
//       showAlertIfEmpty(price, t('enterPrice'));
//       if (!selectedArea || selectedArea.length === 0) {
//         await MessageBoxInform(t('selectArea'));
//         throw new Error(t('selectArea'));
//       }
//       if (!selectedCategory || selectedCategory.length === 0) {
//         MessageBoxInform(t('selectCategory'));
//         throw new Error(t('selectCategory'));
//       }

//       if (!selectedFiles.length) {
//         await MessageBoxInform(t('uploadImages'));
//         throw new Error(t('uploadImages'));
//       }

//       const isConfirmed = await MessageBoxConfirm(
//         t('confirmStoreUpload'),
//         `${t('title')}: ${productName}\n${t('content')}: ${description}\n${t(
//           'stock'
//         )}: ${stock}\n${t('price')}: ${formatPrice(price)} (${koreanPrice} 원)`
//       );

//       if (!isConfirmed) return;

//       const productData = {
//         productName,
//         categoryId:
//           selectedCategory && selectedCategory.length > 0
//             ? selectedCategory.id
//             : null,
//         brandId:
//           selectedBrand && selectedBrand.length > 0 ? selectedBrand.id : null,
//         price,
//         description,
//         stock: stock,
//         createDate: new Date(),
//         uid: currentUser.uid,
//         likes: 0,
//         views: 0,
//         chats: 0,
//         state: 0,
//         selectedArea: selectedArea,
//       };

//       await addProductToStore(currentUser.uid, productData);

//       setUploadingFiles([]);
//       setSelectedFiles([]);
//       setUploadProgress([]);
//       setTotalFileSize(0);
//       setUploadedFileSize(0);
//       setPreviews([]);
//       resetFileInput();

//       MessageBoxInform(t('storeProductUploaded'));
//       setShowMap(false);
//       navigate(`/mystore/${currentUser.uid}`);
//     } catch (error) {
//       console.error('Error:', error);
//     }
//   }, [
//     productName,
//     description,
//     selectedFiles,
//     formatPrice,
//     koreanPrice,
//     selectedArea,
//   ]);

//   const addProductToStore = async (storeId, productData) => {
//     try {
//       const productRef = await addDoc(
//         collection(db, 'stores', storeId, 'products'),
//         productData
//       );
//       console.log('Product added successfully!');

//       const imageSizes = [
//         {
//           width: 1920,
//           height: 1080,
//           collectionName: 'xlarge_images',
//           prefix: 'xlarge_',
//         },
//         {
//           width: 1366,
//           height: 768,
//           collectionName: 'large_images',
//           prefix: 'large_',
//         },
//         {
//           width: 854,
//           height: 480,
//           collectionName: 'medium_images',
//           prefix: 'medium_',
//         },
//         {
//           width: 640,
//           height: 360,
//           collectionName: 'small_images',
//           prefix: 'small_',
//         },
//         {
//           width: 426,
//           height: 240,
//           collectionName: 'xsmall_images',
//           prefix: 'xsmall_',
//         },
//         {
//           width: 213,
//           height: 120,
//           collectionName: 'icon_images',
//           prefix: 'icon_',
//         },
//       ];

//       for (const imageSize of imageSizes) {
//         const fileUrls = await uploadImageFiles(
//           productRef.id,
//           imageSize.width,
//           imageSize.height,
//           imageSize.prefix
//         );
//         const imageUpdates = {};
//         imageUpdates[imageSize.collectionName] = fileUrls;
//         await updateDoc(productRef, imageUpdates);
//       }

//       if (promotionStartDate && promotionEndDate && promotionContent) {
//         const promotionsRef = collection(db, 'stores', storeId, 'promotions');
//         const promotionData = {
//           name: promotionName,
//           content: promotionContent,
//           startDate: promotionStartDate,
//           endDate: promotionEndDate,
//         };
//         await addDoc(promotionsRef, promotionData);
//       }

//       console.log('Product and Promotion added successfully!');
//     } catch (error) {
//       console.error('Error adding product:', error);
//     }
//   };

//   const handleCancel = () => {
//     navigate(-1);
//   };

//   const handlePromotionToggle = (e) => {
//     setHasPromotion(e.target.checked);
//   };

//   const onSelectCategory = async (selectedCategory) => {
//     setSelectedCategory(selectedCategory);
//   };

//   const onSelectBrand = async (selectedBrand) => {
//     setSelectedBrand(selectedBrand);
//   };

//   return (
//     <Container maxWidth="md" sx={{ py: 4 }}>
//       <Typography variant="h4" gutterBottom>
//         {t('uploadProduct')}
//       </Typography>

//       <Box component="form" sx={{ mt: 2 }}>
//         <TextField
//           fullWidth
//           label={t('productName')}
//           value={productName}
//           onChange={(e) => setProductName(e.target.value)}
//           margin="normal"
//         />

//         <TextField
//           fullWidth
//           label={t('description')}
//           value={description}
//           onChange={handleDescriptionChange}
//           margin="normal"
//           multiline
//           rows={4}
//           helperText={`${contentByteSize}/200 ${t('bytes')}`}
//         />

//         {MessageBoxRender()}

//         <FormControlLabel
//           control={
//             <Checkbox checked={hasPromotion} onChange={handlePromotionToggle} />
//           }
//           label={t('applyPromotion')}
//         />

//         {hasPromotion && (
//           <Box sx={{ mt: 2 }}>
//             <TextField
//               fullWidth
//               label={t('promotionName')}
//               value={promotionName}
//               onChange={(e) => setPromotionName(e.target.value)}
//               margin="normal"
//             />
//             <TextField
//               fullWidth
//               label={t('promotionContent')}
//               value={promotionContent}
//               onChange={(e) => setPromotionContent(e.target.value)}
//               margin="normal"
//             />
//             <TextField
//               fullWidth
//               label={t('startDate')}
//               type="datetime-local"
//               value={promotionStartDate}
//               onChange={(e) => setPromotionStartDate(e.target.value)}
//               margin="normal"
//               InputLabelProps={{ shrink: true }}
//             />
//             <TextField
//               fullWidth
//               label={t('endDate')}
//               type="datetime-local"
//               value={promotionEndDate}
//               onChange={(e) => setPromotionEndDate(e.target.value)}
//               margin="normal"
//               InputLabelProps={{ shrink: true }}
//             />
//           </Box>
//         )}

//         <TextField
//           fullWidth
//           label={t('stock')}
//           value={stock}
//           onChange={handleStockChange}
//           margin="normal"
//         />

//         <ProductCategorySelector onSelectCategory={onSelectCategory} />

//         {selectedCategory && (
//           <ProductBrandSelector
//             selectedCategory={selectedCategory}
//             onSelectBrand={onSelectBrand}
//           />
//         )}

//         <TextField
//           fullWidth
//           label={t('price')}
//           value={formatPrice(price)}
//           onChange={handlePriceChange}
//           margin="normal"
//           InputProps={{
//             startAdornment: (
//               <InputAdornment position="start">{t('currency')}</InputAdornment>
//             ),
//           }}
//           helperText={koreanPrice !== '0' ? `${koreanPrice}원` : t('free')}
//         />

//         {uploadingFiles && uploadingFiles.length > 0 && (
//           <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
//             <CircularProgress
//               variant="determinate"
//               value={(uploadedFileSize / totalFileSize) * 100}
//             />
//             <Typography sx={{ ml: 2 }}>
//               {`${Math.round((uploadedFileSize / totalFileSize) * 100)}%`}
//             </Typography>
//           </Box>
//         )}

//         <Box sx={{ mb: 2 }}>
//           <Button
//             variant="contained"
//             component="label"
//             startIcon={<CloudUploadIcon />}
//           >
//             {t('uploadImage')}
//             <input
//               type="file"
//               hidden
//               multiple
//               onChange={handleImageChange}
//               ref={fileInputRef}
//             />
//           </Button>
//           <Typography variant="body2" sx={{ ml: 2 }}>
//             {selectedFiles.length} {t('filesSelected')}
//           </Typography>
//         </Box>

//         <Grid container spacing={2}>
//           {previews.map((preview, index) => (
//             <Grid item xs={6} sm={4} md={3} key={index}>
//               <Box position="relative">
//                 <Avatar
//                   src={preview}
//                   variant="rounded"
//                   sx={{ width: '100%', height: 'auto' }}
//                 />
//                 <Tooltip title={t('remove')}>
//                   <IconButton
//                     size="small"
//                     onClick={() => handleRemoveImage(index)}
//                     sx={{ position: 'absolute', top: 0, right: 0 }}
//                   >
//                     <CancelIcon />
//                   </IconButton>
//                 </Tooltip>
//               </Box>
//             </Grid>
//           ))}
//         </Grid>

//         <Box sx={{ mt: 4 }}>
//           <GoogleMapRegion onSave={handleSaveArea} />
//         </Box>

//         <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
//           <Button variant="outlined" color="primary" onClick={handleCancel}>
//             {t('cancel')}
//           </Button>
//           <Button variant="contained" color="primary" onClick={handleUpload}>
//             {t('upload')}
//           </Button>
//         </Box>
//       </Box>
//     </Container>
//   );
// };

// export default StoreProductUpload;

// import React, { useRef, useState, useEffect, useCallback } from 'react';
// import { useSelector } from 'react-redux';
// import {
//   db,
//   storage,
//   collection,
//   addDoc,
//   updateDoc,
//   ref,
//   uploadBytesResumable,
//   getDownloadURL,
// } from '../firebaseConfig';

// import {
//   Container,
//   TextField,
//   Button,
//   Typography,
//   Box,
//   Checkbox,
//   FormControlLabel,
//   CircularProgress,
//   Avatar,
//   Grid,
//   IconButton,
//   InputAdornment,
//   Tooltip,
// } from '@mui/material';
// import {
//   Cancel as CancelIcon,
//   CloudUpload as CloudUploadIcon,
// } from '@mui/icons-material';
// import { useTranslation } from 'react-i18next';
// import GoogleMapRegion from '../Common/GoogleMapRegion';
// import { RADIUS, CIRCUMFERENCE } from '../Common/Constants';
// import { useNavigate } from 'react-router-dom';
// import {
//   formatPrice,
//   numberToKorean,
//   getByteLength,
// } from '../Utils/PriceFormatter';
// import useMessageBox from '../Common/useMessageBox';
// import ProductCategorySelector from './ProductCategorySelector';
// import ProductBrandSelector from './ProductBrandSelector';
// import { resizeImage } from '../Utils/ImageHelper';
// import PreviewImage from '../Common/PreviewImage';

// const StoreProductUpload = () => {
//   const { t, i18n } = useTranslation();
//   const fileInputRef = useRef(null);
//   const [MessageBoxInform, MessageBoxConfirm, MessageBoxRender] =
//     useMessageBox();
//   const currentUser = useSelector((state) => state.auth.currentUser);
//   const navigate = useNavigate();

//   const [hasPromotion, setHasPromotion] = useState(false);
//   const [promotionName, setPromotionName] = useState('');
//   const [promotionContent, setPromotionContent] = useState('');
//   const [promotionStartDate, setPromotionStartDate] = useState('');
//   const [promotionEndDate, setPromotionEndDate] = useState('');

//   const [productName, setProductName] = useState('');
//   const [description, setDescription] = useState('');
//   const [price, setPrice] = useState(0);

//   const [previews, setPreviews] = useState([]); // 여러 이미지의 미리보기 URL을 위한 상태
//   const [koreanPrice, setKoreanPrice] = useState('0');
//   const [showMap, setShowMap] = useState(false);
//   const [selectedArea, setSelectedArea] = useState([]);

//   const [stock, setStock] = useState(0);
//   const [deliveryTimes, setDeliveryTimes] = useState([]);

//   const [selectedCategory, setSelectedCategory] = useState([]);
//   const [selectedBrand, setSelectedBrand] = useState([]);
//   const [selectedFiles, setSelectedFiles] = useState([]);
//   const [uploadProgress, setUploadProgress] = useState(0);
//   const [uploadingFiles, setUploadingFiles] = useState([]);
//   const [completedCount, setCompletedCount] = useState(0);
//   const [totalFileSize, setTotalFileSize] = useState(0);
//   const [uploadedFileSize, setUploadedFileSize] = useState(0);

//   const handleSaveArea = (savedArea) => {
//     setSelectedArea([...savedArea], () => {
//       console.log('Updated selectedArea:', selectedArea);
//     });
//   };

//   // 가격이 변경될 때 한글로 된 가격도 함께 변경
//   useEffect(() => {
//     setKoreanPrice(numberToKorean(price));
//   }, [price]);

//   const handleStockChange = useCallback((e) => {
//     let stockValue = e.target.value.replace(/[^0-9]/g, '');
//     if (stockValue === '0' || stockValue === '') {
//       stockValue = '0';
//     } else {
//       while (stockValue.startsWith('0')) {
//         stockValue = stockValue.substring(1);
//       }
//     }
//     setStock(stockValue);
//   }, []);
//   // 가격 입력 관리
//   const handlePriceChange = useCallback((e) => {
//     let priceValue = e.target.value.replace(/[^0-9]/g, '');
//     if (priceValue === '0' || priceValue === '') {
//       priceValue = '0';
//     } else {
//       while (priceValue.startsWith('0')) {
//         priceValue = priceValue.substring(1);
//       }
//     }
//     setPrice(priceValue);
//   }, []);

//   const handleDescriptionChange = useCallback(
//     (e) => {
//       if (getByteLength(e.target.value) <= 200) {
//         setDescription(e.target.value);
//       }
//     },
//     [description]
//   );

//   const contentByteSize = getByteLength(description);

//   // 파일 입력 필드 초기화
//   const resetFileInput = () => {
//     if (fileInputRef.current) fileInputRef.current.value = '';
//   };

//   const handleImageChange = useCallback(
//     (event) => {
//       const tmpSelectedFiles = Array.from(event.target.files);

//       if (tmpSelectedFiles.length > process.env.REACT_APP_SEND_IMAGE_MAX_LEN) {
//         MessageBoxInform(
//           `최대 ${process.env.REACT_APP_SEND_IMAGE_MAX_LEN}개의 이미지를 선택할 수 있습니다.`
//         );
//         event.target.value = '';
//         return;
//       }

//       const readerPromises = tmpSelectedFiles.map((file) => {
//         return new Promise((resolve, reject) => {
//           const reader = new FileReader();
//           reader.onloadend = () => {
//             resolve(reader.result);
//           };
//           reader.onerror = reject;
//           reader.readAsDataURL(file);
//         });
//       });

//       Promise.all(readerPromises).then((results) => {
//         setSelectedFiles((prevImages) => [...prevImages, ...tmpSelectedFiles]);
//         setPreviews((prevPreviews) => [...prevPreviews, ...results]);
//       });
//     },
//     [previews]
//   );

//   const handleRemoveImage = useCallback((indexToRemove) => {
//     setSelectedFiles((prevImages) =>
//       prevImages.filter((_, index) => index !== indexToRemove)
//     );
//     setPreviews((prevPreviews) =>
//       prevPreviews.filter((_, index) => index !== indexToRemove)
//     );
//   }, []);

//   const uploadImageFiles = async (productId, width, height, prefix) => {
//     let paths = [];

//     for (const file of selectedFiles) {
//       const resizedFile = await resizeImage(file, width, height);
//       const filePath = `${
//         process.env.REACT_APP_USER_IMAGE_PATH
//       }/${productId}/${prefix}${Date.now()}_${file.name}`;
//       const uploadUrl = await uploadImageFile(productId, resizedFile, filePath);
//       paths.push(uploadUrl);
//     }
//     return paths;
//   };

//   const uploadImageFile = async (productId, file, filePath) => {
//     const fileRef = ref(storage, filePath);
//     const metadata = {
//       customMetadata: {
//         productId: productId,
//       },
//     };

//     try {
//       const uploadTask = uploadBytesResumable(fileRef, file, metadata);

//       uploadTask.on('state_changed', (snapshot) => {
//         const progress =
//           (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
//         setUploadProgress(progress);
//       });

//       await uploadTask;

//       setCompletedCount((prev) => prev + 1);
//       setUploadedFileSize((prev) => prev + file.size);
//       const downloadURL = await getDownloadURL(fileRef);
//       return downloadURL;
//     } catch (error) {
//       console.error('Upload failed:', error);
//       throw new Error('Failed to upload image');
//     }
//   };

//   const handleUpload = useCallback(async () => {
//     const showAlertIfEmpty = (value, message) => {
//       if (!value.trim()) {
//         MessageBoxInform(message);
//         throw new Error(message);
//       }
//     };

//     try {
//       showAlertIfEmpty(productName, t('enterProductName'));
//       showAlertIfEmpty(description, t('enterDescription'));
//       showAlertIfEmpty(stock, t('enterStock'));
//       showAlertIfEmpty(price, t('enterPrice'));
//       if (!selectedArea || selectedArea.length === 0) {
//         await MessageBoxInform(t('selectArea'));
//         throw new Error(t('selectArea'));
//       }
//       if (!selectedCategory || selectedCategory.length === 0) {
//         MessageBoxInform(t('selectCategory'));
//         throw new Error(t('selectCategory'));
//       }

//       if (!selectedFiles.length) {
//         await MessageBoxInform(t('uploadImages'));
//         throw new Error(t('uploadImages'));
//       }

//       const isConfirmed = await MessageBoxConfirm(
//         t('confirmStoreUpload'),
//         `${t('title')}: ${productName}\n${t('content')}: ${description}\n${t(
//           'stock'
//         )}: ${stock}\n${t('price')}: ${formatPrice(price)} (${koreanPrice} 원)`
//       );

//       if (!isConfirmed) return;

//       const productData = {
//         productName,
//         categoryId:
//           selectedCategory && selectedCategory.length > 0
//             ? selectedCategory.id
//             : null,
//         brandId:
//           selectedBrand && selectedBrand.length > 0 ? selectedBrand.id : null,
//         price,
//         description,
//         stock: stock,
//         createDate: new Date(),
//         uid: currentUser.uid,
//         likes: 0,
//         views: 0,
//         chats: 0,
//         state: 0,
//         selectedArea: selectedArea,
//       };

//       await addProductToStore(currentUser.uid, productData);

//       setUploadingFiles([]);
//       setSelectedFiles([]);
//       setUploadProgress(0);
//       setTotalFileSize(0);
//       setUploadedFileSize(0);
//       setPreviews([]);
//       resetFileInput();

//       MessageBoxInform(t('storeProductUploaded'));
//       setShowMap(false);
//       navigate(`/mystore/${currentUser.uid}`);
//     } catch (error) {
//       console.error('Error:', error);
//     }
//   }, [
//     productName,
//     description,
//     selectedFiles,
//     formatPrice,
//     koreanPrice,
//     selectedArea,
//   ]);

//   const addProductToStore = async (storeId, productData) => {
//     try {
//       const productRef = await addDoc(
//         collection(db, 'stores', storeId, 'products'),
//         productData
//       );
//       console.log('Product added successfully!');

//       const imageSizes = [
//         {
//           width: 1920,
//           height: 1080,
//           collectionName: 'xlarge_images',
//           prefix: 'xlarge_',
//         },
//         {
//           width: 1366,
//           height: 768,
//           collectionName: 'large_images',
//           prefix: 'large_',
//         },
//         {
//           width: 854,
//           height: 480,
//           collectionName: 'medium_images',
//           prefix: 'medium_',
//         },
//         {
//           width: 640,
//           height: 360,
//           collectionName: 'small_images',
//           prefix: 'small_',
//         },
//         {
//           width: 426,
//           height: 240,
//           collectionName: 'xsmall_images',
//           prefix: 'xsmall_',
//         },
//         {
//           width: 213,
//           height: 120,
//           collectionName: 'icon_images',
//           prefix: 'icon_',
//         },
//       ];

//       for (const imageSize of imageSizes) {
//         const fileUrls = await uploadImageFiles(
//           productRef.id,
//           imageSize.width,
//           imageSize.height,
//           imageSize.prefix
//         );
//         const imageUpdates = {};
//         imageUpdates[imageSize.collectionName] = fileUrls;
//         await updateDoc(productRef, imageUpdates);
//       }

//       if (promotionStartDate && promotionEndDate && promotionContent) {
//         const promotionsRef = collection(db, 'stores', storeId, 'promotions');
//         const promotionData = {
//           name: promotionName,
//           content: promotionContent,
//           startDate: promotionStartDate,
//           endDate: promotionEndDate,
//         };
//         await addDoc(promotionsRef, promotionData);
//       }

//       console.log('Product and Promotion added successfully!');
//     } catch (error) {
//       console.error('Error adding product:', error);
//     }
//   };

//   const handleCancel = () => {
//     navigate(-1);
//   };

//   const handlePromotionToggle = (e) => {
//     setHasPromotion(e.target.checked);
//   };

//   const onSelectCategory = async (selectedCategory) => {
//     setSelectedCategory(selectedCategory);
//   };

//   const onSelectBrand = async (selectedBrand) => {
//     setSelectedBrand(selectedBrand);
//   };

//   return (
//     <Container maxWidth="md" sx={{ py: 4 }}>
//       <Typography variant="h4" gutterBottom>
//         {t('uploadProduct')}
//       </Typography>

//       <Box component="form" sx={{ mt: 2 }}>
//         <TextField
//           fullWidth
//           label={t('productName')}
//           value={productName}
//           onChange={(e) => setProductName(e.target.value)}
//           margin="normal"
//         />

//         <TextField
//           fullWidth
//           label={t('description')}
//           value={description}
//           onChange={handleDescriptionChange}
//           margin="normal"
//           multiline
//           rows={4}
//           helperText={`${contentByteSize}/200 ${t('bytes')}`}
//         />

//         {MessageBoxRender()}

//         <FormControlLabel
//           control={
//             <Checkbox checked={hasPromotion} onChange={handlePromotionToggle} />
//           }
//           label={t('applyPromotion')}
//         />

//         {hasPromotion && (
//           <Box sx={{ mt: 2 }}>
//             <TextField
//               fullWidth
//               label={t('promotionName')}
//               value={promotionName}
//               onChange={(e) => setPromotionName(e.target.value)}
//               margin="normal"
//             />
//             <TextField
//               fullWidth
//               label={t('promotionContent')}
//               value={promotionContent}
//               onChange={(e) => setPromotionContent(e.target.value)}
//               margin="normal"
//             />
//             <TextField
//               fullWidth
//               label={t('startDate')}
//               type="datetime-local"
//               value={promotionStartDate}
//               onChange={(e) => setPromotionStartDate(e.target.value)}
//               margin="normal"
//               InputLabelProps={{ shrink: true }}
//             />
//             <TextField
//               fullWidth
//               label={t('endDate')}
//               type="datetime-local"
//               value={promotionEndDate}
//               onChange={(e) => setPromotionEndDate(e.target.value)}
//               margin="normal"
//               InputLabelProps={{ shrink: true }}
//             />
//           </Box>
//         )}

//         <TextField
//           fullWidth
//           label={t('stock')}
//           value={stock}
//           onChange={handleStockChange}
//           margin="normal"
//         />

//         <ProductCategorySelector onSelectCategory={onSelectCategory} />

//         {selectedCategory && (
//           <ProductBrandSelector
//             selectedCategory={selectedCategory}
//             onSelectBrand={onSelectBrand}
//           />
//         )}

//         <TextField
//           fullWidth
//           label={t('price')}
//           value={formatPrice(price)}
//           onChange={handlePriceChange}
//           margin="normal"
//           InputProps={{
//             startAdornment: (
//               <InputAdornment position="start">{t('currency')}</InputAdornment>
//             ),
//           }}
//           helperText={koreanPrice !== '0' ? `${koreanPrice}원` : t('free')}
//         />

//         {uploadProgress > 0 && (
//           <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
//             <CircularProgress variant="determinate" value={uploadProgress} />
//             <Typography sx={{ ml: 2 }}>
//               {`${Math.round(uploadProgress)}%`}
//             </Typography>
//           </Box>
//         )}

//         <Box sx={{ mb: 2 }}>
//           <Button
//             variant="contained"
//             component="label"
//             startIcon={<CloudUploadIcon />}
//           >
//             {t('uploadImage')}
//             <input
//               type="file"
//               hidden
//               multiple
//               onChange={handleImageChange}
//               ref={fileInputRef}
//             />
//           </Button>
//           <Typography variant="body2" sx={{ ml: 2 }}>
//             {selectedFiles.length} {t('filesSelected')}
//           </Typography>
//         </Box>

//         <Grid container spacing={2}>
//           {previews.map((preview, index) => (
//             <Grid item xs={6} sm={4} md={3} key={index}>
//               <Box position="relative">
//                 <Avatar
//                   src={preview}
//                   variant="rounded"
//                   sx={{ width: '100%', height: 'auto' }}
//                 />
//                 <Tooltip title={t('remove')}>
//                   <IconButton
//                     size="small"
//                     onClick={() => handleRemoveImage(index)}
//                     sx={{ position: 'absolute', top: 0, right: 0 }}
//                   >
//                     <CancelIcon />
//                   </IconButton>
//                 </Tooltip>
//               </Box>
//             </Grid>
//           ))}
//         </Grid>

//         <Box sx={{ mt: 4 }}>
//           <GoogleMapRegion onSave={handleSaveArea} />
//         </Box>

//         <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
//           <Button variant="outlined" color="primary" onClick={handleCancel}>
//             {t('cancel')}
//           </Button>
//           <Button variant="contained" color="primary" onClick={handleUpload}>
//             {t('upload')}
//           </Button>
//         </Box>
//       </Box>
//     </Container>
//   );
// };

// export default StoreProductUpload;

import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  db,
  storage,
  collection,
  addDoc,
  updateDoc,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from '../firebaseConfig';

import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Checkbox,
  FormControlLabel,
  Avatar,
  Grid,
  IconButton,
  InputAdornment,
  Tooltip,
  Modal,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import {
  Cancel as CancelIcon,
  CloudUpload as CloudUploadIcon,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import GoogleMapRegion from '../Common/GoogleMapRegion';
import { useNavigate } from 'react-router-dom';
import {
  formatPrice,
  numberToKorean,
  getByteLength,
} from '../Utils/PriceFormatter';
import useMessageBox from '../Common/useMessageBox';
import ProductCategorySelector from './ProductCategorySelector';
import ProductBrandSelector from './ProductBrandSelector';
import { resizeImage } from '../Utils/ImageHelper';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
const MAX_IMAGE_UPLOAD = process.env.REACT_APP_SEND_IMAGE_MAX_LEN || 5;

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const StoreProductUpload = () => {
  const { t, i18n } = useTranslation();
  const fileInputRef = useRef(null);
  const [MessageBoxInform, MessageBoxConfirm, MessageBoxRender] =
    useMessageBox();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const navigate = useNavigate();

  const [hasPromotion, setHasPromotion] = useState(false);
  const [promotionName, setPromotionName] = useState('');
  const [promotionContent, setPromotionContent] = useState('');
  const [promotionStartDate, setPromotionStartDate] = useState('');
  const [promotionEndDate, setPromotionEndDate] = useState('');

  const [productName, setProductName] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState(0);

  const [previews, setPreviews] = useState([]);
  const [koreanPrice, setKoreanPrice] = useState('0');
  const [showMap, setShowMap] = useState(false);
  const [selectedArea, setSelectedArea] = useState([]);

  const [stock, setStock] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});
  const [uploadingFiles, setUploadingFiles] = useState([]);
  const [totalFileSize, setTotalFileSize] = useState(0);
  const [uploadedFileSize, setUploadedFileSize] = useState(0);
  const [progressModalOpen, setProgressModalOpen] = useState(false);

  const theme = useTheme();
  // Define media queries for mobile and tablet
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isPad = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const handleSaveArea = (savedArea) => {
    setSelectedArea(savedArea);
  };

  useEffect(() => {
    setKoreanPrice(numberToKorean(price));
  }, [price]);

  const handleStockChange = useCallback((e) => {
    let stockValue = e.target.value.replace(/[^0-9]/g, '');
    if (stockValue === '0' || stockValue === '') {
      stockValue = '0';
    } else {
      while (stockValue.startsWith('0')) {
        stockValue = stockValue.substring(1);
      }
    }
    setStock(stockValue);
  }, []);

  const handlePriceChange = useCallback((e) => {
    let priceValue = e.target.value.replace(/[^0-9]/g, '');
    if (priceValue === '0' || priceValue === '') {
      priceValue = '0';
    } else {
      while (priceValue.startsWith('0')) {
        priceValue = priceValue.substring(1);
      }
    }
    setPrice(priceValue);
  }, []);

  const handleDescriptionChange = useCallback((e) => {
    if (getByteLength(e.target.value) <= 200) {
      setDescription(e.target.value);
    }
  }, []);

  const contentByteSize = getByteLength(description);

  const resetFileInput = () => {
    if (fileInputRef.current) fileInputRef.current.value = '';
  };

  const handleImageChange = useCallback(
    async (event) => {
      const tmpSelectedFiles = Array.from(event.target.files);

      if (!tmpSelectedFiles.length) {
        resetFileInput();
        return;
      }

      if (tmpSelectedFiles.length + selectedFiles.length > MAX_IMAGE_UPLOAD) {
        await MessageBoxInform(
          `최대 ${MAX_IMAGE_UPLOAD}개의 이미지를 선택할 수 있습니다.`
        );
        resetFileInput();
        return;
      }

      let totalSize = 0;
      tmpSelectedFiles.forEach((file) => {
        totalSize += file.size;
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreviews((prevPreviews) => [...prevPreviews, reader.result]);
          setTotalFileSize(totalSize);
          setSelectedFiles((prevImages) => [...prevImages, file]);
        };
        reader.onerror = async () => {
          await MessageBoxInform('파일을 읽는 도중 오류가 발생했습니다.');
        };
        reader.readAsDataURL(file);
      });
    },
    [selectedFiles]
  );

  const handleRemoveImage = useCallback((indexToRemove) => {
    setSelectedFiles((prevImages) =>
      prevImages.filter((_, index) => index !== indexToRemove)
    );
    setPreviews((prevPreviews) =>
      prevPreviews.filter((_, index) => index !== indexToRemove)
    );
  }, []);

  const uploadImageFiles = async (productId, width, height, prefix) => {
    let paths = [];

    for (const file of selectedFiles) {
      const resizedFile = await resizeImage(file, width, height);
      const filePath = `${
        process.env.REACT_APP_USER_IMAGE_PATH
      }/${productId}/${prefix}${Date.now()}_${file.name}`;
      const uploadUrl = await uploadImageFile(productId, resizedFile, filePath);
      paths.push(uploadUrl);
    }
    return paths;
  };

  const uploadImageFile = async (productId, file, filePath) => {
    const fileRef = ref(storage, filePath);
    const metadata = { customMetadata: { productId } };
    const uploadTask = uploadBytesResumable(fileRef, file, metadata);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress((prevProgress) => ({
            ...prevProgress,
            [file.name]: { progress, fileSize: snapshot.totalBytes },
          }));
        },
        reject,
        () => {
          setUploadedFileSize((prev) => prev + file.size);
          getDownloadURL(uploadTask.snapshot.ref).then(resolve, reject);
        }
      );
    });
  };

  const isLoggedIn = async () => {
    if (!currentUser) {
      const isConfirmed = await MessageBoxConfirm(
        t('login-required'),
        t('goto-login')
      );
      if (isConfirmed) {
        navigate('/login');
      }
      return false;
    }
    return true;
  };

  const handleUpload = useCallback(async () => {
    if (!(await isLoggedIn())) return;
    const showAlertIfEmpty = (value, message) => {
      if (!value.trim()) {
        MessageBoxInform(message);
        throw new Error(message);
      }
    };

    try {
      showAlertIfEmpty(productName, t('enterProductName'));
      showAlertIfEmpty(description, t('enterDescription'));
      showAlertIfEmpty(stock, t('enterStock'));
      showAlertIfEmpty(price, t('enterPrice'));

      if (!selectedCategory || selectedCategory.length === 0) {
        MessageBoxInform(t('selectCategory'));
        throw new Error(t('selectCategory'));
      }

      if (!selectedBrand || selectedBrand.length === 0) {
        MessageBoxInform(t('selectBrand'));
        throw new Error(t('selectBrand'));
      }

      if (!selectedFiles.length) {
        await MessageBoxInform(t('uploadImages'));
        throw new Error(t('uploadImages'));
      }

      // if (!selectedArea || selectedArea.length === 0) {
      //   await MessageBoxInform(t('selectArea'));
      //   throw new Error(t('selectArea'));
      // }

      const isConfirmed = await MessageBoxConfirm(
        t('confirmStoreUpload'),
        `${t('title')}: ${productName}\n${t('content')}: ${description}\n${t(
          'stock'
        )}: ${stock}\n${t('price')}: ${formatPrice(price)} (${koreanPrice} 원)`
      );

      if (!isConfirmed) return;

      setProgressModalOpen(true);
      setUploadingFiles(selectedFiles);

      const productRef = await addDoc(
        collection(db, 'stores', currentUser.uid, 'products'),
        {
          productName,
          categoryId: selectedCategory?.id || null,
          brandId: selectedBrand?.id || null,
          price,
          description,
          stock: stock,
          createDate: new Date(),
          uid: currentUser.uid,
          likes: 0,
          views: 0,
          chats: 0,
          state: 0,
          //selectedArea: selectedArea,
        }
      );

      const imageSizes = [
        {
          width: 1920,
          height: 1080,
          collectionName: 'xlarge_images',
          prefix: 'xlarge_',
        },
        // {
        //   width: 1366,
        //   height: 768,
        //   collectionName: 'large_images',
        //   prefix: 'large_',
        // },
        // {
        //   width: 854,
        //   height: 480,
        //   collectionName: 'medium_images',
        //   prefix: 'medium_',
        // },
        // {
        //   width: 640,
        //   height: 360,
        //   collectionName: 'small_images',
        //   prefix: 'small_',
        // },
        // {
        //   width: 426,
        //   height: 240,
        //   collectionName: 'xsmall_images',
        //   prefix: 'xsmall_',
        // },
        {
          width: 213,
          height: 120,
          collectionName: 'icon_images',
          prefix: 'icon_',
        },
      ];

      for (const imageSize of imageSizes) {
        const fileUrls = await uploadImageFiles(
          productRef.id,
          imageSize.width,
          imageSize.height,
          imageSize.prefix
        );
        const imageUpdates = {};
        imageUpdates[imageSize.collectionName] = fileUrls;
        await updateDoc(productRef, imageUpdates);
      }

      if (
        hasPromotion &&
        promotionStartDate &&
        promotionEndDate &&
        promotionContent
      ) {
        const promotionsRef = collection(
          db,
          'stores',
          currentUser.uid,
          'promotions'
        );
        const promotionData = {
          name: promotionName,
          content: promotionContent,
          startDate: promotionStartDate,
          endDate: promotionEndDate,
        };
        await addDoc(promotionsRef, promotionData);
      }

      await MessageBoxInform(t('storeProductUploaded'));
      navigate(`/mystore/${currentUser.uid}`);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setProgressModalOpen(false);
      setUploadingFiles([]);
      setSelectedFiles([]);
      setUploadProgress({});
      setTotalFileSize(0);
      setUploadedFileSize(0);
      setPreviews([]);
      resetFileInput();

      setShowMap(false);
    }
  }, [
    productName,
    description,
    selectedFiles,
    formatPrice,
    koreanPrice,
    // selectedArea,
    selectedCategory,
    selectedBrand,
    stock,
    hasPromotion,
    promotionStartDate,
    promotionEndDate,
    promotionContent,
    MessageBoxInform,
    MessageBoxConfirm,
    currentUser.uid,
    t,
    navigate,
  ]);

  const handleCancel = () => {
    navigate(-1);
  };

  const handlePromotionToggle = (e) => {
    setHasPromotion(e.target.checked);
  };

  const onSelectCategory = async (selectedCategory) => {
    setSelectedCategory(selectedCategory);
  };

  const onSelectBrand = async (selectedBrand) => {
    setSelectedBrand(selectedBrand);
  };

  const totalProgress = Object.values(uploadProgress).reduce(
    (acc, { progress, fileSize }) => {
      acc.bytesTransferred += (progress / 100) * fileSize;
      acc.totalBytes += fileSize;
      return acc;
    },
    { bytesTransferred: 0, totalBytes: 0 }
  );

  const overallProgress =
    totalProgress.totalBytes > 0
      ? (totalProgress.bytesTransferred / totalProgress.totalBytes) * 100
      : 0;

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Typography variant="h4" gutterBottom>
        {t('uploadStoreProduct')}
      </Typography>

      <Box component="form" sx={{ mt: 2 }}>
        <TextField
          fullWidth
          label={t('productName')}
          value={productName}
          onChange={(e) => setProductName(e.target.value)}
          margin="normal"
        />

        <TextField
          fullWidth
          label={t('description')}
          value={description}
          onChange={handleDescriptionChange}
          margin="normal"
          multiline
          rows={4}
          helperText={`${contentByteSize}/200 ${t('bytes')}`}
        />

        {MessageBoxRender()}

        <FormControlLabel
          control={
            <Checkbox checked={hasPromotion} onChange={handlePromotionToggle} />
          }
          label={t('applyPromotion')}
        />

        {hasPromotion && (
          <Box sx={{ mt: 2 }}>
            <TextField
              fullWidth
              label={t('promotionName')}
              value={promotionName}
              onChange={(e) => setPromotionName(e.target.value)}
              margin="normal"
            />
            <TextField
              fullWidth
              label={t('promotionContent')}
              value={promotionContent}
              onChange={(e) => setPromotionContent(e.target.value)}
              margin="normal"
            />
            <TextField
              fullWidth
              label={t('startDate')}
              type="datetime-local"
              value={promotionStartDate}
              onChange={(e) => setPromotionStartDate(e.target.value)}
              margin="normal"
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              fullWidth
              label={t('endDate')}
              type="datetime-local"
              value={promotionEndDate}
              onChange={(e) => setPromotionEndDate(e.target.value)}
              margin="normal"
              InputLabelProps={{ shrink: true }}
            />
          </Box>
        )}

        <TextField
          fullWidth
          label={t('stock')}
          value={stock}
          onChange={handleStockChange}
          margin="normal"
        />

        <ProductCategorySelector onSelectCategory={onSelectCategory} />

        {selectedCategory && (
          <ProductBrandSelector
            selectedCategory={selectedCategory}
            onSelectBrand={onSelectBrand}
          />
        )}

        <TextField
          fullWidth
          label={t('price')}
          value={formatPrice(price)}
          onChange={handlePriceChange}
          margin="normal"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">{t('currency')}</InputAdornment>
            ),
          }}
          helperText={koreanPrice !== '0' ? `${koreanPrice}원` : t('free')}
        />

        <Box sx={{ mb: 2 }}>
          <Button
            variant="contained"
            component="label"
            startIcon={<CloudUploadIcon />}
          >
            {t('uploadImage')}
            <input
              type="file"
              hidden
              multiple
              onChange={handleImageChange}
              ref={fileInputRef}
            />
          </Button>
          <Typography variant="body2" sx={{ ml: 2 }}>
            {selectedFiles.length} {t('filesSelected')}
          </Typography>
        </Box>

        <Grid container spacing={2}>
          {previews.map((preview, index) => (
            <Grid item xs={6} sm={4} md={3} key={index}>
              <Box position="relative">
                <Avatar
                  src={preview}
                  variant="rounded"
                  sx={{ width: '100%', height: 'auto' }}
                />
                <Tooltip title={t('remove')}>
                  <IconButton
                    size="small"
                    onClick={() => handleRemoveImage(index)}
                    sx={{ position: 'absolute', top: 0, right: 0 }}
                  >
                    <CancelIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
          ))}
        </Grid>

        {/* 배송지역 지정을 상품 등록시 마다 하지 않고 최초 스토어 가입시 그때 배송가능지역 설정하는것으로함
        <Box sx={{ mt: 4 }}>
          <GoogleMapRegion onSave={handleSaveArea} />
        </Box> */}

        <Box
          sx={{
            position: 'fixed',
            bottom: isMobile ? 50 : isPad ? 30 : 0,
            left: 0,
            right: 0,
            bgcolor: 'background.paper',
            boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)',
            py: 2,
            px: 3,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={handleCancel}
            sx={{ flex: 1, mr: 1 }}
          >
            {t('cancel')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpload}
            sx={{ flex: 1, mr: 1 }}
          >
            {t('upload')}
          </Button>
        </Box>
      </Box>

      <Modal
        open={progressModalOpen}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            setProgressModalOpen(false);
          }
        }}
        aria-labelledby="progress-modal-title"
        aria-describedby="progress-modal-description"
        BackdropProps={{
          onClick: (event) => event.stopPropagation(),
        }}
      >
        <Box
          sx={{
            ...modalStyle,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <List>
            {uploadingFiles.map((file, index) => (
              <ListItem key={index}>
                <ListItemText
                  primary={file.name}
                  secondary={`${Math.round(
                    uploadProgress[file.name]?.progress || 0
                  )}%`}
                />
                <LinearProgress
                  variant="determinate"
                  value={uploadProgress[file.name]?.progress || 0}
                  sx={{ width: '100px', marginLeft: '10px' }}
                />
              </ListItem>
            ))}
          </List>
          <Button
            variant="contained"
            onClick={() => setUploadingFiles([])}
            disabled={uploadingFiles.some(
              ({ name }) => (uploadProgress[name]?.progress || 0) < 100
            )}
          >
            {t('close')}
          </Button>
        </Box>
      </Modal>
    </Container>
  );
};

export default StoreProductUpload;
