import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Grid,
  Typography,
  CircularProgress,
  AppBar,
  Toolbar,
  IconButton,
  Paper,
  Button,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTranslation } from 'react-i18next';
import Product from './Product';
import useUserActivity from './useUserActivity';
import useStoreUserActivity from './Stores/useStoreUserActivity';
import {
  exportFetchSelectedTownByUserId,
  exportFetchUserProductsByCoordinatesAsArray,
  exportFetchStoreProductsByCoordinatesAsMap,
  exportFetchUserProductsByLastDocAsMap,
  exportFetchStoresByLastDocAsMap,
} from './Utils/LocationStoresAndProducts';
import StoreProduct from './Stores/StoreProduct';
import Lottie from 'lottie-react';
import noUserProductResults from './assets/lottie/no-userproduct-results.json';
import noStoreProductResults from './assets/lottie/no-storeproduct-results.json';

const PAGE_SIZE = 12;

const ProductBrandSearchResults = () => {
  const { t } = useTranslation();
  const [products, setProducts] = useState([]);
  const [storeProducts, setStoreProducts] = useState([]);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const searchBrand = useSelector((state) => state.search.searchBrand);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [userLastDoc, setUserLastDoc] = useState(null);
  const [storeLastDoc, setStoreLastDoc] = useState(null);
  const [userTotalProducts, setUserTotalProducts] = useState(0);
  const [storeTotalProducts, setStoreTotalProducts] = useState(0);
  const navigate = useNavigate();

  const handleNavigateHome = () => {
    navigate('/');
  };

  const { logViewedProduct } = useUserActivity(currentUser?.uid);
  const { logViewedStoreProduct } = useStoreUserActivity(currentUser?.uid);

  useEffect(() => {
    if (searchBrand) {
      handleSearchBrand(searchBrand, null, null, true);
    }
  }, [searchBrand]);

  async function handleSearchBrand(
    brand,
    userLastDoc,
    storeLastDoc,
    reset = false
  ) {
    setLoading(true);
    setError(null);

    try {
      let location = null;
      if (currentUser) {
        location = await exportFetchSelectedTownByUserId(currentUser?.uid);
      }

      if (!currentUser || !location) {
        const allUserProducts = await searchAllUserProducts(brand, userLastDoc);
        const allStoreProducts = await searchAllStoreProducts(
          brand,
          storeLastDoc
        );

        if (reset) {
          setProducts(allUserProducts.products);
          setStoreProducts(allStoreProducts.products);
        } else {
          setProducts((prevProducts) => [
            ...prevProducts,
            ...allUserProducts.products,
          ]);
          setStoreProducts((prevStoreProducts) => [
            ...prevStoreProducts,
            ...allStoreProducts.products,
          ]);
        }

        setUserTotalProducts(allUserProducts.totalCount);
        setStoreTotalProducts(allStoreProducts.totalCount);
        setUserLastDoc(allUserProducts.lastDoc);
        setStoreLastDoc(allStoreProducts.lastDoc);
      } else {
        const productResults = await searchUserProducts(
          brand,
          location,
          userLastDoc
        );
        const storeProductResults = await searchStoreProducts(
          brand,
          location,
          storeLastDoc
        );

        if (reset) {
          setProducts(productResults.products);
          setStoreProducts(storeProductResults.products);
        } else {
          setProducts((prevProducts) => [
            ...prevProducts,
            ...productResults.products,
          ]);
          setStoreProducts((prevStoreProducts) => [
            ...prevStoreProducts,
            ...storeProductResults.products,
          ]);
        }

        setUserTotalProducts(productResults.totalCount);
        setStoreTotalProducts(storeProductResults.totalCount);
        setUserLastDoc(productResults.lastDoc);
        setStoreLastDoc(storeProductResults.lastDoc);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }

  async function searchUserProducts(brand, location, lastDoc) {
    const userProductsArray = await exportFetchUserProductsByCoordinatesAsArray(
      location.lat,
      location.lng,
      location.radius,
      currentUser.uid,
      PAGE_SIZE,
      lastDoc
    );

    const filteredProducts = userProductsArray.filter(
      (product) => product.brandId === brand.id
    );

    return {
      products: filteredProducts,
      lastDoc: products.lastDoc,
      total: filteredProducts.length,
    };
  }

  async function searchStoreProducts(brand, location, lastDoc) {
    try {
      const storeProducts = await exportFetchStoreProductsByCoordinatesAsMap(
        location.lat,
        location.lng,
        location.radius,
        lastDoc
      );

      // Ensure storeProducts.products is always an array
      const productsArray = Object.values(storeProducts.products || {});

      const filteredStoreProducts = productsArray.filter(
        (product) => product.brandId === brand.id
      );

      return {
        products: filteredStoreProducts,
        lastDoc: storeProducts.lastDoc,
        total: filteredStoreProducts.length,
      };
    } catch (error) {
      console.error('Error searching store products:', error);
      return {
        products: [],
        lastDoc: null,
        total: 0,
      };
    }
  }

  async function searchAllUserProducts(brand, lastDoc) {
    const result = await exportFetchUserProductsByLastDocAsMap(
      PAGE_SIZE,
      lastDoc
    );
    const products = result.products || [];

    const filteredProducts = products.filter(
      (product) => product.brandId === brand.id
    );

    return {
      products: filteredProducts,
      lastDoc: result.lastDoc,
      total: filteredProducts.length,
    };
  }

  async function searchAllStoreProducts(brand, lastDoc) {
    try {
      const result = await exportFetchStoresByLastDocAsMap(PAGE_SIZE, lastDoc);

      // Ensure result.products is always an array
      const productsArray = result.products || [];

      const filteredProducts = productsArray.filter(
        (product) => product.brandId === brand.id
      );

      return {
        products: filteredProducts,
        lastDoc: result.lastDoc,
        total: filteredProducts.length,
      };
    } catch (error) {
      console.error('Error searching all store products:', error);
      return {
        products: [],
        lastDoc: null,
        total: 0,
      };
    }
  }

  const handleProductClick = (productId) => {
    logViewedProduct(productId);
  };

  const handleStoreProductClick = (storeId, productId) => {
    logViewedStoreProduct(storeId, productId);
  };

  const handleUserPageChange = async () => {
    await handleSearchBrand(searchBrand, userLastDoc, storeLastDoc);
  };

  const handleStorePageChange = async () => {
    await handleSearchBrand(searchBrand, userLastDoc, storeLastDoc);
  };

  return (
    <>
      <AppBar position="static" color="default" elevation={1}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label={t('navigation.home')}
            onClick={handleNavigateHome}
          >
            <ArrowBackIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box sx={{ width: '100%', maxWidth: '100%', mx: 'auto', px: 2 }}>
        {loading && !products.length && !storeProducts.length ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="50vh"
          >
            <CircularProgress />
          </Box>
        ) : error ? (
          <Typography color="error">{`${t(
            'error.errorMessage'
          )} ${error}`}</Typography>
        ) : (
          <>
            <Typography variant="h4">
              {t('brandSearchResults.title')}
            </Typography>
            <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>
              {t('brandSearchResults.userProducts')}
            </Typography>
            <Grid container spacing={2}>
              {products.length > 0 ? (
                products.map((product) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    xl={2}
                    key={product.id}
                  >
                    <Product
                      key={product.id}
                      product={product}
                      onClick={() => handleProductClick(product.id)}
                    />
                  </Grid>
                ))
              ) : (
                <Grid item xs={12}>
                  <Paper elevation={3} sx={{ padding: 2, textAlign: 'center' }}>
                    <Lottie
                      animationData={noUserProductResults}
                      style={{ height: 300 }}
                    />
                    <Typography>
                      {t('brandSearchResults.noUserResults')}
                    </Typography>
                  </Paper>
                </Grid>
              )}
            </Grid>
            {userLastDoc && (
              <Button onClick={handleUserPageChange}>{t('loadMore')}</Button>
            )}
            <Typography variant="h6" sx={{ mt: 4, mb: 4 }}>
              {t('brandSearchResults.storeProducts')}
            </Typography>
            <Grid container spacing={2}>
              {storeProducts.length > 0 ? (
                storeProducts.map((product) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    xl={2}
                    key={product.id}
                  >
                    <StoreProduct
                      key={product.id}
                      product={product}
                      onClick={() =>
                        handleStoreProductClick(currentUser?.uid, product.id)
                      }
                    />
                  </Grid>
                ))
              ) : (
                <Grid item xs={12}>
                  <Paper elevation={3} sx={{ padding: 2, textAlign: 'center' }}>
                    <Lottie
                      animationData={noStoreProductResults}
                      style={{ height: 300 }}
                    />
                    <Typography>
                      {t('brandSearchResults.noStoreResults')}
                    </Typography>
                  </Paper>
                </Grid>
              )}
            </Grid>
            {storeLastDoc && (
              <Button onClick={handleStorePageChange}>{t('loadMore')}</Button>
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default ProductBrandSearchResults;
