// export function numberToKorean(number) {
//     const numbers = ["", "일", "이", "삼", "사", "오", "육", "칠", "팔", "구"];
//     const units = ["", "십", "백", "천"];
//     const bigUnits = ["", "만", "억", "조", "경"]; // 큰 단위를 더 추가할 경우 여기에 추가합니다.

//     let result = "";
//     let bigUnitPosition = 0;

//     const readNumber = (segment) => {
//         let unitPosition = 0;
//         let koreanSegment = "";

//         while (segment > 0) {
//             const currentNumber = segment % 10;
//             if (currentNumber !== 0) {
//                 koreanSegment = numbers[currentNumber] + units[unitPosition] + koreanSegment;
//             }
//             segment = Math.floor(segment / 10);
//             unitPosition++;
//         }

//         return koreanSegment;
//     };

//     while (number > 0) {
//         const currentSegment = number % 10000;
//         if (currentSegment !== 0) {
//             result = readNumber(currentSegment) + bigUnits[bigUnitPosition] + result;
//         }

//         number = Math.floor(number / 10000);
//         bigUnitPosition++;
//     }

//     return result || "0";

// }
export function numberToKorean(number) {
  const numbers = ['', '일', '이', '삼', '사', '오', '육', '칠', '팔', '구'];
  const units = ['', '십', '백', '천'];
  const bigUnits = ['', '만', '억', '조', '경'];

  let result = '';
  let bigUnitPosition = 0;

  const readNumber = (segment) => {
    let unitPosition = 0;
    let koreanSegment = '';

    while (segment > 0) {
      const currentNumber = segment % 10;

      if (currentNumber !== 0) {
        // 현재 숫자가 1이고, 십의 자리일 경우 '일'은 생략한다.
        const numString =
          unitPosition === 1 && currentNumber === 1
            ? ''
            : numbers[currentNumber];
        koreanSegment = numString + units[unitPosition] + koreanSegment;
      }

      segment = Math.floor(segment / 10);
      unitPosition++;
    }

    return koreanSegment;
  };

  while (number > 0) {
    const currentSegment = number % 10000;

    if (currentSegment !== 0) {
      result = readNumber(currentSegment) + bigUnits[bigUnitPosition] + result;
    }

    number = Math.floor(number / 10000);
    bigUnitPosition++;
  }

  return result || '0';
}

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
// 입력된 문자열의 바이트 수를 계산하는 함수
export function getByteLength(str) {
  return new Blob([str]).size;
}
export function formatPrice(price) {
  if (!price || price === '') return '0원';

  const formattedNumber = numberWithCommas(price);

  return `${formattedNumber}`;
}
