import { db, collection, getDocs, doc, getDoc } from '../firebaseConfig';

export const fetchUserAgreementStatus = async (userId) => {
  const userDoc = await getDoc(doc(db, 'users', userId));
  if (!userDoc.exists()) {
    return { needsTerms: false, needsPrivacy: false };
  }

  const userData = userDoc.data();
  if (userData.step !== 'signupCompleted') {
    return { needsTerms: false, needsPrivacy: false };
  }

  const termsCollection = collection(db, 'terms');
  const termsSnapshot = await getDocs(termsCollection);
  const allVersions = termsSnapshot.docs.map((doc) => doc.data().version);
  const latestVersion = Math.max(...allVersions);

  const termsDoc = termsSnapshot.docs.find(
    (doc) => doc.data().version === latestVersion
  );
  const termsDocId = termsDoc ? termsDoc.id : null;

  const privacyListCollection = collection(db, 'privacy');
  const privacyListSnapshot = await getDocs(privacyListCollection);
  const allPrivacyVersions = privacyListSnapshot.docs.map(
    (doc) => doc.data().version
  );
  const latestPrivacyVersion = Math.max(...allPrivacyVersions);

  const privacyListDoc = privacyListSnapshot.docs.find(
    (doc) => doc.data().version === latestPrivacyVersion
  );
  const privacyListDocId = privacyListDoc ? privacyListDoc.id : null;

  let needsTerms = false;
  let needsPrivacy = false;

  if (termsDocId) {
    const userTermsCollection = collection(
      db,
      'users',
      userId,
      'terms',
      termsDocId,
      'items'
    );
    const userTermsSnapshot = await getDocs(userTermsCollection);
    if (userTermsSnapshot.empty) {
      needsTerms = true;
    } else {
      const agreements = {};
      userTermsSnapshot.docs.forEach((itemDoc) => {
        const itemId = itemDoc.id;
        agreements[`${termsDocId}_${itemId}`] = true;
      });

      const allRequiredAgreed = termsDoc
        .data()
        .items.filter((item) => item.type === 'required')
        .every((item) => agreements[`${termsDocId}_${item.id}`]);

      if (!allRequiredAgreed) {
        needsTerms = true;
      }
    }
  }

  if (privacyListDocId) {
    const userPrivacyCollection = collection(
      db,
      'users',
      userId,
      'privacy',
      privacyListDocId,
      'items'
    );
    const userPrivacySnapshot = await getDocs(userPrivacyCollection);
    if (userPrivacySnapshot.empty) {
      needsPrivacy = true;
    } else {
      const agreements = {};
      userPrivacySnapshot.docs.forEach((itemDoc) => {
        const itemId = itemDoc.id;
        agreements[`${privacyListDocId}_${itemId}`] = true;
      });

      const allRequiredAgreed = privacyListDoc
        .data()
        .items.filter((item) => item.type === 'required')
        .every((item) => agreements[`${privacyListDocId}_${item.id}`]);

      if (!allRequiredAgreed) {
        needsPrivacy = true;
      }
    }
  }

  return { needsTerms, needsPrivacy };
};
