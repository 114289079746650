import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ChatIcon from '@mui/icons-material/Chat';
import CancelIcon from '@mui/icons-material/Cancel';

export const formatState = (state, t) => {
  switch (state) {
    case 0:
      return {
        icon: <CheckCircleIcon />,
        text: t('selling'),
        color: 'success',
      };
    case 1:
      return {
        icon: <FavoriteIcon />,
        text: t('reserved'),
        color: 'info',
      };
    case 2:
      return {
        icon: <ChatIcon />,
        text: t('sold-out'),
        color: 'secondary',
      };
    default:
      return {
        icon: <CancelIcon />,
        text: t('unknown'),
        color: 'error',
      };
  }
};
