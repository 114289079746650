import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Container,
  Typography,
  CircularProgress,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Select,
  MenuItem,
  Box,
} from '@mui/material';
import {
  db,
  collection,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
} from '../firebaseConfig';
import useOrder from './StoreAdmin/useOrder';

function OrderStatus() {
  const { userId } = useParams();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { updateOrderStatus } = useOrder();

  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      try {
        const orders = await getAllOrdersForUser(userId);
        setOrders(orders);
        setLoading(false);
      } catch (err) {
        setError(`Failed to fetch orders: ${err.message}`);
        setLoading(false);
      }
    };

    fetchOrders();
  }, [userId]);

  const getAllOrdersForUser = async (userId) => {
    try {
      const allOrders = [];
      const storesRef = collection(db, 'stores');
      const storesSnapshot = await getDocs(storesRef);

      for (let doc of storesSnapshot.docs) {
        const storeId = doc.id;
        const ordersRef = collection(db, 'stores', storeId, 'orders');
        const q = query(ordersRef, where('userId', '==', userId));
        const querySnapshot = await getDocs(q);
        const orders = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          storeName: doc.data().storeName,
        }));
        allOrders.push(...orders);
      }
      return allOrders;
    } catch (error) {
      console.error('Error fetching orders:', error);
      throw error;
    }
  };

  const handleOrderStatusChange = async (order, newStatus) => {
    setLoading(true);
    try {
      const orderRef = doc(db, 'stores', order.storeId, 'orders', order.id);
      await updateDoc(orderRef, {
        orderStatus: newStatus,
      });
      // Refresh the orders list to reflect the change
      const updatedOrders = await getAllOrdersForUser(userId);
      setOrders(updatedOrders);
    } catch (error) {
      console.error('Error updating order status:', error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  if (!orders.length) {
    return <Typography variant="h6">No orders found.</Typography>;
  }

  return (
    <Container>
      <Typography variant="h4" sx={{ my: 2 }}>
        Manage Order Status
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Order ID</TableCell>
            <TableCell>Store Name</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map((order) => (
            <TableRow key={order.id}>
              <TableCell>{order.id}</TableCell>
              <TableCell>{order.storeName}</TableCell>
              <TableCell>{order.date}</TableCell>
              <TableCell>
                <Select
                  value={order.orderStatus}
                  onChange={(e) =>
                    handleOrderStatusChange(order, e.target.value)
                  }
                  displayEmpty
                >
                  <MenuItem value="pending">Pending</MenuItem>
                  <MenuItem value="shipped">Shipped</MenuItem>
                  <MenuItem value="delivered">Delivered</MenuItem>
                  <MenuItem value="canceled">Canceled</MenuItem>
                  <MenuItem value="returned">Returned</MenuItem>
                </Select>
              </TableCell>
              <TableCell>{order.orderStatus}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Container>
  );
}

export default OrderStatus;
