// // Comment.js (새 컴포넌트 파일)
// import React, { useState, useEffect } from 'react';
// import { db } from '../firebaseConfig';
// import { collection, query, where, getDocs, orderBy } from 'firebase/firestore';
// import ProductReviewCommentForm from './ProductReviewCommentForm';

// const getIndentStyle = (depth) => ({
//     marginLeft: `${depth * 20}px`, // depth 값에 따라 왼쪽 여백을 조절
//     paddingLeft: '10px',
//     borderLeft: '2px solid #ddd' // 계층 구조를 나타내는 선을 추가
//   });

// const ProductReviewComment = ({ storeId, productId, reviewId, parentId = null, depth = 0 }) => {
//   const [comments, setComments] = useState([]);
//   const [hasComments, setHasComments] = useState(false);

// useEffect(() => {
//     const fetchComments = async () => {
//       const commentsRef = collection(db, 'stores', storeId, 'products', productId, 'reviews', reviewId, 'comments');
//       const q = query(commentsRef, orderBy("timestamp")); // 모든 댓글 조회

//       try {
//         const querySnapshot = await getDocs(q);
//         const allComments = querySnapshot.docs.map(doc => ({
//           id: doc.id,
//           ...doc.data()
//         }));

//         // 클라이언트 측에서 최상위 댓글 필터링
//         const filteredComments = allComments.filter(comment =>
//           parentId === null ? !comment.parentId : comment.parentId === parentId
//         );
//         setHasComments(filteredComments.length > 0);
//         setComments(filteredComments);
//       } catch (error) {
//         console.error('Error fetching comments:', error);
//         alert('Error fetching comments: ' + error.message);
//       }
//     };

//     fetchComments();
//   }, [storeId, productId, reviewId, parentId]);

//   const addCommentToList = (newComment) => {
//     // setComments(prevComments => [...prevComments, newComment]);
//     <ProductReviewComment
//                 storeId={storeId}
//                 productId={productId}
//                 reviewId={reviewId}
//                 parentId={newComment.parentId}
//                 depth={depth + 1}
//               />
//   };
//   return (
//     <div>
//       {comments.length > 0 ? (
//         comments.map((comment, index) => (
//           <div key={comment.id} style={getIndentStyle(depth)}>
//             <p>{comment.text} - 작성자: {comment.commentName}</p>
//             {/* depth < 2 &&  */}
//             {(
//               <ProductReviewComment
//                 storeId={storeId}
//                 productId={productId}
//                 reviewId={reviewId}
//                 parentId={comment.id}
//                 depth={depth + 1}
//               />
//             )}

//             {/* 대댓글 중 마지막인 경우에만 입력창을 보이기 depth < 2 && */}
//           {(
//             <div style={getIndentStyle(depth + 1)}>
//               <ProductReviewCommentForm
//                 storeId={storeId}
//                 productId={productId}
//                 reviewId={reviewId}
//                 parentId={comment.id}
//                 onCommentAdded={addCommentToList}
//               />
//             </div>
//           )}
//           </div>

//         ))
//       ) : (
//         // `comments` 배열이 비어 있거나 `undefined`일 때 댓글 입력 폼을 렌더링
//         // 첫 번째 depth에서만 댓글 입력 폼을 보여줌을 가정
//         depth === 0 && (
//           <div style={getIndentStyle(1)}>
//             <ProductReviewCommentForm
//               storeId={storeId}
//               productId={productId}
//               reviewId={reviewId}
//               parentId={null} // 최상위 댓글의 경우 `parentId`는 `null`일 수 있음
//             />
//           </div>
//         )
//       )}
//     </div>
//   );

// };

// export default ProductReviewComment;

import React, { useState, useEffect } from 'react';
import { db } from '../firebaseConfig';
import { collection, query, getDocs, orderBy, where } from 'firebase/firestore';
import ProductReviewCommentForm from './ProductReviewCommentForm';

const getIndentStyle = (depth) => ({
  marginLeft: `${depth * 20}px`,
  paddingLeft: '10px',
  borderLeft: '2px solid #ddd',
});

const ProductReviewComment = ({
  storeId,
  productId,
  reviewId,
  parentId = null,
  depth = 0,
}) => {
  const [comments, setComments] = useState([]);

  // useEffect(() => {
  //   const fetchComments = async () => {
  //     const commentsRef = collection(db, 'stores', storeId, 'products', productId, 'reviews', reviewId, 'comments');
  //     const q = query(commentsRef, where("parentId", "==", parentId || null), orderBy("timestamp"));
  //     try {
  //       const querySnapshot = await getDocs(q);
  //       const allComments = querySnapshot.docs.map(doc => ({
  //         id: doc.id,
  //         ...doc.data()
  //       }));
  //       setComments(allComments);
  //     } catch (error) {
  //       console.error('Error fetching comments:', error);
  //     }
  //   };
  //   fetchComments();
  // }, [storeId, productId, reviewId, parentId]);

  useEffect(() => {
    const fetchComments = async () => {
      const commentsRef = collection(
        db,
        'stores',
        storeId,
        'products',
        productId,
        'reviews',
        reviewId,
        'comments'
      );
      const q = query(
        commentsRef,
        where('parentId', '==', parentId || null),
        orderBy('timestamp')
      );
      try {
        const querySnapshot = await getDocs(q);
        const allComments = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setComments(allComments);
      } catch (error) {
        if (
          error.code === 'failed-precondition' ||
          error.code === 'unavailable'
        ) {
          console.error(
            'Firestore index error: Please create the required index in the Firestore console.',
            error
          );
        } else {
          console.error('Error fetching comments:', error);
        }
      }
    };
    fetchComments();
  }, [storeId, productId, reviewId, parentId]);

  const addCommentToList = (newComment) => {
    setComments((prevComments) => [...prevComments, newComment]);
  };

  return (
    <div>
      {comments.map((comment) => (
        <div key={comment.id} style={getIndentStyle(depth)}>
          <p>
            {comment.text} - 작성자: {comment.commentName}
          </p>
          <ProductReviewComment
            storeId={storeId}
            productId={productId}
            reviewId={reviewId}
            parentId={comment.id}
            depth={depth + 1}
          />
        </div>
      ))}
      {
        <div style={getIndentStyle(depth + 1)}>
          <ProductReviewCommentForm
            storeId={storeId}
            productId={productId}
            reviewId={reviewId}
            parentId={parentId}
            onCommentAdded={addCommentToList}
          />
        </div>
      }
    </div>
  );
};

export default ProductReviewComment;
