// import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import { db, collection, getDocs, query, where } from './firebaseConfig';
// import Product from './Product';
// import { Grid, Typography, Box } from '@mui/material';
// import { useTranslation } from 'react-i18next';

// export default function BuyList() {
//   const { t, i18n } = useTranslation();
//   const [buyingProducts, setBuyingProducts] = useState([]);
//   const currentUser = useSelector((state) => state.auth.currentUser);

//   const fetchMyBuyingProducts = async () => {
//     if (!currentUser) return [];
//     const buyingProductsQuery = query(
//       collection(db, 'products'),
//       where('endUsers.take', '==', currentUser.uid)
//     );
//     const buyingProductsSnapshot = await getDocs(buyingProductsQuery);
//     return buyingProductsSnapshot.docs.map((doc) => ({
//       id: doc.id,
//       ...doc.data(),
//     }));
//   };

//   useEffect(() => {
//     fetchMyBuyingProducts()
//       .then(setBuyingProducts)
//       .catch((err) => {
//         console.error('Error fetching buying products:', err);
//       });
//   }, [currentUser]);

//   const handleProductClick = (product) => {
//     window.open(`/detail/${product.id}`, '_blank', 'noopener,noreferrer');
//   };

//   return (
//     <Box
//       p={3}
//       sx={{
//         width: '100%',
//         maxWidth: '100%',
//         margin: 'auto',
//       }}
//     >
//       <Box
//         sx={{
//           display: 'inline-block',
//           border: '0px solid #d3d3d3', // 연한 회색 테두리
//           borderRadius: '16px', // 둥근 테두리
//           padding: '8px 16px', // 안쪽 여백
//           marginBottom: '16px', // 아래쪽 여백
//           backgroundColor: 'transparent', // 연한 회색 배경색
//           transition: 'transform 0.2s', // 호버 시 애니메이션
//           '&:hover': {
//             transform: 'scale(1.02)', // 호버 시 크기 증가
//           },
//           my: '30px',
//         }}
//       >
//         <Typography variant="h4" component="h3" gutterBottom>
//           {t('myBuyingProducts')}
//         </Typography>
//       </Box>
//       <Grid container spacing={2}>
//         {buyingProducts.length === 0 ? (
//           <Grid item xs={12}>
//             <Typography textAlign="center">{t('noBuyingProducts')}</Typography>
//           </Grid>
//         ) : (
//           buyingProducts.map((product) => (
//             <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={product.id}>
//               <Product
//                 key={product.id}
//                 product={product}
//                 onClick={() => handleProductClick(product)}
//               />
//             </Grid>
//           ))
//         )}
//       </Grid>
//     </Box>
//   );
// }

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  db,
  collection,
  getDocs,
  query,
  where,
  orderBy,
  startAfter,
  limit,
} from './firebaseConfig';
import Product from './Product';
import {
  Grid,
  Typography,
  Box,
  CircularProgress,
  TablePagination,
  useMediaQuery,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTheme } from '@mui/material/styles';

export default function BuyList() {
  const { t, i18n } = useTranslation();
  const [buyingProducts, setBuyingProducts] = useState([]);
  const [lastProduct, setLastProduct] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const itemsPerPage = 10;
  const currentUser = useSelector((state) => state.auth.currentUser);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [error, setError] = useState('');
  // const fetchMyBuyingProducts = async (
  //   pageSize = itemsPerPage,
  //   lastDoc = null
  // ) => {
  //   if (!currentUser) return [];
  //   let q = query(
  //     collection(db, 'products'),
  //     where('endUsers.take', '==', currentUser.uid),
  //     orderBy('createDate', 'desc'),
  //     limit(pageSize)
  //   );
  //   if (lastDoc) {
  //     q = query(q, startAfter(lastDoc));
  //   }
  //   const snapshot = await getDocs(q);
  //   const products = snapshot.docs.map((doc) => ({
  //     id: doc.id,
  //     ...doc.data(),
  //   }));
  //   setLastProduct(snapshot.docs[snapshot.docs.length - 1]);
  //   setHasMore(products.length === pageSize);
  //   return products;
  // };

  const fetchMyBuyingProducts = async (
    pageSize = itemsPerPage,
    lastDoc = null
  ) => {
    if (!currentUser) return [];

    try {
      let q = query(
        collection(db, 'products'),
        where('endUsers.take', '==', currentUser.uid),
        orderBy('createDate', 'desc'),
        limit(pageSize)
      );
      if (lastDoc) {
        q = query(q, startAfter(lastDoc));
      }

      const snapshot = await getDocs(q);
      const products = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setLastProduct(snapshot.docs[snapshot.docs.length - 1]);
      setHasMore(products.length === pageSize);
      return products;
    } catch (err) {
      if (err.code === 'failed-precondition' || err.code === 'unavailable') {
        console.error('Firestore index error:', err);
        setError((prevErrors) => [
          ...prevErrors,
          '필요한 인덱스가 없습니다. Firestore 콘솔에서 인덱스를 생성하세요.',
        ]);
      } else {
        console.error('Error fetching products:', err);
        setError((prevErrors) => [
          ...prevErrors,
          `Error fetching products: ${err.message}`,
        ]);
      }
      return [];
    }
  };

  const loadMoreProducts = async () => {
    const newProducts = await fetchMyBuyingProducts(itemsPerPage, lastProduct);
    setBuyingProducts((prev) => [...prev, ...newProducts]);
  };

  const handlePageChange = async (event, newPage) => {
    setPage(newPage);
    const newProducts = await fetchMyBuyingProducts(itemsPerPage, lastProduct);
    setBuyingProducts(newProducts);
  };

  useEffect(() => {
    fetchMyBuyingProducts()
      .then((products) => {
        setBuyingProducts(products);
        setTotal(products.length);
      })
      .catch((err) => {
        console.error('Error fetching buying products:', err);
      });
  }, [currentUser]);

  const handleProductClick = (product) => {
    window.open(`/detail/${product.id}`, '_blank', 'noopener,noreferrer');
  };

  if (!currentUser) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      p={3}
      sx={{
        width: '100%',
        maxWidth: '100%',
        margin: 'auto',
      }}
    >
      <Box
        sx={{
          display: 'inline-block',
          border: '0px solid #d3d3d3',
          borderRadius: '16px',
          padding: '8px 16px',
          marginBottom: '16px',
          backgroundColor: 'transparent',
          transition: 'transform 0.2s',
          '&:hover': {
            transform: 'scale(1.02)',
          },
          my: '30px',
        }}
      >
        <Typography variant="h4" component="h3" gutterBottom>
          {t('myBuyingProducts')}
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {buyingProducts.length === 0 ? (
          <Grid item xs={12}>
            <Typography textAlign="center">{t('noBuyingProducts')}</Typography>
          </Grid>
        ) : (
          <>
            {isMobile ? (
              <Box
                id="parentScrollBox"
                sx={{
                  marginTop: '20px',
                  height: 'calc(80vh - 70px)',
                  overflow: 'auto',
                }}
              >
                <InfiniteScroll
                  dataLength={buyingProducts.length}
                  next={loadMoreProducts}
                  hasMore={hasMore}
                  loader={<Typography>{t('loading')}</Typography>}
                  endMessage={
                    <Typography variant="body2">
                      {t('noMoreProducts')}
                    </Typography>
                  }
                  scrollableTarget="parentScrollBox"
                >
                  <Grid container spacing={2}>
                    {buyingProducts.map((product) => (
                      <Grid item xs={12} key={product.id}>
                        <Product
                          product={product}
                          onClick={() => handleProductClick(product)}
                          horizontal={isMobile}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </InfiniteScroll>
              </Box>
            ) : (
              <>
                <Grid container spacing={2}>
                  {buyingProducts.map((product) => (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      xl={2}
                      key={product.id}
                    >
                      <Product
                        product={product}
                        onClick={() => handleProductClick(product)}
                      />
                    </Grid>
                  ))}
                </Grid>
                <TablePagination
                  component="div"
                  count={total}
                  page={page}
                  onPageChange={handlePageChange}
                  rowsPerPage={itemsPerPage}
                  rowsPerPageOptions={[itemsPerPage]}
                />
              </>
            )}
          </>
        )}
      </Grid>
    </Box>
  );
}
