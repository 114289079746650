import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  collection,
  db,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  orderBy,
  limit,
} from '../firebaseConfig';

function useRecommandStoreProducts(storeId, product, userId) {
  const currentUser = useSelector((state) => state.auth.currentUser);
  const [recommendedProducts, setRecommendedProducts] = useState([]);
  const [productsViewedByOthers, setProductsViewedByOthers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!product?.id || !currentUser?.uid) return;

    setLoading(true);
    (async () => {
      try {
        const recommended = await fetchRecommendedProducts(
          currentUser.uid,
          storeId
        );
        setRecommendedProducts(recommended);

        const othersViewed = await fetchProductsViewedByOthers(product.id);
        setProductsViewedByOthers(othersViewed);
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    })();
  }, [currentUser, product, storeId]);

  const fetchRecommendedProducts = async (userId, storeId) => {
    // 이용자의 과거 행동(구매, 검색, 클릭)을 기반으로 상품 추천
    const userHistory = await getUserHistory(userId, storeId); // 가상의 함수
    const recommendedProducts = await findSimilarStoreProducts(userHistory); // 가상의 함수
    return recommendedProducts;
  };

  const fetchProductsViewedByOthers = async (currentProductId) => {
    // 현재 상품을 본 다른 이용자들의 행동을 기반으로 상품 추천
    const usersWhoViewed = await getUsersWhoViewedProduct(currentProductId); // 가상의 함수
    const otherProducts = await findOtherProductsViewedByUsers(usersWhoViewed); // 가상의 함수
    return otherProducts;
  };
  // 사용자의 과거 행동 기반 상품 추천
  // async function getUserHistory(userId, storeId) {
  //   if (!userId || !storeId) {
  //     console.error('Missing userId or storeId');
  //     return [];
  //   }

  //   try {
  //     const userActivityRef = collection(
  //       db,
  //       'stores',
  //       storeId,
  //       'userActivities'
  //     );
  //     const q = query(
  //       userActivityRef,
  //       where('userId', '==', userId),
  //       orderBy('timestamp', 'desc'),
  //       limit(5)
  //     );
  //     const snapshot = await getDocs(q);

  //     if (!snapshot.empty) {
  //       return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  //     }
  //     return [];
  //   } catch (error) {
  //     console.error(
  //       'Error getting user history for userId:',
  //       userId,
  //       'Error:',
  //       error
  //     );
  //     return [];
  //   }
  // }

  async function getUserHistory(userId, storeId) {
    if (!userId || !storeId) {
      console.error('Missing userId or storeId');
      return [];
    }

    try {
      const userActivityRef = collection(
        db,
        'stores',
        storeId,
        'userActivities'
      );
      const q = query(
        userActivityRef,
        where('userId', '==', userId),
        orderBy('timestamp', 'desc'),
        limit(5)
      );
      const snapshot = await getDocs(q);

      if (!snapshot.empty) {
        return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      }
      return [];
    } catch (error) {
      if (
        error.code === 'failed-precondition' ||
        error.code === 'unavailable'
      ) {
        console.error('Firestore index error:', error.message);
        // 필요한 경우 사용자에게 알림을 표시하거나 추가 처리를 수행할 수 있습니다.
      } else {
        console.error(
          'Error getting user history for userId:',
          userId,
          'Error:',
          error
        );
      }
      return [];
    }
  }

  async function findSimilarStoreProducts(storeId, productIds) {
    if (!storeId) {
      console.error('Missing  storeId');
      return [];
    }
    if (!Array.isArray(productIds) || productIds.length === 0) {
      console.error('productIds should be a non-empty array');
      return [];
    }

    // try {
    //   const productsRef = collection(db, 'stores', storeId, 'products');
    //   const productSnapshots = await Promise.all(
    //     productIds.map((id) =>
    //       getDocs(query(productsRef, where('id', '==', id)))
    //     )
    //   );
    //   return productSnapshots
    //     .flat()
    //     .map((snapshot) => snapshot.docs[0]?.data())
    //     .filter(Boolean); // Filter out any undefined data
    // } catch (error) {
    //   console.error('Error finding similar products:', error);
    //   return [];
    // }

    try {
      const productsRef = collection(db, 'stores', storeId, 'products');
      const productSnapshots = await Promise.all(
        productIds.map(async (id) => {
          const productQuery = query(productsRef, where('id', '==', id));
          const snapshot = await getDocs(productQuery);
          return snapshot.docs.map((doc) => doc.data());
        })
      );
      return productSnapshots.flat().filter(Boolean); // Flatten and filter out any undefined data
    } catch (error) {
      console.error('Error finding similar products:', error);
      return [];
    }
  }

  async function getUsersWhoViewedProduct(storeId, productId) {
    if (!productId || !storeId) {
      console.error('Missing userId or storeId');
      return [];
    }
    try {
      const userActivityRef = collection(
        db,
        'stores',
        storeId,
        'userActivities'
      );
      const q = query(
        userActivityRef,
        where('viewedProducts', 'array-contains', productId)
      );
      const snapshot = await getDocs(q);

      if (!snapshot.empty) {
        return snapshot.docs.map((doc) => doc.data().userId);
      }
      return [];
    } catch (error) {
      console.error('Error getting users who viewed product:', error);
      return [];
    }
  }

  async function findOtherProductsViewedByUsers(storeId, userIds) {
    if (!storeId) {
      console.error('Missing userId or storeId');
      return [];
    }
    try {
      const userActivityRef = collection(
        db,
        'stores',
        storeId,
        'userActivities'
      );
      const userActivitySnapshots = await Promise.all(
        userIds.map((id) =>
          getDocs(query(userActivityRef, where('userId', '==', id)))
        )
      );
      const viewedProducts = userActivitySnapshots
        .flat()
        .map((snapshot) => snapshot.data().viewedProducts)
        .flat();
      const uniqueProductIds = [...new Set(viewedProducts)];
      const productsRef = collection(db, 'products');
      const productSnapshots = await Promise.all(
        uniqueProductIds.map((id) =>
          getDocs(query(productsRef, where('id', '==', id)))
        )
      );
      return productSnapshots
        .flat()
        .map((snapshot) => snapshot.docs[0]?.data())
        .filter(Boolean); // Filter out any undefined data
    } catch (error) {
      console.error('Error finding other products viewed by users:', error);
      return [];
    }
  }
  // 메모이제이션된 상품 목록
  const memoizedRecommendedProducts = useMemo(
    () => recommendedProducts,
    [recommendedProducts]
  );
  const memoizedProductsViewedByOthers = useMemo(
    () => productsViewedByOthers,
    [productsViewedByOthers]
  );

  return {
    recommendedProducts: memoizedRecommendedProducts,
    productsViewedByOthers: memoizedProductsViewedByOthers,
    loading,
    error,
  };
}

export default useRecommandStoreProducts;
