import axios from 'axios';
import i18n from 'i18next'; // i18n 설정 파일을 불러옵니다.

const googleTranslateApiKey = 'AIzaSyBYYgQuZbhr__lCJXG_S4r2zzoSS2vSRsw';

export const googleTranslate = async (name) => {
  const lang = i18n.language;

  let translatedName = i18n.t(`translated.${name}`, {
    defaultValue: '',
  });
  //요금때문에 번역을 막음.
  if (!translatedName) {
    translatedName = name;
  }
  if (!translatedName) {
    const url = `https://translation.googleapis.com/language/translate/v2?key=${googleTranslateApiKey}`;

    try {
      const response = await axios.post(url, {
        q: name,
        target: lang,
        format: 'text',
      });

      translatedName = response.data.data.translations[0].translatedText;

      i18n.addResource(
        lang,
        'translation',
        `translated.${name}`,
        translatedName
      );
    } catch (error) {
      console.error('Error translating text:', error);
      translatedName = name;
    }
  }

  return translatedName;
};
