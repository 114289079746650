// import React, { useState, useEffect } from 'react';
// import {
//   db,
//   collection,
//   getDocs,
//   getDoc,
//   doc,
//   updateDoc,
//   addDoc,
//   deleteDoc,
//   query,
//   where,
// } from '../../firebaseConfig';
// import {
//   Dialog,
//   DialogContent,
//   DialogTitle,
//   Button,
//   TextField,
// } from '@mui/material';

// import { useParams } from 'react-router-dom';
// import Modal from 'react-modal';
// import ModalStyles from '../../Common/ModalStyles';
// import '../css/SignUpFlow.css';

// // 대표적인 프로모션의 종류:

// // 1)할인 및 특별가격 제공: 일정 기간 동안 제품의 가격을 할인하는 방식.
// // 2)쿠폰 제공: 특정 금액이나 퍼센트만큼 할인받을 수 있는 쿠폰을 제공.
// // 3)사은품 제공: 제품을 구매하면 추가적인 상품이나 서비스를 무료로 제공.
// // 4)적립 프로그램: 구매 시 일정한 포인트를 적립하고, 이를 다른 상품 구매에 사용하도록 하는 프로그램.
// // 5)무료 샘플 제공: 소비자가 제품을 시험해 볼 수 있도록 무료 샘플을 제공.
// // 6)프리미엄 제공: 특정 금액 이상 구매 시 고가의 상품을 무료로 제공하는 전략.
// // 7)경품 추첨: 제품을 구매한 소비자 중에서 추첨을 통해 상품을 제공하는 방식.
// // 8)체험 마케팅: 제품이나 서비스를 직접 체험할 수 있도록 하는 마케팅 활동.
// // 9)바이 원, 겟 원 (BOGO): 하나를 사면 하나를 무료로 제공하는 전략.
// // 10)구매 증가 프로모션: 특정 수량 이상 구매 시 할인이나 사은품 제공.

// const PromotionManagement = ({ storeId }) => {
//   // const { id: storeId } = useParams();
//   const [promotions, setPromotions] = useState([]);
//   const [modalOpen, setModalOpen] = useState(false);
//   const [currentPromotion, setCurrentPromotion] = useState(null);
//   const [promotionName, setPromotionName] = useState('');
//   const [promotionContent, setPromotionContent] = useState('');
//   const [promotionStartDate, setPromotionStartDate] = useState('');
//   const [promotionEndDate, setPromotionEndDate] = useState('');

//   const fetchPromotions = async () => {
//     const promotionsQuery = query(
//       collection(db, 'stores', storeId, 'promotions')
//     );
//     const promotionsSnapshot = await getDocs(promotionsQuery);
//     setPromotions(
//       promotionsSnapshot.docs.map((doc) => ({
//         id: doc.id,
//         ...doc.data(),
//         startDate: doc.data().startDate.toDate(),
//         endDate: doc.data().endDate.toDate(),
//       }))
//     );
//   };

//   useEffect(() => {
//     fetchPromotions();
//   }, [storeId]);

//   // const addPromotion = async () => {
//   //     try {
//   //         const newPromotion = {
//   //             name: promotionName,
//   //             content: promotionContent,
//   //             startDate: new Date(promotionStartDate),
//   //             endDate: new Date(promotionEndDate),
//   //         };
//   //         const promotionsCollectionRef = collection(doc(db, 'stores', storeId), 'promotions');
//   //         await addDoc(promotionsCollectionRef, newPromotion);

//   //         // Clear input fields after adding
//   //         setPromotionName('');
//   //         setPromotionContent('');
//   //         setPromotionStartDate('');
//   //         setPromotionEndDate('');

//   //         // Refresh the list of promotions
//   //         fetchPromotions();
//   //     } catch (error) {
//   //         console.error("Error adding new promotion:", error);
//   //     }
//   // };

//   // const editPromotion = (promotion) => {
//   //     setCurrentPromotion(promotion);
//   //     setPromotionName(promotion.name);
//   //     setPromotionContent(promotion.content);
//   //     setPromotionStartDate(promotion.startDate.toISOString().split('T')[0]);  // assuming it's a Date object
//   //     setPromotionEndDate(promotion.endDate.toISOString().split('T')[0]);    // assuming it's a Date object

//   // };
//   // const handleSubmit = async (e) => {
//   //     e.preventDefault();
//   //     if (currentPromotion) {
//   //         // Update the promotion if currentPromotion is set
//   //         const docRef = doc(db, 'stores', storeId, 'promotions', currentPromotion.id);
//   //         await updateDoc(docRef, {
//   //             name: promotionName,
//   //             content: promotionContent,
//   //             startDate: promotionStartDate,
//   //             endDate: promotionEndDate,
//   //         });
//   //     } else {
//   //         // Otherwise, add a new promotion
//   //         const promotionsCollectionRef = collection(doc(db, 'stores', storeId), 'promotions');
//   //         await addDoc(promotionsCollectionRef, {
//   //             name: promotionName,
//   //             content: promotionContent,
//   //             startDate: promotionStartDate,
//   //             endDate: promotionEndDate,
//   //         });
//   //     }
//   //     fetchPromotions(); // Refresh the list after adding/updating
//   // };

//   const handleOpenModal = (promotion) => {
//     if (promotion) {
//       setCurrentPromotion(promotion);
//       setPromotionName(promotion.name);
//       setPromotionContent(promotion.content);
//       setPromotionStartDate(promotion.startDate);
//       setPromotionEndDate(promotion.endDate);
//     }
//     setModalOpen(true);
//   };

//   const handleCloseModal = () => {
//     setModalOpen(false);
//     setCurrentPromotion(null);
//     setPromotionName('');
//     setPromotionContent('');
//     setPromotionStartDate('');
//     setPromotionEndDate('');
//   };

//   const handleFormSubmit = (e) => {
//     e.preventDefault(); // 폼 제출을 방지합니다.

//     // 현재 프로모션이 있으면 업데이트, 없으면 추가
//     if (currentPromotion) {
//       handleUpdate();
//     } else {
//       handleAdd();
//     }
//   };

//   const handleAdd = () => {
//     // Add new promotion logic

//     try {
//       const startDate = new Date(promotionStartDate);
//       const endDate = new Date(promotionEndDate);

//       const promotionsCollectionRef = collection(
//         db,
//         'stores',
//         storeId,
//         'promotions'
//       );
//       const promotionData = {
//         name: promotionName,
//         content: promotionContent,
//         startDate: startDate,
//         endDate: endDate,
//       };
//       console.log('Data:', {
//         promotionName,
//         promotionContent,
//         startDate,
//         endDate,
//         storeId,
//       });
//       addDoc(promotionsCollectionRef, promotionData)
//         .then(() => {
//           console.log('프로모션이 등록되었습니다.');
//           handleCloseModal();
//           fetchPromotions();
//         })
//         .catch((error) => {
//           console.error('Error adding document: ', error);
//         });
//     } catch (error) {
//       console.error('Error adding document: ', error);
//     }
//   };

//   const handleUpdate = () => {
//     if (!currentPromotion) return; // Safety check to ensure a currentPromotion is selected

//     const startDate = new Date(promotionStartDate);
//     const endDate = new Date(promotionEndDate);

//     // Update existing promotion
//     const promotionsCollectionRef = doc(
//       db,
//       'stores',
//       storeId,
//       'promotions',
//       currentPromotion.id
//     );
//     const promotionData = {
//       name: promotionName,
//       content: promotionContent,
//       startDate: startDate,
//       endDate: endDate,
//     };
//     updateDoc(promotionsCollectionRef, promotionData)
//       .then(() => {
//         console.log('프로모션이 수정되었습니다.');
//         handleCloseModal();
//         fetchPromotions();
//       })
//       .catch((error) => {
//         console.error('Error adding document: ', error);
//       });
//   };

//   const deletePromotion = async (id) => {
//     try {
//       const docRef = doc(db, 'stores', storeId, 'promotions', id);
//       await deleteDoc(docRef);

//       // Refresh the list of promotions after deleting one
//       fetchPromotions();
//     } catch (error) {
//       console.error('Error deleting promotion:', error);
//     }
//   };

//   return (
//     <div>
//       <h2>프로모션 관리 시스템</h2>
//       <div style={{ position: 'relative' }}>
//         <ul>
//           {promotions.map((promotion) => (
//             <li key={promotion.id}>
//               <strong>Name:</strong> {promotion.name}
//               <br />
//               <strong>Content:</strong> {promotion.content}
//               <br />
//               <strong>Start Date:</strong>{' '}
//               {promotion.startDate?.toLocaleString()}
//               <br />
//               <strong>End Date:</strong> {promotion.endDate?.toLocaleString()}
//               <br />
//               <button
//                 className="button"
//                 onClick={() => handleOpenModal(promotion)}
//               >
//                 Edit
//               </button>
//               <button
//                 className="button"
//                 onClick={() => deletePromotion(promotion.id)}
//               >
//                 Delete
//               </button>
//             </li>
//           ))}
//           {promotions.length === 0 && (
//             <div className="coupon-item">
//               <p>진행중인 프로모션이 없습니다.</p>
//             </div>
//           )}
//         </ul>
//         <div style={{ position: 'absolute', right: '1px', bottom: '1px' }}>
//           <button className="button" onClick={() => handleOpenModal(null)}>
//             프로모션 추가
//           </button>
//         </div>
//       </div>

//       <Modal
//         isOpen={modalOpen}
//         onRequestClose={handleCloseModal}
//         style={ModalStyles}
//       >
//         <form onSubmit={handleFormSubmit}>
//           <h2>프로모션 생성</h2>

//           <div className="form-section">
//             <label className="title-label" htmlFor="title">
//               Promotion Name:
//             </label>
//             <input
//               id="title"
//               type="text"
//               value={promotionName}
//               onChange={(e) => setPromotionName(e.target.value)}
//               placeholder="Promotion Name"
//             />
//           </div>

//           <div className="form-section">
//             <label className="title-label" htmlFor="content">
//               Promotion Content:
//             </label>
//             <input
//               id="content"
//               type="text"
//               value={promotionContent}
//               onChange={(e) => setPromotionContent(e.target.value)}
//               placeholder="Promotion Content"
//             />
//           </div>

//           <div className="form-section">
//             <label className="title-label" htmlFor="startDate">
//               Start Date:
//             </label>
//             <input
//               id="startDate"
//               type="datetime-local"
//               value={promotionStartDate}
//               onChange={(e) => setPromotionStartDate(e.target.value)}
//             />
//           </div>

//           <div className="form-section">
//             <label className="title-label" htmlFor="endDate">
//               End Date:
//             </label>
//             <input
//               id="endDate"
//               type="datetime-local"
//               value={promotionEndDate}
//               onChange={(e) => setPromotionEndDate(e.target.value)}
//             />
//           </div>

//           <div style={{ position: 'absolute', right: '20px', bottom: '20px' }}>
//             <button className="button" type="submit">
//               Save
//             </button>
//             <button className="button" type="button" onClick={handleCloseModal}>
//               Cancel
//             </button>
//           </div>
//         </form>
//       </Modal>
//     </div>
//   );
// };
// export default PromotionManagement;

import React, { useState, useEffect } from 'react';
import {
  db,
  collection,
  getDocs,
  doc,
  updateDoc,
  addDoc,
  deleteDoc,
  query,
} from '../../firebaseConfig';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Grid,
  Box,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import '../../css/SignUpFlow.css';

const PromotionManagement = ({ storeId }) => {
  const { t, i18n } = useTranslation();
  const [promotions, setPromotions] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentPromotion, setCurrentPromotion] = useState(null);
  const [promotionName, setPromotionName] = useState('');
  const [promotionContent, setPromotionContent] = useState('');
  const [promotionStartDate, setPromotionStartDate] = useState('');
  const [promotionEndDate, setPromotionEndDate] = useState('');

  useEffect(() => {
    const fetchPromotions = async () => {
      const promotionsQuery = query(
        collection(db, 'stores', storeId, 'promotions')
      );
      const promotionsSnapshot = await getDocs(promotionsQuery);
      setPromotions(
        promotionsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          startDate: doc.data().startDate.toDate(),
          endDate: doc.data().endDate.toDate(),
        }))
      );
    };
    fetchPromotions();
  }, [storeId]);

  const handleOpenModal = (promotion) => {
    setCurrentPromotion(promotion || {});
    setModalOpen(true);
    setPromotionName(promotion?.name || '');
    setPromotionContent(promotion?.content || '');
    setPromotionStartDate(promotion?.startDate || '');
    setPromotionEndDate(promotion?.endDate || '');
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setCurrentPromotion(null);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const promotionData = {
      name: promotionName,
      content: promotionContent,
      startDate: new Date(promotionStartDate),
      endDate: new Date(promotionEndDate),
    };
    if (currentPromotion?.id) {
      await updateDoc(
        doc(db, 'stores', storeId, 'promotions', currentPromotion.id),
        promotionData
      );
    } else {
      await addDoc(
        collection(db, 'stores', storeId, 'promotions'),
        promotionData
      );
    }
    handleCloseModal();
  };

  return (
    <div>
      <Typography variant="h4">{t('promotionManagementSystem')}</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box mt={2} display="flex" justifyContent="flex-end" pr={2}>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => handleOpenModal(null)}
            >
              {t('addPromotion')}
            </Button>
          </Box>
        </Grid>
        {promotions.map((promotion) => (
          <Grid item xs={12} sm={6} md={4} key={promotion.id}>
            <div>
              <div>
                <strong>{t('name')}:</strong> {promotion.name}
              </div>
              <div>
                <strong>{t('content')}:</strong> {promotion.content}
              </div>
              <div>
                <strong>{t('startDate')}:</strong>{' '}
                {promotion.startDate.toLocaleString()}
              </div>
              <div>
                <strong>{t('endDate')}:</strong>{' '}
                {promotion.endDate.toLocaleString()}
              </div>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => handleOpenModal(promotion)}
              >
                {t('edit')}
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() =>
                  deleteDoc(
                    doc(db, 'stores', storeId, 'promotions', promotion.id)
                  )
                }
              >
                {t('delete')}
              </Button>
            </div>
          </Grid>
        ))}
      </Grid>
      <Dialog open={modalOpen} onClose={handleCloseModal}>
        <DialogTitle>
          {currentPromotion?.id ? t('editPromotion') : t('addPromotion')}
        </DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label={t('promotionName')}
            type="text"
            fullWidth
            value={promotionName || ''}
            onChange={(e) => setPromotionName(e.target.value)}
          />
          <TextField
            margin="dense"
            label={t('promotionContent')}
            type="text"
            fullWidth
            value={promotionContent || ''}
            onChange={(e) => setPromotionContent(e.target.value)}
          />
          <TextField
            margin="dense"
            label={t('startDate')}
            type="datetime-local"
            fullWidth
            value={promotionStartDate || ''}
            onChange={(e) => setPromotionStartDate(e.target.value)}
          />
          <TextField
            margin="dense"
            label={t('endDate')}
            type="datetime-local"
            fullWidth
            value={promotionEndDate || ''}
            onChange={(e) => setPromotionEndDate(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>{t('cancel')}</Button>
          <Button onClick={handleFormSubmit} color="primary">
            {t('save')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PromotionManagement;
