import React from 'react';
import { db, collection, doc, setDoc, serverTimestamp } from './firebaseConfig';

function useUserActivity(userId) {
  // 로그인하지 않은 사용자에게 임시 식별자 부여
  const anonymousId =
    sessionStorage.getItem('anonymousId') || generateAnonymousId();

  function generateAnonymousId() {
    const newId = `anon-${Math.random().toString(36).substring(2, 15)}`;
    sessionStorage.setItem('anonymousId', newId);
    return newId;
  }

  // 유저 ID 또는 익명 ID를 사용하여 활동 로그 기능 설정
  const effectiveUserId = userId || anonymousId;

  async function logActivity(activityType, data) {
    if (!effectiveUserId) {
      console.error('Invalid user identifier');
      return;
    }

    const userActivitiesRef = doc(
      collection(db, 'userActivities'),
      effectiveUserId
    );
    try {
      await setDoc(
        userActivitiesRef,
        {
          userId: effectiveUserId,
          type: activityType,
          data: data,
          timestamp: serverTimestamp(),
        },
        { merge: true }
      );
    } catch (error) {
      console.error('Error logging activity: ', error);
    }
  }

  async function logViewedProduct(productId) {
    await logActivity('viewed', productId);
  }

  async function logPurchasedProduct(productId) {
    await logActivity('purchased', productId);
  }

  async function logSearchedKeyword(keyword) {
    await logActivity('searchedKeyword', keyword);
  }

  async function logSearchedCategory(categoryId) {
    await logActivity('searchedCategory', categoryId);
  }

  async function logSearchedBrand(brandId) {
    await logActivity('searchedBrand', brandId);
  }

  return {
    logViewedProduct,
    logPurchasedProduct,
    logSearchedKeyword,
    logSearchedCategory,
    logSearchedBrand,
  };
}

export default useUserActivity;
