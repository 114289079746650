import React, { useEffect, useState } from 'react';
import {
  db,
  collection,
  doc,
  getDoc,
  updateDoc,
  addDoc,
  deleteDoc,
  where,
  getDocs,
  query,
  orderBy,
  limit,
} from '../../firebaseConfig';
import {
  Container,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  Box,
} from '@mui/material';
import useMessageBox from '../../Common/useMessageBox';
import { useTranslation } from 'react-i18next';
import '../../css/detail.css';

function RegisterPrivacy() {
  const { t } = useTranslation();
  const [privacyContents, setPrivacyContents] = useState([]);
  const [versions, setVersions] = useState([]);
  const [nextVersion, setNextVersion] = useState(0);
  const [MessageBoxInform, MessageBoxConfirm, MessageBoxRender] =
    useMessageBox();

  useEffect(() => {
    fetchVersionsAndGenerateNext();
  }, []);

  const fetchVersionsAndGenerateNext = async () => {
    try {
      const termsCollection = collection(db, 'privacy');
      const termsSnapshot = await getDocs(termsCollection);
      const allVersions = termsSnapshot.docs.map((doc) => doc.data().version);
      const uniqueVersions = [...new Set(allVersions)]; // Remove duplicates

      if (uniqueVersions.length === 0) {
        setVersions([]);
        setNextVersion(1);
        setPrivacyContents([
          {
            version: 1,
            items: [
              {
                title: '',
                content: '',
                type: 'optional',
              },
            ],
          },
        ]);
      } else {
        setVersions(uniqueVersions);
        const maxVersion = Math.max(...uniqueVersions);
        const nextVersionNumber = maxVersion + 1;
        setNextVersion(nextVersionNumber);
        setPrivacyContents([
          {
            version: nextVersionNumber,
            items: [
              {
                title: '',
                content: '',
                type: 'optional',
              },
            ],
          },
        ]);
      }
    } catch (error) {
      console.error('Error fetching versions: ', error);
      setVersions([]);
      setNextVersion(1); // Set default next version to 1 in case of error
      setPrivacyContents([
        {
          version: 1,
          items: [
            {
              title: '',
              content: '',
              type: 'optional',
            },
          ],
        },
      ]);
    }
  };

  const fetchLatestVersion = async () => {
    try {
      const privacyQuery = query(
        collection(db, 'privacy'),
        orderBy('version', 'desc'),
        limit(1)
      );
      const latestPrivacySnapshot = await getDocs(privacyQuery);
      if (latestPrivacySnapshot.empty) {
        return 1;
      }
      const latestPrivacy = latestPrivacySnapshot.docs[0].data();
      return latestPrivacy.version;
    } catch (error) {
      if (
        error.code === 'failed-precondition' ||
        error.code === 'unavailable'
      ) {
        console.error('Firestore index error:', error.message);
        // 필요한 경우 사용자에게 알림을 표시하거나 추가 처리를 수행할 수 있습니다.
      } else {
        console.error('Error fetching latest privacy version:', error);
      }
      return 1; // 오류 발생 시 기본 버전 1 반환
    }
  };

  async function registerNewPrivacy(privacy) {
    if (
      privacy.version === undefined ||
      !Array.isArray(privacy.items) ||
      privacy.items.length === 0
    ) {
      throw new Error('Privacy object has missing or undefined fields');
    }

    for (let item of privacy.items) {
      if (!item.title || !item.content || !item.type) {
        throw new Error('Privacy item has missing or undefined fields');
      }
    }

    const newPrivacyRef = await addDoc(collection(db, 'privacy'), {
      version: privacy.version,
      items: privacy.items.map((item) => ({
        title: item.title,
        content: item.content,
        type: item.type,
      })),
      created_at: new Date(),
      updated_at: new Date(),
    });

    return newPrivacyRef.id;
  }

  const handleNewItem = () => {
    const newVersion = {
      version: nextVersion,
      items: [
        {
          title: '',
          content: '',
          type: 'optional',
        },
      ],
    };
    setPrivacyContents([...privacyContents, newVersion]);
  };

  const handleAddItem = (versionIndex) => {
    const newPrivacyContents = [...privacyContents];
    newPrivacyContents[versionIndex].items.push({
      title: '',
      content: '',
      type: 'optional',
    });
    setPrivacyContents(newPrivacyContents);
  };

  const handleRemoveItem = (versionIndex, itemIndex) => {
    const newPrivacyContents = [...privacyContents];
    newPrivacyContents[versionIndex].items.splice(itemIndex, 1);
    setPrivacyContents(newPrivacyContents);
  };

  const handlePrivacyChange = (versionIndex, itemIndex, key, value) => {
    const newPrivacyContents = [...privacyContents];
    if (
      newPrivacyContents[versionIndex] &&
      newPrivacyContents[versionIndex].items[itemIndex]
    ) {
      newPrivacyContents[versionIndex].items[itemIndex][key] = value;
    }
    setPrivacyContents(newPrivacyContents);
  };

  const handleRegisterClick = async () => {
    try {
      const privacyIds = [];
      for (let privacy of privacyContents) {
        const privacyId = await registerNewPrivacy(privacy);
        privacyIds.push(privacyId);
      }
      console.log(
        '새로운 개인정보처리지침들이 등록되었습니다. 개인정보처리지침 ID들:',
        privacyIds
      );
      await MessageBoxInform('새로운 개인정보처리지침이 배포되었습니다.');
      await fetchVersionsAndGenerateNext();
    } catch (error) {
      console.error('개인정보처리지침 등록 중 오류 발생:', error);
    }
  };

  return (
    <Container>
      {MessageBoxRender()}
      <Typography variant="h4" gutterBottom>
        {t('registerNewPrivacy')}
      </Typography>
      <Typography variant="subtitle1">
        {t('newVersion')}: {nextVersion}
      </Typography>
      <Box sx={{ mb: 4 }}>
        {privacyContents.map((privacyContent, versionIndex) => (
          <Box key={versionIndex} sx={{ mb: 2 }}>
            {privacyContent.items.map((item, itemIndex) => (
              <Box
                key={itemIndex}
                sx={{ display: 'flex', flexDirection: 'column', mb: 1 }}
              >
                <TextField
                  label={t('privacyTitle')}
                  value={item.title || ''}
                  onChange={(e) =>
                    handlePrivacyChange(
                      versionIndex,
                      itemIndex,
                      'title',
                      e.target.value
                    )
                  }
                  fullWidth
                  sx={{ mb: 1 }}
                />
                <TextField
                  label={t('privacyContent')}
                  value={item.content || ''}
                  onChange={(e) =>
                    handlePrivacyChange(
                      versionIndex,
                      itemIndex,
                      'content',
                      e.target.value
                    )
                  }
                  multiline
                  rows={4}
                  fullWidth
                  sx={{ mb: 1 }}
                />
                <Select
                  label={t('privacyType')}
                  value={item.type || ''}
                  onChange={(e) =>
                    handlePrivacyChange(
                      versionIndex,
                      itemIndex,
                      'type',
                      e.target.value
                    )
                  }
                  fullWidth
                >
                  <MenuItem value="optional">{t('optionalConsent')}</MenuItem>
                  <MenuItem value="required">{t('requiredConsent')}</MenuItem>
                </Select>
                <Button
                  onClick={() => handleRemoveItem(versionIndex, itemIndex)}
                  sx={{ mt: 1 }}
                >
                  {t('deleteItem')}
                </Button>
              </Box>
            ))}
            <Button
              variant="contained"
              onClick={() => handleAddItem(versionIndex)}
              sx={{ mt: 1, mr: 2 }}
            >
              {t('addNewItem')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleRegisterClick}
              sx={{ mt: 1 }}
            >
              {t('deploy')}
            </Button>
          </Box>
        ))}
      </Box>
    </Container>
  );
}

export default RegisterPrivacy;
