// import React, { useState, useEffect } from 'react';
// import { db, collection, query, where, getDocs } from '../firebaseConfig';
// import { Box, Button, Breadcrumbs, Link, Typography } from '@mui/material';
// import NavigateNextIcon from '@mui/icons-material/NavigateNext';

// const ProductBrandSelector = ({ brandId, onSelectBrand, selectedCategory }) => {
//   const [subBrands, setSubBrands] = useState([]);
//   const [searchBrandPath, setSearchBrandPath] = useState([]);
//   const [siblingBrands, setSiblingBrands] = useState([]);

//   useEffect(() => {
//     const loadInitialBrands = async () => {
//       const rootBrands = await fetchBrands('');
//       setSubBrands(rootBrands);
//       setSearchBrandPath([
//         { id: '', name: '브랜드별', level: 0, brands: rootBrands },
//       ]);
//     };
//     loadInitialBrands();
//   }, []);

//   useEffect(() => {
//     if (selectedCategory) {
//       fetchBrandsByCategory('', selectedCategory.id);
//     } else {
//       fetchRootBrands();
//     }
//   }, [selectedCategory]);

//   const fetchRootBrands = async () => {
//     const rootBrands = await fetchBrands('');
//     setSubBrands(rootBrands);
//     setSearchBrandPath([
//       { id: '', name: '브랜드별', level: 0, brands: rootBrands },
//     ]);
//   };

//   const fetchBrandsByCategory = async (brandId = '', categoryId = '') => {
//     try {
//       const q = query(
//         collection(db, 'brands'),
//         where('parentBrandId', '==', brandId),
//         where('categoryIds', 'array-contains', categoryId)
//       );
//       const querySnapshot = await getDocs(q);
//       const brands = querySnapshot.docs.map((doc) => ({
//         id: doc.id,
//         ...doc.data(),
//       }));
//       setSubBrands(brands);
//       setSearchBrandPath([{ id: '', name: '브랜드별', level: 0, brands }]);
//     } catch (error) {
//       console.error('Error fetching brands by category: ', error);
//     }
//   };

//   const handleChangeBrand = async (selectedBrand) => {
//     const subBrands = await fetchBrands(selectedBrand.id);
//     setSubBrands(subBrands);

//     const newPath = [...searchBrandPath];
//     const existingIndex = newPath.findIndex(
//       (brand) => brand.id === selectedBrand.id
//     );
//     if (existingIndex > -1) {
//       newPath.splice(
//         existingIndex,
//         newPath.length - existingIndex,
//         selectedBrand
//       );
//     } else {
//       newPath.push({
//         id: selectedBrand.id,
//         name: selectedBrand.name,
//         level: newPath.length,
//         brands: subBrands,
//       });
//     }

//     setSearchBrandPath(newPath);
//     onSelectBrand(selectedBrand);
//   };

//   const fetchBrands = async (parentId = '') => {
//     const q = query(
//       collection(db, 'brands'),
//       where('parentBrandId', '==', parentId)
//     );
//     const querySnapshot = await getDocs(q);
//     return querySnapshot.docs.map((doc) => ({
//       id: doc.id,
//       name: doc.data().name,
//       level: doc.data().level,
//       parentBrandId: doc.data().parentBrandId,
//     }));
//   };

//   return (
//     <Box sx={{ width: '100%', p: 2 }}>
//       <Breadcrumbs
//         separator={<NavigateNextIcon fontSize="small" />}
//         aria-label="breadcrumb"
//       >
//         {searchBrandPath.map((brand, index) => (
//           <Link
//             key={brand.id || index}
//             color="inherit"
//             href="#"
//             onClick={(e) => {
//               e.preventDefault();
//               if (index === 0) {
//                 fetchRootBrands();
//               } else {
//                 handleChangeBrand(brand);
//               }
//             }}
//             sx={{ cursor: 'pointer' }}
//           >
//             {brand.name}
//           </Link>
//         ))}
//       </Breadcrumbs>
//       <Box sx={{ mt: 2, display: 'flex', flexDirection: 'row', gap: 1 }}>
//         {subBrands.map((subBrand) => (
//           <Button
//             key={subBrand.id}
//             variant="outlined"
//             color="primary"
//             onClick={() =>
//               handleChangeBrand({
//                 id: subBrand.id,
//                 name: subBrand.name,
//                 level: searchBrandPath.length,
//                 parentBrandId: subBrand.parentBrandId,
//               })
//             }
//             sx={{
//               borderRadius: 16,
//               border: '1px solid',
//               borderColor: 'primary.main',
//               textTransform: 'none',
//               padding: '5px 15px',
//               '&:hover': {
//                 backgroundColor: 'primary.light',
//                 borderColor: 'primary.dark',
//               },
//             }}
//           >
//             {subBrand.name}
//           </Button>
//         ))}
//       </Box>
//     </Box>
//   );
// };

// export default ProductBrandSelector;

// import React, { useState, useEffect } from 'react';
// import {
//   db,
//   collection,
//   query,
//   where,
//   getDocs,
//   doc,
//   getDoc,
// } from '../firebaseConfig';
// import { Box, Button, Breadcrumbs, Link, Typography } from '@mui/material';
// import NavigateNextIcon from '@mui/icons-material/NavigateNext';

// const ProductBrandSelector = ({ brandId, onSelectBrand, selectedCategory }) => {
//   const [subBrands, setSubBrands] = useState([]);
//   const [searchBrandPath, setSearchBrandPath] = useState([]);
//   const [siblingBrands, setSiblingBrands] = useState([]);

//   useEffect(() => {
//     if (!brandId) {
//       fetchRootBrands();
//     }
//   }, []);

//   useEffect(() => {
//     if (brandId) {
//       fetchBrandPath(brandId);
//       setSubBrands([]);
//     }
//   }, [brandId]);

//   useEffect(() => {
//     if (selectedCategory) {
//       fetchBrandsByCategory('', selectedCategory.id);
//     } else {
//       fetchRootBrands();
//     }
//   }, [selectedCategory]);

//   const fetchRootBrands = async () => {
//     const rootBrands = await fetchBrands('');
//     setSubBrands(rootBrands);
//     setSearchBrandPath([
//       { id: '', name: '브랜드별', level: 0, brands: rootBrands },
//     ]);
//   };

//   const fetchBrandPath = async (brandId) => {
//     let path = [];
//     let currentId = brandId;
//     while (currentId) {
//       const brandDoc = await getDoc(doc(db, 'brands', currentId));
//       if (brandDoc.exists()) {
//         const brandData = brandDoc.data();
//         path.unshift({
//           id: brandDoc.id,
//           name: brandData.name,
//           level: brandData.level,
//           parentBrandId: brandData.parentBrandId,
//         });
//         currentId = brandData.parentBrandId;
//       } else {
//         break;
//       }
//     }
//     setSearchBrandPath(path);
//     onSelectBrand(path[path.length - 1]);
//     fetchSiblingsForPath(path);
//   };

//   const fetchBrandsByCategory = async (brandId = '', categoryId = '') => {
//     try {
//       const q = query(
//         collection(db, 'brands'),
//         where('parentBrandId', '==', brandId),
//         where('categoryIds', 'array-contains', categoryId)
//       );
//       const querySnapshot = await getDocs(q);
//       const brands = querySnapshot.docs.map((doc) => ({
//         id: doc.id,
//         ...doc.data(),
//       }));
//       setSubBrands(brands);
//       setSearchBrandPath([{ id: '', name: '브랜드별', level: 0, brands }]);
//     } catch (error) {
//       console.error('Error fetching brands by category: ', error);
//     }
//   };

//   const handleChangeBrand = async (selectedBrand) => {
//     const subBrands = await fetchBrands(selectedBrand.id);
//     setSubBrands(subBrands);

//     const newPath = [...searchBrandPath];
//     const existingIndex = newPath.findIndex(
//       (brand) => brand.id === selectedBrand.id
//     );
//     if (existingIndex > -1) {
//       newPath.splice(
//         existingIndex,
//         newPath.length - existingIndex,
//         selectedBrand
//       );
//     } else {
//       newPath.push({
//         id: selectedBrand.id,
//         name: selectedBrand.name,
//         level: newPath.length,
//         brands: subBrands,
//       });
//     }

//     setSearchBrandPath(newPath);
//     onSelectBrand(selectedBrand);
//   };

//   const fetchBrands = async (parentId = '') => {
//     const q = query(
//       collection(db, 'brands'),
//       where('parentBrandId', '==', parentId)
//     );
//     const querySnapshot = await getDocs(q);
//     return querySnapshot.docs.map((doc) => ({
//       id: doc.id,
//       name: doc.data().name,
//       level: doc.data().level,
//       parentBrandId: doc.data().parentBrandId,
//     }));
//   };

//   const fetchSiblingsForPath = async (path) => {
//     const siblingPromises = path.map(async (brand) => ({
//       level: brand.level,
//       siblings: await fetchBrands(brand.parentBrandId || ''),
//     }));
//     const siblingResults = await Promise.all(siblingPromises);
//     const newSiblings = siblingResults.reduce(
//       (acc, result) => ({
//         ...acc,
//         [result.level]: result.siblings,
//       }),
//       {}
//     );
//     setSiblingBrands(newSiblings);
//   };

//   return (
//     <Box sx={{ width: '100%', p: 2 }}>
//       <Breadcrumbs
//         separator={<NavigateNextIcon fontSize="small" />}
//         aria-label="breadcrumb"
//       >
//         {searchBrandPath.map((brand, index) => (
//           <Link
//             key={brand.id || index}
//             color="inherit"
//             href="#"
//             onClick={(e) => {
//               e.preventDefault();
//               if (index === 0) {
//                 fetchRootBrands();
//               } else {
//                 handleChangeBrand(brand);
//               }
//             }}
//             sx={{ cursor: 'pointer' }}
//           >
//             {brand.name}
//           </Link>
//         ))}
//       </Breadcrumbs>
//       <Box sx={{ mt: 2, display: 'flex', flexDirection: 'row', gap: 1 }}>
//         {subBrands.map((subBrand) => (
//           <Button
//             key={subBrand.id}
//             variant="outlined"
//             color="primary"
//             onClick={() =>
//               handleChangeBrand({
//                 id: subBrand.id,
//                 name: subBrand.name,
//                 level: searchBrandPath.length,
//                 parentBrandId: subBrand.parentBrandId,
//               })
//             }
//             sx={{
//               borderRadius: 16,
//               border: '1px solid',
//               borderColor: 'primary.main',
//               textTransform: 'none',
//               padding: '5px 15px',
//               '&:hover': {
//                 backgroundColor: 'primary.light',
//                 borderColor: 'primary.dark',
//               },
//             }}
//           >
//             {subBrand.name}
//           </Button>
//         ))}
//       </Box>
//     </Box>
//   );
// };

// export default ProductBrandSelector;

import React, { useState, useEffect } from 'react';
import {
  db,
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
} from '../firebaseConfig';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { googleTranslate } from '../Utils/GoogleTranslation';
import { Box, Button, Breadcrumbs, Link, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const ProductBrandSelector = ({ brandId, onSelectBrand, selectedCategory }) => {
  const { t, i18n } = useTranslation();
  const [subBrands, setSubBrands] = useState([]);
  const [searchBrandPath, setSearchBrandPath] = useState([]);
  const [siblingBrands, setSiblingBrands] = useState([]);

  useEffect(() => {
    if (!brandId) {
      fetchRootBrands();
    }
  }, []);

  useEffect(() => {
    if (brandId) {
      fetchBrandPath(brandId);
      setSubBrands([]);
    } else {
      fetchRootBrands();
    }
  }, [brandId]);

  useEffect(() => {
    if (selectedCategory) {
      fetchBrandsByCategory('', selectedCategory.id);
    } else {
      fetchRootBrands();
    }
  }, [selectedCategory]);

  const fetchRootBrands = async () => {
    const rootBrands = await fetchBrands('', brandId);
    setSubBrands(rootBrands);
    setSearchBrandPath([]); // Clear any existing path since we're resetting to root
    setSiblingBrands({ 0: rootBrands }); // Initialize siblings for root
  };

  const fetchBrandPath = async (brandId) => {
    let path = [];
    let currentId = brandId;
    while (currentId) {
      const brandDoc = await getDoc(doc(db, 'brands', currentId));
      if (brandDoc.exists()) {
        const brandData = brandDoc.data();
        const translatedName = await googleTranslate(brandData.name);
        path.unshift({
          id: brandDoc.id,
          name: translatedName,
          level: brandData.level,
          parentBrandId: brandData.parentBrandId,
        });
        currentId = brandData.parentBrandId;
      } else {
        break;
      }
    }
    setSearchBrandPath(path);
    onSelectBrand(path[path.length - 1]);
    fetchSiblingsForPath(path);
  };

  const fetchBrandsByCategory = async (brandId = '', categoryId = '') => {
    try {
      const q = query(
        collection(db, 'brands'),
        where('parentBrandId', '==', brandId)
        //where('categoryIds', 'array-contains', categoryId)
      );
      const querySnapshot = await getDocs(q);
      const brands = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      //setSubBrands(brands);
      //setSearchBrandPath([{ id: '', name: '브랜드별', level: 0, brands }]);
      return brands;
    } catch (error) {
      console.error('Error fetching brands by category: ', error);
    }
  };

  // const findAllSubBrandIds = async (brandId, accum = []) => {
  //   const subBrands = await fetchBrands(brandId);
  //   for (const subBrand of subBrands) {
  //     accum.push(subBrand.id);
  //     await findAllSubBrandIds(subBrand.id, accum);
  //   }
  //   return accum;
  // };
  const findAllSubBrandIds = async (parentId, selectedCategoryId) => {
    try {
      let allIds = [];
      const subBrands = await fetchBrandsByCategory(
        parentId,
        selectedCategoryId
      );
      for (const brand of subBrands) {
        allIds.push(brand.id);
        const subBrandIds = await findAllSubBrandIds(
          brand.id,
          selectedCategoryId
        );
        allIds = allIds.concat(subBrandIds);
      }
      return allIds;
    } catch (error) {
      console.error('Error finding all sub-brand IDs:', error);
      return []; // 오류 발생 시 빈 배열 반환
    }
  };

  const handleChangeBrand = async (selectedBrand) => {
    const subBrands = await fetchBrands(selectedBrand.id);
    setSubBrands(subBrands);

    let updatedPath = searchBrandPath.slice(0, selectedBrand.level || 0);
    updatedPath.push(selectedBrand);
    setSearchBrandPath(updatedPath);

    if (selectedBrand.parentBrandId) {
      const siblings = await fetchBrands(
        selectedCategory.parentCategoryId,
        brandId
      );
      setSiblingBrands((prev) => ({
        ...prev,
        [selectedBrand.level]: siblings,
      }));
    }

    onSelectBrand(selectedBrand);
  };

  // const handleChangeBrand = async (selectedBrand) => {
  //   const subBrands = await fetchBrands(selectedBrand.id);
  //   setSubBrands(subBrands);

  //   const newPath = [...searchBrandPath];
  //   const existingIndex = newPath.findIndex(
  //     (brand) => brand.id === selectedBrand.id
  //   );
  //   if (existingIndex > -1) {
  //     newPath.splice(
  //       existingIndex,
  //       newPath.length - existingIndex,
  //       selectedBrand
  //     );
  //   } else {
  //     newPath.push({
  //       id: selectedBrand.id,
  //       name: selectedBrand.name,
  //       level: newPath.length,
  //       brands: subBrands,
  //     });
  //   }

  //   setSearchBrandPath(newPath);
  //   onSelectBrand(selectedBrand);
  // };

  const fetchBrands = async (parentId = '') => {
    const q = query(
      collection(db, 'brands'),
      where('parentBrandId', '==', parentId)
    );
    const querySnapshot = await getDocs(q);
    const brands = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      name: doc.data().name,
      level: doc.data().level,
      parentBrandId: doc.data().parentBrandId,
    }));
    // return brands;
    // 각 브랜드의 이름을 번역합니다.
    const translatedBrands = await Promise.all(
      brands.map(async (brand) => {
        const translatedName = await googleTranslate(brand.name);
        return { ...brand, name: translatedName };
      })
    );

    return translatedBrands;
  };

  const fetchSiblingsForPath = async (path) => {
    const siblingPromises = path.map(async (brand) => ({
      level: brand.level,
      siblings: await fetchBrands(brand.parentBrandId || ''),
    }));
    const siblingResults = await Promise.all(siblingPromises);
    const newSiblings = siblingResults.reduce(
      (acc, result) => ({
        ...acc,
        [result.level]: result.siblings,
      }),
      {}
    );
    setSiblingBrands(newSiblings);
  };

  // const onSelectBrand = async (selectedBrand) => {
  //   setSelectedBrand(selectedBrand);
  //   try {
  //     // 선택된 브랜드의 ID를 시작점으로 하여 모든 하위 브랜드 ID 찾기
  //     let allBrandIds = await findAllSubBrandIds(selectedBrand.id, []);
  //     allBrandIds = [...allBrandIds, selectedBrand.id];
  //     // 찾은 모든 브랜드 ID들을 사용하여 제품 조회
  //     const products = await fetchProductsByBrands(allBrandIds);

  //     // 조회된 제품들을 상태로 설정
  //     setProducts(products);
  //   } catch (error) {
  //     console.error('Error fetching products by brands: ', error);
  //     // 여기에서 사용자에게 오류 발생을 알리는 로직을 추가할 수 있습니다.
  //   }

  //   // 선택된 브랜드에 대한 검색 로그 남기기
  //   try {
  //     logSearchedBrand(selectedBrand.id);
  //   } catch (logError) {
  //     console.error('Error logging searched brand: ', logError);
  //     // 로그 기록 중 발생한 오류를 처리하는 로직을 추가할 수 있습니다.
  //   }
  // };

  return (
    <Box sx={{ width: '100%', p: 1, marginLeft: 1, marginRight: 1 }}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        <Link
          color="inherit"
          href="#"
          onClick={(e) => {
            e.preventDefault();
            fetchRootBrands();
            onSelectBrand('');
          }}
          sx={{ cursor: 'pointer' }}
        >
          {t('brands')}
        </Link>

        {searchBrandPath.map((brand, index) => (
          <Link
            key={brand.id || index}
            color="inherit"
            href="#"
            onClick={(e) => {
              e.preventDefault();

              handleChangeBrand(brand);

              // onSelectBrand('');
            }}
            sx={{ cursor: 'pointer' }}
          >
            {brand.name}
          </Link>
        ))}
      </Breadcrumbs>
      <Box sx={{ mt: 1, display: 'flex', flexDirection: 'row', gap: 1 }}>
        {subBrands.map((subBrand) => (
          <Button
            key={subBrand.id}
            variant="outlined"
            color="primary"
            onClick={() =>
              handleChangeBrand({
                id: subBrand.id,
                name: subBrand.name,
                level: searchBrandPath.length,
                parentBrandId: subBrand.parentBrandId,
              })
            }
            sx={{
              borderRadius: 16,
              border: '1px solid',
              borderColor: 'primary.main',
              textTransform: 'none',
              padding: '5px 15px',
              margin: '5px',
              '&:hover': {
                backgroundColor: 'primary.light',
                borderColor: 'primary.dark',
              },
            }}
          >
            {subBrand.name}
          </Button>
        ))}
      </Box>
    </Box>
  );
};

export default ProductBrandSelector;
