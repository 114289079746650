// notificationHelper.js
export const checkNotificationPermission = () => {
  if ('Notification' in window) {
    return Notification.permission;
  }
  return 'default';
};

export const requestNotificationPermission = async () => {
  if ('Notification' in window && navigator.serviceWorker) {
    try {
      const permission = await Notification.requestPermission();
      if (permission === 'granted') {
        navigator.serviceWorker.ready.then((registration) => {
          registration.showNotification('알림 제목', {
            body: '알림 내용',
            icon: '/icon.png',
          });
        });
      } else {
        console.log('알림 권한이 거부되었습니다.');
      }
    } catch (error) {
      console.error('알림 권한 요청 중 오류가 발생했습니다:', error);
    }
  } else {
    console.error('이 브라우저에서는 알림을 지원하지 않습니다.');
  }
};
