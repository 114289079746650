// import React, { useState, useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import {
//   collection,
//   db,
//   doc,
//   getDoc,
//   getDocs,
//   query,
//   where,
// } from '../../firebaseConfig';
// import DateFormatter from '../../Utils/DateFormatter';
// import StoreProductPurshase from '../StoreProductPurshase';
// import StorePoints from '../Promotion/Points/StorePoints';
// import useCoupon from '../Promotion/Coupon/UseCoupon';
// import moment from 'moment';
// import '../css/CartItem.css';
// import useOrder from '../StoreAdmin/useOrder';

// function CartItem({
//   item,
//   onCouponChange,
//   isSelected,
//   onToggleSelect,
//   onRemove,
//   onUpdateQuantity,
//   onUpdateCouponPrice,
//   onUpdateDiscountedPrice,
// }) {
//   const [quantity, setQuantity] = useState(item.quantity); // 상태 추가
//   const [storeName, setStoreName] = useState('');
//   const [product, setProduct] = useState(null);

//   const [orderId, setOrderId] = useState('');
//   const [loading, setLoading] = useState(false);
//   const [totalAmount, setTotalAmount] = useState(0);

//   const dispatch = useDispatch();
//   const currentUser = useSelector((state) => state.auth.currentUser);
//   const userId = currentUser.uid;
//   // const [totalAmount, setTotalAmount] = useState(0);
//   const { placeOrder, cancelOrder, returnOrder } = useOrder(item.storeId);

//   const {
//     downloadedCoupons,
//     downloadableCoupons,
//     couponPrice,
//     selectedCoupon,
//     message,
//     downloadCoupon,
//     applyCouponToPrice,
//   } = useCoupon(item.storeId, item.productId, userId);

//   // 예를 들어, 선택된 쿠폰이 바뀔 때 상위 컴포넌트에 변경을 알려줍니다.
//   useEffect(() => {
//     onCouponChange({
//       downloadedCoupons,
//       downloadableCoupons,
//       couponPrice,
//       selectedCoupon,
//     });
//   }, [downloadedCoupons, downloadableCoupons, couponPrice, selectedCoupon]);

//   //////
//   useEffect(() => {
//     if (!item) return;
//     const finalCouponPrice = couponPrice !== undefined ? couponPrice : 0;
//     onUpdateCouponPrice(finalCouponPrice);
//   }, [couponPrice]);

//   useEffect(() => {
//     setTotalAmount(item.price * quantity - (couponPrice || 0));
//   }, [quantity, couponPrice]);

//   // const [itemState, setItemState] = useState(item);

//   // useEffect(() => {
//   //     if (!itemState) return;

//   //     let newDiscountedPrice;

//   //     if (selectedCoupon?.discountType === 'percentage') {
//   //         newDiscountedPrice = itemState.price * (selectedCoupon.discount / 100);
//   //     } else {
//   //         newDiscountedPrice = itemState.price - selectedCoupon.discount;
//   //     }

//   //     // Update the item state with the new discounted price
//   //     setItemState(prevState => ({ ...prevState, discountedPrice: newDiscountedPrice }));
//   // }, [selectedCoupon, itemState]);

//   useEffect(() => {
//     async function fetchData() {
//       try {
//         // Fetch store name
//         const storeRef = doc(db, 'stores', item.storeId);
//         const storeDoc = await getDoc(storeRef);

//         if (storeDoc.exists() && storeDoc.data().storeName) {
//           setStoreName(storeDoc.data().storeName);
//         } else {
//           throw new Error('Store or storeName not found');
//         }

//         // Fetch product details and likes
//         const productRef = doc(
//           db,
//           'stores',
//           item.storeId,
//           'products',
//           item.productId
//         );
//         const productSnapshot = await getDoc(productRef);
//         const productData = productSnapshot.data();

//         if (productData) {
//           const formattedData = {
//             id: productSnapshot.id,
//             ...productData,
//             formattedDate: moment(productData.createDate?.toDate()).fromNow(),
//           };
//           setProduct(formattedData);

//           if (productData.discountCode) {
//             // const discountRef = doc(db, 'stores', storeId, 'discounts', productData.discountCode);
//             // const discountSnapshot = await getDoc(discountRef);
//             // const discountData = discountSnapshot.data();
//             const discountQuery = query(
//               collection(db, 'stores', item.storeId, 'discounts'),
//               where('discountCode', '==', productData.discountCode)
//             );
//             const discountSnapshot = await getDocs(discountQuery);
//             if (!discountSnapshot.empty) {
//               const discountData = discountSnapshot.docs[0].data();

//               if (discountData) {
//                 const now = new Date();
//                 const discountStartDate = discountData.startDate?.toDate();
//                 const discountEndDate = discountData.endDate?.toDate();

//                 // Check if the current date is within the discount period
//                 if (now >= discountStartDate && now <= discountEndDate) {
//                   if (discountData.discountType === 'percentage') {
//                     formattedData.discountedPrice =
//                       formattedData.price -
//                       formattedData.price * (discountData.discount / 100);
//                   } else if (discountData.discountType === 'amount') {
//                     formattedData.discountedPrice =
//                       formattedData.price - discountData.discount;
//                   }
//                   setProduct(formattedData);
//                 }
//               }
//             }
//           }
//         } else {
//           throw new Error('Product not found');
//         }
//       } catch (err) {
//         // setStatus({ loading: false, error: err.message });
//       } finally {
//         // setStatus(prev => ({ ...prev, loading: false }));
//       }
//     }

//     fetchData();
//   }, [item]);

//   const handleQuantityChange = (e) => {
//     const newQuantity = e.target.value;
//     setQuantity(newQuantity);
//     onUpdateQuantity(newQuantity);
//   };

//   const handleCancelOrder = async () => {
//     if (loading) return; // 이미 결제 중인 경우 무시
//     setLoading(true); // 로딩 상태 시작

//     const orderData = {
//       orderId: orderId,
//     };

//     try {
//       dispatchCancelOrder(orderData);
//     } catch (error) {
//       console.error('Error during payment:', error);
//     } finally {
//       setLoading(false); // 로딩 상태 종료
//     }
//   };
//   const handleReturnOrder = async () => {
//     if (loading) return; // 이미 결제 중인 경우 무시
//     setLoading(true); // 로딩 상태 시작

//     const orderData = {
//       orderId: orderId,
//     };

//     try {
//       dispatchReturnOrder(orderData);
//     } catch (error) {
//       console.error('Error during payment:', error);
//     } finally {
//       setLoading(false); // 로딩 상태 종료
//     }
//   };
//   const dispatchPlaceOrder = (orderData) => {
//     //dispatch(placeOrder(orderData));
//     placeOrder(orderData);
//   };

//   const dispatchCancelOrder = (orderData) => {
//     //dispatch(cancelOrder(orderData));
//     cancelOrder(orderData);
//   };

//   const dispatchReturnOrder = (orderData) => {
//     //dispatch(returnOrder(orderData));
//     returnOrder(orderData);
//   };
//   return (
//     <div className="cart-item">
//       <input type="checkbox" checked={isSelected} onChange={onToggleSelect} />
//       <div className="item-image">
//         <a href={`/product_detail/${item.storeId}/${item.productId}`}>
//           <img src={product?.small_images[0]} alt={item.name} />
//         </a>
//       </div>
//       <div className="item-details">
//         <h4>{item.name}</h4>
//         <div className="price-details">
//           <span className="price-detail">가격: {item.price * quantity}원</span>
//           <span className="price-detail">
//             {product?.discountedPrice && product?.discountedPrice > 0 ? (
//               <>
//                 원래금액:{' '}
//                 <span style={{ textDecoration: 'line-through', color: '#888' }}>
//                   {product?.price}원
//                 </span>
//                 <br />
//                 할인가: <strong>{product?.discountedPrice}원</strong>
//               </>
//             ) : (
//               <>
//                 가격: <strong>{product?.price}원</strong>
//               </>
//             )}
//           </span>
//           <span className="price-detail">쿠폰: {couponPrice || 0}원</span>
//           <span className="price-detail quantity-input">
//             수량:{' '}
//             <input
//               type="number"
//               value={quantity}
//               onChange={handleQuantityChange}
//             />
//           </span>
//           <span className="price-detail">금액: {totalAmount}</span>
//         </div>
//       </div>
//       <div className="coupon-section">
//         {message && <p className="message">{message}</p>}
//         {downloadedCoupons.map((downloadedCoupon) => (
//           <div
//             key={`${downloadedCoupon.couponId}-${downloadedCoupon.productId}`}
//             className={`coupon-card ${
//               selectedCoupon?.couponId === downloadedCoupon.couponId &&
//               product?.id === downloadedCoupon.productId
//                 ? 'selected'
//                 : ''
//             }`}
//             onClick={() => applyCouponToPrice(product?.price, downloadedCoupon)}
//             title={`ID: ${downloadedCoupon.couponId}, ProductId: ${downloadedCoupon.productId}, Quantity: ${downloadedCoupon.quantity}`}
//           >
//             <span>
//               {downloadedCoupon.discountType === 'percentage'
//                 ? `할인율: ${downloadedCoupon.discount} %`
//                 : `할인 금액: ${downloadedCoupon.discount} 원`}
//             </span>
//           </div>
//         ))}
//         {downloadableCoupons.length > 0 && (
//           <div className="downloadable-coupons">
//             <p>다운로드 가능한 쿠폰:</p>

//             {downloadableCoupons.map((downloadableCoupon) =>
//               Array.isArray(downloadableCoupon.applicableProducts)
//                 ? downloadableCoupon.applicableProducts.map(
//                     (applicableProduct) => (
//                       <button
//                         key={`${downloadableCoupon.id}-${applicableProduct.id}`}
//                         onClick={() =>
//                           downloadCoupon(
//                             downloadableCoupon.id,
//                             applicableProduct.id
//                           )
//                         }
//                         title={`ID: ${downloadableCoupon.id}, ProductId: ${applicableProduct.id}, Name: ${applicableProduct.name}`}
//                       >
//                         {downloadableCoupon.couponName} -{' '}
//                         {downloadableCoupon.discountType === 'percentage'
//                           ? `${downloadableCoupon.discount}% 할인`
//                           : `${downloadableCoupon.discount} 원 할인`}
//                       </button>
//                     )
//                   )
//                 : null
//             )}
//           </div>
//         )}
//       </div>
//       <div>
//         <button className="remove-button" onClick={onRemove}>
//           X
//         </button>
//       </div>
//     </div>
//   );
// }
// export default CartItem;

// import React, { useState, useEffect } from 'react';
// import {
//   Box,
//   Card,
//   CardContent,
//   CardActions,
//   Checkbox,
//   Button,
//   Typography,
//   TextField,
//   Link,
//   CircularProgress,
// } from '@mui/material';
// import { useDispatch, useSelector } from 'react-redux';
// import {
//   collection,
//   db,
//   doc,
//   getDoc,
//   getDocs,
//   query,
//   where,
// } from '../../firebaseConfig';
// import useCoupon from '../Promotion/Coupon/UseCoupon';
// import useOrder from '../StoreAdmin/useOrder';
// import { useTranslation } from 'react-i18next';

// function CartItem({
//   item,
//   onCouponChange,
//   isSelected,
//   onToggleSelect,
//   onRemove,
//   onUpdateQuantity,
//   onUpdateCouponPrice,
//   onUpdateDiscountedPrice,
// }) {
//   const { t, i18n } = useTranslation();
//   const [quantity, setQuantity] = useState(item.quantity);
//   const [product, setProduct] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const [totalAmount, setTotalAmount] = useState(0);
//   const dispatch = useDispatch();
//   const currentUser = useSelector((state) => state.auth.currentUser);
//   const userId = currentUser?.uid;
//   const { couponPrice, selectedCoupon, message, downloadedCoupons } = useCoupon(
//     item.storeId,
//     item.productId,
//     userId
//   );

//   useEffect(() => {
//     setTotalAmount(item.price * quantity - (couponPrice || 0));
//   }, [quantity, couponPrice]);

//   useEffect(() => {
//     async function fetchData() {
//       const productRef = doc(
//         db,
//         'stores',
//         item.storeId,
//         'products',
//         item.productId
//       );
//       const productSnapshot = await getDoc(productRef);
//       if (productSnapshot.exists()) {
//         setProduct({ ...productSnapshot.data(), id: productSnapshot.id });
//       }
//     }
//     fetchData();
//   }, [item.storeId, item.productId]);

//   const handleQuantityChange = (e) => {
//     const newQuantity = parseInt(e.target.value, 10);
//     setQuantity(newQuantity);
//     onUpdateQuantity(newQuantity);
//   };

//   if (loading) {
//     return <CircularProgress />;
//   }

//   return (
//     <Card variant="outlined" sx={{ mb: 2 }}>
//       <CardContent>
//         <Box sx={{ display: 'flex', alignItems: 'center' }}>
//           <Checkbox checked={isSelected} onChange={onToggleSelect} />
//           <Link
//             href={`/product_detail/${item.storeId}/${item.productId}`}
//             underline="none"
//           >
//             <img
//               src={product?.icon_images[0]}
//               alt={item.name}
//               style={{ width: 100, height: 100, marginRight: 2 }}
//             />
//           </Link>
//           <Box sx={{ flexGrow: 1 }}>
//             <Typography variant="h6">{item.name}</Typography>
//             <Typography variant="body2">
//               {t('price')}: {item.price}
//               {t('currency')}
//             </Typography>
//             {product?.discountedPrice > 0 && (
//               <Typography variant="body2" color="text.secondary">
//                 {t('discountedPrice')}:{' '}
//                 <strong>
//                   {product.discountedPrice}
//                   {t('currency')}
//                 </strong>
//               </Typography>
//             )}
//             <Typography variant="body2">
//               {t('coupon')}: {couponPrice || 0}
//               {t('currency')}
//             </Typography>
//             <Box sx={{ display: 'flex', alignItems: 'center' }}>
//               <Typography variant="body2" sx={{ mr: 1 }}>
//                 {t('quantity')}:
//               </Typography>
//               <TextField
//                 type="number"
//                 value={quantity}
//                 onChange={handleQuantityChange}
//                 size="small"
//                 InputProps={{ inputProps: { min: 1 } }}
//               />
//             </Box>
//             <Typography variant="body2">
//               {t('totalAmount')}: {totalAmount}
//               {t('currency')}
//             </Typography>
//           </Box>
//         </Box>
//         <Box
//           sx={{
//             display: 'flex',
//             justifyContent: 'space-between',
//             alignItems: 'center',
//           }}
//         >
//           <Typography color="text.secondary">
//             {downloadedCoupons.length > 0 ? '' : t('noDownloadedCoupons')}
//           </Typography>
//           <Button size="small" color="error" onClick={onRemove}>
//             {t('remove')}
//           </Button>
//         </Box>
//       </CardContent>
//     </Card>
//   );
// }

// export default CartItem;

// import React, { useState, useEffect } from 'react';
// import {
//   Box,
//   Card,
//   CardContent,
//   CardActions,
//   Checkbox,
//   Button,
//   Typography,
//   TextField,
//   Link,
//   CircularProgress,
//   MenuItem,
//   Select,
//   FormControl,
//   InputLabel,
// } from '@mui/material';
// import { useDispatch, useSelector } from 'react-redux';
// import {
//   collection,
//   db,
//   doc,
//   getDoc,
//   getDocs,
//   query,
//   where,
// } from '../../firebaseConfig';
// import useCoupon from '../Promotion/Coupon/UseCoupon';
// import { useTranslation } from 'react-i18next';

// function CartItem({
//   item,
//   onCouponChange,
//   isSelected,
//   onToggleSelect,
//   onRemove,
//   onUpdateQuantity,
//   onUpdateCouponPrice,
//   onUpdateDiscountedPrice,
// }) {
//   const { t, i18n } = useTranslation();
//   const [quantity, setQuantity] = useState(item.quantity);
//   const [product, setProduct] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const [totalAmount, setTotalAmount] = useState(0);
//   const [selectedCouponId, setSelectedCouponId] = useState('');
//   const dispatch = useDispatch();
//   const currentUser = useSelector((state) => state.auth.currentUser);
//   const userId = currentUser?.uid;
//   const {
//     couponPrice,
//     selectedCoupon,
//     message,
//     downloadedCoupons,
//     applyCouponToPrice,
//   } = useCoupon(item.storeId, item.productId, userId);

//   useEffect(() => {
//     setTotalAmount(item.price * quantity - (couponPrice || 0));
//   }, [quantity, couponPrice]);

//   useEffect(() => {
//     async function fetchData() {
//       const productRef = doc(
//         db,
//         'stores',
//         item.storeId,
//         'products',
//         item.productId
//       );
//       const productSnapshot = await getDoc(productRef);
//       if (productSnapshot.exists()) {
//         setProduct({ ...productSnapshot.data(), id: productSnapshot.id });
//       }
//     }
//     fetchData();
//   }, [item.storeId, item.productId]);

//   const handleQuantityChange = (e) => {
//     const newQuantity = parseInt(e.target.value, 10);
//     setQuantity(newQuantity);
//     onUpdateQuantity(newQuantity);
//   };

//   const handleCouponChange = (e) => {
//     const couponId = e.target.value;
//     setSelectedCouponId(couponId);
//     const selectedCoupon = downloadedCoupons.find(
//       (coupon) => coupon.couponId === couponId
//     );
//     let newDiscountedPrice = 0;
//     if (selectedCoupon) {
//       if (selectedCoupon?.discountType === 'percentage') {
//         newDiscountedPrice = item.price * (selectedCoupon.discount / 100);
//       } else {
//         newDiscountedPrice = item.price - selectedCoupon.discount;
//       }

//       applyCouponToPrice(item.price * quantity, selectedCoupon);
//       onUpdateCouponPrice(newDiscountedPrice);
//     }
//   };

//   if (loading) {
//     return <CircularProgress />;
//   }

//   return (
//     <Card variant="outlined" sx={{ mb: 2 }}>
//       <CardContent>
//         <Box sx={{ display: 'flex', alignItems: 'center' }}>
//           <Checkbox checked={isSelected} onChange={onToggleSelect} />
//           <Link
//             href={`/product_detail/${item.storeId}/${item.productId}`}
//             underline="none"
//           >
//             <img
//               src={product?.icon_images[0]}
//               alt={item.name}
//               style={{ width: 100, height: 100, marginRight: 2 }}
//             />
//           </Link>
//           <Box sx={{ flexGrow: 1 }}>
//             <Typography variant="h6">{item.name}</Typography>
//             <Typography variant="body2">
//               {t('price')}: {item.price}
//               {t('currency')}
//             </Typography>
//             {product?.discountedPrice > 0 && (
//               <Typography variant="body2" color="text.secondary">
//                 {t('discountedPrice')}:{' '}
//                 <strong>
//                   {product.discountedPrice}
//                   {t('currency')}
//                 </strong>
//               </Typography>
//             )}
//             <FormControl fullWidth size="small" sx={{ mt: 1 }}>
//               <InputLabel>{t('selectCoupon')}</InputLabel>
//               <Select
//                 value={selectedCouponId}
//                 onChange={handleCouponChange}
//                 label={t('selectCoupon')}
//               >
//                 {downloadedCoupons.map((coupon) => (
//                   <MenuItem key={coupon.couponId} value={coupon.couponId}>
//                     {coupon.couponName}
//                   </MenuItem>
//                 ))}
//               </Select>
//             </FormControl>
//             <Typography variant="body2">
//               {t('coupon')}: {couponPrice || 0}
//               {t('currency')}
//             </Typography>
//             <Box sx={{ display: 'flex', alignItems: 'center' }}>
//               <Typography variant="body2" sx={{ mr: 1 }}>
//                 {t('quantity')}:
//               </Typography>
//               <TextField
//                 type="number"
//                 value={quantity}
//                 onChange={handleQuantityChange}
//                 size="small"
//                 InputProps={{ inputProps: { min: 1 } }}
//               />
//             </Box>
//             <Typography variant="body2">
//               {t('totalAmount')}: {totalAmount}
//               {t('currency')}
//             </Typography>
//           </Box>
//         </Box>
//         <Box
//           sx={{
//             display: 'flex',
//             justifyContent: 'space-between',
//             alignItems: 'center',
//           }}
//         >
//           <Typography color="text.secondary">
//             {downloadedCoupons.length > 0 ? '' : t('noDownloadedCoupons')}
//           </Typography>
//           <Button size="small" color="error" onClick={onRemove}>
//             {t('remove')}
//           </Button>
//         </Box>
//       </CardContent>
//     </Card>
//   );
// }

// export default CartItem;

import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  Checkbox,
  Button,
  Typography,
  TextField,
  Link,
  CircularProgress,
  Paper,
  Grid,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  db,
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
} from '../../firebaseConfig';
import moment from 'moment';
import { formatPrice } from '../../Utils/PriceFormatter';
import useCoupon from '../Promotion/Coupon/UseCoupon';
import { useTranslation } from 'react-i18next';
import DateFormatter from '../../Utils/DateFormatter';

function CartItem({
  item,
  onCouponChange,
  isSelected,
  onToggleSelect,
  onRemove,
  onUpdateQuantity,
  onUpdateFinalTotalPrice,
}) {
  const { t, i18n } = useTranslation();
  const [quantity, setQuantity] = useState(item.quantity);
  const [product, setProduct] = useState(null);
  const [discount, setDiscount] = useState(null);
  const [loading, setLoading] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [singleAmount, setSingleAmount] = useState(0);
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [status, setStatus] = useState('');
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const userId = currentUser?.uid;
  const { downloadedCoupons, applyCouponToPrice } = useCoupon(
    item.storeId,
    item.productId,
    userId
  );

  useEffect(() => {
    fetchTotalAmount();
  }, []);
  useEffect(() => {
    fetchTotalAmount();
  }, [product, selectedCoupon, quantity]);

  async function fetchTotalAmount() {
    try {
      if (!product) {
        return;
      }
      const productPrice =
        product.discountedPrice > 0 ? product.discountedPrice : product.price;
      if (selectedCoupon) {
        if (selectedCoupon.discountType === 'percentage') {
          setSingleAmount(
            productPrice - productPrice * (selectedCoupon.discount / 100)
          );
          setTotalAmount(
            productPrice * quantity -
              productPrice * quantity * (selectedCoupon.discount / 100)
          );
        } else if (selectedCoupon.discountType === 'amount') {
          setSingleAmount(productPrice - selectedCoupon.discount);
          setTotalAmount(productPrice * quantity - selectedCoupon.discount);
        } else {
          setSingleAmount(productPrice);
          setTotalAmount(productPrice * quantity);
        }
      } else {
        setSingleAmount(productPrice);
        setTotalAmount(productPrice * quantity);
      }
    } catch (err) {
    } finally {
      setStatus((prev) => ({ ...prev, loading: false }));
    }
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const storeRef = doc(db, 'stores', item.storeId);
        const storeDoc = await getDoc(storeRef);

        if (storeDoc.exists() && storeDoc.data().storeName) {
          // setStoreName(storeDoc.data().storeName);
        } else {
          throw new Error('Store or storeName not found');
        }

        const productRef = doc(
          db,
          'stores',
          item.storeId,
          'products',
          item.productId
        );
        const productSnapshot = await getDoc(productRef);
        const productData = productSnapshot.data();

        if (!productData) {
          throw new Error('Product not found');
        }

        const formattedData = {
          id: productSnapshot.id,
          ...productData,
          formattedDate: moment(productData.createDate?.toDate()).fromNow(),
        };

        if (productData.discountCode) {
          const discountQuery = query(
            collection(db, 'stores', item.storeId, 'discounts'),
            where('discountCode', '==', productData.discountCode)
          );
          const discountSnapshot = await getDocs(discountQuery);
          if (!discountSnapshot.empty) {
            const discountData = discountSnapshot.docs[0].data();

            if (discountData) {
              const now = new Date();
              const discountStartDate = discountData.startDate?.toDate();
              const discountEndDate = discountData.endDate?.toDate();

              if (now >= discountStartDate && now <= discountEndDate) {
                if (discountData.discountType === 'percentage') {
                  formattedData.discountedPrice =
                    formattedData.price -
                    formattedData.price * (discountData.discount / 100);
                } else if (discountData.discountType === 'amount') {
                  formattedData.discountedPrice =
                    formattedData.price - discountData.discount;
                }

                setDiscount(discountData);
              }
            }
          }
        }
        setProduct(formattedData);
      } catch (err) {
        if (err.code === 'failed-precondition' || err.code === 'unavailable') {
          console.error('Firestore index error:', err);
          setStatus({
            loading: false,
            error:
              '필요한 인덱스가 없습니다. Firestore 콘솔에서 인덱스를 생성하세요.',
          });
        } else {
          console.error('Error fetching data:', err);
          setStatus({ loading: false, error: err.message });
        }
      } finally {
        setStatus((prev) => ({ ...prev, loading: false }));
      }
    }

    fetchData();
  }, [item.storeId, item.productId, currentUser]);

  const handleQuantityChange = (e) => {
    const newQuantity = parseInt(e.target.value, 10);

    let totalAmount;
    let singleAmount;

    const productPrice =
      product.discountedPrice > 0 ? product.discountedPrice : product.price;

    if (selectedCoupon) {
      if (selectedCoupon.discountType === 'percentage') {
        totalAmount =
          productPrice * newQuantity -
          productPrice * newQuantity * (selectedCoupon.discount / 100);
        singleAmount =
          productPrice - productPrice * (selectedCoupon.discount / 100);
      } else if (selectedCoupon.discountType === 'amount') {
        totalAmount = productPrice * newQuantity - selectedCoupon.discount;
        singleAmount = productPrice - selectedCoupon.discount;
      } else {
        totalAmount = productPrice * newQuantity;
        singleAmount = productPrice * newQuantity;
      }
    } else {
      totalAmount = productPrice * newQuantity;
      singleAmount = productPrice * newQuantity;
    }
    setTotalAmount(totalAmount);
    setSingleAmount(singleAmount);

    setQuantity(newQuantity);
    onUpdateQuantity(newQuantity);
    onUpdateFinalTotalPrice(totalAmount);
  };

  const handleCouponSelect = (selectedCoupon) => {
    let totalAmount, singleAmount;
    const productPrice =
      product.discountedPrice > 0 ? product.discountedPrice : product.price;

    if (selectedCoupon.discountType === 'percentage') {
      totalAmount =
        productPrice * quantity -
        productPrice * quantity * (selectedCoupon.discount / 100);
      singleAmount =
        productPrice - productPrice * (selectedCoupon.discount / 100);
    } else if (selectedCoupon.discountType === 'amount') {
      totalAmount = productPrice * quantity - selectedCoupon.discount;
      singleAmount = productPrice - selectedCoupon.discount;
    } else {
      totalAmount = productPrice * quantity;
      singleAmount = productPrice;
    }
    setTotalAmount(totalAmount);
    setSingleAmount(singleAmount);
    onUpdateFinalTotalPrice(totalAmount);

    setSelectedCoupon(selectedCoupon);
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Card variant="outlined" sx={{ mb: 2 }}>
      <CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox checked={isSelected} onChange={onToggleSelect} />
          <Link
            href={`/product_detail/${item.storeId}/${item.productId}`}
            underline="none"
          >
            <img
              src={product?.icon_images[0]}
              alt={item.name}
              style={{ width: 100, height: 100, marginRight: 2 }}
            />
          </Link>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="h6">{item.name}</Typography>
            {/* <Typography variant="body2">
              {t('price')}: {item.price}
              {t('currency')}
            </Typography>
            {product?.discountedPrice > 0 && (
              <Typography variant="body2" color="text.secondary">
                {t('discountedPrice')}:{' '}
                <strong>
                  {product.discountedPrice}
                  {t('currency')}
                </strong>
              </Typography>
            )} */}
            <Typography variant="subtitle1">
              {product?.discountedPrice && product?.discountedPrice > 0 ? (
                <>
                  {t('original-price')}:{' '}
                  <span
                    style={{
                      textDecoration: 'line-through',
                      color: '#888',
                    }}
                  >
                    {formatPrice(product?.price)}원
                  </span>
                  <br />
                  {t('discounted-price')}:{' '}
                  <strong>{formatPrice(product?.discountedPrice)}원</strong>
                  {discount?.discountType === 'percentage' ? (
                    <>
                      {' '}
                      (<strong>{discount?.discount}%</strong>)
                    </>
                  ) : discount?.discountType === 'amount' ? (
                    <>
                      {' '}
                      (<strong>{formatPrice(discount?.discount)}원</strong>)
                    </>
                  ) : null}
                </>
              ) : (
                <>
                  {t('price')}: <strong>{formatPrice(product?.price)}원</strong>
                </>
              )}
            </Typography>
            <Typography variant="body2">
              {t('coupon')}:
              {selectedCoupon
                ? selectedCoupon.discountType === 'percentage'
                  ? `${selectedCoupon.discount}%`
                  : `${selectedCoupon.discount}${t('currency')}`
                : `0${t('currency')}`}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body2" sx={{ mr: 1 }}>
                {t('quantity')}:
              </Typography>
              <TextField
                type="number"
                value={quantity}
                onChange={handleQuantityChange}
                size="small"
                InputProps={{ inputProps: { min: 1 } }}
              />
            </Box>
            <Typography variant="body2">
              {t('finalPrice')}: {singleAmount}
              {t('currency')}
            </Typography>
            <Typography variant="body2">
              {t('totalAmount')}: {totalAmount}
              {t('currency')}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography color="text.secondary">
            {downloadedCoupons.length > 0 ? '' : t('noDownloadedCoupons')}
          </Typography>
          <Button size="small" color="error" onClick={onRemove}>
            {t('remove')}
          </Button>
        </Box>
        <Box mt={2}>
          <Typography variant="h6">{t('availableCoupons')}</Typography>
          <Grid container spacing={2}>
            {downloadedCoupons.map((coupon) => (
              <Grid item xs={12} sm={6} md={4} key={coupon.couponId}>
                <Paper
                  elevation={3}
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    cursor: 'pointer',
                    border:
                      selectedCoupon && selectedCoupon.id === coupon.couponId
                        ? '2px solid #1976d2'
                        : '2px dashed #ddd',
                    borderRadius: '10px',
                  }}
                  onClick={() => handleCouponSelect(coupon)}
                >
                  <Typography variant="subtitle1" gutterBottom>
                    {coupon.couponName}
                  </Typography>
                  <Typography variant="body2">
                    {coupon.discountType === 'percentage'
                      ? `${coupon.discount}%`
                      : `${coupon.discount}${t('currency')}`}
                  </Typography>
                  <Typography variant="body2">
                    {t('validUntil')}:{' '}
                    {DateFormatter.formatTimeStamp(coupon.endDate)}
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
}

export default CartItem;
