// import React, { useState } from 'react';
// import './css/ProductQnaTab.css';

// const ProductQnaTab = ({ qnaList }) => {
//   const [showAnswer, setShowAnswer] = useState({});

//   const toggleAnswer = (qnaId) => {
//     setShowAnswer((prevShowAnswer) => ({
//       ...prevShowAnswer,
//       [qnaId]: !prevShowAnswer[qnaId],
//     }));
//   };

//   return (
//     <div className="tab-content">
//       <div className="qna-list">
//         {qnaList.map((qna) => (
//           <div key={qna.id} className="qna-item">
//             <div className="qna-question">
//               <p>{qna.question}</p>
//             </div>
//             <div className="qna-answer">
//               <button
//                 onClick={() => toggleAnswer(qna.id)}
//                 className="toggle-answer-button"
//               >
//                 {showAnswer[qna.id] ? 'Hide Answer' : 'Show Answer'}
//               </button>
//               {showAnswer[qna.id] && (
//                 <p className="qna-answer-text">{qna.answer}</p>
//               )}
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default ProductQnaTab;

import React, { useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const ProductQnaTab = ({ qnaList }) => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (qnaId) => (event, isExpanded) => {
    setExpanded(isExpanded ? qnaId : false);
  };

  // Ensure qnaList is defined before attempting to map over it
  if (!qnaList || qnaList.length === 0) {
    return (
      <Box className="tab-content">
        <Typography variant="body2">No questions available.</Typography>
      </Box>
    );
  }

  return (
    <Box className="tab-content">
      {qnaList.map((qna) => (
        <Accordion
          key={qna.id}
          expanded={expanded === qna.id}
          onChange={handleChange(qna.id)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${qna.id}-content`}
            id={`panel${qna.id}-header`}
          >
            <Typography variant="subtitle1">{qna.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2">{qna.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default ProductQnaTab;
