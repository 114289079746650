import React, { useState, useEffect } from 'react';
import axios from 'axios';

function AutoCompleteSearch({ query }) {
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    const fetchSuggestions = async () => {
      if (!query) {
        setSuggestions([]);
        return;
      }
      try {
        // Firebase Function을 통한 검색 요청
        const response = await axios.post('https://asia-northeast3-apple-market-af8c6.cloudfunctions.net/ElasticSearchHandler', { 
          query: query, 
        });
          
        // 검색 결과를 suggestions 상태에 설정
        setSuggestions(response.data);
      } catch (error) {
        console.error("Failed to fetch suggestions:", error);
        setSuggestions([]);
      }
    };

    fetchSuggestions();
  }, [query]);

  return (
    suggestions.length > 0 && (
      <ul className="auto-complete-suggestions">
        {suggestions.map((suggestion, index) => (
          <li key={index} className="suggestion-item">
            {suggestion}
          </li>
        ))}
      </ul>
    )
  );
}

export default AutoCompleteSearch;