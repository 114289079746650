// import React, { useState } from 'react';
// import { useTranslation } from 'react-i18next';
// import './SearchListContainer.css';
// import RecentSearchList from './RecentSearchList';
// import RealTimeSearchList from './RealTimeSearchList';
// import SearchTabBar from './SearchTabBar';

// const SearchListContainer = ({
//   recentSearches,
//   realTimeSearches,
//   setRecentSearches,
//   onSelectSearchKeyword,
// }) => {
//   const { t, i18n } = useTranslation();
//   const [activeTab, setActiveTab] = useState(1);
//   // 개별 검색어 삭제
//   const handleDeleteItem = (index) => {
//     const newRecentSearches = recentSearches.filter((_, i) => i !== index);
//     setRecentSearches(newRecentSearches);
//   };

//   // 전체 검색어 삭제
//   const handleClearAll = () => {
//     setRecentSearches([]);
//   };

//   const handleSelectRecentSearch = (keyword) => {
//     console.log('Selected recent keyword:', keyword);
//     onSelectSearchKeyword(keyword);
//   };

//   const handleSelectRealTimeSearch = (keyword) => {
//     console.log('Selected realtime keyword:', keyword);
//     onSelectSearchKeyword(keyword);
//   };

//   return (
//     <div>
//       <div
//         style={{
//           position: 'absolute',
//           top: '100%',
//           left: 0,
//           right: 0,
//           backgroundColor: 'white',
//           zIndex: 100,
//           display: 'flex',
//         }}
//       >
//         <div style={{ flex: 1, padding: '10px' }}>
//           <h4>{t('recentSearches')}</h4>
//           <RecentSearchList
//             items={recentSearches}
//             onDeleteItem={(index) => handleDeleteItem(index)}
//             onClearAll={() => handleClearAll()}
//             onSelectRecentSearch={(searchTerm) =>
//               handleSelectRecentSearch(searchTerm)
//             }
//           />
//         </div>
//         <div style={{ flex: 1, padding: '10px' }}>
//           <h4>{t('realTimeShoppingSearches')}</h4>
//           <SearchTabBar onSelectTab={setActiveTab} activeTab={activeTab} />
//           <RealTimeSearchList
//             items={realTimeSearches}
//             activeTab={activeTab}
//             onSelectRealTimeSearch={handleSelectRealTimeSearch}
//           />
//         </div>
//       </div>
//     </div>
//   );
// };
// export default SearchListContainer;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './SearchListContainer.css';
import RecentSearchList from './RecentSearchList';
import RealTimeSearchList from './RealTimeSearchList';
import SearchTabBar from './SearchTabBar';

const SearchListContainer = ({
  recentSearches,
  realTimeSearches,
  setRecentSearches,
  onSelectSearchKeyword,
  activeTab,
  setActiveTab,
}) => {
  const { t, i18n } = useTranslation();

  // 개별 검색어 삭제
  const handleDeleteItem = (index) => {
    const newRecentSearches = recentSearches.filter((_, i) => i !== index);
    setRecentSearches(newRecentSearches);
  };

  // 전체 검색어 삭제
  const handleClearAll = () => {
    setRecentSearches([]);
  };

  const handleSelectRecentSearch = (keyword) => {
    console.log('Selected recent keyword:', keyword);
    onSelectSearchKeyword(keyword);
  };

  const handleSelectRealTimeSearch = (keyword) => {
    console.log('Selected realtime keyword:', keyword);
    onSelectSearchKeyword(keyword);
  };

  return (
    <div>
      <div
        style={{
          position: 'absolute',
          top: '100%',
          left: 0,
          right: 0,
          backgroundColor: 'white',
          zIndex: 100,
          display: 'flex',
        }}
      >
        <div style={{ flex: 1, padding: '10px' }}>
          <h4>{t('recentSearches')}</h4>
          <RecentSearchList
            items={recentSearches}
            onDeleteItem={(index) => handleDeleteItem(index)}
            onClearAll={() => handleClearAll()}
            onSelectRecentSearch={(searchTerm) =>
              handleSelectRecentSearch(searchTerm)
            }
          />
        </div>
        <div style={{ flex: 1, padding: '10px' }}>
          <h4>{t('realTimeShoppingSearches')}</h4>
          <SearchTabBar onSelectTab={setActiveTab} activeTab={activeTab} />
          <RealTimeSearchList
            items={realTimeSearches}
            activeTab={activeTab}
            onSelectRealTimeSearch={handleSelectRealTimeSearch}
          />
        </div>
      </div>
    </div>
  );
};

export default SearchListContainer;
