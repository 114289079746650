import { createSlice } from '@reduxjs/toolkit';

const floatingSidebarSlice = createSlice({
  name: 'floatingSidebar',
  initialState: {
    likedProducts: [],
    likedStoreProducts: [],
    recentlyViewedProducts: [],
    recentlyViewedStoreProducts: [],
  },
  reducers: {
    likeProduct: (state, action) => {
      if (!state.likedProducts.includes(action.payload)) {
        state.likedProducts.push(action.payload);
      }
    },
    likeStoreProduct: (state, action) => {
      if (!state.likedStoreProducts.includes(action.payload)) {
        state.likedStoreProducts.push(action.payload);
      }
    },
    addRecentlyViewedProduct: (state, action) => {
      if (!state.recentlyViewedProducts.includes(action.payload)) {
        state.recentlyViewedProducts = [
          action.payload,
          ...state.recentlyViewedProducts,
        ].slice(0, 10);
      }
    },
    addRecentlyViewedStoreProduct: (state, action) => {
      if (!state.recentlyViewedStoreProducts.includes(action.payload)) {
        state.recentlyViewedStoreProducts = [
          action.payload,
          ...state.recentlyViewedStoreProducts,
        ].slice(0, 10);
      }
    },
  },
});

export const {
  likeProduct,
  likeStoreProduct,
  addRecentlyViewedProduct,
  addRecentlyViewedStoreProduct,
} = floatingSidebarSlice.actions;

export default floatingSidebarSlice.reducer;
