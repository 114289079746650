// import { useState, useEffect } from 'react';
// import DaumPostCode from 'react-daum-postcode';
// import './css/address.css';

// function UserAddress({ user, onUpdate, onAddressSelect }) {
//   const initialState = {
//     zoneCode: user?.zoneCode || '',
//     address: user?.address || '',
//     extraAddress: user?.extraAddress || '',
//     additionalAddresses: user?.additionalAddresses || [],
//   };

//   const [state, setState] = useState(initialState);
//   const [selectedAddressType, setSelectedAddressType] = useState('default');
//   const [selectedAddressIndex, setSelectedAddressIndex] = useState(null);
//   const [activePostIdx, setActivePostIdx] = useState(null);
//   const [visibleDaumPost, setVisibleDaumPost] = useState(false);

//   useEffect(() => {
//     setState({
//       zoneCode: user?.zoneCode || '',
//       address: user?.address || '',
//       extraAddress: user?.extraAddress || '',
//       additionalAddresses: user?.additionalAddresses || [],
//     });

//     if (
//       user?.address &&
//       user?.extraAddress &&
//       user?.zoneCode &&
//       selectedAddressType !== 'additional'
//     ) {
//       onAddressSelect({
//         address: user.address,
//         extraAddress: user.extraAddress,
//         zoneCode: user.zoneCode,
//       });
//     }
//   }, [user]);

//   // useEffect(() => {
//   //     const changes = {};
//   //     if (user?.zoneCode !== state.zoneCode) changes.zoneCode = user?.zoneCode;
//   //     if (user?.address !== state.address) changes.address = user?.address;
//   //     if (user?.extraAddress !== state.extraAddress) changes.extraAddress = user?.extraAddress;
//   //     if (JSON.stringify(user?.additionalAddresses) !== JSON.stringify(state.additionalAddresses)) changes.additionalAddresses = user?.additionalAddresses;

//   //     if (Object.keys(changes).length) setState(prevState => ({ ...prevState, ...changes }));

//   //     if (user?.address && user?.extraAddress && user?.zoneCode && selectedAddressType !== 'additional') {
//   //         onAddressSelect({
//   //             address: user.address,
//   //             extraAddress: user.extraAddress,
//   //             zoneCode: user.zoneCode
//   //         });
//   //     }
//   // }, [user, onAddressSelect]);

//   // useEffect(() => {
//   //     if(user?.zoneCode !== zoneCode) {
//   //        setZoneCode(user?.zoneCode);
//   //     }
//   //     if(user?.address !== address) {
//   //        setAddress(user?.address);
//   //     }
//   //     if(user?.extraAddress !== extraAddress) {
//   //        setExtraAddress(user?.extraAddress);
//   //     }
//   //     if(JSON.stringify(user?.additionalAddresses) !== JSON.stringify(additionalAddresses)) {
//   //        setAdditionalAddresses(user?.additionalAddresses);
//   //     }
//   //  }, [user]);

//   const handleAddressSelection = (type, index) => {
//     setSelectedAddressType(type);
//     setSelectedAddressIndex(index);
//     const selectedAddress =
//       type === 'default'
//         ? {
//             address: state.address,
//             extraAddress: state.extraAddress,
//             zoneCode: state.zoneCode,
//           }
//         : state.additionalAddresses[index];
//     onAddressSelect(selectedAddress);
//   };
//   const handleRadioClick = (type, index) => {
//     // 이미 선택된 라디오 버튼을 다시 클릭하는 경우, handleAddressSelection 함수를 호출하여 처리
//     if (
//       (type === 'default' && selectedAddressType === 'default') ||
//       (type === 'additional' &&
//         selectedAddressType === 'additional' &&
//         index === selectedAddressIndex)
//     ) {
//       handleAddressSelection(type, index);
//     }
//   };

//   const handleAddAdditionalAddress = () => {
//     const addresses = Array.isArray(state.additionalAddresses)
//       ? state.additionalAddresses
//       : [];
//     const updatedAddresses = [
//       ...addresses,
//       { address: '', extraAddress: '', zoneCode: '' },
//     ];
//     setState((prevState) => ({
//       ...prevState,
//       additionalAddresses: updatedAddresses,
//     }));
//     console.log('Updated Addresses:', updatedAddresses);
//   };

//   const handleUpdateAddress = (index, key, value) => {
//     const updatedAddresses = [...state.additionalAddresses];
//     updatedAddresses[index][key] = value;
//     setState((prevState) => ({
//       ...prevState,
//       additionalAddresses: updatedAddresses,
//     }));
//   };

//   const handleSetAsDefault = (index) => {
//     const selectedAddress = state.additionalAddresses[index];

//     const newAdditionalAddresses = [
//       ...state.additionalAddresses.slice(0, index),
//       {
//         address: state.address,
//         extraAddress: state.extraAddress,
//         zoneCode: state.zoneCode,
//       },
//       ...state.additionalAddresses.slice(index + 1),
//     ];

//     setState({
//       ...state,
//       address: selectedAddress.address,
//       extraAddress: selectedAddress.extraAddress,
//       zoneCode: selectedAddress.zoneCode,
//       additionalAddresses: newAdditionalAddresses,
//     });

//     const updatedUser = {
//       ...user,
//       address: selectedAddress.address,
//       extraAddress: selectedAddress.extraAddress,
//       zoneCode: selectedAddress.zoneCode,
//       additionalAddresses: newAdditionalAddresses,
//     };

//     onUpdate(updatedUser);
//   };

//   const handleSubmit = () => {
//     const updatedUser = {
//       ...user,
//       zoneCode: state.zoneCode,
//       address: state.address,
//       extraAddress: state.extraAddress,
//       additionalAddresses: state.additionalAddresses,
//     };
//     onUpdate(updatedUser);
//   };

//   const handleOpenPost = (idx = null) => {
//     setActivePostIdx(idx);
//     setVisibleDaumPost(true);
//   };

//   const handleAddress = (data, idx = null) => {
//     const { addressType, bname, buildingName, address, zonecode } = data;
//     let fullAddress = address;
//     let extraAddress = '';

//     if (addressType === 'R') {
//       if (bname) extraAddress += bname;
//       if (buildingName)
//         extraAddress += extraAddress ? `, ${buildingName}` : buildingName;
//       fullAddress += extraAddress ? ` (${extraAddress})` : '';
//     }

//     if (idx === null) {
//       setState({
//         ...state,
//         address: fullAddress,
//         extraAddress: extraAddress,
//         zoneCode: zonecode,
//       });
//     } else {
//       const updatedAddresses = [...state.additionalAddresses];
//       updatedAddresses[idx] = {
//         ...updatedAddresses[idx],
//         address: fullAddress,
//         extraAddress: extraAddress,
//         zoneCode: zonecode,
//       };
//       setState((prevState) => ({
//         ...prevState,
//         additionalAddresses: updatedAddresses,
//       }));
//     }
//   };

//   return (
//     <div className="address-container">
//       <h2>기본 배송지</h2>
//       <div className="address-card">
//         <label
//           className={`address-selection ${
//             selectedAddressType === 'default' ? 'selected' : ''
//           }`}
//         >
//           <input
//             type="radio"
//             name="selectedAddress"
//             checked={selectedAddressType === 'default'}
//             onChange={() => handleAddressSelection('default', null)}
//             onClick={() => handleRadioClick('default', null)}
//           />
//           이번 배송 주소로 선택
//         </label>
//         <div className="cellFirst">
//           <button
//             type="button"
//             onClick={() => handleOpenPost(null)}
//             className="btn btn-primary"
//           >
//             우편번호 찾기
//           </button>
//         </div>

//         {visibleDaumPost && activePostIdx === null && (
//           <DaumPostCode
//             onComplete={(data) => handleAddress(data)}
//             autoClose
//             width="595px"
//             height="450px"
//           />
//         )}
//         <div className="zipCode">우편번호: {state.zoneCode}</div>
//         <div className="flex-container">
//           <label>기본 주소: </label>
//           <input
//             className="flex-input"
//             value={state.address}
//             onChange={(e) =>
//               setState((prevState) => ({
//                 ...prevState,
//                 address: e.target.value,
//               }))
//             }
//           />
//         </div>
//         <div className="flex-container">
//           <label>상세 주소: </label>
//           <input
//             className="flex-input"
//             value={state.extraAddress}
//             onChange={(e) =>
//               setState((prevState) => ({
//                 ...prevState,
//                 extraAddress: e.target.value,
//               }))
//             }
//           />
//         </div>
//       </div>

//       <div className="modalCell">
//         <h2>추가 배송지</h2>
//         {state.additionalAddresses &&
//           state.additionalAddresses.map((addr, index) => (
//             <div key={index} className="address-card">
//               <label
//                 className={`address-selection ${
//                   selectedAddressType === 'additional' &&
//                   selectedAddressIndex === index
//                     ? 'selected'
//                     : ''
//                 }`}
//               >
//                 <input
//                   type="radio"
//                   name="selectedAddress"
//                   checked={
//                     selectedAddressType === 'additional' &&
//                     selectedAddressIndex === index
//                   }
//                   onChange={() => handleAddressSelection('additional', index)}
//                   onClick={() => handleRadioClick('additional', index)}
//                 />
//                 이번 배송 주소로 선택
//               </label>
//               <button
//                 onClick={() => handleSetAsDefault(index)}
//                 className="set-default-btn"
//               >
//                 기본 주소로 설정
//               </button>
//               <button
//                 type="button"
//                 onClick={() => handleOpenPost(index)}
//                 className="btn btn-primary"
//               >
//                 우편번호 찾기
//               </button>

//               {visibleDaumPost && activePostIdx === index && (
//                 <DaumPostCode
//                   onComplete={(data) => handleAddress(data, index)}
//                   autoClose
//                   width="595px"
//                   height="450px"
//                 />
//               )}
//               <div className="zipCode">우편번호: {addr.zoneCode}</div>
//               <div className="flex-container">
//                 <label>기본 주소: </label>
//                 <input
//                   className="flex-input"
//                   value={addr.address}
//                   onChange={(e) =>
//                     handleUpdateAddress(index, 'address', e.target.value)
//                   }
//                 />
//               </div>
//               <div className="flex-container">
//                 <label>상세 주소: </label>
//                 <input
//                   className="flex-input"
//                   value={addr.extraAddress}
//                   onChange={(e) =>
//                     handleUpdateAddress(index, 'extraAddress', e.target.value)
//                   }
//                 />
//               </div>
//             </div>
//           ))}
//       </div>
//       <button onClick={handleAddAdditionalAddress} className="btn btn-primary">
//         주소 추가
//       </button>
//       <button onClick={handleSubmit} className="btn btn-primary">
//         변경 사항 저장
//       </button>
//     </div>
//   );
// }

// export default UserAddress;

// import React, { useState, useEffect } from 'react';
// import {
//   TextField,
//   Button,
//   Radio,
//   RadioGroup,
//   FormControlLabel,
//   Paper,
//   Grid,
//   Typography,
//   Dialog,
//   Box,
// } from '@mui/material';
// import DaumPostcode from 'react-daum-postcode';

// function UserAddress({ user, onUpdate, onAddressSelect }) {
//   const initialState = {
//     zoneCode: user?.zoneCode || '',
//     address: user?.address || '',
//     extraAddress: user?.extraAddress || '',
//     additionalAddresses: user?.additionalAddresses || [],
//   };

//   const [state, setState] = useState(initialState);
//   const [selectedAddressType, setSelectedAddressType] = useState('default');
//   const [selectedAddressIndex, setSelectedAddressIndex] = useState(null);
//   const [activePostIdx, setActivePostIdx] = useState(null);
//   const [visibleDaumPost, setVisibleDaumPost] = useState(false);

//   useEffect(() => {
//     setState({
//       zoneCode: user?.zoneCode || '',
//       address: user?.address || '',
//       extraAddress: user?.extraAddress || '',
//       additionalAddresses: user?.additionalAddresses || [],
//     });

//     if (
//       user?.address &&
//       user?.extraAddress &&
//       user?.zoneCode &&
//       selectedAddressType !== 'additional'
//     ) {
//       onAddressSelect({
//         address: user.address,
//         extraAddress: user.extraAddress,
//         zoneCode: user.zoneCode,
//       });
//     }
//   }, [user]);

//   const handleAddressSelection = (type, index) => {
//     setSelectedAddressType(type);
//     setSelectedAddressIndex(index);
//     // const selectedAddress =
//     //   type === 'default'
//     //     ? {
//     //         address: state.address,
//     //         extraAddress: state.extraAddress,
//     //         zoneCode: state.zoneCode,
//     //       }
//     //     : state.additionalAddresses[index];
//     // onAddressSelect(selectedAddress);
//     if (type === 'default') {
//       onAddressSelect({
//         address: state.address,
//         extraAddress: state.extraAddress,
//         zoneCode: state.zoneCode,
//       });
//     } else {
//       const selectedAddr = state.additionalAddresses[index];
//       onAddressSelect({
//         address: selectedAddr.address,
//         extraAddress: selectedAddr.extraAddress,
//         zoneCode: selectedAddr.zoneCode,
//       });
//     }
//   };

//   const handleAddAdditionalAddress = () => {
//     const addresses = [
//       ...state.additionalAddresses,
//       { address: '', extraAddress: '', zoneCode: '' },
//     ];
//     setState((prevState) => ({
//       ...prevState,
//       additionalAddresses: addresses,
//     }));
//   };

//   const handleUpdateAddress = (index, key, value) => {
//     const updatedAddresses = [...state.additionalAddresses];
//     updatedAddresses[index][key] = value;
//     setState((prevState) => ({
//       ...prevState,
//       additionalAddresses: updatedAddresses,
//     }));
//   };

//   const handleSetAsDefault = (index) => {
//     const selectedAddress = state.additionalAddresses[index];
//     const newAdditionalAddresses = [...state.additionalAddresses];
//     newAdditionalAddresses.splice(index, 1);
//     newAdditionalAddresses.push({
//       address: state.address,
//       extraAddress: state.extraAddress,
//       zoneCode: state.zoneCode,
//     });

//     setState({
//       address: selectedAddress.address,
//       extraAddress: selectedAddress.extraAddress,
//       zoneCode: selectedAddress.zoneCode,
//       additionalAddresses: newAdditionalAddresses,
//     });

//     onUpdate({
//       ...user,
//       address: selectedAddress.address,
//       extraAddress: selectedAddress.extraAddress,
//       zoneCode: selectedAddress.zoneCode,
//       additionalAddresses: newAdditionalAddresses,
//     });
//   };

//   const handleOpenPost = (idx = null) => {
//     setActivePostIdx(idx);
//     setVisibleDaumPost(true);
//   };

//   const handleAddress = (data, idx = null) => {
//     const { address, zonecode } = data;
//     const fullAddress = `${address} (${data.bname || ''} ${
//       data.buildingName || ''
//     })`.trim();

//     if (idx === null) {
//       setState({
//         ...state,
//         address: fullAddress,
//         zoneCode: zonecode,
//       });
//     } else {
//       handleUpdateAddress(idx, 'address', fullAddress);
//       handleUpdateAddress(idx, 'zoneCode', zonecode);
//     }
//     setVisibleDaumPost(false);
//   };

//   return (
//     <Paper elevation={3} sx={{ p: 3, mt: 2, mb: 4 }}>
//       <Typography variant="h6" gutterBottom>
//         기본 배송지
//       </Typography>

//       <RadioGroup row sx={{ mb: 2 }}>
//         <FormControlLabel
//           control={
//             <Radio
//               checked={state.selectedAddressType === 'default'}
//               onChange={() => handleAddressSelection('default', null)}
//             />
//           }
//           label="이번 배송 주소로 선택"
//         />
//       </RadioGroup>
//       <Button
//         variant="outlined"
//         onClick={() => handleOpenPost(null)}
//         sx={{ mb: 2 }}
//       >
//         우편번호 찾기
//       </Button>
//       {visibleDaumPost && state.activePostIdx === null && (
//         <Dialog open={visibleDaumPost} onClose={() => setActivePostIdx(null)}>
//           <DaumPostcode
//             onComplete={(data) => handleAddress(data)}
//             width="100%"
//             height="450px"
//             autoClose
//           />
//         </Dialog>
//       )}
//       <TextField
//         fullWidth
//         label="우편번호"
//         value={state.zoneCode}
//         variant="outlined"
//         margin="normal"
//       />
//       <TextField
//         fullWidth
//         label="기본 주소"
//         value={state.address}
//         onChange={(e) => setState({ ...state, address: e.target.value })}
//         variant="outlined"
//         margin="normal"
//       />
//       <TextField
//         fullWidth
//         label="상세 주소"
//         value={state.extraAddress}
//         onChange={(e) => setState({ ...state, extraAddress: e.target.value })}
//         variant="outlined"
//         margin="normal"
//       />

//       {/* Additional delivery addresses section */}
//       <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
//         추가 배송지
//       </Typography>
//       {state.additionalAddresses.map((addr, index) => (
//         <Grid container spacing={2} key={index} sx={{ mt: 2 }}>
//           <Grid item xs={12}>
//             <RadioGroup row>
//               <FormControlLabel
//                 control={
//                   <Radio
//                     checked={
//                       selectedAddressType === 'additional' &&
//                       selectedAddressIndex === index
//                     }
//                     onChange={() => handleAddressSelection('additional', index)}
//                   />
//                 }
//                 label="이번 배송 주소로 선택"
//               />
//             </RadioGroup>
//           </Grid>

//           {visibleDaumPost && activePostIdx === index && (
//             <Dialog
//               open={visibleDaumPost}
//               onClose={() => setVisibleDaumPost(false)}
//             >
//               <DaumPostcode
//                 onComplete={(data) => handleAddress(data, index)}
//                 width="100%"
//                 height="450px"
//                 autoClose
//               />
//             </Dialog>
//           )}
//           <Grid item xs={12}>
//             <Box display="flex" gap={2}>
//               <Button
//                 variant="outlined"
//                 onClick={() => handleOpenPost(index)}
//                 sx={{ mr: 1 }}
//               >
//                 우편번호 찾기
//               </Button>

//               <Button
//                 variant="outlined"
//                 onClick={() => handleSetAsDefault(index)}
//               >
//                 기본주소로 변경
//               </Button>
//             </Box>
//           </Grid>
//           <Grid item xs={12}>
//             <TextField
//               fullWidth
//               label="우편번호"
//               value={addr.zoneCode}
//               variant="outlined"
//               margin="normal"
//             />
//           </Grid>
//           <Grid item xs={12}>
//             <TextField
//               fullWidth
//               label="기본 주소"
//               value={addr.address}
//               onChange={(e) =>
//                 handleUpdateAddress(index, 'address', e.target.value)
//               }
//               variant="outlined"
//               margin="normal"
//             />
//           </Grid>
//           <Grid item xs={12}>
//             <TextField
//               fullWidth
//               label="상세 주소"
//               value={addr.extraAddress}
//               onChange={(e) =>
//                 handleUpdateAddress(index, 'extraAddress', e.target.value)
//               }
//               variant="outlined"
//               margin="normal"
//             />
//           </Grid>
//         </Grid>
//       ))}
//       <Grid item xs={12}>
//         <Button
//           variant="contained"
//           onClick={handleAddAdditionalAddress}
//           sx={{ mr: 1 }}
//         >
//           주소 추가
//         </Button>
//         <Button
//           variant="contained"
//           color="primary"
//           onClick={() => onUpdate(state)}
//         >
//           변경 사항 저장
//         </Button>
//       </Grid>
//     </Paper>
//   );
// }

// export default UserAddress;

import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  Paper,
  Grid,
  Typography,
  Dialog,
  Box,
} from '@mui/material';
import DaumPostcode from 'react-daum-postcode';

function UserAddress({ user, onUpdate, onAddressSelect }) {
  const initialState = {
    zoneCode: user?.zoneCode || '',
    address: user?.address || '',
    extraAddress: user?.extraAddress || '',
    additionalAddresses: user?.additionalAddresses || [],
  };

  const [state, setState] = useState(initialState);
  const [selectedAddressType, setSelectedAddressType] = useState('default');
  const [selectedAddressIndex, setSelectedAddressIndex] = useState(null);
  const [activePostIdx, setActivePostIdx] = useState(null);
  const [visibleDaumPost, setVisibleDaumPost] = useState(false);

  useEffect(() => {
    setState({
      zoneCode: user?.zoneCode || '',
      address: user?.address || '',
      extraAddress: user?.extraAddress || '',
      additionalAddresses: user?.additionalAddresses || [],
    });

    if (
      user?.address &&
      user?.extraAddress &&
      user?.zoneCode &&
      selectedAddressType === 'default'
    ) {
      onAddressSelect({
        address: user.address,
        extraAddress: user.extraAddress,
        zoneCode: user.zoneCode,
        additionalAddresses: user?.additionalAddresses,
      });
    }
  }, [user]);

  const handleAddressSelection = (type, index) => {
    setSelectedAddressType(type);
    setSelectedAddressIndex(index);
    if (type === 'default') {
      onAddressSelect({
        address: state.address,
        extraAddress: state.extraAddress,
        zoneCode: state.zoneCode,
      });
    } else {
      const selectedAddr = state.additionalAddresses[index];
      onAddressSelect({
        address: selectedAddr.address,
        extraAddress: selectedAddr.extraAddress,
        zoneCode: selectedAddr.zoneCode,
      });
    }
  };

  const handleAddAdditionalAddress = () => {
    const addresses = [
      ...state.additionalAddresses,
      { address: '', extraAddress: '', zoneCode: '' },
    ];
    setState((prevState) => ({
      ...prevState,
      additionalAddresses: addresses,
    }));
  };
  // const handleAddAdditionalAddress = () => {
  //   const newAddress = { address: '', extraAddress: '', zoneCode: '' };
  //   setState((prevState) => ({
  //     ...prevState,
  //     additionalAddresses: [...prevState.additionalAddresses, newAddress],
  //   }));
  // };

  const handleUpdateAddress = (index, key, value) => {
    const updatedAddresses = [...state.additionalAddresses];
    updatedAddresses[index][key] = value;
    setState((prevState) => ({
      ...prevState,
      additionalAddresses: updatedAddresses,
    }));
  };

  const handleRemoveAddress = (index) => {
    setState((prevState) => {
      const newAddresses = [...prevState.additionalAddresses];
      newAddresses.splice(index, 1);
      return { ...prevState, additionalAddresses: newAddresses };
    });
  };

  const handleSetAsDefault = (index) => {
    const selectedAddress = state.additionalAddresses[index];
    const newAdditionalAddresses = [...state.additionalAddresses];
    newAdditionalAddresses.splice(index, 1);
    newAdditionalAddresses.push({
      address: state.address,
      extraAddress: state.extraAddress,
      zoneCode: state.zoneCode,
    });

    setState({
      address: selectedAddress.address,
      extraAddress: selectedAddress.extraAddress,
      zoneCode: selectedAddress.zoneCode,
      additionalAddresses: newAdditionalAddresses,
    });

    onUpdate({
      ...user,
      address: selectedAddress.address,
      extraAddress: selectedAddress.extraAddress,
      zoneCode: selectedAddress.zoneCode,
      additionalAddresses: newAdditionalAddresses,
    });
  };

  const handleOpenPost = (idx) => {
    setSelectedAddressIndex(idx);
    setVisibleDaumPost(true);
  };

  const handleAddress = (data, idx) => {
    const { address, zonecode } = data;
    const fullAddress = `${address} (${data.bname || ''} ${
      data.buildingName || ''
    })`.trim();

    if (idx === null) {
      setState({
        ...state,
        address: fullAddress,
        zoneCode: zonecode,
      });
    } else {
      handleUpdateAddress(idx, 'address', fullAddress);
      handleUpdateAddress(idx, 'zoneCode', zonecode);
    }
    setVisibleDaumPost(false);
  };

  return (
    <Paper elevation={3} sx={{ p: 3, mt: 2, mb: 4 }}>
      <Typography variant="h6" gutterBottom>
        기본 배송지
      </Typography>

      <RadioGroup
        row
        value={selectedAddressType}
        onChange={(event) => handleAddressSelection(event.target.value, null)}
        sx={{ mb: 2 }}
      >
        <FormControlLabel
          value="default"
          control={<Radio />}
          label="이번 배송 주소로 선택"
        />
      </RadioGroup>
      <Button
        variant="outlined"
        onClick={() => handleOpenPost(null)}
        sx={{ mb: 2 }}
      >
        우편번호 찾기
      </Button>
      {visibleDaumPost && (
        <Dialog
          open={visibleDaumPost}
          onClose={() => setVisibleDaumPost(false)}
        >
          <DaumPostcode
            onComplete={(data) => handleAddress(data, selectedAddressIndex)}
            width="100%"
            height="450px"
            autoClose
          />
        </Dialog>
      )}
      <TextField
        fullWidth
        label="우편번호"
        value={state.zoneCode}
        variant="outlined"
        margin="normal"
        disabled
      />
      <TextField
        fullWidth
        label="기본 주소"
        value={state.address}
        onChange={(e) => setState({ ...state, address: e.target.value })}
        variant="outlined"
        margin="normal"
      />
      <TextField
        fullWidth
        label="상세 주소"
        value={state.extraAddress}
        onChange={(e) => setState({ ...state, extraAddress: e.target.value })}
        variant="outlined"
        margin="normal"
      />

      {/* Additional delivery addresses section */}
      <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
        추가 배송지
      </Typography>
      {state.additionalAddresses.map((addr, index) => (
        <Grid container spacing={2} key={index} sx={{ mt: 2 }}>
          <Grid item xs={12}>
            <RadioGroup
              row
              value={
                selectedAddressType === 'additional' &&
                selectedAddressIndex === index
                  ? 'additional'
                  : 'default'
              }
            >
              <FormControlLabel
                value="additional"
                control={<Radio />}
                label="이번 배송 주소로 선택"
                onChange={() => handleAddressSelection('additional', index)}
              />
            </RadioGroup>
          </Grid>
          {visibleDaumPost && index === selectedAddressIndex && (
            <Dialog
              open={visibleDaumPost}
              onClose={() => setVisibleDaumPost(false)}
            >
              <DaumPostcode
                onComplete={(data) => handleAddress(data, index)}
                width="100%"
                height="450px"
                autoClose
              />
            </Dialog>
          )}
          <Grid item xs={12} display="flex" gap={2}>
            <Button
              variant="outlined"
              onClick={() => handleOpenPost(index)}
              sx={{ mr: 1 }}
            >
              우편번호 찾기
            </Button>
            <Button
              variant="outlined"
              onClick={() => handleSetAsDefault(index)}
            >
              기본주소로 변경
            </Button>
            <Button
              variant="outlined"
              color="error"
              onClick={() => handleRemoveAddress(index)}
            >
              삭제
            </Button>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="우편번호"
              value={addr.zoneCode}
              variant="outlined"
              margin="normal"
              disabled
            />
            <TextField
              fullWidth
              label="기본 주소"
              value={addr.address}
              onChange={(e) =>
                handleUpdateAddress(index, 'address', e.target.value)
              }
              variant="outlined"
              margin="normal"
            />
            <TextField
              fullWidth
              label="상세 주소"
              value={addr.extraAddress}
              onChange={(e) =>
                handleUpdateAddress(index, 'extraAddress', e.target.value)
              }
              variant="outlined"
              margin="normal"
            />
          </Grid>
        </Grid>
      ))}
      <Button
        variant="contained"
        onClick={handleAddAdditionalAddress}
        sx={{ mt: 2, mr: 2 }}
      >
        주소 추가
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={() => onUpdate(state)}
        sx={{ mt: 2 }}
      >
        변경 사항 저장
      </Button>
    </Paper>
  );
}

export default UserAddress;

//TESTtestTESTtestTESTtestTEST
//TESTtestTESTtestTESTtestTEST
//TESTtestTESTtestTESTtestTEST
//TESTtestTESTtestTESTtestTEST
//TESTtestTESTtestTESTtestTEST

// import React, { useState, useEffect } from 'react';
// import {
//   TextField,
//   Button,
//   Radio,
//   RadioGroup,
//   FormControlLabel,
//   Paper,
//   Grid,
//   Typography,
//   Dialog,
//   Box,
// } from '@mui/material';
// import DaumPostcode from 'react-daum-postcode';

// function UserAddress({ user, onUpdate, onAddressSelect }) {
//   const initialState = {
//     zoneCode: user?.zoneCode || '',
//     address: user?.address || '',
//     extraAddress: user?.extraAddress || '',
//     additionalAddresses: user?.additionalAddresses || [],
//   };

//   const [state, setState] = useState(initialState);
//   const [selectedAddressType, setSelectedAddressType] = useState('default');
//   const [selectedAddressIndex, setSelectedAddressIndex] = useState(null);
//   const [activePostIdx, setActivePostIdx] = useState(null);
//   const [visibleDaumPost, setVisibleDaumPost] = useState(false);

//   useEffect(() => {
//     console.log('리렌더링 확인', state.additionalAddresses);
//   }, [state.additionalAddresses]);

//   useEffect(() => {
//     setState({
//       zoneCode: user?.zoneCode || '',
//       address: user?.address || '',
//       extraAddress: user?.extraAddress || '',
//       additionalAddresses: user?.additionalAddresses || [],
//     });

//     if (
//       user?.address &&
//       user?.extraAddress &&
//       user?.zoneCode &&
//       selectedAddressType === 'default'
//     ) {
//       onAddressSelect({
//         address: user.address,
//         extraAddress: user.extraAddress,
//         zoneCode: user.zoneCode,
//         additionalAddresses: user.additionalAddresses,
//       });
//     }
//   }, [user]);

//   const handleAddressSelection = (type, index) => {
//     setSelectedAddressType(type);
//     setSelectedAddressIndex(index);
//     if (type === 'default') {
//       onAddressSelect({
//         address: state.address,
//         extraAddress: state.extraAddress,
//         zoneCode: state.zoneCode,
//       });
//     } else {
//       const selectedAddr = state.additionalAddresses[index];
//       onAddressSelect({
//         address: selectedAddr.address,
//         extraAddress: selectedAddr.extraAddress,
//         zoneCode: selectedAddr.zoneCode,
//       });
//     }
//   };

//   const handleAddAdditionalAddress = () => {
//     const addresses = [
//       ...state.additionalAddresses,
//       { address: '', extraAddress: '', zoneCode: '' },
//     ];
//     setState((prevState) => ({
//       ...prevState,
//       additionalAddresses: addresses,
//     }));
//   };

//   const handleUpdateAddress = (index, key, value) => {
//     const updatedAddresses = [...state.additionalAddresses];
//     updatedAddresses[index][key] = value;
//     setState((prevState) => ({
//       ...prevState,
//       additionalAddresses: updatedAddresses,
//     }));
//   };

// const handleRemoveAddress = (index) => {
//   setState((prevState) => {
//     const newAddresses = [...prevState.additionalAddresses];
//     newAddresses.splice(index, 1);
//     return { ...prevState, additionalAddresses: newAddresses };
//   });
// };

//   const handleSetAsDefault = (index) => {
//     const selectedAddress = state.additionalAddresses[index];
//     const newAdditionalAddresses = [...state.additionalAddresses];
//     newAdditionalAddresses.splice(index, 1);
//     newAdditionalAddresses.push({
//       address: state.address,
//       extraAddress: state.extraAddress,
//       zoneCode: state.zoneCode,
//     });

//     setState({
//       address: selectedAddress.address,
//       extraAddress: selectedAddress.extraAddress,
//       zoneCode: selectedAddress.zoneCode,
//       additionalAddresses: newAdditionalAddresses,
//     });

//     onUpdate({
//       ...user,
//       address: selectedAddress.address,
//       extraAddress: selectedAddress.extraAddress,
//       zoneCode: selectedAddress.zoneCode,
//       additionalAddresses: newAdditionalAddresses,
//     });
//   };

//   const handleOpenPost = (idx = null) => {
//     setActivePostIdx(idx);
//     setVisibleDaumPost(true);
//   };

//   const handleClosePost = () => {
//     setVisibleDaumPost(false);
//     setActivePostIdx(null);
//   };

//   const handleAddress = (data, idx = null) => {
//     const { address, zonecode } = data;
//     const fullAddress = `${address} (${data.bname || ''} ${
//       data.buildingName || ''
//     })`.trim();

//     if (idx === null) {
//       setState({
//         ...state,
//         address: fullAddress,
//         zoneCode: zonecode,
//       });
//     } else {
//       handleUpdateAddress(idx, 'address', fullAddress);
//       handleUpdateAddress(idx, 'zoneCode', zonecode);
//     }
//     setVisibleDaumPost(false);
//   };
//   return (
//     <Paper elevation={3} sx={{ p: 3, mt: 2, mb: 4 }}>
//       <Typography variant="h6" gutterBottom>
//         기본 배송지
//       </Typography>
//       <RadioGroup
//         row
//         value={selectedAddressType}
//         onChange={(e) => handleAddressSelection(e.target.value, null)}
//         sx={{ mb: 2 }}
//       >
//         <FormControlLabel
//           value="default"
//           control={<Radio />}
//           label="이번 배송 주소로 선택"
//         />
//       </RadioGroup>
//       <Button variant="outlined" onClick={handleOpenPost} sx={{ mb: 2 }}>
//         우편번호 찾기
//       </Button>

//       {visibleDaumPost && state.activePostIdx === null && (
//         <Dialog open={visibleDaumPost} onClose={handleClosePost}>
//           <DaumPostcode
//             onComplete={handleAddress}
//             width="100%"
//             height="450px"
//             autoClose
//           />
//         </Dialog>
//       )}

//       <TextField
//         fullWidth
//         label="우편번호"
//         value={state.zoneCode}
//         variant="outlined"
//         margin="normal"
//         disabled
//       />
//       <TextField
//         fullWidth
//         label="기본 주소"
//         value={state.address}
//         onChange={(e) => setState({ ...state, address: e.target.value })}
//         variant="outlined"
//         margin="normal"
//       />
//       <TextField
//         fullWidth
//         label="상세 주소"
//         value={state.extraAddress}
//         onChange={(e) => setState({ ...state, extraAddress: e.target.value })}
//         variant="outlined"
//         margin="normal"
//       />
//       <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
//         추가 배송지
//       </Typography>

//       {state.additionalAddresses.map((addr, index) => (
//         <Grid container spacing={2} key={index} sx={{ mt: 2 }}>
//           <Grid item xs={12}>
//             <RadioGroup
//               row
//               value={
//                 selectedAddressType === 'additional' &&
//                 selectedAddressIndex === index
//                   ? 'additional'
//                   : 'default'
//               }
//             >
//               <FormControlLabel
//                 value="additional"
//                 control={<Radio />}
//                 label="이번 배송 주소로 선택"
//                 onChange={() => handleAddressSelection('additional', index)}
//               />
//             </RadioGroup>
//           </Grid>
//           {visibleDaumPost && activePostIdx === index && (
//             <Dialog
//               open={visibleDaumPost}
//               onClose={() => setVisibleDaumPost(false)}
//             >
//               <DaumPostcode
//                 onComplete={(data) => handleAddress(data, index)}
//                 width="100%"
//                 height="450px"
//                 autoClose
//               />
//             </Dialog>
//           )}
//           <Grid item xs={12} display="flex" gap={2}>
//             <Button
//               variant="outlined"
//               onClick={() => handleOpenPost(index)}
//               sx={{ mr: 1 }}
//             >
//               우편번호 찾기
//             </Button>
//             <Button
//               variant="outlined"
//               onClick={() => handleSetAsDefault(index)}
//             >
//               기본주소로 변경
//             </Button>
// <Button
//   variant="outlined"
//   color="error"
//   onClick={() => handleRemoveAddress(index)}
// >
//   삭제
// </Button>
//           </Grid>
//           <Grid item xs={12}>
//             <TextField
//               fullWidth
//               label="우편번호"
//               value={addr.zoneCode}
//               variant="outlined"
//               margin="normal"
//               disabled
//             />
//             <TextField
//               fullWidth
//               label="기본 주소"
//               value={addr.address}
//               onChange={(e) =>
//                 handleUpdateAddress(index, 'address', e.target.value)
//               }
//               variant="outlined"
//               margin="normal"
//             />
//             <TextField
//               fullWidth
//               label="상세 주소"
//               value={addr.extraAddress}
//               onChange={(e) =>
//                 handleUpdateAddress(index, 'extraAddress', e.target.value)
//               }
//               variant="outlined"
//               margin="normal"
//             />
//           </Grid>
//         </Grid>
//       ))}
//       <Button
//         variant="contained"
//         onClick={handleAddAdditionalAddress}
//         sx={{ mt: 2, mr: 2 }}
//       >
//         주소 추가
//       </Button>
//       <Button
//         variant="contained"
//         color="primary"
//         onClick={() => onUpdate(state)}
//         sx={{ mt: 2 }}
//       >
//         변경 사항 저장
//       </Button>
//     </Paper>
//   );
// }

// export default UserAddress;
