// import React, { useState } from 'react';
// import './css/appointments.css';
// import useMessageBox from './Common/useMessageBox';

// const ScheduleAppointment = ({ onAppointmentSet, onClose }) => {
//     const [MessageBoxInform, MessageBoxConfirm, MessageBoxRender] = useMessageBox();
//     const [date, setDate] = useState('');
//     const [time, setTime] = useState('');

//     const handleDateChange = (e) => {
//         setDate(e.target.value);
//     };

//     const handleTimeChange = (e) => {
//         setTime(e.target.value);
//     };

//     const handleAppointmentSet = async (e) => {
//         e.preventDefault();
//         const selectedDateTime = new Date(`${date}T${time}`);
//         if (selectedDateTime < new Date()) {
//             MessageBoxInform("유효하지 않은 날짜 및 시간입니다.");
//             return;
//         }

//         try {

//             onAppointmentSet({ date, time });
//             MessageBoxInform("예약이 성공적으로 저장되었습니다.");

//         } catch (error) {
//             console.error("Error saving appointment: ", error);
//             MessageBoxInform("예약 저장 중 오류가 발생했습니다.");
//         }
//     };

//     return (
//         <div className='ScheduleAppointment'>
//             {MessageBoxRender()}
//             <div className="modal-overlay">
//                 <button className="close-button" onClick={onClose}>X</button>
//                 <div className="modal-content">
//                     <form onSubmit={handleAppointmentSet}>
//                         <div>
//                             <label htmlFor="date">날짜</label>
//                             <input type="date" id="date" value={date} onChange={handleDateChange} required />
//                         </div>
//                         <div>
//                             <label htmlFor="time">시간</label>
//                             <input type="time" id="time" value={time} onChange={handleTimeChange} required />
//                         </div>
//                         <button type="submit">예약</button>
//                     </form>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default ScheduleAppointment;

import React, { useState } from 'react';
import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useMessageBox from './Common/useMessageBox';

const ScheduleAppointment = ({ onAppointmentSet, onClose }) => {
  const [MessageBoxInform, , MessageBoxRender] = useMessageBox();
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');

  const handleDateChange = (e) => {
    setDate(e.target.value);
  };

  const handleTimeChange = (e) => {
    setTime(e.target.value);
  };

  const handleAppointmentSet = async (e) => {
    e.preventDefault();
    const selectedDateTime = new Date(`${date}T${time}`);
    if (selectedDateTime < new Date()) {
      MessageBoxInform('유효하지 않은 날짜 및 시간입니다.');
      return;
    }

    try {
      onAppointmentSet({ date, time });
      MessageBoxInform('예약이 성공적으로 저장되었습니다.');
      onClose(); // Optionally close the modal on success
    } catch (error) {
      console.error('Error saving appointment: ', error);
      MessageBoxInform('예약 저장 중 오류가 발생했습니다.');
    }
  };

  return (
    <Dialog open={true} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        예약 설정
      </DialogTitle>
      <DialogContent>
        {MessageBoxRender()}
        <form onSubmit={handleAppointmentSet}>
          <TextField
            margin="dense"
            id="date"
            label="날짜"
            type="date"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            value={date}
            onChange={handleDateChange}
            required
          />
          <TextField
            margin="dense"
            id="time"
            label="시간"
            type="time"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            value={time}
            onChange={handleTimeChange}
            required
          />
          <Button
            type="submit"
            color="primary"
            fullWidth
            variant="contained"
            sx={{ mt: 2 }}
          >
            예약
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ScheduleAppointment;
