// import React from 'react';
// import {
//   Card,
//   CardContent,
//   CardHeader,
//   Typography,
//   Button,
//   Grid,
//   Paper,
//   List,
//   ListItem,
//   ListItemText,
//   Avatar,
// } from '@mui/material';
// import TrackShipment from './TrackShipment'; // 배송 추적 컴포넌트를 포함시킴

// function OrderDetails({ order, onBack }) {
//   return (
//     <>
//       <Paper
//         className="container mt-5"
//         elevation={3}
//         sx={{ p: 2, maxWidth: '100%', margin: 'auto' }}
//       >
//         <TrackShipment trackingNumber={order.invoiceNumber} />
//         <Card>
//           <CardHeader title="주문 상세" />
//           <CardContent>
//             <Typography variant="h6" gutterBottom>
//               주문자 정보
//             </Typography>
//             <List>
//               <ListItem>
//                 <ListItemText primary={`이름: ${order.userId}`} />
//               </ListItem>
//               <ListItem>
//                 <ListItemText
//                   primary={`연락처: ${order.shippingDetails?.phoneNumber}`}
//                 />
//               </ListItem>
//             </List>

//             <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
//               배송 정보
//             </Typography>
//             <List>
//               <ListItem>
//                 <ListItemText
//                   primary={`배송 주소: ${order.shippingDetails?.address}`}
//                 />
//               </ListItem>
//             </List>

//             <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
//               주문 상품 정보
//             </Typography>
//             <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
//               주문 스토어 이름: {order.storeName}
//             </Typography>

//             {order.items.map((item, index) => (
//               <Grid container spacing={2} key={index} sx={{ mb: 2 }}>
//                 <Grid item>
//                   <Avatar
//                     variant="square"
//                     src={item.productDetails?.imageUrl}
//                     alt={item.productDetails?.name}
//                     sx={{ width: 100, height: 100 }}
//                   />
//                 </Grid>
//                 <Grid item xs>
//                   <Typography>상품명: {item.productDetails?.name}</Typography>
//                   <Typography>
//                     단품가격: {item.price?.toLocaleString()}원
//                   </Typography>
//                   <Typography>수량: {item.quantity}</Typography>
//                   <Typography>
//                     원래가격: {item.totalPrice?.toLocaleString()}원
//                   </Typography>
//                   <Typography>
//                     할인가격: {item.finalPrice?.toLocaleString()}원
//                   </Typography>
//                 </Grid>
//               </Grid>
//             ))}

//             <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
//               결재 정보
//             </Typography>
//             <List>
//               <ListItem>
//                 <ListItemText
//                   primary={`총 주문 금액: ${order.totalAmount?.toLocaleString()}원`}
//                 />
//               </ListItem>
//               <ListItem>
//                 <ListItemText
//                   primary={`할인: ${order.discountedAmount?.toLocaleString()}원`}
//                 />
//               </ListItem>
//               <ListItem>
//                 <ListItemText
//                   primary={`쿠폰: ${order.couponAmount?.toLocaleString()}원`}
//                 />
//               </ListItem>
//               <ListItem>
//                 <ListItemText
//                   primary={`사용 포인트: ${order.usedPoints?.toLocaleString()}점`}
//                 />
//               </ListItem>
//             </List>

//             <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
//               주문 상태 및 이력
//             </Typography>
//             <List>
//               <ListItem>
//                 <ListItemText primary={`주문 상태: ${order.orderStatus}`} />
//               </ListItem>
//               <ListItem>
//                 <ListItemText primary={`주문일시: ${order.dateTime}`} />
//               </ListItem>
//             </List>
//           </CardContent>
//         </Card>
//         <Button variant="outlined" onClick={onBack} sx={{ mt: 2 }}>
//           뒤로가기
//         </Button>
//       </Paper>
//     </>
//   );
// }

// export default OrderDetails;

import React from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Button,
  Grid,
  Paper,
  List,
  ListItem,
  ListItemText,
  Avatar,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import TrackShipment from './StoreAdmin/TrackShipment'; // 배송 추적 컴포넌트를 포함시킴
import { useTranslation } from 'react-i18next';

function OrderDetails({ order, onBack }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Paper
        className="container mt-5"
        elevation={3}
        sx={{
          p: 2,
          maxWidth: isMobile ? '100%' : '80%',
          margin: 'auto',
        }}
      >
        <TrackShipment trackingNumber={order.invoiceNumber} />
        <Card>
          <CardHeader title={t('orderDetails.title')} />
          <CardContent>
            <Typography variant="h6" gutterBottom>
              {t('orderDetails.buyerInfo')}
            </Typography>
            <List>
              <ListItem>
                <ListItemText
                  primary={`${t('orderDetails.name')}: ${order.userId}`}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={`${t('orderDetails.contact')}: ${
                    order.shippingDetails?.phoneNumber
                  }`}
                />
              </ListItem>
            </List>

            <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
              {t('orderDetails.shippingInfo')}
            </Typography>
            <List>
              <ListItem>
                <ListItemText
                  primary={`${t('orderDetails.address')}: ${
                    order.shippingDetails?.address
                  }`}
                />
              </ListItem>
            </List>

            <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
              {t('orderDetails.productInfo')}
            </Typography>
            <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
              {t('orderDetails.storeName')}: {order.storeName}
            </Typography>

            {order.items.map((item, index) => (
              <Grid container spacing={2} key={index} sx={{ mb: 2 }}>
                <Grid item>
                  <Avatar
                    variant="square"
                    src={item.productDetails?.imageUrl}
                    alt={item.productDetails?.name}
                    sx={{
                      width: isMobile ? 60 : 100,
                      height: isMobile ? 60 : 100,
                    }}
                  />
                </Grid>
                <Grid item xs>
                  <Typography>{`${t('orderDetails.productName')}: ${
                    item.productDetails?.name
                  }`}</Typography>
                  <Typography>{`${t(
                    'orderDetails.unitPrice'
                  )}: ${item.price?.toLocaleString()}${t(
                    'orderDetails.currency'
                  )}`}</Typography>
                  <Typography>{`${t('orderDetails.quantity')}: ${
                    item.quantity
                  }`}</Typography>
                  <Typography>{`${t(
                    'orderDetails.originalPrice'
                  )}: ${item.totalPrice?.toLocaleString()}${t(
                    'orderDetails.currency'
                  )}`}</Typography>
                  <Typography>{`${t(
                    'orderDetails.discountedPrice'
                  )}: ${item.finalPrice?.toLocaleString()}${t(
                    'orderDetails.currency'
                  )}`}</Typography>
                </Grid>
              </Grid>
            ))}

            <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
              {t('orderDetails.paymentInfo')}
            </Typography>
            <List>
              <ListItem>
                <ListItemText
                  primary={`${t(
                    'orderDetails.totalAmount'
                  )}: ${order.totalAmount?.toLocaleString()}${t(
                    'orderDetails.currency'
                  )}`}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={`${t(
                    'orderDetails.discount'
                  )}: ${order.discountedAmount?.toLocaleString()}${t(
                    'orderDetails.currency'
                  )}`}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={`${t(
                    'orderDetails.coupon'
                  )}: ${order.couponAmount?.toLocaleString()}${t(
                    'orderDetails.currency'
                  )}`}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={`${t(
                    'orderDetails.usedPoints'
                  )}: ${order.usedPoints?.toLocaleString()}${t(
                    'orderDetails.points'
                  )}`}
                />
              </ListItem>
            </List>

            <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
              {t('orderDetails.orderStatusHistory')}
            </Typography>
            <List>
              <ListItem>
                <ListItemText
                  primary={`${t('orderDetails.orderStatus')}: ${
                    order.orderStatus
                  }`}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={`${t('orderDetails.orderDateTime')}: ${
                    order.dateTime
                  }`}
                />
              </ListItem>
            </List>
          </CardContent>
        </Card>
        <Button variant="outlined" onClick={onBack} sx={{ mt: 2 }}>
          {t('orderDetails.back')}
        </Button>
      </Paper>
    </>
  );
}

export default OrderDetails;
