// // StoreDeliveryArea.jsx
// import { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import { db, doc, getDoc, updateDoc, setDoc } from '../../firebaseConfig';
// import Region from '../../Common/Region';
// import '../css/SignUpFlow.css';

// const StoreDeliveryArea = ({ onPrev, onNext, setIsStepCompleted }) => {
//     const currentUser = useSelector((state) => state.auth.currentUser);
//     const [deliveryArea, setDeliveryArea] = useState([]);
//     const [loading, setLoading] = useState(false);
//     const [isCompleted, setIsCompleted] = useState(false);
//     const [message, setMessage] = useState('지도를 클릭해서 영역을 지정해서 완료 버튼을 클릭해주세요.');
//     const [error, setError] = useState(null);

//     const updateUserDocument = async (updates) => {
//         try {
//             const userRef = doc(db, 'users', currentUser.uid);
//             const userDoc = await getDoc(userRef);

//             if (userDoc.exists()) {
//                 await updateDoc(userRef, updates);
//             } else {
//                 console.error("User document doesn't exist!");
//             }
//         } catch (error) {
//             console.error("Error updating user document:", error);
//             setError("An error occurred. Please try again.");
//         }
//     };
//     const handleSetDeliveryArea = (deliveryArea) => {
//         setDeliveryArea(deliveryArea);
//         setIsCompleted(false);
//         setMessage('영역이 지정되었습니다. 완료를 클릭하여 진행해 주세요.');
//     }
//     const handleSaveDeliveryArea = async () => {
//         setIsCompleted(false);
//         try {
//             await updateUserDocument({
//                 "pendingRegistrations.deliveryArea": deliveryArea,
//                 "pendingRegistrations.modifiedAt": new Date()
//             });
//             recordStepAndMoveNext();
//         } finally {
//             setMessage('설정되었습니다. 다음을 클릭하여 계속 진행해주세요.');
//             setIsCompleted(true);
//         }
//     };

//     const recordStepAndMoveNext = async () => {
//         await updateUserDocument({ "pendingRegistrations.step": process.env.REACT_APP_STORE_DELIVERYAREA_VERIFIED });
//         setIsStepCompleted(true);

//         //onNext();
//     };

//     return (
//         <div >
//             <div className="form-section">
//                 <label className="title-label" htmlFor="title">위치 지정
//                     {loading && <p>Loading...</p>}
//                     {error && <p>{error}</p>}</label>

//                 <div className="region-container">
//                     <label className="content-label" htmlFor="title">{message}</label>
//                     <Region onSave={handleSetDeliveryArea} mode='region' />
//                     <div >
//                         <button className="fixed-button" onClick={handleSaveDeliveryArea}>완료</button>
//                     </div>
//                 </div>
//             </div>

//         </div>
//     );
// };

// export default StoreDeliveryArea;

// import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import { db, doc, getDoc, updateDoc } from '../../firebaseConfig';
// import Region from '../../Common/Region';
// import {
//   Container,
//   Typography,
//   Button,
//   CircularProgress,
//   Alert,
//   Box,
// } from '@mui/material';

// function StoreDeliveryArea({ onPrev, onNext, setIsStepCompleted }) {
//   const currentUser = useSelector((state) => state.auth.currentUser);
//   const [deliveryArea, setDeliveryArea] = useState([]);
//   const [isCompleted, setIsCompleted] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState('');

//   useEffect(() => {
//     // Placeholder for fetching data or initialization logic if needed
//   }, []);

//   const handleSetDeliveryArea = (area) => {
//     setDeliveryArea(area);
//     setIsCompleted(true);
//   };

//   const handleSaveDeliveryArea = async () => {
//     try {
//       setLoading(true);
//       const userRef = doc(db, 'users', currentUser.uid);
//       await updateDoc(userRef, {
//         'pendingRegistrations.deliveryArea': deliveryArea,
//         'pendingRegistrations.step':
//           process.env.REACT_APP_STORE_DELIVERYAREA_VERIFIED,
//         'pendingRegistrations.modifiedAt': new Date(),
//       });
//       setIsStepCompleted(true);
//       onNext();
//     } catch (error) {
//       console.error('Error updating user document:', error);
//       setError('An error occurred. Please try again.');
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <Box
//       sx={{
//         width: '100%',
//         margin: 'auto',
//         marginBottom: '80px',
//       }}
//     >
//       <Container maxWidth="md">
//         <Typography variant="h5" gutterBottom>
//           Define Delivery Area
//         </Typography>
//         {!isCompleted && (
//           <Typography sx={{ mt: 0 }}>Select a region on the map.</Typography>
//         )}
//         {loading && <CircularProgress size={24} sx={{ mb: 1 }} />}
//         {error && <Alert severity="error">{error}</Alert>}
//         <Box sx={{ mt: 0, mb: 0 }}>
//           <Region onSave={handleSetDeliveryArea} mode="region" />
//         </Box>
//         {isCompleted && (
//           <Button
//             variant="contained"
//             color="primary"
//             onClick={handleSaveDeliveryArea}
//           >
//             Save Delivery Area
//           </Button>
//         )}
//       </Container>
//     </Box>
//   );
// }

// export default StoreDeliveryArea;

// import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import { db, doc, getDoc, updateDoc } from '../../firebaseConfig';
// import GoogleMapRegion from '../../Common/GoogleMapRegion';
// import {
//   Container,
//   Typography,
//   Button,
//   CircularProgress,
//   Alert,
//   Box,
// } from '@mui/material';
// import { useTranslation } from 'react-i18next';
// import GoogleMapMarker from '../../Common/GoogleMapMarker';

// function StoreDeliveryArea({ onPrev, onNext, setIsStepCompleted }) {
//   const { t, i18n } = useTranslation();
//   const currentUser = useSelector((state) => state.auth.currentUser);
//   const [deliveryArea, setDeliveryArea] = useState([]);
//   const [isCompleted, setIsCompleted] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState('');

//   useEffect(() => {
//     // Placeholder for fetching data or initialization logic if needed
//   }, []);

//   const handleSetDeliveryArea = (area) => {
//     setDeliveryArea(area);
//     setIsCompleted(true);
//   };

//   const handleSaveDeliveryArea = async () => {
//     try {
//       setLoading(true);
//       const userRef = doc(db, 'users', currentUser.uid);
//       await updateDoc(userRef, {
//         'pendingRegistrations.deliveryArea': deliveryArea,
//         'pendingRegistrations.step':
//           process.env.REACT_APP_STORE_DELIVERYAREA_VERIFIED,
//         'pendingRegistrations.modifiedAt': new Date(),
//       });
//       setIsStepCompleted(true);
//       onNext();
//     } catch (error) {
//       console.error(t('errorUpdatingUserDocument'), error);
//       setError(t('errorOccurred'));
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <Box
//       sx={{
//         width: '100%',
//         margin: 'auto',
//         marginBottom: '80px',
//       }}
//     >
//       <Container maxWidth="md">
//         <Typography variant="h5" gutterBottom>
//           {t('defineDeliveryArea')}
//         </Typography>
//         {!isCompleted && (
//           <Typography sx={{ mt: 0 }}>{t('selectRegionOnMap')}</Typography>
//         )}
//         {loading && <CircularProgress size={24} sx={{ mb: 1 }} />}
//         {error && <Alert severity="error">{error}</Alert>}
//         <Box sx={{ mt: 0, mb: 0 }}>
//           <GoogleMapRegion onSave={handleSetDeliveryArea} />
//         </Box>
//         {isCompleted && (
//           <Button
//             variant="contained"
//             color="primary"
//             onClick={handleSaveDeliveryArea}
//           >
//             {t('saveDeliveryArea')}
//           </Button>
//         )}
//       </Container>
//     </Box>
//   );
// }

// export default StoreDeliveryArea;

// import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import { db, doc, getDoc, updateDoc } from '../../firebaseConfig';
// import GoogleMapRegion from '../../Common/GoogleMapRegion';
// import {
//   Container,
//   Typography,
//   Button,
//   CircularProgress,
//   Alert,
//   Box,
// } from '@mui/material';
// import { useTranslation } from 'react-i18next';

// function StoreDeliveryArea({ onPrev, onNext, setIsStepCompleted }) {
//   const { t, i18n } = useTranslation();
//   const currentUser = useSelector((state) => state.auth.currentUser);
//   const [deliveryArea, setDeliveryArea] = useState([]);
//   const [isCompleted, setIsCompleted] = useState(false);
//   const [loading, setLoading] = useState(true); // 초기 로딩 상태를 true로 설정
//   const [error, setError] = useState('');

//   useEffect(() => {
//     const fetchDeliveryArea = async () => {
//       setLoading(true); // Set loading state at the beginning
//       try {
//         const userRef = doc(db, 'users', currentUser.uid);
//         const userDoc = await getDoc(userRef);

//         if (userDoc.exists()) {
//           const userData = userDoc.data();
//           const areaData = userData.deliveryArea || [];

//           setDeliveryArea(areaData);
//           setIsCompleted(areaData.length > 0); // Set completed state based on area data
//         } else {
//           console.error('User document does not exist');
//         }
//       } catch (error) {
//         console.error(t('errorFetchingUserDocument'), error);
//         setError(t('errorOccurred'));
//       } finally {
//         setLoading(false); // Set loading state to false at the end
//       }
//     };

//     if (currentUser) {
//       fetchDeliveryArea();
//     }
//   }, [currentUser, t]);

//   const handleSetDeliveryArea = (area) => {
//     setDeliveryArea(area);
//     setIsCompleted(true);
//   };

//   const handleSaveDeliveryArea = async () => {
//     try {
//       setLoading(true); // Set loading state at the beginning
//       const userRef = doc(db, 'users', currentUser.uid);
//       const userDoc = await getDoc(userRef);

//       if (userDoc.exists()) {
//         const updateData = {
//           deliveryArea,
//           modifiedAt: new Date(),
//         };

//         await updateDoc(userRef, updateData);

//         if (setIsStepCompleted) {
//           setIsStepCompleted(true); // Mark the step as completed
//         }
//         if (onNext) {
//           onNext(); // Proceed to the next step
//         }
//       } else {
//         throw new Error('User document does not exist');
//       }
//     } catch (error) {
//       console.error(t('errorUpdatingUserDocument'), error);
//       setError(t('errorOccurred')); // Set a user-friendly error message
//     } finally {
//       setLoading(false); // Set loading state to false at the end
//     }
//   };

//   return (
//     <Box
//       sx={{
//         width: '100%',
//         margin: 'auto',
//         marginBottom: '80px',
//       }}
//     >
//       <Container maxWidth="md">
//         <Typography variant="h5" gutterBottom>
//           {t('defineDeliveryArea')}
//         </Typography>
//         {!isCompleted && (
//           <Typography sx={{ mt: 0 }}>{t('selectRegionOnMap')}</Typography>
//         )}
//         {loading && <CircularProgress size={24} sx={{ mb: 1 }} />}
//         {error && <Alert severity="error">{error}</Alert>}
//         <Box sx={{ mt: 0, mb: 0 }}>
//           {!loading && (
//             <GoogleMapRegion
//               initialRegion={deliveryArea}
//               onSave={handleSetDeliveryArea}
//             />
//           )}
//         </Box>
//         {isCompleted && (
//           <Button
//             variant="contained"
//             color="primary"
//             onClick={handleSaveDeliveryArea}
//             sx={{ mt: 2 }}
//           >
//             {t('saveDeliveryArea')}
//           </Button>
//         )}
//       </Container>
//     </Box>
//   );
// }

// export default StoreDeliveryArea;

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { db, doc, getDoc, updateDoc } from '../firebaseConfig';
import GoogleMapRegion from '../Common/GoogleMapRegion';
import {
  Container,
  Typography,
  Button,
  CircularProgress,
  Alert,
  Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

function StoreDeliveryArea({ onPrev, onNext, setIsStepCompleted }) {
  const { t } = useTranslation();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const [deliveryArea, setDeliveryArea] = useState([]);
  const [isCompleted, setIsCompleted] = useState(false);
  const [loading, setLoading] = useState(true); // Initial loading state
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchDeliveryArea = async () => {
      setLoading(true); // Set loading state at the beginning
      try {
        const userRef = doc(db, 'users', currentUser.uid);
        const userDoc = await getDoc(userRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          const areaData = userData.deliveryArea || [];

          setDeliveryArea(areaData);
          setIsCompleted(areaData.length > 0); // Set completed state based on area data
        } else {
          console.error('User document does not exist');
        }
      } catch (error) {
        console.error(t('errorFetchingUserDocument'), error);
        setError(t('errorOccurred'));
      } finally {
        setLoading(false); // Set loading state to false at the end
      }
    };

    if (currentUser) {
      fetchDeliveryArea();
    }
  }, [currentUser, t]);

  const handleSetDeliveryArea = (area) => {
    setDeliveryArea(area);
    setIsCompleted(true);
  };

  const handleSaveDeliveryArea = async () => {
    try {
      setLoading(true); // Set loading state at the beginning
      const userRef = doc(db, 'users', currentUser.uid);
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
        // Check if deliveryArea and its center are defined
        if (!deliveryArea || !deliveryArea.center) {
          throw new Error('Delivery area or its center is not defined');
        }

        const updateData = {
          deliveryArea,
          step: process.env.REACT_APP_STORE_DELIVERYAREA_VERIFIED,
          modifiedAt: new Date(),
        };

        await updateDoc(userRef, updateData);

        if (setIsStepCompleted) {
          setIsStepCompleted(true); // Mark the step as completed
        }
        if (onNext) {
          onNext(); // Proceed to the next step
        }
      } else {
        throw new Error('User document does not exist');
      }
    } catch (error) {
      console.error(t('errorUpdatingUserDocument'), error);
      setError(t('errorOccurred')); // Set a user-friendly error message
    } finally {
      setLoading(false); // Set loading state to false at the end
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        margin: 'auto',
        marginBottom: '80px',
      }}
    >
      <Container maxWidth="md">
        <Typography variant="h5" gutterBottom>
          {t('defineDeliveryArea')}
        </Typography>
        {!isCompleted && (
          <Typography sx={{ mt: 0 }}>{t('selectRegionOnMap')}</Typography>
        )}
        {loading && <CircularProgress size={24} sx={{ mb: 1 }} />}
        {error && <Alert severity="error">{error}</Alert>}
        <Box sx={{ mt: 0, mb: 0 }}>
          {!loading && (
            <GoogleMapRegion
              initialRegion={deliveryArea}
              onSave={handleSetDeliveryArea}
            />
          )}
        </Box>
        {isCompleted && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveDeliveryArea}
            sx={{ mt: 2 }}
          >
            {t('saveDeliveryArea')}
          </Button>
        )}
      </Container>
    </Box>
  );
}

export default StoreDeliveryArea;
