// import React from 'react';

// export default function ChatContent({ url, isImage, isVideo, onFileClick }) {
//     return (
//       <div onClick={() => onFileClick(url)}>
//         {isImage ? (
//           <img src={url} alt="Uploaded content" width="300" />
//         ) : isVideo ? (
//           <video controls width="300">
//             <source src={url} type="video/mp4" />
//             Your browser does not support the video tag.
//           </video>
//         ) : (
//           <a href={url} target="_blank" rel="noopener noreferrer">Download File</a>
//         )}
//       </div>
//     );
//   }

import React from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { styled } from '@mui/material/styles';

// Styling for the image and video tags to have slight rounded corners
const StyledImage = styled('img')({
  width: 300, // Set width
  borderRadius: '8px', // Slight rounding of the corners
});

const StyledVideo = styled('video')({
  width: 300, // Set width
  borderRadius: '8px', // Slight rounding of the corners
});

export default function ChatContent({ url, isImage, isVideo, onFileClick }) {
  return (
    <Box onClick={() => onFileClick(url)} sx={{ cursor: 'pointer' }}>
      {isImage ? (
        <StyledImage src={url} alt="Uploaded content" />
      ) : isVideo ? (
        <StyledVideo controls>
          <source src={url} type="video/mp4" />
          Your browser does not support the video tag.
        </StyledVideo>
      ) : (
        <Link
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          underline="hover"
        >
          Download File
        </Link>
      )}
    </Box>
  );
}
