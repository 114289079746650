export const getGeocode = async (lat, lng) => {
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY; // 여기에 Google Maps API 키를 입력하세요
  const response = await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`
  );
  const data = await response.json();
  if (data.status === 'OK') {
    return data.results[0];
  } else {
    throw new Error('Geocoding API request failed');
  }
};

export const extractLocationName = (geocodeResult) => {
  const addressComponents = geocodeResult.address_components;
  const locality = addressComponents.find((component) =>
    component.types.includes('locality')
  );
  const sublocality = addressComponents.find(
    (component) =>
      component.types.includes('sublocality') ||
      component.types.includes('neighborhood')
  );
  const country = addressComponents.find((component) =>
    component.types.includes('country')
  );

  return {
    locality: locality ? locality.long_name : '',
    sublocality: sublocality ? sublocality.long_name : '',
    country: country ? country.long_name : '',
  };
};
