// import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import {
//   db,
//   doc,
//   query,
//   where,
//   getDoc,
//   getDocs,
//   collection,
//   onSnapshot,
//   orderBy,
//   limit,
//   deleteDoc,
// } from './firebaseConfig';
// import { useTranslation } from 'react-i18next';
// import { SwipeableList, SwipeableListItem } from 'react-swipeable-list';
// import 'react-swipeable-list/dist/styles.css';
// import { useNavigate, useParams } from 'react-router-dom';
// import moment from 'moment';
// import {
//   Box,
//   CircularProgress,
//   Typography,
//   ListItem,
//   ListItemText,
//   ListItemAvatar,
//   Avatar,
//   Card,
//   CardContent,
//   Alert,
//   Divider,
//   CardActionArea,
//   Tabs,
//   Tab,
//   IconButton,
//   Button,
// } from '@mui/material';
// import DeleteIcon from '@mui/icons-material/Delete';

// function ChatList() {
//   const { t, i18n } = useTranslation();
//   const [chatRooms, setChatRooms] = useState([]);
//   const [storeChatRooms, setStoreChatRooms] = useState([]);
//   const [isLoading, setIsLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [activeTab, setActiveTab] = useState(0);
//   const navigate = useNavigate();
//   const currentUser = useSelector((state) => state.auth.currentUser);
//   // const { storeId } = useParams();

//   useEffect(() => {
//     moment.locale(i18n.language);
//   }, [i18n.language]);

//   // useEffect(() => {
//   //   if (currentUser) {
//   //     const chatroomQuery = query(
//   //       collection(db, 'chatroom'),
//   //       where('who', 'array-contains', currentUser.uid)
//   //     );

//   //     const unsubscribe = onSnapshot(chatroomQuery, async (snapshot) => {
//   //       try {
//   //         const chatroomDataPromises = snapshot.docs.map(
//   //           async (documentSnapshot) => {
//   //             const chatData = documentSnapshot.data();
//   //             let productData = null;
//   //             if (chatData.productId) {
//   //               const productRef = doc(db, 'products', chatData.productId);
//   //               const productSnapshot = await getDoc(productRef);
//   //               productData = productSnapshot.exists()
//   //                 ? productSnapshot.data()
//   //                 : null;
//   //             }

//   //             return {
//   //               ...chatData,
//   //               id: documentSnapshot.id,
//   //               product: productData,
//   //               ownerProfileImage: await fetchOwnerImage(
//   //                 chatData.who.find((uid) => uid !== currentUser.uid)
//   //               ),
//   //               lastMessage: await fetchLastMessage(documentSnapshot.id),
//   //             };
//   //           }
//   //         );

//   //         const completedChatrooms = await Promise.all(chatroomDataPromises);
//   //         setChatRooms(completedChatrooms);
//   //         setIsLoading(false);
//   //       } catch (e) {
//   //         console.error('An error occurred:', e);
//   //         setError(e.message);
//   //         setIsLoading(false);
//   //       }
//   //     });

//   //     return () => unsubscribe();
//   //   } else {
//   //     setIsLoading(false);
//   //   }
//   // }, [currentUser]);

//   // useEffect(() => {
//   //   if (currentUser && storeId) {
//   //     const storeChatroomQuery = query(
//   //       collection(db, 'stores', storeId, 'chatroom'),
//   //       where('who', 'array-contains', currentUser.uid)
//   //     );

//   //     const unsubscribe = onSnapshot(storeChatroomQuery, async (snapshot) => {
//   //       try {
//   //         const chatroomDataPromises = snapshot.docs.map(
//   //           async (documentSnapshot) => {
//   //             const chatData = documentSnapshot.data();
//   //             let productData = null;

//   //             if (chatData.productId) {
//   //               const productRef = doc(db, 'products', chatData.productId);
//   //               const productSnapshot = await getDoc(productRef);
//   //               productData = productSnapshot.exists()
//   //                 ? productSnapshot.data()
//   //                 : null;
//   //             }

//   //             return {
//   //               ...chatData,
//   //               id: documentSnapshot.id,
//   //               product: productData,
//   //               ownerProfileImage: await fetchOwnerImage(
//   //                 chatData.who.find((uid) => uid !== currentUser.uid)
//   //               ),
//   //               lastMessage: await fetchLastMessageStore(
//   //                 storeId,
//   //                 documentSnapshot.id
//   //               ),
//   //             };
//   //           }
//   //         );

//   //         const completedChatrooms = await Promise.all(chatroomDataPromises);
//   //         setStoreChatRooms(completedChatrooms);
//   //         setIsLoading(false);
//   //       } catch (e) {
//   //         console.error('An error occurred:', e);
//   //         setError(e.message);
//   //         setIsLoading(false);
//   //       }
//   //     });

//   //     return () => unsubscribe();
//   //   } else {
//   //     setIsLoading(false);
//   //   }
//   // }, [currentUser, storeId]);

//   useEffect(() => {
//     if (currentUser) {
//       const chatroomQuery = query(
//         collection(db, 'chatroom'),
//         where('who', 'array-contains', currentUser.uid)
//       );

//       const unsubscribe = onSnapshot(chatroomQuery, async (snapshot) => {
//         try {
//           const chatroomDataPromises = snapshot.docs.map(
//             async (documentSnapshot) => {
//               const chatData = documentSnapshot.data();
//               let productData = null;
//               if (chatData.productId) {
//                 const productRef = doc(db, 'products', chatData.productId);
//                 const productSnapshot = await getDoc(productRef);
//                 productData = productSnapshot.exists()
//                   ? productSnapshot.data()
//                   : null;
//               }

//               const otherUid = chatData.who.find(
//                 (uid) => uid !== currentUser.uid
//               );
//               const ownerProfileImage = otherUid
//                 ? await fetchOwnerImage(otherUid)
//                 : 'https://via.placeholder.com/150';

//               return {
//                 ...chatData,
//                 id: documentSnapshot.id,
//                 product: productData,
//                 ownerProfileImage: ownerProfileImage,
//                 lastMessage: await fetchLastMessage(documentSnapshot.id),
//               };
//             }
//           );

//           const completedChatrooms = await Promise.all(chatroomDataPromises);
//           setChatRooms(completedChatrooms);
//           setIsLoading(false);
//         } catch (e) {
//           console.error('An error occurred:', e);
//           setError(e.message);
//           setIsLoading(false);
//         }
//       });

//       return () => unsubscribe();
//     } else {
//       setIsLoading(false);
//     }
//   }, [currentUser]);

//   useEffect(() => {
//     if (currentUser && storeId) {
//       const storeChatroomQuery = query(
//         collection(db, 'stores', storeId, 'chatroom'),
//         where('who', 'array-contains', currentUser.uid)
//       );

//       const unsubscribe = onSnapshot(storeChatroomQuery, async (snapshot) => {
//         try {
//           const chatroomDataPromises = snapshot.docs.map(
//             async (documentSnapshot) => {
//               const chatData = documentSnapshot.data();
//               let productData = null;

//               if (chatData.productId) {
//                 const productRef = doc(db, 'products', chatData.productId);
//                 const productSnapshot = await getDoc(productRef);
//                 productData = productSnapshot.exists()
//                   ? productSnapshot.data()
//                   : null;
//               }

//               const otherUid = chatData.who.find(
//                 (uid) => uid !== currentUser.uid
//               );
//               const ownerProfileImage = otherUid
//                 ? await fetchOwnerImage(otherUid)
//                 : 'https://via.placeholder.com/150';

//               return {
//                 ...chatData,
//                 id: documentSnapshot.id,
//                 product: productData,
//                 ownerProfileImage: ownerProfileImage,
//                 lastMessage: await fetchLastMessageStore(
//                   storeId,
//                   documentSnapshot.id
//                 ),
//               };
//             }
//           );

//           const completedChatrooms = await Promise.all(chatroomDataPromises);
//           setStoreChatRooms(completedChatrooms);
//           setIsLoading(false);
//         } catch (e) {
//           console.error('An error occurred:', e);
//           setError(e.message);
//           setIsLoading(false);
//         }
//       });

//       return () => unsubscribe();
//     } else {
//       setIsLoading(false);
//     }
//   }, [currentUser, storeId]);

//   const fetchOwnerImage = async (uid) => {
//     const ownerUserRef = doc(db, 'users', uid);
//     const ownerUserSnapshot = await getDoc(ownerUserRef);
//     return ownerUserSnapshot.exists()
//       ? ownerUserSnapshot.data().photoURL
//       : 'https://via.placeholder.com/150';
//   };

//   const fetchLastMessage = async (chatroomId) => {
//     const lastMessageQuery = query(
//       collection(db, 'chatroom', chatroomId, 'messages'),
//       orderBy('date', 'desc'),
//       limit(1)
//     );
//     const lastMessageSnapshot = await getDocs(lastMessageQuery);
//     if (!lastMessageSnapshot.empty) {
//       const messageData = lastMessageSnapshot.docs[0].data();
//       return { content: messageData.content, date: messageData.date.toDate() };
//     }
//     return { content: t('noMessagesYet'), date: new Date() };
//   };

//   const fetchLastMessageStore = async (storeId, chatroomId) => {
//     const lastMessageQuery = query(
//       collection(db, 'stores', storeId, 'chatroom', chatroomId, 'messages'),
//       orderBy('date', 'desc'),
//       limit(1)
//     );
//     const lastMessageSnapshot = await getDocs(lastMessageQuery);
//     if (!lastMessageSnapshot.empty) {
//       const messageData = lastMessageSnapshot.docs[0].data();
//       return { content: messageData.content, date: messageData.date.toDate() };
//     }
//     return { content: t('noMessagesYet'), date: new Date() };
//   };

//   const handleChatRoomClick = (productId, chatRoomId) => {
//     navigate(`/chatview/${productId}/${chatRoomId}`);
//   };

//   const handleStoreChatRoomClick = (chatRoomId) => {
//     navigate(`/storechatview/${storeId}/${chatRoomId}`);
//   };

//   const handleTabChange = (event, newValue) => {
//     setActiveTab(newValue);
//   };

//   const handleDeleteChatRoom = async (chatRoomId, isStoreChat) => {
//     try {
//       if (isStoreChat) {
//         await deleteDoc(doc(db, 'stores', storeId, 'chatroom', chatRoomId));
//         setStoreChatRooms(
//           storeChatRooms.filter((room) => room.id !== chatRoomId)
//         );
//       } else {
//         await deleteDoc(doc(db, 'chatroom', chatRoomId));
//         setChatRooms(chatRooms.filter((room) => room.id !== chatRoomId));
//       }
//     } catch (e) {
//       console.error('An error occurred while deleting the chat room:', e);
//       setError(e.message);
//     }
//   };

//   const filteredChatRooms = chatRooms.filter((chatRoom) => {
//     if (activeTab === 0) return chatRoom;
//     return false;
//   });

//   const filteredStoreChatRooms = storeChatRooms.filter((chatRoom) => {
//     if (activeTab === 1) return chatRoom;
//     return false;
//   });

//   if (isLoading) {
//     return (
//       <Box
//         display="flex"
//         justifyContent="center"
//         alignItems="center"
//         minHeight="100vh"
//       >
//         <CircularProgress />
//       </Box>
//     );
//   }

//   if (!currentUser) {
//     return (
//       <Box
//         display="flex"
//         flexDirection="column"
//         justifyContent="center"
//         alignItems="center"
//         minHeight="100vh"
//       >
//         <Typography variant="h6" gutterBottom>
//           {t('login-required')}
//         </Typography>
//         <Button
//           variant="contained"
//           color="primary"
//           onClick={() => navigate('/login')}
//         >
//           {t('login')}
//         </Button>
//       </Box>
//     );
//   }

//   return (
//     <Box
//       p={3}
//       sx={{
//         width: '100%',
//         maxWidth: '100%',
//         margin: 'auto',
//       }}
//     >
//       <Box
//         sx={{
//           display: 'inline-block',
//           border: '0px solid #d3d3d3',
//           borderRadius: '16px',
//           padding: '8px 16px',
//           marginBottom: '16px',
//           backgroundColor: 'transparent',
//           transition: 'transform 0.2s',
//           '&:hover': {
//             transform: 'scale(1.02)',
//           },
//         }}
//       >
//         <Typography variant="h4" gutterBottom>
//           {t('chatlist')}
//         </Typography>
//       </Box>
//       {error && <Alert severity="error">{error}</Alert>}
//       <Tabs
//         value={activeTab}
//         onChange={handleTabChange}
//         centered
//         variant="fullWidth"
//       >
//         <Tab label={t('general')} />
//         <Tab label={t('business')} />
//       </Tabs>
//       <Box sx={{ width: '100%', mt: 2 }}>
//         <SwipeableList style={{ width: '100%' }}>
//           {(activeTab === 0 ? filteredChatRooms : filteredStoreChatRooms)
//             .length ? (
//             (activeTab === 0 ? filteredChatRooms : filteredStoreChatRooms).map(
//               (chatRoom) => (
//                 <SwipeableListItem
//                   key={chatRoom.id}
//                   swipeLeft={{
//                     content: (
//                       <Box
//                         sx={{
//                           display: 'flex',
//                           justifyContent: 'flex-end',
//                           alignItems: 'center',
//                           pr: 2,
//                         }}
//                       >
//                         <IconButton
//                           color="secondary"
//                           onClick={() =>
//                             handleDeleteChatRoom(chatRoom.id, activeTab === 1)
//                           }
//                         >
//                           <DeleteIcon />
//                         </IconButton>
//                       </Box>
//                     ),
//                     action: () =>
//                       handleDeleteChatRoom(chatRoom.id, activeTab === 1),
//                   }}
//                 >
//                   <Card sx={{ mb: 2, width: '100%' }}>
//                     <CardActionArea
//                       onClick={() =>
//                         activeTab === 0
//                           ? handleChatRoomClick(chatRoom.productId, chatRoom.id)
//                           : handleStoreChatRoomClick(chatRoom.id)
//                       }
//                     >
//                       <CardContent>
//                         <ListItem alignItems="flex-start">
//                           <ListItemAvatar>
//                             <Avatar
//                               alt="Profile Image"
//                               src={chatRoom.ownerProfileImage}
//                             />
//                           </ListItemAvatar>
//                           <ListItemText
//                             primary={
//                               chatRoom.product?.title ||
//                               chatRoom.storeName || // Handle store chat without product
//                               t('noTitleAvailable')
//                             }
//                             secondary={
//                               <>
//                                 <Typography
//                                   component="span"
//                                   variant="body2"
//                                   color="text.primary"
//                                 >
//                                   {moment(chatRoom.lastMessage.date).fromNow()}
//                                 </Typography>
//                                 {' — '}
//                                 {chatRoom.lastMessage.content}
//                               </>
//                             }
//                           />
//                         </ListItem>
//                       </CardContent>
//                     </CardActionArea>
//                     <Divider />
//                   </Card>
//                 </SwipeableListItem>
//               )
//             )
//           ) : (
//             <Typography textAlign="center">{t('noChatRooms')}</Typography>
//           )}
//         </SwipeableList>
//       </Box>
//     </Box>
//   );
// }

// export default ChatList;

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  db,
  doc,
  query,
  where,
  getDoc,
  getDocs,
  collection,
  onSnapshot,
  orderBy,
  limit,
  deleteDoc,
} from './firebaseConfig';
import { useTranslation } from 'react-i18next';
import { SwipeableList, SwipeableListItem } from 'react-swipeable-list';
import 'react-swipeable-list/dist/styles.css';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import {
  Box,
  CircularProgress,
  Typography,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Card,
  CardContent,
  Alert,
  Divider,
  CardActionArea,
  Tabs,
  Tab,
  IconButton,
  Button,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

function ChatList() {
  const { t, i18n } = useTranslation();
  const [chatRooms, setChatRooms] = useState([]);
  const [storeChatRooms, setStoreChatRooms] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.auth.currentUser);

  useEffect(() => {
    moment.locale(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    if (currentUser) {
      const chatroomQuery = query(
        collection(db, 'chatroom'),
        where('who', 'array-contains', currentUser.uid)
      );

      const unsubscribe = onSnapshot(chatroomQuery, async (snapshot) => {
        try {
          const chatroomDataPromises = snapshot.docs.map(
            async (documentSnapshot) => {
              const chatData = documentSnapshot.data();
              let productData = null;
              if (chatData.productId) {
                const productRef = doc(db, 'products', chatData.productId);
                const productSnapshot = await getDoc(productRef);
                productData = productSnapshot.exists()
                  ? productSnapshot.data()
                  : null;
              }

              const otherUid = chatData.who.find(
                (uid) => uid !== currentUser.uid
              );
              const ownerProfileImage = otherUid
                ? await fetchOwnerImage(otherUid)
                : 'https://via.placeholder.com/150';

              return {
                ...chatData,
                id: documentSnapshot.id,
                product: productData,
                ownerProfileImage: ownerProfileImage,
                lastMessage: await fetchLastMessage(documentSnapshot.id),
              };
            }
          );

          const completedChatrooms = await Promise.all(chatroomDataPromises);
          setChatRooms(completedChatrooms);
          setIsLoading(false);
        } catch (e) {
          console.error('An error occurred:', e);
          setError(e.message);
          setIsLoading(false);
        }
      });

      return () => unsubscribe();
    } else {
      setIsLoading(false);
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser) {
      const fetchStoreChatrooms = async () => {
        try {
          const storesSnapshot = await getDocs(collection(db, 'stores'));
          const chatroomDataPromises = storesSnapshot.docs.map(
            async (storeDoc) => {
              const storeId = storeDoc.id;
              const storeChatroomQuery = query(
                collection(db, 'stores', storeId, 'chatroom'),
                where('who', 'array-contains', currentUser.uid)
              );
              const storeChatroomsSnapshot = await getDocs(storeChatroomQuery);
              const chatrooms = storeChatroomsSnapshot.docs.map(
                async (chatroomDoc) => {
                  const chatData = chatroomDoc.data();
                  let productData = null;

                  if (chatData.productId) {
                    const productRef = doc(
                      db,
                      'stores',
                      storeId,
                      'products',
                      chatData.productId
                    );
                    const productSnapshot = await getDoc(productRef);
                    productData = productSnapshot.exists()
                      ? productSnapshot.data()
                      : null;
                  }

                  const otherUid = chatData.who.find(
                    (uid) => uid !== currentUser.uid
                  );
                  const ownerProfileImage = otherUid
                    ? await fetchOwnerImage(otherUid)
                    : 'https://via.placeholder.com/150';

                  return {
                    ...chatData,
                    id: chatroomDoc.id,
                    storeId,
                    product: productData,
                    ownerProfileImage: ownerProfileImage,
                    lastMessage: await fetchLastMessageStore(
                      storeId,
                      chatroomDoc.id
                    ),
                  };
                }
              );
              return Promise.all(chatrooms);
            }
          );
          const completedChatrooms = (
            await Promise.all(chatroomDataPromises)
          ).flat();
          setStoreChatRooms(completedChatrooms);
          setIsLoading(false);
        } catch (e) {
          console.error('An error occurred:', e);
          setError(e.message);
          setIsLoading(false);
        }
      };

      fetchStoreChatrooms();
    } else {
      setIsLoading(false);
    }
  }, [currentUser]);

  const fetchOwnerImage = async (uid) => {
    const ownerUserRef = doc(db, 'users', uid);
    const ownerUserSnapshot = await getDoc(ownerUserRef);
    return ownerUserSnapshot.exists()
      ? ownerUserSnapshot.data().photoURL
      : 'https://via.placeholder.com/150';
  };

  const fetchLastMessage = async (chatroomId) => {
    const lastMessageQuery = query(
      collection(db, 'chatroom', chatroomId, 'messages'),
      orderBy('date', 'desc'),
      limit(1)
    );
    const lastMessageSnapshot = await getDocs(lastMessageQuery);
    if (!lastMessageSnapshot.empty) {
      const messageData = lastMessageSnapshot.docs[0].data();
      return { content: messageData.content, date: messageData.date.toDate() };
    }
    return { content: t('noMessagesYet'), date: new Date() };
  };

  const fetchLastMessageStore = async (storeId, chatroomId) => {
    const lastMessageQuery = query(
      collection(db, 'stores', storeId, 'chatroom', chatroomId, 'messages'),
      orderBy('date', 'desc'),
      limit(1)
    );
    const lastMessageSnapshot = await getDocs(lastMessageQuery);
    if (!lastMessageSnapshot.empty) {
      const messageData = lastMessageSnapshot.docs[0].data();
      return { content: messageData.content, date: messageData.date.toDate() };
    }
    return { content: t('noMessagesYet'), date: new Date() };
  };

  const handleChatRoomClick = (productId, chatRoomId) => {
    navigate(`/chatview/${productId}/${chatRoomId}`);
  };

  const handleStoreChatRoomClick = (storeId, chatRoomId) => {
    navigate(`/storechatview/${storeId}/${chatRoomId}`);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleDeleteChatRoom = async (
    chatRoomId,
    isStoreChat,
    storeId = null
  ) => {
    try {
      if (isStoreChat) {
        await deleteDoc(doc(db, 'stores', storeId, 'chatroom', chatRoomId));
        setStoreChatRooms(
          storeChatRooms.filter((room) => room.id !== chatRoomId)
        );
      } else {
        await deleteDoc(doc(db, 'chatroom', chatRoomId));
        setChatRooms(chatRooms.filter((room) => room.id !== chatRoomId));
      }
    } catch (e) {
      console.error('An error occurred while deleting the chat room:', e);
      setError(e.message);
    }
  };

  const filteredChatRooms = chatRooms.filter((chatRoom) => {
    if (activeTab === 0) return chatRoom;
    if (activeTab === 1) return false;
    return chatRoom;
  });

  const filteredStoreChatRooms = storeChatRooms.filter((chatRoom) => {
    if (activeTab === 0) return false;
    if (activeTab === 1) return true;
    return chatRoom;
  });

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!currentUser) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Typography variant="h6" gutterBottom>
          {t('login-required')}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/login')}
        >
          {t('login')}
        </Button>
      </Box>
    );
  }

  return (
    <Box
      p={3}
      sx={{
        width: '100%',
        maxWidth: '100%',
        margin: 'auto',
      }}
    >
      <Box
        sx={{
          display: 'inline-block',
          border: '0px solid #d3d3d3',
          borderRadius: '16px',
          padding: '8px 16px',
          marginBottom: '16px',
          backgroundColor: 'transparent',
          transition: 'transform 0.2s',
          '&:hover': {
            transform: 'scale(1.02)',
          },
        }}
      >
        <Typography variant="h4" gutterBottom>
          {t('chatlist')}
        </Typography>
      </Box>
      {error && <Alert severity="error">{error}</Alert>}
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        centered
        variant="fullWidth"
      >
        <Tab label={t('general')} />
        <Tab label={t('business')} />
      </Tabs>
      <Box sx={{ width: '100%', mt: 2 }}>
        <SwipeableList style={{ width: '100%' }}>
          {(activeTab === 0 ? filteredChatRooms : filteredStoreChatRooms)
            .length ? (
            (activeTab === 0 ? filteredChatRooms : filteredStoreChatRooms).map(
              (chatRoom) => (
                <SwipeableListItem
                  key={chatRoom.id}
                  swipeLeft={{
                    content: (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                          pr: 2,
                        }}
                      >
                        <IconButton
                          color="secondary"
                          onClick={() =>
                            handleDeleteChatRoom(
                              chatRoom.id,
                              activeTab === 1,
                              chatRoom.storeId
                            )
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    ),
                    action: () =>
                      handleDeleteChatRoom(
                        chatRoom.id,
                        activeTab === 1,
                        chatRoom.storeId
                      ),
                  }}
                >
                  <Card sx={{ mb: 2, width: '100%' }}>
                    <CardActionArea
                      onClick={() =>
                        activeTab === 0
                          ? handleChatRoomClick(chatRoom.productId, chatRoom.id)
                          : handleStoreChatRoomClick(
                              chatRoom.storeId,
                              chatRoom.id
                            )
                      }
                    >
                      <CardContent>
                        <ListItem alignItems="flex-start">
                          <ListItemAvatar>
                            <Avatar
                              alt="Profile Image"
                              src={chatRoom.ownerProfileImage}
                            />
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              chatRoom.product?.title || t('noTitleAvailable')
                            }
                            secondary={
                              <>
                                <Typography
                                  component="span"
                                  variant="body2"
                                  color="text.primary"
                                >
                                  {moment(chatRoom.lastMessage.date).fromNow()}
                                </Typography>
                                {' — '}
                                {chatRoom.lastMessage.content}
                              </>
                            }
                          />
                        </ListItem>
                      </CardContent>
                    </CardActionArea>
                    <Divider />
                  </Card>
                </SwipeableListItem>
              )
            )
          ) : (
            <Typography textAlign="center">{t('noChatRooms')}</Typography>
          )}
        </SwipeableList>
      </Box>
    </Box>
  );
}

export default ChatList;
