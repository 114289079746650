// import { useState, useEffect } from 'react';
// import { db, doc, getDoc, addDoc, collection, runTransaction } from '../../../firebaseConfig';
// import UsePoints from './UsePoints';
// import useMessageBox from '../../../Common/useMessageBox';

// function StorePoints({ storeId, userId, onUsedPoints }) {

//     const [MessageBoxInform, MessageBoxConfirm, MessageBoxRender] = useMessageBox();
//     const [points, setPoints] = useState(0);
//     const [pointsToUse, setPointsToUse] = useState(0);

//     useEffect(() => {
//         async function fetchUserPoints() {
//             if (!storeId || !userId) {
//                 console.warn("StoreId or UserId is undefined, skipping fetch.");
//                 return;
//             }
//             const storeUserRef = doc(db, 'stores', storeId, 'users', userId);
//             const userDoc = await getDoc(storeUserRef);
//             if (userDoc.exists()) {
//                 setPoints(userDoc.data().points || 0);
//             }
//         }

//         fetchUserPoints();
//     }, [storeId, userId]);

//     const handlePointsUsage = async () => {
//         if (pointsToUse > points || pointsToUse <= 0) {
//             await MessageBoxInform("Cannot use more points than available or invalid point value");
//             setPointsToUse(0); // Reset the points to use state
//             return;
//         }

//         try {
//             const response = await UsePoints(storeId, userId, pointsToUse);

//             if (!response.success) {
//                 throw new Error(response.message);
//             }

//             setPoints(prev => prev - pointsToUse);
//             onUsedPoints(pointsToUse);
//             setPointsToUse(0);
//         } catch (error) {
//             console.error("Error using points: ", error);
//             await MessageBoxInform("포인트 사용에러: 남아있는 포인트보다 작은 숫자를 입력해주세요.");
//             setPointsToUse(0); // Reset the points to use state
//         }
//     }
//     const style = {
//         padding: "20px",
//         backgroundColor: "#f5f5f5",
//         borderRadius: "10px",
//         boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)"
//     };
//     return (
//         <div style={style}>
//             {MessageBoxRender()}
//             <h2>나의 포인트: {points}</h2>
//             <div>
//                 <label>Points to Use:</label>
//                 <input
//                     type="number"
//                     value={pointsToUse}
//                     onChange={(e) => setPointsToUse(Number(e.target.value))}
//                     min="0"
//                     max={points}
//                 />
//             </div>
//             <button onClick={handlePointsUsage}>포인트 사용</button>
//         </div>
//     );
// }

// export default StorePoints;

import React, { useState, useEffect } from 'react';
import {
  db,
  doc,
  getDoc,
  addDoc,
  collection,
  runTransaction,
} from '../../../firebaseConfig';
import { useTranslation } from 'react-i18next';
import UsePoints from './UsePoints';
import useMessageBox from '../../../Common/useMessageBox';
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Box,
} from '@mui/material';

function StorePoints({ storeId, userId, onUsedPoints }) {
  const { t, i18n } = useTranslation();
  const [MessageBoxInform, MessageBoxConfirm, MessageBoxRender] =
    useMessageBox();
  const [points, setPoints] = useState(0);
  const [pointsToUse, setPointsToUse] = useState(0);

  useEffect(() => {
    async function fetchUserPoints() {
      if (!storeId || !userId) {
        console.warn('StoreId or UserId is undefined, skipping fetch.');
        return;
      }
      const storeUserRef = doc(db, 'users', userId);
      const userDoc = await getDoc(storeUserRef);
      if (userDoc.exists()) {
        setPoints(userDoc.data().points || 0);
      }
    }

    fetchUserPoints();
  }, [storeId, userId]);

  const handlePointsUsage = async () => {
    if (pointsToUse > points || pointsToUse <= 0) {
      await MessageBoxInform(
        'Cannot use more points than available or invalid point value'
      );
      setPointsToUse(0);
      return;
    }

    try {
      const response = await UsePoints(storeId, userId, pointsToUse);

      if (!response.success) {
        throw new Error(response.message);
      }

      setPoints((prev) => prev - pointsToUse);
      onUsedPoints(pointsToUse);
      setPointsToUse(0);
    } catch (error) {
      console.error('Error using points: ', error);
      await MessageBoxInform(
        '포인트 사용에러: 남아있는 포인트보다 작은 숫자를 입력해주세요.'
      );
      setPointsToUse(0);
    }
  };

  return (
    <Card sx={{ my: 2 }}>
      {MessageBoxRender()}
      <CardContent>
        <Typography variant="h6">
          {t('myPoints')}: {points}
        </Typography>
        <Box sx={{ mt: 2 }}>
          <TextField
            type="number"
            label={t('pointsToUse')}
            value={pointsToUse || ''}
            onChange={(e) => setPointsToUse(Number(e.target.value))}
            InputProps={{ inputProps: { min: 0, max: points } }}
            variant="outlined"
            fullWidth
          />
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={handlePointsUsage}
          sx={{ mt: 2 }}
        >
          {t('usePoints')}
        </Button>
      </CardContent>
    </Card>
  );
}

export default StorePoints;

// import React, { useState, useEffect } from 'react';
// import { db, doc, getDoc } from '../../../firebaseConfig';
// import { useTranslation } from 'react-i18next';
// import UsePoints from './UsePoints';
// import useMessageBox from '../../../Common/useMessageBox';
// import {
//   Card,
//   CardContent,
//   Typography,
//   TextField,
//   Button,
//   Box,
//   useMediaQuery,
//   useTheme,
// } from '@mui/material';

// function StorePoints({ storeId, userId, onUsedPoints }) {
//   const { t } = useTranslation();
//   const [MessageBoxInform, MessageBoxRender] = useMessageBox();
//   const [points, setPoints] = useState(0);
//   const [pointsToUse, setPointsToUse] = useState(0);
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

//   useEffect(() => {
//     async function fetchUserPoints() {
//       if (!storeId || !userId) {
//         console.warn('StoreId or UserId is undefined, skipping fetch.');
//         return;
//       }
//       try {
//         const storeUserRef = doc(db, 'stores', storeId, 'users', userId);
//         const userDoc = await getDoc(storeUserRef);
//         if (userDoc.exists()) {
//           setPoints(userDoc.data().points || 0);
//         }
//       } catch (error) {
//         console.error('Error fetching user points: ', error);
//       }
//     }

//     fetchUserPoints();
//   }, [storeId, userId]);

//   const handlePointsUsage = async () => {
//     if (pointsToUse > points || pointsToUse <= 0) {
//       await MessageBoxInform(
//         'Cannot use more points than available or invalid point value'
//       );
//       setPointsToUse(0);
//       return;
//     }

//     try {
//       const response = await UsePoints(storeId, userId, pointsToUse);

//       if (!response.success) {
//         throw new Error(response.message);
//       }

//       setPoints((prev) => prev - pointsToUse);
//       onUsedPoints(pointsToUse);
//       setPointsToUse(0);
//     } catch (error) {
//       console.error('Error using points: ', error);
//       await MessageBoxInform(
//         '포인트 사용에러: 남아있는 포인트보다 작은 숫자를 입력해주세요.'
//       );
//       setPointsToUse(0);
//     }
//   };

//   return (
//     <Card sx={{ my: 2 }}>
//       {/* {MessageBoxRender()} */}
//       <CardContent>
//         <Typography variant="h6">
//           {t('myPoints')}: {points}
//         </Typography>
//         <Box sx={{ mt: 2 }}>
//           <TextField
//             type="number"
//             label={t('pointsToUse')}
//             value={pointsToUse || ''}
//             onChange={(e) => setPointsToUse(Number(e.target.value))}
//             InputProps={{ inputProps: { min: 0, max: points } }}
//             variant="outlined"
//             fullWidth
//           />
//         </Box>
//         <Button
//           variant="contained"
//           color="primary"
//           onClick={handlePointsUsage}
//           sx={{ mt: 2 }}
//         >
//           {t('usePoints')}
//         </Button>
//       </CardContent>
//     </Card>
//   );
// }

// export default StorePoints;
