// import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import { db, doc, getDoc } from './firebaseConfig';
// import { LinearProgress, Button } from '@mui/material';
// import { useNavigate } from "react-router-dom";
// import SignUpCreateAccount from './SignUp/SignUpCreateAccount';
// import EmailVerification from './SignUp/EmailVerification';
// import PhoneNumberVerification from './SignUp/PhoneNumberVerification';
// import UserProfile from './UserProfile';
// import SignUpConfirm from './SignUp/SignUpConfirm';
// import SignUpComplete from './SignUp/SignUpComplete';
// import SignUpStatus from './SignUp/SignUpStatus';
// import SignUpEditPassword from './SignUp/SignUpEditPassword';
// // import '../css/temp.css';
// import './Stores/css/SignUpFlow.css';

// function UserSignUpFlow(props) {

//     const navigate = useNavigate();
//     const [mode, setMode] = useState(props.mode || 0);
//     const [step, setStep] = useState(1);
//     const totalSteps = 8;
//     const [isStepCompleted, setIsStepCompleted] = useState(false);
//     const currentUser = useSelector((state) => state.auth.currentUser);
//     const editStep = useSelector((state) => state.edit.step);

//     useEffect(() => {
//         setMode(props.mode);
//     }, [props.mode]);
//     useEffect(() => {
//         if (editStep) {
//             setStep(editStep);
//         } else {
//             // handle invalid step or navigate to an error page
//         }
//     }, [editStep]);
//     useEffect(() => {
//         const checkPendingRegistration = async () => {
//             if (currentUser && mode === 1) {

//                 const userRef = doc(db, 'users', currentUser.uid);
//                 const userSnapshot = await getDoc(userRef);

//                 if (userSnapshot.exists()) {
//                     const userData = userSnapshot.data();

//                     if (userData.pendingRegistrations &&
//                         userData.pendingRegistrations.step &&
//                         userData.pendingRegistrations.step.trim().length > 0) {

//                         determineNextStep(userData.pendingRegistrations.step);

//                     }
//                     else {
//                         determineNextStep(process.env.REACT_APP_STORE_CREATE_ACCOUNT);
//                     }
//                 }
//             }
//         };

//         checkPendingRegistration();
//     }, [currentUser]);

//     const handleBack = () => {
//         setStep((prev) => Math.max(prev - 1, 1));  // Don't go below step 1
//     };

//     const determineNextStep = (step) => {
//         switch (step) {
//             case process.env.REACT_APP_USER_CREATE_ACCOUNT:
//                 setStep(1);
//                 break;
//             case process.env.REACT_APP_CREATE_ACCOUNT_VERIFIED:
//                 setStep(2);
//                 break;
//             case process.env.REACT_APP_EMAIL_VERIFIED:
//                 setStep(3);
//                 break;
//             case process.env.REACT_APP_PHONE_NUMBER_VERIFIED:
//                 setStep(4);
//                 break;
//             case process.env.REACT_APP_USER_PROFILE_VERIFIED:
//                 setStep(5);
//                 break;
//             case process.env.REACT_APP_USER_SIGNUP_CONFIRM:
//                 setStep(6);
//                 break;
//             case process.env.REACT_APP_USER_SIGNUP_COMPLETED:
//                 setStep(7);
//                 break;
//             default:
//                 setStep(1);
//                 break;
//         }
//     };

//     const handleNextClick = () => {
//         if (!isStepCompleted) {
//             alert("이 단계를 완료해주세요!");
//             return;
//         }

//         setStep((prev) => prev + 1);
//         setIsStepCompleted(false); // 다음 단계로 이동할 때 완료 상태 초기화
//     };

//     const handlePrevClick = () => {
//         if (step > 1) {  // 첫 번째 단계보다 높은 경우에만 이전 단계로 이동 가능
//             setStep((prev) => prev - 1);
//         }
//     };

//     const handleCompleteClick = () => {
//         props.closeModal();
//         console.log('Complete button clicked!');
//     };

//     const handleCloseClick = () => {
//         props.closeModal();
//         navigate('/');
//         console.log('Close button clicked!');
//     };
//     const renderStepContent = () => {
//         const commonProps = {
//             onNext: handleNextClick,
//             onPrev: handlePrevClick,
//             setIsStepCompleted,
//         };

//         switch (step) {
//             case 1:
//                 if (mode === 2) {
//                     return <SignUpEditPassword {...commonProps} />;
//                 }
//                 return <SignUpCreateAccount {...commonProps} prevDisabled />;
//             case 2:
//                 return <EmailVerification {...commonProps} />;
//             case 3:
//                 return <PhoneNumberVerification {...commonProps} />;
//             case 4:
//                 return <UserProfile {...commonProps} />;
//             case 5:
//                 return <SignUpConfirm {...commonProps} />;
//             case 6:
//                 return <SignUpComplete {...commonProps} nextDisabled />;
//             case 7:
//                 return <SignUpStatus {...commonProps} />;
//             default:
//                 return <div>Error:step is {step}</div>;
//         }
//     };

//     return (
//         <div className='signup-flow'>
//             <div className='container'>
//                 <div>
//                     <LinearProgress variant="determinate" value={(step / totalSteps) * 100} />
//                 </div>
//                 <div>
//                     <button className="close-button" onClick={handleCloseClick}>x</button>  {/* "x" 버튼 추가 */}
//                 </div>

//                 <div className="step-content">
//                     {renderStepContent()}
//                 </div>

//                 <div className="navigation-buttons">
//                 {mode === 1 && step > 1 && step !== 7 && <button className='prevnext-button' onClick={handlePrevClick}>이전</button>}
//                     {mode === 1 && step < 6 && <button className='prevnext-button' onClick={handleNextClick}>다음</button>}
//                     {mode === 2 && <button className='prevnext-button' onClick={handleCompleteClick}>완료</button>}
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default UserSignUpFlow;

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { db, doc, getDoc } from '../firebaseConfig';
import {
  LinearProgress,
  Button,
  Container,
  Box,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Dialog,
  Stack,
  DialogActions,
  DialogContent,
  Grid,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SignUpCreateAccountEmail from './SignUpCreateAccountEmail';
import EmailVerification from './EmailVerification';
import PhoneNumberVerification from './PhoneNumberVerification';
import UserProfile from '../UserProfile';
import UserSignUpConfirm from './UserSignUpConfirm';
import UserSignUpComplete from './UserSignUpComplete';
import SignUpStatus from './SignUpStatus';
import SignUpEditPassword from './SignUpEditPassword';
import '../css/SignUpFlow.css';
import MyTown from './MyTown';

function UserSignUpFlow(props) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [mode, setMode] = useState(props.mode || 0);
  const [activeStep, setActiveStep] = useState(1);
  const steps = [
    t('createAccount'),
    t('verifyEmail'),
    t('verifyPhoneNumber'),
    t('userProfile'),
    t('mytown'),
    t('confirmation'),
    t('signupComplete'),
  ];
  const [isStepCompleted, setIsStepCompleted] = useState(false);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const editStep = useSelector((state) => state.edit.step);

  useEffect(() => {
    setMode(props.mode);
  }, [props.mode]);

  useEffect(() => {
    if (editStep) {
      setActiveStep(editStep - 1); // Adjust index for zero-based array
    } else {
      // handle invalid step or navigate to an error page
    }
  }, [editStep]);

  useEffect(() => {
    const checkPendingRegistration = async () => {
      if (currentUser && mode === 1) {
        const userRef = doc(db, 'users', currentUser.uid);
        const userSnapshot = await getDoc(userRef);

        if (userSnapshot.exists()) {
          const userData = userSnapshot.data();
          if (userData.step && userData.step.trim().length > 0) {
            determineNextStep(userData.step);
          } else {
            determineNextStep(process.env.REACT_APP_STORE_CREATE_ACCOUNT);
          }
        }
      }
    };
    checkPendingRegistration();
  }, [currentUser]);

  const handleNext = () => {
    // if (!isStepCompleted) {
    //   alert('Please complete this step to proceed.');
    //   return;
    // }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setIsStepCompleted(false); // Reset for the next step
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => Math.max(prevActiveStep - 1, 0));
  };

  const handleClose = () => {
    props.closeModal();
    navigate('/');
  };

  const determineNextStep = (step) => {
    const stepMap = {
      [process.env.REACT_APP_USER_CREATE_ACCOUNT]: 0,
      [process.env.REACT_APP_CREATE_ACCOUNT_VERIFIED]: 1,
      [process.env.REACT_APP_EMAIL_VERIFIED]: 2,
      [process.env.REACT_APP_PHONE_NUMBER_VERIFIED]: 3,
      [process.env.REACT_APP_USER_PROFILE_VERIFIED]: 4,
      [process.env.REACT_APP_USER_TOWN_VERIFIED]: 5,
      [process.env.REACT_APP_USER_SIGNUP_CONFIRM]: 6,
      [process.env.REACT_APP_USER_SIGNUP_COMPLETED]: 7,
    };
    setActiveStep(stepMap[step] || 0);
  };

  const renderStepContent = (stepIndex) => {
    const stepProps = {
      onNext: handleNext,
      onPrev: handleBack,
      onClose: handleClose,
      setIsStepCompleted,
      userType: 1,
    };
    switch (stepIndex) {
      case 0:
        return <SignUpCreateAccountEmail {...stepProps} />;
      case 1:
        return <EmailVerification {...stepProps} />;
      case 2:
        return <PhoneNumberVerification {...stepProps} />;
      case 3:
        return <UserProfile {...stepProps} />;
      case 4:
        return <MyTown {...stepProps} />;
      case 5:
        return <UserSignUpConfirm {...stepProps} />;
      case 6:
        return <UserSignUpComplete {...stepProps} />;
      //   case 6:
      //     return (
      //       <SignUpStatus
      //         onNext={handleNext}
      //         setIsStepCompleted={setIsStepCompleted}
      //       />
      //     );
      default:
        return <Typography>Error: Unknown step</Typography>;
    }
  };

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      sx={{ overflowX: 'hidden', width: '100%' }}
    >
      <DialogContent
        dividers
        style={{ minHeight: '60vh', display: 'flex', flexDirection: 'column' }}
      >
        <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 2 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            py: 2,
          }}
        >
          {renderStepContent(activeStep)}
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        {/* <Box
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            width: '100%',
            bgcolor: 'background.paper',
            px: 2,
            py: 1,
            boxShadow: 1,
          }}
        >
          <Grid container spacing={2} justifyContent="center">
            <React.Fragment>
              <Grid item xs={6}>
                {activeStep > 0 && (
                  <Button onClick={handleBack} sx={{ mr: 1 }}>
                    Back
                  </Button>
                )}
              </Grid>
              <Grid item xs={6}>
                {activeStep < steps.length - 1 && (
                  <Button variant="contained" onClick={handleNext}>
                    Next
                  </Button>
                )}
                {activeStep === steps.length - 1 && (
                  <Button variant="contained" onClick={handleClose}>
                    Finish
                  </Button>
                )}
              </Grid>
            </React.Fragment>
          </Grid>
        </Box> */}
        {activeStep >= 0 && (
          <Button variant="outlined" onClick={handleBack} sx={{ mr: 1 }}>
            {t('back')}
          </Button>
        )}
        {activeStep < steps.length - 1 && (
          <Button variant="outlined" onClick={handleNext}>
            {t('next')}
          </Button>
        )}
        {activeStep === steps.length - 1 && (
          <Button variant="outlined" onClick={handleClose}>
            {t('finish')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default UserSignUpFlow;
