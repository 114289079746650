// import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import { useParams, useNavigate } from 'react-router-dom';
// import {
//   db,
//   collection,
//   setDoc,
//   updateDoc,
//   deleteDoc,
//   getDocs,
//   doc,
//   getDoc,
// } from '../../firebaseConfig';
// import CartItem from './CartItem';
// import StorePoints from '../Promotion/Points/StorePoints';
// import useOrder from '../StoreAdmin/useOrder';
// import UserAddress from '../../UserAddress';
// // import '../css/SignUpComplete.css';
// import '../css/CartList.css';
// // import '../../css/detail.css';
// import useStoreUserActivity from '../useStoreUserActivity';
// import useMessageBox from '../../Common/useMessageBox';

// function CartList() {
//   const [MessageBoxInform, MessageBoxConfirm, MessageBoxRender] =
//     useMessageBox();
//   const [appliedCoupons, setAppliedCoupons] = useState({});
//   const navigate = useNavigate();
//   const [selectedAddress, setSelectedAddress] = useState(null);
//   const [selectedItems, setSelectedItems] = useState([]);
//   const [selectAll, setSelectAll] = useState(false);
//   const [store, setStore] = useState(null);
//   const [error, setError] = useState('');
//   const [cartItems, setCartItems] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const { userId, storeId } = useParams();
//   const [usedPoints, setUsedPoints] = useState(0);
//   const [userData, setUserData] = useState({});
//   const currentUser = useSelector((state) => state.auth.currentUser);

//   const { logViewedProduct, logPurchasedProduct, logSearchedKeyword } =
//     useStoreUserActivity(storeId, currentUser?.uid);

//   const { placeOrder, cancelOrder, returnOrder } = useOrder(storeId);

//   const handleCouponChange = (itemId, couponInfo) => {
//     setAppliedCoupons((prev) => ({
//       ...prev,
//       [itemId]: couponInfo,
//     }));
//   };
//   // 현재 사용하지 않음, 상품에 적용된 쿠폰 업데이트
//   const handleUpdateCoupon = (productId, selectedCoupon) => {
//     setAppliedCoupons((prev) => ({
//       ...prev,
//       [productId]: selectedCoupon,
//     }));
//   };
//   // 현재 사용하지 않음, 상품에 적용된 쿠폰 정보 보기
//   const showAppliedCoupons = () => {
//     Object.entries(appliedCoupons).forEach(([productId, coupon]) => {
//       const product = cartItems.find((item) => item.id === productId);
//       console.log(
//         `Product: ${product.productName}, Coupon Applied: ${
//           coupon ? coupon.name : 'None'
//         }`
//       );
//     });
//   };

//   const fetchData = async () => {
//     setLoading(true);

//     try {
//       // Fetching store
//       const storeRef = doc(db, 'stores', storeId);
//       const storeDoc = await getDoc(storeRef);
//       if (storeDoc.exists()) {
//         setStore(storeDoc.data());
//       } else {
//         setError('Store not found');
//       }

//       // Fetching user data
//       const userRef = doc(db, 'users', userId);
//       const userDoc = await getDoc(userRef);
//       if (userDoc.exists()) {
//         // Here, we're only extracting the relevant fields. If you need more fields, add them.
//         // const userData = {
//         //     address: userDoc.data().address,
//         //     extraAddress: userDoc.data().extraAddress,
//         //     zoneCode: userDoc.data().zoneCode,
//         //     additionalAddresses: userDoc.data().additionalAddresses,
//         //     phoneNumber: userDoc.data().phoneNumber,

//         // };
//         // We're saving this user data to the state, but you can also use it directly when creating the orderData.
//         setUserData(userDoc.data());
//       } else {
//         setError('User not found');
//       }

//       // Fetching cart items
//       const cartCollection = collection(
//         db,
//         'stores',
//         storeId,
//         'users',
//         userId,
//         'cart'
//       );
//       const cartSnapshot = await getDocs(cartCollection);
//       const cartItems = cartSnapshot.docs.map((doc) => doc.data());
//       setCartItems(cartItems);
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     } finally {
//       setLoading(false);
//     }
//   };
//   useEffect(() => {
//     fetchData();
//   }, [storeId, userId]);

//   // const totalAmount1 = cartItems.reduce((sum, item) => sum + (item.price * item.quantity - (item.price - item.discountedPrice)), 0); // 총액 계산
//   // const totalAmount = cartItems.reduce((sum, item) => {
//   //     const discountAmount = item.discountedPrice ? item.discountedPrice : 0;
//   //     return sum + (item.price * item.quantity - discountAmount);
//   // }, 0);
//   const results = cartItems
//     .filter((item) => selectedItems.includes(item.productId))
//     .reduce(
//       (acc, item) => {
//         const discount = item.discountedPrice ? item.discountedPrice : 0;
//         const couponPrice = item.couponPrice ? item.couponPrice : 0;
//         acc.discountAmount += discount;
//         acc.couponAmount += couponPrice;
//         acc.totalAmount += item.price * item.quantity;
//         return acc;
//       },
//       { totalAmount: 0, discountAmount: 0, couponAmount: 0 }
//     );

//   const totalAmount = results.totalAmount;
//   const finalAmount =
//     results.totalAmount -
//     usedPoints -
//     results.discountAmount -
//     results.couponAmount;
//   const discountAmount = results.discountAmount;
//   const couponAmount = results.couponAmount;

//   if (loading) {
//     return <div>로딩 중...</div>;
//   }

//   if (!cartItems.length) {
//     return (
//       <div className="cart">
//         <div className="container">
//           <h2>장바구니가 비어있습니다.</h2>
//           User ID: {userId}, Store ID: {storeId}
//           <p>원하는 상품을 장바구니에 추가해보세요!</p>
//           <button className="btn btn-primary" onClick={() => navigate(-1)}>
//             이전 페이지로 돌아가기
//           </button>
//         </div>
//       </div>
//     );
//   }

//   const toggleItemSelection = (productId) => {
//     let updatedSelectedItems;

//     if (selectedItems.includes(productId)) {
//       updatedSelectedItems = selectedItems.filter((id) => id !== productId);
//     } else {
//       updatedSelectedItems = [...selectedItems, productId];
//     }

//     setSelectedItems(updatedSelectedItems);

//     // Update the selectAll state based on whether all items are selected
//     setSelectAll(updatedSelectedItems.length === cartItems.length);
//   };

//   const handleSelectAll = () => {
//     if (selectAll) {
//       setSelectedItems([]); // Deselect all
//     } else {
//       setSelectedItems(cartItems.map((item) => item.productId)); // Select all
//     }
//     setSelectAll(!selectAll); // Toggle the state
//   };

//   const removeFromCart = async (productId) => {
//     try {
//       const cartRef = doc(
//         db,
//         'stores',
//         storeId,
//         'users',
//         userId,
//         'cart',
//         productId
//       );
//       await deleteDoc(cartRef);

//       // 상태 업데이트: 해당 productId를 제외한 아이템들만 남김
//       setCartItems((prevItems) =>
//         prevItems.filter((item) => item.productId !== productId)
//       );
//     } catch (error) {
//       console.error('Error removing item from cart:', error);
//     }
//   };

//   const updateCouponPrice = async (item, newCouponPrice) => {
//     try {
//       const cartRef = doc(
//         db,
//         'stores',
//         storeId,
//         'users',
//         userId,
//         'cart',
//         item.productId
//       );
//       await updateDoc(cartRef, {
//         couponPrice: newCouponPrice,
//       });

//       // 상태 업데이트: productId에 해당하는 아이템의 discountedPrice를 변경
//       setCartItems((prevItems) =>
//         prevItems.map((prevItem) =>
//           prevItem.productId === item.productId
//             ? { ...prevItem, couponPrice: newCouponPrice }
//             : prevItem
//         )
//       );
//     } catch (error) {
//       console.error('Error updating coupon price:', error);
//     }
//   };

//   const updateDiscountedPrice = async (item, newDiscountedPrice) => {
//     try {
//       const cartRef = doc(
//         db,
//         'stores',
//         storeId,
//         'users',
//         userId,
//         'cart',
//         item.productId
//       );
//       await updateDoc(cartRef, {
//         discountedPrice: newDiscountedPrice,
//       });

//       // 상태 업데이트: productId에 해당하는 아이템의 discountedPrice를 변경
//       setCartItems((prevItems) =>
//         prevItems.map((prevItem) =>
//           prevItem.productId === item.productId
//             ? { ...prevItem, discountedPrice: newDiscountedPrice }
//             : prevItem
//         )
//       );
//     } catch (error) {
//       console.error('Error updating discounted price:', error);
//     }
//   };

//   const updateQuantity = async (productId, newQuantity) => {
//     try {
//       const cartRef = doc(
//         db,
//         'stores',
//         storeId,
//         'users',
//         userId,
//         'cart',
//         productId
//       );
//       await updateDoc(cartRef, {
//         quantity: newQuantity,
//       });

//       // 상태 업데이트: productId에 해당하는 아이템의 수량을 변경
//       setCartItems((prevItems) =>
//         prevItems.map((item) =>
//           item.productId === productId
//             ? {
//                 ...item,
//                 quantity: newQuantity,
//                 totalAmount: item.price * newQuantity,
//                 finalAmount:
//                   item.price * newQuantity -
//                   (item.price - item.discountedPrice - item.couponPrice),
//               }
//             : item
//         )
//       );
//     } catch (error) {
//       console.error('Error updating item quantity:', error);
//     }
//   };

//   async function createOrderData() {
//     // 먼저 모든 상품 정보를 비동기적으로 가져옵니다.
//     const cartCollection = collection(
//       db,
//       'stores',
//       storeId,
//       'users',
//       userId,
//       'cart'
//     );
//     const cartSnapshot = await getDocs(cartCollection);
//     const selectedCartItems = cartSnapshot.docs.filter((doc) =>
//       selectedItems.includes(doc.data().productId)
//     );
//     const productDataPromises = selectedCartItems.map(async (item) => {
//       const productRef = doc(db, 'stores', storeId, 'products', item.id);
//       const productSnapshot = await getDoc(productRef);
//       return productSnapshot.data();
//     });

//     const allProductData = await Promise.all(productDataPromises);

//     const orderData = {
//       userId: userId,
//       storeId: storeId,
//       items: selectedCartItems.map((item, index) => {
//         const productData = allProductData[index];
//         const cartItemData = item.data();
//         const couponPrice = (() => {
//           const originalPrice = cartItemData.price; // If needed
//           const couponData = appliedCoupons[item.id]?.selectedCoupon;
//           if (couponData) {
//             if (couponData.discountType === 'percentage') {
//               return originalPrice * (couponData.discount / 100);
//             } else if (couponData.discountType === 'amount') {
//               return couponData.discount;
//             }
//           }
//           return 0;
//         })();
//         return {
//           productId: item.id,
//           quantity: cartItemData.quantity,
//           price: cartItemData.price,
//           couponPrice: couponPrice,
//           discountedPrice: cartItemData.discountedPrice || 0,
//           selectedCoupon: appliedCoupons[item.id]?.selectedCoupon || null,
//           productDetails: {
//             // 선택한 제품의 상세 정보
//             name: productData.productName,
//             description: productData.description,
//             imageUrl:
//               Array.isArray(productData.small_images) &&
//               productData.small_images.length > 0
//                 ? productData.small_images[0]
//                 : null,
//           },
//           totalPrice: cartItemData.totalPrice,
//           finalPrice:
//             cartItemData.totalPrice -
//             (cartItemData.discountedPrice || 0) -
//             (cartItemData.couponPrice || 0),
//         };
//       }),
//       usedPoints: usedPoints,
//       totalAmount: totalAmount,
//       finalAmount: finalAmount,
//       discountAmount: discountAmount,
//       couponAmount: couponAmount,
//       dateTime: new Date(),
//       shippingDetails: {
//         address: selectedAddress?.address,
//         extraAddress: selectedAddress?.extraAddress,
//         phoneNumber: userData?.phoneNumber,
//         deliveryDate: 'preferredDeliveryDate',
//       },
//     };

//     return {
//       orderData: orderData,
//       selectedCartItems: selectedCartItems,
//     };
//   }

//   const handlePlaceOrder = async () => {
//     if (loading) return;
//     setLoading(true);
//     try {
//       const { orderData, selectedCartItems } = await createOrderData();

//       dispatchPlaceOrder(orderData);

//       for (const item of selectedCartItems) {
//         await logPurchasedProduct(currentUser?.uid, item.productId);
//         await deleteDoc(item.ref);
//       }
//     } catch (error) {
//       console.error('Error during payment:', error);
//     } finally {
//       setLoading(false);
//       fetchData();
//       await MessageBoxInform('주문이 정상적으로 처리되었습니다.');
//     }
//   };
//   const dispatchPlaceOrder = (orderData) => {
//     //dispatch(placeOrder(orderData));
//     placeOrder(orderData);
//   };
//   const handleSelectedAddress = (address) => {
//     setSelectedAddress(address);
//   };
//   const handleUpdateAddress = async (updatedUser) => {
//     try {
//       const userRef = doc(db, 'users', userId);
//       await setDoc(userRef, updatedUser, { merge: true }); // Document 업데이트
//       setUserData(updatedUser);
//       await MessageBoxInform('주소 정보가 업데이트되었습니다.');
//     } catch (error) {
//       console.error('Error updating address:', error);
//       await MessageBoxInform(
//         '주소 정보를 업데이트하는 중 오류가 발생했습니다.'
//       );
//     }
//   };

//   return (
//     <div className="cart">
//       <div className="container">
//         <div className="cart-header">
//           <h3>나의 장바구니 - {store.storeName}</h3>
//         </div>
//         <div className="cart-items">
//           <input
//             type="checkbox"
//             checked={selectAll}
//             onChange={handleSelectAll}
//           />
//           전체 선택
//           {cartItems.length === 0 ? (
//             <p>장바구니가 비어있습니다.</p>
//           ) : (
//             cartItems.map((cartItem) => {
//               if (!cartItem.productId || !cartItem.hasOwnProperty('quantity')) {
//                 console.error('CartItem lacks necessary data:', cartItem);
//                 return (
//                   <div key={cartItem.productId || cartItem.id || Math.random()}>
//                     <p>
//                       Some information about this item is missing. Please
//                       contact support if you think this is an error.
//                     </p>
//                   </div>
//                 );
//               }
//               return (
//                 <CartItem
//                   key={cartItem.productId}
//                   item={cartItem}
//                   onCouponChange={(couponInfo) =>
//                     handleCouponChange(cartItem.productId, couponInfo)
//                   }
//                   isSelected={selectedItems.includes(cartItem.productId)}
//                   onToggleSelect={() => toggleItemSelection(cartItem.productId)}
//                   onRemove={() => removeFromCart(cartItem.productId)}
//                   onUpdateQuantity={(quantity) =>
//                     updateQuantity(cartItem.productId, quantity)
//                   }
//                   onUpdateCouponPrice={(couponPrice) =>
//                     updateCouponPrice(cartItem, couponPrice)
//                   }
//                   onUpdateDiscountedPrice={(discountedPrice) =>
//                     updateDiscountedPrice(cartItem, discountedPrice)
//                   }
//                 />
//               );
//             })
//           )}
//         </div>
//         {MessageBoxRender()}
//         <div className="flex-end">
//           <StorePoints
//             storeId={storeId}
//             userId={userId}
//             onUsedPoints={(newPoints) =>
//               setUsedPoints((prevPoints) => prevPoints + newPoints)
//             }
//           />
//         </div>
//         <div className="flex-end">
//           <UserAddress
//             user={userData}
//             onUpdate={handleUpdateAddress}
//             onAddressSelect={handleSelectedAddress}
//           />
//         </div>
//         <div className="flex-end">
//           <strong>결제금액: {finalAmount}원</strong>

//           <button className="purchase-button" onClick={handlePlaceOrder}>
//             구매하기
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// }
// export default CartList;

// import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import { useParams, useNavigate } from 'react-router-dom';
// import {
//   db,
//   collection,
//   setDoc,
//   updateDoc,
//   deleteDoc,
//   getDocs,
//   doc,
//   getDoc,
// } from '../../firebaseConfig';
// import moment from 'moment';
// import CartItem from './CartItem';
// import StorePoints from '../Promotion/Points/StorePoints';
// import UserAddress from '../../UserAddress';
// import useMessageBox from '../../Common/useMessageBox';
// import useStoreUserActivity from '../useStoreUserActivity';
// import useOrder from '../StoreAdmin/useOrder';
// import { useTranslation } from 'react-i18next';
// import {
//   Container,
//   Typography,
//   Button,
//   Checkbox,
//   CircularProgress,
//   Box,
//   Alert,
//   FormControlLabel,
//   Paper,
//   Grid,
//   Dialog,
//   DialogContent,
//   DialogActions,
// } from '@mui/material';

// function CartList({ userIdProp, storeIdProp }) {
//   const { t, i18n } = useTranslation();
//   const [MessageBoxInform, MessageBoxConfirm, MessageBoxRender] =
//     useMessageBox();
//   const [appliedCoupons, setAppliedCoupons] = useState({});
//   const navigate = useNavigate();
//   const [selectedAddress, setSelectedAddress] = useState(null);

//   const [openAddressDialog, setOpenAddressDialog] = useState(false);
//   const [selectedItems, setSelectedItems] = useState([]);
//   const [selectAll, setSelectAll] = useState(false);
//   const [store, setStore] = useState(null);
//   const [error, setError] = useState('');
//   const [cartItems, setCartItems] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const { userId: userIdRoute, storeId: storeIdRoute } = useParams();
//   const [usedPoints, setUsedPoints] = useState(0);
//   const [userData, setUserData] = useState({});

//   const currentUser = useSelector((state) => state.auth.currentUser);

//   const userId = userIdProp || userIdRoute;
//   const storeId = storeIdProp || storeIdRoute;

//   const { logViewedProduct, logPurchasedProduct, logSearchedKeyword } =
//     useStoreUserActivity(storeId, currentUser?.uid);

//   const { placeOrder, cancelOrder, returnOrder } = useOrder(storeId);

//   useEffect(() => {
//     fetchCartItems();
//   }, [storeId, userId]);

//   useEffect(() => {
//     const fetchUserData = async () => {
//       setLoading(true);
//       try {
//         const userRef = doc(db, 'users', userId);
//         const userSnap = await getDoc(userRef);
//         if (userSnap.exists()) {
//           setUserData(userSnap.data());
//           setSelectedAddress({
//             zoneCode: userSnap.data()?.zoneCode || '',
//             address: userSnap.data()?.address || '',
//             extraAddress: userSnap.data()?.extraAddress || '',
//             additionalAddresses: userSnap.data()?.additionalAddresses || [],
//           });
//         }

//         // Additional fetch operations...
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       } finally {
//         setLoading(false);
//       }
//     };
//     fetchUserData();
//   }, [userId]);

//   const fetchCartItems = async () => {
//     setLoading(true);

//     try {
//       // Fetching store
//       const storeRef = doc(db, 'stores', storeId);
//       const storeDoc = await getDoc(storeRef);
//       if (storeDoc.exists()) {
//         const rawData = storeDoc.data();
//         const formattedStore = {
//           id: storeDoc.id,
//           ...rawData,
//           date: rawData.createDate
//             ? moment(rawData.createDate.toDate()).fromNow()
//             : null,
//         };
//         setStore(formattedStore);
//       } else {
//         setError('Store not found');
//       }

//       // Fetching user data
//       const userRef = doc(db, 'users', userId);
//       const userDoc = await getDoc(userRef);
//       if (userDoc.exists()) {
//         // Here, we're only extracting the relevant fields. If you need more fields, add them.
//         // const userData = {
//         //     address: userDoc.data().address,
//         //     extraAddress: userDoc.data().extraAddress,
//         //     zoneCode: userDoc.data().zoneCode,
//         //     additionalAddresses: userDoc.data().additionalAddresses,
//         //     phoneNumber: userDoc.data().phoneNumber,

//         // };
//         // We're saving this user data to the state, but you can also use it directly when creating the orderData.
//         const rawData = userDoc.data();
//         const formattedUserData = {
//           id: userDoc.id,
//           ...rawData,
//           date: rawData.joinDate
//             ? moment(rawData.joinDate.toDate()).fromNow()
//             : null,
//         };
//         // setUserData(formattedUserData);
//         setUserData(userDoc.data());
//       } else {
//         setError('User not found');
//       }

//       // Fetching cart items
//       const cartCollection = collection(
//         db,
//         'stores',
//         storeId,
//         'users',
//         userId,
//         'cart'
//       );
//       const cartSnapshot = await getDocs(cartCollection);
//       const cartItems = cartSnapshot.docs.map((doc) => doc.data());
//       setCartItems(cartItems);
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleCouponChange = (itemId, couponInfo) => {
//     setAppliedCoupons((prev) => ({
//       ...prev,
//       [itemId]: couponInfo,
//     }));
//   };
//   // 현재 사용하지 않음, 상품에 적용된 쿠폰 업데이트
//   const handleUpdateCoupon = (productId, selectedCoupon) => {
//     setAppliedCoupons((prev) => ({
//       ...prev,
//       [productId]: selectedCoupon,
//     }));
//   };
//   // 현재 사용하지 않음, 상품에 적용된 쿠폰 정보 보기
//   const showAppliedCoupons = () => {
//     Object.entries(appliedCoupons).forEach(([productId, coupon]) => {
//       const product = cartItems.find((item) => item.id === productId);
//       console.log(
//         `Product: ${product.productName}, Coupon Applied: ${
//           coupon ? coupon.name : 'None'
//         }`
//       );
//     });
//   };

//   // const totalAmount1 = cartItems.reduce((sum, item) => sum + (item.price * item.quantity - (item.price - item.discountedPrice)), 0); // 총액 계산
//   // const totalAmount = cartItems.reduce((sum, item) => {
//   //     const discountAmount = item.discountedPrice ? item.discountedPrice : 0;
//   //     return sum + (item.price * item.quantity - discountAmount);
//   // }, 0);
//   const results = cartItems
//     .filter((item) => selectedItems.includes(item.productId))
//     .reduce(
//       (acc, item) => {
//         const discount = item.discountedPrice ? item.discountedPrice : 0;
//         const couponPrice = item.couponPrice ? item.couponPrice : 0;
//         acc.discountAmount += discount;
//         acc.couponAmount += couponPrice;
//         acc.totalAmount += item.price * item.quantity;
//         return acc;
//       },
//       { totalAmount: 0, discountAmount: 0, couponAmount: 0 }
//     );

//   const totalAmount = results.totalAmount;
//   const finalAmount =
//     results.totalAmount -
//     usedPoints -
//     results.discountAmount -
//     results.couponAmount;
//   const discountAmount = results.discountAmount;
//   const couponAmount = results.couponAmount;

//   if (loading) {
//     return <div>로딩 중...</div>;
//   }

//   if (!cartItems.length) {
//     return (
//       <Box
//         p={3}
//         sx={{
//           width: '100%',
//           maxWidth: '90%',
//           margin: 'auto',
//           marginBottom: '80px',
//         }}
//       >
//         <Typography variant="h4" gutterBottom sx={{ mb: 3 }}>
//           {store?.storeName}
//         </Typography>
//         <Container>
//           <Typography variant="h5">{t('cartEmptyMessage')}</Typography>
//           {/* <Typography>
//             {t('userId')}: {userId}, {t('storeId')}: {storeId}
//           </Typography> */}
//           <Typography>{t('addItemsToCartMessage')}</Typography>
//           <Button
//             variant="contained"
//             onClick={() => navigate(`/store_home/${store.id}`)}
//           >
//             {t('goBackToStore')}
//           </Button>
//         </Container>
//       </Box>
//     );
//   }

//   const toggleItemSelection = (productId) => {
//     let updatedSelectedItems;

//     if (selectedItems.includes(productId)) {
//       updatedSelectedItems = selectedItems.filter((id) => id !== productId);
//     } else {
//       updatedSelectedItems = [...selectedItems, productId];
//     }

//     setSelectedItems(updatedSelectedItems);

//     // Update the selectAll state based on whether all items are selected
//     setSelectAll(updatedSelectedItems.length === cartItems.length);
//   };

//   const handleSelectAll = () => {
//     if (selectAll) {
//       setSelectedItems([]); // Deselect all
//     } else {
//       setSelectedItems(cartItems.map((item) => item.productId)); // Select all
//     }
//     setSelectAll(!selectAll); // Toggle the state
//   };

//   const removeFromCart = async (productId) => {
//     try {
//       const cartRef = doc(
//         db,
//         'stores',
//         storeId,
//         'users',
//         userId,
//         'cart',
//         productId
//       );
//       await deleteDoc(cartRef);

//       // 상태 업데이트: 해당 productId를 제외한 아이템들만 남김
//       setCartItems((prevItems) =>
//         prevItems.filter((item) => item.productId !== productId)
//       );
//     } catch (error) {
//       console.error('Error removing item from cart:', error);
//     }
//   };

//   const updateCouponPrice = async (item, newCouponPrice) => {
//     try {
//       const cartRef = doc(
//         db,
//         'stores',
//         storeId,
//         'users',
//         userId,
//         'cart',
//         item.productId
//       );
//       await updateDoc(cartRef, {
//         couponPrice: newCouponPrice,
//       });

//       // 상태 업데이트: productId에 해당하는 아이템의 discountedPrice를 변경
//       setCartItems((prevItems) =>
//         prevItems.map((prevItem) =>
//           prevItem.productId === item.productId
//             ? { ...prevItem, couponPrice: newCouponPrice }
//             : prevItem
//         )
//       );
//     } catch (error) {
//       console.error('Error updating coupon price:', error);
//     }
//   };

//   const updateDiscountedPrice = async (item, newDiscountedPrice) => {
//     try {
//       const cartRef = doc(
//         db,
//         'stores',
//         storeId,
//         'users',
//         userId,
//         'cart',
//         item.productId
//       );
//       await updateDoc(cartRef, {
//         discountedPrice: newDiscountedPrice,
//       });

//       // 상태 업데이트: productId에 해당하는 아이템의 discountedPrice를 변경
//       setCartItems((prevItems) =>
//         prevItems.map((prevItem) =>
//           prevItem.productId === item.productId
//             ? { ...prevItem, discountedPrice: newDiscountedPrice }
//             : prevItem
//         )
//       );
//     } catch (error) {
//       console.error('Error updating discounted price:', error);
//     }
//   };

//   const updateQuantity = async (productId, newQuantity) => {
//     try {
//       const cartRef = doc(
//         db,
//         'stores',
//         storeId,
//         'users',
//         userId,
//         'cart',
//         productId
//       );
//       await updateDoc(cartRef, {
//         quantity: newQuantity,
//       });

//       // 상태 업데이트: productId에 해당하는 아이템의 수량을 변경
//       setCartItems((prevItems) =>
//         prevItems.map((item) =>
//           item.productId === productId
//             ? {
//                 ...item,
//                 quantity: newQuantity,
//                 totalAmount: item.price * newQuantity,
//                 finalAmount:
//                   item.price * newQuantity -
//                   (item.price - item.discountedPrice - item.couponPrice),
//               }
//             : item
//         )
//       );
//     } catch (error) {
//       console.error('Error updating item quantity:', error);
//     }
//   };

//   async function createOrderData() {
//     // 먼저 모든 상품 정보를 비동기적으로 가져옵니다.
//     const cartCollection = collection(
//       db,
//       'stores',
//       storeId,
//       'users',
//       userId,
//       'cart'
//     );
//     const cartSnapshot = await getDocs(cartCollection);
//     const selectedCartItems = cartSnapshot.docs.filter((doc) =>
//       selectedItems.includes(doc.data().productId)
//     );
//     const productDataPromises = selectedCartItems.map(async (item) => {
//       const productRef = doc(db, 'stores', storeId, 'products', item.id);
//       const productSnapshot = await getDoc(productRef);
//       return productSnapshot.data();
//     });

//     const allProductData = await Promise.all(productDataPromises);

//     const orderData = {
//       userId: userId,
//       storeId: storeId,
//       items: selectedCartItems.map((item, index) => {
//         const productData = allProductData[index];
//         const cartItemData = item.data();
//         const couponPrice = (() => {
//           const originalPrice = cartItemData.price; // If needed
//           const couponData = appliedCoupons[item.id]?.selectedCoupon;
//           if (couponData) {
//             if (couponData.discountType === 'percentage') {
//               return originalPrice * (couponData.discount / 100);
//             } else if (couponData.discountType === 'amount') {
//               return couponData.discount;
//             }
//           }
//           return 0;
//         })();
//         return {
//           productId: item.id,
//           quantity: cartItemData.quantity,
//           price: cartItemData.price,
//           couponPrice: couponPrice,
//           discountedPrice: cartItemData.discountedPrice || 0,
//           selectedCoupon: appliedCoupons[item.id]?.selectedCoupon || null,
//           productDetails: {
//             // 선택한 제품의 상세 정보
//             name: productData.productName,
//             description: productData.description,
//             imageUrl:
//               Array.isArray(productData.icon_images) &&
//               productData.icon_images.length > 0
//                 ? productData.icon_images[0]
//                 : null,
//           },
//           totalPrice: cartItemData.totalPrice,
//           finalPrice:
//             cartItemData.totalPrice -
//             (cartItemData.discountedPrice || 0) -
//             (cartItemData.couponPrice || 0),
//         };
//       }),
//       usedPoints: usedPoints,
//       totalAmount: totalAmount,
//       finalAmount: finalAmount,
//       discountAmount: discountAmount,
//       couponAmount: couponAmount,
//       dateTime: new Date(),
//       shippingDetails: {
//         address: selectedAddress?.address,
//         extraAddress: selectedAddress?.extraAddress,
//         phoneNumber: userData?.phoneNumber,
//         deliveryDate: 'preferredDeliveryDate',
//       },
//     };

//     return {
//       orderData: orderData,
//       selectedCartItems: selectedCartItems,
//     };
//   }

//   const handlePlaceOrder = async () => {
//     if (loading) return;
//     if (!selectedAddress) {
//       setOpenAddressDialog(true); // Prompt user to select address if not set
//       return; // 주소가 선택되지 않았다면 여기서 함수 실행을 중단
//     }
//     if (selectedItems.length === 0) {
//       MessageBoxInform('구매할 상품을 선택해주세요.');
//       return;
//     }
//     setLoading(true);
//     try {
//       const { orderData, selectedCartItems } = await createOrderData();
//       dispatchPlaceOrder(orderData);

//       for (const item of selectedCartItems) {
//         await logPurchasedProduct(currentUser?.uid, item.productId);
//         await deleteDoc(item.ref);
//       }
//       await MessageBoxInform('주문이 정상적으로 처리되었습니다.');
//     } catch (error) {
//       console.error('Error during payment:', error);
//     } finally {
//       setLoading(false);
//       fetchCartItems();
//     }
//   };
//   const dispatchPlaceOrder = (orderData) => {
//     //dispatch(placeOrder(orderData));
//     placeOrder(orderData);
//   };
//   const handleOpenAddressDialog = () => {
//     setOpenAddressDialog(true);
//   };
//   // 주소가 선택되었을 때 호출되는 함수
//   const handleSelectedAddress = (address) => {
//     setSelectedAddress(address);
//     // setAddressDialogOpen(false);
//   };

//   const handleUpdateAddress = async (updatedUser) => {
//     try {
//       const userRef = doc(db, 'users', userId);
//       const userDoc = await getDoc(userRef);
//       if (userDoc.exists()) {
//         await updateDoc(userRef, updatedUser); // Update the existing document
//       } else {
//         await setDoc(userRef, updatedUser, { merge: true }); // Create a new document with merge option
//       }
//       setSelectedAddress({
//         zoneCode: updatedUser.zoneCode || '',
//         address: updatedUser.address || '',
//         extraAddress: updatedUser.extraAddress || '',
//         additionalAddresses: updatedUser.additionalAddresses || [],
//       });
//       setUserData(updatedUser);
//       await MessageBoxInform('주소 정보가 업데이트되었습니다.');
//     } catch (error) {
//       console.error('Error updating address:', error);
//       await MessageBoxInform(
//         '주소 정보를 업데이트하는 중 오류가 발생했습니다.'
//       );
//     }
//   };

//   const handleCloseAddressDialog = () => {
//     setOpenAddressDialog(false);
//   };

//   // Fetching data function and other methods remain unchanged

//   if (loading) {
//     return <CircularProgress />;
//   }

//   if (!cartItems.length) {
//     return (
//       <Box
//         p={3}
//         sx={{
//           width: '100%',
//           maxWidth: '100%',
//           margin: 'auto',
//         }}
//       >
//         <Typography variant="h4" gutterBottom sx={{ mb: 3 }}>
//           {store?.storeName}
//         </Typography>
//         <Container>
//           <Typography variant="h5">{t('cartEmptyMessage')}</Typography>
//           <Typography>
//             {t('userId')}: {userId}, {t('storeId')}: {storeId}
//           </Typography>
//           <Typography>{t('addItemsToCartMessage')}</Typography>
//           <Button
//             variant="contained"
//             onClick={() => navigate(`/store_home/${store.id}`)}
//           >
//             {t('goBackToStore')}
//           </Button>
//         </Container>
//       </Box>
//     );
//   }

//   return (
//     <Box
//       p={3}
//       sx={{
//         width: '100%',
//         maxWidth: '90%',
//         margin: 'auto',
//         marginBottom: '80px',
//       }}
//     >
//       <Typography variant="h4" gutterBottom>
//         {store?.storeName}
//       </Typography>
//       <FormControlLabel
//         control={<Checkbox checked={selectAll} onChange={handleSelectAll} />}
//         label={t('selectAll')}
//         sx={{ marginY: 2 }}
//       />
//       <Grid container spacing={2}>
//         {cartItems.map((cartItem) => (
//           <Grid item xs={12} key={cartItem.productId}>
//             <CartItem
//               item={cartItem}
//               onCouponChange={(couponInfo) =>
//                 handleCouponChange(cartItem.productId, couponInfo)
//               }
//               isSelected={selectedItems.includes(cartItem.productId)}
//               onToggleSelect={() => toggleItemSelection(cartItem.productId)}
//               onRemove={() => removeFromCart(cartItem.productId)}
//               onUpdateQuantity={(quantity) =>
//                 updateQuantity(cartItem.productId, quantity)
//               }
//               onUpdateCouponPrice={(couponPrice) =>
//                 updateCouponPrice(cartItem, couponPrice)
//               }
//               onUpdateDiscountedPrice={(discountedPrice) =>
//                 updateDiscountedPrice(cartItem, discountedPrice)
//               }
//             />
//           </Grid>
//         ))}
//       </Grid>
//       {MessageBoxRender()}
//       <Paper
//         elevation={3} // Gives a subtle shadow to the paper component for better visual separation
//         sx={{
//           p: 3, // More padding for better spacing
//           mt: 0, // Adds top margin for spacing from preceding content
//           mb: 0, // Adds bottom margin for spacing from following content
//         }}
//       >
//         <Grid container spacing={1} alignItems="center">
//           <Grid item xs={12} sm={4}>
//             {/* <Typography variant="subtitle1" component="div">
//               사용 가능한 포인트:
//             </Typography> */}
//             <StorePoints
//               storeId={storeId}
//               userId={userId}
//               onUsedPoints={(newPoints) =>
//                 setUsedPoints((prevPoints) => prevPoints + newPoints)
//               }
//             />
//           </Grid>
//           <Grid item xs={12} sm={4} sx={{ textAlign: 'center' }}>
//             <Typography variant="h6">
//               {t('paymentAmount')}: {finalAmount.toLocaleString()}{' '}
//               {t('currency')}
//             </Typography>
//             {selectedAddress && (
//               <Typography>
//                 {t('deliveryAddress')}: {selectedAddress.zoneCode}{' '}
//                 {selectedAddress.address}{' '}
//                 {selectedAddress.extraAddress &&
//                   `(${selectedAddress.extraAddress})`}
//               </Typography>
//             )}
//           </Grid>
//           <Grid item xs={12} sm={4} sx={{ textAlign: 'right' }}>
//             <Grid item xs={12} sx={{ marginBottom: 1 }}>
//               <Button
//                 variant="contained"
//                 color="primary"
//                 onClick={handleOpenAddressDialog}
//                 sx={{ width: { xs: '100%', sm: 'auto' } }} // Button takes full width on extra-small devices
//               >
//                 {t('changeAddress')}
//               </Button>
//             </Grid>
//             <Grid item xs={12}>
//               <Button
//                 variant="contained"
//                 color="primary"
//                 onClick={handlePlaceOrder}
//                 sx={{ width: { xs: '100%', sm: 'auto' } }} // Button takes full width on extra-small devices
//               >
//                 {t('purchase')}
//               </Button>
//             </Grid>
//             <Dialog
//               open={openAddressDialog}
//               onClose={handleCloseAddressDialog}
//               fullWidth
//               maxWidth="sm"
//             >
//               <DialogContent
//                 dividers
//                 style={{
//                   overflowY: 'auto', // 필요할 경우 세로 스크롤 활성화
//                   display: 'flex',
//                   flexDirection: 'column',
//                 }}
//               >
//                 <UserAddress
//                   user={userData}
//                   onUpdate={handleUpdateAddress}
//                   onAddressSelect={handleSelectedAddress}
//                 />
//               </DialogContent>
//               <DialogActions sx={{ justifyContent: 'flex-end' }}>
//                 <Button
//                   variant="outlined"
//                   onClick={handleCloseAddressDialog}
//                   sx={{ mr: 2, mb: 1, mt: 1 }}
//                 >
//                   {t('close')}
//                 </Button>
//               </DialogActions>
//             </Dialog>
//           </Grid>
//         </Grid>
//       </Paper>
//       {/* <Paper variant="outlined" sx={{ padding: 2, marginTop: 2 }}>
//         <UserAddress
//           user={userData}
//           onUpdate={handleUpdateAddress}
//           onAddressSelect={handleSelectedAddress}
//         />
//       </Paper> */}
//     </Box>
//   );
// }

// export default CartList;

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import {
  db,
  collection,
  setDoc,
  updateDoc,
  deleteDoc,
  getDocs,
  doc,
  getDoc,
} from '../../firebaseConfig';
import moment from 'moment';
import CartItem from './CartItem';
import StorePoints from '../Promotion/Points/StorePoints';
import UserAddress from '../../UserAddress';
import useMessageBox from '../../Common/useMessageBox';
import useStoreUserActivity from '../useStoreUserActivity';
import useOrder from '../StoreAdmin/useOrder';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Typography,
  Button,
  Checkbox,
  CircularProgress,
  Box,
  FormControlLabel,
  Paper,
  Grid,
  Dialog,
  DialogContent,
  DialogActions,
} from '@mui/material';

function CartList({ userIdProp, storeIdProp }) {
  const { t, i18n } = useTranslation();
  const [MessageBoxInform, MessageBoxConfirm, MessageBoxRender] =
    useMessageBox();
  const [appliedCoupons, setAppliedCoupons] = useState({});
  const navigate = useNavigate();
  const [selectedAddress, setSelectedAddress] = useState(null);

  const [openAddressDialog, setOpenAddressDialog] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [store, setStore] = useState(null);
  const [error, setError] = useState('');
  const [cartItems, setCartItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const { userId: userIdRoute, storeId: storeIdRoute } = useParams();
  const [usedPoints, setUsedPoints] = useState(0);
  const [userData, setUserData] = useState({});

  const currentUser = useSelector((state) => state.auth.currentUser);

  const userId = userIdProp || userIdRoute;
  const storeId = storeIdProp || storeIdRoute;

  const { logPurchasedProduct } = useStoreUserActivity(
    storeId,
    currentUser?.uid
  );

  const { placeOrder } = useOrder(storeId);

  useEffect(() => {
    fetchCartItems();
  }, [storeId, userId]);

  useEffect(() => {
    const fetchUserData = async () => {
      setLoading(true);
      try {
        const userRef = doc(db, 'users', userId);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          setUserData(userSnap.data());
          setSelectedAddress({
            zoneCode: userSnap.data()?.zoneCode || '',
            address: userSnap.data()?.address || '',
            extraAddress: userSnap.data()?.extraAddress || '',
            additionalAddresses: userSnap.data()?.additionalAddresses || [],
          });
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchUserData();
  }, [userId]);

  const fetchCartItems = async () => {
    setLoading(true);

    try {
      const storeRef = doc(db, 'stores', storeId);
      const storeDoc = await getDoc(storeRef);
      if (storeDoc.exists()) {
        const rawData = storeDoc.data();
        const formattedStore = {
          id: storeDoc.id,
          ...rawData,
          date: rawData.createDate
            ? moment(rawData.createDate.toDate()).fromNow()
            : null,
        };
        setStore(formattedStore);
      } else {
        setError('Store not found');
      }

      const userRef = doc(db, 'users', userId);
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        const rawData = userDoc.data();
        const formattedUserData = {
          id: userDoc.id,
          ...rawData,
          date: rawData.joinDate
            ? moment(rawData.joinDate.toDate()).fromNow()
            : null,
        };
        setUserData(userDoc.data());
      } else {
        setError('User not found');
      }

      const cartCollection = collection(
        db,
        'stores',
        storeId,
        'users',
        userId,
        'cart'
      );
      const cartSnapshot = await getDocs(cartCollection);
      const cartItems = cartSnapshot.docs.map((doc) => doc.data());
      setCartItems(cartItems);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCouponChange = (itemId, couponInfo) => {
    setAppliedCoupons((prev) => ({
      ...prev,
      [itemId]: couponInfo,
    }));
  };

  const handleUpdateCoupon = (productId, selectedCoupon) => {
    setAppliedCoupons((prev) => ({
      ...prev,
      [productId]: selectedCoupon,
    }));
  };

  const showAppliedCoupons = () => {
    Object.entries(appliedCoupons).forEach(([productId, coupon]) => {
      const product = cartItems.find((item) => item.id === productId);
      console.log(
        `Product: ${product.productName}, Coupon Applied: ${
          coupon ? coupon.name : 'None'
        }`
      );
    });
  };

  const results = cartItems
    .filter((item) => selectedItems.includes(item.productId))
    .reduce(
      (acc, item) => {
        const discountedPrice = item.discountedPrice
          ? item.discountedPrice
          : item.price;
        const couponDiscount = item.couponDiscount ? item.couponDiscount : 0;
        acc.discountAmount += (item.price - discountedPrice) * item.quantity;
        acc.couponAmount += couponDiscount * item.quantity;
        acc.finalTotalPrice += item.finalTotalPrice;
        acc.totalAmount += item.price * item.quantity;
        return acc;
      },
      { totalAmount: 0, discountAmount: 0, couponAmount: 0, finalTotalPrice: 0 }
    );

  const totalAmount = results.totalAmount;
  const discountAmount = results.discountAmount;
  const couponAmount = results.couponAmount;
  const finalTotalPrice = results.finalTotalPrice;
  const finalAmount = finalTotalPrice - usedPoints;

  if (loading) {
    return <div>로딩 중...</div>;
  }

  if (!cartItems.length) {
    return (
      <Box
        p={3}
        sx={{
          width: '100%',
          maxWidth: '90%',
          margin: 'auto',
          marginBottom: '80px',
        }}
      >
        <Typography variant="h4" gutterBottom sx={{ mb: 3 }}>
          {store?.storeName}
        </Typography>
        <Container>
          <Typography variant="h5">{t('cartEmptyMessage')}</Typography>
          <Typography>{t('addItemsToCartMessage')}</Typography>
          <Button
            variant="contained"
            onClick={() => navigate(`/store_home/${store.id}`)}
          >
            {t('goBackToStore')}
          </Button>
        </Container>
      </Box>
    );
  }

  const toggleItemSelection = (productId) => {
    let updatedSelectedItems;

    if (selectedItems.includes(productId)) {
      updatedSelectedItems = selectedItems.filter((id) => id !== productId);
    } else {
      updatedSelectedItems = [...selectedItems, productId];
    }

    setSelectedItems(updatedSelectedItems);

    setSelectAll(updatedSelectedItems.length === cartItems.length);
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedItems([]);
    } else {
      setSelectedItems(cartItems.map((item) => item.productId));
    }
    setSelectAll(!selectAll);
  };

  const removeFromCart = async (productId) => {
    try {
      const cartRef = doc(
        db,
        'stores',
        storeId,
        'users',
        userId,
        'cart',
        productId
      );
      await deleteDoc(cartRef);

      setCartItems((prevItems) =>
        prevItems.filter((item) => item.productId !== productId)
      );
    } catch (error) {
      console.error('Error removing item from cart:', error);
    }
  };

  const updateFinalTotalPrice = async (item, finalTotalPrice) => {
    try {
      const cartRef = doc(
        db,
        'stores',
        storeId,
        'users',
        userId,
        'cart',
        item.productId
      );
      await updateDoc(cartRef, {
        finalTotalPrice: finalTotalPrice,
      });

      setCartItems((prevItems) =>
        prevItems.map((prevItem) =>
          prevItem.productId === item.productId
            ? {
                ...prevItem,
                finalTotalPrice: finalTotalPrice,
              }
            : prevItem
        )
      );
    } catch (error) {
      console.error('Error updating coupon price:', error);
    }
  };

  const updateDiscountedPrice = async (item, newDiscountedPrice) => {
    try {
      const cartRef = doc(
        db,
        'stores',
        storeId,
        'users',
        userId,
        'cart',
        item.productId
      );
      await updateDoc(cartRef, {
        discountedPrice: newDiscountedPrice,
      });

      setCartItems((prevItems) =>
        prevItems.map((prevItem) =>
          prevItem.productId === item.productId
            ? { ...prevItem, discountedPrice: newDiscountedPrice }
            : prevItem
        )
      );
    } catch (error) {
      console.error('Error updating discounted price:', error);
    }
  };

  const updateQuantity = async (productId, newQuantity) => {
    try {
      const cartRef = doc(
        db,
        'stores',
        storeId,
        'users',
        userId,
        'cart',
        productId
      );
      await updateDoc(cartRef, {
        quantity: newQuantity,
      });

      setCartItems((prevItems) =>
        prevItems.map((item) =>
          item.productId === productId
            ? {
                ...item,
                quantity: newQuantity,
                totalAmount: item.price * newQuantity,
                finalAmount:
                  item.price * newQuantity -
                  (item.price -
                    (item.price - item.discountedPrice) -
                    item.couponPrice),
              }
            : item
        )
      );
    } catch (error) {
      console.error('Error updating item quantity:', error);
    }
  };

  async function createOrderData() {
    const cartCollection = collection(
      db,
      'stores',
      storeId,
      'users',
      userId,
      'cart'
    );
    const cartSnapshot = await getDocs(cartCollection);
    const selectedCartItems = cartSnapshot.docs.filter((doc) =>
      selectedItems.includes(doc.data().productId)
    );
    const productDataPromises = selectedCartItems.map(async (item) => {
      const productRef = doc(db, 'stores', storeId, 'products', item.id);
      const productSnapshot = await getDoc(productRef);
      return productSnapshot.data();
    });

    const allProductData = await Promise.all(productDataPromises);

    const orderData = {
      userId: userId,
      storeId: storeId,
      items: selectedCartItems.map((item, index) => {
        const productData = allProductData[index];
        const cartItemData = item.data();
        const couponPrice = (() => {
          const originalPrice = cartItemData.price;
          const couponData = appliedCoupons[item.id]?.selectedCoupon;
          if (couponData) {
            if (couponData.discountType === 'percentage') {
              return originalPrice * (couponData.discount / 100);
            } else if (couponData.discountType === 'amount') {
              return couponData.discount;
            }
          }
          return 0;
        })();
        return {
          productId: item.id,
          quantity: cartItemData.quantity,
          price: cartItemData.price,
          couponPrice: couponPrice,
          discountedPrice: cartItemData.discountedPrice || 0,
          selectedCoupon: appliedCoupons[item.id]?.selectedCoupon || null,
          productDetails: {
            name: productData.productName,
            description: productData.description,
            imageUrl:
              Array.isArray(productData.icon_images) &&
              productData.icon_images.length > 0
                ? productData.icon_images[0]
                : null,
          },
          totalPrice: cartItemData.totalPrice,
          finalPrice:
            cartItemData.totalPrice -
            (cartItemData.discountedPrice || 0) -
            (cartItemData.couponPrice || 0),
        };
      }),
      usedPoints: usedPoints,
      totalAmount: totalAmount,
      finalAmount: finalAmount,
      discountAmount: discountAmount,
      couponAmount: couponAmount,
      dateTime: new Date(),
      shippingDetails: {
        address: selectedAddress?.address,
        extraAddress: selectedAddress?.extraAddress,
        phoneNumber: userData?.phoneNumber,
        deliveryDate: 'preferredDeliveryDate',
      },
    };

    return {
      orderData: orderData,
      selectedCartItems: selectedCartItems,
    };
  }

  const handlePlaceOrder = async () => {
    if (loading) return;
    if (!selectedAddress) {
      setOpenAddressDialog(true);
      return;
    }
    if (selectedItems.length === 0) {
      MessageBoxInform('구매할 상품을 선택해주세요.');
      return;
    }
    setLoading(true);
    try {
      const { orderData, selectedCartItems } = await createOrderData();
      dispatchPlaceOrder(orderData);

      for (const item of selectedCartItems) {
        await logPurchasedProduct(currentUser?.uid, item.productId);
        await deleteDoc(item.ref);
      }
      await MessageBoxInform('주문이 정상적으로 처리되었습니다.');
    } catch (error) {
      console.error('Error during payment:', error);
    } finally {
      setLoading(false);
      fetchCartItems();
    }
  };

  const dispatchPlaceOrder = (orderData) => {
    placeOrder(orderData);
  };

  const handleOpenAddressDialog = () => {
    setOpenAddressDialog(true);
  };

  const handleSelectedAddress = (address) => {
    setSelectedAddress(address);
  };

  const handleUpdateAddress = async (updatedUser) => {
    try {
      const userRef = doc(db, 'users', userId);
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        await updateDoc(userRef, updatedUser);
      } else {
        await setDoc(userRef, updatedUser, { merge: true });
      }
      setSelectedAddress({
        zoneCode: updatedUser.zoneCode || '',
        address: updatedUser.address || '',
        extraAddress: updatedUser.extraAddress || '',
        additionalAddresses: updatedUser.additionalAddresses || [],
      });
      setUserData(updatedUser);
      await MessageBoxInform('주소 정보가 업데이트되었습니다.');
    } catch (error) {
      console.error('Error updating address:', error);
      await MessageBoxInform(
        '주소 정보를 업데이트하는 중 오류가 발생했습니다.'
      );
    }
  };

  const handleCloseAddressDialog = () => {
    setOpenAddressDialog(false);
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (!cartItems.length) {
    return (
      <Box
        p={3}
        sx={{
          width: '100%',
          maxWidth: '100%',
          margin: 'auto',
        }}
      >
        <Typography variant="h4" gutterBottom sx={{ mb: 3 }}>
          {store?.storeName}
        </Typography>
        <Container>
          <Typography variant="h5">{t('cartEmptyMessage')}</Typography>
          <Typography>{t('addItemsToCartMessage')}</Typography>
          <Button
            variant="contained"
            onClick={() => navigate(`/store_home/${store.id}`)}
          >
            {t('goBackToStore')}
          </Button>
        </Container>
      </Box>
    );
  }

  return (
    <Box
      p={3}
      sx={{
        width: '100%',
        maxWidth: '90%',
        margin: 'auto',
        marginBottom: '80px',
      }}
    >
      <Typography variant="h4" gutterBottom>
        {store?.storeName}
      </Typography>
      <FormControlLabel
        control={<Checkbox checked={selectAll} onChange={handleSelectAll} />}
        label={t('selectAll')}
        sx={{ marginY: 2 }}
      />
      <Grid container spacing={2}>
        {cartItems.map((cartItem) => (
          <Grid item xs={12} key={cartItem.productId}>
            <CartItem
              item={cartItem}
              onCouponChange={(couponInfo) =>
                handleCouponChange(cartItem.productId, couponInfo)
              }
              isSelected={selectedItems.includes(cartItem.productId)}
              onToggleSelect={() => toggleItemSelection(cartItem.productId)}
              onRemove={() => removeFromCart(cartItem.productId)}
              onUpdateQuantity={(quantity) =>
                updateQuantity(cartItem.productId, quantity)
              }
              onUpdateFinalTotalPrice={(finalTotalPrice) =>
                updateFinalTotalPrice(cartItem, finalTotalPrice)
              }
              onUpdateDiscountedPrice={(discountedPrice) =>
                updateDiscountedPrice(cartItem, discountedPrice)
              }
            />
          </Grid>
        ))}
      </Grid>
      {MessageBoxRender()}
      <Paper
        elevation={3}
        sx={{
          p: 3,
          mt: 0,
          mb: 0,
        }}
      >
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={12} sm={4}>
            <StorePoints
              storeId={storeId}
              userId={userId}
              onUsedPoints={(newPoints) =>
                setUsedPoints((prevPoints) => prevPoints + newPoints)
              }
            />
          </Grid>
          <Grid item xs={12} sm={4} sx={{ textAlign: 'center' }}>
            <Typography variant="h6">
              {t('paymentAmount')}: {finalAmount.toLocaleString()}{' '}
              {t('currency')}
            </Typography>
            {selectedAddress && (
              <Typography>
                {t('deliveryAddress')}: {selectedAddress.zoneCode}{' '}
                {selectedAddress.address}{' '}
                {selectedAddress.extraAddress &&
                  `(${selectedAddress.extraAddress})`}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={4} sx={{ textAlign: 'right' }}>
            <Grid item xs={12} sx={{ marginBottom: 1 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenAddressDialog}
                sx={{ width: { xs: '100%', sm: 'auto' } }}
              >
                {t('changeAddress')}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={handlePlaceOrder}
                sx={{ width: { xs: '100%', sm: 'auto' } }}
              >
                {t('purchase')}
              </Button>
            </Grid>
            <Dialog
              open={openAddressDialog}
              onClose={handleCloseAddressDialog}
              fullWidth
              maxWidth="sm"
            >
              <DialogContent
                dividers
                style={{
                  overflowY: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <UserAddress
                  user={userData}
                  onUpdate={handleUpdateAddress}
                  onAddressSelect={handleSelectedAddress}
                />
              </DialogContent>
              <DialogActions sx={{ justifyContent: 'flex-end' }}>
                <Button
                  variant="outlined"
                  onClick={handleCloseAddressDialog}
                  sx={{ mr: 2, mb: 1, mt: 1 }}
                >
                  {t('close')}
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}

export default CartList;
