import * as tf from '@tensorflow/tfjs';
import * as mobilenet from '@tensorflow-models/mobilenet';

const loadModel = async () => {
  const model = await mobilenet.load();
  return model;
};

export const filterImageTF = async (imageFile) => {
  const model = await loadModel();
  const img = document.createElement('img');
  img.src = URL.createObjectURL(imageFile);
  await img.decode();

  const predictions = await model.classify(img);
  const offensiveLabels = ['porn', 'nudity', 'violence']; // Add more labels based on your needs

  return predictions.some((prediction) =>
    offensiveLabels.includes(prediction.className.toLowerCase())
  );
};
