// import React, { useState, useEffect } from 'react';
// import {
//   db,
//   collection,
//   getDocs,
//   getDoc,
//   doc,
//   updateDoc,
//   addDoc,
//   deleteDoc,
//   query,
//   where,
//   Timestamp,
//   orderBy,
//   limit,
// } from '../../../firebaseConfig';
// import { useParams } from 'react-router-dom';
// import Modal from 'react-modal';
// import ModalStyles from '../../../Common/ModalStyles';
// import '../../css/SignUpFlow.css';
// import useMessageBox from '../../../Common/useMessageBox';

// const CouponManagement = ({ storeId }) => {
//   // const { id: storeId } = useParams();
//   const [MessageBoxInform, MessageBoxConfirm, MessageBoxRender] =
//     useMessageBox();
//   const [discountType, setDiscountType] = useState('percentage'); // 'percentage' 또는 'amount'
//   const [minPurchaseAmount, setMinPurchaseAmount] = useState(0);
//   const [customAmount, setCustomAmount] = useState('');
//   const [isCustomInput, setIsCustomInput] = useState(false);
//   const [couponName, setCouponName] = useState('');
//   const [isModalOpen, setModalOpen] = useState(false);
//   const [quantity, setQuantity] = useState(1);
//   const [product, setProduct] = useState({ name: '', id: null });
//   const [discount, setDiscount] = useState(0);
//   const [allProducts, setAllProducts] = useState(false);
//   const [applicableProducts, setApplicableProducts] = useState([]);
//   const [startDate, setStartDate] = useState('');
//   const [endDate, setEndDate] = useState('');
//   const [error, setError] = useState(null);
//   const [isIssuing, setIsIssuing] = useState(false);
//   const [products, setProducts] = useState([]);
//   const [selectedProduct, setSelectedProduct] = useState(null);

//   // Firestore에서 products 컬렉션 내의 데이터를 가져오는 함수
//   const fetchProducts = async () => {
//     const productsRef = collection(db, 'stores', storeId, 'products');
//     const productsSnapshot = await getDocs(productsRef);
//     const fetchedProducts = productsSnapshot.docs.map((doc) => ({
//       id: doc.id,
//       ...doc.data(),
//     }));
//     setProducts(fetchedProducts);
//   };

//   useEffect(() => {
//     fetchProducts();
//   }, []);

//   const [coupons, setCoupons] = useState([]);

//   const fetchCoupons = async () => {
//     const now = Timestamp.now();
//     const couponsQuery = query(
//       collection(db, 'stores', storeId, 'coupons'),
//       // where("quantity", ">", 0),
//       where('endDate', '>', now),
//       orderBy('endDate', 'asc')
//       //limit(5)
//     );
//     const snapshot = await getDocs(couponsQuery);
//     setCoupons(
//       snapshot.docs.map((doc) => ({
//         id: doc.id,
//         ...doc.data(),
//         startDate: doc.data().startDate.toDate(),
//         endDate: doc.data().endDate.toDate(),
//       }))
//     );
//   };
//   useEffect(() => {
//     fetchCoupons();
//   }, [storeId]);

//   function generateCouponCode(length = 6) {
//     const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
//     let result = '';
//     for (let i = 0; i < length; i++) {
//       result += chars.charAt(Math.floor(Math.random() * chars.length));
//     }
//     return result;
//   }

//   const handleIssueCoupon = async () => {
//     // Check if the required values are set
//     if (!couponName) {
//       await MessageBoxInform('쿠폰 이름을 입력해주세요.');
//       return;
//     }
//     if (!discountType) {
//       await MessageBoxInform('쿠폰 유형을 선택해주세요.');
//       return;
//     }

//     const effectiveMinPurchase = isCustomInput
//       ? customAmount
//       : minPurchaseAmount;

//     if (!effectiveMinPurchase || effectiveMinPurchase <= 0) {
//       await MessageBoxInform('쿠폰 사용 가능한 최소 구매 금액을 설정해주세요.');
//       return;
//     }

//     if (!discount || !startDate || !endDate) {
//       setError('필요한 정보를 모두 입력해주세요.');
//       return;
//     }

//     if (new Date(startDate) >= new Date(endDate)) {
//       setError('시작 시간은 종료 시간보다 이전이어야 합니다.');
//       return;
//     }

//     setIsIssuing(true);

//     const couponCode = generateCouponCode();
//     // const applicableProductNames = applicableProducts.map(product => product.id).join(',');
//     const applicableProductIds = applicableProducts.map(
//       (product) => product.id
//     );
//     const couponData = {
//       targetProducts: allProducts ? 'all' : applicableProductIds,
//       discountType: discountType,
//       minPurchaseAmount: effectiveMinPurchase,
//       discount,
//       couponCode,
//       couponName,
//       issuedAt: new Date(),
//       applicableProducts: applicableProducts.map((product) => ({
//         ...product,
//         issuedQuantity: quantity,
//         quantity: quantity,
//       })),
//       startDate: new Date(startDate),
//       endDate: new Date(endDate),
//     };

//     try {
//       const couponsRef = collection(db, 'stores', storeId, 'coupons');

//       await addDoc(couponsRef, couponData);

//       await MessageBoxInform(
//         `선택한 상품별로 각각 ${quantity}개의 쿠폰이 발급되었습니다!`
//       );
//       resetFields();
//     } catch (error) {
//       console.error('Error issuing coupon: ', error);
//       setError('쿠폰 발급 중 오류가 발생했습니다. 다시 시도해주세요.');
//     } finally {
//       setIsIssuing(false);
//     }
//   };

//   const resetFields = () => {
//     setProduct({ name: '', id: null });
//     setDiscount('');
//     setAllProducts(false);
//     setApplicableProducts([]);
//     setStartDate('');
//     setEndDate('');
//     setQuantity(1);
//     setError(null);
//   };

//   const addApplicableProduct = () => {
//     if (selectedProduct && selectedProduct.id) {
//       // Check if the product is already in the applicableProducts array
//       if (
//         !applicableProducts.some((product) => product.id === selectedProduct.id)
//       ) {
//         setApplicableProducts((prev) => [
//           ...prev,
//           { id: selectedProduct.id, name: selectedProduct.productName },
//         ]);
//         setSelectedProduct(null); // Reset the selected product
//       } else {
//         MessageBoxInform('이 상품은 이미 추가되었습니다.'); // This product is already added.
//       }
//     }
//   };

//   const handleProductSelect = (e) => {
//     const selected = products.find((product) => product.id === e.target.value);
//     setSelectedProduct(selected);

//     const productId = e.target.value;
//     const chosenProduct = products.find((product) => product.id === productId);
//     setSelectedProduct(chosenProduct);
//     setProduct({ ...product, name: chosenProduct?.productName });
//   };

//   const handleOpenModal = () => {
//     setModalOpen(true);
//   };

//   const handleCloseModal = () => {
//     fetchCoupons();
//     setModalOpen(false);
//   };

//   const handleFormSubmit = (e) => {
//     e.preventDefault(); // 폼 제출을 방지합니다.
//   };

//   return (
//     <div>
//       <h2>쿠폰 관리 시스템</h2>
//       <div style={{ position: 'relative' }}>
//         <ul>
//           <div className="coupon-display">
//             {coupons.map((coupon) =>
//               Array.isArray(coupon.applicableProducts)
//                 ? coupon.applicableProducts.map((applicableProduct) => (
//                     <div key={coupon.id} className="coupon-item">
//                       <h1>상품이름: {applicableProduct.name}</h1>
//                       <h2>
//                         {coupon.discountType === 'percentage'
//                           ? `할인율: ${coupon.discount} %`
//                           : `할인 금액: ${coupon.discount} 원`}
//                       </h2>
//                       <h2>남은쿠폰갯수: {applicableProduct.quantity} 개</h2>
//                       <p>사용시작시간: {coupon.startDate?.toLocaleString()}</p>
//                       <p>사용종료시간: {coupon.endDate?.toLocaleString()}</p>
//                     </div>
//                   ))
//                 : null
//             )}
//             {coupons.length === 0 && (
//               <div className="coupon-item">
//                 <p>진행중인 쿠폰이 없습니다.</p>{' '}
//                 {/* Assuming there's a description field in the promo object */}
//                 {/* If there are other details, add them here */}
//               </div>
//             )}
//           </div>
//         </ul>
//         <div style={{ position: 'absolute', right: '1px', bottom: '1px' }}>
//           <button className="button" onClick={() => handleOpenModal(null)}>
//             쿠폰 추가
//           </button>
//         </div>
//       </div>
//       {MessageBoxRender()}
//       <Modal
//         isOpen={isModalOpen}
//         onRequestClose={handleCloseModal}
//         style={ModalStyles}
//       >
//         <form onSubmit={handleFormSubmit}>
//           <div>
//             <h2>쿠폰 발급</h2>
//             {error && <p style={{ color: 'red' }}>{error}</p>}
//             <div>
//               <label>
//                 쿠폰이름:
//                 <input
//                   type="text"
//                   maxLength={20}
//                   value={couponName}
//                   onChange={(e) => setCouponName(e.target.value)}
//                 />
//               </label>
//             </div>
//             <div>
//               <label>
//                 <input
//                   type="checkbox"
//                   checked={allProducts}
//                   onChange={() => setAllProducts(!allProducts)}
//                 />
//                 전체 제품 대상
//               </label>
//             </div>
//             <div>
//               <label>
//                 할인 유형 선택:
//                 <select
//                   value={discountType}
//                   onChange={(e) => setDiscountType(e.target.value)}
//                 >
//                   <option value="percentage">퍼센트 할인</option>
//                   <option value="amount">금액 할인</option>
//                 </select>
//               </label>
//             </div>

//             <div>
//               <label>
//                 {discountType === 'percentage'
//                   ? '할인율 선택:'
//                   : '할인 금액 입력:'}
//                 {discountType === 'percentage' ? (
//                   <select
//                     value={discount}
//                     onChange={(e) => setDiscount(Number(e.target.value))}
//                   >
//                     {[...Array(100).keys()].map((num) => (
//                       <option key={num + 1} value={num + 1}>
//                         {num + 1}%
//                       </option>
//                     ))}
//                   </select>
//                 ) : (
//                   <input
//                     type="number"
//                     value={discount}
//                     onChange={(e) => setDiscount(Math.max(0, e.target.value))}
//                     min="0"
//                     placeholder="원"
//                   />
//                 )}
//               </label>
//             </div>
//             <div>
//               <label>
//                 쿠폰 사용 가능한 최소 구매 금액:
//                 <select
//                   value={isCustomInput ? 'custom' : minPurchaseAmount}
//                   onChange={(e) => {
//                     if (e.target.value === 'custom') {
//                       setIsCustomInput(true);
//                     } else {
//                       setIsCustomInput(false);
//                       setMinPurchaseAmount(e.target.value);
//                     }
//                   }}
//                 >
//                   <option value="5000">5,000원</option>
//                   <option value="10000">10,000원</option>
//                   <option value="15000">15,000원</option>
//                   <option value="custom">직접 입력</option>
//                 </select>
//               </label>
//               {isCustomInput && (
//                 <input
//                   type="number"
//                   value={customAmount}
//                   onChange={(e) => setCustomAmount(e.target.value)}
//                   placeholder="원"
//                 />
//               )}
//             </div>

//             {!allProducts && (
//               <div>
//                 <div>
//                   <label>
//                     상품 선택:
//                     <select onChange={handleProductSelect}>
//                       <option value="">상품을 선택하세요</option>
//                       {products.map((product) => (
//                         <option key={product.id} value={product.id}>
//                           {product.productName
//                             ? product.productName
//                             : '제품 이름 없음'}{' '}
//                           -
//                           {product.images && product.images[0] ? (
//                             <img
//                               src={product.images[0]}
//                               alt={product.productName || '제품 이미지'}
//                               width="50"
//                             />
//                           ) : (
//                             '이미지 없음'
//                           )}
//                         </option>
//                       ))}
//                     </select>
//                     <button
//                       onClick={() => addApplicableProduct(selectedProduct)}
//                     >
//                       코드 추가
//                     </button>
//                   </label>
//                 </div>
//                 <div>
//                   <label>
//                     재고:
//                     <input
//                       type="text"
//                       value={`${selectedProduct?.stock || 0}개 남음`}
//                       readOnly
//                     />
//                   </label>
//                 </div>
//                 <ul>
//                   {applicableProducts.map((product) => (
//                     <li key={product.id}>{product.productName}</li>
//                   ))}
//                 </ul>
//               </div>
//             )}

//             <div>
//               <label>
//                 시작 시간:
//                 <input
//                   type="datetime-local"
//                   value={startDate}
//                   onChange={(e) => setStartDate(e.target.value)}
//                 />
//               </label>
//             </div>
//             <div>
//               <label>
//                 종료 시간:
//                 <input
//                   type="datetime-local"
//                   value={endDate}
//                   onChange={(e) => setEndDate(e.target.value)}
//                 />
//               </label>
//             </div>
//             <div>
//               <label>
//                 발급할 쿠폰 수:
//                 <input
//                   type="number"
//                   value={quantity}
//                   onChange={(e) => setQuantity(Math.max(1, e.target.value))}
//                   min="1"
//                 />
//               </label>
//             </div>
//             {isIssuing && <div className="spinner"></div>}
//           </div>

//           <div style={{ position: 'absolute', right: '20px', bottom: '20px' }}>
//             {/* <button className='button' type="submit">Save</button> */}
//             <button
//               className="button"
//               type="button"
//               onClick={handleIssueCoupon}
//             >
//               쿠폰 발급
//             </button>
//             <button className="button" type="button" onClick={handleCloseModal}>
//               닫기
//             </button>
//           </div>
//         </form>
//       </Modal>
//     </div>
//   );
// };
// export default CouponManagement;

// import React, { useState, useEffect } from 'react';
// import {
//   db,
//   collection,
//   getDocs,
//   doc,
//   getDoc,
//   updateDoc,
//   addDoc,
//   query,
//   where,
//   Timestamp,
//   orderBy,
//   deleteDoc,
// } from '../../../firebaseConfig';
// import { useSelector } from 'react-redux';
// import { useParams, useNavigate } from 'react-router-dom';
// import {
//   TextField,
//   Button,
//   Card,
//   CardContent,
//   Typography,
//   MenuItem,
//   FormControl,
//   InputLabel,
//   Select,
//   FormControlLabel,
//   Checkbox,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   Grid,
//   Box,
//   List,
//   ListItem,
//   ListItemText,
//   IconButton,
// } from '@mui/material';
// import { useTranslation } from 'react-i18next';
// import CouponList from './CouponList';
// import useMessageBox from '../../../Common/useMessageBox';
// import AddIcon from '@mui/icons-material/Add';
// import DeleteIcon from '@mui/icons-material/Delete';

// const CouponManagement = ({ storeId }) => {
//   const { t, i18n } = useTranslation();
//   const [MessageBoxInform, MessageBoxConfirm, MessageBoxRender] =
//     useMessageBox();
//   const [coupons, setCoupons] = useState([]);
//   const currentUser = useSelector((state) => state.auth.currentUser);
//   const [discountType, setDiscountType] = useState('percentage');
//   const [minPurchaseAmount, setMinPurchaseAmount] = useState(5000);
//   const [customAmount, setCustomAmount] = useState('');
//   const [isCustomInput, setIsCustomInput] = useState(false);
//   const [couponName, setCouponName] = useState('');
//   const [isModalOpen, setModalOpen] = useState(false);
//   const [quantity, setQuantity] = useState(1);
//   const [product, setProduct] = useState({ name: '', id: null });
//   const [discount, setDiscount] = useState(0);
//   const [allProducts, setAllProducts] = useState(false);
//   const [applicableProducts, setApplicableProducts] = useState([]);
//   const [startDate, setStartDate] = useState('');
//   const [endDate, setEndDate] = useState('');
//   const [error, setError] = useState(null);
//   const [isIssuing, setIsIssuing] = useState(false);
//   const [products, setProducts] = useState([]);
//   const [selectedProduct, setSelectedProduct] = useState(null);

//   const fetchCoupons = async () => {
//     const now = Timestamp.now();
//     const couponsQuery = query(
//       collection(db, 'stores', storeId, 'coupons'),
//       where('endDate', '>', now),
//       orderBy('endDate', 'asc')
//     );
//     const snapshot = await getDocs(couponsQuery);
//     setCoupons(
//       snapshot.docs.map((doc) => ({
//         id: doc.id,
//         ...doc.data(),
//         startDate: doc.data().startDate.toDate(),
//         endDate: doc.data().endDate.toDate(),
//       }))
//     );
//   };

//   const fetchProducts = async () => {
//     const productsRef = collection(db, 'stores', storeId, 'products');
//     const productsSnapshot = await getDocs(productsRef);
//     const fetchedProducts = productsSnapshot.docs.map((doc) => ({
//       id: doc.id,
//       ...doc.data(),
//     }));
//     setProducts(fetchedProducts);
//   };

//   useEffect(() => {
//     fetchProducts();
//   }, [storeId]);

//   useEffect(() => {
//     fetchCoupons();
//   }, [storeId]);

//   const handleIssueCoupon = async () => {
//     if (!couponName) {
//       await MessageBoxInform('쿠폰 이름을 입력해주세요.');
//       return;
//     }

//     const effectiveMinPurchase = isCustomInput
//       ? customAmount
//       : minPurchaseAmount;

//     if (!effectiveMinPurchase || effectiveMinPurchase <= 0) {
//       await MessageBoxInform('쿠폰 사용 가능한 최소 구매 금액을 설정해주세요.');
//       return;
//     }

//     if (!discount || !startDate || !endDate) {
//       setError('필요한 정보를 모두 입력해주세요.');
//       return;
//     }

//     if (new Date(startDate) >= new Date(endDate)) {
//       setError('시작 시간은 종료 시간보다 이전이어야 합니다.');
//       return;
//     }

//     setIsIssuing(true);

//     const couponCode = generateCouponCode();
//     const applicableProductIds = applicableProducts.map(
//       (product) => product.id
//     );
//     const couponData = {
//       targetProducts: allProducts ? 'all' : applicableProductIds,
//       discountType,
//       minPurchaseAmount: isCustomInput ? customAmount : minPurchaseAmount,
//       discount,
//       couponCode,
//       couponName,
//       issuedAt: new Date(),
//       applicableProducts: applicableProducts.map((product) => ({
//         ...product,
//         issuedQuantity: quantity,
//         quantity,
//       })),
//       startDate: new Date(startDate),
//       endDate: new Date(endDate),
//     };

//     try {
//       const couponsRef = collection(db, 'stores', storeId, 'coupons');
//       await addDoc(couponsRef, couponData);
//       await MessageBoxInform(
//         `선택한 상품별로 각각 ${quantity}개의 쿠폰이 발급되었습니다!`
//       );
//       resetFields();
//       handleCloseModal();
//       fetchCoupons();
//     } catch (error) {
//       console.error('Error issuing coupon: ', error);
//       setError('쿠폰 발급 중 오류가 발생했습니다. 다시 시도해주세요.');
//     } finally {
//       setIsIssuing(false);
//     }
//   };

//   const resetFields = () => {
//     setProduct({ name: '', id: null });
//     setDiscount(0);
//     setAllProducts(false);
//     setApplicableProducts([]);
//     setStartDate('');
//     setEndDate('');
//     setQuantity(1);
//     setError(null);
//   };

//   const handleDeleteCoupon = async (couponId) => {
//     const isConfirmed = await MessageBoxConfirm('쿠폰을 삭제할까요?');
//     if (isConfirmed) {
//       try {
//         // Create a reference to the specific coupon document
//         const couponRef = doc(db, 'stores', storeId, 'coupons', couponId);
//         // Delete the document
//         await deleteDoc(couponRef);
//         // Update local state to reflect the change
//         setCoupons(coupons.filter((coupon) => coupon.id !== couponId));
//         // Show confirmation message
//         await MessageBoxInform('쿠폰이 성공적으로 삭제되었습니다.');
//       } catch (error) {
//         console.error('Error deleting coupon:', error);
//         await MessageBoxInform('쿠폰 삭제 중 오류가 발생했습니다.');
//       }
//     }
//   };

//   const handleAddProduct = () => {
//     if (
//       selectedProduct &&
//       !applicableProducts.some((p) => p.id === selectedProduct.id)
//     ) {
//       setApplicableProducts((prev) => [...prev, selectedProduct]);
//       setSelectedProduct(null); // Clear selection after adding
//     } else if (!selectedProduct) {
//       MessageBoxInform('상품을 선택해 주세요.');
//     } else {
//       MessageBoxInform('이 상품은 이미 추가되었습니다.');
//     }
//   };

//   const handleRemoveProduct = (productId) => {
//     setApplicableProducts(applicableProducts.filter((p) => p.id !== productId));
//   };

//   const handleOpenModal = () => {
//     setModalOpen(true);
//   };

//   const handleCloseModal = () => {
//     setModalOpen(false);
//   };
//   function generateCouponCode(length = 6) {
//     const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
//     let result = '';
//     for (let i = 0; i < length; i++) {
//       result += chars.charAt(Math.floor(Math.random() * chars.length));
//     }
//     return result;
//   }

//   return (
//     <div>
//       <Typography variant="h4">{t('couponManagementSystem')}</Typography>
//       <Box mt={2} display="flex" justifyContent="flex-end" pr={2}>
//         <Button
//           variant="contained"
//           startIcon={<AddIcon />}
//           onClick={handleOpenModal}
//         >
//           {t('addCoupon')}
//         </Button>
//       </Box>

//       <Grid container spacing={2}>
//         <Grid item xs={12}>
//           <CouponList
//             coupons={coupons}
//             onDelete={handleDeleteCoupon}
//             isSeller={currentUser?.uid === storeId}
//           />
//         </Grid>
//       </Grid>
//       {MessageBoxRender()}
//       <Dialog open={isModalOpen} onClose={handleCloseModal}>
//         <DialogTitle>{t('issueCoupon')}</DialogTitle>
//         <DialogContent>
//           <TextField
//             autoFocus
//             margin="dense"
//             label={t('couponName')}
//             type="text"
//             fullWidth
//             value={couponName || ''}
//             onChange={(e) => setCouponName(e.target.value)}
//           />
//           <FormControl fullWidth>
//             <InputLabel>{t('discountType')}</InputLabel>
//             <Select
//               value={discountType || ''}
//               onChange={(e) => setDiscountType(e.target.value)}
//               label={t('discountType')}
//             >
//               <MenuItem value="percentage">{t('percentageDiscount')}</MenuItem>
//               <MenuItem value="amount">{t('amountDiscount')}</MenuItem>
//             </Select>
//           </FormControl>
//           {discountType === 'amount' && (
//             <FormControl fullWidth margin="normal">
//               <InputLabel id="min-purchase-amount-label">
//                 {t('minPurchaseAmount')}
//               </InputLabel>
//               <Select
//                 labelId="min-purchase-amount-label"
//                 value={isCustomInput ? 'custom' : minPurchaseAmount}
//                 onChange={(e) => {
//                   if (e.target.value === 'custom') {
//                     setIsCustomInput(true);
//                   } else {
//                     setIsCustomInput(false);
//                     setMinPurchaseAmount(e.target.value);
//                   }
//                 }}
//               >
//                 <MenuItem value="5000">5,000원</MenuItem>
//                 <MenuItem value="10000">10,000원</MenuItem>
//                 <MenuItem value="15000">15,000원</MenuItem>
//                 <MenuItem value="custom">{t('customInput')}</MenuItem>
//               </Select>
//               {isCustomInput && (
//                 <TextField
//                   margin="dense"
//                   id="custom-amount"
//                   label={t('customAmount', { unit: '원' })}
//                   type="number"
//                   fullWidth
//                   value={customAmount || ''}
//                   onChange={(e) => setCustomAmount(e.target.value)}
//                 />
//               )}
//             </FormControl>
//           )}
//           <FormControlLabel
//             control={
//               <Checkbox
//                 checked={allProducts}
//                 onChange={() => setAllProducts(!allProducts)}
//               />
//             }
//             label={t('allProducts')}
//           />
//           {!allProducts && (
//             <FormControl fullWidth margin="normal">
//               <InputLabel id="product-select-label">
//                 {t('selectProduct')}
//               </InputLabel>
//               <Select
//                 labelId="product-select-label"
//                 value={selectedProduct ? selectedProduct.id : ''}
//                 onChange={(e) =>
//                   setSelectedProduct(
//                     products.find((p) => p.id === e.target.value)
//                   )
//                 }
//               >
//                 {products.length > 0 ? (
//                   products.map((product) => (
//                     <MenuItem key={product.id} value={product.id || ''}>
//                       {product.productName}
//                     </MenuItem>
//                   ))
//                 ) : (
//                   <MenuItem disabled>{t('loadingProducts')}</MenuItem>
//                 )}
//               </Select>
//               <Button onClick={handleAddProduct} sx={{ mt: 2 }}>
//                 {t('addCouponProduct')}
//               </Button>
//               <List dense>
//                 {applicableProducts.length > 0 ? (
//                   applicableProducts.map((product) => (
//                     <ListItem
//                       key={product.id}
//                       secondaryAction={
//                         <IconButton
//                           edge="end"
//                           aria-label="delete"
//                           onClick={() => handleRemoveProduct(product.id)}
//                         >
//                           <DeleteIcon />
//                         </IconButton>
//                       }
//                     >
//                       <ListItemText primary={product.productName} />
//                     </ListItem>
//                   ))
//                 ) : (
//                   <ListItem>
//                     <ListItemText primary={t('noApplicableProducts')} />
//                   </ListItem>
//                 )}
//               </List>
//             </FormControl>
//           )}
//           <TextField
//             margin="dense"
//             label={t('discountValue')}
//             type="number"
//             fullWidth
//             value={discount || ''}
//             onChange={(e) => setDiscount(e.target.value)}
//           />
//           {selectedProduct && (
//             <TextField
//               margin="dense"
//               label={t('stock')}
//               type="number"
//               fullWidth
//               value={selectedProduct ? selectedProduct.stock : 0}
//               InputProps={{
//                 readOnly: true,
//               }}
//             />
//           )}
//           <TextField
//             margin="dense"
//             label={t('couponQuantity')}
//             type="number"
//             fullWidth
//             value={quantity || ''}
//             onChange={(e) =>
//               setQuantity(Math.max(1, parseInt(e.target.value, 10)))
//             }
//             InputProps={{
//               min: 1,
//             }}
//           />
//           <TextField
//             margin="dense"
//             label={t('startDate')}
//             type="date"
//             fullWidth
//             InputLabelProps={{ shrink: true }}
//             value={startDate || ''}
//             onChange={(e) => setStartDate(e.target.value)}
//           />
//           <TextField
//             margin="dense"
//             label={t('endDate')}
//             type="date"
//             fullWidth
//             InputLabelProps={{ shrink: true }}
//             value={endDate || ''}
//             onChange={(e) => setEndDate(e.target.value)}
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleIssueCoupon} color="primary">
//             {t('issue')}
//           </Button>
//           <Button onClick={handleCloseModal} color="secondary">
//             {t('close')}
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </div>
//   );
// };

// export default CouponManagement;

import React, { useState, useEffect } from 'react';
import {
  db,
  collection,
  getDocs,
  doc,
  getDoc,
  updateDoc,
  addDoc,
  query,
  where,
  Timestamp,
  orderBy,
  deleteDoc,
} from '../../../firebaseConfig';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import {
  TextField,
  Button,
  Typography,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Box,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CouponList from './CouponList';
import useMessageBox from '../../../Common/useMessageBox';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

const CouponManagement = ({ storeId }) => {
  const { t } = useTranslation();
  const [MessageBoxInform, MessageBoxConfirm, MessageBoxRender] =
    useMessageBox();
  const [coupons, setCoupons] = useState([]);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const [discountType, setDiscountType] = useState('percentage');
  const [minPurchaseAmount, setMinPurchaseAmount] = useState(5000);
  const [customAmount, setCustomAmount] = useState('');
  const [isCustomInput, setIsCustomInput] = useState(false);
  const [couponName, setCouponName] = useState('');
  const [isModalOpen, setModalOpen] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [product, setProduct] = useState({ name: '', id: null });
  const [discount, setDiscount] = useState(0);
  const [allProducts, setAllProducts] = useState(false);
  const [applicableProducts, setApplicableProducts] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [error, setError] = useState(null);
  const [isIssuing, setIsIssuing] = useState(false);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [loading, setLoading] = useState(false);
  // const fetchCoupons = async () => {
  //   const now = Timestamp.now();
  //   const couponsQuery = query(
  //     collection(db, 'stores', storeId, 'coupons'),
  //     where('endDate', '>', now),
  //     orderBy('endDate', 'asc')
  //   );
  //   const snapshot = await getDocs(couponsQuery);
  //   setCoupons(
  //     snapshot.docs.map((doc) => ({
  //       id: doc.id,
  //       ...doc.data(),
  //       startDate: doc.data().startDate.toDate(),
  //       endDate: doc.data().endDate.toDate(),
  //     }))
  //   );
  // };

  const fetchCoupons = async () => {
    setLoading(true);
    try {
      const now = Timestamp.now();
      const couponsQuery = query(
        collection(db, 'stores', storeId, 'coupons'),
        where('endDate', '>', now),
        orderBy('endDate', 'asc')
      );
      const snapshot = await getDocs(couponsQuery);
      const couponsData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        startDate: doc.data().startDate.toDate(),
        endDate: doc.data().endDate.toDate(),
      }));
      setCoupons(couponsData);
    } catch (error) {
      if (
        error.code === 'failed-precondition' ||
        error.code === 'unavailable'
      ) {
        console.error('Firestore index error:', error.message);
        setError('Firestore index error. Please create a Firestore index.');
      } else {
        console.error('Error fetching coupons:', error);
        setError('Failed to fetch coupons.');
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchProducts = async () => {
    const productsRef = collection(db, 'stores', storeId, 'products');
    const productsSnapshot = await getDocs(productsRef);
    const fetchedProducts = productsSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setProducts(fetchedProducts);
  };

  useEffect(() => {
    fetchProducts();
  }, [storeId]);

  useEffect(() => {
    fetchCoupons();
  }, [storeId]);

  const handleIssueCoupon = async () => {
    if (!couponName) {
      await MessageBoxInform(t('pleaseEnterCouponName'));
      return;
    }

    const effectiveMinPurchase = isCustomInput
      ? customAmount
      : minPurchaseAmount;

    if (!effectiveMinPurchase || effectiveMinPurchase <= 0) {
      await MessageBoxInform(t('pleaseSetMinPurchaseAmount'));
      return;
    }

    if (!discount || !startDate || !endDate) {
      setError(t('pleaseEnterAllRequiredFields'));
      return;
    }

    if (new Date(startDate) >= new Date(endDate)) {
      setError(t('startTimeMustBeBeforeEndTime'));
      return;
    }

    setIsIssuing(true);

    const couponCode = generateCouponCode();
    const applicableProductIds = applicableProducts.map(
      (product) => product.id
    );
    const couponData = {
      targetProducts: allProducts ? 'all' : applicableProductIds,
      discountType,
      minPurchaseAmount: isCustomInput ? customAmount : minPurchaseAmount,
      discount,
      couponCode,
      couponName,
      issuedAt: new Date(),
      applicableProducts: applicableProducts.map((product) => ({
        ...product,
        issuedQuantity: quantity,
        quantity,
      })),
      startDate: new Date(startDate),
      endDate: new Date(endDate),
    };

    try {
      const couponsRef = collection(db, 'stores', storeId, 'coupons');
      await addDoc(couponsRef, couponData);
      await MessageBoxInform(t('couponIssuedSuccessfully', { quantity }));
      resetFields();
      handleCloseModal();
      fetchCoupons();
    } catch (error) {
      console.error('Error issuing coupon: ', error);
      setError(t('errorIssuingCoupon'));
    } finally {
      setIsIssuing(false);
    }
  };

  const resetFields = () => {
    setProduct({ name: '', id: null });
    setDiscount(0);
    setAllProducts(false);
    setApplicableProducts([]);
    setStartDate('');
    setEndDate('');
    setQuantity(1);
    setError(null);
  };

  const handleDeleteCoupon = async (couponId) => {
    const isConfirmed = await MessageBoxConfirm(t('confirmDeleteCoupon'));
    if (isConfirmed) {
      try {
        const couponRef = doc(db, 'stores', storeId, 'coupons', couponId);
        await deleteDoc(couponRef);
        setCoupons(coupons.filter((coupon) => coupon.id !== couponId));
        await MessageBoxInform(t('couponDeletedSuccessfully'));
      } catch (error) {
        console.error('Error deleting coupon:', error);
        await MessageBoxInform(t('errorDeletingCoupon'));
      }
    }
  };

  const handleAddProduct = () => {
    if (
      selectedProduct &&
      !applicableProducts.some((p) => p.id === selectedProduct.id)
    ) {
      setApplicableProducts((prev) => [...prev, selectedProduct]);
      setSelectedProduct(null);
    } else if (!selectedProduct) {
      MessageBoxInform(t('pleaseSelectProduct'));
    } else {
      MessageBoxInform(t('productAlreadyAdded'));
    }
  };

  const handleRemoveProduct = (productId) => {
    setApplicableProducts(applicableProducts.filter((p) => p.id !== productId));
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  function generateCouponCode(length = 6) {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
  }

  return (
    <div>
      <Typography variant="h4">{t('couponManagementSystem')}</Typography>
      <Box mt={2} display="flex" justifyContent="flex-end" pr={2}>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleOpenModal}
        >
          {t('addCoupon')}
        </Button>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CouponList
            coupons={coupons}
            onDelete={handleDeleteCoupon}
            isSeller={currentUser?.uid === storeId}
          />
        </Grid>
      </Grid>
      {MessageBoxRender()}
      <Dialog open={isModalOpen} onClose={handleCloseModal}>
        <DialogTitle>{t('issueCoupon')}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label={t('couponName')}
            type="text"
            fullWidth
            value={couponName || ''}
            onChange={(e) => setCouponName(e.target.value)}
          />
          <FormControl fullWidth>
            <InputLabel>{t('discountType')}</InputLabel>
            <Select
              value={discountType || ''}
              onChange={(e) => setDiscountType(e.target.value)}
              label={t('discountType')}
            >
              <MenuItem value="percentage">{t('percentageDiscount')}</MenuItem>
              <MenuItem value="amount">{t('amountDiscount')}</MenuItem>
            </Select>
          </FormControl>
          {discountType === 'amount' && (
            <FormControl fullWidth margin="normal">
              <InputLabel id="min-purchase-amount-label">
                {t('minPurchaseAmount')}
              </InputLabel>
              <Select
                labelId="min-purchase-amount-label"
                value={isCustomInput ? 'custom' : minPurchaseAmount}
                onChange={(e) => {
                  if (e.target.value === 'custom') {
                    setIsCustomInput(true);
                  } else {
                    setIsCustomInput(false);
                    setMinPurchaseAmount(e.target.value);
                  }
                }}
              >
                <MenuItem value="5000">5,000원</MenuItem>
                <MenuItem value="10000">10,000원</MenuItem>
                <MenuItem value="15000">15,000원</MenuItem>
                <MenuItem value="custom">{t('customInput')}</MenuItem>
              </Select>
              {isCustomInput && (
                <TextField
                  margin="dense"
                  id="custom-amount"
                  label={t('customAmount', { unit: '원' })}
                  type="number"
                  fullWidth
                  value={customAmount || ''}
                  onChange={(e) => setCustomAmount(e.target.value)}
                />
              )}
            </FormControl>
          )}
          <FormControlLabel
            control={
              <Checkbox
                checked={allProducts}
                onChange={() => setAllProducts(!allProducts)}
              />
            }
            label={t('allProducts')}
          />
          {!allProducts && (
            <FormControl fullWidth margin="normal">
              <InputLabel id="product-select-label">
                {t('selectProduct')}
              </InputLabel>
              <Select
                labelId="product-select-label"
                value={selectedProduct ? selectedProduct.id : ''}
                onChange={(e) =>
                  setSelectedProduct(
                    products.find((p) => p.id === e.target.value)
                  )
                }
              >
                {products.length > 0 ? (
                  products.map((product) => (
                    <MenuItem key={product.id} value={product.id || ''}>
                      {product.productName}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>{t('loadingProducts')}</MenuItem>
                )}
              </Select>
              <Button onClick={handleAddProduct} sx={{ mt: 2 }}>
                {t('addCouponProduct')}
              </Button>
              <List dense>
                {applicableProducts.length > 0 ? (
                  applicableProducts.map((product) => (
                    <ListItem
                      key={product.id}
                      secondaryAction={
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => handleRemoveProduct(product.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText primary={product.productName} />
                    </ListItem>
                  ))
                ) : (
                  <ListItem>
                    <ListItemText primary={t('noApplicableProducts')} />
                  </ListItem>
                )}
              </List>
            </FormControl>
          )}
          <TextField
            margin="dense"
            label={t('discountValue')}
            type="number"
            fullWidth
            value={discount || ''}
            onChange={(e) => setDiscount(e.target.value)}
          />
          {selectedProduct && (
            <TextField
              margin="dense"
              label={t('stock')}
              type="number"
              fullWidth
              value={selectedProduct ? selectedProduct.stock : 0}
              InputProps={{
                readOnly: true,
              }}
            />
          )}
          <TextField
            margin="dense"
            label={t('couponQuantity')}
            type="number"
            fullWidth
            value={quantity || ''}
            onChange={(e) =>
              setQuantity(Math.max(1, parseInt(e.target.value, 10)))
            }
            InputProps={{
              min: 1,
            }}
          />
          <TextField
            margin="dense"
            label={t('startDate')}
            type="date"
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={startDate || ''}
            onChange={(e) => setStartDate(e.target.value)}
          />
          <TextField
            margin="dense"
            label={t('endDate')}
            type="date"
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={endDate || ''}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleIssueCoupon} color="primary">
            {t('issue')}
          </Button>
          <Button onClick={handleCloseModal} color="secondary">
            {t('close')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CouponManagement;
