import moment from 'moment';

class DateFormatter {
    // YYYY-MM-DD 형식으로 날짜를 반환합니다.
    static formatDate(date) {
        return moment(date).format('YYYY-MM-DD');
    }

    static formatTimeStamp = (timestamp) => {
        if (!timestamp) { // timestamp가 null 또는 undefined인지 확인
            return "Date not provided"; // 또는 원하는 기본 메시지나 다른 처리를 여기에 적용
        }
        const { seconds, nanoseconds } = timestamp;
        const milliseconds = seconds * 1000 + nanoseconds / 1000000;
        const dateObj = new Date(milliseconds);
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
        return dateObj.toLocaleDateString(undefined, options);
    }

    // YYYY-MM-DD HH:MM:SS 형식으로 날짜와 시간을 반환합니다.
    static formatDateTime(date) {
        return moment(date).format('YYYY-MM-DD HH:mm:ss');
    }

    // 문자열을 Date 객체로 변환합니다.
    static stringToDate(dateString) {
        return moment(dateString).toDate();
    }

    // 문자열을 지정된 형식에 맞춰 Date 객체로 변환합니다.
    static stringToDateWithFormat(dateString, format) {
        return moment(dateString, format).toDate();
    }
    // 기타 필요한 형식화 함수들...
    
    // 예: 월만 출력
    static formatMonth(date) {
        return moment(date).format('MMMM'); // 'January', 'February'...
    }
    
    // 예: 요일만 출력
    static formatDayOfWeek(date) {
        return moment(date).format('dddd'); // 'Monday', 'Tuesday'...
    }
    
    // 기타 다른 형식의 날짜 형식화 함수들을 여기에 추가할 수 있습니다.
}
export default DateFormatter;
// 사용 예시
// const now = new Date();
// console.log(DateFormatter.formatDate(now)); // 예: 2023-09-19
// console.log(DateFormatter.formatDateTime(now)); // 예: 2023-09-19 15:20:30
// console.log(DateFormatter.formatMonth(now)); // 예: September
// console.log(DateFormatter.formatDayOfWeek(now)); // 예: Tuesday

// const dateStr = "2023-09-19";
// console.log(DateFormatter.stringToDate(dateStr)); // Tue Sep 19 2023 ...
// const dateTimeStr = "2023-09-19 15:20:30";
// console.log(DateFormatter.stringToDateWithFormat(dateTimeStr, 'YYYY-MM-DD HH:mm:ss')); // Tue Sep 19 2023 ...
