// import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import {
//   Container,
//   Box,
//   Button,
//   Typography,
//   IconButton,
//   Paper,
//   Grid,
//   CircularProgress,
//   Snackbar,
//   Alert,
//   ToggleButton,
//   ToggleButtonGroup,
//   Slider,
// } from '@mui/material';
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import {
//   doc,
//   getDoc,
//   updateDoc,
//   collection,
//   query,
//   where,
//   getDocs,
//   writeBatch,
// } from 'firebase/firestore';
// import { db } from './firebaseConfig';
// import GoogleMapMarker from './Common/GoogleMapMarker';
// import { getGeocode, extractLocationName } from './Utils/GeoCoding';

// function MyTown() {
//   const [town1, setTown1] = useState(null);
//   const [town2, setTown2] = useState(null);
//   const [currentLocation, setCurrentLocation] = useState(null);
//   const [locationName, setLocationName] = useState('');
//   const [selectedTown, setSelectedTown] = useState(null);
//   const [radius, setRadius] = useState(10); // 반경 상태 추가
//   const [loading, setLoading] = useState(true);
//   const [openSnackbar, setOpenSnackbar] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState('');
//   const navigate = useNavigate();
//   const currentUser = useSelector((state) => state.auth.currentUser);

//   useEffect(() => {
//     if (currentUser) {
//       const fetchTowns = async () => {
//         const userRef = doc(db, 'users', currentUser.uid);
//         const userDoc = await getDoc(userRef);
//         if (userDoc.exists()) {
//           const userData = userDoc.data();
//           setTown1(userData.town1);
//           setTown2(userData.town2);
//           setSelectedTown(userData.selectedTown); // 로딩 시 선택된 상태 반영

//           // 초기 위치 설정
//           if (userData.selectedTown === 1 && userData.town1) {
//             setCurrentLocation({
//               lat: userData.town1.lat,
//               lng: userData.town1.lng,
//             });
//           } else if (userData.selectedTown === 2 && userData.town2) {
//             setCurrentLocation({
//               lat: userData.town2.lat,
//               lng: userData.town2.lng,
//             });
//           }
//         }
//         setLoading(false);
//       };
//       fetchTowns();
//     }
//   }, [currentUser]);

//   const updateProductLocations = async (townNumber, newLocation) => {
//     const productsRef = collection(db, 'products');
//     const q = query(
//       productsRef,
//       where('townNumber', '==', townNumber),
//       where('userId', '==', currentUser.uid)
//     );
//     const querySnapshot = await getDocs(q);

//     const batch = writeBatch(db);
//     querySnapshot.forEach((doc) => {
//       const productRef = doc.ref;
//       batch.update(productRef, { location: newLocation });
//     });

//     await batch.commit();
//   };

//   const handleTownClick = async (townNumber) => {
//     if (!currentUser) return;
//     if (!currentLocation) {
//       setSnackbarMessage('Please select a location on the map.');
//       setOpenSnackbar(true);
//       return;
//     }

//     const locationData = {
//       name: locationName || `Selected Location ${townNumber}`, // Geocoding API에서 얻은 이름 사용
//       ...currentLocation,
//       radius, // 반경 정보 추가
//     };

//     const userRef = doc(db, 'users', currentUser.uid);

//     if (townNumber === 1) {
//       if (
//         town2 &&
//         currentLocation.lat === town2.lat &&
//         currentLocation.lng === town2.lng
//       ) {
//         setSnackbarMessage(
//           'This location is already assigned to Town 2. Please choose a different location.'
//         );
//         setOpenSnackbar(true);
//         return;
//       }
//       setTown1(locationData);
//     } else {
//       if (
//         town1 &&
//         currentLocation.lat === town1.lat &&
//         currentLocation.lng === town1.lng
//       ) {
//         setSnackbarMessage(
//           'This location is already assigned to Town 1. Please choose a different location.'
//         );
//         setOpenSnackbar(true);
//         return;
//       }
//       setTown2(locationData);
//     }

//     await updateDoc(userRef, {
//       [`town${townNumber}`]: locationData,
//       selectedTown: townNumber, // 선택된 위치 업데이트
//     });

//     await updateProductLocations(townNumber, currentLocation);

//     setSelectedTown(townNumber); // 상태 업데이트
//   };

//   const handleCloseSnackbar = () => {
//     setOpenSnackbar(false);
//     setSnackbarMessage('');
//   };

//   const handleLocationSelect = async (location) => {
//     setCurrentLocation(location);
//     try {
//       const geocodeResult = await getGeocode(location.lat, location.lng);
//       const locationName = extractLocationName(geocodeResult);
//       setLocationName(
//         `${locationName.sublocality || locationName.locality || ''}, ${
//           locationName.country
//         }`
//       );
//     } catch (error) {
//       setSnackbarMessage('Failed to fetch location name');
//       setOpenSnackbar(true);
//     }
//   };

//   const handleToggleChange = (event, newTown) => {
//     if (newTown !== null) {
//       setSelectedTown(newTown);
//       if (newTown === 1 && town1) {
//         setCurrentLocation({ lat: town1.lat, lng: town1.lng });
//       } else if (newTown === 2 && town2) {
//         setCurrentLocation({ lat: town2.lat, lng: town2.lng });
//       }
//     }
//   };

//   const handleRadiusChange = (event, newValue) => {
//     setRadius(newValue);
//   };

//   if (loading) {
//     return (
//       <Container maxWidth="sm">
//         <Box
//           display="flex"
//           justifyContent="center"
//           alignItems="center"
//           minHeight="100vh"
//         >
//           <CircularProgress />
//         </Box>
//       </Container>
//     );
//   }

//   return (
//     <Container maxWidth="sm" sx={{ height: '100vh' }}>
//       <Paper elevation={3} sx={{ padding: 3, marginTop: 4, height: '90%' }}>
//         <Box display="flex" alignItems="center" mb={2}>
//           <IconButton onClick={() => navigate('/settings')} color="primary">
//             <ArrowBackIcon />
//           </IconButton>
//           <Typography variant="h5" component="h1" gutterBottom>
//             내 동네 설정
//           </Typography>
//         </Box>

//         <Box sx={{ flex: 1 }}>
//           <GoogleMapMarker
//             onLocationSelect={handleLocationSelect}
//             initialPosition={currentLocation}
//           />
//         </Box>
//         {locationName && (
//           <Box mt={2}>
//             <Typography variant="subtitle1">
//               선택된 위치: {locationName}
//             </Typography>
//           </Box>
//         )}
//         <Box mt={2}>
//           <Typography variant="h6" gutterBottom>
//             반경 설정 (km)
//           </Typography>
//           <Slider
//             value={radius}
//             onChange={handleRadiusChange}
//             aria-labelledby="radius-slider"
//             valueLabelDisplay="auto"
//             step={1}
//             marks
//             min={1}
//             max={20}
//           />
//         </Box>
//         <Box mt={8}>
//           <Typography variant="h6" gutterBottom>
//             내동네
//           </Typography>
//           <ToggleButtonGroup
//             value={selectedTown}
//             exclusive
//             onChange={handleToggleChange}
//             aria-label="town selection"
//             fullWidth
//           >
//             <ToggleButton
//               value={1}
//               onClick={() => handleTownClick(1)}
//               aria-label="town1"
//             >
//               {town1 ? town1.name : '추가'}
//             </ToggleButton>
//             <ToggleButton
//               value={2}
//               onClick={() => handleTownClick(2)}
//               aria-label="town2"
//             >
//               {town2 ? town2.name : '추가'}
//             </ToggleButton>
//           </ToggleButtonGroup>
//         </Box>
//       </Paper>

//       <Snackbar
//         open={openSnackbar}
//         autoHideDuration={6000}
//         onClose={handleCloseSnackbar}
//       >
//         <Alert
//           onClose={handleCloseSnackbar}
//           severity="warning"
//           sx={{ width: '100%' }}
//         >
//           {snackbarMessage}
//         </Alert>
//       </Snackbar>
//     </Container>
//   );
// }

// export default MyTown;

// import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import {
//   Container,
//   Box,
//   Button,
//   Typography,
//   IconButton,
//   Paper,
//   Grid,
//   CircularProgress,
//   Snackbar,
//   Alert,
//   ToggleButton,
//   ToggleButtonGroup,
//   Slider,
// } from '@mui/material';
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import {
//   doc,
//   getDoc,
//   updateDoc,
//   collection,
//   query,
//   where,
//   getDocs,
//   writeBatch,
//   setDoc,
// } from 'firebase/firestore';
// import { db } from '../firebaseConfig';
// import GoogleMapMarker from '../Common/GoogleMapMarker';
// import { getGeocode, extractLocationName } from '../Utils/GeoCoding';
// import { useTranslation } from 'react-i18next';

// function MyTown({ onPrev, onNext, setIsStepCompleted }) {
//   const { t } = useTranslation();
//   const [town1, setTown1] = useState(null);
//   const [town2, setTown2] = useState(null);
//   const [currentLocation, setCurrentLocation] = useState(null);
//   const [locationName, setLocationName] = useState('');
//   const [selectedTown, setSelectedTown] = useState(null);
//   const [radius, setRadius] = useState(10); // 반경 상태 추가
//   const [loading, setLoading] = useState(true);
//   const [openSnackbar, setOpenSnackbar] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState('');
//   const navigate = useNavigate();
//   const currentUser = useSelector((state) => state.auth.currentUser);

//   useEffect(() => {
//     const fetchTowns = async () => {
//       if (currentUser) {
//         const userRef = doc(db, 'users', currentUser.uid);
//         const userDoc = await getDoc(userRef);
//         if (userDoc.exists()) {
//           const userData = userDoc.data();
//           setTown1(userData.town1);
//           setTown2(userData.town2);
//           setSelectedTown(userData.selectedTown); // 로딩 시 선택된 상태 반영

//           // 초기 위치 설정
//           if (userData.selectedTown === 1 && userData.town1) {
//             setCurrentLocation({
//               lat: userData.town1.lat,
//               lng: userData.town1.lng,
//             });
//             setRadius(userData.town1.radius);
//           } else if (userData.selectedTown === 2 && userData.town2) {
//             setCurrentLocation({
//               lat: userData.town2.lat,
//               lng: userData.town2.lng,
//             });
//             setRadius(userData.town2.radius);
//           }
//         }
//       }
//       setLoading(false);
//     };
//     fetchTowns();
//   }, [currentUser]);

//   const updateProductLocations = async (townNumber, newLocation) => {
//     const productsRef = collection(db, 'products');
//     const q = query(
//       productsRef,
//       where('townNumber', '==', townNumber),
//       where('userId', '==', currentUser.uid)
//     );
//     const querySnapshot = await getDocs(q);

//     const batch = writeBatch(db);
//     querySnapshot.forEach((doc) => {
//       const productRef = doc.ref;
//       batch.update(productRef, { location: newLocation });
//     });

//     await batch.commit();
//   };

//   const handleTownClick = async (townNumber) => {
//     if (!currentUser) {
//       setSnackbarMessage(t('userNotLoggedIn'));
//       setOpenSnackbar(true);
//       return;
//     }
//     if (!currentLocation) {
//       setSnackbarMessage(t('myTown.selectLocation'));
//       setOpenSnackbar(true);
//       return;
//     }

//     const locationData = {
//       name:
//         locationName || t('myTown.selectedLocation', { number: townNumber }), // Geocoding API에서 얻은 이름 사용
//       ...currentLocation,
//       radius, // 반경 정보 추가
//     };

//     const userRef = doc(db, 'users', currentUser.uid);
//     const userDoc = await getDoc(userRef);

//     if (townNumber === 1) {
//       if (
//         town2 &&
//         currentLocation.lat === town2.lat &&
//         currentLocation.lng === town2.lng
//       ) {
//         setSnackbarMessage(t('myTown.locationAssignedToTown2'));
//         setOpenSnackbar(true);
//         return;
//       }
//       setTown1(locationData);
//     } else {
//       if (
//         town1 &&
//         currentLocation.lat === town1.lat &&
//         currentLocation.lng === town1.lng
//       ) {
//         setSnackbarMessage(t('myTown.locationAssignedToTown1'));
//         setOpenSnackbar(true);
//         return;
//       }
//       setTown2(locationData);
//     }

//     if (userDoc.exists()) {
//       // 문서가 존재할 경우 업데이트
//       await updateDoc(userRef, {
//         [`town${townNumber}`]: locationData,
//         selectedTown: townNumber, // 선택된 위치 업데이트
//       });
//     } else {
//       // 문서가 존재하지 않을 경우 새로 생성
//       await setDoc(userRef, {
//         [`town${townNumber}`]: locationData,
//         selectedTown: townNumber, // 선택된 위치 업데이트
//       });
//     }

//     await updateProductLocations(townNumber, currentLocation);

//     setSelectedTown(townNumber); // 상태 업데이트
//     if (town1 && town2) {
//       await recordStepAndMoveNext();
//     }
//   };

//   const recordStepAndMoveNext = async () => {
//     try {
//       const userInfo = {
//         step: process.env.REACT_APP_USER_TOWN_VERIFIED,
//       };
//       await upsertUserData(currentUser, userInfo);
//       if (setIsStepCompleted) {
//         setIsStepCompleted(true);
//       }
//       if (onNext) {
//         onNext();
//       }
//     } catch (error) {
//       console.error('Error completing step:', error);
//       setSnackbarMessage('Error completing step. Please try again.');
//       setOpenSnackbar(true);
//     }
//   };
//   const upsertUserData = async (user, userInfo) => {
//     try {
//       const userRef = doc(db, 'users', user.uid);
//       await setDoc(
//         userRef,
//         {
//           ...userInfo,
//           createdAt: new Date(),
//           modifiedAt: new Date(),
//         },
//         { merge: true }
//       );
//     } catch (error) {
//       setSnackbarMessage('Failed to update user data.', error.message);
//     }
//   };
//   const handleCloseSnackbar = () => {
//     setOpenSnackbar(false);
//     setSnackbarMessage('');
//   };

//   const handleLocationSelect = async (location) => {
//     setCurrentLocation(location);
//     try {
//       const geocodeResult = await getGeocode(location.lat, location.lng);
//       const locationName = extractLocationName(geocodeResult);
//       setLocationName(
//         `${locationName.sublocality || locationName.locality || ''}, ${
//           locationName.country
//         }`
//       );
//     } catch (error) {
//       setSnackbarMessage(t('myTown.failedToFetchLocationName'));
//       setOpenSnackbar(true);
//     }
//   };

//   const handleToggleChange = (event, newTown) => {
//     if (newTown !== null) {
//       setSelectedTown(newTown);
//       if (newTown === 1 && town1) {
//         setCurrentLocation({ lat: town1.lat, lng: town1.lng });
//         setRadius(town1.radius);
//       } else if (newTown === 2 && town2) {
//         setCurrentLocation({ lat: town2.lat, lng: town2.lng });
//         setRadius(town2.radius);
//       }
//     }
//   };

//   const handleRadiusChange = async (event, newValue) => {
//     setRadius(newValue);
//     if (selectedTown === 1 && town1) {
//       const updatedTown1 = { ...town1, radius: newValue };
//       setTown1(updatedTown1);
//       await updateDoc(doc(db, 'users', currentUser.uid), {
//         town1: updatedTown1,
//       });
//     } else if (selectedTown === 2 && town2) {
//       const updatedTown2 = { ...town2, radius: newValue };
//       setTown2(updatedTown2);
//       await updateDoc(doc(db, 'users', currentUser.uid), {
//         town2: updatedTown2,
//       });
//     }
//   };

//   if (loading) {
//     return (
//       <Container maxWidth="sm">
//         <Box
//           display="flex"
//           justifyContent="center"
//           alignItems="center"
//           minHeight="100vh"
//         >
//           <CircularProgress />
//         </Box>
//       </Container>
//     );
//   }

//   return (
//     <Container maxWidth="sm" sx={{ height: '100vh' }}>
//       <Paper elevation={3} sx={{ padding: 3, marginTop: 4, height: '100%' }}>
//         <Box display="flex" alignItems="center" mb={2}>
//           <IconButton onClick={() => navigate('/settings')} color="primary">
//             <ArrowBackIcon />
//           </IconButton>
//           <Typography variant="h5" component="h1" gutterBottom>
//             {t('myTown.title')}
//           </Typography>
//         </Box>

//         <Box sx={{ flex: 1 }}>
//           <GoogleMapMarker
//             onLocationSelect={handleLocationSelect}
//             initialPosition={currentLocation}
//           />
//         </Box>
//         {locationName && (
//           <Box mt={2}>
//             <Typography variant="subtitle1">
//               {t('myTown.selectedLocationName')}: {locationName}
//             </Typography>
//           </Box>
//         )}
//         <Box mt={2}>
//           <Typography variant="h6" gutterBottom>
//             {t('myTown.radius')} (km)
//           </Typography>
//           <Slider
//             value={radius}
//             onChange={handleRadiusChange}
//             aria-labelledby="radius-slider"
//             valueLabelDisplay="auto"
//             step={1}
//             marks
//             min={1}
//             max={20}
//           />
//         </Box>
//         <Box mt={8}>
//           <Typography variant="h6" gutterBottom>
//             {t('myTown.myTown')}
//           </Typography>
//           <ToggleButtonGroup
//             value={selectedTown}
//             exclusive
//             onChange={handleToggleChange}
//             aria-label="town selection"
//             fullWidth
//           >
//             <ToggleButton
//               value={1}
//               onClick={() => handleTownClick(1)}
//               aria-label="town1"
//             >
//               {town1 ? town1.name : t('myTown.add')}
//             </ToggleButton>
//             <ToggleButton
//               value={2}
//               onClick={() => handleTownClick(2)}
//               aria-label="town2"
//             >
//               {town2 ? town2.name : t('myTown.add')}
//             </ToggleButton>
//           </ToggleButtonGroup>
//         </Box>
//       </Paper>

//       <Snackbar
//         open={openSnackbar}
//         autoHideDuration={6000}
//         onClose={handleCloseSnackbar}
//       >
//         <Alert
//           onClose={handleCloseSnackbar}
//           severity="warning"
//           sx={{ width: '100%' }}
//         >
//           {snackbarMessage}
//         </Alert>
//       </Snackbar>
//     </Container>
//   );
// }

// export default MyTown;

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Box,
  Button,
  Typography,
  IconButton,
  Paper,
  Grid,
  CircularProgress,
  Snackbar,
  Alert,
  ToggleButton,
  ToggleButtonGroup,
  Slider,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  doc,
  getDoc,
  updateDoc,
  collection,
  query,
  where,
  getDocs,
  writeBatch,
  setDoc,
} from 'firebase/firestore';
import { db } from '../firebaseConfig';
import GoogleMapMarker from '../Common/GoogleMapMarker';
import { getGeocode, extractLocationName } from '../Utils/GeoCoding';
import { useTranslation } from 'react-i18next';

function MyTown({ onPrev, onNext, setIsStepCompleted }) {
  const { t } = useTranslation();
  const [town1, setTown1] = useState(null);
  const [town2, setTown2] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [locationName, setLocationName] = useState('');
  const [selectedTown, setSelectedTown] = useState(null);
  const [radius, setRadius] = useState(10); // 반경 상태 추가
  const [loading, setLoading] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.auth.currentUser);

  useEffect(() => {
    const fetchTowns = async () => {
      if (currentUser) {
        const userRef = doc(db, 'users', currentUser.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setTown1(userData.town1);
          setTown2(userData.town2);
          setSelectedTown(userData.selectedTown); // 로딩 시 선택된 상태 반영

          // 초기 위치 설정
          if (userData.selectedTown === 1 && userData.town1) {
            setCurrentLocation({
              lat: userData.town1.lat,
              lng: userData.town1.lng,
            });
            setRadius(userData.town1.radius);
          } else if (userData.selectedTown === 2 && userData.town2) {
            setCurrentLocation({
              lat: userData.town2.lat,
              lng: userData.town2.lng,
            });
            setRadius(userData.town2.radius);
          }
        }
      }
      setLoading(false);
    };
    fetchTowns();
  }, [currentUser]);

  const updateProductLocations = async (townNumber, newLocation) => {
    const productsRef = collection(db, 'products');
    const q = query(
      productsRef,
      where('townNumber', '==', townNumber),
      where('userId', '==', currentUser.uid)
    );
    const querySnapshot = await getDocs(q);

    const batch = writeBatch(db);
    querySnapshot.forEach((doc) => {
      const productRef = doc.ref;
      batch.update(productRef, { location: newLocation });
    });

    await batch.commit();
  };

  const handleTownClick = async (townNumber) => {
    if (!currentUser) {
      setSnackbarMessage(t('userNotLoggedIn'));
      setOpenSnackbar(true);
      return;
    }
    if (!currentLocation) {
      setSnackbarMessage(t('myTown.selectLocation'));
      setOpenSnackbar(true);
      return;
    }

    const locationData = {
      name:
        locationName || t('myTown.selectedLocation', { number: townNumber }), // Geocoding API에서 얻은 이름 사용
      ...currentLocation,
      radius, // 반경 정보 추가
    };

    const userRef = doc(db, 'users', currentUser.uid);
    const userDoc = await getDoc(userRef);

    if (townNumber === 1) {
      if (
        town2 &&
        currentLocation.lat === town2.lat &&
        currentLocation.lng === town2.lng
      ) {
        setSnackbarMessage(t('myTown.locationAssignedToTown2'));
        setOpenSnackbar(true);
        return;
      }
      setTown1(locationData);
    } else {
      if (
        town1 &&
        currentLocation.lat === town1.lat &&
        currentLocation.lng === town1.lng
      ) {
        setSnackbarMessage(t('myTown.locationAssignedToTown1'));
        setOpenSnackbar(true);
        return;
      }
      setTown2(locationData);
    }

    if (userDoc.exists()) {
      // 문서가 존재할 경우 업데이트
      await updateDoc(userRef, {
        [`town${townNumber}`]: locationData,
        selectedTown: townNumber, // 선택된 위치 업데이트
      });
    } else {
      // 문서가 존재하지 않을 경우 새로 생성
      await setDoc(userRef, {
        [`town${townNumber}`]: locationData,
        selectedTown: townNumber, // 선택된 위치 업데이트
      });
    }

    await updateProductLocations(townNumber, currentLocation);

    setSelectedTown(townNumber); // 상태 업데이트
    if (town1 && town2) {
      await recordStepAndMoveNext();
    }
  };

  const recordStepAndMoveNext = async () => {
    try {
      const userInfo = {
        step: process.env.REACT_APP_USER_TOWN_VERIFIED,
      };
      await upsertUserData(currentUser, userInfo);
      if (setIsStepCompleted) {
        setIsStepCompleted(true);
      }
      if (onNext) {
        onNext();
      }
    } catch (error) {
      console.error('Error completing step:', error);
      setSnackbarMessage('Error completing step. Please try again.');
      setOpenSnackbar(true);
    }
  };
  const upsertUserData = async (user, userInfo) => {
    try {
      const userRef = doc(db, 'users', user.uid);
      await setDoc(
        userRef,
        {
          ...userInfo,
          createdAt: new Date(),
          modifiedAt: new Date(),
        },
        { merge: true }
      );
    } catch (error) {
      setSnackbarMessage('Failed to update user data.', error.message);
    }
  };
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
    setSnackbarMessage('');
  };

  const handleLocationSelect = async (location) => {
    setCurrentLocation(location);
    try {
      const geocodeResult = await getGeocode(location.lat, location.lng);
      const locationName = extractLocationName(geocodeResult);
      setLocationName(
        `${locationName.sublocality || locationName.locality || ''}, ${
          locationName.country
        }`
      );
    } catch (error) {
      setSnackbarMessage(t('myTown.failedToFetchLocationName'));
      setOpenSnackbar(true);
    }
  };

  const handleToggleChange = (event, newTown) => {
    if (newTown !== null) {
      setSelectedTown(newTown);
      if (newTown === 1 && town1) {
        setCurrentLocation({ lat: town1.lat, lng: town1.lng });
        setRadius(town1.radius);
      } else if (newTown === 2 && town2) {
        setCurrentLocation({ lat: town2.lat, lng: town2.lng });
        setRadius(town2.radius);
      }
    }
  };

  const handleRadiusChange = async (event, newValue) => {
    setRadius(newValue);
    if (selectedTown === 1 && town1) {
      const updatedTown1 = { ...town1, radius: newValue };
      setTown1(updatedTown1);
      await updateDoc(doc(db, 'users', currentUser.uid), {
        town1: updatedTown1,
      });
    } else if (selectedTown === 2 && town2) {
      const updatedTown2 = { ...town2, radius: newValue };
      setTown2(updatedTown2);
      await updateDoc(doc(db, 'users', currentUser.uid), {
        town2: updatedTown2,
      });
    }
  };

  if (loading) {
    return (
      <Container maxWidth="sm">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  return (
    <Container
      maxWidth="sm"
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Paper
        elevation={3}
        sx={{
          padding: 3,
          marginTop: 3,
          marginBottom: 3,
          flex: 1,
          overflow: 'auto',
        }}
      >
        <Box display="flex" alignItems="center" mb={2}>
          <IconButton onClick={() => navigate('/settings')} color="primary">
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h5" component="h1" gutterBottom>
            {t('myTown.title')}
          </Typography>
        </Box>

        <Box sx={{ flex: 1 }}>
          <GoogleMapMarker
            onLocationSelect={handleLocationSelect}
            initialPosition={currentLocation}
          />
        </Box>
        {locationName && (
          <Box mt={2}>
            <Typography variant="subtitle1">
              {t('myTown.selectedLocationName')}: {locationName}
            </Typography>
          </Box>
        )}
        <Box mt={2}>
          <Typography variant="h6" gutterBottom>
            {t('myTown.radius')} ({radius} km)
          </Typography>
          <Slider
            value={radius}
            onChange={handleRadiusChange}
            aria-labelledby="radius-slider"
            valueLabelDisplay="auto"
            step={1}
            marks
            min={1}
            max={20}
          />
        </Box>
        <Box mt={8}>
          <Typography variant="h6" gutterBottom>
            {t('myTown.myTown')}
          </Typography>
          <ToggleButtonGroup
            value={selectedTown}
            exclusive
            onChange={handleToggleChange}
            aria-label="town selection"
            fullWidth
          >
            <ToggleButton
              value={1}
              onClick={() => handleTownClick(1)}
              aria-label="town1"
            >
              {town1 ? town1.name : t('myTown.add')}
            </ToggleButton>
            <ToggleButton
              value={2}
              onClick={() => handleTownClick(2)}
              aria-label="town2"
            >
              {town2 ? town2.name : t('myTown.add')}
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Paper>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="warning"
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default MyTown;
