import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Box,
  Button,
  Typography,
  CircularProgress,
  Snackbar,
  Alert,
} from '@mui/material';
import { deleteUser, getAuth } from 'firebase/auth';
import {
  doc,
  deleteDoc,
  collection,
  query,
  where,
  getDocs,
  writeBatch,
} from 'firebase/firestore';
import { db } from './firebaseConfig';
import { setCurrentUser } from './Redux/reducers/authReducer';
import useMessageBox from './Common/useMessageBox';

const DeleteUserAccount = () => {
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const currentUser = useSelector((state) => state.auth.currentUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [MessageBoxInform, MessageBoxConfirm, MessageBoxRender] =
    useMessageBox();
  const handleDeleteAccount = async () => {
    const isConfirmed = await MessageBoxConfirm('Are you sure?');
    if (!isConfirmed) return;

    if (!currentUser) return;

    setLoading(true);
    try {
      const auth = getAuth();
      const user = auth.currentUser;

      // Delete user data from Firestore
      await deleteUserData(user.uid);

      // Delete user authentication
      await deleteUser(user);

      // Clear user from Redux state
      dispatch(setCurrentUser(null));

      // Navigate to home or login page
      navigate('/');

      setSnackbarMessage('Your account has been deleted successfully.');
      setOpenSnackbar(true);
    } catch (error) {
      setSnackbarMessage(`Failed to delete account: ${error.message}`);
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  const deleteUserData = async (userId) => {
    const userRef = doc(db, 'users', userId);
    await deleteDoc(userRef);

    // Delete user's products
    const productsRef = collection(db, 'products');
    const q = query(productsRef, where('userId', '==', userId));
    const querySnapshot = await getDocs(q);
    const batch = writeBatch(db);
    querySnapshot.forEach((doc) => {
      batch.delete(doc.ref);
    });
    await batch.commit();
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
    setSnackbarMessage('');
  };

  return (
    <Container
      maxWidth="sm"
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {MessageBoxRender()}
      <Box textAlign="center">
        <Typography variant="h5" gutterBottom>
          Delete User Account
        </Typography>
        <Typography variant="body1" gutterBottom>
          Are you sure you want to delete your account? This action is
          irreversible.
        </Typography>
        {loading ? (
          <CircularProgress />
        ) : (
          <Button
            variant="contained"
            color="secondary"
            onClick={handleDeleteAccount}
          >
            Delete My Account
          </Button>
        )}
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="warning"
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default DeleteUserAccount;
