// import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import { db, doc, getDoc } from '../../firebaseConfig';
// import { LinearProgress, Button } from '@mui/material';
// import { useNavigate } from "react-router-dom";
// import SignUpCreateAccount from './SignUpCreateAccount';
// import EmailVerification from './EmailVerification';
// import PhoneNumberVerification from './PhoneNumberVerification';
// import StoreProfile from './StoreProfile';
// import StorePosition from './StorePosition';
// import StoreDeliveryArea from './StoreDeliveryArea';
// import SignUpConfirm from './SignUpConfirm';
// import SignUpComplete from './SignUpComplete';
// import SignUpStatus from './SignUpStatus';
// import SignUpEditPassword from './SignUpEditPassword';
// // import '../css/temp.css';
// import '../css/SignUpFlow.css';

// function StoreSignUpFlow(props) {
//     const navigate = useNavigate();
//     const [mode, setMode] = useState(props.mode || 0);
//     const [step, setStep] = useState(1);
//     const totalSteps = 8;
//     const [isStepCompleted, setIsStepCompleted] = useState(false);
//     const currentUser = useSelector((state) => state.auth.currentUser);
//     const editStep = useSelector((state) => state.edit.step);

//     useEffect(() => {
//         setMode(props.mode);
//     }, [props.mode]);
//     useEffect(() => {
//         if (editStep) {
//             setStep(editStep);
//         } else {
//             // handle invalid step or navigate to an error page
//         }
//     }, [editStep]);
//     useEffect(() => {
//         const checkPendingRegistration = async () => {
//             if (currentUser && mode === 1) {

//                 const userRef = doc(db, 'users', currentUser.uid);
//                 const userSnapshot = await getDoc(userRef);

//                 if (userSnapshot.exists()) {
//                     const userData = userSnapshot.data();

//                     if (userData.pendingRegistrations &&
//                         userData.pendingRegistrations.step &&
//                         userData.pendingRegistrations.step.trim().length > 0) {

//                         determineNextStep(userData.pendingRegistrations.step);

//                     }
//                     else {
//                         determineNextStep(process.env.REACT_APP_STORE_CREATE_ACCOUNT);
//                     }
//                 }
//             }
//         };

//         checkPendingRegistration();
//     }, [currentUser]);

//     const handleBack = () => {
//         setStep((prev) => Math.max(prev - 1, 1));  // Don't go below step 1
//     };

//     const determineNextStep = (step) => {
//         switch (step) {
//             case process.env.REACT_APP_STORE_CREATE_ACCOUNT:
//                 setStep(1);
//                 break;
//             case process.env.REACT_APP_CREATE_ACCOUNT_VERIFIED:
//                 setStep(2);
//                 break;
//             case process.env.REACT_APP_EMAIL_VERIFIED:
//                 setStep(3);
//                 break;
//             case process.env.REACT_APP_PHONE_NUMBER_VERIFIED:
//                 setStep(4);
//                 break;
//             case process.env.REACT_APP_STORE_PROFILE_VERIFIED:
//                 setStep(5);
//                 break;
//             case process.env.REACT_APP_STORE_DELIVERYAREA_VERIFIED:
//                 setStep(6);
//                 break;
//             case process.env.REACT_APP_STORE_POSITION_VERIFIED:
//                 setStep(7);
//                 break;
//             case process.env.REACT_APP_STORE_SIGNUP_CONFIRM:
//                 setStep(8);
//                 break;
//             case process.env.REACT_APP_STORE_SIGNUP_COMPLETED:
//                 setStep(9);
//                 break;
//             default:
//                 setStep(1);
//                 break;
//         }
//     };

//     const handleNextClick = () => {
//         if (!isStepCompleted) {
//             alert("이 단계를 완료해주세요!");
//             return;
//         }

//         setStep((prev) => prev + 1);
//         setIsStepCompleted(false); // 다음 단계로 이동할 때 완료 상태 초기화
//     };

//     const handlePrevClick = () => {
//         if (step > 1) {  // 첫 번째 단계보다 높은 경우에만 이전 단계로 이동 가능
//             setStep((prev) => prev - 1);
//         }
//     };
//     const handleCloseClick = () => {
//         props.closeModal();
//         navigate('/');
//         console.log('Close button clicked!');
//     };
//     const handleCompleteClick = () => {
//         props.closeModal();
//         console.log('Complete button clicked!');
//     };

//     const renderStepContent = () => {
//         const commonProps = {
//             onNext: handleNextClick,
//             onPrev: handlePrevClick,
//             onClose: handleCloseClick,
//             setIsStepCompleted,
//         };

//         switch (step) {
//             case 1:
//                 if (mode === 2) {
//                     return <SignUpEditPassword {...commonProps} />;
//                 }
//                 return <SignUpCreateAccount {...commonProps} prevDisabled />;
//             case 2:
//                 return <EmailVerification {...commonProps} />;
//             case 3:
//                 return <PhoneNumberVerification {...commonProps} />;
//             case 4:
//                 return <StoreProfile {...commonProps} />;
//             case 5:
//                 return <StoreDeliveryArea {...commonProps} />;
//             case 6:
//                 return <StorePosition {...commonProps} />;
//             case 7:
//                 return <SignUpConfirm {...commonProps} />;
//             case 8:
//                 return <SignUpComplete {...commonProps} nextDisabled />;
//             case 9:
//                 return <SignUpStatus {...commonProps} />;
//             default:
//                 return <div>Error:step is {step}</div>;
//         }
//     };

//     return (
//         <div className='signup-flow'>
//             <div className='container'>
//                 <div>
//                     <button className="close-button" onClick={handleCloseClick}>x</button>  {/* "x" 버튼 추가 */}
//                 </div>

//                 <div className="step-content">
//                     {renderStepContent()}
//                 </div>
//                 <div>
//                     <LinearProgress variant="determinate" value={(step / totalSteps) * 100} />
//                 </div>
//                 <div className="navigation-buttons">
//                     {mode === 1 && step > 1 && step !== 9 && <button className='prevnext-button' onClick={handlePrevClick}>이전</button>}
//                     {mode === 1 && step < 8 && <button className='prevnext-button' onClick={handleNextClick}>다음</button>}
//                     {mode === 2 && <button className='prevnext-button' onClick={handleCompleteClick}>완료</button>}
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default StoreSignUpFlow;

// import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import Container from '@mui/material/Container';
// import Button from '@mui/material/Button';
// import LinearProgress from '@mui/material/LinearProgress';
// import CloseIcon from '@mui/icons-material/Close';
// import IconButton from '@mui/material/IconButton';
// import { Box } from '@mui/material';
// import { db, doc, getDoc } from '../../firebaseConfig';
// import SignUpCreateAccount from './SignUpCreateAccount';
// import EmailVerification from './EmailVerification';
// import PhoneNumberVerification from './PhoneNumberVerification';
// import StoreProfile from './StoreProfile';
// import StorePosition from './StorePosition';
// import StoreDeliveryArea from './StoreDeliveryArea';
// import SignUpConfirm from './SignUpConfirm';
// import SignUpComplete from './SignUpComplete';
// import SignUpStatus from './SignUpStatus';
// import SignUpEditPassword from './SignUpEditPassword';
// import '../css/SignUpFlow.css';

// function StoreSignUpFlow(props) {
//   const navigate = useNavigate();
//   const [mode, setMode] = useState(props.mode || 0);
//   const [step, setStep] = useState(1);
//   const totalSteps = 8;
//   const [isStepCompleted, setIsStepCompleted] = useState(false);
//   const currentUser = useSelector((state) => state.auth.currentUser);
//   const editStep = useSelector((state) => state.edit.step);

//   useEffect(() => {
//     setMode(props.mode);
//   }, [props.mode]);
//   useEffect(() => {
//     if (editStep) {
//       setStep(editStep);
//     }
//   }, [editStep]);
//   useEffect(() => {
//     const checkPendingRegistration = async () => {
//       if (currentUser && mode === 1) {
//         const userRef = doc(db, 'users', currentUser.uid);
//         const userSnapshot = await getDoc(userRef);
//         if (userSnapshot.exists()) {
//           const userData = userSnapshot.data();
//           if (
//             userData.pendingRegistrations &&
//             userData.pendingRegistrations.step &&
//             userData.pendingRegistrations.step.trim().length > 0
//           ) {
//             determineNextStep(userData.pendingRegistrations.step);
//           } else {
//             determineNextStep(process.env.REACT_APP_STORE_CREATE_ACCOUNT);
//           }
//         }
//       }
//     };
//     checkPendingRegistration();
//   }, [currentUser]);

//   const handleNextClick = () => {
//     if (!isStepCompleted) {
//       alert('이 단계를 완료해주세요!');
//       return;
//     }

//     setStep((prev) => prev + 1);
//     setIsStepCompleted(false); // 다음 단계로 이동할 때 완료 상태 초기화
//   };

//   const handlePrevClick = () => {
//     if (step > 1) {
//       // 첫 번째 단계보다 높은 경우에만 이전 단계로 이동 가능
//       setStep((prev) => prev - 1);
//     }
//   };
//   const handleCloseClick = () => {
//     props.closeModal();
//     navigate('/');
//     console.log('Close button clicked!');
//   };
//   const handleCompleteClick = () => {
//     props.closeModal();
//     console.log('Complete button clicked!');
//   };
//   const determineNextStep = (step) => {
//     switch (step) {
//       case process.env.REACT_APP_STORE_CREATE_ACCOUNT:
//         setStep(1);
//         break;
//       case process.env.REACT_APP_CREATE_ACCOUNT_VERIFIED:
//         setStep(2);
//         break;
//       case process.env.REACT_APP_EMAIL_VERIFIED:
//         setStep(3);
//         break;
//       case process.env.REACT_APP_PHONE_NUMBER_VERIFIED:
//         setStep(4);
//         break;
//       case process.env.REACT_APP_STORE_PROFILE_VERIFIED:
//         setStep(5);
//         break;
//       case process.env.REACT_APP_STORE_DELIVERYAREA_VERIFIED:
//         setStep(6);
//         break;
//       case process.env.REACT_APP_STORE_POSITION_VERIFIED:
//         setStep(7);
//         break;
//       case process.env.REACT_APP_STORE_SIGNUP_CONFIRM:
//         setStep(8);
//         break;
//       case process.env.REACT_APP_STORE_SIGNUP_COMPLETED:
//         setStep(9);
//         break;
//       default:
//         setStep(1);
//         break;
//     }
//   };
//   const renderStepContent = () => {
//     const commonProps = {
//       onNext: handleNextClick,
//       onPrev: handlePrevClick,
//       onClose: handleCloseClick,
//       setIsStepCompleted,
//     };

//     switch (step) {
//       case 1:
//         return mode === 2 ? (
//           <SignUpEditPassword {...commonProps} />
//         ) : (
//           <SignUpCreateAccount {...commonProps} />
//         );
//       case 2:
//         return <EmailVerification {...commonProps} />;
//       case 3:
//         return <PhoneNumberVerification {...commonProps} />;
//       case 4:
//         return <StoreProfile {...commonProps} />;
//       case 5:
//         return <StoreDeliveryArea {...commonProps} />;
//       case 6:
//         return <StorePosition {...commonProps} />;
//       case 7:
//         return <SignUpConfirm {...commonProps} />;
//       case 8:
//         return <SignUpComplete {...commonProps} />;
//       case 9:
//         return <SignUpStatus {...commonProps} />;
//       default:
//         return <div>Error: Step is {step}</div>;
//     }
//   };

//   return (
//     <Container className="signup-flow">
//       <Box display="flex" justifyContent="flex-end">
//         <IconButton onClick={handleCloseClick}>
//           <CloseIcon />
//         </IconButton>
//       </Box>
//       <Box className="step-content">{renderStepContent()}</Box>
//       <LinearProgress
//         variant="determinate"
//         value={(step / totalSteps) * 100}
//         sx={{ margin: '20px 0' }}
//       />
//       <Box display="flex" justifyContent="space-between">
//         {mode === 1 && step > 1 && step !== 9 && (
//           <Button variant="contained" onClick={handlePrevClick}>
//             이전
//           </Button>
//         )}
//         {mode === 1 && step < 8 && (
//           <Button variant="contained" color="primary" onClick={handleNextClick}>
//             다음
//           </Button>
//         )}
//         {mode === 2 && (
//           <Button
//             variant="contained"
//             color="secondary"
//             onClick={handleCompleteClick}
//           >
//             완료
//           </Button>
//         )}
//       </Box>
//     </Container>
//   );
// }

// export default StoreSignUpFlow;

// import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import {
//   Container,
//   Button,
//   LinearProgress,
//   IconButton,
//   Box,
//   Stepper,
//   Step,
//   StepLabel,
//   Typography,
//   Dialog,
//   DialogContent,
//   DialogActions,
// } from '@mui/material';
// import CloseIcon from '@mui/icons-material/Close';
// import { db, doc, getDoc } from '../../firebaseConfig';
// import SignUpCreateAccount from './SignUpCreateAccount';
// import EmailVerification from './EmailVerification';
// import PhoneNumberVerification from './PhoneNumberVerification';
// import StoreProfile from './StoreProfile';
// import StorePosition from './StorePosition';
// import StoreDeliveryArea from './StoreDeliveryArea';
// import SignUpConfirm from './SignUpConfirm';
// import SignUpComplete from './SignUpComplete';
// import SignUpStatus from './SignUpStatus';
// import SignUpEditPassword from './SignUpEditPassword';

// function StoreSignUpFlow(props) {
//   const navigate = useNavigate();
//   const [step, setStep] = useState(1);
//   const steps = [
//     'Create Account',
//     'Verify Email',
//     'Verify Phone',
//     'Store Profile',
//     'Store Delivery Area',
//     'Store Position',
//     'Confirm Details',
//     'Complete Signup',
//     // 'Signup Status',
//   ];
//   const currentUser = useSelector((state) => state.auth.currentUser);

//   useEffect(() => {
//     if (currentUser && props.mode === 1) {
//       checkPendingRegistration();
//     }
//   }, [currentUser, props.mode]);

//   const checkPendingRegistration = async () => {
//     const userRef = doc(db, 'users', currentUser.uid);
//     const userSnapshot = await getDoc(userRef);
//     if (userSnapshot.exists() && userSnapshot.data().pendingRegistrations) {
//       const { step } = userSnapshot.data().pendingRegistrations;
//       setStep(step);
//     }
//   };

//   const handleNext = () => {
//     if (step < steps.length) {
//       setStep((prevStep) => Math.min(prevStep + 1, steps.length));
//     }
//   };

//   const handleBack = () => {
//     if (step > 1) {
//       setStep((prevStep) => Math.max(prevStep - 1, 1));
//     }
//   };

//   const handleClose = () => {
//     props.closeModal();
//     navigate('/');
//   };

//   const renderStepContent = (stepIndex) => {
//     const stepProps = {
//       onNext: handleNext,
//       onPrev: handleBack,
//       onClose: handleClose,
//     };
//     switch (stepIndex) {
//       case 1:
//         return <SignUpCreateAccount {...stepProps} />;
//       case 2:
//         return <EmailVerification {...stepProps} />;
//       case 3:
//         return <PhoneNumberVerification {...stepProps} />;
//       case 4:
//         return <StoreProfile {...stepProps} />;
//       case 5:
//         return <StoreDeliveryArea {...stepProps} />;
//       case 6:
//         return <StorePosition {...stepProps} />;
//       case 7:
//         return <SignUpConfirm {...stepProps} />;
//       case 8:
//         return <SignUpComplete {...stepProps} />;
//       // case 9:
//       //   return <SignUpStatus {...stepProps} />;
//       default:
//         return <Typography>Error: Unknown step</Typography>;
//     }
//   };

//   return (
//     <Dialog open={true} onClose={handleClose} fullWidth maxWidth="sm">
//       <DialogContent
//         dividers
//         style={{ minHeight: '60vh', display: 'flex', flexDirection: 'column' }}
//       >
//         <Stepper activeStep={step} alternativeLabel sx={{ mb: 2 }}>
//           {steps.map((label) => (
//             <Step key={label}>
//               <StepLabel>{label}</StepLabel>
//             </Step>
//           ))}
//         </Stepper>

//         <Box
//           sx={{
//             display: 'flex',
//             flexDirection: 'column',
//             alignItems: 'center',
//             py: 2,
//           }}
//         >
//           {renderStepContent(step)}
//         </Box>
//       </DialogContent>
//       <DialogActions sx={{ justifyContent: 'space-between' }}>
//         {step > 0 && (
//           <Button variant="outlined" onClick={handleBack} sx={{ mr: 1 }}>
//             Back
//           </Button>
//         )}
//         {step < steps.length - 1 && (
//           <Button variant="outlined" onClick={handleNext}>
//             Next
//           </Button>
//         )}
//         {step === steps.length - 1 && (
//           <Button variant="outlined" onClick={handleClose}>
//             Finish
//           </Button>
//         )}
//       </DialogActions>
//     </Dialog>
//   );
// }

// export default StoreSignUpFlow;

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Button,
  LinearProgress,
  IconButton,
  Box,
  Stepper,
  Step,
  StepLabel,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { db, doc, getDoc } from '../firebaseConfig';
import SignUpCreateAccountEmail from './SignUpCreateAccountEmail';
import EmailVerification from './EmailVerification';
import PhoneNumberVerification from './PhoneNumberVerification';
import StoreProfile from './StoreProfile';
import StorePosition from './StorePosition';
import StoreDeliveryArea from './StoreDeliveryArea';
import StoreSignUpConfirm from './StoreSignUpConfirm';
import StoreSignUpComplete from './StoreSignUpComplete';
import { useTranslation } from 'react-i18next';
import MyTown from './MyTown';

function StoreSignUpFlow(props) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [step, setStep] = useState(1);
  const steps = [
    t('createAccount'),
    t('verifyEmail'),
    t('verifyPhone'),
    t('mytown'),
    t('storeProfile'),
    t('storeDeliveryArea'),
    t('storePosition'),
    t('confirmDetails'),
    t('finalConfirmation'),
    t('signupComplete'),
    // t('signupStatus'), // If you want to add this step later
  ];
  const currentUser = useSelector((state) => state.auth.currentUser);
  const [isStepCompleted, setIsStepCompleted] = useState(false);

  useEffect(() => {
    if (currentUser && props.mode === 1) {
      checkRegistrationStep();
    }
  }, [currentUser, props.mode]);

  const checkRegistrationStep = async () => {
    const userRef = doc(db, 'users', currentUser.uid);
    const userSnapshot = await getDoc(userRef);
    if (userSnapshot.exists()) {
      const step = userSnapshot.data().step;
      determineNextStep(step);
    }
  };

  const handleNext = () => {
    if (step < steps.length) {
      setStep((prevStep) => Math.min(prevStep + 1, steps.length));
    }
  };

  const handleBack = () => {
    if (step > 1) {
      setStep((prevStep) => Math.max(prevStep - 1, 1));
    }
  };

  const handleClose = () => {
    props.closeModal();
    navigate('/');
  };

  const determineNextStep = (step) => {
    switch (step) {
      case process.env.REACT_APP_STORE_CREATE_ACCOUNT:
        setStep(0);
        break;
      case process.env.REACT_APP_CREATE_ACCOUNT_VERIFIED:
        setStep(1);
        break;
      case process.env.REACT_APP_EMAIL_VERIFIED:
        setStep(2);
        break;
      case process.env.REACT_APP_PHONE_NUMBER_VERIFIED:
        setStep(3);
        break;
      case process.env.REACT_APP_USER_TOWN_VERIFIED:
        setStep(4);
        break;
      case process.env.REACT_APP_STORE_PROFILE_VERIFIED:
        setStep(5);
        break;
      case process.env.REACT_APP_STORE_DELIVERYAREA_VERIFIED:
        setStep(6);
        break;
      case process.env.REACT_APP_STORE_POSITION_VERIFIED:
        setStep(7);
        break;
      case process.env.REACT_APP_STORE_SIGNUP_CONFIRM:
        setStep(8);
        break;
      case process.env.REACT_APP_STORE_SIGNUP_COMPLETED:
        setStep(9);
        break;
      default:
        setStep(1);
        break;
    }
  };
  const renderStepContent = (stepIndex) => {
    const stepProps = {
      onNext: handleNext,
      onPrev: handleBack,
      onClose: handleClose,
      setIsStepCompleted,
      userType: 2,
    };
    switch (stepIndex) {
      case 1:
        return <SignUpCreateAccountEmail {...stepProps} />;
      case 2:
        return <EmailVerification {...stepProps} />;
      case 3:
        return <PhoneNumberVerification {...stepProps} />;
      case 4:
        return <MyTown {...stepProps} />;
      case 5:
        return <StoreProfile {...stepProps} />;
      case 6:
        return <StoreDeliveryArea {...stepProps} />;
      case 7:
        return <StorePosition {...stepProps} />;
      case 8:
        return <StoreSignUpConfirm {...stepProps} />;
      case 9:
        return <StoreSignUpComplete {...stepProps} />;
      // case 9:
      //   return <SignUpStatus {...stepProps} />;
      default:
        return <Typography>{t('errorUnknownStep')}</Typography>;
    }
  };

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      sx={{ overflowX: 'hidden', width: '100%' }}
    >
      <DialogContent
        dividers
        style={{ minHeight: '60vh', display: 'flex', flexDirection: 'column' }}
      >
        <Stepper activeStep={step} alternativeLabel sx={{ mb: 2 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            py: 2,
          }}
        >
          {renderStepContent(step)}
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        {step >= 1 && (
          <Button variant="outlined" onClick={handleBack} sx={{ mr: 1 }}>
            {t('back')}
          </Button>
        )}
        {step < steps.length - 1 && (
          <Button variant="outlined" onClick={handleNext}>
            {t('next')}
          </Button>
        )}
        {step === steps.length - 1 && (
          <Button variant="outlined" onClick={handleClose}>
            {t('finish')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default StoreSignUpFlow;
