import React, { useEffect, useState } from 'react';
import { collection, query, orderBy, getDocs } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Pie,
  PieChart,
} from 'recharts';
import { Dialog, DialogTitle, DialogContent, IconButton } from '@mui/material';
import ProductReviewForm from './ProductReviewForm';
import filledStarSrc from '../assets/images/filled_star.png';
import emptyStarSrc from '../assets/images/empty_star.png';
import CloseIcon from '@mui/icons-material/Close';

const Star = ({ filled }) => (
  <img
    src={filled ? filledStarSrc : emptyStarSrc}
    style={{ cursor: 'pointer', width: '25px', height: '25px' }}
    alt="rating star"
  />
);

const ProductReviewRating = ({ storeId, productId }) => {
  const [reviews, setReviews] = useState([]);
  const [averageRating, setAverageRating] = useState(0);
  const [showReviewFormViewer, setShowReviewFormViewer] = useState(false);

  // Initialize with starArray to ensure it's used throughout
  const initialStarArray = [
    { index: 5, star: '별5개', count: 0 },
    { index: 4, star: '별4개', count: 0 },
    { index: 3, star: '별3개', count: 0 },
    { index: 2, star: '별2개', count: 0 },
    { index: 1, star: '별1개', count: 0 },
  ];
  const [starArray, setStarArray] = useState([...initialStarArray]);

  const initialUnboxArray = [
    { index: 5, star: '튼튼해요', count: 10 },
    { index: 4, star: '무거워요', count: 2 },
    { index: 3, star: '소장가치있어요', count: 3 },
    { index: 2, star: '기대이상이에요', count: 5 },
    { index: 1, star: '다시사고싶어요', count: 0 },
  ];
  const [unboxArray, setUnboxArray] = useState([...initialUnboxArray]);

  // useEffect(() => {
  //   if (!storeId || !productId) {
  //     console.error('Store ID or Product ID is undefined');
  //     return;
  //   }

  //   const fetchReviews = async () => {
  //     const reviewsRef = collection(
  //       db,
  //       'stores',
  //       storeId,
  //       'products',
  //       productId,
  //       'reviews'
  //     );
  //     const q = query(reviewsRef, orderBy('timestamp', 'desc'));
  //     try {
  //       const querySnapshot = await getDocs(q);
  //       const fetchedReviews = querySnapshot.docs.map((doc) => ({
  //         id: doc.id,
  //         ...doc.data(),
  //       }));
  //       setReviews(fetchedReviews);

  //       const ratingsCount = fetchedReviews.reduce((acc, { rating }) => {
  //         acc[rating] = (acc[rating] || 0) + 1;
  //         return acc;
  //       }, {});

  //       // Update starArray based on fetched reviews
  //       const updatedStarArray = initialStarArray.map((item) => ({
  //         ...item,
  //         count: ratingsCount[item.index] || 0,
  //       }));

  //       setStarArray(updatedStarArray);

  //       // Calculate average rating
  //       const totalRating = fetchedReviews.reduce(
  //         (acc, { rating }) => acc + rating,
  //         0
  //       );
  //       setAverageRating(
  //         fetchedReviews.length > 0 ? totalRating / fetchedReviews.length : 0
  //       );
  //     } catch (error) {
  //       console.error('Error fetching reviews:', error);
  //     }
  //   };

  //   fetchReviews();
  // }, [storeId, productId]);

  useEffect(() => {
    if (!storeId || !productId) {
      console.error('Store ID or Product ID is undefined');
      return;
    }

    const fetchReviews = async () => {
      const reviewsRef = collection(
        db,
        'stores',
        storeId,
        'products',
        productId,
        'reviews'
      );
      const q = query(reviewsRef, orderBy('timestamp', 'desc'));
      try {
        const querySnapshot = await getDocs(q);
        const fetchedReviews = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          const timestamp = data.timestamp
            ? new Date(data.timestamp.seconds * 1000)
            : null;

          const formattedTimestamp = timestamp
            ? new Intl.DateTimeFormat(navigator.language, {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: false,
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              }).format(timestamp)
            : 'No timestamp';

          return {
            id: doc.id,
            ...data,
            formattedTimestamp,
          };
        });
        setReviews(fetchedReviews);

        const ratingsCount = fetchedReviews.reduce((acc, { rating }) => {
          acc[rating] = (acc[rating] || 0) + 1;
          return acc;
        }, {});

        // Update starArray based on fetched reviews
        const updatedStarArray = initialStarArray.map((item) => ({
          ...item,
          count: ratingsCount[item.index] || 0,
        }));

        setStarArray(updatedStarArray);

        // Calculate average rating
        const totalRating = fetchedReviews.reduce(
          (acc, { rating }) => acc + rating,
          0
        );
        setAverageRating(
          fetchedReviews.length > 0 ? totalRating / fetchedReviews.length : 0
        );
      } catch (error) {
        if (
          error.code === 'failed-precondition' ||
          error.code === 'unavailable'
        ) {
          console.error(
            'Firestore index error: Please create the required index in the Firestore console.',
            error
          );
        } else {
          console.error('Error fetching reviews:', error);
        }
      }
    };

    fetchReviews();
  }, [storeId, productId]);

  const toggleReviewFormViewer = () => setShowReviewFormViewer((prev) => !prev);

  return (
    <div>
      <h2>리뷰 평점</h2>
      <p>리뷰 전체 개수: {reviews.length}</p>
      <div className="average-rating">
        <p>평균 별점: {averageRating.toFixed(2)}</p>
        {[...Array(5)].map((_, index) => (
          <Star key={index} filled={index < Math.round(averageRating)} />
        ))}
      </div>
      <p />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          flexWrap: 'wrap',
        }}
      >
        {/* 별점 분포 Section */}
        <div style={{ width: '48%' }}>
          {' '}
          {/* Adjust width as needed for spacing */}
          <h2>별점 분포</h2>
          {starArray.map((data) => (
            <div key={data.star} style={{ marginBottom: '10px' }}>
              <div style={{ fontSize: '14px', marginBottom: '5px' }}>
                {data.star}
              </div>
              <div
                style={{
                  width: '100%',
                  backgroundColor: '#f1f1f1',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    width: `${(data.count / reviews.length) * 100}%`,
                    backgroundColor: '#4CAF50',
                    textAlign: 'right',
                    paddingRight: data.count > 0 ? '5px' : '0px', // Apply paddingRight only if data.count > 0
                    color: 'white',
                    whiteSpace: 'nowrap', // Prevents the text from wrapping
                    overflow: 'hidden', // Hide overflow
                    textOverflow: 'ellipsis', // Show ellipsis for overflowed content
                    display: 'flex',
                    justifyContent: 'flex-end', // Align text to the right
                    alignItems: 'center',
                    height: '100%', // Match parent's height to vertically center
                  }}
                >
                  {data.count > 0
                    ? `${data.count} (${(
                        (data.count / reviews.length) *
                        100
                      ).toFixed(2)}%)`
                    : '0%'}
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* RadarChart Section */}
        <div style={{ width: '48%' }}>
          {' '}
          {/* Adjust width as needed for spacing */}
          <h2>사용자 경험</h2>
          <ResponsiveContainer width="100%" height={300}>
            <RadarChart cx="50%" cy="50%" outerRadius="80%" data={unboxArray}>
              <PolarGrid />
              <PolarAngleAxis dataKey="star" />
              <PolarRadiusAxis angle={30} domain={[0, 'dataMax']} />
              <Radar
                name="Reviews"
                dataKey="count"
                stroke="#8884d8"
                fill="#8884d8"
                fillOpacity={0.6}
              />
            </RadarChart>
          </ResponsiveContainer>
        </div>
      </div>

      <button className="btn btn-primary" onClick={toggleReviewFormViewer}>
        리뷰 작성
      </button>
      <Dialog
        open={showReviewFormViewer}
        onClose={toggleReviewFormViewer}
        PaperProps={{
          style: {
            padding: '20px',
            backgroundColor: '#f6f6f6',
            width: '60%', // 너비를 80%로 설정
            height: '600px', // 높이를 600px로 설정
            maxWidth: '100%', // 최대 너비 제한 없음
          },
        }}
      >
        <DialogTitle>
          <IconButton
            style={{ position: 'absolute', right: '8px', top: '8px' }}
            onClick={toggleReviewFormViewer}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <ProductReviewForm storeId={storeId} productId={productId} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ProductReviewRating;
