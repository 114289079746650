import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, List, ListItem, ListItemText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const MyPage = () => {
  const { t, i18n } = useTranslation();
  const { userId } = useParams();

  return (
    <Box
      p={3}
      sx={{
        width: '100%',
        maxWidth: '100%',
        margin: 'auto',
      }}
    >
      <Box
        sx={{
          display: 'inline-block',
          border: '0px solid #d3d3d3', // 연한 회색 테두리
          borderRadius: '16px', // 둥근 테두리
          padding: '8px 16px', // 안쪽 여백
          marginBottom: '16px', // 아래쪽 여백
          backgroundColor: 'transparent', // 연한 회색 배경색
          // boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // 그림자 효과
          transition: 'transform 0.2s', // 호버 시 애니메이션
          '&:hover': {
            transform: 'scale(1.02)', // 호버 시 크기 증가
          },
        }}
      >
        <Typography variant="h4" gutterBottom>
          {t('myPage')}
        </Typography>
      </Box>
      <nav>
        <List>
          <ListItem button component={Link} to={`/my_profile/${userId}`}>
            <ListItemText primary={t('myProfile')} />
          </ListItem>
          <ListItem button component={Link} to={`/allcartlist/${userId}`}>
            <ListItemText primary={t('allCart')} />
          </ListItem>
          <ListItem button component={Link} to={`/orders/${userId}`}>
            <ListItemText primary={t('myOrders')} />
          </ListItem>
          <ListItem button component={Link} to={`/returns/${userId}`}>
            <ListItemText primary={t('returns')} />
          </ListItem>
          <ListItem button component={Link} to={`/order_status/${userId}`}>
            <ListItemText primary={t('orderStatusChange')} />
          </ListItem>
          {/* <ListItem button component={Link} to={`/user_points/${userId}`}>
            <ListItemText primary={t('points')} />
          </ListItem>
          <ListItem button component={Link} to={`/user_coupons/${userId}`}>
            <ListItemText primary={t('coupons')} />
          </ListItem> */}
        </List>
      </nav>
    </Box>
  );
};

export default MyPage;
