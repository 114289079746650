import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../firebaseConfig';
import { collection, query, orderBy, getDocs } from 'firebase/firestore';
import './css/ReviewList.css';
import filledStarSrc from '../assets/images/filled_star.png';
import emptyStarSrc from '../assets/images/empty_star.png';
import ProductReviewComment from './ProductReviewComment';
import ProductReviewCommentForm from './ProductReviewCommentForm';
const Star = ({ filled, onClick }) => {
  return (
    <img
      src={filled ? filledStarSrc : emptyStarSrc}
      onClick={onClick}
      style={{ cursor: 'pointer', width: '25px', height: '25px' }} // Adjust size as needed
      alt="rating star"
    />
    // <span onClick={onClick} style={{ cursor: 'pointer' }}>
    //   {filled ? '⭐' : '☆'}
    // </span>
  );
};

const ProductReviewList = ({ storeId, productId }) => {
  const [reviews, setReviews] = useState([]);
  const [averageRating, setAverageRating] = useState(0);

  // useEffect(() => {
  //   const fetchReviews = async () => {
  //     if (typeof storeId === 'undefined' || typeof productId === 'undefined') {
  //       console.error('storeId or productId is undefined');
  //       return;
  //     }

  //     const reviewsRef = collection(db, 'stores', storeId, 'products', productId, 'reviews');
  //     const q = query(reviewsRef, orderBy('timestamp', 'desc'));

  //     try {
  //       const querySnapshot = await getDocs(q);
  //       const reviewData = querySnapshot.docs.map(doc => {
  //         // Firestore 타임스탬프를 Date 객체로 변환
  //         const timestamp = doc.data().timestamp ? new Date(doc.data().timestamp.seconds * 1000) : null;

  //         const formattedTimestamp = doc.data().timestamp ? new Intl.DateTimeFormat(navigator.language, {
  //           year: 'numeric',
  //           month: 'long',
  //           day: 'numeric',
  //           hour: 'numeric',
  //           minute: 'numeric',
  //           hour12: false, // 24시간 표기법을 선호하는 경우 true 대신 false를 사용
  //           timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone // 사용자의 시간대 자동 감지
  //         }).format(timestamp) : 'No timestamp';

  //         return {
  //           id: doc.id,
  //           ...doc.data(),
  //           timestamp: formattedTimestamp, // 포맷팅된 타임스탬프
  //         };
  //       });
  //       setReviews(reviewData);

  //       const totalRating = reviewData.reduce((sum, review) => sum + review.rating, 0);
  //       const avgRating = reviewData.length > 0 ? totalRating / reviewData.length : 0;
  //       setAverageRating(avgRating);
  //     } catch (error) {
  //       console.error('Error fetching reviews:', error);
  //     }
  //   };

  //   fetchReviews();
  // }, [storeId, productId]);

  useEffect(() => {
    const fetchReviews = async () => {
      if (typeof storeId === 'undefined' || typeof productId === 'undefined') {
        console.error('storeId or productId is undefined');
        return;
      }

      const reviewsRef = collection(
        db,
        'stores',
        storeId,
        'products',
        productId,
        'reviews'
      );
      const q = query(reviewsRef, orderBy('timestamp', 'desc'));

      try {
        const querySnapshot = await getDocs(q);
        const reviewData = querySnapshot.docs.map((doc) => {
          // Firestore 타임스탬프를 Date 객체로 변환
          const timestamp = doc.data().timestamp
            ? new Date(doc.data().timestamp.seconds * 1000)
            : null;

          const formattedTimestamp = timestamp
            ? new Intl.DateTimeFormat(navigator.language, {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: false, // 24시간 표기법을 선호하는 경우 true 대신 false를 사용
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, // 사용자의 시간대 자동 감지
              }).format(timestamp)
            : 'No timestamp';

          return {
            id: doc.id,
            ...doc.data(),
            timestamp: formattedTimestamp, // 포맷팅된 타임스탬프
          };
        });
        setReviews(reviewData);

        const totalRating = reviewData.reduce(
          (sum, review) => sum + review.rating,
          0
        );
        const avgRating =
          reviewData.length > 0 ? totalRating / reviewData.length : 0;
        setAverageRating(avgRating);
      } catch (error) {
        if (
          error.code === 'failed-precondition' ||
          error.code === 'unavailable'
        ) {
          console.error(
            'Firestore index error: Please create the required index in the Firestore console.',
            error
          );
        } else {
          console.error('Error fetching reviews:', error);
        }
      }
    };

    fetchReviews();
  }, [storeId, productId]);

  return (
    <div>
      <h2>리뷰 목록</h2>
      <ul className="review-list">
        {reviews.map((review, index) => (
          <li key={review.id} className="review-item">
            <Link
              to={`/reviews/${storeId}/${productId}/${review.id}`}
              className="review-link"
            >
              <div className="review-header">
                <div className="review-rating">
                  {[1, 2, 3, 4, 5].map((star, index) => (
                    <Star key={index} filled={index < review.rating} />
                  ))}
                </div>

                {review.timestamp ? review.timestamp : 'Date not available'}
              </div>
              <div className="review-text">
                리뷰 작성자: {review.reviewerName}
              </div>

              <div className="review-text">리뷰 내용: {review.text}</div>
              <div className="image-list">
                {review.imageUrls &&
                  review.imageUrls.map((imageUrl, index) => (
                    <img
                      key={index}
                      src={imageUrl}
                      alt={`Review ${index + 1}`}
                      className="review-image"
                    />
                  ))}
              </div>
            </Link>
            {/* 최상위 댓글 목록을 표시하며, 따라서 parentId는 null로 설정 */}

            <ProductReviewComment
              storeId={storeId}
              productId={productId}
              reviewId={review.id}
              parentId={null}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ProductReviewList;
