// components/EditPromotion.js

import React, { useState, useEffect } from 'react';
import { db, collection, getDocs, getDoc, doc, updateDoc, addDoc } from '../../firebaseConfig';

const EditPromotion = ({ match }) => {
  const promotionId = match.params.id; // Assuming you're using react-router
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    type: 'discount',
    value: '',
    startDate: '',
    endDate: ''
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPromotion = async () => {
      try {
        const promotionRef = doc(db, 'promotions', promotionId);
        const promotionDoc = await getDoc(promotionRef);
        if (promotionDoc.exists()) {
          setFormData(promotionDoc.data());
        } else {
          setError('Promotion not found');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPromotion();
  }, [promotionId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const promotionRef = doc(db, 'promotions', promotionId);
      await updateDoc(promotionRef, formData);
      alert('Promotion updated successfully!');
    } catch (err) {
      alert(`Error: ${err.message}`);
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="edit-promotion-container">
      <form onSubmit={handleSubmit} className="edit-promotion-form">
        {/* ... your form input fields based on formData structure ... */}
        <button type="submit">Update Promotion</button>
      </form>
    </div>
  );
}

export default EditPromotion;
