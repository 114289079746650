// import React, { useState, useEffect } from 'react';
// import { db, collection, query, where, orderBy, getDocs, Timestamp, limit } from '../../../firebaseConfig';  // Assuming you've updated the firebaseConfig file to v9

// const DiscountDisplay = ({ storeId }) => {
//   const [discounts, setDiscounts] = useState([]);

//   useEffect(() => {
//     const fetchDiscounts = async () => {
//       const now = Timestamp.now();
//       const discountsQuery = query(
//         collection(db, 'stores', storeId, 'discounts'),
//         where("endDate", ">", now),
//         orderBy("endDate", "asc"),
//         limit(5)
//       );
//       const snapshot = await getDocs(discountsQuery);
//       setDiscounts(snapshot.docs.map(doc => ({ id: doc.id, ...doc.data(),
//         startDate: doc.data().startDate.toDate(),
//         endDate: doc.data().endDate.toDate() })));
//     };

//     fetchDiscounts();
//   }, [storeId]);

//   return (
//     <div className="discount-display">
//       {discounts.map(discount => (
//         <div key={discount.id} className="discount-item">
//           <h1>{discount.name}</h1>
//           <h2>{discount.content}</h2>
//           <p>{discount.startDate?.toLocaleString()}</p>
//           <p>{discount.endDate?.toLocaleString()}</p>
//         </div>
//       ))}
//       {
//         discounts.length === 0 &&
//         <div className="discount-item">
//           <p>진행중인 할인이 없습니다.</p> {/* Assuming there's a description field in the promo object */}
//           {/* If there are other details, add them here */}
//         </div>
//       }
//     </div>
//   );
// };

// export default DiscountDisplay;

// import React, { useState, useEffect } from 'react';
// import {
//   db,
//   collection,
//   query,
//   where,
//   orderBy,
//   getDocs,
//   Timestamp,
//   limit,
// } from '../../../firebaseConfig';
// import { Card, CardContent, Typography, Grid, Box } from '@mui/material';
// import { useTranslation } from 'react-i18next';
// import DiscountCard from './DiscountCard';
// import DiscountList from './DiscountList';

// const DiscountDisplay = ({ storeId }) => {
//   const { t, i18n } = useTranslation();
//   const [discounts, setDiscounts] = useState([]);

//   useEffect(() => {
//     const fetchDiscounts = async () => {
//       const now = Timestamp.now();
//       const discountsQuery = query(
//         collection(db, 'stores', storeId, 'discounts'),
//         where('endDate', '>', now),
//         orderBy('endDate', 'asc'),
//         limit(5)
//       );
//       const snapshot = await getDocs(discountsQuery);
//       setDiscounts(
//         snapshot.docs.map((doc) => ({
//           id: doc.id,
//           ...doc.data(),
//           startDate: doc.data().startDate.toDate(),
//           endDate: doc.data().endDate.toDate(),
//         }))
//       );
//     };

//     fetchDiscounts();
//   }, [storeId]);

//   return (
//     <Box sx={{ mt: 2 }}>
//       <Grid item xs={12}>
//         <Typography variant="h4" color="text.secondary">
//           {t('ongoingDiscounts')}
//         </Typography>
//       </Grid>
//       <Grid container spacing={2}>
//         {discounts.map((discount) => (
//           <Grid item xs={12} sm={6} md={4} key={discount.id}>
//             {/* <Card raised>
//               <CardContent>
//                 <Typography variant="h5" component="div">
//                   {discount.discountName}
//                 </Typography>
//                 <Typography sx={{ mb: 1.5 }} color="text.secondary">
//                   {discount.discription}
//                 </Typography>
//                 <Typography variant="body2">
//                   {t('startDate')}: {discount.startDate?.toLocaleString()}
//                 </Typography>
//                 <Typography variant="body2">
//                   {t('endDate')}: {discount.endDate?.toLocaleString()}
//                 </Typography>
//               </CardContent>
//             </Card> */}
//             <DiscountCard discount={discount} />
//           </Grid>
//         ))}
//         {discounts.length === 0 && (
//           <Grid item xs={12}>
//             <Typography>{t('noOngoingDiscounts')}</Typography>
//           </Grid>
//         )}
//       </Grid>
//     </Box>
//   );
// };

// export default DiscountDisplay;

import React, { useState, useEffect } from 'react';
import {
  db,
  collection,
  query,
  where,
  orderBy,
  getDocs,
  Timestamp,
  limit,
} from '../../../firebaseConfig';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import DiscountCard from './DiscountCard';

const DiscountDisplay = ({ storeId }) => {
  const { t } = useTranslation();
  const [discounts, setDiscounts] = useState([]);
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // useEffect(() => {
  //   const fetchDiscounts = async () => {
  //     const now = Timestamp.now();
  //     const discountsQuery = query(
  //       collection(db, 'stores', storeId, 'discounts'),
  //       where('endDate', '>', now),
  //       orderBy('endDate', 'asc'),
  //       limit(5)
  //     );
  //     const snapshot = await getDocs(discountsQuery);
  //     setDiscounts(
  //       snapshot.docs.map((doc) => ({
  //         id: doc.id,
  //         ...doc.data(),
  //         startDate: doc.data().startDate.toDate(),
  //         endDate: doc.data().endDate.toDate(),
  //       }))
  //     );
  //   };

  //   fetchDiscounts();
  // }, [storeId]);

  useEffect(() => {
    const fetchDiscounts = async () => {
      try {
        const now = Timestamp.now();
        const discountsQuery = query(
          collection(db, 'stores', storeId, 'discounts'),
          where('endDate', '>', now),
          orderBy('endDate', 'asc'),
          limit(5)
        );
        const snapshot = await getDocs(discountsQuery);
        const discountsData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          startDate: doc.data().startDate.toDate(),
          endDate: doc.data().endDate.toDate(),
        }));
        setDiscounts(discountsData);
      } catch (error) {
        if (
          error.code === 'failed-precondition' ||
          error.code === 'unavailable'
        ) {
          console.error('Firestore index error:', error.message);
          setError('Firestore index error. Please create a Firestore index.');
        } else {
          console.error('Error fetching discounts:', error);
          setError('Failed to fetch discounts.');
        }
      } finally {
        setLoading(false);
      }
    };

    if (storeId) {
      fetchDiscounts();
    } else {
      console.error('Store ID is undefined');
      setLoading(false);
      setError('Store ID is required.');
    }
  }, [storeId]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  return (
    <Box sx={{ mt: 2 }}>
      <Grid item xs={12}>
        <Typography variant="h4" color="text.secondary">
          {t('ongoingDiscounts')}
        </Typography>
      </Grid>
      <Grid container spacing={2}>
        {discounts.map((discount) => (
          <Grid item xs={12} sm={6} md={4} key={discount.id}>
            <DiscountCard discount={discount} />
          </Grid>
        ))}
        {discounts.length === 0 && (
          <Grid item xs={12}>
            <Typography>{t('noOngoingDiscounts')}</Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default DiscountDisplay;
