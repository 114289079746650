// import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';  // 홈 페이지로 리디렉션하기 위한 hook
// import { db, doc, getDoc, updateDoc, setDoc, FieldValue } from '../firebaseConfig';

// import './SignUpComplete.css';  // CSS 모듈

// function SignUpConfirm({ onPrev, onNext, setIsStepCompleted }) {
//   const currentUser = useSelector((state) => state.auth.currentUser);
//   const [pendingAllData, setPendingAllData] = useState(null);
//   const [storeData, setStoreData] = useState(null);

//   useEffect(() => {
//     fetchPendingRegistrations();
//   }, [currentUser]);

//   const fetchPendingRegistrations = async () => {
//     try {
//       const userRef = doc(db, 'users', currentUser.uid);
//       await updateDoc(userRef, { "pendingRegistrations.step": "signupCompleted" });
//       const userDoc = await getDoc(userRef);
//       if (userDoc.exists() && userDoc.data().pendingRegistrations) {
//         setPendingAllData(userDoc.data().pendingRegistrations);
//         saveStoreUser(userDoc.data().pendingRegistrations);
//       }
//     } catch (error) {
//       console.error("Error fetching user's pending registrations:", error);
//     }
//   }

//   const saveStoreUser = async (pendingData) => {
//     try {

//       const userStoreRef = doc(db, 'stores', currentUser.uid);
//       await setDoc(userStoreRef, { ...pendingData, storeId: currentUser.uid }, { merge: true });

//       const storeSnapshot = await getDoc(userStoreRef);
//       if (storeSnapshot.exists()) {
//         setStoreData(storeSnapshot.data());
//       }

//       const userRef = doc(db, 'users', currentUser.uid);
//       await updateDoc(userRef, { pendingRegistrations: FieldValue.delete() });

//       setIsStepCompleted(true);
//     } catch (error) {
//       console.error("Error updating registration step:", error);
//     }
//   };

//   return (
//     <div className="signupcomplete">
//       <div className="container">
//         <h1 className="title">최종 내용을 확인하세요!</h1>
//         <p className="text">내용은 다음과 같습니다.</p>
//         {pendingAllData ? (
//           <ul>
//             <li><strong>StoreName:</strong> {pendingAllData.storeName}</li>
//             <li><strong>Name:</strong> {pendingAllData.name}</li>
//             <li><strong>Email:</strong> {pendingAllData.email}</li>
//             <li><strong>Description:</strong> {pendingAllData.description}</li>
//             <li><strong>Address:</strong> {pendingAllData.address}</li>
//             <li><strong>Contact:</strong> {pendingAllData.contact}</li>
//             {/* <li>
//               <strong>Delivery Areas:</strong>
//               <ul>
//                 {pendingAllData.deliveryArea.map((area, index) => <li key={index}>{area}</li>)}
//               </ul>
//             </li> */}
//             <li><strong>Store Position:</strong> Lat: {pendingAllData.storePosition?.lat}, Lng: {pendingAllData.storePosition?.lng}</li>
//           </ul>
//         ) : (
//           <p>Loading data...</p>
//         )}
//       </div>
//     </div>
//   );
// }

// export default SignUpConfirm;

// import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import { db, doc, getDoc, updateDoc, setDoc, FieldValue } from '../../firebaseConfig';
// import '../css/SignUpFlow.css';

// function SignUpConfirm({ setIsStepCompleted }) {
//   const currentUser = useSelector((state) => state.auth.currentUser);
//   const [pendingAllData, setPendingAllData] = useState(null);
//   const [isLoading, setIsLoading] = useState(true);

//   useEffect(() => {
//     fetchAndUpdateUser();
//   }, [currentUser]);

//   const fetchAndUpdateUser = async () => {
//     setIsLoading(true);
//     try {
//       const userRef = doc(db, 'users', currentUser.uid);
//       const userDoc = await getDoc(userRef);

//     //   if (userDoc.exists() && userDoc.data().pendingRegistrations) {
//     //     let pendingRegistrations = { ...userDoc.data().pendingRegistrations };
//     //     setPendingAllData(pendingRegistrations);

//     //     // Update the step directly on the cloned object
//     //     pendingRegistrations.step = "signupCompleted";

//     //     await updateDoc(userRef, {
//     //       "pendingRegistrations.step": "signupCompleted",
//     //       pendingRegistrations: FieldValue.delete()
//     //     });

//     //     await saveStoreUser(pendingRegistrations);
//     //   }
//     // } catch (error) {
//     //   console.error("Error fetching and updating user:", error);
//     // }
//       if (userDoc.exists()) {
//         const { pendingRegistrations } = userDoc.data();
//         if (pendingRegistrations) {
//           setPendingAllData(pendingRegistrations);

//           await updateDoc(userRef, {
//             "pendingRegistrations.step": "signupCompleted",
//             // Consider how to properly remove or update pendingRegistrations
//           });

//           await saveStoreUser({ ...pendingRegistrations, step: "signupCompleted" });
//         }
//       }
//     } catch (error) {
//       console.error("Error fetching and updating user:", error);
//     } finally {
//       setIsLoading(false);
//     }

//   };

//   const saveStoreUser = async (pendingData) => {
//     try {
//       const userStoreRef = doc(db, 'stores', currentUser.uid);
//       await setDoc(userStoreRef, { ...pendingData, storeId: currentUser.uid }, { merge: true });
//       setIsStepCompleted(true);
//     } catch (error) {
//       console.error("Error updating store:", error);
//     }
//   };

//   return (

//     <div >
//       <h1 className="title">최종 내용을 확인하세요!</h1>
//       <p className="text">내용은 다음과 같습니다.</p>
//       {pendingAllData ? (
//         <ul>
//           <li><strong>StoreName:</strong> {pendingAllData.storeName}</li>
//           <li><strong>Name:</strong> {pendingAllData.name}</li>
//           <li><strong>Email:</strong> {pendingAllData.email}</li>
//           <li><strong>Description:</strong> {pendingAllData.description}</li>
//           <li><strong>Address:</strong> {pendingAllData.address}</li>
//           <li><strong>Contact:</strong> {pendingAllData.contact}</li>
//           <li>
//             <strong>Delivery Area:</strong>
//             <ul>
//               {pendingAllData.deliveryArea?.map((location, index) => (
//                 <li key={index}>
//                   Latitude: {location.lat}, Longitude: {location.lng}
//                 </li>
//               ))}
//             </ul>
//           </li>
//           <li><strong>Store Position:</strong> Lat: {pendingAllData.storePosition?.lat}, Lng: {pendingAllData.storePosition?.lng}</li>
//         </ul>
//       ) : (
//         <p>Loading data...</p>
//       )}
//     </div>
//   );
// }

// export default SignUpConfirm;

// import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import { db, doc, getDoc, updateDoc, setDoc } from '../../firebaseConfig';
// import {
//   Container,
//   Typography,
//   CircularProgress,
//   List,
//   ListItem,
//   ListItemText,
//   Box,
// } from '@mui/material';

// function SignUpConfirm({ setIsStepCompleted }) {
//   const currentUser = useSelector((state) => state.auth.currentUser);
//   const [pendingAllData, setPendingAllData] = useState(null);
//   const [isLoading, setIsLoading] = useState(true);

//   useEffect(() => {
//     fetchAndUpdateUser();
//   }, [currentUser]);

//   const fetchAndUpdateUser = async () => {
//     setIsLoading(true);
//     try {
//       const userRef = doc(db, 'users', currentUser.uid);
//       const userDoc = await getDoc(userRef);
//       if (userDoc.exists() && userDoc.data().pendingRegistrations) {
//         const pendingData = userDoc.data().pendingRegistrations;
//         setPendingAllData(pendingData);
//         await updateDoc(userRef, {
//           'pendingRegistrations.step': 'signupCompleted',
//         });
//         await saveStoreUser(pendingData);
//       }
//     } catch (error) {
//       console.error('Error fetching and updating user:', error);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const saveStoreUser = async (pendingData) => {
//     try {
//       const userStoreRef = doc(db, 'stores', currentUser.uid);
//       await setDoc(
//         userStoreRef,
//         { ...pendingData, storeId: currentUser.uid },
//         { merge: true }
//       );
//       setIsStepCompleted(true);
//     } catch (error) {
//       console.error('Error updating store:', error);
//     }
//   };

//   if (isLoading) {
//     return (
//       <Container>
//         <CircularProgress />
//       </Container>
//     );
//   }

//   return (
//     <Container>
//       <Typography variant="h4" sx={{ mb: 2 }}>
//         Final Confirmation
//       </Typography>
//       <Typography paragraph>Here are the details you provided:</Typography>
//       {pendingAllData ? (
//         <List dense>
//           <ListItem>
//             <ListItemText
//               primary="Store Name"
//               secondary={pendingAllData.storeName}
//             />
//           </ListItem>
//           <ListItem>
//             <ListItemText primary="Name" secondary={pendingAllData.name} />
//           </ListItem>
//           <ListItem>
//             <ListItemText primary="Email" secondary={pendingAllData.email} />
//           </ListItem>
//           <ListItem>
//             <ListItemText
//               primary="Description"
//               secondary={pendingAllData.description}
//             />
//           </ListItem>
//           <ListItem>
//             <ListItemText
//               primary="Address"
//               secondary={pendingAllData.address}
//             />
//           </ListItem>
//           <ListItem>
//             <ListItemText
//               primary="Contact"
//               secondary={pendingAllData.contact}
//             />
//           </ListItem>
//           <ListItem>
//             <ListItemText
//               primary="Delivery Area"
//               secondary={pendingAllData.deliveryArea
//                 ?.map(
//                   (location, index) =>
//                     `Location ${index + 1}: Lat ${location.lat}, Lng ${
//                       location.lng
//                     }`
//                 )
//                 .join(', ')}
//             />
//           </ListItem>
//           <ListItem>
//             <ListItemText
//               primary="Store Position"
//               secondary={`Latitude: ${pendingAllData.storePosition?.lat}, Longitude: ${pendingAllData.storePosition?.lng}`}
//             />
//           </ListItem>
//         </List>
//       ) : (
//         <Typography>Loading data...</Typography>
//       )}
//     </Container>
//   );
// }

// export default SignUpConfirm;

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { db, doc, getDoc, updateDoc, setDoc } from '../firebaseConfig';
import {
  Container,
  Typography,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  ToggleButtonGroup,
  ToggleButton,
  Box,
  Alert,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

function StoreSignUpConfirm({ setIsStepCompleted }) {
  const { t } = useTranslation();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const [storeData, setStoreData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [selectedTown, setSelectedTown] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (currentUser) {
      fetchAndUpdateUser();
    }
  }, [currentUser]);

  const fetchAndUpdateUser = async () => {
    setIsLoading(true);
    try {
      const userRef = doc(db, 'users', currentUser.uid);
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        await updateDoc(userRef, {
          step: process.env.REACT_APP_STORE_SIGNUP_COMPLETED,
        });
        const userData = userDoc.data();
        setUserData(userData);
        const data = {
          storePosition: userData.storePosition || null,
          deliveryArea: userData.deliveryArea || [],
          storeAddress: userData.address || '',
          storeName: userData.storeName || '',
          name: userData.name || '',
          email: userData.email || '',
          phoneNumber: userData.phoneNumber || '',
          description: userData.description || '',
        };
        setStoreData(data);
        await saveStoreUser(data);
      }
    } catch (error) {
      console.error(t('errorFetchingAndUpdatingUser'), error);
    } finally {
      setIsLoading(false);
    }
  };

  const saveStoreUser = async (data) => {
    try {
      const userStoreRef = doc(db, 'stores', currentUser.uid);
      await setDoc(
        userStoreRef,
        { ...data, storeId: currentUser.uid },
        { merge: true }
      );
      if (setIsStepCompleted) {
        setIsStepCompleted(true);
      }
    } catch (error) {
      console.error(t('errorUpdatingStore'), error);
    }
  };

  const handleTownChange = async (event, newTown) => {
    if (newTown !== null) {
      setSelectedTown(newTown);
      try {
        const userRef = doc(db, 'users', currentUser.uid);
        await updateDoc(userRef, {
          selectedTown: newTown,
          modifiedAt: new Date(),
        });
      } catch (error) {
        console.error(t('errorUpdatingSelectedTown'), error);
        setError(t('errorUpdatingSelectedTown'));
      }
    }
  };

  if (isLoading) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }
  if (error) {
    return (
      <Container>
        <Alert severity="error" sx={{ mt: 4 }}>
          {error}
        </Alert>
      </Container>
    );
  }
  return (
    <Container>
      <Typography variant="h4" sx={{ mb: 2 }}>
        {t('finalConfirmation')}
      </Typography>
      <Typography paragraph>{t('hereAreTheDetails')}</Typography>
      {storeData ? (
        <List dense>
          <ListItem>
            <ListItemText
              primary={t('storeName')}
              secondary={storeData.storeName}
            />
          </ListItem>
          <ListItem>
            <ListItemText primary={t('name')} secondary={storeData.name} />
          </ListItem>
          <ListItem>
            <ListItemText primary={t('email')} secondary={storeData.email} />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={t('description')}
              secondary={storeData.description}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={t('address')}
              secondary={storeData.storeAddress}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={t('contact')}
              secondary={storeData.phoneNumber}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={t('deliveryArea')}
              secondary={storeData.deliveryArea?.coordinates
                .map(
                  (location, index) =>
                    `${t('location')} ${index + 1}: ${t('lat')} ${
                      location.lat
                    }, ${t('lng')} ${location.lng}`
                )
                .join(', ')}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={t('storePosition')}
              secondary={`${t('lat')}: ${storeData.storePosition?.lat}, ${t(
                'lng'
              )}: ${storeData.storePosition?.lng}`}
            />
          </ListItem>
          <Box mt={4}>
            <Typography variant="h6" gutterBottom>
              {t('myTown.myTown')}
            </Typography>
            <Typography paragraph>
              {t('myTown.changeTownDescription')}
            </Typography>
            <ToggleButtonGroup
              value={selectedTown}
              exclusive
              onChange={handleTownChange}
              aria-label="town selection"
              fullWidth
            >
              <ToggleButton value={1} aria-label="town1">
                {userData.town1 ? userData.town1.name : t('myTown.add')}
              </ToggleButton>
              <ToggleButton value={2} aria-label="town2">
                {userData.town2 ? userData.town2.name : t('myTown.add')}
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </List>
      ) : (
        <Typography>{t('loadingData')}</Typography>
      )}
    </Container>
  );
}

export default StoreSignUpConfirm;
