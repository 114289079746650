import React from 'react';

const ProductInfoTab = ({ productInfo }) => {
  return (
    <div className="tab-content">
      {productInfo ? (
        <>
          <h3>상품 정보</h3>
          {/* Display the product information */}
          <p>스토어: <strong>{productInfo.storeName}</strong></p>
          <p>제목: <strong>{productInfo.productName}</strong></p>
          {/* Add other product information here */}
        </>
      ) : (
        <p>상품정보 내용이 없습니다.</p>
      )}
    </div>
  );
};

export default ProductInfoTab;

