// import React, { useState } from 'react';
// import ReactDOM from 'react-dom';
// import '../css/messagebox.css'; // CSS 스타일

// function MessageBox({ title, content, onYes, onNo, onOk, type, onClose }) {
//   const overlayStyle = {
//     position: 'fixed',
//     top: 0,
//     left: 0,
//     width: '100%',
//     height: '100%',
//     backgroundColor: 'rgba(0, 0, 0, 0.5)',
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     zIndex: 1000,
//   };

//   const modalStyle = {
//     width: '300px',
//     padding: '20px',
//     backgroundColor: '#fff',
//     borderRadius: '8px',
//     boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
//     display: 'flex',
//     flexDirection: 'column',
//     gap: '20px',
//   };

//   const buttonStyle = {
//     padding: '10px 15px',
//     border: 'none',
//     borderRadius: '4px',
//     cursor: 'pointer',
//     backgroundColor: '#004085', // 파란색 배경
//     color: 'white', // 텍스트 색상을 흰색으로
//     fontSize: '20px', // 텍스트 크기 조정
//     fontWeight: 'bold', // 텍스트를 굵게
//     textTransform: 'uppercase', // 대문자로 텍스트 변환
//     boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // 그림자 효과 추가
//     transition: 'background-color 0.3s', // 배경색 변경시 애니메이션 효과
//   };

//   const handleContent = () => {
//     if (!content) return null;
//     return content.split('\n').map((str, index) => (
//       <React.Fragment key={index}>
//         {str}
//         <br />
//       </React.Fragment>
//     ));
//   };

//   return ReactDOM.createPortal(
//     <div style={overlayStyle}>
//       <div style={modalStyle}>
//         <div
//           style={{
//             display: 'flex',
//             justifyContent: 'space-between',
//             alignItems: 'center',
//           }}
//         >
//           <h2 style={{ flex: '1' }}>{title}</h2>
//           <button
//             onClick={onClose}
//             style={{
//               background: 'none',
//               border: 'none',
//               fontSize: '1.5em',
//               cursor: 'pointer',
//             }}
//           >
//             X
//           </button>
//         </div>

//         <p>{handleContent()}</p>
//         {type === 'yesNo' ? (
//           <div
//             style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}
//           >
//             <button style={buttonStyle} onClick={onYes}>
//               확인
//             </button>
//             <button style={buttonStyle} onClick={onNo}>
//               취소
//             </button>
//           </div>
//         ) : (
//           <button style={buttonStyle} onClick={onOk}>
//             확인
//           </button>
//         )}
//       </div>
//     </div>,
//     document.body
//   );
// }

// export default MessageBox;

// import React from 'react';
// import ReactDOM from 'react-dom';
// import {
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   Button,
//   Typography,
// } from '@mui/material';

// function MessageBox({ title, content, onYes, onNo, onOk, type, onClose }) {
//   const handleContent = () => {
//     if (!content) return null;
//     return content.split('\n').map((str, index) => (
//       <React.Fragment key={index}>
//         {str}
//         <br />
//       </React.Fragment>
//     ));
//   };

//   return ReactDOM.createPortal(
//     <Dialog open onClose={onClose}>
//       <DialogTitle>
//         <Typography variant="h6">{title}</Typography>
//       </DialogTitle>
//       <DialogContent>
//         <Typography variant="body1">{handleContent()}</Typography>
//       </DialogContent>
//       <DialogActions>
//         {type === 'yesNo' ? (
//           <>
//             <Button onClick={onYes} variant="contained" color="primary">
//               확인
//             </Button>
//             <Button onClick={onNo} variant="outlined" color="secondary">
//               취소
//             </Button>
//           </>
//         ) : (
//           <Button onClick={onOk} variant="contained" color="primary">
//             확인
//           </Button>
//         )}
//       </DialogActions>
//     </Dialog>,
//     document.body
//   );
// }

// export default MessageBox;

import React from 'react';
import ReactDOM from 'react-dom';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
} from '@mui/material';

function MessageBox({
  title,
  content,
  onYes,
  onNo,
  onOk,
  type,
  onClose,
  isVisible,
}) {
  const handleContent = () => {
    if (!content) return null;
    return content.split('\n').map((str, index) => (
      <React.Fragment key={index}>
        {str}
        <br />
      </React.Fragment>
    ));
  };

  return ReactDOM.createPortal(
    <Dialog open={isVisible} onClose={onClose}>
      <DialogTitle>
        <Typography variant="h6">{title}</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">{handleContent()}</Typography>
      </DialogContent>
      <DialogActions>
        {type === 'yesNo' ? (
          <>
            <Button onClick={onYes} variant="contained" color="primary">
              Yes
            </Button>
            <Button onClick={onNo} variant="outlined" color="secondary">
              No
            </Button>
          </>
        ) : (
          <Button onClick={onOk} variant="contained" color="primary">
            OK
          </Button>
        )}
      </DialogActions>
    </Dialog>,
    document.body
  );
}
export default MessageBox;
