// import { useNavigate } from 'react-router-dom';
// import { useSelector } from 'react-redux';
// import PropTypes from 'prop-types';
// import '../css/SignUpFlow.css';

// function SignUpComplete({ onPrev, onNext, onClose,  setIsStepCompleted }) {
//     const currentUser = useSelector((state) => state.auth.currentUser);
//     // Using the navigate hook at the top of the component.
//     const navigate = useNavigate();

//     // Mark the step as completed immediately when this component loads.
//     setIsStepCompleted(true);

//     const handleGoStoreHome = () => {
//         navigate(`/mystore/${currentUser.uid}`);  // Redirect to the home page.
//         onClose();
//     };

//     return (

//         <div className='container'>
//             <h1 className="title">가입 완료!</h1>
//             <p className="text">BizUser 가입이 완료되었습니다. 서비스를 시작하세요.</p>
//             <button className="button" onClick={handleGoStoreHome}>Store 홈으로</button>
//         </div>

//     );
// }

// // Adding prop types for better documentation and type-checking.
// SignUpComplete.propTypes = {
//     onPrev: PropTypes.func.isRequired,
//     onNext: PropTypes.func.isRequired,
//     setIsStepCompleted: PropTypes.func.isRequired
// };

// export default SignUpComplete;

// import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import { db, doc, getDoc } from '../../firebaseConfig';
// import {
//   Container,
//   Typography,
//   Button,
//   List,
//   ListItem,
//   ListItemText,
//   Box,
// } from '@mui/material';

// import PropTypes from 'prop-types';

// function SignUpComplete({ onClose, setIsStepCompleted }) {
//   const [storeData, setStoreData] = useState(null);
//   const currentUser = useSelector((state) => state.auth.currentUser);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const loadStoreData = async () => {
//       try {
//         const storeRef = doc(db, 'stores', currentUser.uid);
//         const storeDoc = await getDoc(storeRef);
//         if (storeDoc.exists()) {
//           setStoreData(storeDoc.data());
//         }
//       } catch (error) {
//         console.error("Error fetching user's data:", error);
//       }
//     };
//     loadStoreData();
//   }, [currentUser, setIsStepCompleted]);
//   // Mark the step as completed immediately when this component loads.
//   //setIsStepCompleted(true);

//   const handleGoStoreHome = () => {
//     navigate(`/mystore/${currentUser.uid}`); // Redirect to the user's store home page.
//     onClose();
//   };

//   return (
//     <Container>
//       <Box textAlign="center" p={4}>
//         <Typography variant="h4" gutterBottom>
//           가입 완료!
//         </Typography>
//         <Typography variant="subtitle1" gutterBottom>
//           BizUser 가입이 완료되었습니다. 서비스를 시작하세요.
//         </Typography>
//         {storeData ? (
//           <List>
//             <ListItem>
//               <ListItemText primary="Name:" secondary={storeData.name} />
//             </ListItem>
//             <ListItem>
//               <ListItemText primary="Email:" secondary={storeData.email} />
//             </ListItem>
//             <ListItem>
//               <ListItemText
//                 primary="Description:"
//                 secondary={storeData.description}
//               />
//             </ListItem>
//             <ListItem>
//               <ListItemText primary="Address:" secondary={storeData.address} />
//             </ListItem>
//             <ListItem>
//               <ListItemText primary="Contact:" secondary={storeData.contact} />
//             </ListItem>
//             <ListItem>
//               <ListItemText
//                 primary="Store Position:"
//                 secondary={`Lat: ${storeData.storePosition?.lat}, Lng: ${storeData.storePosition?.lng}`}
//               />
//             </ListItem>
//           </List>
//         ) : (
//           <Box mt={2}></Box>
//         )}
//         <Button variant="contained" color="primary" onClick={handleGoStoreHome}>
//           Store 홈으로
//         </Button>
//       </Box>
//     </Container>
//   );
// }

// SignUpComplete.propTypes = {
//   onClose: PropTypes.func.isRequired,
//   setIsStepCompleted: PropTypes.func.isRequired,
// };

// export default SignUpComplete;

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { db, doc, getDoc } from '../firebaseConfig';
import {
  Container,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

function StoreSignUpComplete({ onClose, setIsStepCompleted }) {
  const { t, i18n } = useTranslation();
  const [storeData, setStoreData] = useState(null);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const navigate = useNavigate();

  useEffect(() => {
    const loadStoreData = async () => {
      try {
        const storeRef = doc(db, 'stores', currentUser.uid);
        const storeDoc = await getDoc(storeRef);
        if (storeDoc.exists()) {
          setStoreData(storeDoc.data());
        }
      } catch (error) {
        console.error(t('errorFetchingUserData'), error);
      }
    };
    loadStoreData();
  }, [currentUser, setIsStepCompleted, t]);

  const handleGoStoreHome = () => {
    navigate(`/mystore/${currentUser.uid}`);
    onClose();
  };

  return (
    <Container>
      <Box textAlign="center" p={4}>
        <Typography variant="h4" gutterBottom>
          {t('signupComplete')}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {t('bizUserSignupComplete')}
        </Typography>

        <Button variant="contained" color="primary" onClick={handleGoStoreHome}>
          {t('goToStoreHome')}
        </Button>
      </Box>
    </Container>
  );
}

StoreSignUpComplete.propTypes = {
  onClose: PropTypes.func.isRequired,
  setIsStepCompleted: PropTypes.func.isRequired,
};

export default StoreSignUpComplete;
