// import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import {
//   firebaseApp,
//   db,
//   auth,
//   analytics,
//   storage,
//   functions,
//   collection,
//   query,
//   where,
//   orderBy,
//   getDocs,
//   doc,
//   getDoc,
//   updateDoc,
//   ref,
//   getDownloadURL,
//   uploadBytesResumable,
// } from './firebaseConfig';

// import { useNavigate, useParams } from 'react-router-dom';
// import GoogleMapRegion from './Common/GoogleMapRegion';
// import ImageSlider from './ImageSlider';
// import moment from 'moment';
// import {
//   formatPrice,
//   getByteLength,
//   numberToKorean,
// } from './Utils/PriceFormatter';
// import './css/edit.css';
// import useMessageBox from './Common/useMessageBox';

// const Edit = () => {
//   const [MessageBoxInform, MessageBoxConfirm, MessageBoxRender] =
//     useMessageBox();
//   const [product, setProduct] = useState({});
//   // useState
//   // const [productState, setProductState] = useState({
//   //   title: "",
//   //   content: "",
//   //   price: null,
//   //   images: [],
//   //   selectedArea: {},
//   //   formattedDate: ""
//   // });
//   const [title, setTitle] = useState('');
//   const [price, setPrice] = useState('0');
//   const [content, setContent] = useState('');
//   const [existingImages, setExistingImages] = useState([]); // For already uploaded images URLs
//   const [newImages, setNewImages] = useState([]); // For new images File objects to be uploaded
//   const [previews, setPreviews] = useState([]); // 여러 이미지의 미리보기 URL을 위한 상태
//   const [uploadProgress, setUploadProgress] = useState(0);
//   const [koreanPrice, setKoreanPrice] = useState('0');
//   const [showMap, setShowMap] = useState(false);
//   const [selectedArea, setSelectedArea] = useState({ center: {}, radius: 0 });

//   const { id: productId } = useParams();
//   const navigate = useNavigate();

//   const currentUser = useSelector((state) => state.auth.currentUser);

//   useEffect(() => {
//     fetchProductData();
//   }, [productId]);

//   const fetchProductData = async () => {
//     try {
//       const productRef = doc(db, 'products', productId);
//       const productSnapshot = await getDoc(productRef);
//       const rawData = productSnapshot.data();
//       if (rawData) {
//         const productData = {
//           id: productSnapshot.id,
//           ...rawData,
//           formattedDate: moment(rawData.createDate?.toDate()).fromNow(),
//         };
//         setProduct(productData); // 상태를 한번에 업데이트

//         setTitle(productData.title);
//         setContent(productData.content);
//         setExistingImages(productData.images);
//         setPreviews(productData.images);
//         setPrice(formatPrice(productData.price));
//         setSelectedArea(productData.selectedArea);
//       }
//     } catch (err) {
//       console.log(err);
//     }
//     setShowMap(true);
//   };

//   // 가격이 변경될 때 한글로 된 가격도 함께 변경
//   useEffect(() => {
//     setKoreanPrice(numberToKorean(price));
//   }, [price]);

//   // 가격 입력 관리
//   const handlePriceChange = (e) => {
//     let priceValue = e.target.value.replace(/[^0-9]/g, ''); // 숫자만 허용
//     // 앞쪽의 불필요한 0 제거
//     while (priceValue.startsWith('0') && priceValue.length > 1) {
//       priceValue = priceValue.substring(1);
//     }
//     setPrice(priceValue);
//   };

//   const handleContentChange = (e) => {
//     if (getByteLength(e.target.value) <= 200) {
//       setContent(e.target.value);
//     }
//   };
//   const contentByteSize = getByteLength(content);

//   const handleImageChange = (event) => {
//     const selectedImages = Array.from(event.target.files);
//     //const newPreviews = selectedImages.map(image => URL.createObjectURL(image));

//     setNewImages((prevImages) => [...prevImages, ...selectedImages]);
//     //setPreviews(prevPreviews => [...prevPreviews, ...newPreviews]);
//     // 각 이미지에 대한 미리보기 생성
//     const readerPromises = selectedImages.map((file) => {
//       return new Promise((resolve, reject) => {
//         const reader = new FileReader();
//         reader.onloadend = () => {
//           resolve(reader.result);
//         };
//         reader.onerror = reject;
//         reader.readAsDataURL(file);
//       });
//     });

//     Promise.all(readerPromises).then((results) => {
//       setPreviews((prevPreviews) => [...prevPreviews, ...results]);
//     });
//   };
//   const handleRemoveImage = (indexToRemove) => {
//     setExistingImages((prevImages) =>
//       prevImages.filter((_, index) => index !== indexToRemove)
//     );
//     setPreviews((prevPreviews) =>
//       prevPreviews.filter((_, index) => index !== indexToRemove)
//     );
//   };
//   const handleCancel = () => {
//     navigate(-1);
//   };
//   const handleUpload = async () => {
//     if (!currentUser) {
//       MessageBoxInform('로그인이 필요합니다.');
//       return;
//     }
//     // 입력 검사: 제목, 내용, 이미지, 가격이 입력되었는지 확인
//     if (title.trim() === '') {
//       MessageBoxInform('제목을 입력해주세요.');
//       return;
//     }
//     if (content.trim() === '') {
//       MessageBoxInform('내용을 입력해주세요.');
//       return;
//     }
//     // if (price.trim() === '0') {
//     //     MessageBoxInform("가격을 입력해주세요.");
//     //     return;
//     // }

//     if (!selectedArea || selectedArea.length === 0) {
//       MessageBoxInform('알림받을 지역을 선택해주세요.');
//       throw new Error('알림받을 지역을 선택해주세요.');
//     }

//     if (!existingImages.length && !newImages.length) {
//       MessageBoxInform('이미지를 업로드해주세요.');
//       return;
//     }

//     // 사용자에게 최종 내용 확인 요청
//     const isConfirmed = window.confirm(
//       '다음 내용으로 상품을 수정하시겠습니까?\n' +
//         `제목: ${title}\n` +
//         `내용: ${content}\n` +
//         `가격: ${formatPrice(price)} (${koreanPrice} 원)`
//     );
//     if (!isConfirmed) {
//       return; // 사용자가 확인을 클릭하지 않았으면 함수 종료
//     }

//     // 진행률 초기화
//     setUploadProgress(0);

//     let loadedImages = 0;

//     // 새로 추가한 이미지에 대한 업로드 프로미스 생성

//     const uploadPromises = newImages.map((image) => {
//       const uploadPath = ref(
//         storage,
//         process.env.REACT_APP_USER_IMAGE_PATH + '/' + image.name
//       );

//       return new Promise((resolve, reject) => {
//         const uploadTask = uploadBytesResumable(uploadPath, image);

//         uploadTask.on(
//           'state_changed',
//           (snapshot) => {
//             const progress =
//               (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
//             setUploadProgress(
//               (progress + 100 * loadedImages) / newImages.length
//             );
//           },
//           reject,
//           async () => {
//             try {
//               const downloadURL = await getDownloadURL(uploadPath);
//               setUploadProgress(100);
//               loadedImages += 1;
//               resolve(downloadURL);
//             } catch (error) {
//               reject(error);
//             }
//           }
//         );
//       });
//     });

//     Promise.all(uploadPromises)
//       .then(async (downloadURLs) => {
//         const allImages = [...existingImages, ...downloadURLs];
//         const productData = {
//           title,
//           price,
//           content,
//           createDate: new Date(),
//           images: allImages,
//           uid: currentUser.uid,
//           selectedArea: selectedArea,
//         };

//         const productRef = doc(db, 'products', productId);
//         try {
//           await updateDoc(productRef, productData);
//           MessageBoxInform('상품 정보가 성공적으로 수정되었습니다.');
//           navigate(`/detail/${productId}`);
//         } catch (err) {
//           console.error('Error updating product:', err);
//         }
//       })
//       .catch((error) => {
//         console.error('Error reading images:', error);
//       });
//   };
//   const ImagePreview = ({ preview, index }) => (
//     <div style={{ display: 'inline-block', position: 'relative' }}>
//       <img src={preview} alt="Preview" className="preview-image" />
//       <button
//         style={{
//           position: 'absolute',
//           top: '5px',
//           right: '5px',
//           background: 'rgba(0, 0, 0, 0.5)',
//           color: 'white',
//           border: 'none',
//           fontSize: '10px',
//           width: '20px',
//           height: '10px', // 세로 길이 조정
//           cursor: 'pointer',
//           padding: '0',
//           lineHeight: '10px', // 세로 길이에 맞게 조정
//           textAlign: 'center',
//           boxSizing: 'border-box', // padding과 border를 width 및 height에 포함
//         }}
//         onClick={() => handleRemoveImage(index)}
//       >
//         x
//       </button>
//     </div>
//   );

//   const handleSaveArea = (savedArea) => {
//     // savedArea 값을 서버에 저장하는 로직
//     setSelectedArea(savedArea);
//   };

//   return (
//     <div className="edit">
//       <div className="container">
//         {product.images && product.images.length > 0 && (
//           <ImageSlider images={previews} />
//         )}
//         <div className="form-section">
//           <label className="title-label" for="title">
//             제목
//           </label>
//           <input
//             type="text"
//             className="form-control mt-2"
//             value={title}
//             onChange={(e) => setTitle(e.target.value)}
//             placeholder="Title"
//           />
//         </div>
//         <div className="form-section">
//           <label className="content-label" for="content">
//             내용
//           </label>
//           <div className="mt-2 position-relative">
//             <p className="mb-4"></p>
//             <textarea
//               className="form-control"
//               rows="5"
//               value={content}
//               onChange={handleContentChange}
//               placeholder="content"
//             ></textarea>
//             <div className="position-absolute top-0 end-0">
//               <p>{contentByteSize}/200 바이트 입력됨</p>
//             </div>
//           </div>
//         </div>
//         <div className="form-section">
//           <label className="price-label" for="price">
//             가격
//           </label>
//           <input
//             type="text"
//             className="form-control mt-2"
//             placeholder="Price (e.g. 1,000원)"
//             value={formatPrice(price)}
//             onChange={handlePriceChange}
//           />
//           <span className="korean-price-label">
//             {koreanPrice !== '0' ? `${koreanPrice}원` : '무료 나눔'}
//           </span>
//         </div>
//         {MessageBoxRender()}
//         <div className="form-section">
//           <label className="title-label" for="title">
//             이미지 업로드
//           </label>
//           <div className="mt-2">
//             <progress
//               value={uploadProgress}
//               max="100"
//               className="w-100"
//             ></progress>
//             <span>{Math.round(uploadProgress)}%</span>
//           </div>

//           <div
//             className="preview-container"
//             style={{ display: 'flex', flexDirection: 'column' }}
//           >
//             <div
//               className="form-section"
//               style={{ display: 'flex', flexDirection: 'row' }}
//             >
//               <input
//                 className="form-control mt-2"
//                 type="file"
//                 multiple
//                 onChange={handleImageChange}
//               />
//             </div>
//             <div
//               className="form-section"
//               style={{ display: 'flex', flexDirection: 'row' }}
//             >
//               {previews &&
//                 previews.map((preview, index) => (
//                   <ImagePreview key={index} preview={preview} index={index} />
//                 ))}
//             </div>
//           </div>
//         </div>
//         <div className="form-section">
//           <label className="price-label" for="price">
//             지역설정
//           </label>
//           <div>
//             {/* <button onClick={() => setShowMap(true)}>보여질 동네 선택</button> */}
//             {showMap && (
//               <GoogleMapRegion
//                 selectedArea={product.selectedArea}
//                 onSave={handleSaveArea}
//               />
//             )}
//           </div>
//         </div>
//         <div className="bottom-buttons">
//           <button
//             className="btn btn-outline-primary action-button"
//             onClick={handleCancel}
//           >
//             취소하기
//           </button>
//           <button
//             className="btn btn-outline-danger action-button"
//             onClick={handleUpload}
//           >
//             수정하기
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Edit;

// import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import {
//   db,
//   storage,
//   doc,
//   getDoc,
//   updateDoc,
//   ref,
//   getDownloadURL,
//   uploadBytesResumable,
// } from './firebaseConfig';
// import { useNavigate, useParams } from 'react-router-dom';
// import GoogleMapRegion from './Common/GoogleMapRegion';
// import ImageSlider from './ImageSlider';
// import moment from 'moment';
// import {
//   formatPrice,
//   getByteLength,
//   numberToKorean,
// } from './Utils/PriceFormatter';
// import './css/edit.css';
// import useMessageBox from './Common/useMessageBox';
// import {
//   Box,
//   Button,
//   Container,
//   TextField,
//   Typography,
//   IconButton,
//   LinearProgress,
// } from '@mui/material';
// import DeleteIcon from '@mui/icons-material/Delete';
// import { useTranslation } from 'react-i18next';

// const Edit = () => {
//   const [MessageBoxInform, MessageBoxConfirm, MessageBoxRender] =
//     useMessageBox();
//   const [product, setProduct] = useState({});
//   const [title, setTitle] = useState('');
//   const [price, setPrice] = useState('0');
//   const [content, setContent] = useState('');
//   const [existingImages, setExistingImages] = useState([]); // For already uploaded images URLs
//   const [newImages, setNewImages] = useState([]); // For new images File objects to be uploaded
//   const [previews, setPreviews] = useState([]); // Initialize previews as an array
//   const [uploadProgress, setUploadProgress] = useState(0);
//   const [koreanPrice, setKoreanPrice] = useState('0');
//   const [showMap, setShowMap] = useState(false);
//   const [selectedArea, setSelectedArea] = useState({ center: {}, radius: 0 });

//   const { id: productId } = useParams();
//   const navigate = useNavigate();
//   const { t } = useTranslation();
//   const currentUser = useSelector((state) => state.auth.currentUser);

//   useEffect(() => {
//     fetchProductData();
//   }, [productId]);

//   const fetchProductData = async () => {
//     try {
//       const productRef = doc(db, 'products', productId);
//       const productSnapshot = await getDoc(productRef);
//       const rawData = productSnapshot.data();
//       if (rawData) {
//         const productData = {
//           id: productSnapshot.id,
//           ...rawData,
//           formattedDate: moment(rawData.createDate?.toDate()).fromNow(),
//         };
//         setProduct(productData); // 상태를 한번에 업데이트

//         setTitle(productData.title);
//         setContent(productData.content);
//         setExistingImages(productData.images);
//         setPreviews(productData.images);
//         setPrice(formatPrice(productData.price));
//         setSelectedArea(productData.selectedArea);
//       }
//     } catch (err) {
//       console.log(err);
//     }
//     setShowMap(true);
//   };

//   useEffect(() => {
//     setKoreanPrice(numberToKorean(price));
//   }, [price]);

//   const handlePriceChange = (e) => {
//     let priceValue = e.target.value.replace(/[^0-9]/g, '');
//     while (priceValue.startsWith('0') && priceValue.length > 1) {
//       priceValue = priceValue.substring(1);
//     }
//     setPrice(priceValue);
//   };

//   const handleContentChange = (e) => {
//     if (getByteLength(e.target.value) <= 200) {
//       setContent(e.target.value);
//     }
//   };

//   const contentByteSize = getByteLength(content);

//   const handleImageChange = (event) => {
//     const selectedImages = Array.from(event.target.files);
//     setNewImages((prevImages) => [...prevImages, ...selectedImages]);

//     const readerPromises = selectedImages.map((file) => {
//       return new Promise((resolve, reject) => {
//         const reader = new FileReader();
//         reader.onloadend = () => {
//           resolve(reader.result);
//         };
//         reader.onerror = reject;
//         reader.readAsDataURL(file);
//       });
//     });

//     Promise.all(readerPromises).then((results) => {
//       setPreviews((prevPreviews) => [...(prevPreviews || []), ...results]);
//     });
//   };

//   const handleRemoveImage = (indexToRemove) => {
//     if (indexToRemove < existingImages.length) {
//       setExistingImages((prevImages) =>
//         prevImages.filter((_, index) => index !== indexToRemove)
//       );
//     } else {
//       const newIndex = indexToRemove - existingImages.length;
//       setNewImages((prevImages) =>
//         prevImages.filter((_, index) => index !== newIndex)
//       );
//     }
//     setPreviews((prevPreviews) =>
//       prevPreviews.filter((_, index) => index !== indexToRemove)
//     );
//   };

//   const handleCancel = () => {
//     navigate(-1);
//   };

//   const handleUpload = async () => {
//     if (!currentUser) {
//       MessageBoxInform(t('loginRequired'));
//       return;
//     }

//     if (title.trim() === '') {
//       MessageBoxInform(t('enterTitle'));
//       return;
//     }
//     if (content.trim() === '') {
//       MessageBoxInform(t('enterContent'));
//       return;
//     }
//     if (!selectedArea || selectedArea.length === 0) {
//       MessageBoxInform(t('selectArea'));
//       throw new Error(t('selectArea'));
//     }

//     if (!existingImages.length && !newImages.length) {
//       MessageBoxInform(t('uploadImages'));
//       return;
//     }

//     const isConfirmed = window.confirm(
//       `${t('confirmProductDetails')}\n` +
//         `${t('title')}: ${title}\n` +
//         `${t('content')}: ${content}\n` +
//         `${t('price')}: ${formatPrice(price)} (${koreanPrice} ${t('won')})`
//     );
//     if (!isConfirmed) {
//       return;
//     }

//     setUploadProgress(0);
//     let loadedImages = 0;

//     const uploadPromises = newImages.map((image) => {
//       const uploadPath = ref(
//         storage,
//         process.env.REACT_APP_USER_IMAGE_PATH + '/' + image.name
//       );

//       return new Promise((resolve, reject) => {
//         const uploadTask = uploadBytesResumable(uploadPath, image);

//         uploadTask.on(
//           'state_changed',
//           (snapshot) => {
//             const progress =
//               (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
//             setUploadProgress(
//               (progress + 100 * loadedImages) / newImages.length
//             );
//           },
//           reject,
//           async () => {
//             try {
//               const downloadURL = await getDownloadURL(uploadPath);
//               setUploadProgress(100);
//               loadedImages += 1;
//               resolve(downloadURL);
//             } catch (error) {
//               reject(error);
//             }
//           }
//         );
//       });
//     });

//     Promise.all(uploadPromises)
//       .then(async (downloadURLs) => {
//         const allImages = [...existingImages, ...downloadURLs];
//         const productData = {
//           title,
//           price,
//           content,
//           createDate: new Date(),
//           images: allImages,
//           uid: currentUser.uid,
//           selectedArea: selectedArea,
//         };

//         const productRef = doc(db, 'products', productId);
//         try {
//           await updateDoc(productRef, productData);
//           MessageBoxInform(t('productUpdated'));
//           navigate(`/detail/${productId}`);
//         } catch (err) {
//           console.error('Error updating product:', err);
//         }
//       })
//       .catch((error) => {
//         console.error('Error reading images:', error);
//       });
//   };

//   const ImagePreview = ({ preview, index }) => (
//     <Box position="relative" display="inline-block">
//       <img src={preview} alt="Preview" className="preview-image" />
//       <IconButton
//         size="small"
//         sx={{
//           position: 'absolute',
//           top: 5,
//           right: 5,
//           backgroundColor: 'rgba(0, 0, 0, 0.5)',
//           color: 'white',
//           '&:hover': {
//             backgroundColor: 'rgba(0, 0, 0, 0.7)',
//           },
//         }}
//         onClick={() => handleRemoveImage(index)}
//       >
//         <DeleteIcon fontSize="small" />
//       </IconButton>
//     </Box>
//   );

//   const handleSaveArea = (savedArea) => {
//     setSelectedArea(savedArea);
//   };

//   return (
//     <Container maxWidth="md">
//       <Box sx={{ mt: 4 }}>
//         {product.images && product.images.length > 0 && (
//           <ImageSlider images={previews} />
//         )}
//         <Box sx={{ mt: 4 }}>
//           <TextField
//             fullWidth
//             label={t('title')}
//             value={title}
//             onChange={(e) => setTitle(e.target.value)}
//             placeholder={t('title')}
//             variant="outlined"
//             sx={{ mb: 2 }}
//           />
//           <TextField
//             fullWidth
//             label={t('content')}
//             value={content}
//             onChange={handleContentChange}
//             placeholder={t('content')}
//             multiline
//             rows={5}
//             variant="outlined"
//             sx={{ mb: 2 }}
//           />
//           <Typography
//             variant="body2"
//             color="textSecondary"
//             sx={{ textAlign: 'right', mb: 2 }}
//           >
//             {contentByteSize}/200 {t('bytes')}
//           </Typography>
//           <TextField
//             fullWidth
//             label={t('price')}
//             value={formatPrice(price)}
//             onChange={handlePriceChange}
//             placeholder="Price (e.g. 1,000원)"
//             variant="outlined"
//             sx={{ mb: 2 }}
//           />
//           <Typography variant="body2" color="textSecondary">
//             {koreanPrice !== '0' ? `${koreanPrice}${t('won')}` : t('free')}
//           </Typography>
//         </Box>
//         <Box sx={{ mt: 4 }}>
//           <Typography variant="h6" gutterBottom>
//             {t('uploadImages')}
//           </Typography>
//           <input
//             type="file"
//             multiple
//             onChange={handleImageChange}
//             style={{ display: 'none' }}
//             id="upload-button"
//           />
//           <label htmlFor="upload-button">
//             <Button
//               variant="contained"
//               color="primary"
//               component="span"
//               fullWidth
//               sx={{ mb: 2 }}
//             >
//               {t('selectImages')}
//             </Button>
//           </label>
//           <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
//             {previews &&
//               previews.map((preview, index) => (
//                 <ImagePreview key={index} preview={preview} index={index} />
//               ))}
//           </Box>
//           <LinearProgress
//             variant="determinate"
//             value={uploadProgress}
//             sx={{ mt: 2 }}
//           />
//           <Typography variant="body2" sx={{ mt: 1 }}>
//             {Math.round(uploadProgress)}%
//           </Typography>
//         </Box>
//         <Box sx={{ mt: 4 }}>
//           <Typography variant="h6" gutterBottom>
//             {t('setRegion')}
//           </Typography>
//           {showMap && (
//             <GoogleMapRegion
//               selectedArea={product.selectedArea}
//               onSave={handleSaveArea}
//             />
//           )}
//         </Box>
//         <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
//           <Button variant="outlined" color="secondary" onClick={handleCancel}>
//             {t('cancel')}
//           </Button>
//           <Button variant="contained" color="primary" onClick={handleUpload}>
//             {t('update')}
//           </Button>
//         </Box>
//       </Box>
//       {MessageBoxRender()}
//     </Container>
//   );
// };

// export default Edit;

// import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import {
//   db,
//   storage,
//   doc,
//   getDoc,
//   updateDoc,
//   ref,
//   getDownloadURL,
//   uploadBytesResumable,
// } from './firebaseConfig';
// import { useNavigate, useParams } from 'react-router-dom';
// import GoogleMapRegion from './Common/GoogleMapRegion';
// import ImageSlider from './ImageSlider';
// import moment from 'moment';
// import {
//   formatPrice,
//   getByteLength,
//   numberToKorean,
// } from './Utils/PriceFormatter';
// import './css/edit.css';
// import useMessageBox from './Common/useMessageBox';
// import {
//   Box,
//   Button,
//   Container,
//   TextField,
//   Typography,
//   IconButton,
//   LinearProgress,
// } from '@mui/material';
// import DeleteIcon from '@mui/icons-material/Delete';
// import { useTranslation } from 'react-i18next';

// const Edit = () => {
//   const [MessageBoxInform, MessageBoxConfirm, MessageBoxRender] =
//     useMessageBox();
//   const [product, setProduct] = useState({});
//   const [title, setTitle] = useState('');
//   const [price, setPrice] = useState('0');
//   const [content, setContent] = useState('');
//   const [existingImages, setExistingImages] = useState([]); // For already uploaded images URLs
//   const [newImages, setNewImages] = useState([]); // For new images File objects to be uploaded
//   const [previews, setPreviews] = useState([]); // Initialize previews as an array
//   const [uploadProgress, setUploadProgress] = useState(0);
//   const [koreanPrice, setKoreanPrice] = useState('0');
//   const [showMap, setShowMap] = useState(false);
//   const [selectedArea, setSelectedArea] = useState({ center: {}, radius: 0 });

//   const { id: productId } = useParams();
//   const navigate = useNavigate();
//   const { t } = useTranslation();
//   const currentUser = useSelector((state) => state.auth.currentUser);

//   useEffect(() => {
//     fetchProductData();
//   }, [productId]);

//   const fetchProductData = async () => {
//     try {
//       const productRef = doc(db, 'products', productId);
//       const productSnapshot = await getDoc(productRef);
//       const rawData = productSnapshot.data();
//       if (rawData) {
//         const productData = {
//           id: productSnapshot.id,
//           ...rawData,
//           formattedDate: moment(rawData.createDate?.toDate()).fromNow(),
//         };
//         setProduct(productData); // 상태를 한번에 업데이트

//         setTitle(productData.title);
//         setContent(productData.content);
//         setExistingImages(productData.images || []);
//         setPreviews(productData.images || []);
//         setPrice(formatPrice(productData.price));
//         setSelectedArea(productData.selectedArea);
//       }
//     } catch (err) {
//       console.log(err);
//     }
//     setShowMap(true);
//   };

//   useEffect(() => {
//     setKoreanPrice(numberToKorean(price));
//   }, [price]);

//   const handlePriceChange = (e) => {
//     let priceValue = e.target.value.replace(/[^0-9]/g, '');
//     while (priceValue.startsWith('0') && priceValue.length > 1) {
//       priceValue = priceValue.substring(1);
//     }
//     setPrice(priceValue);
//   };

//   const handleContentChange = (e) => {
//     if (getByteLength(e.target.value) <= 200) {
//       setContent(e.target.value);
//     }
//   };

//   const contentByteSize = getByteLength(content);

//   const handleImageChange = (event) => {
//     const selectedImages = Array.from(event.target.files);
//     setNewImages((prevImages) => [...prevImages, ...selectedImages]);

//     const readerPromises = selectedImages.map((file) => {
//       return new Promise((resolve, reject) => {
//         const reader = new FileReader();
//         reader.onloadend = () => {
//           resolve(reader.result);
//         };
//         reader.onerror = reject;
//         reader.readAsDataURL(file);
//       });
//     });

//     Promise.all(readerPromises).then((results) => {
//       setPreviews((prevPreviews) => [...(prevPreviews || []), ...results]);
//     });
//   };

//   const handleRemoveImage = (indexToRemove) => {
//     if (indexToRemove < existingImages.length) {
//       setExistingImages((prevImages) =>
//         prevImages.filter((_, index) => index !== indexToRemove)
//       );
//     } else {
//       const newIndex = indexToRemove - existingImages.length;
//       setNewImages((prevImages) =>
//         prevImages.filter((_, index) => index !== newIndex)
//       );
//     }
//     setPreviews((prevPreviews) =>
//       prevPreviews.filter((_, index) => index !== indexToRemove)
//     );
//   };

//   const handleCancel = () => {
//     navigate(-1);
//   };

//   const handleUpload = async () => {
//     if (!currentUser) {
//       MessageBoxInform(t('loginRequired'));
//       return;
//     }

//     if (title.trim() === '') {
//       MessageBoxInform(t('enterTitle'));
//       return;
//     }
//     if (content.trim() === '') {
//       MessageBoxInform(t('enterContent'));
//       return;
//     }
//     if (!selectedArea || selectedArea.length === 0) {
//       MessageBoxInform(t('selectArea'));
//       throw new Error(t('selectArea'));
//     }

//     if (!existingImages.length && !newImages.length) {
//       MessageBoxInform(t('uploadImages'));
//       return;
//     }

//     const isConfirmed = window.confirm(
//       `${t('confirmProductDetails')}\n` +
//         `${t('title')}: ${title}\n` +
//         `${t('content')}: ${content}\n` +
//         `${t('price')}: ${formatPrice(price)} (${koreanPrice} ${t('won')})`
//     );
//     if (!isConfirmed) {
//       return;
//     }

//     setUploadProgress(0);
//     let loadedImages = 0;

//     const uploadPromises = newImages.map((image) => {
//       const uploadPath = ref(
//         storage,
//         process.env.REACT_APP_USER_IMAGE_PATH + '/' + image.name
//       );

//       return new Promise((resolve, reject) => {
//         const uploadTask = uploadBytesResumable(uploadPath, image);

//         uploadTask.on(
//           'state_changed',
//           (snapshot) => {
//             const progress =
//               (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
//             setUploadProgress(
//               (progress + 100 * loadedImages) / newImages.length
//             );
//           },
//           reject,
//           async () => {
//             try {
//               const downloadURL = await getDownloadURL(uploadPath);
//               setUploadProgress(100);
//               loadedImages += 1;
//               resolve(downloadURL);
//             } catch (error) {
//               reject(error);
//             }
//           }
//         );
//       });
//     });

//     Promise.all(uploadPromises)
//       .then(async (downloadURLs) => {
//         const allImages = [...existingImages, ...downloadURLs];
//         const productData = {
//           title,
//           price,
//           content,
//           createDate: new Date(),
//           images: allImages,
//           uid: currentUser.uid,
//           selectedArea: selectedArea,
//         };

//         const productRef = doc(db, 'products', productId);
//         try {
//           await updateDoc(productRef, productData);
//           MessageBoxInform(t('productUpdated'));
//           navigate(`/detail/${productId}`);
//         } catch (err) {
//           console.error('Error updating product:', err);
//         }
//       })
//       .catch((error) => {
//         console.error('Error reading images:', error);
//       });
//   };

//   const ImagePreview = ({ preview, index }) => (
//     <Box
//       position="relative"
//       display="inline-block"
//       sx={{
//         width: 100,
//         height: 100,
//         m: 1,
//         overflow: 'hidden',
//         borderRadius: '8px',
//         border: '1px solid #ddd',
//       }}
//     >
//       <img
//         src={preview}
//         alt="Preview"
//         style={{
//           width: '100%',
//           height: '100%',
//           objectFit: 'cover',
//         }}
//       />
//       <IconButton
//         size="small"
//         sx={{
//           position: 'absolute',
//           top: 5,
//           right: 5,
//           backgroundColor: 'rgba(0, 0, 0, 0.5)',
//           color: 'white',
//           '&:hover': {
//             backgroundColor: 'rgba(0, 0, 0, 0.7)',
//           },
//         }}
//         onClick={() => handleRemoveImage(index)}
//       >
//         <DeleteIcon fontSize="small" />
//       </IconButton>
//     </Box>
//   );

//   const handleSaveArea = (savedArea) => {
//     setSelectedArea(savedArea);
//   };

//   return (
//     <Container maxWidth="md">
//       <Box sx={{ mt: 4 }}>
//         {product.images && product.images.length > 0 && (
//           <ImageSlider images={previews} />
//         )}
//         <Box sx={{ mt: 4 }}>
//           <TextField
//             fullWidth
//             label={t('title')}
//             value={title}
//             onChange={(e) => setTitle(e.target.value)}
//             placeholder={t('title')}
//             variant="outlined"
//             sx={{ mb: 2 }}
//           />
//           <TextField
//             fullWidth
//             label={t('content')}
//             value={content}
//             onChange={handleContentChange}
//             placeholder={t('content')}
//             multiline
//             rows={5}
//             variant="outlined"
//             sx={{ mb: 2 }}
//           />
//           <Typography
//             variant="body2"
//             color="textSecondary"
//             sx={{ textAlign: 'right', mb: 2 }}
//           >
//             {contentByteSize}/200 {t('bytes')}
//           </Typography>
//           <TextField
//             fullWidth
//             label={t('price')}
//             value={formatPrice(price)}
//             onChange={handlePriceChange}
//             placeholder="Price (e.g. 1,000원)"
//             variant="outlined"
//             sx={{ mb: 2 }}
//           />
//           <Typography variant="body2" color="textSecondary">
//             {koreanPrice !== '0' ? `${koreanPrice}${t('won')}` : t('free')}
//           </Typography>
//         </Box>
//         {MessageBoxRender()}
//         <Box sx={{ mt: 4 }}>
//           <Typography variant="h6" gutterBottom>
//             {t('uploadImages')}
//           </Typography>
//           <input
//             type="file"
//             multiple
//             onChange={handleImageChange}
//             style={{ display: 'none' }}
//             id="upload-button"
//           />
//           <label htmlFor="upload-button">
//             <Button
//               variant="contained"
//               color="primary"
//               component="span"
//               fullWidth
//               sx={{ mb: 2 }}
//             >
//               {t('selectImages')}
//             </Button>
//           </label>
//           <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
//             {previews &&
//               previews.map((preview, index) => (
//                 <ImagePreview key={index} preview={preview} index={index} />
//               ))}
//           </Box>
//           <LinearProgress
//             variant="determinate"
//             value={uploadProgress}
//             sx={{ mt: 2 }}
//           />
//           <Typography variant="body2" sx={{ mt: 1 }}>
//             {Math.round(uploadProgress)}%
//           </Typography>
//         </Box>
//         <Box sx={{ mt: 4 }}>
//           <Typography variant="h6" gutterBottom>
//             {t('setRegion')}
//           </Typography>
//           {showMap && (
//             <GoogleMapRegion
//               selectedArea={product.selectedArea}
//               onSave={handleSaveArea}
//             />
//           )}
//         </Box>
//         <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
//           <Button variant="outlined" color="secondary" onClick={handleCancel}>
//             {t('cancel')}
//           </Button>
//           <Button variant="contained" color="primary" onClick={handleUpload}>
//             {t('update')}
//           </Button>
//         </Box>
//       </Box>
//     </Container>
//   );
// };

// export default Edit;

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  db,
  storage,
  doc,
  getDoc,
  updateDoc,
  ref,
  getDownloadURL,
  uploadBytesResumable,
} from './firebaseConfig';
import { useNavigate, useParams } from 'react-router-dom';
import GoogleMapRegion from './Common/GoogleMapRegion';
import ImageSlider from './ImageSlider';
import moment from 'moment';
import { getByteLength, numberToKorean } from './Utils/PriceFormatter';
import './css/edit.css';
import useMessageBox from './Common/useMessageBox';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  IconButton,
  LinearProgress,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';

const MAX_FILES = parseInt(
  process.env.REACT_APP_SEND_IMAGE_MAX_LEN || '10',
  10
);

const Edit = () => {
  const [MessageBoxInform, MessageBoxConfirm, MessageBoxRender] =
    useMessageBox();
  const [product, setProduct] = useState({});
  const [title, setTitle] = useState('');
  const [price, setPrice] = useState('0');
  const [formattedPrice, setFormattedPrice] = useState('');
  const [content, setContent] = useState('');
  const [existingImages, setExistingImages] = useState([]); // For already uploaded images URLs
  const [newImages, setNewImages] = useState([]); // For new images File objects to be uploaded
  const [previews, setPreviews] = useState([]); // Initialize previews as an array
  const [uploadProgress, setUploadProgress] = useState(0);
  const [showMap, setShowMap] = useState(false);
  const [selectedArea, setSelectedArea] = useState({ center: {}, radius: 0 });

  const { id: productId } = useParams();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const currentUser = useSelector((state) => state.auth.currentUser);

  useEffect(() => {
    fetchProductData();
  }, [productId]);

  useEffect(() => {
    const formattedPrice = new Intl.NumberFormat(i18n.language, {
      style: 'currency',
      currency: getCurrencyByLocale(i18n.language),
    }).format(price);
    setFormattedPrice(formattedPrice);
  }, [price, i18n.language]);

  const fetchProductData = async () => {
    try {
      const productRef = doc(db, 'products', productId);
      const productSnapshot = await getDoc(productRef);
      const rawData = productSnapshot.data();
      if (rawData) {
        const productData = {
          id: productSnapshot.id,
          ...rawData,
          formattedDate: moment(rawData.createDate?.toDate()).fromNow(),
        };
        setProduct(productData); // 상태를 한번에 업데이트

        setTitle(productData.title);
        setContent(productData.content);
        setExistingImages(productData.images || []);
        setPreviews(productData.images || []);
        setPrice(productData.price.toString());
        setSelectedArea(productData.selectedArea || { center: {}, radius: 0 });
      }
    } catch (err) {
      console.log(err);
    }
    setShowMap(true);
  };

  const handlePriceChange = (e) => {
    const priceValue = e.target.value.replace(/[^0-9]/g, '');
    setPrice(priceValue);
  };

  const handleContentChange = (e) => {
    if (getByteLength(e.target.value) <= 200) {
      setContent(e.target.value);
    }
  };

  const handleImageChange = (event) => {
    const selectedImages = Array.from(event.target.files);

    if (
      existingImages.length + newImages.length + selectedImages.length >
      MAX_FILES
    ) {
      MessageBoxInform(`최대 ${MAX_FILES}개의 이미지를 선택할 수 있습니다.`);
      return;
    }

    setNewImages((prevImages) => [...prevImages, ...selectedImages]);

    const readerPromises = selectedImages.map((file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    });

    Promise.all(readerPromises).then((results) => {
      setPreviews((prevPreviews) => [...prevPreviews, ...results]);
    });
  };

  const handleRemoveImage = (indexToRemove) => {
    if (indexToRemove < existingImages.length) {
      setExistingImages((prevImages) =>
        prevImages.filter((_, index) => index !== indexToRemove)
      );
    } else {
      const newIndex = indexToRemove - existingImages.length;
      setNewImages((prevImages) =>
        prevImages.filter((_, index) => index !== newIndex)
      );
    }
    setPreviews((prevPreviews) =>
      prevPreviews.filter((_, index) => index !== indexToRemove)
    );
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const handleUpload = async () => {
    if (!currentUser) {
      MessageBoxInform(t('login_required'));
      return;
    }

    if (title.trim() === '' || content.trim() === '') {
      MessageBoxInform(t('enterRequiredFields'));
      return;
    }

    // if (!selectedArea || !selectedArea.center || !selectedArea.coordinates) {
    //   MessageBoxInform(t('selectArea'));
    //   return;
    // }

    if (!existingImages.length && !newImages.length) {
      MessageBoxInform(t('uploadImages'));
      return;
    }

    const isConfirmed = await MessageBoxConfirm(
      t('confirmProductDetails'),
      `${t('title')}: ${title}\n${t('content')}: ${content}\n${t(
        'price'
      )}: ${formattedPrice}`
    );
    if (!isConfirmed) return;

    setUploadProgress(0);
    let loadedImages = 0;

    const uploadPromises = newImages.map((image) => {
      const uploadPath = ref(
        storage,
        `${process.env.REACT_APP_USER_IMAGE_PATH}/${image.name}`
      );

      return new Promise((resolve, reject) => {
        const uploadTask = uploadBytesResumable(uploadPath, image);

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress((prevProgress) => {
              const newProgress =
                ((loadedImages + progress / 100) / newImages.length) * 100;
              return newProgress > 100 ? 100 : newProgress;
            });
          },
          reject,
          async () => {
            try {
              const downloadURL = await getDownloadURL(uploadPath);
              loadedImages += 1;
              resolve(downloadURL);
            } catch (error) {
              reject(error);
            }
          }
        );
      });
    });

    Promise.all(uploadPromises)
      .then(async (downloadURLs) => {
        const allImages = [...existingImages, ...downloadURLs];
        const productData = {
          title,
          price,
          content,
          createDate: new Date(),
          images: allImages,
          uid: currentUser.uid,
          // selectedArea: selectedArea,
        };

        const productRef = doc(db, 'products', productId);
        try {
          await updateDoc(productRef, productData);
          await MessageBoxInform(t('productUpdated'));
          navigate(`/detail/${productId}`);
        } catch (err) {
          console.error('Error updating product:', err);
        }
      })
      .catch((error) => {
        console.error('Error uploading images:', error);
      });
  };

  const ImagePreview = ({ preview, index }) => (
    <Box
      position="relative"
      display="inline-block"
      sx={{
        width: 100,
        height: 100,
        m: 1,
        overflow: 'hidden',
        borderRadius: '8px',
        border: '1px solid #ddd',
      }}
    >
      <img
        src={preview}
        alt="Preview"
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}
      />
      <IconButton
        size="small"
        sx={{
          position: 'absolute',
          top: 5,
          right: 5,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          color: 'white',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 7)',
          },
        }}
        onClick={() => handleRemoveImage(index)}
      >
        <DeleteIcon fontSize="small" />
      </IconButton>
    </Box>
  );

  const handleSaveArea = (savedArea) => {
    setSelectedArea(savedArea);
    console.log('Updated selectedArea:', savedArea);
  };

  const getCurrencyByLocale = (locale) => {
    switch (locale) {
      case 'ko':
        return 'KRW';
      case 'en':
        return 'USD';
      case 'ja':
        return 'JPY';
      case 'zh':
        return 'CNY';
      default:
        return 'USD';
    }
  };

  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 4 }}>
        <Box sx={{ mt: 4 }}>
          <TextField
            fullWidth
            label={t('title')}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder={t('title')}
            variant="outlined"
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label={t('content')}
            value={content}
            onChange={handleContentChange}
            placeholder={t('content')}
            multiline
            rows={5}
            variant="outlined"
            sx={{ mb: 2 }}
          />
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{ textAlign: 'right', mb: 2 }}
          >
            {getByteLength(content)}/200 {t('bytes')}
          </Typography>
          <TextField
            fullWidth
            label={t('price')}
            value={price}
            onChange={handlePriceChange}
            placeholder={t('pricePlaceholder')}
            variant="outlined"
            sx={{ mb: 2 }}
          />
          <Typography variant="body2" color="textSecondary">
            {price !== '0' ? formattedPrice : t('free')}
          </Typography>
        </Box>
        {MessageBoxRender()}
        <Box sx={{ mt: 4 }}>
          <Typography variant="h6" gutterBottom>
            {t('uploadImages')}
          </Typography>
          <input
            type="file"
            multiple
            onChange={handleImageChange}
            style={{ display: 'none' }}
            id="upload-button"
          />
          <label htmlFor="upload-button">
            <Button
              variant="contained"
              color="primary"
              component="span"
              fullWidth
              sx={{ mb: 2 }}
            >
              {t('selectImages')}
            </Button>
          </label>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {previews.map((preview, index) => (
              <ImagePreview key={index} preview={preview} index={index} />
            ))}
          </Box>
          <LinearProgress
            variant="determinate"
            value={uploadProgress}
            sx={{ mt: 2 }}
          />
          <Typography variant="body2" sx={{ mt: 1 }}>
            {Math.round(uploadProgress)}%
          </Typography>
        </Box>
        {/* <Box sx={{ mt: 4 }}>
          <Typography variant="h6" gutterBottom>
            {t('setRegion')}
          </Typography>
          {showMap && (
            <GoogleMapRegion
              initialRegion={product.selectedArea}
              onSave={handleSaveArea}
            />
          )}
        </Box> */}
        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleCancel}
            sx={{ width: '48%' }} // Set width to 48% for a balanced look
          >
            {t('cancel')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpload}
            sx={{ width: '48%' }} // Set width to 48% for a balanced look
          >
            {t('update')}
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default Edit;
