// components/PromotionList.js

import React, { useState, useEffect } from 'react';
import Promotion from './Promotion';
import { db, collection, getDocs, getDoc, doc, updateDoc, addDoc } from '../../firebaseConfig';

const PromotionList = () => {
  const [promotions, setPromotions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPromotions = async () => {
      try {
        const promotionsCollection = collection(db, 'promotions');
        const snapshot = await getDocs(promotionsCollection);
        const promotionsData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setPromotions(promotionsData);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPromotions();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="promotion-list">
      {promotions.length === 0 ? <p>No promotions available.</p> : promotions.map(promo => <Promotion key={promo.id} promotion={promo} />)}
    </div>
  );
}

export default PromotionList;
