import React, { useState } from 'react';
import {
  firebaseApp,
  db,
  auth,
  analytics,
  storage,
  functions,
  collection,
  query,
  where,
  orderBy,
  getDocs,
  doc,
  setDoc,
  getDoc,
  updateDoc,
  getAuth,
  onAuthStateChanged,
  updateProfile,
  sendEmailVerification,
  createUserWithEmailAndPassword,
} from './firebaseConfig';

import './css/join.css';
import { useNavigate } from 'react-router-dom';
import { Button, Container, TextField } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
// import logo from './assets/images/google-icon.png';

const Join = () => {
  const [displayName, setDisplayName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();
  const [isVerificationSent, setIsVerificationSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleError = (userFacingMessage, logMessage) => {
    setError(userFacingMessage);
    console.error(logMessage);
  };

  const handleJoin = async () => {
    setLoading(true);
    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      setLoading(false);
      return;
    }
    try {
      const { user } = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      await sendEmailVerification(user);
      setIsVerificationSent(true);
      toast.success('인증 이메일이 발송되었습니다. 인증 후 계속 진행됩니다.');
    } catch (error) {
      handleError('실패하였습니다. 다시 확인해 주세요.', error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleVerificationComplete = async () => {
    const user = auth.currentUser;

    try {
      await user.reload();

      if (user.emailVerified) {
        const 유저정보 = {
          displayName: displayName,
          email: email,
        };

        const userRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userRef);
        const updates = {
          ...유저정보,
          step: process.env.REACT_APP_EMAIL_VERIFIED,
        };

        if (userDoc.exists()) {
          await updateDoc(userRef, updates); // Update the existing document
        } else {
          await setDoc(userRef, updates, { merge: true }); // Create a new document with merge option
        }

        try {
          await updateProfile(user, { displayName: displayName });
          console.log('이름 저장 성공');
          toast.success('가입이 완료 되었습니다.');
          navigate('/login');
        } catch (profileError) {
          console.error('Error updating profile:', profileError.message);
        }
      } else {
        setError(
          '이메일 인증이 아직 완료되지 않았습니다. 이메일을 다시 확인해주세요.'
        );
      }
    } catch (error) {
      console.error(
        'Error verifying email or updating user document:',
        error.message
      );
    }
  };

  return (
    <div className="join">
      <div className="container">
        <div className="d-flex justify-content-between align-items-center mb-4">
          {/* <img src={logo} alt="Logo" style={{width: '100px'}} />  */}
          <h3>회원가입</h3>
        </div>
        <ToastContainer />
        <div>
          <div className="registration-card">
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="name-new"
              label="Name"
              placeholder="Enter your name"
              value={displayName || ''}
              onChange={(e) => setDisplayName(e.target.value)}
            />

            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="email-new"
              label="Email"
              placeholder="Enter your email"
              value={email || ''}
              onChange={(e) => setEmail(e.target.value)}
            />

            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="pw-new"
              label="Password"
              placeholder="Enter your password"
              type="password"
              value={password || ''}
              onChange={(e) => setPassword(e.target.value)}
            />

            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="confirm-new-pw"
              label="Confirm Password"
              placeholder="Confirm your password"
              type="password"
              value={confirmPassword || ''}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />

            {error && <div style={{ color: 'red' }}>{error}</div>}
            <br />
            <br />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleJoin}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : '가입하기'}
            </Button>

            {isVerificationSent && (
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                style={{ marginTop: '10px' }}
                onClick={handleVerificationComplete}
              >
                인증 완료
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Join;
