// import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import {
//     auth,
//     db,
//     sendEmailVerification,
//     reload,
//     onAuthStateChanged,
//     doc,
//     getDoc,
//     updateDoc,
//     setDoc,
//     addDoc,
//     collection,
//     getFirestore
// } from '../../firebaseConfig';
// import '../css/SignUpFlow.css';

// function EmailVerification({ onPrev, onNext, setIsStepCompleted }) {
//     const [message, setMessage] = useState('');
//     const [isSent, setIsSent] = useState(false);

//     const currentUser = useSelector((state) => state.auth.currentUser);

//     useEffect(() => {
//         if (!auth.currentUser) return;

//         if (auth.currentUser.emailVerified) {
//             handleEmailVerified();
//         }
//         else {
//             handleEmailNotVerified();
//         }
//     }, [currentUser]);

//     const handleEmailVerified = () => {
//         setIsSent(true);
//         setMessage('이메일주소가 인증되었습니다.');
//         recordStepAndMoveNext();
//     };

//     const handleEmailNotVerified = () => {
//         setMessage('이메일주소가 인증되지 않았습니다.');
//         //sendVerificationEmail();
//     };

//     const sendVerificationEmail = async () => {

//         if (auth.currentUser) {
//             try {
//                 await sendEmailVerification(auth.currentUser);
//                 setIsSent(true);
//                 setMessage('인증을 위한 이메일을 보냈습니다. 해당이메일을 확인하시고 이메일인증을 해주세요.');
//             } catch (error) {
//                 setMessage(`sendEmailVerification Error: ${error.message}`);
//                 setIsSent(false);
//             }
//         } else {
//             setMessage('User not logged in.');
//         }
//     };

//     const checkEmailVerified = async () => {
//         setMessage('');
//         if (auth.currentUser) {
//             await reload(auth.currentUser);
//             if (auth.currentUser.emailVerified) {
//                 setMessage('이메일주소가 인증되었습니다.');
//                 recordStepAndMoveNext();
//             } else {
//                 setMessage('이메일주소가 인증되지 않았습니다. 다시 확인해 주세요.');
//             }
//         } else {
//             setMessage('로그인되지 않았습니다.');
//         }
//     };

//     const recordStepAndMoveNext = async () => {
//         try {
//             const userRef = doc(db, 'users', currentUser.uid);

//             // Fetch the user document
//             const userDoc = await getDoc(userRef);

//             if (userDoc.exists()) {
//                 if (userDoc.data().pendingRegistrations) {
//                     // If the 'pendingRegistrations' map exists, just update the step
//                     await updateDoc(userRef, {
//                         "pendingRegistrations.step": process.env.REACT_APP_EMAIL_VERIFIED,
//                         "pendingRegistrations.modifiedAt": new Date()
//                     });
//                 } else {
//                     // If the 'pendingRegistrations' map doesn't exist, create it and set the step
//                     await setDoc(userRef, {
//                         pendingRegistrations: { step: process.env.REACT_APP_EMAIL_VERIFIED, modifiedAt: new Date() }
//                     }, { merge: true });
//                 }
//             } else {
//                 // Handle the case where the entire user document doesn't exist, though it's rare
//                 console.error("User document doesn't exist!");
//             }
//             setIsStepCompleted(true);
//             //onNext(); // Move to the next step
//         } catch (error) {
//             console.error("Error updating registration step:", error);
//         }
//     };

//     return (

//             <div>
//                 <h1>이메일 인증</h1>
//                 <p>{message}</p>
//                 {isSent ? (
//                     <button className="button" onClick={checkEmailVerified}>
//                         인증 완료 확인
//                     </button>
//                 ) : (
//                     <button className="button" onClick={sendVerificationEmail}>
//                         인증 코드 다시 전송
//                     </button>
//                 )}
//             </div>

//     );
// }

// export default EmailVerification;

// import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import {
//   auth,
//   sendEmailVerification,
//   reload,
//   doc,
//   getDoc,
//   updateDoc,
//   setDoc,
//   db,
// } from '../../firebaseConfig';
// import {
//   Button,
//   Typography,
//   Container,
//   Box,
//   CircularProgress,
//   Alert,
// } from '@mui/material';

// function EmailVerification({ onPrev, onNext, setIsStepCompleted }) {
//   const [message, setMessage] = useState('');
//   const [isSent, setIsSent] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const currentUser = useSelector((state) => state.auth.currentUser);

//   useEffect(() => {
//     if (!currentUser || !auth.currentUser) return;

//     if (auth.currentUser.emailVerified) {
//       handleEmailVerified();
//     } else {
//       handleEmailNotVerified();
//     }
//   }, [currentUser]);

//   const handleEmailVerified = () => {
//     setIsSent(true);
//     setMessage('Your email address has been verified.');
//     recordStepAndMoveNext();
//   };

//   const handleEmailNotVerified = () => {
//     setMessage('Your email address has not been verified.');
//   };

//   const sendVerificationEmail = async () => {
//     if (!auth.currentUser) {
//       setMessage('User not logged in.');
//       return;
//     }

//     setLoading(true);
//     try {
//       await sendEmailVerification(auth.currentUser);
//       setIsSent(true);
//       setMessage(
//         'A verification email has been sent. Please check your email and verify.'
//       );
//     } catch (error) {
//       setMessage(`Error sending verification email: ${error.message}`);
//       setIsSent(false);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const checkEmailVerified = async () => {
//     if (!auth.currentUser) {
//       setMessage('User not logged in.');
//       return;
//     }

//     setLoading(true);
//     try {
//       await reload(auth.currentUser);
//       if (auth.currentUser.emailVerified) {
//         setMessage('Your email address has been verified.');
//         recordStepAndMoveNext();
//       } else {
//         setMessage(
//           'Your email address has not been verified. Please check again.'
//         );
//       }
//     } catch (error) {
//       setMessage(`Error verifying email: ${error.message}`);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const recordStepAndMoveNext = async () => {
//     try {
//       const userRef = doc(db, 'users', currentUser.uid);
//       const userDoc = await getDoc(userRef);

//       if (userDoc.exists()) {
//         await updateDoc(userRef, {
//           'pendingRegistrations.step': process.env.REACT_APP_EMAIL_VERIFIED,
//           'pendingRegistrations.modifiedAt': new Date(),
//         });
//       } else {
//         console.error("User document doesn't exist!");
//       }

//       setIsStepCompleted(true);
//       onNext();
//     } catch (error) {
//       setMessage(`Error updating registration step: ${error.message}`);
//     }
//   };

//   return (
//     <Container component="main" maxWidth="xs">
//       <Box
//         sx={{
//           mt: 4,
//           display: 'flex',
//           flexDirection: 'column',
//           alignItems: 'center',
//         }}
//       >
//         <Typography variant="h5">Email Verification</Typography>
//         {message && (
//           <Alert severity={isSent ? 'success' : 'error'}>{message}</Alert>
//         )}
//         {loading && <CircularProgress />}
//         <Box sx={{ mt: 2 }}>
//           {isSent ? (
//             <Button
//               variant="contained"
//               onClick={checkEmailVerified}
//               disabled={loading}
//             >
//               Check Verification
//             </Button>
//           ) : (
//             <Button
//               variant="contained"
//               color="primary"
//               onClick={sendVerificationEmail}
//               disabled={loading}
//             >
//               Resend Verification Email
//             </Button>
//           )}
//         </Box>
//       </Box>
//     </Container>
//   );
// }

// export default EmailVerification;

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  auth,
  sendEmailVerification,
  reload,
  doc,
  getDoc,
  updateDoc,
  db,
} from '../firebaseConfig';
import {
  Button,
  Typography,
  Container,
  Box,
  CircularProgress,
  Alert,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

function EmailVerification({ onPrev, onNext, setIsStepCompleted }) {
  const { t, i18n } = useTranslation();
  const [message, setMessage] = useState('');
  const [isSent, setIsSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const currentUser = useSelector((state) => state.auth.currentUser);

  useEffect(() => {
    if (!currentUser || !auth.currentUser) return;

    if (auth.currentUser.emailVerified) {
      handleEmailVerified();
    } else {
      handleEmailNotVerified();
    }
  }, [currentUser]);

  const handleEmailVerified = () => {
    setIsSent(true);
    setMessage(t('emailVerifiedMessage'));
    recordStepAndMoveNext();
  };

  const handleEmailNotVerified = () => {
    setMessage(t('emailNotVerifiedMessage'));
  };

  const sendVerificationEmail = async () => {
    if (!auth.currentUser) {
      setMessage(t('userNotLoggedIn'));
      return;
    }

    setLoading(true);
    try {
      await sendEmailVerification(auth.currentUser);
      setIsSent(true);
      setMessage(t('verificationEmailSentMessage'));
    } catch (error) {
      setMessage(`${t('errorSendingVerificationEmail')}: ${error.message}`);
      setIsSent(false);
    } finally {
      setLoading(false);
    }
  };

  const checkEmailVerified = async () => {
    if (!auth.currentUser) {
      setMessage(t('userNotLoggedIn'));
      return;
    }

    setLoading(true);
    try {
      await reload(auth.currentUser);
      if (auth.currentUser.emailVerified) {
        setMessage(t('emailVerifiedMessage'));
        recordStepAndMoveNext();
      } else {
        setMessage(t('emailNotVerifiedCheckMessage'));
      }
    } catch (error) {
      setMessage(`${t('errorVerifyingEmail')}: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const recordStepAndMoveNext = async () => {
    try {
      const userRef = doc(db, 'users', currentUser.uid);
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
        await updateDoc(userRef, {
          step: process.env.REACT_APP_EMAIL_VERIFIED,
          modifiedAt: new Date(),
        });
      } else {
        console.error(t('userDocNotExist'));
      }

      if (setIsStepCompleted) {
        setIsStepCompleted(true);
      }
      if (onNext) {
        onNext();
      }
    } catch (error) {
      setMessage(`${t('errorUpdatingRegistrationStep')}: ${error.message}`);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          mt: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h5">{t('emailVerification')}</Typography>
        {message && (
          <Alert severity={isSent ? 'success' : 'error'}>{message}</Alert>
        )}
        {loading && <CircularProgress />}
        <Box sx={{ mt: 2 }}>
          {isSent ? (
            <Button
              variant="contained"
              onClick={checkEmailVerified}
              disabled={loading}
            >
              {t('checkVerification')}
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={sendVerificationEmail}
              disabled={loading}
            >
              {t('resendVerificationEmail')}
            </Button>
          )}
        </Box>
      </Box>
    </Container>
  );
}

export default EmailVerification;
