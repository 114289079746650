// import React, { useRef, useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import {
//   db,
//   auth,
//   storage,
//   collection,
//   onSnapshot,
//   updateDoc,
//   doc,
//   getDoc,
//   addDoc,
//   query,
//   orderBy,
// } from '../firebaseConfig';

// import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
// import '../css/chatview.css';
// import { useParams } from 'react-router-dom';
// import ChatHeader from '../ChatHeader';
// import ChatMessage from '../ChatMessage';
// import moment from 'moment';
// import Modal from 'react-modal';
// import ScheduleAppointment from '../ScheduleAppointment';
// import { RADIUS, CIRCUMFERENCE } from '../Common/Constants';
// import useMessageBox from '../Common/useMessageBox';
// import ModalStyles from '../Common/ModalStyles';
// import StoreChatHeader from './StoreChatHeader';
// import axios from 'axios';

// Modal.setAppElement('#root');

// function StoreChatView() {
//   const [MessageBoxInform, MessageBoxConfirm, MessageBoxRender] =
//     useMessageBox();
//   const currentUser = useSelector((state) => state.auth.currentUser);
//   const chatBoxRef = useRef();
//   const [store, setStore] = useState([]);
//   const [chatMessages, setChatMessages] = useState([]);
//   const [assistantMessages, setAssistantMessages] = useState([]);
//   const [inputValue, setInputValue] = useState('');
//   const [appointments, setAppointments] = useState([]);
//   // const { id: chatRoomId } = useParams();
//   const { storeId, chatRoomId } = useParams();
//   const [modalIsOpen, setModalIsOpen] = useState(false);

//   const [selectedFiles, setSelectedFiles] = useState([]);
//   const [fileUrls, setFileUrls] = useState([]);
//   const [uploadProgress, setUploadProgress] = useState([]);
//   const [uploadingFiles, setUploadingFiles] = useState([]);
//   const [completedCount, setCompletedCount] = useState(0);
//   const [totalFileSize, setTotalFileSize] = useState(uploadingFiles.length);
//   const [uploadedFileSize, setUploadedFileSize] = useState(0);

//   useEffect(() => {
//     fetchStoreData();
//   }, [storeId]);

//   const fetchStoreData = async () => {
//     try {
//       const storeRef = doc(db, 'stores', storeId);
//       const storeSnapshot = await getDoc(storeRef);

//       if (storeSnapshot.exists()) {
//         const rawData = storeSnapshot.data();
//         const formattedStore = {
//           id: storeSnapshot.id,
//           ...rawData,
//           formattedDate: moment(rawData.createDate?.toDate()).fromNow(),
//         };

//         setStore(formattedStore);
//       }
//     } catch (err) {
//       console.log(err);
//     }
//   };

//   useEffect(() => {
//     if (!storeId || !chatRoomId) {
//       console.error('Store ID or Chat Room ID is undefined');
//       return;
//     }

//     const messagesQuery = query(
//       collection(
//         doc(db, 'stores', storeId, 'chatroom', chatRoomId),
//         'messages'
//       ),
//       orderBy('date', 'asc')
//     );

//     const unsubscribe = onSnapshot(
//       messagesQuery,
//       (snapshot) => {
//         const messagesData = snapshot.docs.map((doc) => doc.data());
//         setChatMessages(messagesData);

//         const appointmentMessages = messagesData.filter(
//           (message) => message.type === 'appointments' && message.content !== ''
//         );
//         const allAppointments = appointmentMessages.map(
//           (message) => message.content
//         );
//         setAppointments(allAppointments);
//       },
//       (error) => {
//         console.error('Error fetching messages:', error);
//       }
//     );

//     return () => unsubscribe();
//   }, [storeId, chatRoomId]);

//   useEffect(() => {
//     const chatBox = chatBoxRef.current;
//     if (chatBox) {
//       chatBox.scrollTop = chatBox.scrollHeight;
//     }
//   }, [chatMessages, chatMessages.length]);

//   // isFAQ 함수
//   const isFAQ = (message) => {
//     const faqTriggers = ['영업 시간', '이벤트', '쿠폰', '할인', '배송 정보'];
//     return faqTriggers.some((trigger) => message.includes(trigger));
//   };

//   // getAutoResponseFromChatGPT 함수
//   const getAutoResponseFromChatGPT = async (userInput) => {
//     if (!userInput) return null;

//     try {
//       const body = {
//         myDateTime: new Date(),
//         chatMessages: [...chatMessages, userInput], // 이전 메시지들에 현재 사용자 입력을 추가합니다.
//         assistantMessages, // 이전 ChatGPT 응답들
//       };
//       // const response = await axios.post('https://05wqdp0x6j.execute-api.ap-northeast-2.amazonaws.com/prod/chat', body);
//       // return response.data.assistant; // ChatGPT로부터의 응답을 반환합니다.

//       const response = await fetch(
//         'https://05wqdp0x6j.execute-api.ap-northeast-2.amazonaws.com/prod/chat',
//         {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify(body),
//         }
//       );

//       if (!response.ok) {
//         throw new Error(
//           `Failed to get response from ChatGPT: ${response.status} ${response.statusText}`
//         );
//       }

//       const data = await response.json();

//       return data.assistant; // ChatGPT로부터의 응답을 반환합니다.
//     } catch (error) {
//       console.error(error);
//       return '죄송합니다. 현재 자동응답 서비스에 문제가 발생했습니다.';
//     }
//   };

//   const sendMessage = async (e) => {
//     e.preventDefault();

//     if (!chatRoomId || (!inputValue && !selectedFiles.length)) return;

//     const currentUserId = auth.currentUser.uid;
//     const photoURL = auth.currentUser.photoURL;
//     let fileUrls = [];

//     // If there are selected files, upload them and retrieve the URLs
//     if (selectedFiles.length > 0) {
//       fileUrls = await uploadFiles();
//       setFileUrls(fileUrls);
//     }

//     // Construct the base message object
//     const baseMessageData = {
//       type: selectedFiles.length > 0 ? 'text|image' : 'text',
//       content: inputValue,
//       date: new Date(),
//       uid: currentUserId,
//       photoURL,
//       fileUrls: fileUrls.length ? fileUrls : [], // Only include fileUrls if there are any
//     };

//     const messagesCollectionRef = collection(
//       doc(db, 'stores', storeId, 'chatroom', chatRoomId),
//       'messages'
//     );
//     await addDoc(messagesCollectionRef, baseMessageData);

//     if (isFAQ(inputValue)) {
//       const autoResponse = await getAutoResponseFromChatGPT(inputValue);
//       const responseMessageData = {
//         type: 'text',
//         content: autoResponse,
//         date: new Date(),
//         uid: 'ChatBot', // or any identifier for the chatbot
//         photoURL: 'chatbot_image_url', // Chatbot's image
//         fileUrls: fileUrls.length ? fileUrls : [],
//       };
//       setAssistantMessages([...assistantMessages, autoResponse]);
//       await addDoc(messagesCollectionRef, responseMessageData);
//     }

//     resetChatInput();
//   };

//   const resetChatInput = () => {
//     setInputValue('');
//     setSelectedFiles([]);

//     if (chatBoxRef.current) {
//       chatBoxRef.current.focus();
//       chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
//     }
//   };

//   const uploadSingleFile = async (file) => {
//     const fileRef = ref(
//       storage,
//       `${process.env.REACT_APP_CHAT_FILE_PATH}/${Date.now()}_${file.name}`
//     );
//     const uploadTask = uploadBytesResumable(fileRef, file);

//     uploadTask.on('state_changed', (snapshot) => {
//       const fileProgress =
//         (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

//       // 업로드 진행률 계산
//       setUploadingFiles((prev) => {
//         return prev.map((item) => {
//           if (item.file.name === file.name) {
//             return {
//               ...item,
//               progress: fileProgress,
//             };
//           }
//           return item;
//         });
//       });
//     });

//     await new Promise((resolve, reject) => {
//       uploadTask
//         .then(() => {
//           setCompletedCount((prev) => prev + 1);
//           setUploadedFileSize((prev) => prev + file.size); // 여기서 전체 업로드된 바이트를 누적합니다.
//           resolve();
//         })
//         .catch(reject);
//     });

//     return await getDownloadURL(fileRef);
//   };

//   const uploadFiles = async () => {
//     const urls = [];
//     setUploadProgress([]); // 업로드 시작 전에 진행 상황 초기화

//     const totalSize = Array.from(selectedFiles).reduce(
//       (acc, file) => acc + file.size,
//       0
//     );
//     setTotalFileSize(totalSize);

//     for (const selectedFile of selectedFiles) {
//       const uploadStatus = {
//         file: selectedFile,
//         progress: 0,
//       };

//       setUploadingFiles((prev) => [...prev, uploadStatus]);

//       const url = await uploadSingleFile(selectedFile);
//       urls.push(url);
//     }
//     await MessageBoxInform('파일전송이 완료되었습니다.');

//     setUploadingFiles([]);
//     setSelectedFiles([]); // 파일들이 모두 업로드되면 상태 초기화
//     setUploadProgress([]); // Reset progress for all files
//     setTotalFileSize(0);
//     setUploadedFileSize(0);
//     setFileUrls([]);
//     return urls;
//   };

//   const handleFilesUpload = async (files) => {
//     const totalSize = Array.from(files).reduce(
//       (acc, file) => acc + file.size,
//       0
//     );
//     setTotalFileSize(totalSize);

//     const newFileUrls = [];

//     for (let file of files) {
//       const uploadStatus = {
//         file,
//         progress: 0,
//       };

//       setUploadingFiles((prev) => [...prev, uploadStatus]);

//       const fileRef = ref(
//         storage,
//         `${process.env.REACT_APP_CHAT_FILE_PATH}/${file.name}`
//       );
//       const uploadTask = uploadBytesResumable(fileRef, file);

//       uploadTask.on('state_changed', (snapshot) => {
//         const progress =
//           (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

//         setUploadingFiles((prev) => {
//           setCompletedCount((prev) => prev + 1);
//           return prev.map((item) =>
//             item.file.name === file.name ? { ...item, progress } : item
//           );
//         });
//       });

//       await uploadTask;

//       const fileURL = await getDownloadURL(fileRef);
//       newFileUrls.push(fileURL);

//       setUploadingFiles((prev) =>
//         prev.filter((item) => item.file.name !== file.name)
//       );

//       if (newFileUrls.length === files.length) {
//         const messageData = {
//           content: '',
//           fileUrls: newFileUrls,
//           date: new Date(),
//           uid: auth.currentUser.uid,
//         };

//         const messagesCollectionRef = collection(
//           db,
//           'stores',
//           storeId,
//           'chatroom',
//           chatRoomId,
//           'messages'
//         );
//         await addDoc(messagesCollectionRef, messageData);

//         setFileUrls([]);
//       } else {
//         setFileUrls((prev) => [...prev, ...newFileUrls]);
//       }
//     }
//   };

//   const openModal = () => {
//     setModalIsOpen(true);
//   };

//   const closeModal = () => {
//     setModalIsOpen(false);
//   };

//   const handleAppointmentSet = async (appointments) => {
//     const formatDateAndTime = (date) => {
//       const year = date.getFullYear();
//       const month = ('0' + (date.getMonth() + 1)).slice(-2);
//       const day = ('0' + date.getDate()).slice(-2);
//       const hour = ('0' + date.getHours()).slice(-2);
//       const minute = ('0' + date.getMinutes()).slice(-2);

//       return `${year}-${month}-${day} ${hour}:${minute}`;
//     };

//     const selectedDateTime = new Date(
//       `${appointments.date}T${appointments.time}`
//     );
//     const formattedDateTime = formatDateAndTime(selectedDateTime);

//     const messageData = {
//       type: 'appointments',
//       content: formattedDateTime || '',
//       date: new Date(),
//       uid: currentUser.uid,
//       fileUrls: fileUrls,
//       photoURL: '',
//       appointments: appointments,
//     };

//     await addDoc(
//       collection(db, 'chatroom', chatRoomId, 'messages'),
//       messageData
//     );
//     resetChatInput();
//     //setAppointments([...appointments, formattedDateTime]);
//     setChatMessages([...chatMessages, messageData]);
//     closeModal();
//   };

//   const removeFileFromPreview = (indexToRemove) => {
//     const updatedFiles = selectedFiles.filter(
//       (_, index) => index !== indexToRemove
//     );
//     setSelectedFiles(updatedFiles);
//   };
//   const clearAllPreviews = () => {
//     setSelectedFiles([]);
//   };

//   return (
//     <div className="chatview">
//       <div className="container">
//         <Modal
//           isOpen={modalIsOpen}
//           onRequestClose={closeModal}
//           contentLabel="Schedule Appointment"
//           style={ModalStyles}
//         >
//           <ScheduleAppointment
//             onAppointmentSet={handleAppointmentSet}
//             onClose={closeModal}
//           />
//         </Modal>

//         <StoreChatHeader
//           isCurrentUserOwner={currentUser?.uid === store.uid}
//           store={store}
//         />

//         <div className="chat-box" ref={chatBoxRef}>
//           {chatMessages &&
//             chatMessages.map((msg, index) => (
//               <ChatMessage key={index} message={msg} />
//             ))}
//         </div>

//         {selectedFiles && selectedFiles.length > 0 && (
//           <div className="file-previews">
//             <button className="close-all-button" onClick={clearAllPreviews}>
//               X
//             </button>
//             {selectedFiles.map((file, index) => (
//               <div key={index} className="file-preview">
//                 {file.type.startsWith('image/') ? (
//                   <img
//                     className="file-preview"
//                     src={URL.createObjectURL(file)}
//                     alt="File Preview"
//                   />
//                 ) : (
//                   <div className="file-preview">📄</div> // 이 부분을 원하는 아이콘 또는 텍스트로 교체할 수 있습니다.
//                 )}
//                 <button
//                   className="file-preview"
//                   onClick={() => removeFileFromPreview(index)}
//                 >
//                   x
//                 </button>
//               </div>
//             ))}
//           </div>
//         )}
//         {MessageBoxRender()}
//         <form onSubmit={sendMessage}>
//           <button
//             type="button"
//             onClick={() => document.getElementById('fileInput').click()}
//           >
//             +
//           </button>
//           <input
//             id="fileInput"
//             type="file"
//             multiple
//             style={{ display: 'none' }}
//             onChange={(e) => setSelectedFiles([...e.target.files])}
//           />

//           <input
//             type="text"
//             value={inputValue}
//             onChange={(e) => {
//               if (
//                 e.target.value.length <= process.env.REACT_APP_SEND_TEXT_MAX_LEN
//               ) {
//                 setInputValue(e.target.value);
//               } else {
//                 MessageBoxInform(
//                   `메시지는 ${process.env.REACT_APP_SEND_TEXT_MAX_LEN} 자를 초과할 수 없습니다!`
//                 );
//               }
//             }}
//             placeholder="메시지 입력"
//           />
//           <button
//             type="submit"
//             disabled={!inputValue && selectedFiles.length === 0}
//           >
//             보내기
//           </button>
//         </form>

//         {uploadingFiles && uploadingFiles.length > 0 && (
//           <div className="progress-container">
//             {/* 전체 진행률 표시 */}
//             <div className="circular-progress">
//               <svg viewBox="0 0 100 100">
//                 <circle
//                   className="circle-bg"
//                   cx="50"
//                   cy="50"
//                   r={RADIUS}
//                 ></circle>
//                 <circle
//                   className="circle"
//                   cx="50"
//                   cy="50"
//                   r={RADIUS}
//                   strokeDasharray={`${
//                     (uploadedFileSize / totalFileSize) * CIRCUMFERENCE
//                   }, ${CIRCUMFERENCE}`}
//                   strokeDashoffset={`${
//                     CIRCUMFERENCE -
//                     (uploadedFileSize / totalFileSize) * CIRCUMFERENCE
//                   }`}
//                 ></circle>
//               </svg>
//               {/* <div className="progress-text">{`${uploadedFileSize}/${totalFileSize} bytes`}</div> */}
//               <div className="progress-text">{`${Math.round(
//                 (uploadedFileSize / totalFileSize) * 100
//               )}%`}</div>
//             </div>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// }

// export default StoreChatView;

import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  db,
  auth,
  storage,
  collection,
  onSnapshot,
  updateDoc,
  doc,
  getDoc,
  addDoc,
  query,
  orderBy,
} from '../firebaseConfig';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import {
  Box,
  Button,
  TextField,
  Modal,
  Typography,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
} from '@mui/material';
import requestPermission from '../requestPermission';
import AddIcon from '@mui/icons-material/Add';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import { useParams } from 'react-router-dom';
import StoreChatHeader from './StoreChatHeader';
import ChatMessage from '../ChatMessage';
import moment from 'moment';
import ScheduleAppointment from '../ScheduleAppointment';
import useMessageBox from '../Common/useMessageBox';
import { useNavigate } from 'react-router-dom';
import ModalStyles from '../Common/ModalStyles';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { filterMessage, filterImage } from '../Utils/ContentsFilterClient';
import { filterImageTF } from '../Utils/TensorFlowFilter';

function StoreChatView() {
  const { t } = useTranslation();
  const [MessageBoxInform, MessageBoxConfirm, MessageBoxRender] =
    useMessageBox();
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const chatBoxRef = useRef();
  const inputRef = useRef();
  const inputContainerRef = useRef();
  const [store, setStore] = useState([]);
  const { storeId, productId, chatRoomId } = useParams();
  // const { productId, chatRoomId } = useParams();
  const [product, setProduct] = useState([]);
  const [chatMessages, setChatMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [appointments, setAppointments] = useState([]);

  const [isOpenScheduleModal, setIsOpenScheduleModal] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadingFiles, setUploadingFiles] = useState([]);
  const [totalFileSize, setTotalFileSize] = useState(0);
  const [uploadedFileSize, setUploadedFileSize] = useState(0);
  const [inputHeight, setInputHeight] = useState(70);
  const [error, setError] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const tabHeight = 56; // Assuming 56px for the tab bar height on mobile

  useEffect(() => {
    fetchStoreData();
    requestPermission();
  }, [storeId]);

  const fetchProductData = async () => {
    try {
      const productRef = doc(db, 'products', productId);
      const productSnapshot = await getDoc(productRef);

      if (productSnapshot.exists()) {
        const rawData = productSnapshot.data();
        const formattedProduct = {
          id: productSnapshot.id,
          ...rawData,
          formattedDate: moment(rawData.createDate?.toDate()).fromNow(),
        };
        setProduct(formattedProduct);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    let unsubscribe = null;

    const fetchMessages = async () => {
      try {
        const messagesQuery = query(
          collection(
            doc(db, 'stores', storeId, 'chatroom', chatRoomId),
            'messages'
          ),
          orderBy('date', 'asc')
        );

        unsubscribe = onSnapshot(
          messagesQuery,
          (snapshot) => {
            const messagesData = snapshot.docs.map((doc) => doc.data());

            setChatMessages(messagesData);

            // Show browser notification for new messages
            if (messagesData.length > 0) {
              const latestMessage = messagesData[messagesData.length - 1];
              if (
                Notification.permission === 'granted' &&
                latestMessage.uid !== currentUser?.uid
              ) {
                sendNotification(
                  'New Message',
                  latestMessage.content,
                  latestMessage.photoURL || '/path/to/default/icon.png'
                );
              }
            }
          },
          (err) => {
            if (
              err.code === 'failed-precondition' ||
              err.code === 'unavailable'
            ) {
              console.error('Firestore index error:', err);
              setError(
                '필요한 인덱스가 없습니다. Firestore 콘솔에서 인덱스를 생성하세요.'
              );
            } else {
              console.error('Error fetching messages:', err);
              setError(`Error fetching messages: ${err.message}`);
            }
          }
        );
      } catch (err) {
        console.error('Error initializing messages query:', err);
        setError(`Error initializing messages query: ${err.message}`);
      }
    };

    fetchMessages();

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [chatRoomId, currentUser]);

  const fetchStoreData = async () => {
    try {
      const storeRef = doc(db, 'stores', storeId);
      const storeSnapshot = await getDoc(storeRef);

      if (storeSnapshot.exists()) {
        const rawData = storeSnapshot.data();
        const formattedStore = {
          id: storeSnapshot.id,
          ...rawData,
          formattedDate: moment(rawData.createDate?.toDate()).fromNow(),
        };

        setStore(formattedStore);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!storeId || !chatRoomId) {
      console.error('Store ID or Chat Room ID is undefined');
      return;
    }

    const messagesQuery = query(
      collection(
        doc(db, 'stores', storeId, 'chatroom', chatRoomId),
        'messages'
      ),
      orderBy('date', 'asc')
    );

    const unsubscribe = onSnapshot(
      messagesQuery,
      (snapshot) => {
        const messagesData = snapshot.docs.map((doc) => doc.data());
        setChatMessages(messagesData);

        const appointmentMessages = messagesData.filter(
          (message) => message.type === 'appointments' && message.content !== ''
        );
        const allAppointments = appointmentMessages.map(
          (message) => message.content
        );
        setAppointments(allAppointments);
      },
      (error) => {
        console.error('Error fetching messages:', error);
      }
    );

    return () => unsubscribe();
  }, [storeId, chatRoomId]);

  useEffect(() => {
    scrollToBottom();
  }, [chatMessages]);

  const scrollToBottom = () => {
    const chatBox = chatBoxRef.current;
    if (chatBox) {
      chatBox.scrollTop = chatBox.scrollHeight;
    }
  };

  const sendNotification = (title, body, icon) => {
    if (Notification.permission === 'granted') {
      new Notification(title, {
        body,
        icon,
      });
    }
  };

  const sendMessage = async (e) => {
    e.preventDefault();

    if (!chatRoomId || (!inputValue && !selectedFiles.length)) return;

    // 필터링 적용
    let isMessageOffensive = false;
    if (inputValue) {
      // 필터링 적용
      isMessageOffensive = await filterMessage(inputValue);
    }

    const areImagesOffensive = await Promise.all(
      selectedFiles.map((file) => filterImageTF(file))
    );

    if (
      isMessageOffensive ||
      areImagesOffensive.some((isOffensive) => isOffensive)
    ) {
      MessageBoxInform(t('offensiveContentDetected'));
      return;
    }

    const currentUserId = auth.currentUser.uid;
    const photoURL = auth.currentUser.photoURL;
    let fileUrls = [];

    if (selectedFiles.length > 0) {
      fileUrls = await uploadFiles();
    }

    const baseMessageData = {
      type: selectedFiles.length > 0 ? 'text|image' : 'text',
      content: inputValue,
      date: new Date(),
      uid: currentUserId,
      photoURL,
      fileUrls: fileUrls.length ? fileUrls : [],
    };

    const messagesCollectionRef = collection(
      doc(db, 'stores', storeId, 'chatroom', chatRoomId),
      'messages'
    );
    await addDoc(messagesCollectionRef, baseMessageData);
    resetChatInput();
    setTimeout(() => {
      scrollToBottom();
    }, 100); // Ensure the function is called after state updates
  };

  const resetChatInput = () => {
    setInputValue('');
    setSelectedFiles([]);
    setUploadingFiles([]);
    setUploadedFileSize(0);
    setTotalFileSize(0);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const uploadSingleFile = async (file) => {
    const fileRef = ref(
      storage,
      `${process.env.REACT_APP_CHAT_FILE_PATH}/${Date.now()}_${file.name}`
    );
    const uploadTask = uploadBytesResumable(fileRef, file);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const fileProgress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

          setUploadingFiles((prev) => {
            return prev.map((item) => {
              if (item.file.name === file.name) {
                return {
                  ...item,
                  progress: fileProgress,
                };
              }
              return item;
            });
          });
        },
        (error) => {
          reject(error);
        },
        async () => {
          const downloadURL = await getDownloadURL(fileRef);
          setUploadedFileSize((prev) => prev + file.size);
          resolve(downloadURL);
        }
      );
    });
  };

  const uploadFiles = async () => {
    const urls = [];
    setUploadingFiles(selectedFiles.map((file) => ({ file, progress: 0 })));
    const totalSize = selectedFiles.reduce((acc, file) => acc + file.size, 0);
    setTotalFileSize(totalSize);

    for (const selectedFile of selectedFiles) {
      const url = await uploadSingleFile(selectedFile);
      urls.push(url);
    }

    return urls;
  };

  const handleImageClick = async () => {
    try {
      navigate(`/store_home/${storeId}`);
    } catch (error) {}
  };

  const openScheduleModal = () => {
    setIsOpenScheduleModal(true);
  };

  const closeScheduleModal = () => {
    setIsOpenScheduleModal(false);
  };

  const handleAppointmentSet = async (appointments) => {
    const formatDateAndTime = (date) => {
      const year = date.getFullYear();
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const day = ('0' + date.getDate()).slice(-2);
      const hour = ('0' + date.getHours()).slice(-2);
      const minute = ('0' + date.getMinutes()).slice(-2);

      return `${year}-${month}-${day} ${hour}:${minute}`;
    };

    const selectedDateTime = new Date(
      `${appointments.date}T${appointments.time}`
    );
    const formattedDateTime = formatDateAndTime(selectedDateTime);

    const messageData = {
      type: 'appointments',
      content: formattedDateTime || '',
      date: new Date(),
      uid: currentUser.uid,
      appointments: appointments,
    };

    await addDoc(
      collection(db, 'stores', storeId, 'chatroom', chatRoomId, 'messages'),
      messageData
    );
    resetChatInput();
    setChatMessages((prev) => [...prev, messageData]);
    closeScheduleModal();
    setTimeout(() => {
      scrollToBottom();
    }, 100); // Ensure the function is called after state updates
  };

  const removeFileFromPreview = (indexToRemove) => {
    const updatedFiles = selectedFiles.filter(
      (_, index) => index !== indexToRemove
    );
    setSelectedFiles(updatedFiles);
  };

  const handlePhoneCallClick = async () => {};

  return (
    <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Modal
        open={isOpenScheduleModal}
        onClose={closeScheduleModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={
            {
              /* your ModalStyles here */
            }
          }
        >
          <ScheduleAppointment
            onAppointmentSet={handleAppointmentSet}
            onClose={closeScheduleModal}
          />
        </Box>
      </Modal>

      {MessageBoxRender()}

      <Box
        sx={{
          flexShrink: 0,
          position: 'sticky',
          top: 0,
          zIndex: 1000,
          backgroundColor: 'background.paper',
          borderBottom: '1px solid',
          borderColor: 'divider',
        }}
      >
        <StoreChatHeader
          isOwner={currentUser?.uid === store.uid}
          imageUrl={store?.mainImages ? store.mainImages[0] : ''}
          title={store.storeName}
          onPhoneCallClick={handlePhoneCallClick}
          appointments={
            appointments.length > 0
              ? appointments[appointments.length - 1]
              : null
          }
          onImageClick={handleImageClick}
        />
      </Box>

      <Box
        ref={chatBoxRef}
        sx={{
          flexGrow: 1,
          overflowY: 'auto',
          minHeight: 0,
          paddingBottom: isMobile
            ? `${inputHeight + tabHeight - 30}px`
            : `${inputHeight + 20}px`,
          paddingX: 2, // Add horizontal padding if needed
        }}
      >
        {chatMessages.map((msg, index) => (
          <ChatMessage key={index} message={msg} />
        ))}
      </Box>

      <Box
        component="form"
        ref={inputContainerRef}
        onSubmit={sendMessage}
        sx={{
          position: 'fixed', // Fixing the position
          bottom: isMobile ? `${tabHeight}px` : 0, // Considering tab bar height in mobile
          left: 0,
          right: 0,
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          padding: '10px',
          boxShadow: '0 -5px 10px rgba(0,0,0,0.1)',
          backgroundColor: 'background.paper',
          borderTop: '1px solid',
          borderColor: 'divider',
        }}
      >
        {selectedFiles.length > 0 && (
          <Box
            sx={{
              display: 'flex',
              overflowX: 'auto',
              gap: 1,
              padding: '10px 0',
              borderBottom: '1px solid',
              borderColor: 'divider',
              backgroundColor: 'rgba(0, 0, 0, 0.05)', // Semi-transparent background
              justifyContent: 'center', // Center align images
            }}
          >
            {selectedFiles.map((file, index) => (
              <Box
                key={index}
                sx={{
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                {file.type.startsWith('image/') ? (
                  <img
                    src={URL.createObjectURL(file)}
                    alt="Preview"
                    style={{
                      height: 100,
                      width: 'auto',
                      marginBottom: '4px',
                      borderRadius: '4px',
                    }}
                  />
                ) : (
                  <Typography>📄</Typography>
                )}
                <IconButton
                  size="small"
                  onClick={() => removeFileFromPreview(index)}
                  sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    backgroundColor: 'rgba(255,255,255,0.7)',
                  }}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Box>
            ))}
          </Box>
        )}

        <Box sx={{ display: 'flex', gap: 1 }}>
          <Button variant="contained" component="label">
            <AddIcon />
            <input
              type="file"
              multiple
              hidden
              onChange={(e) => setSelectedFiles([...e.target.files])}
            />
          </Button>

          <TextField
            fullWidth
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder={t('enterMessage')}
            inputProps={{
              maxLength: process.env.REACT_APP_SEND_TEXT_MAX_LEN,
            }}
            onKeyPress={async (e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                await sendMessage(e);
                e.preventDefault(); // Prevent newline
              }
            }}
            inputRef={inputRef}
            sx={{
              height: '100%',
              '.MuiInputBase-root': {
                height: '100%',
              },
              '.MuiInputBase-input': {
                height: '100%',
                padding: '18px',
                boxSizing: 'border-box',
              },
            }}
          />

          <Button
            variant="contained"
            type="submit"
            disabled={!inputValue && selectedFiles.length === 0}
            endIcon={<SendIcon />}
            onClick={async (e) => {
              await sendMessage(e);
              inputRef.current.focus();
            }}
          >
            {/* {t('send')} */}
          </Button>
        </Box>
      </Box>

      {uploadingFiles.length > 0 && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1300,
          }}
        >
          <Box
            sx={{
              width: '80%',
              maxWidth: '400px',
              backgroundColor: '#fff',
              padding: '20px',
              borderRadius: '8px',
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <Typography variant="h6">{t('uploadProgress')}</Typography>
            <List>
              {uploadingFiles.map(({ file, progress }, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={file.name}
                    secondary={`${Math.round(progress)}%`}
                  />
                  <LinearProgress
                    variant="determinate"
                    value={progress}
                    sx={{ width: '100px', marginLeft: '10px' }}
                  />
                </ListItem>
              ))}
            </List>
            <Button
              variant="contained"
              onClick={() => setUploadingFiles([])}
              disabled={uploadingFiles.some(({ progress }) => progress < 100)}
            >
              {t('close')}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default StoreChatView;
