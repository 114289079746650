import {
  db,
  storage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from '../firebaseConfig';

export const resizeImage = async (file, maxWidth, maxHeight) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = document.createElement('img');
      img.src = e.target.result;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const ratio = Math.min(maxWidth / img.width, maxHeight / img.height);
        const newWidth = img.width * ratio;
        const newHeight = img.height * ratio;
        canvas.width = newWidth;
        canvas.height = newHeight;
        ctx.drawImage(img, 0, 0, newWidth, newHeight);
        canvas.toBlob((blob) => {
          if (!blob) {
            reject(new Error('Canvas to Blob failed'));
            return;
          }
          resolve(
            new File([blob], file.name, {
              type: 'image/jpeg',
              lastModified: Date.now(),
            })
          );
        }, 'image/jpeg');
      };
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};

export const uploadImageFile = async (productId, file, filePath) => {
  const fileRef = ref(storage, filePath);
  const metadata = {
    customMetadata: {
      productId: productId,
    },
  };
  const uploadTask = uploadBytesResumable(fileRef, file, metadata);

  await new Promise((resolve, reject) => {
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(`Upload is ${progress}% done`);
      },
      (error) => {
        console.error('Upload failed:', error);
        reject(error);
      },
      () => {
        resolve(getDownloadURL(fileRef));
      }
    );
  });

  return await getDownloadURL(fileRef);
};
