import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import StoreIcon from '@mui/icons-material/Store';
import ChatIcon from '@mui/icons-material/Chat';
import SettingsIcon from '@mui/icons-material/Settings';

const MobileTabBar = ({ activeMenu }) => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(activeMenu);

  useEffect(() => {
    setActiveTab(activeMenu);
  }, [activeMenu]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    navigate(newValue);
  };

  return (
    <BottomNavigation
      value={activeTab}
      onChange={handleTabChange}
      showLabels
      sx={{
        width: '100%',
        position: 'fixed',
        bottom: 0,
        zIndex: 1000, // 우선순위를 높이는 zIndex 설정
        backgroundColor: 'white',
        borderTop: '1px solid #ddd', // 상단 경계선 추가
      }}
    >
      <BottomNavigationAction label="Home" value="/" icon={<HomeIcon />} />
      <BottomNavigationAction
        label="Stores"
        value="/storelist"
        icon={<StoreIcon />}
      />
      <BottomNavigationAction
        label="Chat"
        value="/chatlist"
        icon={<ChatIcon />}
      />
      <BottomNavigationAction
        label="Settings"
        value="/settings"
        icon={<SettingsIcon />}
      />
    </BottomNavigation>
  );
};

export default MobileTabBar;
