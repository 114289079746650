// import React, { useState, useEffect } from 'react';
// import {
//   db,
//   collection,
//   query,
//   where,
//   getDocs,
//   doc,
//   getDoc,
// } from '../firebaseConfig';
// import {
//   Box,
//   FormControl,
//   Select,
//   MenuItem,
//   Button,
//   Grid,
//   Link,
//   Breadcrumbs,
//   Typography,
// } from '@mui/material';
// import NavigateNextIcon from '@mui/icons-material/NavigateNext';

// const ProductCategorySelector = ({ categoryId, onSelectCategory }) => {
//   const [subCategories, setSubCategories] = useState([]);
//   const [searchCategoryPath, setSearchCategoryPath] = useState([]);
//   const [siblingCategories, setSiblingCategories] = useState({});

//   useEffect(() => {
//     if (!categoryId) {
//       fetchRootCategories();
//     }
//   }, []);
//   useEffect(() => {
//     if (categoryId) {
//       fetchCategoryPath(categoryId);
//       setSubCategories([]);
//     }
//   }, [categoryId]);
//   const fetchRootCategories = async () => {
//     const rootCategories = await fetchCategories('');
//     setSubCategories(rootCategories);
//     setSearchCategoryPath([]); // Clear any existing path since we're resetting to root
//     setSiblingCategories({ 0: rootCategories }); // Initialize siblings for root
//   };

//   const fetchCategoryPath = async (categoryId) => {
//     let path = [];
//     let currentId = categoryId;
//     while (currentId) {
//       const categoryDoc = await getDoc(doc(db, 'categories', currentId));
//       if (categoryDoc.exists()) {
//         path.unshift({
//           id: categoryDoc.id,
//           name: categoryDoc.data().name,
//           level: categoryDoc.data().level,
//           parentCategoryId: categoryDoc.data().parentCategoryId,
//         });
//         currentId = categoryDoc.data().parentCategoryId;
//       } else {
//         break;
//       }
//     }
//     setSearchCategoryPath(path);
//     onSelectCategory(path[path.length - 1]);
//     fetchSiblingsForPath(path);
//   };

//   const fetchCategories = async (parentId = '') => {
//     const q = query(
//       collection(db, 'categories'),
//       where('parentCategoryId', '==', parentId)
//     );
//     const querySnapshot = await getDocs(q);
//     return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
//   };

//   const handleChangeCategory = async (selectedCategory) => {
//     const subCategories = await fetchCategories(selectedCategory.id);
//     setSubCategories(subCategories);
//     let updatedPath = searchCategoryPath.slice(0, selectedCategory.level || 0);
//     updatedPath.push(selectedCategory);
//     setSearchCategoryPath(updatedPath);
//     if (selectedCategory.parentCategoryId) {
//       const siblings = await fetchCategories(selectedCategory.parentCategoryId);
//       setSiblingCategories((prev) => ({
//         ...prev,
//         [selectedCategory.level]: siblings,
//       }));
//     }
//     onSelectCategory(selectedCategory);
//   };

//   const fetchSiblingsForPath = async (path) => {
//     const siblingPromises = path.map(async (category) => ({
//       level: category.level,
//       siblings: await fetchCategories(category.parentCategoryId || ''),
//     }));
//     const siblingResults = await Promise.all(siblingPromises);
//     const newSiblings = siblingResults.reduce(
//       (acc, result) => ({
//         ...acc,
//         [result.level]: result.siblings,
//       }),
//       {}
//     );
//     setSiblingCategories(newSiblings);
//   };

//   return (
//     <Box sx={{ width: '100%', p: 2 }}>
//       <Breadcrumbs
//         separator={<NavigateNextIcon fontSize="small" />}
//         aria-label="breadcrumb"
//       >
//         <Link
//           color="inherit"
//           href="#"
//           onClick={(e) => {
//             e.preventDefault();
//             fetchRootCategories();
//           }}
//           sx={{ cursor: 'pointer' }}
//         >
//           카테고리별
//         </Link>

//         {searchCategoryPath.map((category, index) => (
//           <Link
//             key={category.id}
//             color="inherit"
//             href="#"
//             onClick={(e) => {
//               e.preventDefault();
//               handleChangeCategory(category);
//             }}
//             sx={{ cursor: 'pointer' }}
//           >
//             {category.name}
//           </Link>
//         ))}
//       </Breadcrumbs>
//       <Box sx={{ mt: 2, display: 'flex', flexDirection: 'row', gap: 1 }}>
//         {subCategories.map((subCategory) => (
//           <Button
//             key={subCategory.id}
//             variant="outlined"
//             color="primary"
//             onClick={() =>
//               handleChangeCategory({
//                 id: subCategory.id,
//                 name: subCategory.name,
//                 level: subCategory.level,
//                 parentCategoryId: subCategory.parentCategoryId,
//               })
//             }
//             sx={{
//               borderRadius: 16, // 라운드 모서리 반지름
//               border: '1px solid', // 테두리 두께 및 스타일
//               borderColor: 'primary.main', // 테두리 색상
//               textTransform: 'none', // 버튼 텍스트 대문자 변환 방지
//               padding: '5px 15px', // 내부 패딩
//               '&:hover': {
//                 // 호버 상태 스타일
//                 backgroundColor: 'primary.light', // 호버 시 배경 색상
//                 borderColor: 'primary.dark', // 호버 시 테두리 색상
//               },
//             }}
//           >
//             {subCategory.name}
//           </Button>
//           //   <Link
//           //     key={subCategory.id}
//           //     color="inherit"
//           //     href="#"
//           //     onClick={(e) => {
//           //       e.preventDefault();
//           //       handleChangeCategory(subCategory);
//           //     }}
//           //     sx={{ cursor: 'pointer' }}
//           //   >
//           //     {subCategory.name}
//           //   </Link>
//         ))}
//       </Box>
//     </Box>
//   );
// };

// export default ProductCategorySelector;

// import React, { useState, useEffect } from 'react';
// import {
//   db,
//   collection,
//   query,
//   where,
//   getDocs,
//   doc,
//   getDoc,
// } from '../firebaseConfig';
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';
// import { googleTranslate } from '../Utils/GoogleTranslation';
// import { Box, Button, Breadcrumbs, Link, Typography } from '@mui/material';
// import NavigateNextIcon from '@mui/icons-material/NavigateNext';

// const ProductCategorySelector = ({ categoryId, onSelectCategory, brandId }) => {
//   const { t, i18n } = useTranslation();
//   const navigate = useNavigate();
//   const [subCategories, setSubCategories] = useState([]);
//   const [searchCategoryPath, setSearchCategoryPath] = useState([]);
//   const [siblingCategories, setSiblingCategories] = useState({});

//   useEffect(() => {
//     if (!categoryId) {
//       fetchRootCategories();
//     }
//   }, []);

//   useEffect(() => {
//     if (categoryId) {
//       fetchCategoryPath(categoryId);
//       setSubCategories([]);
//     }
//   }, [categoryId]);

//   useEffect(() => {
//     if (brandId) {
//       fetchRootCategories();
//     }
//   }, [brandId]);

//   const fetchRootCategories = async () => {
//     const rootCategories = await fetchCategories('', brandId);
//     setSubCategories(rootCategories);
//     setSearchCategoryPath([]); // Clear any existing path since we're resetting to root
//     setSiblingCategories({ 0: rootCategories }); // Initialize siblings for root
//   };

//   const fetchCategoryPath = async (categoryId) => {
//     let path = [];
//     let currentId = categoryId;
//     while (currentId) {
//       const categoryDoc = await getDoc(doc(db, 'categories', currentId));
//       if (categoryDoc.exists()) {
//         const translatedName = await googleTranslate(categoryDoc.data().name);
//         path.unshift({
//           id: categoryDoc.id,
//           name: translatedName,
//           level: categoryDoc.data().level,
//           parentCategoryId: categoryDoc.data().parentCategoryId,
//         });
//         currentId = categoryDoc.data().parentCategoryId;
//       } else {
//         break;
//       }
//     }
//     setSearchCategoryPath(path);
//     onSelectCategory(path[path.length - 1]);
//     fetchSiblingsForPath(path);
//   };

//   const fetchCategories = async (parentId = '', brandId = '') => {
//     const q = brandId
//       ? query(
//           collection(db, 'categories'),
//           where('parentCategoryId', '==', parentId),
//           where('brandIds', 'array-contains', brandId)
//         )
//       : query(
//           collection(db, 'categories'),
//           where('parentCategoryId', '==', parentId)
//         );
//     // const querySnapshot = await getDocs(q);
//     // return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
//     const querySnapshot = await getDocs(q);
//     const categories = querySnapshot.docs.map((doc) => ({
//       id: doc.id,
//       ...doc.data(),
//     }));

//     // 각 카테고리의 이름을 번역합니다.
//     const translatedCategories = await Promise.all(
//       categories.map(async (category) => {
//         const translatedName = await googleTranslate(category.name);
//         return { ...category, name: translatedName };
//       })
//     );

//     return translatedCategories;
//   };

//   const handleChangeCategory = async (selectedCategory) => {
//     const subCategories = await fetchCategories(selectedCategory.id, brandId);
//     setSubCategories(subCategories);
//     let updatedSearchCategoryPath = searchCategoryPath.slice(
//       0,
//       selectedCategory.level || 0
//     );
//     updatedSearchCategoryPath.push(selectedCategory);
//     setSearchCategoryPath(updatedSearchCategoryPath);
//     if (selectedCategory.parentCategoryId) {
//       const siblings = await fetchCategories(
//         selectedCategory.parentCategoryId,
//         brandId
//       );
//       setSiblingCategories((prev) => ({
//         ...prev,
//         [selectedCategory.level]: siblings,
//       }));
//     }
//     onSelectCategory(selectedCategory);
//     // navigate(
//     //   `/search?category=${selectedCategory}&categoryPath=${encodeURIComponent(
//     //     updatedSearchCategoryPath
//     //   )}`
//     // );
//   };

//   const fetchSiblingsForPath = async (path) => {
//     const siblingPromises = path.map(async (category) => ({
//       level: category.level,
//       siblings: await fetchCategories(category.parentCategoryId || '', brandId),
//     }));
//     const siblingResults = await Promise.all(siblingPromises);
//     const newSiblings = siblingResults.reduce(
//       (acc, result) => ({
//         ...acc,
//         [result.level]: result.siblings,
//       }),
//       {}
//     );
//     setSiblingCategories(newSiblings);
//   };

//   return (
//     <Box sx={{ width: '100%', p: 2 }}>
//       <Breadcrumbs
//         separator={<NavigateNextIcon fontSize="small" />}
//         aria-label="breadcrumb"
//       >
//         <Link
//           color="inherit"
//           href="#"
//           onClick={(e) => {
//             e.preventDefault();
//             fetchRootCategories();
//             onSelectCategory('');
//           }}
//           sx={{ cursor: 'pointer' }}
//         >
//           {t('categories')}
//         </Link>

//         {searchCategoryPath.map((category, index) => (
//           <Link
//             key={category.id}
//             color="inherit"
//             href="#"
//             onClick={(e) => {
//               e.preventDefault();
//               handleChangeCategory(category);
//             }}
//             sx={{ cursor: 'pointer' }}
//           >
//             {category.name}
//           </Link>
//         ))}
//       </Breadcrumbs>
//       <Box sx={{ mt: 2, display: 'flex', flexDirection: 'row', gap: 1 }}>
//         {subCategories.map((subCategory) => (
//           <Button
//             key={subCategory.id}
//             variant="outlined"
//             color="primary"
//             onClick={() =>
//               handleChangeCategory({
//                 id: subCategory.id,
//                 name: subCategory.name,
//                 level: subCategory.level,
//                 parentCategoryId: subCategory.parentCategoryId,
//               })
//             }
//             sx={{
//               borderRadius: 16, // 라운드 모서리 반지름
//               border: '1px solid', // 테두리 두께 및 스타일
//               borderColor: 'primary.main', // 테두리 색상
//               textTransform: 'none', // 버튼 텍스트 대문자 변환 방지
//               padding: '5px 15px', // 내부 패딩
//               '&:hover': {
//                 // 호버 상태 스타일
//                 backgroundColor: 'primary.light', // 호버 시 배경 색상
//                 borderColor: 'primary.dark', // 호버 시 테두리 색상
//               },
//             }}
//           >
//             {subCategory.name}
//           </Button>
//         ))}
//       </Box>
//     </Box>
//   );
// };

// export default ProductCategorySelector;

import React, { useState, useEffect } from 'react';
import {
  db,
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
} from '../firebaseConfig';
import { useTranslation } from 'react-i18next';
import { googleTranslate } from '../Utils/GoogleTranslation';
import { Box, Button, Breadcrumbs, Link, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const ProductCategorySelector = ({
  categoryId,
  onSelectCategory,
  brandId = '',
}) => {
  const { t, i18n } = useTranslation();
  const [subCategories, setSubCategories] = useState([]);
  const [searchCategoryPath, setSearchCategoryPath] = useState([]);
  const [siblingCategories, setSiblingCategories] = useState({});

  useEffect(() => {
    if (!categoryId) {
      fetchRootCategories();
    }
  }, []);

  useEffect(() => {
    if (categoryId) {
      fetchCategoryPath(categoryId);
      setSubCategories([]);
    }
  }, [categoryId]);

  useEffect(() => {
    if (brandId) {
      fetchRootCategories();
    }
  }, [brandId]);

  const fetchRootCategories = async () => {
    const rootCategories = await fetchCategories('', brandId);
    setSubCategories(rootCategories);
    setSearchCategoryPath([]); // Clear any existing path since we're resetting to root
    setSiblingCategories({ 0: rootCategories }); // Initialize siblings for root
  };

  const fetchCategoryPath = async (categoryId) => {
    let path = [];
    let currentId = categoryId;
    while (currentId) {
      const categoryDoc = await getDoc(doc(db, 'categories', currentId));
      if (categoryDoc.exists()) {
        const translatedName = await googleTranslate(categoryDoc.data().name);
        path.unshift({
          id: categoryDoc.id,
          name: translatedName,
          level: categoryDoc.data().level,
          parentCategoryId: categoryDoc.data().parentCategoryId,
        });
        currentId = categoryDoc.data().parentCategoryId;
      } else {
        break;
      }
    }
    setSearchCategoryPath(path);
    onSelectCategory(path[path.length - 1]);
    fetchSiblingsForPath(path);
  };

  const fetchCategories = async (parentId = '', brandId = '') => {
    const q = brandId
      ? query(
          collection(db, 'categories'),
          where('parentCategoryId', '==', parentId),
          where('brandIds', 'array-contains', brandId)
        )
      : query(
          collection(db, 'categories'),
          where('parentCategoryId', '==', parentId)
        );
    const querySnapshot = await getDocs(q);
    const categories = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    const translatedCategories = await Promise.all(
      categories.map(async (category) => {
        const translatedName = await googleTranslate(category.name);
        return { ...category, name: translatedName };
      })
    );

    return translatedCategories;
  };

  const handleChangeCategory = async (selectedCategory) => {
    const subCategories = await fetchCategories(selectedCategory.id, brandId);
    setSubCategories(subCategories);
    let updatedSearchCategoryPath = searchCategoryPath.slice(
      0,
      selectedCategory.level || 0
    );
    updatedSearchCategoryPath.push(selectedCategory);
    setSearchCategoryPath(updatedSearchCategoryPath);
    if (selectedCategory.parentCategoryId) {
      const siblings = await fetchCategories(
        selectedCategory.parentCategoryId,
        brandId
      );
      setSiblingCategories((prev) => ({
        ...prev,
        [selectedCategory.level]: siblings,
      }));
    }
    onSelectCategory(selectedCategory);
  };

  const fetchSiblingsForPath = async (path) => {
    const siblingPromises = path.map(async (category) => ({
      level: category.level,
      siblings: await fetchCategories(category.parentCategoryId || '', brandId),
    }));
    const siblingResults = await Promise.all(siblingPromises);
    const newSiblings = siblingResults.reduce(
      (acc, result) => ({
        ...acc,
        [result.level]: result.siblings,
      }),
      {}
    );
    setSiblingCategories(newSiblings);
  };

  return (
    <Box sx={{ width: '100%', p: 1, marginLeft: 1, marginRight: 1 }}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        <Link
          color="inherit"
          href="#"
          onClick={(e) => {
            e.preventDefault();
            fetchRootCategories();
            onSelectCategory('');
          }}
          sx={{ cursor: 'pointer' }}
        >
          {t('settings.categories')}
        </Link>

        {searchCategoryPath.map((category, index) => (
          <Link
            key={category.id}
            color="inherit"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleChangeCategory(category);
            }}
            sx={{ cursor: 'pointer' }}
          >
            {category.name}
          </Link>
        ))}
      </Breadcrumbs>
      <Box
        sx={{
          mt: 1,
          display: 'flex',
          flexDirection: 'row',
          gap: 1,
          flexWrap: 'wrap',
        }}
      >
        {subCategories &&
          subCategories.map((subCategory) => (
            <Button
              key={subCategory.id}
              variant="outlined"
              color="primary"
              onClick={() =>
                handleChangeCategory({
                  id: subCategory.id,
                  name: subCategory.name,
                  level: subCategory.level,
                  parentCategoryId: subCategory.parentCategoryId,
                })
              }
              sx={{
                borderRadius: 16, // 라운드 모서리 반지름
                border: '1px solid', // 테두리 두께 및 스타일
                borderColor: 'primary.main', // 테두리 색상
                textTransform: 'none', // 버튼 텍스트 대문자 변환 방지
                padding: '5px 15px', // 내부 패딩
                margin: '5px', // 버튼 사이의 간격
                '&:hover': {
                  // 호버 상태 스타일
                  backgroundColor: 'primary.light', // 호버 시 배경 색상
                  borderColor: 'primary.dark', // 호버 시 테두리 색상
                },
              }}
            >
              {subCategory.name}
            </Button>
          ))}
      </Box>
    </Box>
  );
};

export default ProductCategorySelector;
