// import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import {
//   Card,
//   CardContent,
//   CardMedia,
//   Typography,
//   Button,
//   Grid,
//   Box,
//   useMediaQuery,
//   useTheme,
// } from '@mui/material';
// import { useTranslation } from 'react-i18next';
// import useCoupon from '../Coupon/UseCoupon';

// function CouponDisplay({ storeId }) {
//   const { t } = useTranslation();
//   const { currentUser } = useSelector((state) => state.auth);
//   const userId = currentUser?.uid;
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

//   const { downloadableCoupons, message, downloadCoupon } = useCoupon(
//     storeId,
//     null,
//     userId
//   );

//   const formatDate = (date) => {
//     return date ? new Date(date).toLocaleDateString() : '날짜 정보 없음';
//   };

//   return (
//     <Box sx={{ mt: 2 }}>
//       <Grid item xs={12}>
//         <Typography variant="h4" color="text.secondary">
//           {t('ongoingCoupons')}
//         </Typography>
//       </Grid>
//       {message && <Typography color="error">{message}</Typography>}
//       <Grid container spacing={2}>
//         {downloadableCoupons.length > 0 ? (
//           downloadableCoupons.map((coupon) => (
//             <Grid item key={coupon.id} xs={12} sm={6} md={4}>
//               <Card>
//                 <CardMedia
//                   component="img"
//                   height="140"
//                   image={coupon.imageUrl || 'default_coupon_image.jpg'} // Provide a default image if none is available
//                   alt={coupon.couponName}
//                 />
//                 <CardContent>
//                   <Typography variant="h5" component="h2">
//                     {coupon.couponName}
//                   </Typography>
//                   <Typography>
//                     {t('discountRate')}:{' '}
//                     {coupon.discountType === 'percentage'
//                       ? `${coupon.discount}%`
//                       : `${coupon.discount}${t('currency')}`}
//                   </Typography>
//                   <Typography>
//                     {t('applicableProducts')}:{' '}
//                     {coupon.applicableProducts
//                       .map((product) => product.productName)
//                       .join(', ')}
//                   </Typography>
//                   <Typography>
//                     {t('validPeriod')}: {formatDate(coupon.startDate)} ~{' '}
//                     {formatDate(coupon.endDate)}
//                   </Typography>
//                   <Button
//                     variant="outlined"
//                     color="primary"
//                     onClick={() => downloadCoupon(coupon.id)}
//                   >
//                     {t('download')}
//                   </Button>
//                 </CardContent>
//               </Card>
//             </Grid>
//           ))
//         ) : (
//           <Grid item xs={12}>
//             <Typography>{t('noOngoingCoupons')}</Typography>
//           </Grid>
//         )}
//       </Grid>
//     </Box>
//   );
// }

// export default CouponDisplay;

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Grid,
  Box,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import useCoupon from '../Coupon/UseCoupon';

function CouponDisplay({ storeId }) {
  const { t } = useTranslation();
  const { currentUser } = useSelector((state) => state.auth);
  const userId = currentUser?.uid;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { downloadableCoupons, downloadedCoupons, message, downloadCoupon } =
    useCoupon(storeId, null, userId);

  const formatDate = (date) => {
    return date ? new Date(date).toLocaleDateString() : '날짜 정보 없음';
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Grid item xs={12}>
        <Typography variant="h4" color="text.secondary">
          {t('ongoingCoupons')}
        </Typography>
      </Grid>
      {message && <Typography color="error">{message}</Typography>}
      <Grid container spacing={2}>
        {downloadableCoupons.length > 0 ? (
          downloadableCoupons.map((coupon) => (
            <Grid item key={coupon.id} xs={12} sm={6} md={4}>
              <Card>
                <CardMedia
                  component="img"
                  height="140"
                  image={coupon.imageUrl || 'default_coupon_image.jpg'} // Provide a default image if none is available
                  alt={coupon.couponName}
                />
                <CardContent>
                  <Typography variant="h5" component="h2">
                    {coupon.couponName}
                  </Typography>
                  <Typography>
                    {t('discountRate')}:{' '}
                    {coupon.discountType === 'percentage'
                      ? `${coupon.discount}%`
                      : `${coupon.discount}${t('currency')}`}
                  </Typography>
                  <Typography>
                    {t('applicableProducts')}:{' '}
                    {coupon.targetProducts === 'all'
                      ? t('allProducts')
                      : coupon.applicableProducts
                          .map((product) => product.productName)
                          .join(', ')}
                  </Typography>
                  <Typography>
                    {t('validPeriod')}: {formatDate(coupon.startDate)} ~{' '}
                    {formatDate(coupon.endDate)}
                  </Typography>
                  {coupon.targetProducts === 'all' ? (
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => downloadCoupon(coupon.id)}
                    >
                      {t('download')}
                    </Button>
                  ) : (
                    coupon.applicableProducts.map((product) => (
                      <Button
                        variant="outlined"
                        color="primary"
                        key={product.id}
                        onClick={() => downloadCoupon(coupon.id, product.id)}
                      >
                        {t('download')} - {product.productName}
                      </Button>
                    ))
                  )}
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography>{t('noOngoingCoupons')}</Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default CouponDisplay;
