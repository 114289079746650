// import React, { useState, useEffect } from 'react';
// import { db, collection, query, where, orderBy, getDocs, Timestamp, limit } from '../../firebaseConfig';  // Assuming you've updated the firebaseConfig file to v9

// const PromotionDisplay = ({ storeId }) => {
//   const [promotions, setPromotions] = useState([]);

//   useEffect(() => {
//     const fetchPromotions = async () => {
//       const now = Timestamp.now();
//       const promotionsQuery = query(
//         collection(db, 'stores', storeId, 'promotions'),
//         where("endDate", ">", now),
//         orderBy("endDate", "asc"),
//         limit(5)
//       );
//       const snapshot = await getDocs(promotionsQuery);
//       setPromotions(snapshot.docs.map(doc => ({ id: doc.id, ...doc.data(),
//         startDate: doc.data().startDate.toDate(),
//         endDate: doc.data().endDate.toDate() })));
//     };

//     fetchPromotions();
//   }, [storeId]);

//   return (
//     <div className="promotion-display">
//       {promotions.map(promotion => (
//         <div key={promotion.id} className="promotion-item">
//           <h1>{promotion.name}</h1>
//           <h2>{promotion.content}</h2>
//           <p>{promotion.startDate?.toLocaleString()}</p>
//           <p>{promotion.endDate?.toLocaleString()}</p>
//         </div>
//       ))}
//       {
//         promotions.length === 0 &&
//         <div className="promotion-item">
//           <p>진행중인 프로모션이 없습니다.</p> {/* Assuming there's a description field in the promo object */}
//           {/* If there are other details, add them here */}
//         </div>
//       }
//     </div>
//   );
// };

// export default PromotionDisplay;

import React, { useState, useEffect } from 'react';
import {
  db,
  collection,
  query,
  where,
  orderBy,
  getDocs,
  Timestamp,
  limit,
} from '../../firebaseConfig'; // Assuming you've updated the firebaseConfig file to v9
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const PromotionDisplay = ({ storeId }) => {
  const { t } = useTranslation();
  const [promotions, setPromotions] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // useEffect(() => {
  //   const fetchPromotions = async () => {
  //     const now = Timestamp.now();
  //     const promotionsQuery = query(
  //       collection(db, 'stores', storeId, 'promotions'),
  //       where('endDate', '>', now),
  //       orderBy('endDate', 'asc'),
  //       limit(5)
  //     );
  //     const snapshot = await getDocs(promotionsQuery);
  //     setPromotions(
  //       snapshot.docs.map((doc) => ({
  //         id: doc.id,
  //         ...doc.data(),
  //         startDate: doc.data().startDate.toDate(),
  //         endDate: doc.data().endDate.toDate(),
  //       }))
  //     );
  //   };

  //   fetchPromotions();
  // }, [storeId]);

  useEffect(() => {
    const fetchPromotions = async () => {
      try {
        const now = Timestamp.now();
        const promotionsQuery = query(
          collection(db, 'stores', storeId, 'promotions'),
          where('endDate', '>', now),
          orderBy('endDate', 'asc'),
          limit(5)
        );
        const snapshot = await getDocs(promotionsQuery);
        const promotionsData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          startDate: doc.data().startDate.toDate(),
          endDate: doc.data().endDate.toDate(),
        }));
        setPromotions(promotionsData);
      } catch (error) {
        console.error('Error fetching promotions:', error);
      }
    };

    if (storeId) {
      fetchPromotions();
    } else {
      console.error('Store ID is undefined');
    }
  }, [storeId]);

  return (
    <Box sx={{ mt: 2 }}>
      <Grid item xs={12}>
        <Typography variant="h4" color="text.secondary">
          {t('ongoingPromotions')}
        </Typography>
      </Grid>
      <Grid container spacing={2}>
        {promotions.map((promotion) => (
          <Grid item xs={12} sm={6} md={4} key={promotion.id}>
            <Card raised>
              <CardContent>
                <Typography variant="h5" component="div">
                  {promotion.name}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  {promotion.content}
                </Typography>
                <Typography variant="body2">
                  {t('startDate')}: {promotion.startDate?.toLocaleString()}
                </Typography>
                <Typography variant="body2">
                  {t('endDate')}: {promotion.endDate?.toLocaleString()}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
        {promotions.length === 0 && (
          <Grid item xs={12}>
            <Typography>{t('noOngoingPromotions')}</Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default PromotionDisplay;
