import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Typography,
  TextField,
  Button,
  Box,
  CircularProgress,
  Grid,
} from '@mui/material';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../../firebaseConfig';

function WMSApiKeyInput() {
  const [apiKey, setApiKey] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const currentUser = useSelector((state) => state.auth.currentUser);

  useEffect(() => {
    const fetchApiKey = async () => {
      if (currentUser) {
        const userDocRef = doc(db, 'users', currentUser.uid);
        try {
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            setApiKey(userDoc.data().wmsApiKey || '');
          }
        } catch (error) {
          console.error('Failed to fetch API Key:', error);
          setError('Failed to fetch API Key');
        }
      }
    };

    fetchApiKey();
  }, [currentUser]);

  const handleSaveApiKey = async () => {
    if (!apiKey) {
      setError('API Key is required');
      return;
    }
    try {
      setLoading(true);
      const userDoc = doc(db, 'users', currentUser.uid);
      await setDoc(userDoc, { wmsApiKey: apiKey }, { merge: true });
      setError('API Key saved successfully!');
    } catch (error) {
      console.error('Failed to save API Key:', error);
      setError('Failed to save API Key');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ flexGrow: 1, mt: 2, mx: 'auto', maxWidth: 500, p: 3 }}>
      <Grid container spacing={2} justifyContent="flex-start">
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom align="left">
            WMS API Key Management
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="API Key"
            value={apiKey}
            onChange={(e) => setApiKey(e.target.value)}
            margin="normal"
            error={!!error && error !== 'API Key saved successfully!'}
            helperText={error || 'Enter your API Key here'}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveApiKey}
            disabled={loading}
            fullWidth
          >
            {loading ? <CircularProgress size={24} /> : 'Save API Key'}
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            color: error === 'API Key saved successfully!' ? 'green' : 'red',
          }}
        >
          {error && <Typography variant="body2">{error}</Typography>}
        </Grid>
      </Grid>
    </Box>
  );
}

export default WMSApiKeyInput;
