// // StorePosition.jsx
// import { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import { doc, getDoc, updateDoc, setDoc, db } from '../../firebaseConfig';
// import Region from '../../Common/Region';
// import '../css/SignUpFlow.css'

// const StorePosition = ({ onPrev, onNext, setIsStepCompleted }) => {

//   const currentUser = useSelector((state) => state.auth.currentUser);
//   const [storePosition, setStorePosition] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const [isCompleted, setIsCompleted] = useState(false);

//   const updateUserDocument = async (updates) => {
//     setLoading(true);
//     try {
//       const userRef = doc(db, 'users', currentUser.uid);
//       const userDoc = await getDoc(userRef);

//       if (userDoc.exists()) {
//         await updateDoc(userRef, updates);
//       } else {
//         console.error("User document doesn't exist!");
//       }
//     } catch (error) {
//       console.error("Error updating user document:", error);
//       setError("An error occurred. Please try again.");
//     } finally {
//       setLoading(false);
//     }
//   };
//   const handleSetPosition = (storePosition) => {
//     setStorePosition(storePosition);
//   };
//   const handleSavePosition = async () => {
//     await updateUserDocument({
//       "pendingRegistrations.storePosition": storePosition,
//       "pendingRegistrations.modifiedAt": new Date()
//     });
//     recordStepAndMoveNext();
//   };

//   const recordStepAndMoveNext = async () => {
//     await updateUserDocument({ "pendingRegistrations.step": process.env.REACT_APP_STORE_POSITION_VERIFIED });
//     setIsCompleted(true);
//     setIsStepCompleted(true);
//     //onNext();
//   };

//   return (
//     <div >
//       <div className="form-section">
//         <label className="title-label" htmlFor="title">위치 지정</label>
//         <div className="region-container">
//           {isCompleted === false ? (
//             <label className="content-label" htmlFor="title">지도를 클릭해서 위치를 지정한 후 완료 버튼을 클릭해주세요.</label>
//           ) : (
//             <label className="content-label" htmlFor="title">설정되었습니다. 다음을 클릭하여 계속 진행해주세요.</label>
//           )}

//           <Region onSave={handleSetPosition} mode='marker' />
//         </div>
//         <div >
//           <button className="fixed-button" onClick={handleSavePosition}>완료</button>
//         </div>
//       </div>
//       {loading && <p>Loading...</p>}
//       {error && <p>{error}</p>}

//     </div>
//   );
// };

// export default StorePosition;

// import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import { doc, getDoc, updateDoc, db } from '../../firebaseConfig';
// import Region from '../../Common/Region';
// import {
//   Container,
//   Typography,
//   Button,
//   CircularProgress,
//   Alert,
//   Box,
// } from '@mui/material';

// const StorePosition = ({ onPrev, onNext, setIsStepCompleted }) => {
//   const currentUser = useSelector((state) => state.auth.currentUser);
//   const [storePosition, setStorePosition] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState('');
//   const [isCompleted, setIsCompleted] = useState(false);

//   const updateUserDocument = async (updates) => {
//     setLoading(true);
//     try {
//       const userRef = doc(db, 'users', currentUser.uid);
//       const userDoc = await getDoc(userRef);
//       if (userDoc.exists()) {
//         await updateDoc(userRef, updates);
//         setIsCompleted(true);
//         setIsStepCompleted(true);
//         onNext(); // Uncomment if you wish to automatically proceed to the next step
//       } else {
//         throw new Error("User document doesn't exist!");
//       }
//     } catch (error) {
//       console.error('Error updating user document:', error);
//       setError('An error occurred. Please try again.');
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleSetPosition = (position) => {
//     setStorePosition(position);
//   };

//   const handleSavePosition = async () => {
//     await updateUserDocument({
//       'pendingRegistrations.storePosition': storePosition,
//       'pendingRegistrations.modifiedAt': new Date(),
//     });
//   };

//   return (
//     <Container maxWidth="md">
//       <Typography variant="h5" sx={{ mt: 2, mb: 2 }}>
//         Set Store Position
//       </Typography>
//       <Box sx={{ mb: 2 }}>
//         <Region onSave={handleSetPosition} mode="marker" />
//       </Box>
//       <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
//         <Button
//           variant="contained"
//           color="primary"
//           onClick={handleSavePosition}
//           disabled={loading || isCompleted}
//         >
//           {isCompleted ? 'Position Saved' : 'Save Position'}
//         </Button>
//       </Box>
//       {loading && <CircularProgress size={24} sx={{ mt: 2 }} />}
//       {error && (
//         <Alert severity="error" sx={{ mt: 2 }}>
//           {error}
//         </Alert>
//       )}
//     </Container>
//   );
// };

// export default StorePosition;

// import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import { doc, getDoc, updateDoc, db } from '../../firebaseConfig';
// import GoogleMapRegion from '../../Common/GoogleMapRegion';
// import {
//   Container,
//   Typography,
//   Button,
//   CircularProgress,
//   Alert,
//   Box,
// } from '@mui/material';
// import { useTranslation } from 'react-i18next';
// import GoogleMapMarker from '../../Common/GoogleMapMarker';

// const StorePosition = ({ onPrev, onNext, setIsStepCompleted }) => {
//   const { t, i18n } = useTranslation();
//   const currentUser = useSelector((state) => state.auth.currentUser);
//   const [storePosition, setStorePosition] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState('');
//   const [isCompleted, setIsCompleted] = useState(false);

//   const updateUserDocument = async (updates) => {
//     if (!currentUser) throw new Error('not logged in');
//     setLoading(true); // Show loading indicator
//     try {
//       const userRef = doc(db, 'users', currentUser.uid); // Reference to the user's document
//       const userDoc = await getDoc(userRef); // Fetch the user's document

//       if (userDoc.exists()) {
//         // Check if the document exists
//         await updateDoc(userRef, updates); // Update the document with the provided updates

//         setIsCompleted(true); // Indicate completion
//         if (setIsStepCompleted) {
//           setIsStepCompleted(true); // Optional callback for step completion
//         }
//         if (onNext) {
//           onNext(); // Optional callback for moving to the next step
//         }
//       } else {
//         throw new Error(t('userDocNotExist')); // Throw an error if the document does not exist
//       }
//     } catch (error) {
//       console.error(t('errorUpdatingUserDocument'), error); // Log the error to the console
//       setError(t('errorOccurred')); // Set a user-friendly error message
//     } finally {
//       setLoading(false); // Hide loading indicator
//     }
//   };

//   const handleSetPosition = async (position) => {
//     setStorePosition(position);
//   };

//   const handleSavePosition = async () => {
//     try {
//       await updateUserDocument({
//         storePosition: storePosition, // Update store position
//         modifiedAt: new Date(), // Update modification timestamp
//       });
//     } catch (error) {
//       console.error('Error in handleSavePosition:', error); // Log the error specific to this function
//       setError(t('errorOccurredWhileSavingPosition')); // Set a specific error message for this action
//     }
//   };
//   return (
//     <Container maxWidth="md">
//       <Typography variant="h5" sx={{ mt: 2, mb: 2 }}>
//         {t('storePosition')}
//       </Typography>
//       <Box sx={{ mb: 2 }}>
//         <GoogleMapMarker onLocationSelect={handleSetPosition} />
//       </Box>
//       <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
//         <Button
//           variant="contained"
//           color="primary"
//           onClick={handleSavePosition}
//           disabled={loading || isCompleted}
//         >
//           {isCompleted ? t('positionSaved') : t('savePosition')}
//         </Button>
//       </Box>
//       {loading && <CircularProgress size={24} sx={{ mt: 2 }} />}
//       {error && (
//         <Alert severity="error" sx={{ mt: 2 }}>
//           {error}
//         </Alert>
//       )}
//     </Container>
//   );
// };

// export default StorePosition;

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { doc, getDoc, updateDoc, db } from '../firebaseConfig';
import GoogleMapRegion from '../Common/GoogleMapRegion';
import {
  Container,
  Typography,
  Button,
  CircularProgress,
  Alert,
  Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import GoogleMapMarker from '../Common/GoogleMapMarker';

const StorePosition = ({ onPrev, onNext, setIsStepCompleted }) => {
  const { t } = useTranslation();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const [storePosition, setStorePosition] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [isCompleted, setIsCompleted] = useState(false);

  useEffect(() => {
    if (currentUser) {
      fetchStorePosition();
    }
  }, [currentUser]);

  const fetchStorePosition = async () => {
    setLoading(true);
    try {
      const userRef = doc(db, 'users', currentUser.uid);
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        if (userData.storePosition) {
          setStorePosition(userData.storePosition);
        }
      } else {
        console.error(t('userDocNotExist'));
      }
    } catch (error) {
      console.error(t('errorFetchingUserDocument'), error);
      setError(t('errorOccurred'));
    } finally {
      setLoading(false);
    }
  };

  const updateUserDocument = async (updates) => {
    if (!currentUser) throw new Error('not logged in');
    setLoading(true); // Show loading indicator
    try {
      const userRef = doc(db, 'users', currentUser.uid); // Reference to the user's document
      const userDoc = await getDoc(userRef); // Fetch the user's document

      if (userDoc.exists()) {
        // Check if the document exists
        await updateDoc(userRef, updates); // Update the document with the provided updates

        setIsCompleted(true); // Indicate completion
        if (setIsStepCompleted) {
          setIsStepCompleted(true); // Optional callback for step completion
        }
        if (onNext) {
          onNext(); // Optional callback for moving to the next step
        }
      } else {
        throw new Error(t('userDocNotExist')); // Throw an error if the document does not exist
      }
    } catch (error) {
      console.error(t('errorUpdatingUserDocument'), error); // Log the error to the console
      setError(t('errorOccurred')); // Set a user-friendly error message
    } finally {
      setLoading(false); // Hide loading indicator
    }
  };

  const handleSetPosition = async (position) => {
    setStorePosition(position);
  };

  const handleSavePosition = async () => {
    try {
      await updateUserDocument({
        storePosition: storePosition, // Update store position
        step: process.env.REACT_APP_STORE_POSITION_VERIFIED,
        modifiedAt: new Date(), // Update modification timestamp
      });
    } catch (error) {
      console.error('Error in handleSavePosition:', error); // Log the error specific to this function
      setError(t('errorOccurredWhileSavingPosition')); // Set a specific error message for this action
    }
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h5" sx={{ mt: 2, mb: 2 }}>
        {t('storePosition')}
      </Typography>
      <Box sx={{ mb: 2 }}>
        <GoogleMapMarker
          onLocationSelect={handleSetPosition}
          initialPosition={storePosition}
        />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSavePosition}
          disabled={loading || isCompleted}
        >
          {isCompleted ? t('positionSaved') : t('savePosition')}
        </Button>
      </Box>
      {loading && <CircularProgress size={24} sx={{ mt: 2 }} />}
      {error && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}
    </Container>
  );
};

export default StorePosition;
