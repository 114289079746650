import React, { useEffect, useState, useRef, useCallback } from 'react';
import {
  db,
  collection,
  getDocs,
  addDoc,
  query,
  orderBy,
  limit,
} from '../../firebaseConfig';
import {
  Container,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  Box,
} from '@mui/material';
import useMessageBox from '../../Common/useMessageBox';
import { useTranslation } from 'react-i18next';

function RegisterTerms() {
  const { t } = useTranslation();
  const [termContents, setTermContents] = useState([]);
  const [versions, setVersions] = useState([]);
  const [nextVersion, setNextVersion] = useState(0);
  const [MessageBoxInform, MessageBoxConfirm, MessageBoxRender] =
    useMessageBox();

  const renderCount = useRef(0);

  const fetchVersionsAndGenerateNext = useCallback(async () => {
    try {
      const termsCollection = collection(db, 'terms');
      const termsSnapshot = await getDocs(termsCollection);
      const allVersions = termsSnapshot.docs.map((doc) => doc.data().version);
      const uniqueVersions = [...new Set(allVersions)]; // Remove duplicates

      if (uniqueVersions.length === 0) {
        setVersions([]);
        setNextVersion(1);
        setTermContents([
          {
            version: 1,
            items: [
              {
                title: '',
                content: '',
                type: 'optional',
              },
            ],
          },
        ]);
      } else {
        setVersions(uniqueVersions);
        const maxVersion = Math.max(...uniqueVersions);
        const nextVersionNumber = maxVersion + 1;
        setNextVersion(nextVersionNumber);
        setTermContents([
          {
            version: nextVersionNumber,
            items: [
              {
                title: '',
                content: '',
                type: 'optional',
              },
            ],
          },
        ]);
      }
    } catch (error) {
      console.error('Error fetching versions: ', error);
      setVersions([]);
      setNextVersion(1); // Set default next version to 1 in case of error
      setTermContents([
        {
          version: 1,
          items: [
            {
              title: '',
              content: '',
              type: 'optional',
            },
          ],
        },
      ]);
    }
  }, []);

  useEffect(() => {
    fetchVersionsAndGenerateNext();
  }, [fetchVersionsAndGenerateNext]);

  async function registerNewTerms(term) {
    if (
      term.version === undefined ||
      !Array.isArray(term.items) ||
      term.items.length === 0
    ) {
      throw new Error('Term object has missing or undefined fields');
    }

    for (let item of term.items) {
      if (!item.title || !item.content || !item.type) {
        throw new Error('Term item has missing or undefined fields');
      }
    }

    const newTermRef = await addDoc(collection(db, 'terms'), {
      version: term.version,
      items: term.items.map((item) => ({
        title: item.title,
        content: item.content,
        type: item.type,
      })),
      created_at: new Date(),
      updated_at: new Date(),
    });

    return newTermRef.id;
  }

  const handleAddItem = useCallback((versionIndex) => {
    setTermContents((prevTermContents) => {
      const newTermContents = [...prevTermContents];
      newTermContents[versionIndex].items.push({
        title: '',
        content: '',
        type: 'optional',
      });
      return newTermContents;
    });
  }, []);

  const handleRemoveItem = useCallback((versionIndex, itemIndex) => {
    setTermContents((prevTermContents) => {
      const newTermContents = [...prevTermContents];
      newTermContents[versionIndex].items.splice(itemIndex, 1);
      return newTermContents;
    });
  }, []);

  const handleTermChange = useCallback(
    (versionIndex, itemIndex, key, value) => {
      setTermContents((prevTermContents) => {
        const newTermContents = [...prevTermContents];
        if (
          newTermContents[versionIndex] &&
          newTermContents[versionIndex].items[itemIndex]
        ) {
          newTermContents[versionIndex].items[itemIndex][key] = value;
        }
        return newTermContents;
      });
    },
    []
  );

  const handleRegisterClick = async () => {
    try {
      const termIds = [];
      for (let termContent of termContents) {
        const termId = await registerNewTerms(termContent);
        termIds.push(termId);
      }
      console.log('새로운 약관들이 등록되었습니다. 약관 ID들:', termIds);
      await MessageBoxInform('새로운 약관이 배포되었습니다.');
      await fetchVersionsAndGenerateNext(); // 배포 후 데이터 초기화
    } catch (error) {
      console.error('약관 등록 중 오류 발생:', error);
    }
  };

  renderCount.current += 1;

  return (
    <Container>
      {MessageBoxRender()}
      <Typography variant="h4" gutterBottom>
        {t('registerNewTerms')}
      </Typography>
      <Typography variant="subtitle1">
        {t('newVersion')}: {nextVersion}
      </Typography>
      <Typography variant="subtitle1">
        {t('renderCount')}: {renderCount.current}
      </Typography>
      <Box sx={{ mb: 4 }}>
        {termContents.map((termContent, versionIndex) => (
          <Box key={versionIndex} sx={{ mb: 2 }}>
            {termContent.items.map((item, itemIndex) => (
              <Box
                key={itemIndex}
                sx={{ display: 'flex', flexDirection: 'column', mb: 1 }}
              >
                <TextField
                  label={t('termTitle')}
                  value={item.title || ''}
                  onChange={(e) =>
                    handleTermChange(
                      versionIndex,
                      itemIndex,
                      'title',
                      e.target.value
                    )
                  }
                  fullWidth
                  sx={{ mb: 1 }}
                />
                <TextField
                  label={t('termContent')}
                  value={item.content || ''}
                  onChange={(e) =>
                    handleTermChange(
                      versionIndex,
                      itemIndex,
                      'content',
                      e.target.value
                    )
                  }
                  multiline
                  rows={4}
                  fullWidth
                  sx={{ mb: 1 }}
                />
                <Select
                  label={t('termType')}
                  value={item.type || ''}
                  onChange={(e) =>
                    handleTermChange(
                      versionIndex,
                      itemIndex,
                      'type',
                      e.target.value
                    )
                  }
                  fullWidth
                >
                  <MenuItem value="optional">{t('optionalConsent')}</MenuItem>
                  <MenuItem value="required">{t('requiredConsent')}</MenuItem>
                </Select>
                <Button
                  onClick={() => handleRemoveItem(versionIndex, itemIndex)}
                  sx={{ mt: 1 }}
                >
                  {t('deleteItem')}
                </Button>
              </Box>
            ))}
            <Button
              variant="contained"
              onClick={() => handleAddItem(versionIndex)}
              sx={{ mt: 1, mr: 2 }}
            >
              {t('addNewItem')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleRegisterClick}
              sx={{ mt: 1 }}
            >
              {t('deploy')}
            </Button>
          </Box>
        ))}
      </Box>
    </Container>
  );
}

export default RegisterTerms;
