// import React, { useState } from 'react';
// import { db, storage } from '../firebaseConfig';
// import { collection, addDoc, serverTimestamp, doc, updateDoc } from 'firebase/firestore';
// import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
// import filledStarSrc from '../assets/images/filled_star.png';
// import emptyStarSrc from '../assets/images/empty_star.png';
// import './css/ProductReviewForm.css';

// const Star = ({ filled, onClick }) => {

//   return (
//     <img
//       src={filled ? filledStarSrc : emptyStarSrc}
//       onClick={onClick}
//       style={{ cursor: 'pointer', width: '25px', height: '25px' }} // Adjust size as needed
//       alt="rating star"
//     />
//     // <span onClick={onClick} style={{ cursor: 'pointer' }}>
//     //   {filled ? '⭐' : '☆'}
//     // </span>
//   );
// };

// const ProductReviewForm = ({ storeId, productId }) => {
//   const [reviewText, setReviewText] = useState('');
//   const [rating, setRating] = useState(0);
//   const [imageFiles, setImageFiles] = useState([]);
//   const [imageUrls, setImageUrls] = useState([]);

//   const handleImageUpload = (e) => {
//     const files = e.target.files;
//     const fileArray = Array.from(files);
//     setImageFiles(fileArray);

//     // 이미지 미리보기 URL 생성
//     const fileUrls = fileArray.map((file) => URL.createObjectURL(file));
//     setImageUrls(fileUrls);

//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (rating === 0 || !reviewText.trim()) {
//       alert('별점수와 리뷰를 남겨주세요.');
//       return;
//     }
//     // Firebase Firestore에 리뷰 데이터 저장
//     const reviewData = {
//       text: reviewText,
//       rating: rating,
//       timestamp: serverTimestamp(),
//     };

//     try {
//       const reviewsRef = collection(db, `stores/${storeId}/products/${productId}/reviews`);
//       const reviewDocRef = await addDoc(reviewsRef, reviewData);

//       if (imageFiles.length > 0) {
//         const imageUploadPromises = imageFiles.map((file) => {
//           const imageRef = ref(storage, `reviews/${reviewDocRef.id}/${file.name}`);
//           return uploadBytes(imageRef, file).then(() => getDownloadURL(imageRef));
//         });

//         const imageUrls = await Promise.all(imageUploadPromises);
//         // Update the review document with image URLs
//         await updateDoc(reviewDocRef, { imageUrls });
//       }

//       console.log('리뷰가 성공적으로 저장되었습니다.');
//       setReviewText('');
//       setRating(0);
//       setImageFiles([]);
//       setImageUrls([]);
//     } catch (error) {
//       console.error('리뷰를 저장하는 동안 오류가 발생했습니다:', error);
//     }
//   };
//   const handleStarClick = (ratingValue) => {
//     setRating(ratingValue);
//   };
//   return (
//     <div>
//       <h2>리뷰 작성</h2>
//       <form onSubmit={handleSubmit}>
//         <div>
//           <label>별점:</label>
//           <div>
//             {[1, 2, 3, 4, 5].map((star) => (
//               <Star key={star} filled={star <= rating} onClick={() => handleStarClick(star)} />
//             ))}
//           </div>

//           <input
//             type="number"
//             min="1"
//             max="5"
//             value={rating}
//             onChange={(e) => setRating(parseInt(e.target.value, 10))}
//           />
//         </div>
//         <div>
//           <label>리뷰 내용:</label>
//           <textarea
//             value={reviewText}
//             onChange={(e) => setReviewText(e.target.value)}
//           />
//         </div>
//         <div>
//           <label>이미지 업로드:</label>
//           <input type="file" accept="image/*" multiple onChange={handleImageUpload} />
//         </div>
//         <div className="image-previews">
//           {imageUrls.map((url, index) => (
//             <img className="image-preview" key={index} src={url} alt={`Preview ${index}`} />
//           ))}
//         </div>
//         <button type="submit">리뷰 작성</button>
//       </form>
//     </div>
//   );
// };

// export default ProductReviewForm;

import React, { useState } from 'react';
import { db, storage, auth } from '../firebaseConfig';
import {
  collection,
  addDoc,
  serverTimestamp,
  doc,
  updateDoc,
} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import filledStarSrc from '../assets/images/filled_star.png';
import emptyStarSrc from '../assets/images/empty_star.png';
import './css/ProductReviewForm.css';

const Star = ({ filled, onClick }) => {
  return (
    <img
      src={filled ? filledStarSrc : emptyStarSrc}
      onClick={onClick}
      style={{ cursor: 'pointer', width: '25px', height: '25px' }} // Adjust size as needed
      alt="rating star"
    />
    // <span onClick={onClick} style={{ cursor: 'pointer' }}>
    //   {filled ? '⭐' : '☆'}
    // </span>
  );
};

const ProductReviewForm = ({ storeId, productId, handleClose }) => {
  const [reviewText, setReviewText] = useState('');
  const [rating, setRating] = useState(0);
  const [imageFiles, setImageFiles] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);

  const handleImageUpload = (e) => {
    const files = e.target.files;
    const fileArray = Array.from(files);
    setImageFiles(fileArray);

    // 이미지 미리보기 URL 생성
    const fileUrls = fileArray.map((file) => URL.createObjectURL(file));
    setImageUrls(fileUrls);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (rating === 0 || !reviewText.trim()) {
      alert('별점수와 리뷰를 남겨주세요.');
      return;
    }
    // Firebase Firestore에 리뷰 데이터 저장
    const currentUserId = auth.currentUser.uid;
    const currentUserName = auth.currentUser?.displayName;
    const reviewData = {
      text: reviewText,
      rating: rating,
      timestamp: serverTimestamp(),
      reviewerId: currentUserId,
      reviewerName: currentUserName,
    };

    try {
      const reviewsRef = collection(
        db,
        `stores/${storeId}/products/${productId}/reviews`
      );
      const reviewDocRef = await addDoc(reviewsRef, reviewData);

      if (imageFiles.length > 0) {
        const imageUploadPromises = imageFiles.map((file) => {
          const imageRef = ref(
            storage,
            `reviews/${reviewDocRef.id}/${file.name}`
          );
          return uploadBytes(imageRef, file).then(() =>
            getDownloadURL(imageRef)
          );
        });

        const imageUrls = await Promise.all(imageUploadPromises);
        // Update the review document with image URLs
        await updateDoc(reviewDocRef, { imageUrls });
      }

      console.log('리뷰가 성공적으로 저장되었습니다.');
      setReviewText('');
      setRating(0);
      setImageFiles([]);
      setImageUrls([]);
      handleClose();
    } catch (error) {
      console.error('리뷰를 저장하는 동안 오류가 발생했습니다:', error);
    }
  };
  const handleStarClick = (ratingValue) => {
    setRating(ratingValue);
  };
  return (
    <div>
      <h2>리뷰 작성</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>별점:</label>
          <div>
            {[1, 2, 3, 4, 5].map((star) => (
              <Star
                key={star}
                filled={star <= rating}
                onClick={() => handleStarClick(star)}
              />
            ))}
          </div>

          <input
            type="number"
            min="1"
            max="5"
            value={rating}
            onChange={(e) => setRating(parseInt(e.target.value, 10))}
          />
        </div>
        <div>
          <label>리뷰 내용:</label>
          <textarea
            value={reviewText}
            onChange={(e) => setReviewText(e.target.value)}
          />
        </div>
        <div>
          <label>이미지 업로드:</label>
          <input
            type="file"
            accept="image/*"
            multiple
            onChange={handleImageUpload}
          />
        </div>
        <div className="image-previews">
          {imageUrls.map((url, index) => (
            <img
              className="image-preview"
              key={index}
              src={url}
              alt={`Preview ${index}`}
            />
          ))}
        </div>
        <button className="btn btn-primary" type="submit">
          리뷰 작성
        </button>
      </form>
    </div>
  );
};

export default ProductReviewForm;
