// components/Promotion.js

import React from 'react';
import './Promotion.css'; // 스타일

const Promotion = ({ promotion }) => {
  return (
    <div className="promotion-card">
      <h2>{promotion.name}</h2>
      <p>{promotion.description}</p>
      <p>{promotion.type === 'discount' ? 'Discount' : 'Coupon Code'}: {promotion.value}</p>
      <p>Start Date: {promotion.startDate.toLocaleDateString()}</p>
      <p>End Date: {promotion.endDate.toLocaleDateString()}</p>
    </div>
  );
}

export default Promotion;
