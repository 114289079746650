import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { db, collection, getDocs } from '../firebaseConfig';
import Store from './Store';
import {
  Container,
  Grid,
  Typography,
  CircularProgress,
  Box,
  TablePagination,
  useMediaQuery,
  Paper,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import useMessageBox from '../Common/useMessageBox';
import {
  exportFetchStoresByCoordinatesAsMap,
  exportFetchSelectedTownByUserId,
} from '../Utils/LocationStoresAndProducts';
import { exportFetchStoresPerPageAsMap } from '../Utils/LocationStoresAndProducts';
import { useTranslation } from 'react-i18next';
import Lottie from 'lottie-react';
import InfiniteScroll from 'react-infinite-scroll-component';
import noStoreResults from '../assets/lottie/no-storeproduct-results.json';
import CategoryBrandSelector from '../CategoryBrandSelector';
import StoreCategorySelector from '../StoreCategorySelector';

function StoreList() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [MessageBoxInform, MessageBoxConfirm, MessageBoxRender] =
    useMessageBox();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const currentUser = useSelector((state) => state.auth.currentUser);
  const [stores, setStores] = useState([]);
  const [filteredStores, setFilteredStores] = useState([]);
  const [hasMoreStores, setHasMoreStores] = useState(true);
  const [lastStore, setLastStore] = useState(null);
  const [storesTotalCount, setStoresTotalCount] = useState(0);
  const [storesPage, setStoresPage] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isPad = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const itemsPerPage = 10;

  useEffect(() => {
    if (currentUser) {
      loadStoresByCoordinates();
    } else {
      fetchStoresByPage();
    }
  }, [currentUser, storesPage]);

  useEffect(() => {
    if (selectedCategory) {
      setFilteredStores(
        stores.filter((store) => store.categoryId === selectedCategory.id)
      );
    } else {
      setFilteredStores(stores);
    }
  }, [selectedCategory, stores]);

  //로그아웃상태일때만
  //
  const fetchStoresByPage = async () => {
    try {
      const storesResponse = await exportFetchStoresPerPageAsMap(
        itemsPerPage,
        storesPage
      );
      setStores((prevStores) => [
        ...prevStores,
        ...storesResponse.stores.filter(
          (store) => !prevStores.some((s) => s.id === store.id)
        ),
      ]);
      setStoresTotalCount(storesResponse.totalCount);
      setHasMoreStores(
        storesResponse.stores.length === itemsPerPage &&
          storesResponse.stores.length !== 0
      );
      setLastStore(storesResponse.lastDoc);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  //로그인 되어 있을때만 호출
  const loadStoresByCoordinates = async () => {
    try {
      const location = await exportFetchSelectedTownByUserId(currentUser.uid);
      if (!location) {
        const isConfirmed = await MessageBoxConfirm(
          t('neighborhoodNotification'),
          t('neighborhoodNotDesignatedMessage')
        );
        if (!isConfirmed) {
          throw new Error('Failed to fetch location.');
        }
        navigate('/settings/mytown');
      }
      const { stores, totalCount, lastDoc } =
        await exportFetchStoresByCoordinatesAsMap(
          location.lat,
          location.lng,
          location.radius,
          currentUser.uid,
          lastStore,
          itemsPerPage
        );
      setStores((prevStores) => [
        ...prevStores,
        ...stores.filter((store) => !prevStores.some((s) => s.id === store.id)),
      ]);
      setLastStore(lastDoc);
      setStoresTotalCount(totalCount);
      setHasMoreStores(stores.length === itemsPerPage && stores.length !== 0);
    } catch (error) {
      setError((prevErrors) => [...prevErrors, error.message]);
    } finally {
      setLoading(false);
    }
  };

  //로그인 상관없이 두가지 경우 다 처리
  //로그인 없는 경우 fetchStoresByPage 호출
  const loadMoreStores = async () => {
    if (!hasMoreStores) return;

    try {
      if (currentUser) {
        const location = await exportFetchSelectedTownByUserId(currentUser.uid);
        if (!location) {
          const isConfirmed = await MessageBoxConfirm(
            t('neighborhoodNotification'),
            t('neighborhoodNotDesignatedMessage')
          );
          if (!isConfirmed) {
            throw new Error('Failed to fetch location.');
          }
          navigate('/settings/mytown');
        }

        const { stores, totalCount, lastDoc } =
          await exportFetchStoresByCoordinatesAsMap(
            location.lat,
            location.lng,
            location.radius,
            currentUser.uid,
            lastStore
          );

        setStores((prevStores) => [
          ...prevStores,
          ...stores.filter(
            (store) => !prevStores.some((s) => s.id === store.id)
          ),
        ]);
        setLastStore(lastDoc);
        setStoresTotalCount(totalCount);
        setHasMoreStores(stores.length === itemsPerPage && stores.length !== 0);
      } else {
        fetchStoresByPage();
      }
    } catch (error) {
      console.error('Error loading more stores:', error);
      setError((prevErrors) => [...prevErrors, error.message]);
    }
  };

  const handleSelectCategory = (category) => {
    setSelectedCategory(category);
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="80vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      p={0}
      sx={{
        width: '100%',
        maxWidth: '100%',
        margin: 'auto',
      }}
    >
      <Box
        sx={{
          border: '0px solid #d3d3d3', // 연한 회색 테두리
          borderRadius: '16px', // 둥근 테두리
          padding: '8px 16px', // 안쪽 여백
          marginBottom: '16px', // 아래쪽 여백
          backgroundColor: 'transparent', // 연한 회색 배경색
          transition: 'transform 0.2s', // 호버 시 애니메이션
          '&:hover': {
            transform: 'scale(1.02)', // 호버 시 크기 증가
          },
        }}
      >
        <Typography variant="h4" gutterBottom component="h2">
          {t('storeList')}
        </Typography>
      </Box>
      {MessageBoxRender()}
      <Grid item xs={12}>
        <StoreCategorySelector onSelectCategory={handleSelectCategory} />
        {filteredStores.length === 0 ? (
          <Paper elevation={3} sx={{ padding: 2, textAlign: 'center' }}>
            <Lottie animationData={noStoreResults} style={{ height: 300 }} />
            <Typography textAlign="center" sx={{ width: '100%' }}>
              {t('noStores')}
            </Typography>
          </Paper>
        ) : isMobile ? (
          <Box
            id="storesScrollBox"
            sx={{
              marginTop: '20px',
              height: 'calc(80vh - 70px)',
              overflow: 'auto',
            }}
          >
            <InfiniteScroll
              dataLength={filteredStores.length}
              next={loadMoreStores}
              hasMore={hasMoreStores}
              loader={<Typography>{t('loading')}</Typography>}
              endMessage={
                <Typography variant="body2">{t('noMoreStores')}</Typography>
              }
              scrollableTarget="storesScrollBox"
            >
              <Grid container spacing={0}>
                {filteredStores.map((store) => (
                  <Grid item xs={12} key={store.id}>
                    <Store store={store} horizontal={isMobile} />
                  </Grid>
                ))}
              </Grid>
            </InfiniteScroll>
          </Box>
        ) : (
          <>
            <Grid container spacing={2}>
              {filteredStores.map((store) => (
                <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={store.id}>
                  <Store store={store} horizontal={isMobile} />
                </Grid>
              ))}
            </Grid>
            <TablePagination
              component="div"
              count={storesTotalCount}
              page={storesPage}
              onPageChange={(e, newPage) => setStoresPage(newPage)}
              rowsPerPage={itemsPerPage}
              rowsPerPageOptions={[itemsPerPage]}
            />
          </>
        )}
      </Grid>
    </Box>
  );
}

export default StoreList;
