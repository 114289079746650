// UserRatingForm.js
// import React, { useState } from 'react';
// import {
//   Box,
//   Button,
//   Typography,
//   IconButton,
//   Tooltip,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogContentText,
//   DialogTitle,
//   TextField,
//   Rating,
// } from '@mui/material';
// import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
// import { db } from './firebaseConfig';
// import { collection, addDoc, serverTimestamp } from 'firebase/firestore';

// const weights = {
//   accuracy: 0.2,
//   responseTime: 0.2,
//   friendliness: 0.2,
//   punctuality: 0.2,
//   overallSatisfaction: 0.2,
// };

// const UserRatingForm = ({ ratedUserId, raterUserId }) => {
//   const [accuracy, setAccuracy] = useState(0);
//   const [responseTime, setResponseTime] = useState(0);
//   const [friendliness, setFriendliness] = useState(0);
//   const [punctuality, setPunctuality] = useState(0);
//   const [overallSatisfaction, setOverallSatisfaction] = useState(0);
//   const [comment, setComment] = useState('');
//   const [openDialog, setOpenDialog] = useState(null);

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const overallScore = (
//       accuracy * weights.accuracy +
//       responseTime * weights.responseTime +
//       friendliness * weights.friendliness +
//       punctuality * weights.punctuality +
//       overallSatisfaction * weights.overallSatisfaction
//     ).toFixed(2);

//     try {
//       await addDoc(collection(db, 'ratings'), {
//         ratedUserId,
//         raterUserId,
//         accuracy,
//         responseTime,
//         friendliness,
//         punctuality,
//         overallSatisfaction,
//         overallScore,
//         comment,
//         timestamp: serverTimestamp(),
//       });
//       setAccuracy(0);
//       setResponseTime(0);
//       setFriendliness(0);
//       setPunctuality(0);
//       setOverallSatisfaction(0);
//       setComment('');
//     } catch (error) {
//       console.error('Error submitting rating:', error);
//     }
//   };

//   const handleOpenDialog = (type) => {
//     setOpenDialog(type);
//   };

//   const handleCloseDialog = () => {
//     setOpenDialog(null);
//   };

//   return (
//     <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
//       {[
//         'accuracy',
//         'responseTime',
//         'friendliness',
//         'punctuality',
//         'overallSatisfaction',
//       ].map((type) => (
//         <Box key={type} sx={{ mb: 2 }}>
//           <Box sx={{ display: 'flex', alignItems: 'center' }}>
//             <Typography variant="body1" sx={{ mr: 1 }}>
//               {type.charAt(0).toUpperCase() + type.slice(1)}:
//             </Typography>
//             <Tooltip title="Click for more info">
//               <IconButton onClick={() => handleOpenDialog(type)} size="small">
//                 <InfoOutlinedIcon fontSize="small" />
//               </IconButton>
//             </Tooltip>
//           </Box>
//           <Rating
//             name={type}
//             value={eval(type)}
//             onChange={(e, newValue) =>
//               eval(`set${type.charAt(0).toUpperCase() + type.slice(1)}`)(
//                 newValue
//               )
//             }
//             precision={0.5}
//           />
//         </Box>
//       ))}
//       <TextField
//         label="Comment"
//         value={comment}
//         onChange={(e) => setComment(e.target.value)}
//         multiline
//         rows={4}
//         fullWidth
//         required
//       />
//       <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
//         Submit Rating
//       </Button>

//       <Dialog open={openDialog !== null} onClose={handleCloseDialog}>
//         <DialogTitle>
//           {openDialog &&
//             openDialog.charAt(0).toUpperCase() + openDialog.slice(1)}
//         </DialogTitle>
//         <DialogContent>
//           <DialogContentText>
//             {openDialog === 'accuracy' &&
//               '평가 대상의 상품 설명이 실제와 얼마나 일치하는지 평가합니다.'}
//             {openDialog === 'responseTime' &&
//               '거래 과정에서의 메시지 응답 속도를 평가합니다.'}
//             {openDialog === 'friendliness' &&
//               '거래 상대의 태도와 예의바름을 평가합니다.'}
//             {openDialog === 'punctuality' &&
//               '약속한 시간에 거래가 이루어졌는지 평가합니다.'}
//             {openDialog === 'overallSatisfaction' &&
//               '전체 거래 경험에 대한 전반적인 만족도를 평가합니다.'}
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleCloseDialog} color="primary">
//             Close
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </Box>
//   );
// };

// export default UserRatingForm;

// UserRatingForm.js
// import React, { useState } from 'react';
// import {
//   Box,
//   Button,
//   Typography,
//   IconButton,
//   Tooltip,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogContentText,
//   DialogTitle,
//   TextField,
//   Rating,
// } from '@mui/material';
// import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
// import { db } from './firebaseConfig';
// import {
//   collection,
//   doc,
//   setDoc,
//   getDoc,
//   updateDoc,
//   serverTimestamp,
// } from 'firebase/firestore';

// const weights = {
//   accuracy: 0.2,
//   responseTime: 0.2,
//   friendliness: 0.2,
//   punctuality: 0.2,
//   overallSatisfaction: 0.2,
// };

// const UserRatingForm = ({ open, handleClose, ratedUserId, raterUserId }) => {
//   const [accuracy, setAccuracy] = useState(0);
//   const [responseTime, setResponseTime] = useState(0);
//   const [friendliness, setFriendliness] = useState(0);
//   const [punctuality, setPunctuality] = useState(0);
//   const [overallSatisfaction, setOverallSatisfaction] = useState(0);
//   const [comment, setComment] = useState('');
//   const [openDialog, setOpenDialog] = useState(null);

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const overallScore =
//       accuracy * weights.accuracy +
//       responseTime * weights.responseTime +
//       friendliness * weights.friendliness +
//       punctuality * weights.punctuality +
//       overallSatisfaction * weights.overallSatisfaction;

//     const ratingRef = doc(db, `users/${ratedUserId}/ratings/${raterUserId}`);
//     const ratingSnapshot = await getDoc(ratingRef);

//     try {
//       if (ratingSnapshot.exists()) {
//         await updateDoc(ratingRef, {
//           accuracy,
//           responseTime,
//           friendliness,
//           punctuality,
//           overallSatisfaction,
//           overallScore,
//           comment,
//           timestamp: serverTimestamp(),
//         });
//       } else {
//         await setDoc(ratingRef, {
//           ratedUserId,
//           raterUserId,
//           accuracy,
//           responseTime,
//           friendliness,
//           punctuality,
//           overallSatisfaction,
//           overallScore,
//           comment,
//           timestamp: serverTimestamp(),
//         });
//       }
//       setAccuracy(0);
//       setResponseTime(0);
//       setFriendliness(0);
//       setPunctuality(0);
//       setOverallSatisfaction(0);
//       setComment('');
//     } catch (error) {
//       console.error('Error submitting rating:', error);
//     }
//   };

//   const handleOpenDialog = (type) => {
//     setOpenDialog(type);
//   };

//   const handleCloseDialog = () => {
//     setOpenDialog(null);
//   };

//   return (
//     <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
//       {[
//         'accuracy',
//         'responseTime',
//         'friendliness',
//         'punctuality',
//         'overallSatisfaction',
//       ].map((type) => (
//         <Box key={type} sx={{ mb: 2 }}>
//           <Box sx={{ display: 'flex', alignItems: 'center' }}>
//             <Typography variant="body1" sx={{ mr: 1 }}>
//               {type.charAt(0).toUpperCase() + type.slice(1)}:
//             </Typography>
//             <Tooltip title="Click for more info">
//               <IconButton onClick={() => handleOpenDialog(type)} size="small">
//                 <InfoOutlinedIcon fontSize="small" />
//               </IconButton>
//             </Tooltip>
//           </Box>
//           <Rating
//             name={type}
//             value={eval(type)}
//             onChange={(e, newValue) =>
//               eval(`set${type.charAt(0).toUpperCase() + type.slice(1)}`)(
//                 newValue
//               )
//             }
//             precision={0.5}
//           />
//         </Box>
//       ))}
//       <TextField
//         label="Comment"
//         value={comment}
//         onChange={(e) => setComment(e.target.value)}
//         multiline
//         rows={4}
//         fullWidth
//         required
//       />
//       <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
//         Submit Rating
//       </Button>

//       <Dialog open={openDialog !== null} onClose={handleCloseDialog}>
//         <DialogTitle>
//           {openDialog &&
//             openDialog.charAt(0).toUpperCase() + openDialog.slice(1)}
//         </DialogTitle>
//         <DialogContent>
//           <DialogContentText>
//             {openDialog === 'accuracy' &&
//               '평가 대상의 상품 설명이 실제와 얼마나 일치하는지 평가합니다.'}
//             {openDialog === 'responseTime' &&
//               '거래 과정에서의 메시지 응답 속도를 평가합니다.'}
//             {openDialog === 'friendliness' &&
//               '거래 상대의 태도와 예의바름을 평가합니다.'}
//             {openDialog === 'punctuality' &&
//               '약속한 시간에 거래가 이루어졌는지 평가합니다.'}
//             {openDialog === 'overallSatisfaction' &&
//               '전체 거래 경험에 대한 전반적인 만족도를 평가합니다.'}
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleCloseDialog} color="primary">
//             Close
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </Box>
//   );
// };

// export default UserRatingForm;

import React, { useState } from 'react';
import {
  Box,
  Button,
  Typography,
  IconButton,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Rating,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { db } from './firebaseConfig';
import {
  collection,
  doc,
  setDoc,
  getDoc,
  updateDoc,
  serverTimestamp,
} from 'firebase/firestore';
import { useTranslation } from 'react-i18next';

const weights = {
  accuracy: 0.2,
  responseTime: 0.2,
  friendliness: 0.2,
  punctuality: 0.2,
  overallSatisfaction: 0.2,
};

const UserRatingForm = ({ open, handleClose, ratedUserId, raterUserId }) => {
  const { t } = useTranslation();
  const [accuracy, setAccuracy] = useState(0);
  const [responseTime, setResponseTime] = useState(0);
  const [friendliness, setFriendliness] = useState(0);
  const [punctuality, setPunctuality] = useState(0);
  const [overallSatisfaction, setOverallSatisfaction] = useState(0);
  const [comment, setComment] = useState('');
  const [openDialog, setOpenDialog] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const overallScore =
      accuracy * weights.accuracy +
      responseTime * weights.responseTime +
      friendliness * weights.friendliness +
      punctuality * weights.punctuality +
      overallSatisfaction * weights.overallSatisfaction;

    const ratingRef = doc(db, `users/${ratedUserId}/ratings/${raterUserId}`);
    const ratingSnapshot = await getDoc(ratingRef);

    try {
      if (ratingSnapshot.exists()) {
        await updateDoc(ratingRef, {
          accuracy,
          responseTime,
          friendliness,
          punctuality,
          overallSatisfaction,
          overallScore,
          comment,
          timestamp: serverTimestamp(),
        });
      } else {
        await setDoc(ratingRef, {
          ratedUserId,
          raterUserId,
          accuracy,
          responseTime,
          friendliness,
          punctuality,
          overallSatisfaction,
          overallScore,
          comment,
          timestamp: serverTimestamp(),
        });
      }
      setAccuracy(0);
      setResponseTime(0);
      setFriendliness(0);
      setPunctuality(0);
      setOverallSatisfaction(0);
      setComment('');
    } catch (error) {
      console.error('Error submitting rating:', error);
    }
  };

  const handleOpenDialog = (type) => {
    setOpenDialog(type);
  };

  const handleCloseDialog = () => {
    setOpenDialog(null);
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
      {[
        'accuracy',
        'responseTime',
        'friendliness',
        'punctuality',
        'overallSatisfaction',
      ].map((type) => (
        <Box key={type} sx={{ mb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body1" sx={{ mr: 1 }}>
              {t(`userRatingForm.${type}`)}:
            </Typography>
            <Tooltip title={t('userRatingForm.clickForMoreInfo')}>
              <IconButton onClick={() => handleOpenDialog(type)} size="small">
                <InfoOutlinedIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
          <Rating
            name={type}
            value={eval(type)}
            onChange={(e, newValue) =>
              eval(`set${type.charAt(0).toUpperCase() + type.slice(1)}`)(
                newValue
              )
            }
            precision={0.5}
          />
        </Box>
      ))}
      <TextField
        label={t('userRatingForm.comment')}
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        multiline
        rows={4}
        fullWidth
        required
      />
      <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
        {t('userRatingForm.submitRating')}
      </Button>

      <Dialog open={openDialog !== null} onClose={handleCloseDialog}>
        <DialogTitle>
          {openDialog && t(`userRatingForm.${openDialog}`)}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {openDialog === 'accuracy' &&
              t('userRatingForm.accuracyDescription')}
            {openDialog === 'responseTime' &&
              t('userRatingForm.responseTimeDescription')}
            {openDialog === 'friendliness' &&
              t('userRatingForm.friendlinessDescription')}
            {openDialog === 'punctuality' &&
              t('userRatingForm.punctualityDescription')}
            {openDialog === 'overallSatisfaction' &&
              t('userRatingForm.overallSatisfactionDescription')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            {t('userRatingForm.close')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default UserRatingForm;
