import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const RecentSearchList = ({
  items,
  onDeleteItem,
  onClearAll,
  onSelectRecentSearch,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <div>
        <button
          onClick={(e) => {
            e.stopPropagation(); // 이벤트 전파를 막음
            onClearAll();
          }}
          style={{
            marginBottom: '10px',
            cursor: 'pointer',
            background: 'none',
            border: 'none',
            color: '#696969', // 회색 계열 색상
            fontSize: '0.8rem', // 폰트 크기 축소
          }}
        >
          {t('deleteAll')}
        </button>
        {items.map((item, index) => (
          <div
            key={index}
            onClick={(e) => {
              e.stopPropagation();
              onSelectRecentSearch(item);
            }}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '5px',
              cursor: 'pointer',
            }}
          >
            <span>{item}</span>
            <button
              onClick={(e) => {
                e.stopPropagation(); // 이벤트 전파를 막음
                onDeleteItem(index);
              }}
              style={{
                background: 'none',
                border: 'none',
                cursor: 'pointer',
                color: '#696969', // 회색 계열 색상
                fontSize: '0.8rem', // 폰트 크기 축소
              }}
            >
              &#10005; {/* X 문자 */}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};
export default RecentSearchList;
