import React, { useState, useRef, useEffect } from 'react';
import {
  Grid,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  Paper,
  InputBase,
  Button,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  db,
  collection,
  query,
  orderBy,
  limit,
  getDocs,
  where,
  auth,
} from '../firebaseConfig';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setSearchKeyword } from '../Redux/reducers/searchReducer';
import useOutsideClick from '../useOutsideClick';
import useUserActivity from '../useUserActivity';
import useStoreUserActivity from '../Stores/useStoreUserActivity';
import ProductCategories from '../Stores/ProductCategories'; // Import your ProductCategories component
import UserMenu from '../UserMenu'; // Import UserMenu component
import AutoCompleteSearch from '../Search/AutoCompleteSearch';
import SearchTabBar from './SearchTabBar';
import SearchListContainer from './SearchListContainer';
import { alpha, styled } from '@mui/material/styles';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: '22px', // 라운드 처리
  backgroundColor: 'transparent', // 내부 컬러 제거
  border: `1px solid ${alpha(theme.palette.common.black, 0.5)}`, // 테두리 스타일 추가
  '&:hover': {
    border: `1px solid ${alpha(theme.palette.common.black, 0.75)}`, // 호버 시 테두리 스타일
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: '40%',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 1),
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  flexGrow: 1,
  '& .MuiInputBase-input': {
    padding: theme.spacing(1),
    paddingLeft: `calc(1em + ${theme.spacing(2)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
  },
}));

const MobileNavBar = ({ setActiveMenu }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const searchRef = useRef();
  const popupRef = useRef();
  const blurTimeoutRef = useRef();
  const categoryRef = useRef();

  const { logSearchedKeyword } = useUserActivity(currentUser?.uid);
  const { logSearchedStoreKeyword } = useStoreUserActivity(currentUser?.uid);

  const [inputValue, setInputValue] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [showCategories, setShowCategories] = useState(false);
  const [recentSearches, setRecentSearches] = useState(() => {
    const savedSearches = localStorage.getItem('recentSearches');
    return savedSearches ? JSON.parse(savedSearches) : [];
  });
  const [realTimeSearches, setRealTimeSearches] = useState([]);
  const [activeTab, setActiveTab] = useState(1);

  useEffect(() => {
    const savedSearches = localStorage.getItem('recentSearches');
    if (savedSearches) {
      setRecentSearches(JSON.parse(savedSearches));
    }
    setInterval(updateTopSearchRanks, 300000);
    updateTopSearchRanks();
  }, []);

  function updateTopSearchRanks() {
    getTop20SearchRanks().then((topSearchRanks) => {
      const realTimeSearches = topSearchRanks.map((rank) => ({
        currentRank: rank.currentRank,
        keyword: rank.keyword,
        status: rank.status,
      }));
      setRealTimeSearches(realTimeSearches);
    });
  }

  // const getTop20SearchRanks = async () => {
  //   const searchRanksRef = collection(db, 'searchRanks');
  //   const q = query(searchRanksRef, orderBy('currentRank'), limit(20));
  //   const querySnapshot = await getDocs(q);

  //   return querySnapshot.docs.map((docSnapshot) => {
  //     const data = docSnapshot.data();
  //     let status;
  //     const rankChange = data.previousRank - data.currentRank;
  //     if (rankChange >= 3) {
  //       status = '↑↑';
  //     } else if (rankChange >= 1) {
  //       status = '↑';
  //     } else if (rankChange === 0) {
  //       status = '-';
  //     } else {
  //       status = '↓';
  //     }

  //     return {
  //       keyword: data.keyword,
  //       currentRank: data.currentRank,
  //       previousRank: data.previousRank,
  //       status: status,
  //     };
  //   });
  // };

  const getTop20SearchRanks = async () => {
    try {
      const searchRanksRef = collection(db, 'searchRanks');
      const q = query(searchRanksRef, orderBy('currentRank'), limit(20));
      const querySnapshot = await getDocs(q);

      return querySnapshot.docs.map((docSnapshot) => {
        const data = docSnapshot.data();
        let status;
        const rankChange = data.previousRank - data.currentRank;
        if (rankChange >= 3) {
          status = '↑↑';
        } else if (rankChange >= 1) {
          status = '↑';
        } else if (rankChange === 0) {
          status = '-';
        } else {
          status = '↓';
        }

        return {
          keyword: data.keyword,
          currentRank: data.currentRank,
          previousRank: data.previousRank,
          status: status,
        };
      });
    } catch (error) {
      if (
        error.code === 'failed-precondition' ||
        error.code === 'unavailable'
      ) {
        console.error(
          'Firestore index error: Please create the required index in the Firestore console.',
          error
        );
      } else {
        console.error('Error fetching top 20 search ranks:', error);
      }
      return [];
    }
  };

  const handleFocus = () => {
    setShowPopup(true);
    if (blurTimeoutRef.current) {
      clearTimeout(blurTimeoutRef.current);
    }
  };

  const handleBlur = () => {
    blurTimeoutRef.current = setTimeout(() => {
      // setShowPopup(false);
    }, 100);
  };
  // 팝업 외부 클릭 시 팝업 닫기
  useOutsideClick(popupRef, () => {
    setShowPopup(false);
  });

  // 검색바 내부 클릭 시 팝업 유지
  useOutsideClick(searchRef, () => {
    // setShowPopup(true);
  });

  const handleSearchKeyword = (searchTerm) => {
    dispatch(setSearchKeyword(searchTerm));
    navigate('/keyword_search_results');

    setRecentSearches((prevSearches) => {
      const updatedSearches = [
        searchTerm,
        ...prevSearches.filter((item) => item !== searchTerm),
      ];
      const slicedSearches = updatedSearches.slice(0, 20);
      localStorage.setItem('recentSearches', JSON.stringify(slicedSearches));
      return slicedSearches;
    });

    logSearchedKeyword(searchTerm);
    logSearchedStoreKeyword(currentUser?.uid, searchTerm);
    setShowPopup(false);
  };

  const handleSelectSearchKeyword = (searchTerm) => {
    // setInputValue(searchTerm);
    // handleSearchKeyword(searchTerm);
    // dispatch(setSearchKeyword(searchTerm));
    // blurTimeoutRef.current = setTimeout(() => {
    //   setShowPopup(false);
    // }, 100);
    setInputValue(searchTerm); // 선택한 검색어를 입력창에 표시
    handleSearchKeyword(searchTerm);
    dispatch(setSearchKeyword(searchTerm));
    setRecentSearches((prevSearches) => {
      const updatedSearches = [
        searchTerm,
        ...prevSearches.filter((item) => item !== searchTerm),
      ];
      localStorage.setItem(
        'recentSearches',
        JSON.stringify(updatedSearches.slice(0, 20))
      ); // 최근 검색어를 로컬 스토리지에 저장
      return updatedSearches.slice(0, 20);
    });
    blurTimeoutRef.current = setTimeout(() => {
      setShowPopup(false);
    }, 100);
  };

  // const handleDeleteItem = (index) => {
  //   // const newRecentSearches = recentSearches.filter((_, i) => i !== index);
  //   // setRecentSearches(newRecentSearches);
  //   setRecentSearches((prevSearches) => {
  //     const updatedSearches = prevSearches.filter((_, i) => i !== index);
  //     localStorage.setItem('recentSearches', JSON.stringify(updatedSearches));
  //     return updatedSearches;
  //   });
  // };

  // const handleClearAll = () => {
  //   setRecentSearches([]);
  // };

  useOutsideClick(searchRef, () => setShowPopup(false));
  useOutsideClick(popupRef, () => setShowPopup(false));
  useOutsideClick(categoryRef, () => setShowCategories(false));

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="fixed"
        sx={{ backgroundColor: 'white', color: 'black' }}
      >
        <Toolbar sx={{ backgroundColor: 'white' }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 0 }}
            onClick={() => setShowCategories(!showCategories)}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h5"
            component={Link}
            to="/"
            sx={{
              flexGrow: 1,
              textDecoration: 'none', // 언더바 제거
              color: 'inherit',
              margin: 'auto',
              fontSize: '1.2rem', // 폰트 크기 설정
            }}
            onClick={(event) => {
              navigate('/');
            }}
          >
            {t('appTitle')}
          </Typography>
          {isMobile && (
            <Box sx={{ flexGrow: 1 }}>
              <Search ref={searchRef}>
                <StyledInputBase
                  placeholder={t('search')}
                  inputProps={{ 'aria-label': 'search' }}
                  value={inputValue}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  onChange={(e) => setInputValue(e.target.value)}
                />
                <IconButton
                  type="button"
                  sx={{ p: '10px', mr: '10px' }}
                  aria-label="search"
                  onClick={() => handleSearchKeyword(inputValue)}
                >
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                </IconButton>
                {showPopup && (
                  <div
                    ref={popupRef}
                    style={{
                      position: 'absolute',
                      top: '100%',
                      left: 0,
                      width: '100%',
                      zIndex: 1,
                    }}
                  >
                    <SearchListContainer
                      recentSearches={recentSearches}
                      realTimeSearches={realTimeSearches}
                      setRecentSearches={setRecentSearches}
                      onSelectSearchKeyword={handleSelectSearchKeyword}
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                    />
                    <AutoCompleteSearch query={inputValue} />
                  </div>
                )}
              </Search>
            </Box>
          )}
          <UserMenu
            user={currentUser}
            handleUserProfile={() =>
              navigate(`/my_profile/${currentUser?.uid}`)
            }
            handleGoTerms={() => navigate('/terms_list')}
            handleGoPrivacy={() => navigate('/privacy_list')}
            handleLogout={async () => {
              await auth.signOut();
              navigate('/');
            }}
          />
        </Toolbar>
      </AppBar>
      <Toolbar /> {/* Add this to push the content down */}
      {showCategories && (
        <div
          ref={categoryRef}
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            height: '100vh',
            width: '300px',
            backgroundColor: 'white',
            overflowY: 'auto',
            boxShadow: '2px 0 5px rgba(0, 0, 0, 0.1)',
            zIndex: 1000,
          }}
        >
          <ProductCategories
            showMode="multiLine"
            onSelectedCategoryPath={() => setShowCategories(false)}
            onSelectCategory={() => setShowCategories(false)}
            onClose={() => setShowCategories(false)}
          />
        </div>
      )}
      <Grid container spacing={2} sx={{ paddingTop: 8 }}>
        {/* Your main content here */}
      </Grid>
    </Box>
  );
};

export default MobileNavBar;
