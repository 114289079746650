import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { db, storage, doc, getDoc } from '../firebaseConfig';


const ProductReviewDetail = () => {
  const { storeId, productId, reviewId } = useParams();
  const [review, setReview] = useState(null);

  useEffect(() => {
    // Firebase Firestore에서 리뷰 데이터를 가져오는 함수
    const fetchReviewDetail = async () => {
      const reviewDocRef = doc(db, 'stores', storeId, 'products', productId, 'reviews', reviewId);
    
      try {
        const docSnap = await getDoc(reviewDocRef);
        if (docSnap.exists()) {
          setReview({ id: docSnap.id, ...docSnap.data() });
        } else {
          console.log('리뷰를 찾을 수 없습니다.');
        }
      } catch (error) {
        console.error('리뷰 데이터를 가져오는 중 오류가 발생했습니다:', error);
      }
    };

    fetchReviewDetail();
  }, [reviewId]);

  if (!review) {
    return <p>리뷰를 불러오는 중입니다...</p>;
  }

  return (
    <div>
      <h2>리뷰 상세 정보</h2>
      <p>별점: {review.rating}</p>
      <p>리뷰 내용: {review.text}</p>
      {/* 이미지를 표시하는 부분 */}
      <div className="image-list">
        {review.imageUrls && review.imageUrls.map((imageUrl, index) => (
          <img key={index} src={imageUrl} alt={`Image ${index}`} />
        ))}
      </div>
      <Link to={`/product_detail/${storeId}/${productId}`}>리뷰 목록으로 돌아가기</Link>
    </div>
  );
};

export default ProductReviewDetail;
