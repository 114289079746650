import React, { useState } from 'react';
import { db, auth } from '../firebaseConfig';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import './css/ProductReviewCommentForm.css';

const ProductReviewCommentForm = ({
  storeId,
  productId,
  reviewId,
  parentId = null,
  onCommentAdded,
}) => {
  const [commentText, setCommentText] = useState('');
  const [feedback, setFeedback] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!commentText.trim()) {
      setFeedback('댓글을 입력해주세요.'); // 비어 있는 내용은 제출하지 않음
      return;
    }

    const currentUserId = auth.currentUser?.uid;
    const currentUserName = auth.currentUser?.displayName || '익명의 사용자';
    try {
      const docRef = await addDoc(
        collection(
          db,
          'stores',
          storeId,
          'products',
          productId,
          'reviews',
          reviewId,
          'comments'
        ),
        {
          text: commentText,
          timestamp: serverTimestamp(), // Firebase 서버 시간 사용
          parentId, // 최상위 댓글인 경우 null, 대댓글인 경우 해당 parentId
          commentId: currentUserId,
          commentName: currentUserName,
        }
      );
      setCommentText(''); // 입력 필드 초기화
      setFeedback('댓글이 성공적으로 등록되었습니다.'); // 성공 메시지 설정

      onCommentAdded({
        id: docRef.id, // 새로 추가된 댓글의 ID
        text: commentText,
        parentId: parentId,
        commentId: currentUserId,
        commentName: currentUserName,
        // 기타 필요한 댓글 정보
      });
    } catch (error) {
      console.error('Error adding comment:', error);
      setFeedback('댓글 등록에 실패했습니다. 다시 시도해주세요.'); // 오류 메시지 설정
    }
  };

  return (
    <div className="form-container">
      <form onSubmit={handleSubmit}>
        <textarea
          className="textarea"
          value={commentText}
          onChange={(e) => setCommentText(e.target.value)}
          placeholder="댓글을 작성하세요..."
        />
        <button type="submit" className="btn btn-primary">
          댓글 작성
        </button>
      </form>
      {feedback && <div>{feedback}</div>} {/* 사용자 피드백 표시 */}
    </div>
  );
};

export default ProductReviewCommentForm;
