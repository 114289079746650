// import React, { useState, useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { Link, Route, Routes } from 'react-router-dom';
// import {
//   db,
//   addDoc,
//   setDoc,
//   updateDoc,
//   doc,
//   getDoc,
//   arrayUnion,
//   increment,
//   runTransaction,
// } from '../../firebaseConfig';
// import { wmsPlaceOrder, wmsReturnOrder, wmsCancelOrder } from './wmsClient';

// function useOrder(storeId) {
//   const currentUser = useSelector((state) => state.auth.currentUser);

//   function generateRandomOrderId() {
//     return Date.now().toString(36) + Math.random().toString(36).slice(2);
//   }

//   // 주문 함수
//   async function placeOrder(orderData) {
//     const userId = currentUser.uid;
//     const {
//       items,
//       storeId,
//       usedPoints,
//       totalAmount,
//       finalAmount,
//       shippingDetails,
//     } = orderData;
//     const storeUserRef = doc(db, 'stores', storeId, 'users', userId);

//     try {
//       await runTransaction(db, async (transaction) => {
//         // 1. All Reads First
//         const storeUserDoc = await transaction.get(storeUserRef);
//         const productDocs = await Promise.all(
//           items.map((item) =>
//             transaction.get(
//               doc(db, 'stores', storeId, 'products', item.productId)
//             )
//           )
//         );
//         const couponDocs = items
//           .filter((item) => item.selectedCoupon && item.selectedCoupon.couponId)
//           .map((item) =>
//             transaction.get(
//               doc(
//                 db,
//                 'stores',
//                 storeId,
//                 'coupons',
//                 item.selectedCoupon.couponId
//               )
//             )
//           );

//         // Create a map for easy access of coupon data
//         const couponDataMap = {};
//         for (let i = 0; i < items.length; i++) {
//           if (items[i].selectedCoupon && items[i].selectedCoupon.id) {
//             couponDataMap[items[i].selectedCoupon.id] = couponDocs[i].data();
//           }
//         }

//         // 2. All Writes
//         for (const item of items) {
//           const productRef = doc(
//             db,
//             'stores',
//             storeId,
//             'products',
//             item.productId
//           );
//           const productDocData = productDocs
//             .find((pDoc) => pDoc.id === item.productId)
//             ?.data();
//           const orderStats = productDocData?.orderStats;

//           if (orderStats) {
//             transaction.update(productRef, {
//               'orderStats.totalOrders': increment(1),
//               'orderStats.lastOrderedDate': new Date(),
//             });
//           } else {
//             transaction.set(
//               productRef,
//               {
//                 orderStats: {
//                   totalOrders: 1,
//                   lastOrderedDate: new Date(),
//                 },
//               },
//               { merge: true }
//             );
//           }

//           if (item.selectedCoupon && item.selectedCoupon.couponId) {
//             transaction.update(storeUserRef, {
//               [`downloadedCoupons.${item.selectedCoupon.couponId}.${item.productId}.used`]: true,
//             });

//             const couponData = couponDataMap[item.selectedCoupon.couponId];

//             if (
//               couponData &&
//               couponData.applicableProducts.includes(item.productId)
//             ) {
//               const couponDocRef = doc(
//                 db,
//                 'stores',
//                 storeId,
//                 'coupons',
//                 item.selectedCoupon.couponId
//               );
//               transaction.update(couponDocRef, {
//                 [`applicableProducts.${item.productId}.usersWhoUsedCoupons`]:
//                   arrayUnion(userId),
//               });
//             }
//           }
//         }

//         if (
//           !shippingDetails ||
//           !shippingDetails.address ||
//           !shippingDetails.extraAddress ||
//           !shippingDetails.phoneNumber
//         ) {
//           throw new Error('Incomplete shipping details provided.');
//         }

//         const orderRef = doc(
//           db,
//           'stores',
//           storeId,
//           'orders',
//           generateRandomOrderId()
//         );
//         transaction.set(orderRef, orderData, { merge: true });

//         let userPoints = storeUserDoc.data()?.points || 0;
//         userPoints += finalAmount * 0.01;
//         transaction.set(storeUserRef, { points: userPoints }, { merge: true });
//       });
//       wmsPlaceOrder(orderData);
//       console.log('Order placed successfully!');
//     } catch (error) {
//       console.error('Error placing order:', error);
//     }
//   }

//   //주문 취소
//   async function cancelOrder(orderData) {
//     const userId = currentUser.uid;
//     const { productId, storeId, orderId, usedPoints, selectedCoupon } =
//       orderData;

//     const userRef = doc(db, 'stores', storeId, 'users', userId);
//     const userOrderRef = doc(
//       db,
//       'stores',
//       storeId,
//       'users',
//       userId,
//       'orders',
//       orderId
//     );
//     const productRef = doc(db, 'stores', storeId, 'products', productId);
//     const storeOrderRef = doc(db, 'stores', storeId, 'orders', orderId);

//     try {
//       await runTransaction(db, async (transaction) => {
//         // Fetch the current data
//         const userData = await getDoc(userRef);
//         const productData = await getDoc(productRef);

//         // Check if the user data exists before trying to access its properties
//         if (userData.exists()) {
//           const currentPoints = userData.data().points || 0;
//           transaction.update(userRef, { points: currentPoints + usedPoints });
//         }

//         // Check if the product data exists and has orderStats before trying to access its properties
//         if (productData.exists() && productData.data().orderStats) {
//           const currentTotalOrders =
//             productData.data().orderStats.totalOrders || 0;
//           transaction.update(productRef, {
//             'orderStats.totalOrders': Math.max(0, currentTotalOrders - 1), // Ensure never goes below 0
//           });
//         }

//         // Delete order references
//         transaction.delete(userOrderRef);
//         transaction.delete(storeOrderRef);
//       });
//       wmsCancelOrder(orderData);
//       console.log('Order canceled successfully!');
//     } catch (error) {
//       console.error('Error canceling order:', error);
//       // Consider throwing the error or handling it further as per your app's requirements
//     }
//   }

//   async function returnOrder(orderData) {
//     const userId = currentUser.uid;
//     const { productId, storeId, orderId } = orderData;

//     const userOrderRef = doc(
//       db,
//       'stores',
//       storeId,
//       'users',
//       userId,
//       'orders',
//       orderId
//     );
//     const productOrderStatsRef = doc(
//       db,
//       'stores',
//       storeId,
//       'products',
//       productId,
//       'orderStats'
//     );
//     const storeOrderRef = doc(db, 'stores', storeId, 'orders', orderId);

//     try {
//       await runTransaction(db, async (transaction) => {
//         // Fetch the current data for user's order, product order stats, and store's order
//         const userOrderData = await getDoc(userOrderRef);
//         const productOrderStatsData = await getDoc(productOrderStatsRef);
//         const storeOrderData = await getDoc(storeOrderRef);

//         // Update the user's order status to 'returned'
//         if (userOrderData.exists()) {
//           transaction.update(userOrderRef, { status: 'returned' });
//         }

//         // Increment the total returns for the product
//         if (productOrderStatsData.exists()) {
//           const currentTotalReturns =
//             productOrderStatsData.data().totalReturns || 0;
//           transaction.update(productOrderStatsRef, {
//             totalReturns: currentTotalReturns + 1,
//           });
//         }

//         // Update the store's order status to 'returned'
//         if (storeOrderData.exists()) {
//           transaction.update(storeOrderRef, { status: 'returned' });
//         }

//         // For arrays, you can add checks with Array.isArray() where needed.
//         // e.g., if you need to check an array in the fetched data:
//         // if (Array.isArray(userOrderData.data().someArrayProperty)) {
//         //     // Handle the array-specific operations
//         // }
//       });
//       wmsReturnOrder(orderData);
//       console.log('Order returned successfully!');
//     } catch (error) {
//       console.error('Error processing return:', error);
//       // Consider throwing the error or handling it further as per your app's requirements
//     }
//   }
//   return {
//     placeOrder,
//     returnOrder,
//     cancelOrder,
//   };
// }
// export default useOrder;

// import React, { useState, useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { Link, Route, Routes } from 'react-router-dom';
// import {
//   db,
//   addDoc,
//   setDoc,
//   updateDoc,
//   doc,
//   getDoc,
//   arrayUnion,
//   increment,
//   runTransaction,
// } from '../../firebaseConfig';
// import { wmsPlaceOrder, wmsReturnOrder, wmsCancelOrder } from './wmsClient';

// function useOrder(storeId) {
//   const currentUser = useSelector((state) => state.auth.currentUser);

//   function generateRandomOrderId() {
//     return Date.now().toString(36) + Math.random().toString(36).slice(2);
//   }

//   async function placeOrder(orderData) {
//     const userId = currentUser.uid;
//     const {
//       items,
//       storeId,
//       usedPoints,
//       totalAmount,
//       finalAmount,
//       shippingDetails,
//     } = orderData;
//     const userOrdersRef = doc(
//       db,
//       'users',
//       userId,
//       'orders',
//       generateRandomOrderId()
//     );

//     try {
//       await runTransaction(db, async (transaction) => {
//         const productDocs = await Promise.all(
//           items.map((item) =>
//             transaction.get(doc(db, 'stores', 'products', item.productId))
//           )
//         );
//         const couponDocs = items
//           .filter((item) => item.selectedCoupon && item.selectedCoupon.couponId)
//           .map((item) =>
//             transaction.get(
//               doc(db, 'stores', 'coupons', item.selectedCoupon.couponId)
//             )
//           );

//         for (const item of items) {
//           const productRef = doc(db, 'stores', 'products', item.productId);
//           const productDocData = productDocs
//             .find((pDoc) => pDoc.id === item.productId)
//             ?.data();

//           if (productDocData?.orderStats) {
//             transaction.update(productRef, {
//               'orderStats.totalOrders': increment(1),
//               'orderStats.lastOrderedDate': new Date(),
//             });
//           }

//           if (item.selectedCoupon && item.selectedCoupon.couponId) {
//             const couponRef = doc(
//               db,
//               'stores',
//               'coupons',
//               item.selectedCoupon.couponId
//             );
//             transaction.update(couponRef, {
//               usedBy: arrayUnion(userId),
//             });
//           }
//         }

//         if (
//           !shippingDetails?.address ||
//           !shippingDetails.extraAddress ||
//           !shippingDetails.phoneNumber
//         ) {
//           throw new Error('Incomplete shipping details provided.');
//         }

//         transaction.set(userOrdersRef, orderData, { merge: true });

//         let userPoints =
//           (await transaction.get(doc(db, 'users', userId))).data()?.points || 0;
//         userPoints += finalAmount * 0.01;
//         transaction.set(
//           doc(db, 'users', userId),
//           { points: userPoints },
//           { merge: true }
//         );
//       });

//       wmsPlaceOrder(orderData);
//       console.log('Order placed successfully!');
//     } catch (error) {
//       console.error('Error placing order:', error);
//     }
//   }

//   async function cancelOrder(orderData) {
//     const userId = currentUser.uid;
//     const { orderId, usedPoints } = orderData;

//     const userRef = doc(db, 'users', userId);
//     const userOrderRef = doc(db, 'users', userId, 'orders', orderId);

//     try {
//       await runTransaction(db, async (transaction) => {
//         const userData = await transaction.get(userRef);
//         const userOrderData = await transaction.get(userOrderRef);

//         // Update user points
//         if (userData.exists()) {
//           const currentPoints = userData.data().points || 0;
//           transaction.update(userRef, { points: currentPoints + usedPoints });
//         }

//         // Delete user's order reference
//         if (userOrderData.exists()) {
//           transaction.delete(userOrderRef);
//         }
//       });
//       wmsCancelOrder(orderData);
//       console.log('Order canceled successfully!');
//     } catch (error) {
//       console.error('Error canceling order:', error);
//     }
//   }

//   async function returnOrder(orderData) {
//     const userId = currentUser.uid;
//     const { orderId } = orderData;

//     const userOrderRef = doc(db, 'users', userId, 'orders', orderId);
//     const returnedOrdersRef = doc(db, 'stores', 'orders', 'returned', orderId);

//     try {
//       await runTransaction(db, async (transaction) => {
//         const userOrderData = await transaction.get(userOrderRef);

//         // Update the user's order status to 'returned'
//         if (userOrderData.exists()) {
//           transaction.update(userOrderRef, { status: 'returned' });
//           // Assume creation of a new record in 'stores > orders > returned'
//           transaction.set(returnedOrdersRef, {
//             ...userOrderData.data(),
//             status: 'returned',
//           });
//         }
//       });
//       wmsReturnOrder(orderData);
//       console.log('Order returned successfully!');
//     } catch (error) {
//       console.error('Error processing return:', error);
//     }
//   }

//   // Adjust the cancelOrder and returnOrder functions similarly to handle the new paths for orders under "users"
//   // and handling the returned status by creating a new document in "stores > orders > returned" if necessary.

//   return {
//     placeOrder,
//     cancelOrder,
//     returnOrder,
//   };
// }
// export default useOrder;

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  db,
  runTransaction,
  doc,
  collection,
  addDoc,
} from '../../firebaseConfig';
import { wmsPlaceOrder, wmsReturnOrder, wmsCancelOrder } from './wmsClient';

function useOrder(storeId) {
  const currentUser = useSelector((state) => state.auth.currentUser);
  const [error, setError] = useState(null);

  // Function to place an order
  const placeOrder = async (orderData) => {
    // 'orders' 컬렉션 참조를 정확하게 생성
    const ordersRef = collection(db, 'stores', storeId, 'orders');

    try {
      // 데이터에서 undefined 값 제거
      const sanitizedOrderData = JSON.parse(
        JSON.stringify({
          ...orderData,
          userId: currentUser.uid, // 사용자 ID를 주문 데이터에 포함
          createdAt: new Date(), // 주문 생성 시각 추가
        })
      );

      // 문서 추가
      const result = await addDoc(ordersRef, sanitizedOrderData);
      console.log('Order placed successfully!', result.id);

      // WMS(창고 관리 시스템)와 통합
      await wmsPlaceOrder({
        ...orderData,
        orderId: result.id, // WMS 함수 호출 시 orderId 전달
      });
    } catch (error) {
      console.error('Error placing order:', error);
      setError(error.message); // 에러 메시지 상태 업데이트
    }
  };

  // Function to cancel an order
  const cancelOrder = async (orderData) => {
    // 올바른 문서 참조 생성: 사용자 ID를 경로에서 제거
    const orderRef = doc(db, 'stores', storeId, 'orders', orderData.id);

    try {
      await runTransaction(db, async (transaction) => {
        const orderDoc = await transaction.get(orderRef);
        if (!orderDoc.exists()) {
          throw new Error('Order does not exist.');
        }
        // 주문 문서를 'canceled' 상태로 업데이트
        transaction.update(orderRef, { orderStatus: 'canceled' });
      });
      console.log('Order canceled successfully!');

      // WMS(창고 관리 시스템)에 취소를 통합
      await wmsCancelOrder({ orderData, userId: currentUser.uid });
    } catch (error) {
      console.error('Error canceling order:', error);
      setError(error.message);
    }
  };

  // Function to return an order
  const returnOrder = async (orderData) => {
    // 올바른 문서 참조 생성: 사용자 ID를 경로에서 제거
    const orderRef = doc(db, 'stores', storeId, 'orders', orderData.id);

    try {
      await runTransaction(db, async (transaction) => {
        const orderDoc = await transaction.get(orderRef);
        if (!orderDoc.exists()) {
          throw new Error('Order does not exist.');
        }
        // 주문 문서를 'returned' 상태로 업데이트
        transaction.update(orderRef, { orderStatus: 'returned' });
        console.log('Order returned successfully!');
      });

      // WMS(창고 관리 시스템)에 반품을 통합
      await wmsReturnOrder({ orderData, userId: currentUser.uid });
    } catch (error) {
      console.error('Error processing return:', error);
      setError(error.message);
    }
  };

  return {
    placeOrder,
    cancelOrder,
    returnOrder,
    error,
  };
}

export default useOrder;
