import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  Container,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CouponCard from './CouponCard';

const CouponList = ({ coupons, onDelete, isSeller }) => {
  const { t, i18n } = useTranslation();
  return (
    <Container>
      <Grid container spacing={2}>
        {coupons.length > 0 ? (
          coupons.map((coupon) => (
            <Grid item xs={12} sm={6} md={4} key={coupon.id}>
              <CouponCard
                coupon={coupon}
                onDelete={onDelete}
                isSeller={isSeller}
              />
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography variant="subtitle1" textAlign="left">
              {t('noAvailableCoupons')}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default CouponList;
