// import React, { useRef, useState, useEffect } from 'react';
// import {
//   firebaseApp,
//   db,
//   auth,
//   analytics,
//   storage,
//   functions,
//   collection,
//   query,
//   where,
//   orderBy,
//   doc,
//   getDoc,
//   getDocs,
// } from './firebaseConfig';

// import { useNavigate } from 'react-router-dom';
// import ChatContent from './ChatContent';
// import {
//   getMediaType,
//   isImageURL,
//   isVideoURL,
//   MEDIA_TYPES,
// } from './Utils/FileHelper';

// import './css/chatview.css';

// export default function ChatMessage({ key, message }) {
//   // ImageViewer의 표시 여부를 결정하는 state
//   const [isImageViewerVisible, setImageViewerVisible] = useState(false);
//   const [selectedFile, setSelectedFile] = useState(null);
//   const [files, setFiles] = useState([]);
//   const { type, content, date, fileUrls, uid, photoURL } = message;

//   const currentUserId = auth.currentUser?.uid;
//   const currentUserName = auth.currentUser?.displayName;

//   let messageClass = uid === currentUserId ? 'sent' : 'received';
//   if (type === 'appointments') {
//     messageClass = 'appointments';
//   }
//   const navigate = useNavigate();

//   useEffect(() => {
//     async function fetchAndSetFiles() {
//       let i = 0;

//       // Get the displayName for the provided uid
//       const displayName = await getDisplayNameByUID(uid);

//       const newFiles = fileUrls.map((url) => {
//         const currentFile = {
//           index: i,
//           fileUrl: url,
//           type: getMediaType(url),
//           senderName: displayName, // Use the fetched displayName
//           date,
//         };

//         i++;
//         return currentFile;
//       });

//       setFiles(newFiles);
//     }

//     fetchAndSetFiles();
//   }, [fileUrls, uid]); // Added uid to dependency array

//   async function getDisplayNameByUID(uid) {
//     const userDocRef = doc(db, 'users', uid);
//     const docSnapshot = await getDoc(userDocRef);

//     if (docSnapshot.exists()) {
//       return docSnapshot.data().name;
//     } else {
//       console.log('No such document!');
//       return null;
//     }
//   }

//   const handleFileClick = (url) => {
//     const selectedFile = {
//       fileUrl: url,
//       type: getMediaType(url),
//       senderName: currentUserName,
//       date,
//     };
//     setSelectedFile(selectedFile);
//     const idx = files.findIndex(
//       (file) => file.fileUrl === selectedFile.fileUrl
//     );

//     navigate('/imageviewer', { state: { files: files, index: idx } });
//   };

//   return (
//     <div className="chatview">
//       <div className={`message ${messageClass}`}>
//         {type !== 'appointments' && (
//           <img
//             src={photoURL || 'https://via.placeholder.com/150'}
//             alt="User Profile"
//           />
//         )}
//         <div className="content-section">
//           <div>
//             {type === 'appointments' ? (
//               <div>
//                 {content && (
//                   <div>
//                     <p>
//                       약속 시간이 정해졌습니다.서로 매너를 지키며 즐거운 거래
//                       되세요.약속 시간:{content}
//                     </p>
//                   </div>
//                 )}
//               </div>
//             ) : (
//               <div>{content && <p>{content}</p>}</div>
//             )}
//           </div>

//           <span>
//             {date && date.toDate ? date.toDate().toLocaleTimeString() : ''}
//           </span>
//           {fileUrls && fileUrls.length > 0 && (
//             <div className="media-previews">
//               {fileUrls.map((url, index) => (
//                 <ChatContent
//                   key={index}
//                   url={url}
//                   isImage={isImageURL(url)}
//                   isVideo={isVideoURL(url)}
//                   onFileClick={handleFileClick}
//                 />
//               ))}
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// }

// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import {
//   Avatar,
//   Card,
//   CardContent,
//   Typography,
//   IconButton,
//   Paper,
//   Box,
// } from '@mui/material';
// import ImageIcon from '@mui/icons-material/Image';
// import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
// import ChatContent from './ChatContent';
// import { getMediaType, isImageURL, isVideoURL } from './Utils/FileHelper';
// import { auth, db, doc, getDoc } from './firebaseConfig';
// import './css/chatview.css';

// export default function ChatMessage({ message }) {
//   const [selectedFile, setSelectedFile] = useState(null);
//   const [files, setFiles] = useState([]);
//   const { type, content, date, fileUrls, uid, photoURL } = message;
//   const navigate = useNavigate();
//   const currentUserId = auth.currentUser?.uid;
//   const currentUserName = auth.currentUser?.displayName;

//   let messageClass = uid === currentUserId ? 'sent' : 'received';
//   if (type === 'appointments') {
//     messageClass = 'appointments';
//   }

//   useEffect(() => {
//     async function fetchAndSetFiles() {
//       const displayName = await getDisplayNameByUID(uid);
//       const newFiles = fileUrls.map((url, index) => ({
//         index,
//         fileUrl: url,
//         type: getMediaType(url),
//         senderName: displayName,
//         date,
//       }));
//       setFiles(newFiles);
//     }
//     fetchAndSetFiles();
//   }, [fileUrls, uid]);

//   async function getDisplayNameByUID(uid) {
//     const userDocRef = doc(db, 'users', uid);
//     const docSnapshot = await getDoc(userDocRef);
//     return docSnapshot.exists() ? docSnapshot.data().name : null;
//   }

//   const handleFileClick = (url) => {
//     const selectedFile = {
//       fileUrl: url,
//       type: getMediaType(url),
//       senderName: currentUserName,
//       date,
//     };
//     setSelectedFile(selectedFile);
//     const idx = files.findIndex((file) => file.fileUrl === url);
//     navigate('/imageviewer', { state: { files: files, index: idx } });
//   };

//   return (
//     <Box className="chatview">
//       <Paper className={`message ${messageClass}`} elevation={3}>
//         <Card variant="outlined">
//           <CardContent>
//             {type !== 'appointments' && (
//               <Avatar
//                 src={photoURL || 'https://via.placeholder.com/150'}
//                 alt="User Profile"
//               />
//             )}
//             <Typography variant="body2" color="text.secondary">
//               {type === 'appointments'
//                 ? content &&
//                   `약속 시간이 정해졌습니다. 서로 매너를 지키며 즐거운 거래 되세요. 약속 시간: ${content}`
//                 : content}
//             </Typography>
//             <Typography variant="caption">
//               {date && date.toDate ? date.toDate().toLocaleTimeString() : ''}
//             </Typography>
//             {fileUrls && fileUrls.length > 0 && (
//               <div className="media-previews">
//                 {fileUrls.map((url, index) => (
//                   <IconButton onClick={() => handleFileClick(url)} key={index}>
//                     {isImageURL(url) ? <ImageIcon /> : <VideoCameraFrontIcon />}
//                   </IconButton>
//                 ))}
//               </div>
//             )}
//           </CardContent>
//         </Card>
//       </Paper>
//     </Box>
//   );
// }

// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import {
//   Avatar,
//   Card,
//   CardContent,
//   Typography,
//   IconButton,
//   Grid,
//   Paper,
//   Box,
// } from '@mui/material';
// import ImageIcon from '@mui/icons-material/Image';
// import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
// import ChatContent from './ChatContent';
// import {
//   getMediaType,
//   isImageURL,
//   isVideoURL,
//   MEDIA_TYPES,
// } from './Utils/FileHelper';
// import { auth, db, doc, getDoc } from './firebaseConfig';

// export default function ChatMessage({ message }) {
//   const [selectedFile, setSelectedFile] = useState(null);
//   const [files, setFiles] = useState([]);
//   const { type, content, date, fileUrls, uid, photoURL } = message;
//   const navigate = useNavigate();
//   const currentUserId = auth.currentUser?.uid;
//   const currentUserName = auth.currentUser?.displayName;
//   let isSender = uid === currentUserId;
//   let messageClass = uid === currentUserId ? 'sent' : 'received';
//   if (type === 'appointments') {
//     messageClass = 'appointments';
//   }

//   useEffect(() => {
//     async function fetchAndSetFiles() {
//       const displayName = await getDisplayNameByUID(uid);
//       const newFiles = fileUrls.map((url, index) => ({
//         index,
//         fileUrl: url,
//         type: getMediaType(url),
//         senderName: displayName,
//         date,
//       }));
//       setFiles(newFiles);
//     }
//     fetchAndSetFiles();
//   }, [fileUrls, uid]);

//   async function getDisplayNameByUID(uid) {
//     const userDocRef = doc(db, 'users', uid);
//     const docSnapshot = await getDoc(userDocRef);
//     return docSnapshot.exists() ? docSnapshot.data().name : null;
//   }

//   const handleFileClick = (url) => {
//     const selectedFile = {
//       fileUrl: url,
//       type: getMediaType(url),
//       senderName: currentUserName,
//       date,
//     };
//     setSelectedFile(selectedFile);
//     const idx = files.findIndex((file) => file.fileUrl === url);
//     navigate('/imageviewer', { state: { files: files, index: idx } });
//   };

//   return (
//     <Box sx={{ width: '100%', mt: 1, mb: 1 }}>
//       <Paper elevation={1} sx={{ p: 2 }}>
//         <Grid container justifyContent={isSender ? 'flex-end' : 'flex-start'}>
//           <Grid item>
//             {type !== 'appointments' && (
//               <Avatar
//                 src={photoURL || 'https://via.placeholder.com/150'}
//                 alt="User Profile"
//               />
//             )}
//           </Grid>
//           <Grid item xs={12} sm={8}>
//             <Box sx={{ textAlign: isSender ? 'right' : 'left' }}>
//               {type === 'appointments' ? (
//                 <Typography variant="body2" align="center">
//                   약속 시간이 정해졌습니다. 서로 매너를 지키며 즐거운 거래
//                   되세요. 약속 시간: {content}
//                 </Typography>
//               ) : (
//                 <Typography variant="body2">{content}</Typography>
//               )}
//               <Typography variant="caption">
//                 {date && date.toDate ? date.toDate().toLocaleTimeString() : ''}
//               </Typography>
//             </Box>
//             {fileUrls && fileUrls.length > 0 && (
//               <Box
//                 sx={{
//                   display: 'flex',
//                   justifyContent: isSender ? 'flex-end' : 'flex-start',
//                   gap: 1,
//                   mt: 1,
//                 }}
//               >
//                 {fileUrls.map((url, index) => (
//                   <IconButton onClick={() => handleFileClick(url)} key={index}>
//                     {isImageURL(url) ? <ImageIcon /> : <VideoCameraFrontIcon />}
//                   </IconButton>
//                 ))}
//               </Box>
//             )}
//           </Grid>
//         </Grid>
//       </Paper>
//     </Box>
//   );
// }

// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import {
//   Avatar,
//   Box,
//   Grid,
//   IconButton,
//   Typography,
//   Paper,
// } from '@mui/material';
// import ChatContent from './ChatContent';
// import { getMediaType, isImageURL, isVideoURL } from './Utils/FileHelper';
// import { auth, db, doc, getDoc } from './firebaseConfig';

// export default function ChatMessage({ message }) {
//   const navigate = useNavigate();
//   const { type, content, date, fileUrls, uid, photoURL } = message;
//   const currentUserId = auth.currentUser?.uid;
//   const isSender = uid === currentUserId;

//   async function getDisplayNameByUID(uid) {
//     const userDocRef = doc(db, 'users', uid);
//     const docSnapshot = await getDoc(userDocRef);
//     return docSnapshot.exists() ? docSnapshot.data().name : null;
//   }

//   const handleFileClick = (url) => {
//     const selectedFile = {
//       fileUrl: url,
//       type: getMediaType(url),
//       senderName: currentUserId, // Assuming you want to use the current user's name
//       date,
//     };
//     navigate('/imageviewer', { state: { file: selectedFile } });
//   };

//   return (
//     <Box
//       sx={{
//         width: '100%',
//         mt: 1,
//         mb: 1,
//         display: 'flex',
//         justifyContent: isSender ? 'flex-end' : 'flex-start',
//       }}
//     >
//       <Paper
//         elevation={1}
//         sx={{
//           maxWidth: '80%',
//           display: 'flex',
//           alignItems: 'center',
//           backgroundColor: 'background.paper',
//           overflow: 'hidden',
//         }}
//       >
//         {!isSender && (
//           <Avatar
//             src={photoURL || 'https://via.placeholder.com/150'}
//             alt="User Profile"
//             sx={{ width: 40, height: 40, marginRight: 1 }}
//           />
//         )}
//         <Box sx={{ flex: 1 }}>
//           <Typography
//             variant="body3"
//             sx={{ color: 'lightgray', wordBreak: 'break-word' }} // Changed color to white for visibility
//           >
//             {content}
//           </Typography>
//           {fileUrls &&
//             fileUrls.length > 0 &&
//             fileUrls.map((url, index) => (
//               <ChatContent
//                 key={index}
//                 url={url}
//                 isImage={isImageURL(url)}
//                 isVideo={isVideoURL(url)}
//                 onFileClick={() => handleFileClick(url)}
//               />
//             ))}
//           <Typography
//             variant="caption"
//             sx={{ display: 'block', textAlign: 'right' }}
//           >
//             {date && date.toDate ? date.toDate().toLocaleTimeString() : ''}
//           </Typography>
//         </Box>
//         {isSender && (
//           <Avatar
//             src={photoURL || 'https://via.placeholder.com/150'}
//             alt="User Profile"
//             sx={{ width: 40, height: 40, marginLeft: 1 }}
//           />
//         )}
//       </Paper>
//     </Box>
//   );
// }

// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { Avatar, Box, Paper, Typography, Grid } from '@mui/material';

// import ChatContent from './ChatContent';
// import { auth, db, doc, getDoc } from './firebaseConfig';
// import { getMediaType, isImageURL, isVideoURL } from './Utils/FileHelper';

// function ChatMessage({ message }) {
//   const [files, setFiles] = useState([]);
//   const { type, content, date, fileUrls, uid, photoURL } = message;
//   const navigate = useNavigate();
//   const currentUserId = auth.currentUser?.uid;
//   const isSender = uid === currentUserId;

//   useEffect(() => {
//     async function fetchAndSetFiles() {
//       const displayName = await getDisplayNameByUID(uid);
//       const newFiles = fileUrls.map((url, index) => ({
//         index,
//         fileUrl: url,
//         type: getMediaType(url),
//         senderName: displayName,
//         date,
//       }));
//       setFiles(newFiles);
//     }
//     fetchAndSetFiles();
//   }, [fileUrls, uid]);

//   async function getDisplayNameByUID(uid) {
//     const userDocRef = doc(db, 'users', uid);
//     const docSnapshot = await getDoc(userDocRef);
//     return docSnapshot.exists() ? docSnapshot.data().name : null;
//   }

//   const handleFileClick = (url) => {
//     const idx = files.findIndex((file) => file.fileUrl === url);
//     navigate('/imageviewer', { state: { files: files, index: idx } });
//   };

//   if (type === 'appointments') {
//     return (
//       <Typography
//         variant="body2"
//         align="center"
//         sx={{
//           marginY: 2,
//           color: 'darkslategray',
//           backgroundColor: 'lightgray',
//           padding: '8px',
//           borderRadius: '4px',
//         }}
//       >
//         약속 시간이 정해졌습니다. 서로 매너를 지키며 즐거운 거래 되세요. 약속
//         시간: {content}
//       </Typography>
//     );
//   }
//   return (
//     <Paper
//       elevation={0}
//       sx={{
//         width: '100%',
//         mt: 1,
//         mb: 1,
//         display: 'flex',
//         justifyContent: isSender ? 'flex-end' : 'flex-start',
//         backgroundColor: 'transparent',
//       }}
//     >
//       {!isSender && (
//         <Avatar
//           src={photoURL || 'https://via.placeholder.com/150'}
//           alt="User Profile"
//           sx={{ width: 40, height: 40, marginRight: 1, borderRadius: '8px' }}
//         />
//       )}
//       <Box
//         sx={{ maxWidth: '75%', overflow: 'hidden', wordBreak: 'break-word' }}
//       >
//         {content && (
//           <Typography
//             variant="body2"
//             sx={{
//               color: 'darkslategray',
//               backgroundColor: 'lightgray',
//               padding: '8px',
//               borderRadius: '4px',
//               wordBreak: 'break-word',
//               display: 'inline-block',
//               maxWidth: '100%',
//             }}
//           >
//             {content}
//           </Typography>
//         )}

//         {fileUrls && fileUrls.length > 0 && (
//           <Grid container spacing={0} sx={{ margin: 0 }}>
//             {fileUrls.map((url, index) => (
//               <Grid className="media-previews" item key={index}>
//                 <ChatContent
//                   url={url}
//                   isImage={isImageURL(url)}
//                   isVideo={isVideoURL(url)}
//                   onFileClick={() => handleFileClick(url)}
//                 />
//               </Grid>
//             ))}
//           </Grid>
//         )}
//         <Typography
//           variant="caption"
//           sx={{ display: 'block', textAlign: 'right', color: 'gray' }}
//         >
//           {date && date.toDate ? date.toDate().toLocaleTimeString() : ''}
//         </Typography>
//       </Box>
//       {isSender && (
//         <Avatar
//           src={photoURL || 'https://via.placeholder.com/150'}
//           alt="User Profile"
//           sx={{ width: 40, height: 40, marginLeft: 1, borderRadius: '8px' }}
//         />
//       )}
//     </Paper>
//   );
// }

// export default ChatMessage;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, Box, Paper, Typography, Grid } from '@mui/material';

import ChatContent from './ChatContent';
import { auth, db, doc, getDoc } from './firebaseConfig';
import { getMediaType, isImageURL, isVideoURL } from './Utils/FileHelper';
import { fetchUserProfile } from './Utils/GetUserProfile';

function ChatMessage({ message }) {
  const [files, setFiles] = useState([]);
  const { type, content, date, fileUrls, uid, photoURL } = message;
  const navigate = useNavigate();
  const currentUserId = auth.currentUser?.uid;
  const isSender = uid === currentUserId;

  useEffect(() => {
    if (type !== 'appointments') {
      async function fetchAndSetFiles() {
        const userProfile = await fetchUserProfile(uid);
        const newFiles = fileUrls.map((url, index) => ({
          index,
          fileUrl: url,
          type: getMediaType(url),
          senderName: userProfile?.name,
          date,
        }));
        setFiles(newFiles);
      }
      fetchAndSetFiles();
    }
  }, [fileUrls, uid, type]);

  const handleFileClick = (url) => {
    const idx = files.findIndex((file) => file.fileUrl === url);
    navigate('/imageviewer', { state: { files: files, index: idx } });
  };

  // Conditionally render the appointment message
  if (type === 'appointments') {
    return (
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          paddingY: 2,
        }}
      >
        <Typography
          variant="body2"
          align="center"
          sx={{
            color: 'darkslategray',
            backgroundColor: 'lightgray',
            padding: '8px',
            borderRadius: '4px',
          }}
        >
          약속 시간이 정해졌습니다. 서로 매너를 지키며 즐거운 거래 되세요. 약속
          시간: {content}
        </Typography>
      </Box>
    );
  }

  // Otherwise render the regular message format
  return (
    <Paper
      elevation={0}
      sx={{
        width: '100%',
        mt: 1,
        mb: 1,
        display: 'flex',
        justifyContent: isSender ? 'flex-end' : 'flex-start',
        backgroundColor: 'transparent',
      }}
    >
      {!isSender && (
        <Avatar
          src={photoURL || 'https://via.placeholder.com/150'}
          alt="User Profile"
          // sx={{ width: 40, height: 40, marginRight: 1, borderRadius: '8px' }}
          sx={{ width: 40, height: 40, marginRight: 1 }}
        />
      )}
      <Box
        sx={{ maxWidth: '75%', overflow: 'hidden', wordBreak: 'break-word' }}
      >
        {content && (
          <Typography
            variant="body2"
            sx={{
              color: 'darkslategray',
              backgroundColor: 'lightgray',
              padding: '8px',
              borderRadius: '4px',
              wordBreak: 'break-word',
              display: 'inline-block',
              maxWidth: '100%',
            }}
          >
            {content}
          </Typography>
        )}
        {fileUrls && fileUrls.length > 0 && (
          <Grid container spacing={0} sx={{ marginTop: 1 }}>
            {fileUrls.map((url, index) => (
              <Grid className="media-previews" item key={index}>
                <ChatContent
                  url={url}
                  isImage={isImageURL(url)}
                  isVideo={isVideoURL(url)}
                  onFileClick={() => handleFileClick(url)}
                />
              </Grid>
            ))}
          </Grid>
        )}
        <Typography
          variant="caption"
          sx={{ display: 'block', textAlign: 'right', color: 'gray' }}
        >
          {date && date.toDate ? date.toDate().toLocaleTimeString() : ''}
        </Typography>
      </Box>
      {isSender && (
        <Avatar
          src={photoURL || 'https://via.placeholder.com/150'}
          alt="User Profile"
          // sx={{ width: 40, height: 40, marginLeft: 1, borderRadius: '8px' }}
          sx={{ width: 40, height: 40, marginLeft: 1 }}
        />
      )}
    </Paper>
  );
}

export default ChatMessage;
