import React from 'react';
import {
  Stage,
  Layer,
  Rect,
  Text,
  Group,
  Line,
  Circle,
  Ellipse,
} from 'react-konva';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

// Simplified outline of the stadium
// const stadiumOutline = [
//   {
//     type: 'rect', // Main outer rectangle (boundary)
//     x: 10,
//     y: 10,
//     width: 980,
//     height: 480,
//     stroke: 'black',
//     strokeWidth: 5,
//   },
//   {
//     type: 'ellipse', // Inner oval (seating area)
//     x: 500,
//     y: 250,
//     radiusX: 450,
//     radiusY: 220,
//     stroke: 'grey',
//     strokeWidth: 2,
//   },
//   {
//     type: 'rect', // Stage area
//     x: 460,
//     y: 400,
//     width: 80,
//     height: 50,
//     fill: 'darkslategray',
//     stroke: 'black',
//     strokeWidth: 1,
//   },
// ];

// // Example Data Structure
// // You would replace this with your actual fetch call to get real data
// const venueData = {
//   sections: [
//     {
//       name: 'Section A',
//       x: 50,
//       y: 50,
//       rows: Array.from({ length: 20 }, (_, rowIndex) => ({
//         seats: Array.from({ length: 30 }, (_, seatIndex) => ({
//           occupied: Math.random() < 0.5, // Randomly assign occupied status
//         })),
//       })),
//     },
//     {
//       name: 'Section B',
//       x: 400,
//       y: 50,
//       rows: Array.from({ length: 20 }, (_, rowIndex) => ({
//         seats: Array.from({ length: 30 }, (_, seatIndex) => ({
//           occupied: Math.random() < 0.5, // Randomly assign occupied status
//         })),
//       })),
//     },
//     {
//       name: 'Section C',
//       x: 750,
//       y: 50,
//       rows: Array.from({ length: 20 }, (_, rowIndex) => ({
//         seats: Array.from({ length: 30 }, (_, seatIndex) => ({
//           occupied: Math.random() < 0.5, // Randomly assign occupied status
//         })),
//       })),
//     },
//   ],
// };
const isInsideEllipse = (x, y, ellipse) => {
  const dx = x - ellipse.x;
  const dy = y - ellipse.y;
  return (
    (dx * dx) / (ellipse.radiusX * ellipse.radiusX) +
      (dy * dy) / (ellipse.radiusY * ellipse.radiusY) <=
    1
  );
};

const generateSeats = (section, ellipse) => {
  const seats = [];
  for (let rowIndex = 0; rowIndex < section.rows; rowIndex++) {
    for (let seatIndex = 0; seatIndex < section.seatsPerRow; seatIndex++) {
      const x = section.x + seatIndex * 30; // 30 is seat width + spacing
      const y = section.y + rowIndex * 30; // 30 is seat height + spacing
      if (!isInsideEllipse(x, y, ellipse)) {
        seats.push({ x, y, occupied: Math.random() < 0.5 }); // Random occupancy
      }
    }
  }
  return seats;
};

const stadiumOutline = {
  rect: { x: 10, y: 10, width: 980, height: 480 },
  ellipse: { x: 500, y: 250, radiusX: 450, radiusY: 220 },
};

const sections = [
  {
    name: 'Section A',
    x: 50,
    y: 50,
    rows: 20,
    seatsPerRow: 30,
  },
  // Add more sections as needed
];

const venueData = {
  sections: sections.map((section) => ({
    ...section,
    seats: generateSeats(section, stadiumOutline.ellipse),
  })),
};

const ConcertStadium = () => {
  const scale = 0.5; // Scale factor for seats to manage visibility at different zoom levels

  return (
    <TransformWrapper
      defaultScale={1}
      defaultPositionX={200}
      defaultPositionY={100}
    >
      {({ zoomIn, zoomOut, resetTransform }) => (
        <React.Fragment>
          <TransformComponent>
            <Stage width={window.innerWidth} height={window.innerHeight}>
              <Layer>
                {/* Draw the stadium outline */}
                <Rect
                  x={stadiumOutline.rect.x}
                  y={stadiumOutline.rect.y}
                  width={stadiumOutline.rect.width}
                  height={stadiumOutline.rect.height}
                  stroke="black"
                  strokeWidth={5}
                />
                <Ellipse
                  x={stadiumOutline.ellipse.x}
                  y={stadiumOutline.ellipse.y}
                  radiusX={stadiumOutline.ellipse.radiusX}
                  radiusY={stadiumOutline.ellipse.radiusY}
                  stroke="grey"
                  strokeWidth={2}
                />
                {/* Draw sections and seats */}
                {venueData.sections.map((section, idx) => (
                  <Group key={idx} x={0} y={0}>
                    <Text text={section.name} fontSize={14} fontStyle="bold" />
                    {section.seats.map((seat, idz) => (
                      <Rect
                        key={idz}
                        x={seat.x}
                        y={seat.y}
                        width={20}
                        height={20}
                        fill={seat.occupied ? 'red' : 'green'}
                        stroke="black"
                        strokeWidth={1}
                      />
                    ))}
                  </Group>
                ))}
              </Layer>
            </Stage>
          </TransformComponent>
          <div style={{ position: 'absolute', top: '10px', left: '10px' }}>
            <button onClick={zoomIn}>Zoom In</button>
            <button onClick={zoomOut}>Zoom Out</button>
            <button onClick={resetTransform}>Reset</button>
          </div>
        </React.Fragment>
      )}
    </TransformWrapper>
  );
};

export default ConcertStadium;
