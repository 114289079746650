import { db } from './firebaseConfig';
import { collection, addDoc } from 'firebase/firestore';

export const createUserTransaction = async (
  sellerId,
  buyerId,
  transactionData
) => {
  try {
    const docRef = await addDoc(collection(db, 'userTransactions'), {
      sellerId,
      buyerId,
      ...transactionData,
      sellerComplete: false,
      buyerComplete: false,
      createdAt: new Date(),
    });
    return docRef.id; // 이 ID가 transactionId가 됩니다.
  } catch (error) {
    console.error('Error creating transaction:', error);
    return null;
  }
};
