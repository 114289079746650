// import React from 'react';
// import { Link } from 'react-router-dom';
// import moment from 'moment';
// import './css/product.css';
// import { formatPrice } from './Utils/PriceFormatter';

// moment.locale('ko');

// export const Product = ({ product, onClick }) => {
//     const dateObj = product?.createDate?.toDate();
//     const formattedDate = moment(dateObj).fromNow();
//     const formatState = (state) => {
//         switch (state) {
//             case 0: return "판매중";
//             case 1: return "예약중";
//             case 2: return "판매완료";
//             default: return "";
//         }
//     }
//     const getProductStateClass = (state) => {
//         switch (state) {
//             case 0:
//                 return 'on-sale';
//             case 1:
//                 return 'reserved';
//             case 2:
//                 return 'sold-out';
//             default:
//                 return '';
//         }
//     }

//     // const imageUrl = product.images[0];

//     // // Locate the last '/' and insert 'medium_' after it
//     // const lastSlashIndex = imageUrl.lastIndexOf('%2F');
//     // const newImageUrl = `${imageUrl.substring(0, lastSlashIndex + 3)}medium_${imageUrl.substring(lastSlashIndex + 3)}`;

//     return (
//         <div className='products' onClick={onClick}>
//             <Link to={`/detail/${product.id}`} className="custom-link">
//                 <div>
//                     <div className="thumbnail" style={{ backgroundImage: `url(${product.images[0]})` }}></div>
//                     <div className="flex-grow-1">
//                         <h5 className="title">
//                             {product.title}
//                         </h5>
//                         <p className="date">{formattedDate}</p>
//                         <p className="price">{formatPrice(product.price)}</p>
//                         <p className={`state ${getProductStateClass(product.state)}`}>{formatState(product.state)}</p>

//                         <div className="actions">
//                             <span>
//                                 <span className="icon-heart">좋아요</span>{product.likes}
//                             </span>
//                             <span>
//                                 <span className="icon-views">조회수</span>{product.views}
//                             </span>
//                             <span>
//                                 <span className="icon-chat">채팅</span>{product.chats}
//                             </span>
//                         </div>
//                     </div>
//                 </div>
//             </Link>
//         </div>

//         // </div>
//     );
// }
// export default Product;

// import React from 'react';
// import { Link } from 'react-router-dom';
// import { formatPrice } from './Utils/PriceFormatter';
// import moment from 'moment';
// import {
//   Card,
//   CardActionArea,
//   CardMedia,
//   CardContent,
//   Typography,
//   IconButton,
//   Box,
//   useTheme,
// } from '@mui/material';
// import FavoriteIcon from '@mui/icons-material/Favorite';
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import ChatIcon from '@mui/icons-material/Chat';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import CancelIcon from '@mui/icons-material/Cancel';

// moment.locale('ko');

// const Product = ({ product, onClick }) => {
//   const theme = useTheme();
//   const dateObj = product?.createDate?.toDate();
//   const formattedDate = moment(dateObj).fromNow();

//   const formatState = (state) => {
//     switch (state) {
//       case 0:
//         return {
//           icon: <CheckCircleIcon color="success" />,
//           text: '판매중',
//           color: theme.palette.success.main,
//         };
//       case 2:
//         return {
//           icon: <CancelIcon color="error" />,
//           text: '판매완료',
//           color: theme.palette.error.main,
//         };
//       default:
//         return { icon: null, text: '', color: theme.palette.text.secondary };
//     }
//   };

//   return (
//     <Card
//       sx={{
//         maxWidth: '100%',
//         borderRadius: '8px', // 모든 모서리 라운드 처리
//         m: 0,
//         margin: '5px',
//         padding: '5px',
//         boxShadow: 3,
//         overflow: 'visible',
//       }}
//       onClick={onClick}
//     >
//       {/* <CardActionArea component={Link} to={`/detail/${product.id}`}> */}
//       <CardActionArea>
//         <CardMedia
//           component="div"
//           sx={{
//             height: 0,
//             paddingTop: '56.25%', // 16:9 비율 유지
//             backgroundImage: `url(${product.small_images[0]})`,
//             backgroundSize: 'cover',
//             backgroundPosition: 'center',
//             borderRadius: '8px', // 모든 모서리 라운드 처리
//           }}
//           alt={product.title}
//         />
//         <CardContent
//           sx={{
//             '&:last-child': {
//               pb: 1,
//             },
//           }}
//         >
//           <Typography gutterBottom variant="h6" component="div">
//             {product.title}
//           </Typography>
//           <Typography variant="body2" color="text.secondary">
//             {formattedDate}
//           </Typography>
//           <Typography variant="body1" component="div">
//             {formatPrice(product.price)}
//           </Typography>
//           <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
//             {formatState(product.state).icon}
//             <Typography sx={{ color: formatState(product.state).color }}>
//               {formatState(product.state).text}
//             </Typography>
//           </Box>
//           <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
//             <IconButton aria-label="add to favorites" sx={{ p: 0.5 }}>
//               <FavoriteIcon fontSize="small" />
//               <Typography variant="caption">{product.likes}</Typography>
//             </IconButton>
//             <IconButton aria-label="view count" sx={{ p: 0.5 }}>
//               <VisibilityIcon fontSize="small" />
//               <Typography variant="caption">{product.views}</Typography>
//             </IconButton>
//             <IconButton aria-label="chat count" sx={{ p: 0.5 }}>
//               <ChatIcon fontSize="small" />
//               <Typography variant="caption">{product.chats}</Typography>
//             </IconButton>
//           </Box>
//         </CardContent>
//       </CardActionArea>
//     </Card>
//   );
// };

// export default Product;

// import React, { useEffect } from 'react';
// import {
//   Card,
//   CardActionArea,
//   CardMedia,
//   CardContent,
//   Typography,
//   IconButton,
//   Box,
//   Chip,
//   Tooltip,
// } from '@mui/material';
// import FavoriteIcon from '@mui/icons-material/Favorite';
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import ChatIcon from '@mui/icons-material/Chat';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import CancelIcon from '@mui/icons-material/Cancel';
// import { formatPrice } from './Utils/PriceFormatter';
// import moment from 'moment';
// import { useTranslation } from 'react-i18next';
// import productDefaultImage from './assets/images/productDefaultImage.png';

// const Product = ({ product, onClick }) => {
//   const { t, i18n } = useTranslation();
//   useEffect(() => {
//     moment.locale(i18n.language);
//   }, [i18n.language]);
//   const dateObj = product?.createDate?.toDate();
//   const formattedDate = moment(dateObj).fromNow();

//   const formatState = (state) => {
//     switch (state) {
//       case 0:
//         return { icon: <CheckCircleIcon />, text: '판매중', color: 'success' };
//       case 1:
//         return { icon: <FavoriteIcon />, text: '예약중', color: 'info' };
//       case 2:
//         return { icon: <ChatIcon />, text: '판매완료', color: 'secondary' };
//       default:
//         return { icon: <CancelIcon />, text: '알수없음', color: 'error' };
//     }
//   };
//   const imageUrl =
//     product.small_images && product.small_images.length > 0
//       ? product.small_images[0]
//       : productDefaultImage;
//   return (
//     <Card
//       sx={{
//         maxWidth: '100%',
//         m: '5px',
//         p: '5px',
//         boxShadow: 3,
//         borderRadius: 2,
//         overflow: 'visible',
//       }}
//       onClick={onClick}
//     >
//       <CardActionArea>
//         <CardMedia
//           component="div"
//           sx={{
//             height: 0,
//             paddingTop: '56.25%', // 16:9 aspect ratio
//             backgroundImage: `url(${imageUrl})`,
//             backgroundSize: 'cover',
//             backgroundPosition: 'center',
//             borderRadius: '8px',
//           }}
//           alt={product.title}
//         />
//         <CardContent>
//           <Typography
//             gutterBottom
//             variant="h6"
//             component="div"
//             noWrap
//             sx={{
//               overflow: 'hidden',
//               textOverflow: 'ellipsis',
//               whiteSpace: 'nowrap',
//             }}
//           >
//             {product.title}
//           </Typography>
//           <Box
//             sx={{
//               display: 'flex',
//               alignItems: 'center',
//               justifyContent: 'space-between',
//               mt: 1,
//               mb: 2,
//             }}
//           >
//             <Typography variant="body2" color="text.secondary" component="span">
//               {formattedDate}
//             </Typography>
//             <Chip
//               icon={formatState(product.state).icon}
//               label={formatState(product.state).text}
//               color={formatState(product.state).color}
//               size="small"
//               variant="outlined"
//             />
//           </Box>

//           <Box
//             sx={{
//               display: 'flex',
//               alignItems: 'center',
//               justifyContent: 'space-between',
//               mt: 2,
//             }}
//           >
//             <Typography variant="body1" component="span" sx={{ flexGrow: 1 }}>
//               {formatPrice(product.price)}
//             </Typography>
//             <Box sx={{ display: 'flex', alignItems: 'center' }}>
//               <Tooltip title="Likes">
//                 <IconButton aria-label="add to favorites" size="small">
//                   <FavoriteIcon />
//                   <Typography variant="caption" sx={{ ml: 0.5 }}>
//                     {product.likes}
//                   </Typography>
//                 </IconButton>
//               </Tooltip>
//               <Tooltip title="Views">
//                 <IconButton aria-label="view count" size="small">
//                   <VisibilityIcon />
//                   <Typography variant="caption" sx={{ ml: 0.5 }}>
//                     {product.views}
//                   </Typography>
//                 </IconButton>
//               </Tooltip>
//               <Tooltip title="Chat">
//                 <IconButton aria-label="chat count" size="small">
//                   <ChatIcon />
//                   <Typography variant="caption" sx={{ ml: 0.5 }}>
//                     {product.chats}
//                   </Typography>
//                 </IconButton>
//               </Tooltip>
//             </Box>
//           </Box>
//         </CardContent>
//       </CardActionArea>
//     </Card>
//   );
// };

// export default Product;

import React, { useEffect } from 'react';
import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  IconButton,
  Box,
  Chip,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import FavoriteIcon from '@mui/icons-material/Favorite';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ChatIcon from '@mui/icons-material/Chat';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { formatPrice } from './Utils/PriceFormatter';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import productDefaultImage from './assets/images/productDefaultImage.png';
import { formatState } from './Utils/ProductState';

const Product = ({ product, onClick, horizontal }) => {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    moment.locale(i18n.language);
  }, [i18n.language]);
  const dateObj = product?.createDate?.toDate();
  const formattedDate = moment(dateObj).fromNow();

  // const formatState = (state) => {
  //   switch (state) {
  //     case 0:
  //       return {
  //         icon: <CheckCircleIcon />,
  //         text: t('selling'),
  //         color: 'success',
  //       };
  //     case 1:
  //       return { icon: <FavoriteIcon />, text: t('reserved'), color: 'info' };
  //     case 2:
  //       return { icon: <ChatIcon />, text: t('sold-out'), color: 'secondary' };
  //     default:
  //       return { icon: <CancelIcon />, text: t('unknown'), color: 'error' };
  //   }
  // };
  const imageUrl =
    product.icon_images && product.icon_images.length > 0
      ? product.icon_images[0]
      : productDefaultImage;

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const productState = formatState(product.state, t);
  return (
    <Card
      sx={{
        maxWidth: '100%',
        m: '5px',
        p: '5px',
        boxShadow: 3,
        borderRadius: 2,
        overflow: 'hidden',
        '@media (max-width: 600px)': {
          m: '2px',
          p: '2px',
        },
      }}
      onClick={onClick}
    >
      <CardActionArea>
        {horizontal && isMobile ? (
          <Box display="flex" flexDirection="row" sx={{ height: '170px' }}>
            <Box
              sx={{
                width: '40%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CardMedia
                component="img"
                image={imageUrl}
                alt={product.name}
                sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
            </Box>
            <CardContent
              sx={{
                width: '60%',
                padding: '8px',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                marginTop: 'auto',
                marginBottom: 'auto',
              }}
            >
              <Typography gutterBottom variant="h6" component="div" noWrap>
                {product.title}
              </Typography>
              <Typography variant="body2" color="text.secondary" noWrap>
                {product.content}
              </Typography>

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  mt: 0,
                }}
              >
                <Typography
                  variant="body2"
                  color="text.secondary"
                  component="span"
                >
                  {formattedDate}
                </Typography>
                <Chip
                  icon={productState.icon}
                  label={productState.text}
                  color={productState.color}
                  size="small"
                  variant="outlined"
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  mt: 1,
                }}
              >
                <Typography
                  variant="body1"
                  component="span"
                  sx={{ flexGrow: 1 }}
                >
                  {formatPrice(product.price)} 원
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Tooltip title="Likes">
                    <IconButton aria-label="add to favorites" size="small">
                      <FavoriteIcon />
                      <Typography variant="caption" sx={{ ml: 0.5 }}>
                        {product.likes}
                      </Typography>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Views">
                    <IconButton aria-label="view count" size="small">
                      <VisibilityIcon />
                      <Typography variant="caption" sx={{ ml: 0.5 }}>
                        {product.views}
                      </Typography>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Chat">
                    <IconButton aria-label="chat count" size="small">
                      <ChatIcon />
                      <Typography variant="caption" sx={{ ml: 0.5 }}>
                        {product.chats}
                      </Typography>
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            </CardContent>
          </Box>
        ) : (
          <>
            {/* <CardMedia
              component="img"
              height="200"
              image={imageUrl}
              alt={product.name}
              sx={{ objectFit: 'cover' }}
            /> */}
            <CardMedia
              component={RouterLink}
              // to={`/product_detail/${product.uid}/${product.id}`}
              sx={{
                height: 0,
                paddingTop: '56.25%',
                backgroundImage: `url(${imageUrl})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                borderRadius: '8px',
                '@media (max-width: 600px)': {
                  height: '150px',
                  paddingTop: 0,
                },
              }}
              alt={product.productName}
            />
            <CardContent sx={{ padding: '8px' }}>
              <Typography gutterBottom variant="h6" component="div" noWrap>
                {product.title}
              </Typography>
              <Typography variant="body2" color="text.secondary" noWrap>
                {product.content}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  mt: 1,
                  mb: 2,
                }}
              >
                <Typography
                  variant="body2"
                  color="text.secondary"
                  component="span"
                >
                  {formattedDate}
                </Typography>
                <Chip
                  icon={productState.icon}
                  label={productState.text}
                  color={productState.color}
                  size="small"
                  variant="outlined"
                  sx={{ fontSize: '0.75rem', height: '20px' }}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  mt: 2,
                }}
              >
                <Typography
                  variant="body1"
                  component="span"
                  sx={{ flexGrow: 1 }}
                >
                  {formatPrice(product.price)} 원
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Tooltip title="Likes">
                    <IconButton aria-label="add to favorites" size="small">
                      <FavoriteIcon />
                      <Typography variant="caption" sx={{ ml: 0.5 }}>
                        {product.likes}
                      </Typography>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Views">
                    <IconButton aria-label="view count" size="small">
                      <VisibilityIcon />
                      <Typography variant="caption" sx={{ ml: 0.5 }}>
                        {product.views}
                      </Typography>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Chat">
                    <IconButton aria-label="chat count" size="small">
                      <ChatIcon />
                      <Typography variant="caption" sx={{ ml: 0.5 }}>
                        {product.chats}
                      </Typography>
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            </CardContent>
          </>
        )}
      </CardActionArea>
    </Card>
  );
};

export default Product;

// import React from 'react';
// import {
//   Card,
//   CardActionArea,
//   CardContent,
//   CardMedia,
//   Typography,
//   Box,
//   useMediaQuery,
// } from '@mui/material';

// const Product = ({ product, onClick, horizontal }) => {
//   const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

//   return (
//     <Card
//       onClick={onClick}
//       sx={{ height: isMobile && horizontal ? 'auto' : '100%' }}
//     >
//       <CardActionArea>
//         {horizontal && isMobile ? (
//           <Box display="flex" flexDirection="row" sx={{ height: '100px' }}>
//             <Box
//               sx={{
//                 width: '40%',
//                 height: '100%',
//                 display: 'flex',
//                 alignItems: 'center',
//                 justifyContent: 'center',
//               }}
//             >
//               <CardMedia
//                 component="img"
//                 image={product.icon_images[0]}
//                 alt={product.name}
//                 sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
//               />
//             </Box>
//             <CardContent
//               sx={{
//                 width: '60%',
//                 padding: '8px',
//                 height: '100%',
//                 display: 'flex',
//                 flexDirection: 'column',
//                 justifyContent: 'center',
//               }}
//             >
//               <Typography gutterBottom variant="h6" component="div">
//                 {product.name}
//               </Typography>
//               <Typography variant="body2" color="text.secondary">
//                 {product.description}
//               </Typography>
//               <Typography variant="body1" color="text.primary">
//                 ${product.price}
//               </Typography>
//             </CardContent>
//           </Box>
//         ) : (
//           <>
//             <CardMedia
//               component="img"
//               height="200"
//               image={product.icon_images[0]}
//               alt={product.name}
//               sx={{ objectFit: 'cover' }}
//             />
//             <CardContent>
//               <Typography gutterBottom variant="h5" component="div">
//                 {product.name}
//               </Typography>
//               <Typography variant="body2" color="text.secondary" paragraph>
//                 {product.description}
//               </Typography>
//               <Typography variant="body1" color="text.primary">
//                 ${product.price}
//               </Typography>
//             </CardContent>
//           </>
//         )}
//       </CardActionArea>
//     </Card>
//   );
// };

// export default Product;
