import { initializeApp } from 'firebase/app';
import {
  getFirestore,
  collection,
  doc,
  addDoc,
  updateDoc,
  deleteDoc,
  setDoc,
  query,
  where,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  increment,
  arrayUnion,
  arrayRemove,
  writeBatch,
  limit,
  FieldValue,
  Timestamp,
  serverTimestamp,
  startAfter,
  runTransaction,
} from 'firebase/firestore';
import {
  getAuth,
  updateProfile,
  RecaptchaVerifier,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
  signInWithCustomToken,
  onAuthStateChanged,
  signInWithPhoneNumber,
  signInWithEmailAndPassword,
  signOut,
  sendEmailVerification,
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  PhoneAuthProvider,
  updatePassword,
  signInWithCredential,
  reauthenticateWithCredential,
  linkWithCredential,
  reload,
} from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  uploadBytes,
} from 'firebase/storage';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyBYYgQuZbhr__lCJXG_S4r2zzoSS2vSRsw',
  authDomain: 'apple-market-af8c6.firebaseapp.com',
  projectId: 'apple-market-af8c6',
  storageBucket: 'apple-market-af8c6.appspot.com',
  messagingSenderId: '126159046474',
  appId: '1:126159046474:web:3567eaffd37e801f3223b3',
  measurementId: 'G-JSC76WQBJ0',
};

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);
const analytics = getAnalytics(firebaseApp);
const storage = getStorage(firebaseApp);
const functions = getFunctions(firebaseApp);
const getKakaoToken = httpsCallable(functions, 'getCustomToken');
const googleAuthProvider = new GoogleAuthProvider();
const messaging = getMessaging(firebaseApp);

// web-push generate-vapid-keys

// =======================================

// Public Key:
// BOWJwiBc9S4FZ7YsXgq8e_rrbwbBFhJOGKc0gN_qxJllbTk3cu6zbqUup9YlS0Dyw8M8n2VgIGK6OF42gBf5F8k

// Private Key:
// PcEPZ2Be2TxWgaaIDl-OIVLFCIGys65qc6mw2N79DPs

// Firebase 클라우드 메시징 토큰 얻기
export const requestForToken = () => {
  return getToken(messaging, {
    vapidKey:
      'BOWJwiBc9S4FZ7YsXgq8e_rrbwbBFhJOGKc0gN_qxJllbTk3cu6zbqUup9YlS0Dyw8M8n2VgIGK6OF42gBf5F8k',
  })
    .then((currentToken) => {
      if (currentToken) {
        console.log('current token for client: ', currentToken);
        // Send the token to your server and update the UI if necessary
        // ...
      } else {
        console.log(
          'No registration token available. Request permission to generate one.'
        );
        // Show permission request UI
        // ...
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
      // ...
    });
};

// 메시지를 받을 때의 이벤트 핸들러
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
export {
  firebaseApp,
  db,
  collection,
  doc,
  addDoc,
  updateDoc,
  setDoc,
  deleteDoc,
  query,
  where,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  limit,
  startAfter,
  runTransaction,
  auth,
  RecaptchaVerifier,
  googleAuthProvider,
  OAuthProvider,
  signInWithPopup,
  signInWithCustomToken,
  onAuthStateChanged,
  signInWithPhoneNumber,
  signInWithEmailAndPassword,
  signInWithCredential,
  signOut,
  EmailAuthProvider,
  PhoneAuthProvider,
  linkWithCredential,
  updatePassword,
  reauthenticateWithCredential,
  analytics,
  storage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  uploadBytes,
  functions,
  updateProfile,
  increment,
  arrayUnion,
  arrayRemove,
  writeBatch,
  sendEmailVerification,
  createUserWithEmailAndPassword,
  reload,
  FieldValue,
  Timestamp,
  serverTimestamp,
  getKakaoToken,
  messaging,
};
