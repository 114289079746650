import React from 'react';
import {
  Box,
  Typography,
  Select,
  MenuItem,
  Button,
  Modal,
  Fade,
  Backdrop,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import countries from './Country/countries.json';

const LanguageSettingsModal = ({
  open,
  handleClose,
  activeLanguage,
  setActiveLanguage,
}) => {
  const { t, i18n } = useTranslation();

  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;
    setActiveLanguage(newLanguage);
    i18n.changeLanguage(newLanguage);
    localStorage.setItem('language', newLanguage);
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            p: 2,
            bgcolor: 'background.paper',
            borderRadius: '16px 16px 0 0',
            boxShadow: 24,
          }}
        >
          <Typography variant="h6" gutterBottom>
            {t('settings.languageSettings')}
          </Typography>
          <Select
            value={activeLanguage}
            onChange={handleLanguageChange}
            fullWidth
          >
            {countries.map((country) => (
              <MenuItem key={country.code} value={country.code}>
                <span
                  className={`flag-icon flag-icon-${country.flag.toLowerCase()}`}
                  style={{ marginRight: 8 }}
                ></span>
                {country.name}
              </MenuItem>
            ))}
          </Select>
          <Box mt={2} textAlign="right">
            <Button onClick={handleClose} variant="contained">
              {t('settings.close')}
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default LanguageSettingsModal;
