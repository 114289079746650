import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  db,
  collection,
  getDocs,
  query,
  where,
  orderBy,
  doc,
  getDoc,
  setDoc,
  deleteDoc,
  writeBatch,
} from '../../firebaseConfig';
import {
  Container,
  Typography,
  Button,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Breadcrumbs,
  Link,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { googleTranslate } from '../../Utils/GoogleTranslation';

function PrivacyAgreementScreen() {
  const { t, i18n } = useTranslation();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const [versions, setVersions] = useState([]);
  const [version, setVersion] = useState(null);
  const [privacyList, setPrivacyList] = useState([]);
  const [checkedPrivacy, setCheckedPrivacy] = useState({});
  const [selectedPrivacyId, setSelectedPrivacyId] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [allChecked, setAllChecked] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPrivacy, setCurrentPrivacy] = useState({});

  useEffect(() => {
    const fetchVersions = async () => {
      const privacyCollection = collection(db, 'privacy');
      const privacySnapshot = await getDocs(privacyCollection);
      const allVersions = privacySnapshot.docs.map((doc) => doc.data().version);
      const uniqueVersions = [...new Set(allVersions)].sort((a, b) => b - a);
      setVersions(uniqueVersions);
      if (uniqueVersions.length > 0) {
        setVersion(uniqueVersions[0]);
      }
    };
    fetchVersions();
  }, []);

  useEffect(() => {
    if (version != null) {
      fetchPrivacy(version);
    }
  }, [version]);

  // const fetchPrivacy = async (version) => {
  //   const privacyCollection = collection(db, 'privacy');
  //   const q = query(
  //     privacyCollection,
  //     where('version', '==', version),
  //     orderBy('created_at')
  //   );
  //   const privacySnapshot = await getDocs(q);
  //   const privacyData = privacySnapshot.docs.map((doc) => ({
  //     ...doc.data(),
  //     id: doc.id,
  //     items: doc
  //       .data()
  //       .items.map((item, index) => ({ ...item, id: `${doc.id}_${index}` })),
  //   }));
  //   setPrivacyList(privacyData);
  //   initializeCheckedState(privacyData);
  // };
  // const fetchPrivacy = async (version) => {
  //   const privacyCollection = collection(db, 'privacy');
  //   const q = query(
  //     privacyCollection,
  //     where('version', '==', version),
  //     orderBy('created_at')
  //   );
  //   const privacySnapshot = await getDocs(q);

  //   const privacyData = await Promise.all(
  //     privacySnapshot.docs.map(async (doc) => {
  //       const translatedItems = await Promise.all(
  //         doc.data().items.map(async (item, index) => {
  //           const translatedTitle = await googleTranslate(item.title, i18n);
  //           const translatedContent = await googleTranslate(item.content, i18n);
  //           return {
  //             ...item,
  //             title: translatedTitle,
  //             content: translatedContent,
  //             id: `${doc.id}_${index}`,
  //           };
  //         })
  //       );

  //       const translatedPrivacyName = await googleTranslate(
  //         doc.data().name,
  //         i18n
  //       );

  //       return {
  //         ...doc.data(),
  //         name: translatedPrivacyName,
  //         id: doc.id,
  //         items: translatedItems,
  //       };
  //     })
  //   );

  //   setPrivacyList(privacyData);
  //   initializeCheckedState(privacyData);
  // };

  const fetchPrivacy = async (version) => {
    try {
      const privacyCollection = collection(db, 'privacy');
      const q = query(
        privacyCollection,
        where('version', '==', version),
        orderBy('created_at')
      );
      const privacySnapshot = await getDocs(q);

      const privacyData = await Promise.all(
        privacySnapshot.docs.map(async (doc) => {
          const translatedItems = await Promise.all(
            doc.data().items.map(async (item, index) => {
              try {
                const translatedTitle = await googleTranslate(item.title, i18n);
                const translatedContent = await googleTranslate(
                  item.content,
                  i18n
                );
                return {
                  ...item,
                  title: translatedTitle,
                  content: translatedContent,
                  id: `${doc.id}_${index}`,
                };
              } catch (translateError) {
                console.error(
                  `Error translating item ${index}:`,
                  translateError
                );
                return { ...item, id: `${doc.id}_${index}` };
              }
            })
          );

          try {
            const translatedPrivacyName = await googleTranslate(
              doc.data().name,
              i18n
            );

            return {
              ...doc.data(),
              name: translatedPrivacyName,
              id: doc.id,
              items: translatedItems,
            };
          } catch (translateError) {
            console.error('Error translating privacy name:', translateError);
            return {
              ...doc.data(),
              id: doc.id,
              items: translatedItems,
            };
          }
        })
      );

      setPrivacyList(privacyData);
      initializeCheckedState(privacyData);
    } catch (error) {
      if (
        error.code === 'failed-precondition' ||
        error.code === 'unavailable'
      ) {
        console.error('Firestore index error:', error.message);
        // 필요한 경우 사용자에게 알림을 표시하거나 추가 처리를 수행할 수 있습니다.
      } else {
        console.error('Error fetching privacy data:', error);
      }
    }
  };

  const initializeCheckedState = async (privacyData) => {
    let initialState = {};
    privacyData.forEach((term) => {
      term.items.forEach((item) => {
        initialState[`${term.id}_${item.id}`] = false; // 약관 항목별 고유 ID 생성
      });
    });
    setCheckedPrivacy(initialState);
    await fetchUserAgreements(privacyData);
  };

  const fetchUserAgreements = async (privacyData) => {
    if (currentUser && currentUser.uid) {
      const agreements = {};

      for (const privacy of privacyData) {
        const userPrivacyCollection = collection(
          db,
          'users',
          currentUser.uid,
          'privacy',
          privacy.id,
          'items'
        );
        const userPrivacySnapshot = await getDocs(userPrivacyCollection);

        if (!userPrivacySnapshot.empty) {
          userPrivacySnapshot.docs.forEach((itemDoc) => {
            const itemId = itemDoc.id;
            agreements[`${privacy.id}_${itemId}`] = true;
          });
        }
      }

      setCheckedPrivacy((prevState) => {
        const newState = {
          ...prevState,
          ...agreements,
        };
        updateAllCheckedState(newState);
        return newState;
      });
    } else {
      console.error('No current user found, unable to fetch agreements.');
    }
  };

  const areAllPrivacyChecked = () => {
    return Object.values(checkedPrivacy).every(Boolean);
  };

  const handleAllCheck = async () => {
    const allChecked = !areAllPrivacyChecked();
    const updatedCheckedPrivacy = {};
    privacyList.forEach((privacy) => {
      privacy.items.forEach((item) => {
        updatedCheckedPrivacy[`${privacy.id}_${item.id}`] = allChecked;
      });
    });
    setCheckedPrivacy(updatedCheckedPrivacy);
    setAllChecked(allChecked);

    if (allChecked) {
      await agreeToAllItems(currentUser?.uid, updatedCheckedPrivacy);
    } else {
      await disagreeToAllItems(currentUser?.uid, updatedCheckedPrivacy);
    }
  };

  const handleIndividualCheck = async (termId, itemId) => {
    const itemKey = `${termId}_${itemId}`;
    setCheckedPrivacy((prevState) => {
      const newState = {
        ...prevState,
        [itemKey]: !prevState[itemKey],
      };
      updateAllCheckedState(newState);
      return newState;
    });

    const currentlyChecked = !checkedPrivacy[itemKey];
    if (currentlyChecked) {
      await agreeToPrivacy(currentUser?.uid, termId, itemId);
    } else {
      await disagreeToPrivacy(currentUser?.uid, termId, itemId);
    }
  };

  const updateAllCheckedState = (newCheckedPrivacy) => {
    const allChecked = Object.values(newCheckedPrivacy).every(Boolean);
    setAllChecked(allChecked);
  };

  async function agreeToPrivacy(userId, termId, itemId) {
    const userPrivacyRef = doc(
      db,
      'users',
      userId,
      'privacy',
      termId,
      'items',
      itemId
    );
    await setDoc(userPrivacyRef, {
      userId,
      termId,
      itemId,
      agreed_at: new Date(),
    });
  }

  async function disagreeToPrivacy(userId, termId, itemId) {
    const userPrivacyRef = doc(
      db,
      'users',
      userId,
      'privacy',
      termId,
      'items',
      itemId
    );
    await deleteDoc(userPrivacyRef);
  }

  async function agreeToAllItems(userId, newCheckedPrivacy) {
    if (!userId || !newCheckedPrivacy) {
      console.error('Invalid input to agreeToAllItems.');
      return;
    }

    const batch = writeBatch(db);
    privacyList.forEach((privacy) => {
      privacy.items.forEach((item) => {
        const itemKey = `${privacy.id}_${item.id}`;
        if (newCheckedPrivacy[itemKey]) {
          const userPrivacyRef = doc(
            db,
            'users',
            userId,
            'privacy',
            privacy.id,
            'items',
            item.id
          );
          batch.set(userPrivacyRef, {
            userId,
            termId: privacy.id,
            itemId: item.id,
            agreed_at: new Date(),
          });
        }
      });
    });

    try {
      await batch.commit();
    } catch (error) {
      console.error('Error committing batch:', error);
    }
  }

  async function disagreeToAllItems(userId, newCheckedPrivacy) {
    const batch = writeBatch(db);
    privacyList.forEach((privacy) => {
      privacy.items.forEach((item) => {
        const itemKey = `${privacy.id}_${item.id}`;
        const userPrivacyRef = doc(
          db,
          'users',
          userId,
          'privacy',
          privacy.id,
          'items',
          item.id
        );
        batch.delete(userPrivacyRef);
      });
    });

    try {
      await batch.commit();
    } catch (error) {
      console.error('Error committing batch:', error);
    }
  }

  const handleVersionChange = (event) => {
    setVersion(event.target.value);
    setSelectedPrivacyId(null);
    setSelectedItemId(null);
  };

  const handleItemClick = (itemId) => {
    setSelectedItemId(itemId);
  };

  const handleViewPrivacy = (privacy) => {
    if (!privacy) {
      console.warn('No privacy provided to handleViewPrivacy');
      return;
    }
    setCurrentPrivacy(privacy);
    openModal();
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentPrivacy({});
  };

  const selectedPrivacy = privacyList.find(
    (privacy) => privacy.id === selectedPrivacyId
  );
  const selectedItem = selectedPrivacy?.items.find(
    (item) => item.id === selectedItemId
  );

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        {t('privacyList')}
      </Typography>
      <Box display="flex" justifyContent="flex-end">
        <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2, mt: 2 }}>
          <Link
            color="inherit"
            href="/"
            onClick={(event) => event.preventDefault()}
          >
            {t('home')}
          </Link>
          <Link
            color="inherit"
            href="/support"
            onClick={(event) => event.preventDefault()}
          >
            {t('customer-service.title')}
          </Link>
          <Typography color="textPrimary">
            {t('privacy-policy.title')}
          </Typography>
        </Breadcrumbs>
      </Box>
      <FormControl fullWidth margin="normal">
        <InputLabel>Version</InputLabel>
        <Select value={version} onChange={handleVersionChange} label="Version">
          {versions.map((v) => (
            <MenuItem key={v} value={v}>
              {v}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControlLabel
        control={
          <Checkbox
            checked={allChecked}
            onChange={handleAllCheck}
            name="allPrivacy"
          />
        }
        label={t('all-agree')}
      />
      <Typography variant="h6">
        {t('terms-and-policies.mandatory-terms')}
      </Typography>
      {privacyList
        .filter((privacy) =>
          privacy.items.some((item) => item.type === 'required')
        )
        .map((privacy) => (
          <Box key={privacy.id} sx={{ mb: 2 }}>
            {privacy.items
              .filter((item) => item.type === 'required')
              .map((item) => (
                <Box
                  key={item.id}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          checkedPrivacy[`${privacy.id}_${item.id}`] || false
                        }
                        onChange={() =>
                          handleIndividualCheck(privacy.id, item.id)
                        }
                      />
                    }
                    label={item.title}
                  />
                  <Button onClick={() => handleViewPrivacy(item)}>
                    {t('viewDetails')}
                  </Button>
                </Box>
              ))}
          </Box>
        ))}
      <Typography variant="h6">{t('optional-terms')}</Typography>
      {privacyList
        .filter((privacy) =>
          privacy.items.some((item) => item.type === 'optional')
        )
        .map((privacy) => (
          <Box key={privacy.id} sx={{ mb: 2 }}>
            {privacy.items
              .filter((item) => item.type === 'optional')
              .map((item) => (
                <Box
                  key={item.id}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          checkedPrivacy[`${privacy.id}_${item.id}`] || false
                        }
                        onChange={() =>
                          handleIndividualCheck(privacy.id, item.id)
                        }
                      />
                    }
                    label={item.title}
                  />
                  <Button onClick={() => handleViewPrivacy(item)}>
                    {t('viewDetails')}
                  </Button>
                </Box>
              ))}
          </Box>
        ))}
      <Dialog open={isModalOpen} onClose={closeModal}>
        <DialogTitle>{currentPrivacy.title}</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              mt: 2,
              p: 2,
              whiteSpace: 'pre-wrap',
              border: '1px solid #ccc',
              borderRadius: '4px',
            }}
          >
            {/* <Typography
              variant="subtitle1"
              sx={{ textDecoration: 'underline' }}
            >
              {currentPrivacy.title}
            </Typography> */}
            <Typography component="div" sx={{ whiteSpace: 'pre-wrap' }}>
              {currentPrivacy.content}
            </Typography>
          </Box>
        </DialogContent>
        <Button onClick={closeModal} color="primary" autoFocus>
          {t('close')}
        </Button>
      </Dialog>
    </Container>
  );
}

export default PrivacyAgreementScreen;
