import React, { useState, useEffect } from 'react';
import { db, functions } from './firebaseConfig';
import { httpsCallable } from 'firebase/functions';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

function SimilarUserProducts({ productId }) {
  const [similarProducts, setSimilarProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (productId) {
      fetchSimilarProducts(productId);
    }
  }, [productId]);

  const fetchSimilarProducts = async (prodId) => {
    setLoading(true);
    try {
      const getNearestEmbeddings = httpsCallable(
        functions,
        'getNearestProducts'
      );
      const result = await getNearestEmbeddings({ productId: prodId });
      setSimilarProducts(result.data);
    } catch (error) {
      console.error('Error fetching similar products:', error);
      setSimilarProducts([]);
    }
    setLoading(false);
  };

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      alignItems="left"
      justify="center"
    >
      {loading ? (
        <Grid item xs={12}>
          <CircularProgress />
        </Grid>
      ) : (
        <Grid container spacing={2}>
          {similarProducts.length === 0 ? (
            <Grid item xs={12}>
              <Typography textAlign="left"></Typography>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Typography variant="h6" component="h6" gutterBottom>
                비슷한 상품들
              </Typography>

              {similarProducts.map(({ prodId, similarity }) => (
                <Grid item xs={12} sm={6} md={4} key={prodId}>
                  <Paper
                    elevation={3}
                    style={{ padding: '20px', textAlign: 'center' }}
                  >
                    <Typography variant="body1">
                      Product ID: {prodId}
                    </Typography>
                    <Typography variant="body2">
                      Similarity: {similarity.toFixed(2)}
                    </Typography>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
}

export default SimilarUserProducts;
