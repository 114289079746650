import axios from 'axios';
import { db, doc, getDoc } from '../../firebaseConfig';
// WMS API의 기본 URL을 설정합니다.
const WMS_BASE_URL = `https://asia-northeast3-warehousemanagementsyste-334f8.cloudfunctions.net`;

// Firestore에서 API 키를 가져오는 함수
async function getApiKey(userId) {
  const apiKeyRef = doc(db, 'users', userId);
  const docSnap = await getDoc(apiKeyRef);
  if (docSnap.exists()) {
    return docSnap.data().wmsApiKey;
  } else {
    throw new Error('No API key available');
  }
}

// 주문 추가 함수
async function wmsPlaceOrder(orderData) {
  try {
    const wmsApiKey = await getApiKey(orderData.userId);
    const response = await axios.post(`${WMS_BASE_URL}/placeOrder`, orderData, {
      headers: {
        'x-api-key': wmsApiKey,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Failed to place order in WMS:', error);
    throw error;
  }
}

// 주문 취소 함수
async function wmsCancelOrder(orderData) {
  try {
    const wmsApiKey = await getApiKey(orderData.userId);
    const response = await axios.post(
      `${WMS_BASE_URL}/cancelOrder`,
      orderData,
      {
        headers: {
          'x-api-key': wmsApiKey,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Failed to cancel order in WMS:', error);
    throw error;
  }
}

// 주문 반품 함수
async function wmsReturnOrder(orderData) {
  try {
    const wmsApiKey = await getApiKey(orderData.userId);
    const response = await axios.post(
      `${WMS_BASE_URL}/returnOrder`,
      orderData,
      {
        headers: {
          'x-api-key': wmsApiKey,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Failed to process return in WMS:', error);
    throw error;
  }
}
export { wmsPlaceOrder, wmsReturnOrder, wmsCancelOrder };
