import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { db } from './firebaseConfig';
import { doc, getDoc, updateDoc, serverTimestamp } from 'firebase/firestore';
import UserRatingForm from './UserRatingForm';
import { Button, Typography, Box } from '@mui/material';

const UserTransactionPage = () => {
  const { transactionId } = useParams();
  const [transaction, setTransaction] = useState(null);
  const [ratingFormOpen, setRatingFormOpen] = useState(false);
  const [ratedUserId, setRatedUserId] = useState(null);
  const [currentUserType, setCurrentUserType] = useState(''); // 'seller' 또는 'buyer'

  useEffect(() => {
    const fetchTransaction = async () => {
      try {
        const transactionDoc = await getDoc(
          doc(db, 'userTransactions', transactionId)
        );
        if (transactionDoc.exists()) {
          setTransaction(transactionDoc.data());
          setCurrentUserType(transactionDoc.data().currentUserType); // 현재 사용자 타입 설정
        }
      } catch (error) {
        console.error('Error fetching transaction:', error);
      }
    };

    fetchTransaction();
  }, [transactionId]);

  const handleTransactionComplete = async () => {
    await markUserTransactionComplete(transactionId, currentUserType);
  };

  const markUserTransactionComplete = async (transactionId, userType) => {
    try {
      const userTransactionRef = doc(db, 'userTransactions', transactionId);
      const updateData =
        userType === 'seller'
          ? { sellerComplete: true }
          : { buyerComplete: true };

      await updateDoc(userTransactionRef, updateData);

      // 거래가 양쪽 모두 완료되었는지 확인
      const transactionDoc = await getDoc(userTransactionRef);
      const transactionData = transactionDoc.data();
      if (transactionData.sellerComplete && transactionData.buyerComplete) {
        await updateDoc(userTransactionRef, { completedAt: serverTimestamp() });
        // 평가 폼을 띄우는 로직 추가
        setRatingFormOpen(true);
        // setRatedUserId(transactionData.ratedUserId);
        setRatedUserId(
          userType === 'seller'
            ? transactionData.buyerId
            : transactionData.sellerId
        );
      }
    } catch (error) {
      console.error('Error marking transaction complete:', error);
    }
  };
  return (
    <Box>
      <Typography variant="h4">Transaction Details</Typography>
      {transaction && (
        <>
          <Typography variant="body1">
            Seller: {transaction.sellerId}
          </Typography>
          <Typography variant="body1">Buyer: {transaction.buyerId}</Typography>
          <Typography variant="body1">
            Product ID: {transaction.productId}
          </Typography>
          <Typography variant="body1">Price: {transaction.price}</Typography>
          <Button
            onClick={handleTransactionComplete}
            color="primary"
            variant="contained"
          >
            거래 완료
          </Button>
        </>
      )}
      <UserRatingForm
        open={ratingFormOpen}
        handleClose={() => setRatingFormOpen(false)}
        transactionId={transactionId}
        ratedUserId={ratedUserId}
      />
    </Box>
  );
};

export default UserTransactionPage;
