// import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import { db, deleteDoc, writeBatch, doc, setDoc, collection, orderBy, getDocs, query, where, limit } from '../../firebaseConfig';
// import '../../css/detail.css';
// import TermsAgreementScreen from './TermsAgreementScreen';
// import TermsModal from './TermsModal';
// import Modal from 'react-modal';
// import ModalStyles from '../../Common/ModalStyles';

// function Terms() {
//     const [versions, setVersions] = useState([]);
//     const [version, setVersion] = useState(2); // Default version
//     const [checkedTerms, setCheckedTerms] = useState({ required: {}, optional: {} });
//     const currentUser = useSelector((state) => state.auth.currentUser);
//     const [terms, setTerms] = useState([]);
//     const [isModalOpen, setIsModalOpen] = useState(false);
//     const [currentTerm, setCurrentTerm] = useState({});

//     useEffect(() => {
//         const fetchVersions = async () => {
//             const termsCollection = collection(db, 'terms');
//             const termsSnapshot = await getDocs(termsCollection);

//             const allVersions = termsSnapshot.docs.map(doc => doc.data().version);
//             const uniqueVersions = [...new Set(allVersions)]; // Remove duplicates

//             setVersions(uniqueVersions);
//         };

//         fetchVersions();
//     }, []);

//     useEffect(() => {
//         const fetchTerms = async () => {
//             const termsCollection = collection(db, 'terms');
//             const q = query(termsCollection, where("version", "==", version), orderBy("created_at"));
//             const termsSnapshot = await getDocs(q);
//             const termsData = termsSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));

//             let requiredTerms = {};
//             let optionalTerms = {};

//             termsData.forEach(term => {
//                 if (term.type === 'required') {
//                     requiredTerms[term.id] = false;
//                 } else {
//                     optionalTerms[term.id] = false;
//                 }
//             });

//             setCheckedTerms({ required: requiredTerms, optional: optionalTerms });
//             setTerms(termsData);
//         };

//         fetchTerms();
//     }, [version]);

//     const fetchUserTerms = async () => {
//         // 이 부분은 Firestore 문법에 맞게 수정해야 합니다.
//         let userTerms = [];

//         if (currentUser && currentUser.uid) {
//             const userTermsQuery = query(
//                 collection(db, 'user_terms'),
//                 where("userId", "==", currentUser.uid)
//             );
//             const userTermsSnapshot = await getDocs(userTermsQuery);
//             userTerms = userTermsSnapshot.docs.map(doc => doc.data());
//         } else {
//             console.error("Invalid user ID");
//             // 혹은 다른 오류 처리 로직을 여기에 추가
//         }

//         let updatedCheckedTerms = { ...checkedTerms };

//         userTerms.forEach(userTerm => {
//             if (updatedCheckedTerms.required[userTerm.termId] !== undefined) {
//                 updatedCheckedTerms.required[userTerm.termId] = true;
//             } else if (updatedCheckedTerms.optional[userTerm.termId] !== undefined) {
//                 updatedCheckedTerms.optional[userTerm.termId] = true;
//             }
//         });

//         setCheckedTerms(updatedCheckedTerms);
//     };
//     useEffect(() => {

//         fetchUserTerms();
//     }, [currentUser, terms]);

//     const areAllTermsChecked = () => {
//         return Object.values(checkedTerms.required).every(Boolean) &&
//             Object.values(checkedTerms.optional).every(Boolean);
//     };
//     // 모든 필수 약관이 동의되었는지 확인하는 함수
//     const allRequiredTermsAgreed = () => {
//         return Object.values(checkedTerms.required).every(val => val);
//     };

//     const handleAllCheck = () => {
//         const allChecked = !areAllTermsChecked();
//         const newCheckedTerms = {
//             required: Object.keys(checkedTerms.required).reduce((acc, termId) => {
//                 acc[termId] = allChecked;
//                 return acc;
//             }, {}),
//             optional: Object.keys(checkedTerms.optional).reduce((acc, termId) => {
//                 acc[termId] = allChecked;
//                 return acc;
//             }, {})
//         };

//         setCheckedTerms(newCheckedTerms);

//         if (allChecked) {
//             agreeToAllTerms(currentUser?.uid, newCheckedTerms);
//         } else {
//             disagreeToAllTerms(currentUser?.uid, newCheckedTerms);
//         }
//     };

//     const handleIndividualCheck = async (termId, type) => {
//         const updatedCheckedTerms = { ...checkedTerms };
//         updatedCheckedTerms[type][termId] = !updatedCheckedTerms[type][termId];
//         setCheckedTerms(updatedCheckedTerms);

//         // 모든 약관에 대한 체크 상태를 검사하여 '모두 동의합니다.' 체크박스 상태를 업데이트
//         if (updatedCheckedTerms[type][termId]) {
//             await agreeToTerm(currentUser?.uid, termId);
//         } else {
//             await disagreeToTerm(currentUser?.uid, termId);
//         }
//         fetchUserTerms();  // Update UI state to reflect backend state.
//     };

//     async function agreeToTerm(userId, termId) {
//         const userTermRef = doc(db, 'user_terms', `${userId}_${termId}`);
//         await setDoc(userTermRef, {
//             userId,
//             termId,
//             agreed_at: new Date()
//         });

//     }

//     async function disagreeToTerm(userId, termId) {
//         const userTermRef = doc(db, 'user_terms', `${userId}_${termId}`);
//         await deleteDoc(userTermRef);  // 동의 철회 시 해당 도큐먼트 삭제
//     }
//     async function agreeToAllTerms(userId) {
//         if (!userId || !terms || !checkedTerms) {
//             console.error('Invalid input to agreeToAllTerms.');
//             return;
//         }

//         const batch = writeBatch(db);
//         terms.forEach((term) => {
//             // checkedTerms의 해당 type 확인
//             const currentTermType = checkedTerms[term.type];

//             if (!currentTermType) {
//                 console.error(`Invalid term type: ${term.type}`);
//                 return;
//             }

//             // 이미 동의한 약관은 제외
//             if (!currentTermType[term.id]) {
//                 const userTermRef = doc(db, 'user_terms', `${userId}_${term.id}`);
//                 batch.set(userTermRef, {
//                     userId,
//                     termId: term.id,
//                     agreed_at: new Date()
//                 });
//             }
//         });

//         try {
//             await batch.commit();
//         } catch (error) {
//             console.error('Error committing batch:', error);
//         }
//     }

//     async function disagreeToAllTerms(userId) {
//         const batch = writeBatch(db);

//         // Handle required terms
//         Object.keys(checkedTerms.required).forEach((termId) => {
//             const userTermRef = doc(db, 'user_terms', `${userId}_${termId}`);
//             batch.delete(userTermRef);
//         });

//         // Handle optional terms
//         Object.keys(checkedTerms.optional).forEach((termId) => {
//             const userTermRef = doc(db, 'user_terms', `${userId}_${termId}`);
//             batch.delete(userTermRef);
//         });

//         await batch.commit();
//     }

//     const handleViewTerm = (term) => {
//         if (!term) {
//             console.warn("No term provided to handleViewTerm");
//             return;
//         }

//         // Optionally, you can perform additional logic here.
//         // For example, logging user actions or tracking user engagement with terms.

//         setCurrentTerm(term);
//         openModal();
//     };
//     const openModal = () => {
//         setIsModalOpen(true);
//     };

//     const closeModal = () => {
//         setIsModalOpen(false);
//         setCurrentTerm({}); // Optionally reset the current term when closing the modal.
//     };

//     return (
//         <div className='detail'>
//             <div className="container">
//                 <h1>이용약관</h1>

//                 <label>
//                     Version:
//                     <select value={version} onChange={(e) => setVersion(Number(e.target.value))}>
//                         {versions.map(v => <option key={v} value={v}>{v}</option>)}
//                     </select>
//                 </label>
//                 <div className="terms-check-group">
//                     <label>
//                         <input type="checkbox" checked={checkedTerms.all} onChange={handleAllCheck} />
//                         모두 동의합니다.
//                     </label>
//                 </div>

//                 <h2>필수 약관</h2>
//                 {terms.filter(term => term.items.some(item => item.type === 'required')).map(term => (
//                     <section key={term.id}>
//                         {term.items.filter(item => item.type === 'required').map((item, index) => (
//                             <div key={index}>
//                                 <label>
//                                     <input type="checkbox"
//                                         checked={checkedTerms.required[term.id] || false}
//                                         onChange={() => handleIndividualCheck(term.id, 'required')} />
//                                     {item.title}
//                                 </label>
//                                 <button onClick={() => handleViewTerm(item)}>보기</button>
//                                 <div className="term-item-content">
//                                     <h3>{item.title}</h3>
//                                     <p>{item.content}</p>
//                                 </div>
//                             </div>
//                         ))}
//                     </section>
//                 ))}

//                 <h2>선택 약관</h2>
//                 {terms.filter(term => term.items.some(item => item.type === 'optional')).map(term => (
//                     <section key={term.id}>
//                         {term.items.filter(item => item.type === 'optional').map((item, index) => (
//                             <div key={index}>
//                                 <label>
//                                     <input type="checkbox"
//                                         checked={checkedTerms.optional[term.id] || false}
//                                         onChange={() => handleIndividualCheck(term.id, 'optional')} />
//                                     {item.title}
//                                 </label>
//                                 <button onClick={() => handleViewTerm(item)}>보기</button>
//                                 <div className="term-item-content">
//                                     <h3>{item.title}</h3>
//                                     <p>{item.content}</p>
//                                 </div>
//                             </div>
//                         ))}
//                     </section>
//                 ))}

//                 <Modal
//                     isOpen={isModalOpen}
//                     onRequestClose={closeModal}
//                     contentLabel="약관 내용"
//                     style={ModalStyles}
//                 >
//                     <TermsModal
//                         title={currentTerm.title}
//                         content={currentTerm.content}
//                         isOpen={isModalOpen}
//                         onClose={() => closeModal()}
//                     />
//                 </Modal>
//                 <TermsAgreementScreen />
//                 <div className="button-group">
//                     <a href="/join" className="button" disabled={!allRequiredTermsAgreed()}>다음</a>
//                     {!allRequiredTermsAgreed() && <p style={{ color: 'red' }}>모든 필수 약관에 동의해야 다음 단계로 진행할 수 있습니다.</p>}
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default Terms;

// import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import {
//   db,
//   collection,
//   getDocs,
//   query,
//   where,
//   orderBy,
//   doc,
//   setDoc,
//   deleteDoc,
//   writeBatch,
// } from '../../firebaseConfig';
// import {
//   Container,
//   Typography,
//   Button,
//   Box,
//   Select,
//   MenuItem,
//   FormControl,
//   InputLabel,
//   Paper,
//   Breadcrumbs,
//   Link,
//   Checkbox,
//   FormControlLabel,
//   Dialog,
//   DialogTitle,
//   DialogContent,
// } from '@mui/material';
// import { useTranslation } from 'react-i18next';

// function Terms() {
//   const { t, i18n } = useTranslation();
//   const currentUser = useSelector((state) => state.auth.currentUser);
//   const [versions, setVersions] = useState([]);
//   const [version, setVersion] = useState(null);
//   const [terms, setTerms] = useState([]);
//   const [checkedTerms, setCheckedTerms] = useState({});
//   //   const [currentTerm, setCurrentTerm] = useState({});
//   const [selectedTermId, setSelectedTermId] = useState(null);
//   const [selectedItemId, setSelectedItemId] = useState(null);
//   const [userAgreements, setUserAgreements] = useState({});
//   const [allChecked, setAllChecked] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [currentTerm, setCurrentTerm] = useState({});

//   useEffect(() => {
//     const fetchVersions = async () => {
//       const termsCollection = collection(db, 'terms');
//       const termsSnapshot = await getDocs(termsCollection);
//       const allVersions = termsSnapshot.docs.map((doc) => doc.data().version);
//       const uniqueVersions = [...new Set(allVersions)].sort((a, b) => b - a);
//       setVersions(uniqueVersions);
//       if (uniqueVersions.length > 0) {
//         setVersion(uniqueVersions[0]);
//       }
//     };
//     fetchVersions();
//   }, []);

//   useEffect(() => {
//     if (version != null) {
//       fetchTerms(version);
//     }
//   }, [version]);

//   const fetchTerms = async (version) => {
//     const termsCollection = collection(db, 'terms');
//     const q = query(
//       termsCollection,
//       where('version', '==', version),
//       orderBy('created_at')
//     );
//     const termsSnapshot = await getDocs(q);
//     const termsData = termsSnapshot.docs.map((doc) => ({
//       ...doc.data(),
//       id: doc.id,
//       items: doc
//         .data()
//         .items.map((item, index) => ({ ...item, id: `${doc.id}_${index}` })),
//     }));
//     let requiredTerms = {};
//     let optionalTerms = {};

//     termsData.forEach((term) => {
//       term.items.forEach((item) => {
//         if (item.type === 'required') {
//           requiredTerms[item.id] = item;
//         } else {
//           optionalTerms[item.id] = item;
//         }
//       });
//     });

//     setCheckedTerms({ required: requiredTerms, optional: optionalTerms });
//     setTerms(termsData);
//     initializeCheckedState(termsData);

//     if (termsData.length > 0) {
//       setSelectedTermId(termsData[0].id); // 첫 번째 용어 자동 선택
//       if (termsData[0].items.length > 0) {
//         setSelectedItemId(termsData[0].items[0].id); // 첫 번째 용어의 첫 번째 아이템 자동 선택
//       }
//     }
//   };

//   // 체크 상태 초기화 및 업데이트
//   const initializeCheckedState = (termsData) => {
//     let initialState = {};
//     termsData.forEach((term) => {
//       term.items.forEach((item) => {
//         initialState[`${term.id}_${item.id}`] = false; // 약관 항목별 고유 ID 생성
//       });
//     });
//     setCheckedTerms(initialState);
//     fetchUserAgreements();
//   };
//   const fetchUserAgreements = async () => {
//     if (currentUser && currentUser.uid) {
//       const userTermsQuery = query(
//         collection(db, 'user_terms'),
//         where('userId', '==', currentUser.uid)
//       );
//       const userTermsSnapshot = await getDocs(userTermsQuery);
//       const agreements = {};
//       userTermsSnapshot.docs.forEach((doc) => {
//         const data = doc.data();
//         const key = `${data.termId}_${data.itemId}`;
//         agreements[key] = true; // Set the agreement to true if found in the database
//       });

//       //   setCheckedTerms((prevState) => ({
//       //     ...prevState,
//       //     ...agreements,

//       //   }));

//       setCheckedTerms((prevState) => {
//         const newState = {
//           ...prevState,
//           ...agreements,
//         };

//         // Check if all are now checked or unchecked after this operation
//         updateAllCheckedState(newState);

//         return newState;
//       });
//     } else {
//       console.error('No current user found, unable to fetch agreements.');
//     }
//   };

//   useEffect(() => {
//     fetchUserAgreements();
//   }, [currentUser, terms]);

//   // 모든 약관이 동의되었는지 확인하는 함수
//   const areAllTermsChecked = () => {
//     return Object.values(checkedTerms).every(Boolean);
//   };
//   // 모든 필수 약관이 동의되었는지 확인하는 함수
//   const allRequiredTermsAgreed = () => {
//     return Object.values(checkedTerms.required).every((val) => val);
//   };
//   // 모든 선택 약관이 동의되었는지 확인하는 함수
//   const allOptionalTermsAgreed = () => {
//     return Object.values(checkedTerms.optional).every((val) => val);
//   };
//   const handleAllCheck = () => {
//     const allChecked = !areAllTermsChecked();
//     // Update every term to the new `allChecked` state
//     const newCheckedTerms = Object.keys(checkedTerms).reduce((acc, termId) => {
//       acc[termId] = allChecked;
//       return acc;
//     }, {});

//     setCheckedTerms(newCheckedTerms);
//     updateAllCheckedState(newCheckedTerms);
//     // Perform the agreement or disagreement for all terms based on `allChecked`
//     if (allChecked) {
//       agreeToAllItemsOfTerm(currentUser?.uid, selectedTermId, newCheckedTerms);
//     } else {
//       disagreeToAllItemsOfTerm(
//         currentUser?.uid,
//         selectedTermId,
//         newCheckedTerms
//       );
//     }
//   };

//   const handleIndividualCheck = async (termId, itemId) => {
//     const itemKey = `${termId}_${itemId}`;
//     // Update the local state to reflect the user's action
//     setCheckedTerms((prevState) => {
//       const newState = {
//         ...prevState,
//         [itemKey]: !prevState[itemKey],
//       };

//       // Check if all are now checked or unchecked after this operation
//       updateAllCheckedState(newState);

//       return newState;
//     });

//     // Since state updates are asynchronous, directly using the updated state can lead to inconsistencies.
//     // Hence, determine the current state of the checkbox and perform the database update accordingly.
//     const currentlyChecked = !checkedTerms[itemKey]; // This gets the intended new state

//     if (currentlyChecked) {
//       await agreeToTerm(currentUser?.uid, termId, itemId);
//     } else {
//       await disagreeToTerm(currentUser?.uid, termId, itemId);
//     }
//   };
//   // Function to update the "all checked" state
//   const updateAllCheckedState = (newCheckedTerms) => {
//     const allChecked = Object.values(newCheckedTerms).every(Boolean);
//     setAllChecked(allChecked);
//   };
//   // 약관 동의 함수
//   async function agreeToTerm(userId, termId, itemId) {
//     const userTermRef = doc(db, 'user_terms', `${userId}_${termId}_${itemId}`);
//     await setDoc(userTermRef, {
//       userId,
//       termId,
//       itemId,
//       agreed_at: new Date(),
//     });
//   }

//   // 약관 철회 함수
//   async function disagreeToTerm(userId, termId, itemId) {
//     const userTermRef = doc(db, 'user_terms', `${userId}_${termId}_${itemId}`);
//     await deleteDoc(userTermRef);
//   }

//   async function agreeToAllItemsOfTerm(userId, termId, newCheckedTerms) {
//     if (!userId || !termId || !newCheckedTerms) {
//       console.error('Invalid input to agreeToAllItemsOfTerm.');
//       return;
//     }

//     const batch = writeBatch(db);
//     const term = terms.find((t) => t.id === termId);
//     if (term) {
//       term.items.forEach((item) => {
//         const itemKey = `${term.id}_${item.id}`;
//         if (newCheckedTerms[itemKey]) {
//           const userTermRef = doc(db, 'user_terms', `${userId}_${itemKey}`);
//           batch.set(userTermRef, {
//             userId,
//             termId: term.id,
//             itemId: item.id,
//             agreed_at: new Date(),
//           });
//         }
//       });

//       try {
//         await batch.commit();
//       } catch (error) {
//         console.error('Error committing batch:', error);
//       }
//     }
//   }
//   async function disagreeToAllItemsOfTerm(userId, termId, newCheckedTerms) {
//     const batch = writeBatch(db);
//     const term = terms.find((t) => t.id === termId);
//     if (term) {
//       term.items.forEach((item) => {
//         const itemKey = `${term.id}_${item.id}`;
//         const userTermRef = doc(db, 'user_terms', `${userId}_${itemKey}`);
//         batch.delete(userTermRef);
//       });

//       try {
//         await batch.commit();
//       } catch (error) {
//         console.error('Error committing batch:', error);
//       }
//     }
//   }

//   async function agreeToAllTerms(userId) {
//     if (!userId || !terms || !checkedTerms) {
//       console.error('Invalid input to agreeToAllTerms.');
//       return;
//     }

//     const batch = writeBatch(db);
//     terms.forEach((term) => {
//       term.items.forEach((item) => {
//         // 각 약관 항목별로 동의 처리
//         const isChecked = checkedTerms[`${term.id}_${item.id}`];

//         if (isChecked) {
//           const userTermRef = doc(
//             db,
//             'user_terms',
//             `${userId}_${term.id}_${item.id}`
//           );
//           batch.set(userTermRef, {
//             userId,
//             termId: term.id,
//             itemId: item.id,
//             agreed_at: new Date(),
//           });
//         }
//       });
//     });

//     try {
//       await batch.commit();
//     } catch (error) {
//       console.error('Error committing batch:', error);
//     }
//   }

//   async function disagreeToAllTerms(userId) {
//     const batch = writeBatch(db);

//     terms.forEach((term) => {
//       term.items.forEach((item) => {
//         // 각 약관 항목별로 동의 철회 처리
//         const userTermRef = doc(
//           db,
//           'user_terms',
//           `${userId}_${term.id}_${item.id}`
//         );
//         batch.delete(userTermRef);
//       });
//     });

//     try {
//       await batch.commit();
//     } catch (error) {
//       console.error('Error committing batch:', error);
//     }
//   }

//   const handleVersionChange = (event) => {
//     setVersion(event.target.value);
//     setSelectedTermId(null);
//     setSelectedItemId(null);
//   };

//   const handleItemClick = (itemId) => {
//     setSelectedItemId(itemId);
//   };

//   const handleCheckChange = (termId, type) => {
//     const updatedCheckedTerms = {
//       ...checkedTerms,
//       [type]: { ...checkedTerms[type], [termId]: !checkedTerms[type][termId] },
//     };
//     setCheckedTerms(updatedCheckedTerms);
//   };

//   const selectedTerm = terms.find((term) => term.id === selectedTermId);
//   const selectedItem = selectedTerm?.items.find(
//     (item) => item.id === selectedItemId
//   );

//   const handleViewTerm = (term) => {
//     if (!term) {
//       console.warn('No term provided to handleViewTerm');
//       return;
//     }

//     // Optionally, you can perform additional logic here.
//     // For example, logging user actions or tracking user engagement with terms.

//     setCurrentTerm(term);
//     openModal();
//   };
//   const openModal = () => {
//     setIsModalOpen(true);
//   };

//   const closeModal = () => {
//     setIsModalOpen(false);
//     setCurrentTerm({}); // Optionally reset the current term when closing the modal.
//   };
//   return (
//     <Container>
//       <Typography variant="h4" gutterBottom>
//         {t('termslist')}
//       </Typography>

//       <Box display="flex" justifyContent="flex-end">
//         <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2, mt: 2 }}>
//           <Link
//             color="inherit"
//             href="/"
//             onClick={(event) => event.preventDefault()}
//           >
//             홈
//           </Link>
//           <Link
//             color="inherit"
//             href="/support"
//             onClick={(event) => event.preventDefault()}
//           >
//             고객센터
//           </Link>
//           <Typography color="textPrimary">약관 및 정책</Typography>
//         </Breadcrumbs>
//       </Box>
//       <FormControl fullWidth margin="normal">
//         <InputLabel>Version</InputLabel>
//         <Select value={version} onChange={handleVersionChange} label="Version">
//           {versions.map((v) => (
//             <MenuItem key={v} value={v}>
//               {v}
//             </MenuItem>
//           ))}
//         </Select>
//       </FormControl>

//       <FormControlLabel
//         control={
//           <Checkbox
//             checked={allChecked}
//             onChange={handleAllCheck}
//             name="allTerms"
//           />
//         }
//         label="모두 동의합니다."
//       />

//       <Typography variant="h6">필수 약관</Typography>
//       {terms
//         .filter((term) => term.items.some((item) => item.type === 'required'))
//         .map((term) => (
//           <Box key={term.id} sx={{ mb: 2 }}>
//             {term.items
//               .filter((item) => item.type === 'required')
//               .map((item, index) => (
//                 <Box
//                   key={index}
//                   sx={{
//                     display: 'flex',
//                     alignItems: 'center',
//                     justifyContent: 'space-between',
//                   }}
//                 >
//                   <FormControlLabel
//                     control={
//                       <Checkbox
//                         checked={checkedTerms[`${term.id}_${item.id}`] || false}
//                         onChange={() => handleIndividualCheck(term.id, item.id)}
//                       />
//                     }
//                     label={item.title}
//                   />
//                   <Button onClick={() => handleViewTerm(item)}>보기</Button>
//                 </Box>
//               ))}
//           </Box>
//         ))}

//       <Typography variant="h6">선택 약관</Typography>
//       {terms
//         .filter((term) => term.items.some((item) => item.type === 'optional'))
//         .map((term) => (
//           <Box key={term.id} sx={{ mb: 2 }}>
//             {term.items
//               .filter((item) => item.type === 'optional')
//               .map((item, index) => (
//                 <Box
//                   key={index}
//                   sx={{
//                     display: 'flex',
//                     alignItems: 'center',
//                     justifyContent: 'space-between',
//                   }}
//                 >
//                   <FormControlLabel
//                     control={
//                       <Checkbox
//                         checked={checkedTerms[`${term.id}_${item.id}`] || false}
//                         onChange={() => handleIndividualCheck(term.id, item.id)}
//                       />
//                     }
//                     label={item.title}
//                   />
//                   <Button onClick={() => handleViewTerm(item)}>보기</Button>
//                 </Box>
//               ))}
//           </Box>
//         ))}
//       <Dialog open={isModalOpen} onClose={closeModal}>
//         <DialogTitle>{currentTerm.title}</DialogTitle>
//         <DialogContent>
//           <Box
//             sx={{
//               mt: 2,
//               p: 2,
//               whiteSpace: 'pre-wrap',
//               border: '1px solid #ccc',
//               borderRadius: '4px',
//             }}
//           >
//             {/* <Typography
//               variant="subtitle1"
//               sx={{ textDecoration: 'underline' }}
//             >
//               {currentTerm.title}
//             </Typography> */}
//             <Typography component="div" sx={{ whiteSpace: 'pre-wrap' }}>
//               {currentTerm.content}
//             </Typography>
//           </Box>
//         </DialogContent>
//         <Button onClick={closeModal} color="primary" autoFocus>
//           {t('close')}
//         </Button>
//       </Dialog>
//     </Container>
//   );
// }

// export default Terms;

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  db,
  collection,
  getDocs,
  query,
  where,
  orderBy,
  doc,
  getDoc,
  setDoc,
  deleteDoc,
  writeBatch,
} from '../../firebaseConfig';
import {
  Container,
  Typography,
  Button,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Breadcrumbs,
  Link,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { googleTranslate } from '../../Utils/GoogleTranslation';

function TermsAgreementScreen() {
  const { t, i18n } = useTranslation();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const [versions, setVersions] = useState([]);
  const [version, setVersion] = useState(null);
  const [terms, setTerms] = useState([]);
  const [checkedTerms, setCheckedTerms] = useState({});
  const [selectedTermId, setSelectedTermId] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [allChecked, setAllChecked] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentTerm, setCurrentTerm] = useState({});

  useEffect(() => {
    const fetchVersions = async () => {
      const termsCollection = collection(db, 'terms');
      const termsSnapshot = await getDocs(termsCollection);
      const allVersions = termsSnapshot.docs.map((doc) => doc.data().version);
      const uniqueVersions = [...new Set(allVersions)].sort((a, b) => b - a);
      setVersions(uniqueVersions);
      if (uniqueVersions.length > 0) {
        setVersion(uniqueVersions[0]);
      }
    };
    fetchVersions();
  }, []);

  useEffect(() => {
    if (version != null) {
      fetchTerms(version);
    }
  }, [version]);

  // const fetchTerms = async (version) => {
  //   const termsCollection = collection(db, 'terms');
  //   const q = query(
  //     termsCollection,
  //     where('version', '==', version),
  //     orderBy('created_at')
  //   );
  //   const termsSnapshot = await getDocs(q);
  //   // const termsData = termsSnapshot.docs.map((doc) => ({
  //   //   ...doc.data(),
  //   //   id: doc.id,
  //   //   items: doc
  //   //     .data()
  //   //     .items.map((item, index) => ({ ...item, id: `${doc.id}_${index}` })),
  //   // }));
  //   const termsData = await Promise.all(
  //     termsSnapshot.docs.map(async (doc) => {
  //       const translatedItems = await Promise.all(
  //         doc.data().items.map(async (item, index) => {
  //           const translatedTitle = await googleTranslate(item.title, i18n);
  //           const translatedContent = await googleTranslate(item.content, i18n);
  //           return {
  //             ...item,
  //             title: translatedTitle,
  //             content: translatedContent,
  //             id: `${doc.id}_${index}`,
  //           };
  //         })
  //       );

  //       const translatedTersmName = await googleTranslate(
  //         doc.data().name,
  //         i18n
  //       );

  //       return {
  //         ...doc.data(),
  //         name: translatedTersmName,
  //         id: doc.id,
  //         items: translatedItems,
  //       };
  //     })
  //   );
  //   setTerms(termsData);
  //   initializeCheckedState(termsData);
  // };

  const fetchTerms = async (version) => {
    try {
      const termsCollection = collection(db, 'terms');
      const q = query(
        termsCollection,
        where('version', '==', version),
        orderBy('created_at')
      );
      const termsSnapshot = await getDocs(q);

      const termsData = await Promise.all(
        termsSnapshot.docs.map(async (doc) => {
          const translatedItems = await Promise.all(
            doc.data().items.map(async (item, index) => {
              try {
                const translatedTitle = await googleTranslate(item.title, i18n);
                const translatedContent = await googleTranslate(
                  item.content,
                  i18n
                );
                return {
                  ...item,
                  title: translatedTitle,
                  content: translatedContent,
                  id: `${doc.id}_${index}`,
                };
              } catch (translateError) {
                console.error('Error translating item:', translateError);
                return {
                  ...item,
                  id: `${doc.id}_${index}`,
                  translationError: true, // 추가적인 오류 플래그
                };
              }
            })
          );

          try {
            const translatedTermsName = await googleTranslate(
              doc.data().name,
              i18n
            );
            return {
              ...doc.data(),
              name: translatedTermsName,
              id: doc.id,
              items: translatedItems,
            };
          } catch (translateError) {
            console.error('Error translating terms name:', translateError);
            return {
              ...doc.data(),
              id: doc.id,
              items: translatedItems,
              translationError: true, // 추가적인 오류 플래그
            };
          }
        })
      );

      setTerms(termsData);
      initializeCheckedState(termsData);
    } catch (error) {
      if (
        error.code === 'failed-precondition' ||
        error.code === 'unavailable'
      ) {
        console.error('Firestore index error:', error.message);
        // 필요한 경우 사용자에게 알림을 표시하거나 추가 처리를 수행할 수 있습니다.
      } else {
        console.error('Error fetching terms:', error);
      }
    }
  };

  const initializeCheckedState = async (termsData) => {
    let initialState = {};
    termsData.forEach((term) => {
      term.items.forEach((item) => {
        initialState[`${term.id}_${item.id}`] = false; // 약관 항목별 고유 ID 생성
      });
    });
    setCheckedTerms(initialState);
    await fetchUserAgreements(termsData);
  };

  const fetchUserAgreements = async (termsData) => {
    if (currentUser && currentUser.uid) {
      const agreements = {};

      for (const term of termsData) {
        const userTermsCollection = collection(
          db,
          'users',
          currentUser.uid,
          'terms',
          term.id,
          'items'
        );
        const userTermsSnapshot = await getDocs(userTermsCollection);

        if (!userTermsSnapshot.empty) {
          userTermsSnapshot.docs.forEach((itemDoc) => {
            const itemId = itemDoc.id;
            agreements[`${term.id}_${itemId}`] = true;
          });
        }
      }

      setCheckedTerms((prevState) => {
        const newState = {
          ...prevState,
          ...agreements,
        };
        updateAllCheckedState(newState);
        return newState;
      });
    } else {
      console.error('No current user found, unable to fetch agreements.');
    }
  };

  const areAllTermsChecked = () => {
    return Object.values(checkedTerms).every(Boolean);
  };

  const handleAllCheck = async () => {
    const allChecked = !areAllTermsChecked();
    const updatedCheckedTerms = {};
    terms.forEach((term) => {
      term.items.forEach((item) => {
        updatedCheckedTerms[`${term.id}_${item.id}`] = allChecked;
      });
    });
    setCheckedTerms(updatedCheckedTerms);
    setAllChecked(allChecked);

    if (allChecked) {
      await agreeToAllItems(currentUser?.uid, updatedCheckedTerms);
    } else {
      await disagreeToAllItems(currentUser?.uid, updatedCheckedTerms);
    }
  };

  const handleIndividualCheck = async (termId, itemId) => {
    const itemKey = `${termId}_${itemId}`;
    setCheckedTerms((prevState) => {
      const newState = {
        ...prevState,
        [itemKey]: !prevState[itemKey],
      };
      updateAllCheckedState(newState);
      return newState;
    });

    const currentlyChecked = !checkedTerms[itemKey];
    if (currentlyChecked) {
      await agreeToTerm(currentUser?.uid, termId, itemId);
    } else {
      await disagreeToTerm(currentUser?.uid, termId, itemId);
    }
  };

  const updateAllCheckedState = (newCheckedTerms) => {
    const allChecked = Object.values(newCheckedTerms).every(Boolean);
    setAllChecked(allChecked);
  };

  async function agreeToTerm(userId, termId, itemId) {
    const userTermRef = doc(
      db,
      'users',
      userId,
      'terms',
      termId,
      'items',
      itemId
    );
    await setDoc(userTermRef, {
      userId,
      termId,
      itemId,
      agreed_at: new Date(),
    });
  }

  async function disagreeToTerm(userId, termId, itemId) {
    const userTermRef = doc(
      db,
      'users',
      userId,
      'terms',
      termId,
      'items',
      itemId
    );
    await deleteDoc(userTermRef);
  }

  async function agreeToAllItems(userId, newCheckedTerms) {
    if (!userId || !newCheckedTerms) {
      console.error('Invalid input to agreeToAllItems.');
      return;
    }

    const batch = writeBatch(db);
    terms.forEach((term) => {
      term.items.forEach((item) => {
        const itemKey = `${term.id}_${item.id}`;
        if (newCheckedTerms[itemKey]) {
          const userTermRef = doc(
            db,
            'users',
            userId,
            'terms',
            term.id,
            'items',
            item.id
          );
          batch.set(userTermRef, {
            userId,
            termId: term.id,
            itemId: item.id,
            agreed_at: new Date(),
          });
        }
      });
    });

    try {
      await batch.commit();
    } catch (error) {
      console.error('Error committing batch:', error);
    }
  }

  async function disagreeToAllItems(userId, newCheckedTerms) {
    const batch = writeBatch(db);
    terms.forEach((term) => {
      term.items.forEach((item) => {
        const itemKey = `${term.id}_${item.id}`;
        const userTermRef = doc(
          db,
          'users',
          userId,
          'terms',
          term.id,
          'items',
          item.id
        );
        batch.delete(userTermRef);
      });
    });

    try {
      await batch.commit();
    } catch (error) {
      console.error('Error committing batch:', error);
    }
  }

  const handleVersionChange = (event) => {
    setVersion(event.target.value);
    setSelectedTermId(null);
    setSelectedItemId(null);
  };

  const handleItemClick = (itemId) => {
    setSelectedItemId(itemId);
  };

  const handleViewTerm = (term) => {
    if (!term) {
      console.warn('No term provided to handleViewTerm');
      return;
    }
    setCurrentTerm(term);
    openModal();
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentTerm({});
  };

  const selectedTerm = terms.find((term) => term.id === selectedTermId);
  const selectedItem = selectedTerm?.items.find(
    (item) => item.id === selectedItemId
  );

  return (
    <Container sx={{ mt: 5 }}>
      <Typography variant="h4" gutterBottom>
        {t('termslist')}
      </Typography>
      <Box display="flex" justifyContent="flex-end">
        <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2, mt: 2 }}>
          <Link
            color="inherit"
            href="/"
            onClick={(event) => event.preventDefault()}
          >
            {t('home')}
          </Link>
          <Link
            color="inherit"
            href="/support"
            onClick={(event) => event.preventDefault()}
          >
            {t('customer-service.title')}
          </Link>
          <Typography color="textPrimary">
            {t('terms-and-policies.title')}
          </Typography>
        </Breadcrumbs>
      </Box>
      <FormControl fullWidth margin="normal">
        <InputLabel>Version</InputLabel>
        <Select
          value={version || ''}
          onChange={handleVersionChange}
          label="Version"
        >
          {versions.map((v) => (
            <MenuItem key={v} value={v}>
              {v}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControlLabel
        control={
          <Checkbox
            checked={allChecked}
            onChange={handleAllCheck}
            name="allTerms"
          />
        }
        label={t('all-agree')}
      />
      <Typography variant="h6">
        {t('terms-and-policies.mandatory-terms')}
      </Typography>
      {terms
        .filter((term) => term.items.some((item) => item.type === 'required'))
        .map((term) => (
          <Box key={term.id} sx={{ mb: 2 }}>
            {term.items
              .filter((item) => item.type === 'required')
              .map((item) => (
                <Box
                  key={item.id}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkedTerms[`${term.id}_${item.id}`] || false}
                        onChange={() => handleIndividualCheck(term.id, item.id)}
                      />
                    }
                    label={item.title}
                  />
                  <Button onClick={() => handleViewTerm(item)}>보기</Button>
                </Box>
              ))}
          </Box>
        ))}
      <Typography variant="h6">{t('optional-terms')}</Typography>
      {terms
        .filter((term) => term.items.some((item) => item.type === 'optional'))
        .map((term) => (
          <Box key={term.id} sx={{ mb: 2 }}>
            {term.items
              .filter((item) => item.type === 'optional')
              .map((item) => (
                <Box
                  key={item.id}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkedTerms[`${term.id}_${item.id}`] || false}
                        onChange={() => handleIndividualCheck(term.id, item.id)}
                      />
                    }
                    label={item.title}
                  />
                  <Button onClick={() => handleViewTerm(item)}>보기</Button>
                </Box>
              ))}
          </Box>
        ))}
      <Dialog open={isModalOpen} onClose={closeModal}>
        <DialogTitle>{currentTerm.title}</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              mt: 2,
              p: 2,
              whiteSpace: 'pre-wrap',
              border: '1px solid #ccc',
              borderRadius: '4px',
            }}
          >
            {/* <Typography
              variant="subtitle1"
              sx={{ textDecoration: 'underline' }}
            >
              {currentTerm.title}
            </Typography> */}
            <Typography component="div" sx={{ whiteSpace: 'pre-wrap' }}>
              {currentTerm.content}
            </Typography>
          </Box>
        </DialogContent>
        <Button onClick={closeModal} color="primary" autoFocus>
          {t('close')}
        </Button>
      </Dialog>
    </Container>
  );
}

export default TermsAgreementScreen;
