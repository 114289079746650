// import React, { useEffect, useState } from 'react';
// import { db } from './firebaseConfig';
// import { collection, query, getDocs } from 'firebase/firestore';
// import {
//   Box,
//   Typography,
//   List,
//   ListItem,
//   ListItemText,
//   Paper,
//   Rating,
//   CircularProgress,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogContentText,
//   DialogActions,
//   Button,
// } from '@mui/material';
// import { useTranslation } from 'react-i18next';

// const UserRatings = ({ userId }) => {
//   const { t } = useTranslation();
//   const [ratings, setRatings] = useState([]);
//   const [averageRatings, setAverageRatings] = useState({
//     overallScore: 0,
//   });
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [open, setOpen] = useState(false);

//   useEffect(() => {
//     if (!userId) {
//       setError(t('userRatings.invalidUserId'));
//       setLoading(false);
//       return;
//     }

//     const fetchRatings = async () => {
//       try {
//         const q = query(collection(db, `users/${userId}/ratings`));
//         const querySnapshot = await getDocs(q);
//         const ratingsData = querySnapshot.docs.map((doc) => doc.data());
//         setRatings(ratingsData);
//         if (ratingsData.length > 0) {
//           const avgRatings = ratingsData.reduce(
//             (acc, rating) => {
//               acc.overallScore += parseFloat(rating.overallScore);
//               return acc;
//             },
//             {
//               overallScore: 0,
//             }
//           );

//           avgRatings.overallScore =
//             avgRatings.overallScore / ratingsData.length;
//           setAverageRatings(avgRatings);
//         }
//       } catch (error) {
//         setError(
//           t('userRatings.errorFetchingRatings', { error: error.message })
//         );
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchRatings();
//   }, [userId, t]);

//   const handleClickOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };

//   if (loading) {
//     return (
//       <Box
//         display="flex"
//         justifyContent="center"
//         alignItems="center"
//         minHeight="200px"
//       >
//         <CircularProgress />
//       </Box>
//     );
//   }

//   if (error) {
//     return (
//       <Box
//         display="flex"
//         justifyContent="center"
//         alignItems="center"
//         minHeight="200px"
//       >
//         <Typography variant="body1" color="error">
//           {error}
//         </Typography>
//       </Box>
//     );
//   }

//   return (
//     <Box>
//       <Box onClick={handleClickOpen} sx={{ cursor: 'pointer' }}>
//         <Typography variant="h6" align="center">
//           {t('userRatings.averageRatings')}
//         </Typography>
//         <List>
//           <ListItem>
//             <ListItemText
//               primary={
//                 <>
//                   <Typography variant="body2">
//                     {t('userRatings.overallScore')}:{' '}
//                     {averageRatings.overallScore.toFixed(1)}
//                   </Typography>
//                   <Rating
//                     value={averageRatings.overallScore}
//                     readOnly
//                     precision={0.5}
//                   />
//                 </>
//               }
//               sx={{ textAlign: 'center' }}
//             />
//           </ListItem>
//         </List>
//       </Box>

//       <Dialog open={open} onClose={handleClose}>
//         <DialogTitle>{t('userRatings.userRatings')}</DialogTitle>
//         <DialogContent>
//           <DialogContentText>
//             <Typography variant="h6" align="center">
//               {t('userRatings.averageRatings')}
//             </Typography>
//             <List>
//               <ListItem>
//                 <ListItemText
//                   primary={
//                     <>
//                       <Typography variant="body2">
//                         {t('userRatings.overallScore')}:{' '}
//                         {averageRatings.overallScore.toFixed(1)}
//                       </Typography>
//                       <Rating
//                         value={averageRatings.overallScore}
//                         readOnly
//                         precision={0.5}
//                       />
//                     </>
//                   }
//                   sx={{ textAlign: 'center' }}
//                 />
//               </ListItem>
//             </List>
//           </DialogContentText>
//           <Box mt={4}>
//             <Typography variant="h6" align="center">
//               {t('userRatings.userReviews')}
//             </Typography>
//             <List>
//               {ratings.map((rating, index) => (
//                 <Paper key={index} sx={{ marginBottom: 2, padding: 2 }}>
//                   <ListItem>
//                     <ListItemText
//                       primary={t('userRatings.ratingBy', {
//                         userId: rating.raterUserId,
//                       })}
//                     />
//                   </ListItem>
//                   {[
//                     'accuracy',
//                     'responseTime',
//                     'friendliness',
//                     'punctuality',
//                     'overallSatisfaction',
//                   ].map((type) => (
//                     <ListItem key={type}>
//                       <ListItemText
//                         primary={
//                           <>
//                             <Typography variant="body2">
//                               {t(`userRatings.${type}`)}: {rating[type]}
//                             </Typography>
//                             <Rating
//                               value={rating[type]}
//                               readOnly
//                               precision={0.5}
//                             />
//                           </>
//                         }
//                       />
//                     </ListItem>
//                   ))}
//                   <ListItem>
//                     <ListItemText
//                       primary={
//                         <>
//                           <Typography variant="body2">
//                             {t('userRatings.overallScore')}:{' '}
//                             {rating.overallScore}
//                           </Typography>
//                           <Rating
//                             value={rating.overallScore}
//                             readOnly
//                             precision={0.5}
//                           />
//                         </>
//                       }
//                     />
//                   </ListItem>
//                   <ListItem>
//                     <ListItemText
//                       primary={t('userRatings.comment', {
//                         comment: rating.comment,
//                       })}
//                     />
//                   </ListItem>
//                 </Paper>
//               ))}
//             </List>
//           </Box>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleClose} color="primary">
//             {t('close')}
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </Box>
//   );
// };

// export default UserRatings;

import React, { useEffect, useState } from 'react';
import { db } from './firebaseConfig';
import { collection, query, getDocs, doc, getDoc } from 'firebase/firestore';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Paper,
  Rating,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const UserRatings = ({ userId }) => {
  const { t } = useTranslation();
  const [ratings, setRatings] = useState([]);
  const [averageRatings, setAverageRatings] = useState({
    overallScore: 0,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!userId) {
      setError(t('userRatings.invalidUserId'));
      setLoading(false);
      return;
    }

    const fetchRatings = async () => {
      try {
        const q = query(collection(db, `users/${userId}/ratings`));
        const querySnapshot = await getDocs(q);
        const ratingsData = await Promise.all(
          querySnapshot.docs.map(async (docData) => {
            const rating = docData.data();
            const raterDoc = await getDoc(
              doc(db, `users/${rating.raterUserId}`)
            );
            const raterData = raterDoc.exists() ? raterDoc.data() : {};
            return { ...rating, raterDisplayName: raterData.displayName };
          })
        );
        setRatings(ratingsData);
        if (ratingsData.length > 0) {
          const avgRatings = ratingsData.reduce(
            (acc, rating) => {
              acc.overallScore += parseFloat(rating.overallScore);
              return acc;
            },
            {
              overallScore: 0,
            }
          );

          avgRatings.overallScore =
            avgRatings.overallScore / ratingsData.length;
          setAverageRatings(avgRatings);
        }
      } catch (error) {
        setError(
          t('userRatings.errorFetchingRatings', { error: error.message })
        );
      } finally {
        setLoading(false);
      }
    };

    fetchRatings();
  }, [userId, t]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px"
      >
        <Typography variant="body1" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Box onClick={handleClickOpen} sx={{ cursor: 'pointer' }}>
        <Typography variant="h6" align="center">
          {t('userRatings.averageRatings')}
        </Typography>
        <List>
          <ListItem>
            <ListItemText
              primary={
                <>
                  <Typography variant="body2">
                    {t('userRatings.overallScore')}:{' '}
                    {averageRatings.overallScore.toFixed(1)}
                  </Typography>
                  <Rating
                    value={averageRatings.overallScore}
                    readOnly
                    precision={0.5}
                  />
                </>
              }
              sx={{ textAlign: 'center' }}
            />
          </ListItem>
        </List>
      </Box>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{t('userRatings.userRatings')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant="h6" align="center">
              {t('userRatings.averageRatings')}
            </Typography>
            <List>
              <ListItem>
                <ListItemText
                  primary={
                    <>
                      <Typography variant="body2">
                        {t('userRatings.overallScore')}:{' '}
                        {averageRatings.overallScore.toFixed(1)}
                      </Typography>
                      <Rating
                        value={averageRatings.overallScore}
                        readOnly
                        precision={0.5}
                      />
                    </>
                  }
                  sx={{ textAlign: 'center' }}
                />
              </ListItem>
            </List>
          </DialogContentText>
          <Box mt={4}>
            <Typography variant="h6" align="center">
              {t('userRatings.userReviews')}
            </Typography>
            <List>
              {ratings.map((rating, index) => (
                <Paper key={index} sx={{ marginBottom: 2, padding: 2 }}>
                  <ListItem>
                    <ListItemText
                      primary={t('userRatings.ratingBy', {
                        userId: rating.raterDisplayName,
                      })}
                    />
                  </ListItem>
                  {[
                    'accuracy',
                    'responseTime',
                    'friendliness',
                    'punctuality',
                    'overallSatisfaction',
                  ].map((type) => (
                    <ListItem key={type}>
                      <ListItemText
                        primary={
                          <>
                            <Typography variant="body2">
                              {t(`userRatings.${type}`)}: {rating[type]}
                            </Typography>
                            <Rating
                              value={rating[type]}
                              readOnly
                              precision={0.5}
                            />
                          </>
                        }
                      />
                    </ListItem>
                  ))}
                  <ListItem>
                    <ListItemText
                      primary={
                        <>
                          <Typography variant="body2">
                            {t('userRatings.overallScore')}:{' '}
                            {rating.overallScore}
                          </Typography>
                          <Rating
                            value={rating.overallScore}
                            readOnly
                            precision={0.5}
                          />
                        </>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={t('userRatings.comment', {
                        comment: rating.comment,
                      })}
                    />
                  </ListItem>
                </Paper>
              ))}
            </List>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('close')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default UserRatings;
