// // KakaoRedirect.js
// import React, { useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { firebaseApp, db, auth, analytics, storage, functions } from './js/firebase';
// import { httpsCallable } from 'firebase/functions';
// import { useParams, useLocation } from 'react-router-dom';
// import axios from 'axios';

// function KakaoRedirect() {

//     const navigate = useNavigate();
//     const location = useLocation();
//     useEffect(() => {

//         const query = new URLSearchParams(location.search);
//         const authorizationCode = query.get('code');

//         if (!authorizationCode) {
//             console.error('Authorization code not found');
//             alert("Authorization code not found");
//             return;
//         }
//         alert("1.authcode:" + authorizationCode);
//         // Firebase Cloud Function 호출

//         // axios.post('http://127.0.0.1:5001/apple-market-af8c6/asia-northeast3/getCustomToken/', { code: authorizationCode })
//         //     .then(response => {
//         //         const customToken = response.data.firebaseToken;
//         //         alert("2.firebaseToken(signInWithCustomToken):" + authorizationCode);
//         //         return auth.signInWithCustomToken(customToken);
//         //     })
//         //     .catch(error => {
//         //         console.error('Error:', error);
//         //     });

//         const getCustomToken = httpsCallable(functions, 'getCustomToken');
//         if (!getCustomToken) {
//             throw new Error('getCustomToken function is not available');
//         }

//         getCustomToken({ code: authorizationCode })
//             .then(result => {
//                 const customToken = result.data.firebaseToken;
//                 alert("2.firebaseToken(signInWithCustomToken):" + authorizationCode);
//                 return auth.signInWithCustomToken(customToken);
//             })
//             .then(() => {
//                 alert("3:navigate(/)")
//                 navigate('/');  // 인증 후 원하는 페이지로 리디렉션
//             })
//             .catch(error => {
//                 console.error('Error:', error);
//                 alert("4.error:" + error)
//                 navigate('/');
//             });

//     }, [navigate]);

//     return (
//         <div>
//             Kakao 로그인 처리중...
//         </div>
//     );
// }

// export default KakaoRedirect;

import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import {
  db,
  updateDoc,
  setDoc,
  getDoc,
  updateProfile,
  doc,
  auth,
  functions,
  getKakaoToken,
  signInWithCustomToken,
  linkWithCredential,
  EmailAuthProvider,
  serverTimestamp,
} from './firebaseConfig';
// import { httpsCallable } from 'firebase/functions';
import useMessageBox from './Common/useMessageBox';
import axios from 'axios';

function KakaoRedirect() {
  const [MessageBoxInform, MessageBoxConfirm, MessageBoxRender] =
    useMessageBox();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    async function authenticateUser() {
      const authorizationCode = getParamAuthorizationCode(location.search);

      if (!authorizationCode) {
        console.error('Authorization code not found');
        await MessageBoxInform('Authorization code not found');
        return;
      }

      try {
        const kakaoUserInfo = await fetchKakaoToken(authorizationCode);
        await signInWithFirebase(kakaoUserInfo);
        navigate('/');
      } catch (error) {
        console.error('Error:', error);
        await MessageBoxInform('Error during authentication:' + error);
        navigate('/');
      }
    }

    authenticateUser();
  }, [navigate, location.search]);

  function getParamAuthorizationCode(search) {
    const query = new URLSearchParams(search);
    return query.get('code');
  }

  async function fetchKakaoToken(authorizationCode) {
    try {
      // Assuming MessageBoxInform is a function to log or display messages to the user
      // await MessageBoxInform('Fetching Kakao Token with authCode:', authorizationCode);
      const result = await getKakaoTokenFireFunc(authorizationCode);

      // Validate the result to ensure the expected structure is present
      if (!result || !result.firebaseToken) {
        throw new Error(
          'The server response did not include a Firebase token.'
        );
      }
      return result; // Successfully retrieved the token
    } catch (error) {
      console.error('Error retrieving custom token:', error);

      let errorMessage = 'An unexpected error occurred during token retrieval.';
      if (error.message.includes('Network response was not ok')) {
        errorMessage =
          'Failed to connect to the server. Please check your internet connection and try again.';
      } else if (error.message.includes('Failed to parse JSON response')) {
        errorMessage =
          'There was a problem parsing server response. Please try again later.';
      } else if (
        error.message.includes(
          'The server response did not include a Firebase token.'
        )
      ) {
        errorMessage =
          'Failed to obtain a Firebase token from the server. Please try again later.';
      } else {
        // 여기서는 error.message를 직접 사용하는 대신, 사용자 친화적인 메시지를 제공하는 것이 좋습니다.
        // 예를 들어, 구체적인 오류 메시지를 로깅하고, 사용자에게는 더 일반적인 오류 메시지를 보여줍니다.
        // 그러나 여기에서는 예시를 단순화하기 위해 error.message를 직접 사용합니다.
        errorMessage = error.message;
      }

      // Attempt to display the error message to the user
      try {
        await MessageBoxInform(errorMessage);
      } catch (displayError) {
        console.error('Error displaying the message box:', displayError);
        // Consider how to handle MessageBoxInform failures. Depending on application requirements,
        // you may want to log this error or inform the user through another mechanism.
      }

      // It might be beneficial to encapsulate the error within a custom Error object
      // if additional context or handling is required by the caller.
      throw new Error(errorMessage);
    }
  }

  const getKakaoTokenFireFunc = async (authorizationCode) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_KAKAO_ACCESS_TOKEN_FIREFUNC_URL,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            code: authorizationCode,
          }),
          timeout: 10000, // Timeout after 10000ms or 10 seconds
        }
      );

      if (!response.ok) {
        // Check if the response was successful (status in the range 200-299)
        // const errorText = await response.text(); // Attempt to read response text
        // throw new Error(`Network response was not ok (status: ${response.status}): ${errorText}`);
        throw new Error(
          `Network response was not ok (status: ${response.status})`
        );
      }

      let data;
      try {
        data = await response.json(); // Attempt to parse JSON
      } catch (jsonError) {
        throw new Error('Failed to parse JSON response: ' + jsonError.message);
      }

      return data;
    } catch (error) {
      console.error('Error fetching Kakao access token:', error);
      throw error; // Re-throwing the error to be handled by the caller
    }
  };

  async function signInWithFirebase(userInfo) {
    try {
      // Sign in with custom token
      const firebaseCustomToken = userInfo.firebaseToken;
      const userCredential = await signInWithCustomToken(
        auth,
        firebaseCustomToken
      );
      const user = userCredential.user;

      await handleUserLogin(user, 'kakao.com');
      // await linkKakaoAccount(firebaseCustomToken);
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const handleUserLogin = async (user, providerId) => {
    const email = user.email;
    const displayName = user.displayName;

    const userRef = doc(db, 'users', user.uid);
    const updates = {
      email: email,
      displayName: displayName,
      providerId: providerId,
      setPassword: false,
    };

    const userDoc = await getDoc(userRef);
    if (userDoc.exists()) {
      await updateDoc(userRef, updates);
    } else {
      await setDoc(userRef, updates, { merge: true });
    }
    await updateProfile(user, {
      displayName: displayName,
      photoURL: user.photoURL,
    });
    // await updateUserProviderData(user, providerId);
    await updateLastLogin(user.uid);
    // Dispatch user data to Redux store
    // dispatch(setUser(user));
    navigate('/');
  };
  // 사용자 로그인 후 providerData 업데이트 함수
  const updateUserProviderData = async (user, providerId) => {
    try {
      await updateProfile(user, {
        displayName: user.displayName,
        photoURL: user.photoURL,
        providerData: [
          {
            providerId: providerId,
            uid: user.uid,
            displayName: user.displayName,
            email: user.email,
            phoneNumber: user.phoneNumber,
            photoURL: user.photoURL,
          },
        ],
      });
      console.log('User providerData updated successfully');
    } catch (error) {
      console.error('Error updating user providerData:', error);
    }
  };
  async function updateLastLogin(userId) {
    const userRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userRef);
    const updates = { lastLogin: serverTimestamp() };
    if (userDoc.exists()) {
      updateDoc(userRef, updates)
        .then(() => {
          console.log('Last login time updated');
        })
        .catch((error) => {
          console.error('Error updating last login time:', error);
        });
    } else {
      setDoc(userRef, updates, { merge: true })
        .then(() => {
          console.log('Last login time updated');
        })
        .catch((error) => {
          console.error('Error updating last login time:', error);
        });
    }
  }
  // 가정: 사용자가 이미 로그인되어 있고, 카카오 로그인으로부터 액세스 토큰을 획득했다고 가정
  async function linkKakaoAccount(firebaseCustomToken) {
    try {
      // 백엔드 서버를 통해 카카오 액세스 토큰으로부터 Firebase 사용자 정의 토큰을 획득

      // Firebase 사용자 정의 토큰으로 인증
      const userCredential = await signInWithCustomToken(
        auth,
        firebaseCustomToken
      );

      // 기존 Firebase 계정에 카카오 계정 연결
      const kakaoCredential = auth
        .OAuthProvider('kakao.com')
        .credential(firebaseCustomToken);
      await auth.currentUser.linkWithCredential(kakaoCredential);

      console.log('카카오 계정이 기존 계정에 연결되었습니다.');
    } catch (error) {
      console.error('카카오 계정 연결 오류:', error);
    }
  }
  return (
    <div>
      {MessageBoxRender()}
      Kakao 로그인 처리중...
    </div>
  );
}

export default KakaoRedirect;
