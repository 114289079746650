import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import moment from 'moment';

// Import the locales you need
import 'moment/locale/ko';
import 'moment/locale/ja';
import 'moment/locale/zh-cn';

import en from './i18n/en.json';
import ko from './i18n/ko.json';
import ja from './i18n/ja.json';
import zh from './i18n/zh.json';

const defaultLanguage = process.env.REACT_APP_DEFAULT_LANGUAGE || 'en';
// Get the saved language from local storage or fallback to 'en'
const savedLanguage = localStorage.getItem('language') || defaultLanguage;
i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: en,
      },
      ko: {
        translation: ko,
      },
      ja: {
        translation: ja,
      },
      zh: {
        translation: zh,
      },
    },
    fallbackLng: savedLanguage,
    lng: savedLanguage,
    debug: true,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
    detection: {
      order: ['localStorage', 'navigator'],
      caches: ['localStorage'],
    },
  });
// Listen for language changes and update moment locale
i18n.on('languageChanged', (lng) => {
  moment.locale(lng === 'zh' ? 'zh-cn' : lng); // Use 'zh-cn' for Chinese locale
});
export default i18n;
