// import React, { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// // import { placeOrder, cancelOrder, returnOrder }from '../Redux/reducers/orderReducer';
// import DateFormatter from '../Utils/DateFormatter';
// import {
//   arrayUnion,
//   collection,
//   db,
//   doc,
//   getDoc,
//   getDocs,
//   setDoc,
//   updateDoc,
//   runTransaction,
// } from '../firebaseConfig'; // Assuming you've updated the firebaseConfig file to v9
// import './css/Purshase.css';

// import StorePoints from './Promotion/Points/StorePoints';
// import useStoreUserActivity from './useStoreUserActivity';
// import useCoupon from './Promotion/Coupon/UseCoupon';
// import useOrder from './StoreAdmin/useOrder';
// import useMessageBox from '../Common/useMessageBox';
// import UserAddress from '../UserAddress';
// import TossPayments from './Payment/Toss/TossPayments';
// import {
//   Button,
//   Select,
//   MenuItem,
//   FormControl,
//   InputLabel,
//   CircularProgress,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
// } from '@mui/material';

// const StoreProductPurchase = ({ storeId, product, userId }) => {
//   // const productId = props.product.id;
//   const [selectedAddress, setSelectedAddress] = useState(null);
//   const [userData, setUserData] = useState({});
//   const [usedPoints, setUsedPoints] = useState(0);
//   const [orderId, setOrderId] = useState('');
//   const [loading, setLoading] = useState(false);
//   const [paymentData, setPaymentData] = useState({});
//   const dispatch = useDispatch();
//   const currentUser = useSelector((state) => state.auth.currentUser);
//   const [MessageBoxInform, MessageBoxConfirm, MessageBoxRender] =
//     useMessageBox();
//   const {
//     downloadedCoupons,
//     downloadableCoupons,
//     couponPrice,
//     selectedCoupon,
//     message,
//     downloadCoupon,
//     applyCouponToPrice,
//   } = useCoupon(storeId, product.id, userId);

//   const { placeOrder, cancelOrder, returnOrder } = useOrder(storeId);

//   const { logPurchasedStoreProduct } = useStoreUserActivity(currentUser?.uid);

//   const [quantity, setQuantity] = useState(10);
//   const [localMessage, setMessage] = useState('');
//   const maxQuantity = 10;

//   let totalPrice, finalPrice;

//   if (product?.discountedPrice && product?.discountedPrice > 0) {
//     totalPrice = product.discountedPrice * quantity;
//     finalPrice = product.discountedPrice * quantity - couponPrice - usedPoints;
//   } else {
//     totalPrice = product.price * quantity;
//     finalPrice = product.price * quantity - couponPrice - usedPoints;
//   }
//   paymentData.amount = finalPrice;
//   // 추가: 결제 진행 중 상태 관리
//   const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);

//   const onPaymentSuccess = async (paymentResult) => {
//     // ... 기존의 성공 로직 ...

//     // 성공 후 리디렉션 또는 상태 업데이트
//     // 예: history.push('/order-confirmation');
//     handlePlaceOrder();
//     setIsPaymentProcessing(false); // 결제 처리 상태 해제
//   };

//   const onPaymentFailure = async (error) => {
//     // ... 기존의 실패 로직 ...
//     setIsPaymentProcessing(false); // 결제 처리 상태 해제
//   };
//   useEffect(() => {
//     setMessage(message);
//   }, [message]);

//   useEffect(() => {
//     const fetchUserData = async () => {
//       try {
//         const userRef = doc(db, 'users', userId); // Reference 생성
//         const userDoc = await getDoc(userRef); // Document 가져오기

//         if (userDoc.exists()) {
//           const userData = {
//             address: userDoc.data().address,
//             extraAddress: userDoc.data().extraAddress,
//             zoneCode: userDoc.data().zoneCode,
//             additionalAddresses: userDoc.data().additionalAddresses,
//             // phoneNumber: userDoc.data().phoneNumber,
//           };

//           setUserData(userDoc.data());
//         }
//       } catch (error) {
//         console.error('Error fetching user data:', error);
//       }
//     };

//     fetchUserData();
//   }, [userId]);

//   const addToCart = async (product) => {
//     const cartRef = doc(
//       db,
//       'stores',
//       storeId,
//       'users',
//       userId,
//       'cart',
//       product.id
//     );
//     const cartSnapshot = await getDoc(cartRef);

//     if (cartSnapshot.exists()) {
//       // 상품이 이미 장바구니에 있으면 수량을 증가시킵니다.
//       const result = await MessageBoxConfirm(
//         '이미 상품이 장바구니에 있습니다. 추가하시겠어요?'
//       );
//       if (result) {
//         const currentQuantity = cartSnapshot.data().quantity;
//         await updateDoc(cartRef, {
//           quantity: currentQuantity + 1,
//         });
//         await MessageBoxInform('장바구니에 추가 되었습니다.');
//       }
//     } else {
//       // 새로운 상품을 장바구니에 추가합니다.
//       const orderDetails = {
//         userId: currentUser.uid,
//         storeId: storeId,
//         productId: product.id,
//         quantity: quantity,
//         price: product.price,
//         couponId: selectedCoupon ? selectedCoupon.id : null,
//         couponCode: selectedCoupon ? selectedCoupon.couponCode : null,
//         couponPrice,
//         totalPrice,
//       };
//       await setDoc(cartRef, orderDetails);
//       await MessageBoxInform('장바구니에 추가 되었습니다.');
//     }
//   };

//   const handleAddToCart = async () => {
//     await addToCart(product);
//     // setMessage('장바구니에 추가 되었습니다.');
//   };

//   const handlePlaceOrder = async () => {
//     if (loading) return; // 이미 결제 중인 경우 무시

//     const orderData = {
//       userId: currentUser.uid,
//       storeId: storeId,
//       items: [
//         {
//           productId: product.id,
//           quantity: quantity,
//           price: product.price,
//           couponPrice,
//           discountedPrice: product?.discountedPrice ?? 0,
//           selectedCoupon,
//           productDetails: {
//             // 선택한 제품의 상세 정보
//             name: product.productName,
//             description: product.description,
//             imageUrl:
//               Array.isArray(product.small_images) &&
//               product.small_images.length > 0
//                 ? product.small_images[0]
//                 : null,
//           },
//           totalPrice,
//           finalPrice,
//         },
//       ],
//       usedPoints: usedPoints,
//       totalAmount: totalPrice,
//       finalAmount: finalPrice,
//       discountAmount: product?.discountedPrice ?? 0,
//       couponAmount: couponPrice,
//       dateTime: new Date(),
//       shippingDetails: {
//         address: selectedAddress?.address,
//         extraAddress: selectedAddress?.extraAddress,
//         phoneNumber: '01012341111',
//         deliveryDate: 'preferredDeliveryDate',
//       },
//     };
//     const result = await MessageBoxConfirm('주문하겠습니까?');
//     if (result) {
//       try {
//         setLoading(true); // 로딩 상태 시작
//         dispatchPlaceOrder(orderData);
//       } catch (error) {
//         console.error('Error during payment:', error);
//       } finally {
//         logPurchasedStoreProduct(userId, product.id);
//         setLoading(false); // 로딩 상태 종료
//         await MessageBoxInform('주문이 정상적으로 처리되었습니다.');
//       }
//     }
//   };

//   const dispatchPlaceOrder = (orderData) => {
//     //dispatch(placeOrder(orderData));
//     placeOrder(orderData);
//   };

//   const handleSelectedAddress = (address) => {
//     setSelectedAddress(address);
//   };
//   const handleUpdateAddress = async (updatedUser) => {
//     try {
//       const userRef = doc(db, 'users', userId);
//       await setDoc(userRef, updatedUser, { merge: true }); // Document 업데이트
//       setUserData(updatedUser);
//       await MessageBoxInform('주소 정보가 업데이트되었습니다.');
//     } catch (error) {
//       console.error('Error updating address:', error);
//       await MessageBoxInform(
//         '주소 정보를 업데이트하는 중 오류가 발생했습니다.'
//       );
//     }
//   };
//   // Render logic
//   return (
//     <div>
//       {localMessage && <p className="message">{localMessage}</p>}

//       {downloadedCoupons &&
//         downloadedCoupons.map((downloadedCoupon) => (
//           <div
//             key={`${downloadedCoupon.couponId}-${downloadedCoupon.productId}`}
//             className={`coupon-card ${
//               selectedCoupon?.couponId === downloadedCoupon.couponId &&
//               product?.id === downloadedCoupon.productId
//                 ? 'selected'
//                 : ''
//             }`}
//             onClick={() => applyCouponToPrice(product?.price, downloadedCoupon)}
//             title={`ID: ${downloadedCoupon.couponId}, ProductId: ${downloadedCoupon.productId}, Quantity: ${downloadedCoupon.quantity}`}
//           >
//             <ul>
//               <li>쿠폰이름: {downloadedCoupon.couponName}</li>
//               <li>
//                 시작일:{' '}
//                 {DateFormatter.formatTimeStamp(downloadedCoupon.startDate)}
//               </li>
//               <li>
//                 종료일:{' '}
//                 {DateFormatter.formatTimeStamp(downloadedCoupon.endDate)}
//               </li>
//               <li>
//                 {downloadedCoupon.discountType === 'percentage'
//                   ? `할인율: ${downloadedCoupon.discount} %`
//                   : `할인 금액: ${downloadedCoupon.discount} 원`}
//               </li>
//               <li>
//                 {downloadedCoupon.minPurchaseAmount}원 이상 결제시 사용가능
//               </li>
//             </ul>
//           </div>
//         ))}

//       {downloadableCoupons && downloadableCoupons.length > 0 && (
//         <div>
//           <h4>다운로드 가능한 쿠폰:</h4>
//           {downloadableCoupons.map((coupon) =>
//             coupon.applicableProducts
//               .filter(
//                 (applicableProduct) => applicableProduct.id === product.id
//               )
//               .map((applicableProduct) => (
//                 <button
//                   key={`${coupon.id}-${applicableProduct.id}`}
//                   onClick={() =>
//                     downloadCoupon(coupon.id, applicableProduct.id)
//                   }
//                   title={`ID: ${coupon.id}, ProductId: ${applicableProduct.id}, Name: ${applicableProduct.name}`}
//                 >
//                   {coupon.couponName} -{' '}
//                   {coupon.discountType === 'percentage'
//                     ? `${coupon.discount}% 할인`
//                     : `${coupon.discount} 원 할인`}
//                 </button>
//               ))
//           )}
//         </div>
//       )}

//       {MessageBoxRender()}

//       <FormControl fullWidth>
//         <InputLabel>수량</InputLabel>
//         <Select
//           value={quantity}
//           onChange={(e) => setQuantity(Number(e.target.value))}
//           label="수량"
//         >
//           {[...Array(maxQuantity).keys()].map((num) => (
//             <MenuItem key={num} value={num + 1}>
//               {num + 1}
//             </MenuItem>
//           ))}
//         </Select>
//       </FormControl>

//       <div className="price">
//         {product?.discountedPrice && product?.discountedPrice > 0 ? (
//           <>
//             원래 가격:{' '}
//             <span style={{ textDecoration: 'line-through', color: '#888' }}>
//               {product?.price}원
//             </span>
//             <br />
//             할인가: <strong>{product?.discountedPrice}원</strong>
//           </>
//         ) : (
//           <>
//             가격: <strong>{product?.price}원</strong>
//           </>
//         )}
//       </div>

//       <div>
//         <StorePoints
//           storeId={storeId}
//           userId={currentUser?.uid}
//           onUsedPoints={(newPoints) =>
//             setUsedPoints((prevPoints) => prevPoints + newPoints)
//           }
//         />
//       </div>

//       <div>전체가격: {totalPrice}</div>
//       <div>포인트사용: {usedPoints} 원</div>
//       <div>쿠폰가격: {couponPrice} 원</div>
//       <div>결제금액: {finalPrice} 원</div>
//       <div>
//         <TossPayments
//           paymentData={paymentData}
//           onSuccess={onPaymentSuccess}
//           onFailure={onPaymentFailure}
//           isProcessing={isPaymentProcessing}
//         />
//       </div>
//       <div>
//         {userData && (
//           <UserAddress
//             user={userData}
//             onUpdate={handleUpdateAddress}
//             onAddressSelect={handleSelectedAddress}
//           />
//         )}
//       </div>

//       <div>
//         <button onClick={handlePlaceOrder} disabled={loading}>
//           {loading ? 'Processing...' : '주문하기'}
//         </button>
//         <button className="button" onClick={handleAddToCart}>
//           장바구니 담기
//         </button>
//       </div>
//     </div>
//   );
// };
// export default StoreProductPurchase;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  arrayUnion,
  collection,
  db,
  doc,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
  runTransaction,
} from '../firebaseConfig';
import {
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
  Snackbar,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { updateCartItemsCount } from '../Redux/reducers/cartReducer';
import DateFormatter from '../Utils/DateFormatter';
import StorePoints from './Promotion/Points/StorePoints';
import useMessageBox from '../Common/useMessageBox';
import useCoupon from './Promotion/Coupon/UseCoupon';
import useOrder from './StoreAdmin/useOrder';
import UserAddress from '../UserAddress';
import TossPayments from './Payment/Toss/TossPayments';

const StoreProductPurchase = ({ storeId, product, userId }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const [MessageBoxInform, MessageBoxConfirm, MessageBoxRender] =
    useMessageBox();
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [userData, setUserData] = useState({});
  const [quantity, setQuantity] = useState(1);
  const [paymentData, setPaymentData] = useState({});
  const [addressDialogOpen, setAddressDialogOpen] = useState(false);
  const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const {
    downloadedCoupons,
    downloadableCoupons,
    couponPrice,
    selectedCoupon,
    message,
    downloadCoupon,
    applyCouponToPrice,
  } = useCoupon(storeId, product.id, userId);
  const { placeOrder } = useOrder(storeId);

  const calculateFinalPrice = (usedPoints) => {
    let totalPrice, finalPrice;
    // Ensure usedPoints is a number and not undefined or null
    const validPoints = Number.isFinite(usedPoints) ? usedPoints : 0;

    if (product?.discountedPrice && product?.discountedPrice > 0) {
      totalPrice = product.discountedPrice * quantity;
      finalPrice =
        product.discountedPrice * quantity - couponPrice - usedPoints;
    } else {
      totalPrice = product.price * quantity;
      finalPrice = product.price * quantity - couponPrice - usedPoints;
    }
    paymentData.amount = finalPrice;
    // Calculate final price ensuring it never drops below zero
    finalPrice = Math.max(0, totalPrice - validPoints);

    return { totalPrice, finalPrice };
  };

  const { totalPrice, finalPrice } = calculateFinalPrice(userData.usedPoints);

  // Prepare paymentData just before opening the payment dialog
  useEffect(() => {
    if (paymentDialogOpen) {
      setPaymentData({
        amount: finalPrice, // Ensure to calculate this from state
        // Other necessary payment data
      });
    }
  }, [paymentDialogOpen, finalPrice]);

  const isLoggedIn = async () => {
    if (!currentUser) {
      const isConfirmed = await MessageBoxConfirm(
        t('login-required'),
        t('goto-login')
      );
      if (isConfirmed) {
        navigate('/login');
      }
      return false;
    }
    return true;
  };

  const handlePlaceOrder = async () => {
    if (!(await isLoggedIn())) return;
    if (currentUser.uid === product.uid) {
      MessageBoxInform(t('seller-cannot-buy'));
      return;
    }

    setLoading(true);
    try {
      await placeOrder({ storeId, productId: product.id, quantity, userId });
      await MessageBoxInform(t('purchase-request-registered'));
      setSnackbarMessage('Order placed successfully!');
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage('Failed to place order');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userRef = doc(db, 'users', userId); // Reference 생성
        const userDoc = await getDoc(userRef); // Document 가져오기

        if (userDoc.exists()) {
          const userData = {
            address: userDoc.data().address,
            extraAddress: userDoc.data().extraAddress,
            zoneCode: userDoc.data().zoneCode,
            additionalAddresses: userDoc.data().additionalAddresses,
            // phoneNumber: userDoc.data().phoneNumber,
          };

          setUserData(userDoc.data());
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, [userId]);

  const addToCart = async (product) => {
    const cartRef = doc(
      db,
      'stores',
      storeId,
      'users',
      userId,
      'cart',
      product.id
    );
    const cartSnapshot = await getDoc(cartRef);

    if (cartSnapshot.exists()) {
      // 상품이 이미 장바구니에 있으면 수량을 증가시킵니다.
      const result = await MessageBoxConfirm(
        '이미 상품이 장바구니에 있습니다. 추가하시겠어요?'
      );
      if (result) {
        const currentQuantity = cartSnapshot.data().quantity;
        await updateDoc(cartRef, {
          quantity: currentQuantity + 1,
        });
        await MessageBoxInform('장바구니에 추가 되었습니다.');
        dispatch(updateCartItemsCount(currentQuantity + 1));
      }
    } else {
      // 새로운 상품을 장바구니에 추가합니다.
      const isConfirmed = await MessageBoxConfirm('장바구니에 추가 할까요?');
      if (!isConfirmed) return;
      const orderDetails = {
        userId: currentUser.uid,
        storeId: storeId,
        productId: product.id,
        quantity: quantity,
        price: product.price,
        discountedPrice: product.discountedPrice,
        couponId: selectedCoupon ? selectedCoupon.id : null,
        couponCode: selectedCoupon ? selectedCoupon.couponCode : null,
        couponPrice,
        totalPrice,
      };
      await setDoc(cartRef, orderDetails);
      await MessageBoxInform('장바구니에 추가 되었습니다.');
    }
  };

  const handleAddToCart = async () => {
    await addToCart(product);
  };

  const handleUpdateAddress = async (updatedUser) => {
    try {
      const userRef = doc(db, 'users', userId);
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        await updateDoc(userRef, updatedUser); // Update the existing document
      } else {
        await setDoc(userRef, updatedUser, { merge: true }); // Create a new document with merge option
      }

      setUserData(updatedUser);
      await MessageBoxInform('주소 정보가 업데이트되었습니다.');
    } catch (error) {
      console.error('Error updating address:', error);
      await MessageBoxInform(
        '주소 정보를 업데이트하는 중 오류가 발생했습니다.'
      );
    }
  };

  const handleSelectedAddress = (address) => {
    setSelectedAddress(address);
  };
  const handleOpenAddressDialog = () => {
    setAddressDialogOpen(true);
  };

  const handleCloseAddressDialog = () => {
    setAddressDialogOpen(false);
  };

  const handleOpenPaymentDialog = () => {
    setPaymentDialogOpen(true);
  };

  const handleClosePaymentDialog = () => {
    setPaymentDialogOpen(false);
  };

  const onPaymentSuccess = (paymentResult) => {
    // Handle payment success
    handleClosePaymentDialog();
    handlePlaceOrder();
  };

  const onPaymentFailure = (error) => {
    // Handle payment failure
    handleClosePaymentDialog();
    // Additional logic
  };
  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h5" gutterBottom>
        Purchase {product.name}
      </Typography>
      {MessageBoxRender()}
      <FormControl fullWidth margin="normal">
        <InputLabel>Quantity</InputLabel>
        <Select
          value={quantity}
          label="Quantity"
          onChange={(e) => setQuantity(Number(e.target.value))}
        >
          {[...Array(10).keys()].map((num) => (
            <MenuItem key={num} value={num + 1}>
              {num + 1}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Box sx={{ mt: 2 }}>
        <Typography>Total Price: {totalPrice.toFixed(2)}원</Typography>
        <Typography>Final Price: {finalPrice.toFixed(2)}원</Typography>
      </Box>

      <Button
        variant="contained"
        color="primary"
        onClick={handleAddToCart}
        disabled={loading}
        sx={{ mr: 2, mt: 2 }}
      >
        {loading ? <CircularProgress size={24} /> : '장바구니 담기'}
      </Button>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />

      {/* UserAddress Popup */}
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpenAddressDialog}
        sx={{ mt: 2 }}
      >
        주소변경
      </Button>
      {selectedAddress && (
        <Typography>
          {t('deliveryAddress')}: {selectedAddress.zoneCode}{' '}
          {selectedAddress.address}{' '}
          {selectedAddress.extraAddress && `(${selectedAddress.extraAddress})`}
        </Typography>
      )}
      <Dialog open={addressDialogOpen} onClose={handleCloseAddressDialog}>
        <DialogTitle>배송지 주소 선택</DialogTitle>
        <DialogContent>
          {userData && (
            <UserAddress
              user={userData}
              onUpdate={handleUpdateAddress}
              onAddressSelect={handleSelectedAddress}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddressDialog}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* TossPayments Popup */}
      {/* <Button onClick={handleOpenPaymentDialog}>결제하기</Button> */}
      <Dialog open={paymentDialogOpen} onClose={handleClosePaymentDialog}>
        <DialogTitle>Complete Your Payment</DialogTitle>
        <DialogContent>
          <TossPayments
            paymentData={paymentData}
            onSuccess={onPaymentSuccess}
            onFailure={onPaymentFailure}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePaymentDialog}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* Optional: Add TossPayments dialog if needed */}
    </Box>
  );
};

export default StoreProductPurchase;

// import React, { useState, useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { Button, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress } from '@mui/material';
// import UserAddress from '../UserAddress';
// import TossPayments from './Payment/Toss/TossPayments';

// function StoreProductPurchase({ storeId, product, userId }) {
//   const [addressDialogOpen, setAddressDialogOpen] = useState(false);
//   const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);
//   const [selectedAddress, setSelectedAddress] = useState(null);
//   const [paymentData, setPaymentData] = useState({});
//   const [loading, setLoading] = useState(false);

//   const handleOpenAddressDialog = () => {
//     setAddressDialogOpen(true);
//   };

//   const handleCloseAddressDialog = () => {
//     setAddressDialogOpen(false);
//   };

//   const handleOpenPaymentDialog = () => {
//     setPaymentDialogOpen(true);
//   };

//   const handleClosePaymentDialog = () => {
//     setPaymentDialogOpen(false);
//   };

//   const onPaymentSuccess = (paymentResult) => {
//     // Handle payment success
//     handleClosePaymentDialog();
//     // Additional logic
//   };

//   const onPaymentFailure = (error) => {
//     // Handle payment failure
//     handleClosePaymentDialog();
//     // Additional logic
//   };

//   // Prepare paymentData just before opening the payment dialog
//   useEffect(() => {
//     if (paymentDialogOpen) {
//       setPaymentData({
//         amount: finalPrice, // Ensure to calculate this from state
//         // Other necessary payment data
//       });
//     }
//   }, [paymentDialogOpen, finalPrice]);

//   return (
//     <div>
//       {/* UserAddress Popup */}
//       <Button onClick={handleOpenAddressDialog}>Change Address</Button>
//       <Dialog open={addressDialogOpen} onClose={handleCloseAddressDialog}>
//         <DialogTitle>Update Your Address</DialogTitle>
//         <DialogContent>
//           <UserAddress user={selectedAddress} onUpdate={handleOpenAddressDialog} />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleCloseAddressDialog}>Close</Button>
//         </DialogActions>
//       </Dialog>

//       {/* TossPayments Popup */}
//       <Button onClick={handleOpenPaymentDialog}>Proceed to Payment</Button>
//       <Dialog open={paymentDialogOpen} onClose={handleClosePaymentDialog}>
//         <DialogTitle>Complete Your Payment</DialogTitle>
//         <DialogContent>
//           <TossPayments
//             paymentData={paymentData}
//             onSuccess={onPaymentSuccess}
//             onFailure={onPaymentFailure}
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleClosePaymentDialog}>Close</Button>
//         </DialogActions>
//       </Dialog>

//       {/* Other UI components */}
//     </div>
//   );
// }

// export default StoreProductPurchase;
