// import React, { useState } from 'react';
// import { useSelector } from 'react-redux';
// import { db, auth, doc, setDoc, getDoc, collection, query, getDocs, where, updateDoc, updateProfile, sendEmailVerification, createUserWithEmailAndPassword, signInWithEmailAndPassword } from '../../firebaseConfig';
// import { useNavigate } from 'react-router-dom';
// import { Button, TextField, CircularProgress } from '@mui/material';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import '../css/SignUpFlow.css'

// function SignUpCreateAccountEmail({ onPrev, onNext, setIsStepCompleted }) {
//     const currentUser = useSelector((state) => state.auth.currentUser);
//     const [name, setName] = useState('');
//     const [email, setEmail] = useState('');
//     const [password, setPassword] = useState('');
//     const [confirmPassword, setConfirmPassword] = useState('');
//     const [isVerificationSent, setIsVerificationSent] = useState(false);
//     const [loading, setLoading] = useState(false);
//     const [error, setError] = useState('');
//     const navigate = useNavigate();

//     // Utility function to handle errors
//     const handleError = (errorMessage, logMessage) => {
//         const message = logMessage ? `${errorMessage}, ${logMessage}` : errorMessage;
//         setError(message);
//         console.error(message);
//     };
//     function validatePassword(password) {
//         const minLength = 7;
//         const hasUpperCase = /[A-Z]/.test(password);
//         const hasLowerCase = /[a-z]/.test(password);
//         const hasNumbers = /\d/.test(password);
//         const hasSpecialChars = /[@#$%&]/.test(password);

//         if (password.length < minLength
//             // || !hasUpperCase
//             || !hasLowerCase
//             || !hasNumbers
//             // || !hasSpecialChars
//             ) {
//             return false;
//         }

//         return true;
//     }
//     const handleRegister = async () => {
//         console.log("handleRegister called");
//         setLoading(true);
//         if (password !== confirmPassword) {
//             setError("Passwords do not match.");
//             setLoading(false);
//             return;
//         }
//         if (!validatePassword(password)) {
//             setError("비밀번호가 조건을 충족하지 않습니다. 비밀번호는 8자 이상이어야 하며, 대문자, 소문자, 숫자, 특수 문자(@#$%&)를 포함해야 합니다.");
//             setLoading(false);
//             return;
//         }
//         try {
//             await createUserWithEmailAndPassword(auth, email, password);
//             await sendVerificationEmail();
//         } catch (error) {
//             if (error.code === "auth/email-already-in-use") {
//                 // Assume we have a users collection where each document has an email field
//                 const storesRef = collection(db, 'stores');
//                 const q = query(storesRef, where("email", "==", email));
//                 try {
//                     const querySnapshot = await getDocs(q);
//                     if (!querySnapshot.empty) {
//                         // Found user with the email, now check their step
//                         const storeData = querySnapshot.docs[0].data(); // Assuming email is unique and only fetches one doc

//                         if (storeData.step === 'signupCompleted') {
//                             handleError('이미 비즈계정으로 가입된 유저입니다.');
//                         } else {
//                             handleError('비즈계정으로 가입중인 기록이 있습니다.');
//                             const userResponse = window.confirm("해당 계정으로 비즈계정 가입을 이어서 진행할까요?");
//                             if (userResponse) {
//                                 handleLogin(); // Proceed with login or account upgrade
//                             }
//                         }

//                     } else {
//                         // No user found with this email, handle accordingly
//                         handleError('이미 일반계정으로 가입된 유저입니다.');
//                         const userResponse = window.confirm("해당 계정으로 비즈계정 가입을 진행할까요?");
//                         if (userResponse) {
//                             handleLogin(); // Proceed with login or account upgrade
//                         }

//                     }
//                 } catch (error) {
//                     handleError("Error checking user's account type:", error);
//                 }
//             } else if (error.code === "auth/weak-password") {
//                 handleError("비밀번호가 너무 약합니다.");
//             } else if (error.code === "auth/wrong-password") {
//                 handleError("잘못된 비밀번호입니다.");
//             } else if (error.code === "auth/user-not-found") {
//                 handleError("사용자를 찾을 수 없습니다.");
//             } else if (error.code === "auth/invalid-email") {
//                 handleError("잘못된 이메일 형식입니다.");
//             } else if (error.code === "auth/too-many-requests") {
//                 handleError("너무 많은 요청이 감지되었습니다. 나중에 다시 시도해주세요.");

//             } else {
//                 handleError("회원가입 중에 오류가 발생했습니다: ", error.message);

//             }
//         }
//         setLoading(false);
//     };

//     const sendVerificationEmail = async () => {
//         try {
//             await sendEmailVerification(auth.currentUser);
//             setIsVerificationSent(true);
//             toast.success('Verification email sent. Please verify to continue.');
//         } catch (error) {
//             handleError('Error sending verification email.', error.message);
//         }
//     };

//     const handleLogin = async () => {
//         try {
//             await signInWithEmailAndPassword(auth, email, password);
//             handleVerificationComplete();
//         } catch (error) {
//             handleError('Failed to login.', error.message);
//         }
//     };

//     const handleVerificationComplete = async () => {
//         try {
//             const user = auth.currentUser;
//             await user.reload();
//             if (user.emailVerified) {
//                 const userInfo = {
//                     email: user.email,
//                     name: name,
//                     step: process.env.REACT_APP_CREATE_ACCOUNT_VERIFIED,
//                 };
//                 await upsertUserData(user, userInfo);
//                 await updatePendingRegistrations(user, userInfo);
//                 await updateProfile(user, { displayName: name });
//                 toast.success('Email verified successfully.');
//                 setIsStepCompleted(true);
//                 //onNext(userInfo);

//             } else {
//                 await sendVerificationEmail();
//             }
//         } catch (error) {
//             handleError('Error during verification process.', error.message);
//         }
//     };

//     const upsertUserData = async (user, userInfo) => {
//         try {
//             const userRef = doc(db, 'users', user.uid);
//             await setDoc(userRef, {
//                 ...userInfo,
//                 createdAt: new Date(),
//                 modifiedAt: new Date()
//             }, { merge: true });
//         } catch (error) {
//             handleError('Failed to update user data.', error.message);
//         }
//     };

//     const updatePendingRegistrations = async (user, userInfo) => {
//         try {
//             const userRef = doc(db, 'users', user.uid);
//             await updateDoc(userRef, {
//                 "pendingRegistrations": {
//                     ...userInfo,
//                     step: userInfo.step,
//                     createdAt: new Date(),
//                     modifiedAt: new Date()
//                 }
//             });
//         } catch (error) {
//             handleError('Failed to update pending registrations.', error.message);
//         }
//     };

//     return (
//         <div>
//             <div>
//                 <div className="d-flex justify-content-between align-items-center mb-4">
//                     {/* <img src={logo} alt="Logo" style={{width: '100px'}} />  */}
//                     <h3>회원가입</h3>
//                 </div>
//                 <ToastContainer />
//                 <div>
//                     <div >
//                         <TextField
//                             variant="outlined"
//                             margin="normal"
//                             fullWidth
//                             id="name-new"
//                             label="Name"
//                             placeholder="Enter your name"
//                             value={name}
//                             onChange={(e) => setName(e.target.value)}
//                         />

//                         <TextField
//                             variant="outlined"
//                             margin="normal"
//                             fullWidth
//                             id="email-new"
//                             label="Email"
//                             placeholder="Enter your email"
//                             value={email}
//                             onChange={(e) => setEmail(e.target.value)}
//                         />

//                         <TextField
//                             variant="outlined"
//                             margin="normal"
//                             fullWidth
//                             id="pw-new"
//                             label="Password"
//                             placeholder="Enter your password"
//                             type="password"
//                             value={password}
//                             onChange={(e) => setPassword(e.target.value)}
//                         />

//                         <TextField
//                             variant="outlined"
//                             margin="normal"
//                             fullWidth
//                             id="confirm-new-pw"
//                             label="Confirm Password"
//                             placeholder="Confirm your password"
//                             type="password"
//                             value={confirmPassword}
//                             onChange={(e) => setConfirmPassword(e.target.value)}
//                         />
//                         {error && <div style={{ color: 'red' }}>{error}</div>}
//                         <br /><br />
//                         <Button
//                             type="submit"
//                             fullWidth
//                             variant="contained"
//                             color="primary"
//                             onClick={handleRegister}
//                             disabled={loading}
//                         >
//                             {loading ? <CircularProgress size={24} /> : '가입하기'}
//                         </Button>

//                         {isVerificationSent &&
//                             <Button
//                                 fullWidth
//                                 variant="contained"
//                                 color="secondary"
//                                 style={{ marginTop: '10px' }}
//                                 onClick={handleVerificationComplete}
//                             >
//                                 인증 완료
//                             </Button>
//                         }
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default SignUpCreateAccountEmail;

import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import {
  db,
  auth,
  doc,
  setDoc,
  getDoc,
  collection,
  query,
  getDocs,
  where,
  updateDoc,
  updateProfile,
  sendEmailVerification,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from '../firebaseConfig';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  TextField,
  CircularProgress,
  Container,
  Typography,
  Box,
  Alert,
} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';

function SignUpCreateAccountEmail({
  onPrev,
  onNext,
  setIsStepCompleted,
  userType,
}) {
  const { t, i18n } = useTranslation();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isVerificationSent, setIsVerificationSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleError = (errorMessage, logMessage) => {
    const message = logMessage
      ? `${errorMessage}, ${logMessage}`
      : errorMessage;
    setError(message);
    console.error(message);
  };
  function validatePassword(password) {
    const minLength = 7;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumbers = /\d/.test(password);
    const hasSpecialChars = /[@#$%&]/.test(password);

    if (
      password.length < minLength ||
      // !hasUpperCase ||
      !hasLowerCase
      // !hasNumbers ||
      // !hasSpecialChars
    ) {
      return false;
    }

    return true;
  }
  const handleRegister = async () => {
    console.log('handleRegister called');
    setError('');
    setLoading(true);
    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      setLoading(false);
      return;
    }
    if (!validatePassword(password)) {
      setError(
        '비밀번호가 조건을 충족하지 않습니다. 비밀번호는 8자 이상이어야 하며, 대문자, 소문자, 숫자, 특수 문자(@#$%&)를 포함해야 합니다.'
      );
      setLoading(false);
      return;
    }
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      await sendVerificationEmail();
    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        // Assume we have a users collection where each document has an email field
        const storesRef = collection(db, 'stores');
        const q = query(storesRef, where('email', '==', email));
        try {
          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            // Found user with the email, now check their step
            const storeData = querySnapshot.docs[0].data(); // Assuming email is unique and only fetches one doc

            if (storeData.step === 'signupCompleted') {
              handleError('이미 비즈계정으로 가입된 유저입니다.');
            } else {
              handleError('비즈계정으로 가입중인 기록이 있습니다.');
              const userResponse = window.confirm(
                '해당 계정으로 비즈계정 가입을 이어서 진행할까요?'
              );
              if (userResponse) {
                handleLogin(); // Proceed with login or account upgrade
              }
            }
          } else {
            // No user found with this email, handle accordingly
            handleError('이미 일반계정으로 가입된 유저입니다.');
            // const userResponse = window.confirm(
            //   '해당 계정으로 비즈계정 가입을 진행할까요?'
            // );
            // if (userResponse) {
            //   handleLogin(); // Proceed with login or account upgrade
            // }
          }
        } catch (error) {
          handleError("Error checking user's account type:", error);
        }
      } else if (error.code === 'auth/weak-password') {
        handleError('비밀번호가 너무 약합니다.');
      } else if (error.code === 'auth/wrong-password') {
        handleError('잘못된 비밀번호입니다.');
      } else if (error.code === 'auth/user-not-found') {
        handleError('사용자를 찾을 수 없습니다.');
      } else if (error.code === 'auth/invalid-email') {
        handleError('잘못된 이메일 형식입니다.');
      } else if (error.code === 'auth/too-many-requests') {
        handleError(
          '너무 많은 요청이 감지되었습니다. 나중에 다시 시도해주세요.'
        );
      } else {
        handleError('회원가입 중에 오류가 발생했습니다: ', error.message);
      }
    }
    setLoading(false);
  };

  const sendVerificationEmail = async () => {
    try {
      await sendEmailVerification(auth.currentUser);
      setIsVerificationSent(true);
      toast.success(t('verificationEmailSent'));
    } catch (error) {
      setError(error.message);
    }
  };
  const handleVerificationComplete = async () => {
    try {
      const user = auth.currentUser;
      await user.reload();
      if (user.emailVerified) {
        const userInfo = {
          email: user.email,
          name: name,
          userType: userType,
          step: process.env.REACT_APP_CREATE_ACCOUNT_VERIFIED,
        };
        await upsertUserData(user, userInfo);
        await updateProfile(user, { displayName: name });
        toast.success('Email verified successfully.');
        setIsStepCompleted(true);
        onNext(userInfo);
      } else {
        await sendVerificationEmail();
      }
    } catch (error) {
      handleError('Error during verification process.', error.message);
    }
  };
  const upsertUserData = async (user, userInfo) => {
    try {
      const userRef = doc(db, 'users', user.uid);
      await setDoc(
        userRef,
        {
          ...userInfo,
          createdAt: new Date(),
          modifiedAt: new Date(),
        },
        { merge: true }
      );
    } catch (error) {
      handleError('Failed to update user data.', error.message);
    }
  };
  const handleLogin = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      handleVerificationComplete();
    } catch (error) {
      handleError('Failed to login.', error.message);
    }
  };
  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          mt: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          {t('signup')}
        </Typography>
        <Box component="form" noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="name"
            label={t('name')}
            name="name"
            autoComplete="name"
            autoFocus
            value={name || ''}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label={t('email')}
            name="email"
            autoComplete="email"
            value={email || ''}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label={t('password')}
            type="password"
            id="password"
            autoComplete="new-password"
            value={password || ''}
            onChange={(e) => setPassword(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="confirmPassword"
            label={t('confirmPassword')}
            type="password"
            id="confirmPassword"
            value={confirmPassword || ''}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          {error && <Alert severity="error">{error}</Alert>}
          <Button
            type="button"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleRegister}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : t('signup')}
          </Button>
          {isVerificationSent && (
            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 1 }}
              onClick={handleVerificationComplete}
            >
              {t('completeVerification')}
            </Button>
          )}
        </Box>
      </Box>
      <ToastContainer />
    </Container>
  );
}

export default SignUpCreateAccountEmail;
