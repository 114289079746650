// import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import {
//   db,
//   auth,
//   doc,
//   setDoc,
//   updateDoc,
//   updateProfile,
//   sendEmailVerification,
//   updatePassword,
//   EmailAuthProvider,
//   googleAuthProvider,
// } from '../firebaseConfig';

// import {
//   getAuth,
//   reauthenticateWithCredential,
//   reauthenticateWithPopup,
//   reauthenticateWithRedirect,
//   getRedirectResult,
// } from 'firebase/auth';

// import { useNavigate } from 'react-router-dom';
// import {
//   Button,
//   TextField,
//   CircularProgress,
//   Box,
//   Typography,
//   Paper,
// } from '@mui/material';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import { useTranslation } from 'react-i18next';

// function SignUpSetPassword({ onPrev, onNext, setIsStepCompleted }) {
//   const { t } = useTranslation();
//   const currentUser = useSelector((state) => state.auth.currentUser);
//   const [name, setName] = useState('');
//   const [email, setEmail] = useState('');
//   const [newPassword, setNewPassword] = useState('');
//   const [confirmNewPassword, setConfirmNewPassword] = useState('');
//   const [isVerificationSent, setIsVerificationSent] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState('');
//   const navigate = useNavigate();

//   // Utility function to handle errors
//   const handleError = (errorMessage, logMessage) => {
//     setError(errorMessage);
//     console.error(logMessage);
//   };

//   const sendVerificationEmail = async () => {
//     try {
//       await sendEmailVerification(auth.currentUser);
//       setIsVerificationSent(true);
//       toast.success(t('verificationEmailSent'));
//     } catch (error) {
//       handleError(t('errorSendingVerificationEmail'), error.message);
//     }
//   };

//   const handleVerificationComplete = async () => {
//     const user = auth.currentUser;

//     try {
//       await user.reload();

//       if (user.emailVerified) {
//         const userInfo = {
//           email: user.email,
//           name: name,
//         };

//         await upsertUserData(user, userInfo);

//         const userInfoWithStep = {
//           ...userInfo,
//           step: process.env.REACT_APP_CREATE_ACCOUNT_VERIFIED,
//         };

//         await updateRegistrations(user, userInfoWithStep);

//         try {
//           await updateProfile(user, { displayName: name });
//           toast.success(t('emailVerified'));

//           if (setIsStepCompleted) {
//             setIsStepCompleted(true);
//           }
//         } catch (profileError) {
//           handleError(t('failedToUpdateProfile'), profileError.message);
//         }
//       } else {
//         await sendVerificationEmail();
//       }
//     } catch (error) {
//       console.error('Error handling verification:', error.message);
//       handleError(t('errorVerifyingEmail'), error.message);
//     }
//   };

//   const reauthenticate = async (currentPassword) => {
//     const user = auth.currentUser;
//     const credential = EmailAuthProvider.credential(
//       user.email,
//       currentPassword
//     );
//     return reauthenticateWithCredential(user, credential);
//   };

//   const reauthenticateWithGoogle = async () => {
//     const user = auth.currentUser;

//     if (user) {
//       try {
//         await reauthenticateWithPopup(user, googleAuthProvider);
//         console.log('Reauthentication successful');
//       } catch (error) {
//         console.error('Error during reauthentication:', error);
//       }
//     } else {
//       console.error('No user is currently signed in.');
//     }
//   };
//   // 리디렉션 결과 처리
//   // const handleRedirectResult = async () => {
//   //   const auth = getAuth();
//   //   try {
//   //     const result = await getRedirectResult(auth);
//   //     if (result) {
//   //       // 재인증에 성공한 경우
//   //       const user = result.user;
//   //       console.log('User reauthenticated: ', user);
//   //     }
//   //   } catch (error) {
//   //     console.error('Error during reauthentication with redirect:', error);
//   //   }
//   // };

//   // useEffect(() => {
//   //   handleRedirectResult();
//   // }, []);

//   // // Google 재인증 리디렉션 시작
//   // const reauthenticateWithGoogle = async () => {
//   //   const auth = getAuth();
//   //   const user = auth.currentUser;

//   //   if (user) {
//   //     reauthenticateWithRedirect(user, googleAuthProvider);
//   //   } else {
//   //     console.error('No user is currently signed in.');
//   //   }
//   // };
//   const reauthenticateWithNaver = async () => {
//     const state = Math.random().toString(36).substring(2, 15);
//     sessionStorage.setItem('naverOAuthState', state);
//     const naverUrl = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${process.env.REACT_APP_NAVER_CLIENT_ID}&state=${state}&redirect_uri=${process.env.REACT_APP_NAVER_REDIRECT_URI}`;
//     window.location.href = naverUrl;
//   };

//   const reauthenticateWithKakao = async () => {
//     const kakaoUrl = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_KAKAO_REDIRECT_URI}&response_type=code`;
//     window.location.href = kakaoUrl;
//   };

//   const handleUpdatePassword = async () => {
//     if (newPassword !== confirmNewPassword) {
//       setError(t('passwordsDoNotMatch'));
//       return;
//     } else {
//       setError('');
//     }

//     if (!newPassword) {
//       setError(t('enterBothPasswords'));
//       return;
//     }

//     setLoading(true);
//     const user = auth.currentUser;
//     try {
//       if (!user || !user.providerData || user.providerData.length === 0) {
//         throw new Error('No provider data available');
//       }

//       const providerId = user.providerData[0].providerId;

//       if (providerId === 'google.com') {
//         await reauthenticateWithGoogle();
//       } else if (providerId === 'naver.com') {
//         await reauthenticateWithNaver();
//       } else if (providerId === 'kakao.com') {
//         await reauthenticateWithKakao();
//       } else {
//         throw new Error('Unsupported provider');
//       }

//       await updatePassword(user, newPassword);
//       toast.success(t('passwordUpdated'));
//     } catch (error) {
//       if (error.code === 'auth/wrong-password') {
//         setError(t('incorrectCurrentPassword'));
//       } else {
//         handleError(t('failedToUpdatePassword'), error.message);
//       }
//     } finally {
//       setError('');
//       setNewPassword('');
//       setConfirmNewPassword('');
//       setLoading(false);
//     }
//   };

//   const upsertUserData = async (user, userInfo) => {
//     const userRef = doc(db, 'users', user.uid);
//     await setDoc(
//       userRef,
//       {
//         ...userInfo,
//         createdAt: new Date(),
//         modifiedAt: new Date(),
//       },
//       { merge: true }
//     );
//   };

//   const updateRegistrations = async (user, userInfo) => {
//     try {
//       const userRef = doc(db, 'users', user.uid);

//       await updateDoc(userRef, {
//         ...userInfo,
//         createdAt: new Date(),
//         modifiedAt: new Date(),
//       });
//     } catch (error) {
//       console.error('Error updating pending registrations:', error.message);
//       handleError(t('errorUpdatingRegistrations'), error.message);
//     }
//   };

//   return (
//     <Box sx={{ maxWidth: 600, mx: 'auto', p: 3 }}>
//       <ToastContainer />
//       <Paper elevation={3} sx={{ p: 3, borderRadius: 2 }}>
//         <Typography variant="h5" gutterBottom>
//           {t('changePassword')}
//         </Typography>

//         <TextField
//           variant="outlined"
//           margin="normal"
//           fullWidth
//           id="pw-new"
//           label={t('newPassword')}
//           placeholder={t('enterNewPassword')}
//           type="password"
//           value={newPassword || ''}
//           onChange={(e) => setNewPassword(e.target.value)}
//         />
//         <TextField
//           variant="outlined"
//           margin="normal"
//           fullWidth
//           id="confirm-new-pw"
//           label={t('confirmNewPassword')}
//           placeholder={t('confirmNewPassword')}
//           type="password"
//           value={confirmNewPassword || ''}
//           onChange={(e) => setConfirmNewPassword(e.target.value)}
//         />

//         {error && <Typography color="error">{error}</Typography>}

//         {isVerificationSent && (
//           <Button
//             fullWidth
//             variant="contained"
//             color="secondary"
//             sx={{ mt: 2 }}
//             onClick={handleVerificationComplete}
//           >
//             {t('completeVerification')}
//           </Button>
//         )}

//         <Button
//           fullWidth
//           variant="contained"
//           color="primary"
//           sx={{ mt: 2 }}
//           onClick={handleUpdatePassword}
//           disabled={loading}
//         >
//           {loading ? (
//             <CircularProgress size={24} color="inherit" />
//           ) : (
//             t('changePassword')
//           )}
//         </Button>
//       </Paper>
//     </Box>
//   );
// }

// export default SignUpSetPassword;

// import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import {
//   db,
//   auth,
//   doc,
//   setDoc,
//   updateDoc,
//   updateProfile,
//   sendEmailVerification,
//   updatePassword,
//   EmailAuthProvider,
//   googleAuthProvider,
//   naverAuthProvider,
//   kakaoAuthProvider,
// } from '../firebaseConfig';

// import {
//   reauthenticateWithCredential,
//   reauthenticateWithPopup,
// } from 'firebase/auth';
// import {
//   Button,
//   TextField,
//   CircularProgress,
//   Box,
//   Typography,
//   Paper,
// } from '@mui/material';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import { useTranslation } from 'react-i18next';

// function SignUpSetPassword({ onPrev, onNext, setIsStepCompleted }) {
//   const { t } = useTranslation();
//   const currentUser = useSelector((state) => state.auth.currentUser);
//   const [name, setName] = useState('');
//   const [email, setEmail] = useState('');
//   const [newPassword, setNewPassword] = useState('');
//   const [confirmNewPassword, setConfirmNewPassword] = useState('');
//   const [isVerificationSent, setIsVerificationSent] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState('');
//   const navigate = useNavigate();

//   const handleError = (errorMessage, logMessage) => {
//     setError(errorMessage);
//     console.error(logMessage);
//   };

//   const sendVerificationEmail = async () => {
//     try {
//       await sendEmailVerification(auth.currentUser);
//       setIsVerificationSent(true);
//       toast.success(t('verificationEmailSent'));
//     } catch (error) {
//       handleError(t('errorSendingVerificationEmail'), error.message);
//     }
//   };

//   const handleVerificationComplete = async () => {
//     const user = auth.currentUser;

//     try {
//       await user.reload();

//       if (user.emailVerified) {
//         const userInfo = {
//           email: user.email,
//           name: name,
//         };

//         await upsertUserData(user, userInfo);

//         const userInfoWithStep = {
//           ...userInfo,
//           step: process.env.REACT_APP_CREATE_ACCOUNT_VERIFIED,
//         };

//         await updateRegistrations(user, userInfoWithStep);

//         try {
//           await updateProfile(user, { displayName: name });
//           toast.success(t('emailVerified'));

//           if (setIsStepCompleted) {
//             setIsStepCompleted(true);
//           }
//         } catch (profileError) {
//           handleError(t('failedToUpdateProfile'), profileError.message);
//         }
//       } else {
//         await sendVerificationEmail();
//       }
//     } catch (error) {
//       console.error('Error handling verification:', error.message);
//       handleError(t('errorVerifyingEmail'), error.message);
//     }
//   };

//   const reauthenticate = async (currentPassword) => {
//     const user = auth.currentUser;
//     const credential = EmailAuthProvider.credential(
//       user.email,
//       currentPassword
//     );
//     return reauthenticateWithCredential(user, credential);
//   };

//   const reauthenticateWithGoogle = async () => {
//     const user = auth.currentUser;

//     if (user) {
//       try {
//         await reauthenticateWithPopup(user, googleAuthProvider);
//         console.log('Reauthentication successful');
//       } catch (error) {
//         console.error('Error during reauthentication:', error);
//       }
//     } else {
//       console.error('No user is currently signed in.');
//     }
//   };

//   const reauthenticateWithNaver = async () => {
//     const state = Math.random().toString(36).substring(2, 15);
//     sessionStorage.setItem('naverOAuthState', state);
//     const naverUrl = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${process.env.REACT_APP_NAVER_CLIENT_ID}&state=${state}&redirect_uri=${process.env.REACT_APP_NAVER_REDIRECT_URI}`;
//     window.location.href = naverUrl;
//   };

//   const reauthenticateWithKakao = async () => {
//     const kakaoUrl = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_KAKAO_REDIRECT_URI}&response_type=code`;
//     window.location.href = kakaoUrl;
//   };

//   const handleUpdatePassword = async () => {
//     if (newPassword !== confirmNewPassword) {
//       setError(t('passwordsDoNotMatch'));
//       return;
//     } else {
//       setError('');
//     }

//     if (!newPassword) {
//       setError(t('enterBothPasswords'));
//       return;
//     }

//     setLoading(true);
//     const user = auth.currentUser;
//     try {
//       if (!user || !user.providerData || user.providerData.length === 0) {
//         throw new Error('No provider data available');
//       }

//       const providerId = user.providerData[0].providerId;

//       if (providerId === 'google.com') {
//         await reauthenticateWithGoogle();
//       } else if (providerId === 'naver.com') {
//         await reauthenticateWithNaver();
//       } else if (providerId === 'kakao.com') {
//         await reauthenticateWithKakao();
//       } else {
//         throw new Error('Unsupported provider');
//       }

//       await updatePassword(user, newPassword);
//       toast.success(t('passwordUpdated'));
//       navigate('/');
//     } catch (error) {
//       if (error.code === 'auth/wrong-password') {
//         setError(t('incorrectCurrentPassword'));
//       } else {
//         handleError(t('failedToUpdatePassword'), error.message);
//       }
//     } finally {
//       setError('');
//       setNewPassword('');
//       setConfirmNewPassword('');
//       setLoading(false);
//     }
//   };

//   const upsertUserData = async (user, userInfo) => {
//     const userRef = doc(db, 'users', user.uid);
//     await setDoc(
//       userRef,
//       {
//         ...userInfo,
//         createdAt: new Date(),
//         modifiedAt: new Date(),
//       },
//       { merge: true }
//     );
//   };

//   const updateRegistrations = async (user, userInfo) => {
//     try {
//       const userRef = doc(db, 'users', user.uid);

//       await updateDoc(userRef, {
//         ...userInfo,
//         createdAt: new Date(),
//         modifiedAt: new Date(),
//       });
//     } catch (error) {
//       console.error('Error updating pending registrations:', error.message);
//       handleError(t('errorUpdatingRegistrations'), error.message);
//     }
//   };

//   return (
//     <Box sx={{ maxWidth: 600, mx: 'auto', p: 3 }}>
//       <ToastContainer />
//       <Paper elevation={3} sx={{ p: 3, borderRadius: 2 }}>
//         <Typography variant="h5" gutterBottom>
//           {t('changePassword')}
//         </Typography>

//         <TextField
//           variant="outlined"
//           margin="normal"
//           fullWidth
//           id="pw-new"
//           label={t('newPassword')}
//           placeholder={t('enterNewPassword')}
//           type="password"
//           value={newPassword || ''}
//           onChange={(e) => setNewPassword(e.target.value)}
//         />
//         <TextField
//           variant="outlined"
//           margin="normal"
//           fullWidth
//           id="confirm-new-pw"
//           label={t('confirmNewPassword')}
//           placeholder={t('confirmNewPassword')}
//           type="password"
//           value={confirmNewPassword || ''}
//           onChange={(e) => setConfirmNewPassword(e.target.value)}
//         />

//         {error && <Typography color="error">{error}</Typography>}

//         {isVerificationSent && (
//           <Button
//             fullWidth
//             variant="contained"
//             color="secondary"
//             sx={{ mt: 2 }}
//             onClick={handleVerificationComplete}
//           >
//             {t('completeVerification')}
//           </Button>
//         )}

//         <Button
//           fullWidth
//           variant="contained"
//           color="primary"
//           sx={{ mt: 2 }}
//           onClick={handleUpdatePassword}
//           disabled={loading}
//         >
//           {loading ? (
//             <CircularProgress size={24} color="inherit" />
//           ) : (
//             t('changePassword')
//           )}
//         </Button>
//       </Paper>
//     </Box>
//   );
// }

// export default SignUpSetPassword;

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  db,
  auth,
  doc,
  setDoc,
  getDoc,
  updateDoc,
  updateProfile,
  sendEmailVerification,
  updatePassword,
  EmailAuthProvider,
  googleAuthProvider,
  naverAuthProvider,
  kakaoAuthProvider,
} from '../firebaseConfig';

import {
  reauthenticateWithCredential,
  reauthenticateWithPopup,
} from 'firebase/auth';
import {
  Button,
  TextField,
  CircularProgress,
  Box,
  Typography,
  Paper,
} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
import useMessageBox from '../Common/useMessageBox';

function SignUpSetPassword({ onPrev, onNext, setIsStepCompleted }) {
  const { t } = useTranslation();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [isVerificationSent, setIsVerificationSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [MessageBoxInform, MessageBoxConfirm, MessageBoxRender] =
    useMessageBox();
  const handleError = (errorMessage, logMessage) => {
    setError(errorMessage);
    console.error(logMessage);
  };

  const sendVerificationEmail = async () => {
    try {
      await sendEmailVerification(auth.currentUser);
      setIsVerificationSent(true);
      toast.success(t('verificationEmailSent'));
    } catch (error) {
      handleError(t('errorSendingVerificationEmail'), error.message);
    }
  };

  const handleVerificationComplete = async () => {
    const user = auth.currentUser;

    try {
      await user.reload();

      if (user.emailVerified) {
        const userInfo = {
          email: user.email,
          name: name,
        };

        await upsertUserData(user, userInfo);

        const userInfoWithStep = {
          ...userInfo,
          step: process.env.REACT_APP_CREATE_ACCOUNT_VERIFIED,
        };

        await updateRegistrations(user, userInfoWithStep);

        try {
          await updateProfile(user, { displayName: name });
          toast.success(t('emailVerified'));

          if (setIsStepCompleted) {
            setIsStepCompleted(true);
          }
        } catch (profileError) {
          handleError(t('failedToUpdateProfile'), profileError.message);
        }
      } else {
        await sendVerificationEmail();
      }
    } catch (error) {
      console.error('Error handling verification:', error.message);
      handleError(t('errorVerifyingEmail'), error.message);
    }
  };

  const reauthenticate = async (currentPassword) => {
    const user = auth.currentUser;
    const credential = EmailAuthProvider.credential(
      user.email,
      currentPassword
    );
    return reauthenticateWithCredential(user, credential);
  };

  const reauthenticateWithGoogle = async () => {
    const user = auth.currentUser;

    if (user) {
      try {
        await reauthenticateWithPopup(user, googleAuthProvider);
        console.log('Reauthentication successful');
      } catch (error) {
        console.error('Error during reauthentication:', error);
      }
    } else {
      console.error('No user is currently signed in.');
    }
  };

  const reauthenticateWithNaver = async () => {
    const state = Math.random().toString(36).substring(2, 15);
    sessionStorage.setItem('naverOAuthState', state);
    const naverUrl = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${process.env.REACT_APP_NAVER_CLIENT_ID}&state=${state}&redirect_uri=${process.env.REACT_APP_NAVER_REDIRECT_URI}`;
    window.location.href = naverUrl;
  };

  const reauthenticateWithKakao = async () => {
    const kakaoUrl = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_KAKAO_REDIRECT_URI}&response_type=code`;
    window.location.href = kakaoUrl;
  };

  const handleUpdatePassword = async () => {
    if (newPassword !== confirmNewPassword) {
      setError(t('passwordsDoNotMatch'));
      return;
    } else {
      setError('');
    }

    if (!newPassword) {
      setError(t('enterBothPasswords'));
      return;
    }

    setLoading(true);
    const user = auth.currentUser;
    try {
      let providerId;
      if (user.providerData && user.providerData.length > 0) {
        providerId = user.providerData[0].providerId;
      } else {
        const userRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          providerId = userData.providerId;
        } else {
          throw new Error('No provider data available');
        }
      }

      if (providerId === 'google.com') {
        await reauthenticateWithGoogle();
      } else if (providerId === 'naver.com') {
        //await reauthenticateWithNaver();
      } else if (providerId === 'kakao.com') {
        //await reauthenticateWithKakao();
      } else {
        throw new Error('Unsupported provider');
      }

      await updatePassword(user, newPassword);
      //toast.success(t('passwordUpdated'));

      // 패스워드가 성공적으로 변경되면 setPassword 필드를 true로 업데이트
      const userRef = doc(db, 'users', user.uid);
      await updateDoc(userRef, { setPassword: true });
      await MessageBoxInform(t('passwordUpdated'));
      navigate('/');
    } catch (error) {
      if (error.code === 'auth/wrong-password') {
        setError(t('incorrectCurrentPassword'));
      } else {
        handleError(t('failedToUpdatePassword'), error.message);
      }
    } finally {
      setError('');
      setNewPassword('');
      setConfirmNewPassword('');
      setLoading(false);
    }
  };

  const upsertUserData = async (user, userInfo) => {
    const userRef = doc(db, 'users', user.uid);
    await setDoc(
      userRef,
      {
        ...userInfo,
        createdAt: new Date(),
        modifiedAt: new Date(),
      },
      { merge: true }
    );
  };

  const updateRegistrations = async (user, userInfo) => {
    try {
      const userRef = doc(db, 'users', user.uid);

      await updateDoc(userRef, {
        ...userInfo,
        createdAt: new Date(),
        modifiedAt: new Date(),
      });
    } catch (error) {
      console.error('Error updating pending registrations:', error.message);
      handleError(t('errorUpdatingRegistrations'), error.message);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <Box sx={{ maxWidth: 600, mx: 'auto', p: 3 }}>
        <ToastContainer />
        {MessageBoxRender()}
        <Paper elevation={3} sx={{ p: 3, borderRadius: 2 }}>
          <Typography variant="h5" gutterBottom>
            {t('setPassword')}
          </Typography>

          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="pw-new"
            label={t('newPassword')}
            placeholder={t('enterNewPassword')}
            type="password"
            value={newPassword || ''}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="confirm-new-pw"
            label={t('confirmNewPassword')}
            placeholder={t('confirmNewPassword')}
            type="password"
            value={confirmNewPassword || ''}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
          />

          {error && <Typography color="error">{error}</Typography>}

          {isVerificationSent && (
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              sx={{ mt: 2 }}
              onClick={handleVerificationComplete}
            >
              {t('completeVerification')}
            </Button>
          )}

          <Button
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
            onClick={handleUpdatePassword}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              t('setPassword')
            )}
          </Button>
        </Paper>
      </Box>
    </Box>
  );
}

export default SignUpSetPassword;
