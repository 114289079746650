import { db } from '../firebaseConfig';
import { doc, getDoc, updateDoc } from 'firebase/firestore';

export const fetchUserProfile = async (uid) => {
  try {
    const userRef = doc(db, 'users', uid); // 'users'는 Firestore에서 사용자 데이터를 저장하는 컬렉션 이름입니다.
    const userDoc = await getDoc(userRef);
    if (userDoc.exists()) {
      const userData = userDoc.data();
      return {
        displayName: userData.displayName,
        photoURL: userData.photoURL,
      }; // 사용자 문서에서 이름과 사진 URL 필드 추출
    } else {
      console.error('No such user document!');
      return null;
    }
  } catch (error) {
    console.error('Error fetching user data:', error);
    return null;
  }
};

export const updateUserProfile = async (uid, { displayName, photoUrl }) => {
  try {
    const userRef = doc(db, 'users', uid); // 'users'는 Firestore에서 사용자 데이터를 저장하는 컬렉션 이름입니다.

    const updates = {};
    if (displayName) updates.displayName = displayName;
    if (photoUrl) updates.photoUrl = photoUrl;

    await updateDoc(userRef, updates);
    console.log('User profile updated successfully!');
  } catch (error) {
    console.error('Error updating user profile:', error);
  }
};
