import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Typography,
  Link,
  List,
  ListItem,
  Tabs,
  Tab,
  Button,
  Select,
  MenuItem,
  useMediaQuery,
  IconButton,
  ListItemText,
  ListItemSecondaryAction,
} from '@mui/material';

const SearchTabBar = ({ onSelectTab, activeTab }) => {
  const { t } = useTranslation();

  return (
    <Box display="flex">
      <Button
        onClick={() => onSelectTab(1)}
        sx={{
          flex: 1,
          fontSize: '0.8rem',
          textAlign: 'center',
          color: 'black',
          backgroundColor: activeTab === 1 ? 'lightgray' : 'white',
          padding: '10px',
          border: 'none',
          cursor: 'pointer',
        }}
      >
        {t('rank1To10')}
      </Button>
      <Button
        onClick={() => onSelectTab(2)}
        sx={{
          flex: 1,
          fontSize: '0.8rem',
          textAlign: 'center',
          color: 'black',
          backgroundColor: activeTab === 2 ? 'lightgray' : 'white',
          padding: '10px',
          border: 'none',
          cursor: 'pointer',
        }}
      >
        {t('rank11To20')}
      </Button>
    </Box>
  );
};
export default SearchTabBar;
