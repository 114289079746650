import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const PopupBlockedNotification = ({ popupBlocked, handleLogin }) => {
  const { t } = useTranslation();

  return (
    <Box textAlign="center" mt={2}>
      {popupBlocked ? (
        <Box>
          <Typography variant="body1" gutterBottom>
            {t('popupBlocked.message')}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            component="a"
            href="https://support.google.com/chrome/answer/95472"
            target="_blank"
            sx={{ mt: 1 }}
          >
            {t('popupBlocked.howToAllowChrome')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            component="a"
            href="https://support.mozilla.org/en-US/kb/pop-block-settings-exceptions-troubleshooting"
            target="_blank"
            sx={{ mt: 1 }}
          >
            {t('popupBlocked.howToAllowFirefox')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            component="a"
            href="https://support.apple.com/en-us/guide/safari/sfri11471/mac"
            target="_blank"
            sx={{ mt: 1 }}
          >
            {t('popupBlocked.howToAllowSafari')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            component="a"
            href="https://support.microsoft.com/en-us/microsoft-edge/pop-up-blocker-in-microsoft-edge-4ae2de5d-0b26-cb8a-00a0-ec08a4e448af"
            target="_blank"
            sx={{ mt: 1 }}
          >
            {t('popupBlocked.howToAllowEdge')}
          </Button>
        </Box>
      ) : (
        <Button variant="contained" color="primary" onClick={handleLogin}>
          {t('popupBlocked.login')}
        </Button>
      )}
    </Box>
  );
};

export default PopupBlockedNotification;
