import React, { useState, useEffect } from 'react';
import {
  Paper,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

// 아이콘 임포트
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  '& .MuiStepConnector-line': {
    borderColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const stepIcons = {
  1: <MoveToInboxIcon />,
  2: <WarehouseIcon />,
  3: <LocalShippingIcon />,
  4: <WarehouseIcon />,
  5: <DirectionsCarIcon />,
  6: <CheckCircleIcon />,
};

// const steps = [
//   '상품 접수',
//   '터미널 입고',
//   '상품 이동 중',
//   '배송 터미널 도착',
//   '배송 출발',
//   '배송 완료',
// ];
const steps = ['결재 완료', '상품 준비중', '배송 시작', '배송 중', '배송 완료'];
const ColorlibStepIcon = styled('div')(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 40, // 아이콘 크기 축소
  height: 40, // 아이콘 크기 축소
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient(136deg, rgba(255, 215, 0, 1) 0%, rgba(255, 165, 0, 1) 50%, rgba(255, 215, 0, 1) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient(136deg, rgba(0, 255, 0, 1) 0%, rgba(0, 128, 0, 1) 50%, rgba(0, 255, 0, 1) 100%)',
  }),
}));

const TrackShipment = ({ trackingNumber, activeStep = 1 }) => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  // Large screen: 'md' 브레이크포인트 이상의 화면에서 true를 반환
  const largeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const hanjinUrl = `https://www.hanjin.co.kr/kor/CMS/DeliveryMgr/WaybillResult.do?wbl_num=${trackingNumber}`;
  const cjUrl = `https://www.cjlogistics.com/ko/tool/parcel/tracking?gnbInvcNo=${trackingNumber}`;
  const logenUrl = `https://www.ilogen.com/web/personal/trace/${trackingNumber}`;
  const kgLogisUrl = `https://www.kglogis.co.kr/delivery/delivery_result.jsp?item_no=${trackingNumber}`;

  const [shipmentInfo, setShipmentInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (trackingNumber) {
      trackShipment();
    } else {
      setError('아직 송장이 등록되지 않았습니다');
    }
  }, [trackingNumber]); // trackingNumber가 변경될 때마다 추적 실행

  const trackShipment = async () => {
    setLoading(true);
    setError('');
    setShipmentInfo(null);
    try {
      const response = await fetch(hanjinUrl);
      if (!response.ok) throw new Error('Failed to fetch shipment details');
      const data = await response.json();
      setShipmentInfo(data);
    } catch (err) {
      setError('Failed to retrieve shipment information');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Paper
      sx={{ padding: 3, marginTop: 3, width: largeScreen ? '100%' : 'auto' }}
    >
      <Typography
        variant="h6"
        align="center"
        sx={{ marginBottom: 2, fontSize: largeScreen ? '1.5rem' : '1.25rem' }}
      >
        4/25(목) 도착 예정
      </Typography>
      <Stepper
        alternativeLabel
        activeStep={activeStep - 1}
        connector={<QontoConnector />}
      >
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel
              StepIconComponent={(props) => (
                <ColorlibStepIcon
                  {...props}
                  ownerState={{
                    completed: index < activeStep - 1,
                    active: index === activeStep - 1,
                  }}
                >
                  {stepIcons[index + 1]}
                </ColorlibStepIcon>
              )}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Paper>
  );
};

export default TrackShipment;

// import React, { useState, useEffect } from 'react';
// import { Button, Typography, Paper, CircularProgress } from '@mui/material';

// const TrackShipment = ({ trackingNumber }) => {
//   const hanjinUrl = `https://www.hanjin.co.kr/kor/CMS/DeliveryMgr/WaybillResult.do?wbl_num=${trackingNumber}`;
//   const cjUrl = `https://www.cjlogistics.com/ko/tool/parcel/tracking?gnbInvcNo=${trackingNumber}`;
//   const logenUrl = `https://www.ilogen.com/web/personal/trace/${trackingNumber}`;
//   const kgLogisUrl = `https://www.kglogis.co.kr/delivery/delivery_result.jsp?item_no=${trackingNumber}`;

//   const [shipmentInfo, setShipmentInfo] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState('');

//   useEffect(() => {
//     if (trackingNumber) {
//       trackShipment();
//     } else {
//       setError('아직 송장이 등록되지 않았습니다');
//     }
//   }, [trackingNumber]); // trackingNumber가 변경될 때마다 추적 실행

//   const trackShipment = async () => {
//     setLoading(true);
//     setError('');
//     setShipmentInfo(null);
//     try {
//       const response = await fetch(hanjinUrl);
//       if (!response.ok) throw new Error('Failed to fetch shipment details');
//       const data = await response.json();
//       setShipmentInfo(data);
//     } catch (err) {
//       setError('Failed to retrieve shipment information');
//       console.error(err);
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <Paper style={{ padding: 20, maxWidth: 500, margin: '20px auto' }}>
//       <Typography variant="h6">Track Your Shipment</Typography>
//       <Button
//         variant="contained"
//         color="primary"
//         onClick={trackShipment}
//         disabled={loading || !trackingNumber}
//       >
//         {loading ? <CircularProgress size={24} /> : 'Track'}
//       </Button>
//       {error && (
//         <Typography color="error" style={{ marginTop: 10 }}>
//           {error}
//         </Typography>
//       )}
//       {shipmentInfo && (
//         <div style={{ marginTop: 20 }}>
//           <Typography variant="body1">
//             <strong>Status:</strong> {shipmentInfo.status}
//           </Typography>
//           <Typography variant="body1">
//             <strong>Estimated Delivery:</strong>{' '}
//             {shipmentInfo.estimatedDelivery}
//           </Typography>
//           {/* 추가적으로 배송 정보 표시 */}
//         </div>
//       )}
//     </Paper>
//   );
// };

// export default TrackShipment;

// import React, { useState } from 'react';
// import {
//   Typography,
//   Button,
//   TextField,
//   Select,
//   MenuItem,
//   FormControl,
//   InputLabel,
//   Paper,
// } from '@mui/material';

// const courierData = {
//   kr_kdexp: 'https://kdexp.com/newDeliverySearch.kd?barcode=',
//   kr_ilogen: 'https://www.ilogen.com/m/personal/trace/',
//   kr_epost:
//     'https://service.epost.go.kr/trace.RetrieveDomRigiTraceList.comm?displayHeader=N&sid1=',
//   kr_hanjin:
//     'https://www.hanjin.co.kr/kor/CMS/DeliveryMgr/WaybillResult.do?mCode=MN038&schLang=KR&wblnumText2=',
//   kr_cjlogistics: 'http://nplus.doortodoor.co.kr/web/detail.jsp?slipno=',
//   kr_lotteglogis:
//     'https://www.lotteglogis.com/mobile/reservation/tracking/linkView?InvNo=',
// };

// const TrackShipment = () => {
//   const [trackingNumber, setTrackingNumber] = useState('');
//   const [selectedCourier, setSelectedCourier] = useState('');

//   const handleCourierChange = (event) => {
//     setSelectedCourier(event.target.value);
//   };

//   const handleTrackingNumberChange = (event) => {
//     setTrackingNumber(event.target.value);
//   };

//   const openTrackingPage = () => {
//     if (trackingNumber && selectedCourier) {
//       const url = courierData[selectedCourier] + trackingNumber;
//       window.open(url, '_blank');
//     }
//   };

//   return (
//     <Paper style={{ padding: 20, maxWidth: 600, margin: '20px auto' }}>
//       <Typography variant="h6">배송 조회</Typography>
//       <FormControl fullWidth margin="normal">
//         <InputLabel id="courier-select-label">택배사 선택</InputLabel>
//         <Select
//           labelId="courier-select-label"
//           id="courier-select"
//           value={selectedCourier}
//           label="택배사 선택"
//           onChange={handleCourierChange}
//         >
//           {Object.keys(courierData).map((key) => (
//             <MenuItem key={key} value={key}>
//               {key}
//             </MenuItem>
//           ))}
//         </Select>
//       </FormControl>
//       <TextField
//         fullWidth
//         label="송장 번호"
//         value={trackingNumber}
//         onChange={handleTrackingNumberChange}
//         margin="normal"
//       />
//       <Button
//         variant="contained"
//         color="primary"
//         onClick={openTrackingPage}
//         disabled={!trackingNumber || !selectedCourier}
//       >
//         조회하기
//       </Button>
//     </Paper>
//   );
// };

// export default TrackShipment;
