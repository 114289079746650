// import React from 'react';
// import { Link } from 'react-router-dom';
// import moment from 'moment';
// import ImageSlider from '../../ImageSlider';
// import { useNavigate } from 'react-router-dom';
// import '../../css/store.css';

// moment.locale('ko');

// export const Store = ({ store }) => {
//     const navigate = useNavigate();
//     const formatState = (state) => {
//         switch (state) {
//   case 0:
//     return t('closed');
//   case 1:
//     return t('open');
//   case 2:
//     return t('out');
//   default:
//     return t('open');
// }
//     }
//     const getStoreStateClass = (state) => {
//         switch (state) {
//             case 0:
//                 return 'open';
//             case 1:
//                 return 'closed';
//             case 2:
//                 return 'away';
//             default:
//                 return 'open';
//         }
//     }
//     const mainImagesClick = (index) => {
//         navigate('/imageviewer', { state: { files: store.mainImages, index: index } });
//       };
//       const subImagesClick = (index) => {
//         navigate('/imageviewer', { state: { files: store.subImages, index: index } });
//       };
//     return (
//         <div className='store'>
//             <Link to={`/store_home/${store.id}`} className="custom-link">
//                 <div>
//                     <div className="thumbnail"
//                         style={{ backgroundImage: `url(${store.mainImages && store.mainImages.length > 0 ? store.mainImages[0]
//                             : `${process.env.REACT_APP_STORE_DEFAULT_IMAGE_URL}`})` }}>
//                     </div>
//                     <div className="flex-grow-1">
//                         <h5 className="title">
//                             {store.storeName}
//                         </h5>
//                         <h5 className="name">
//                             {store.name}
//                         </h5>
//                         <p className="desc">{store.description}</p>
//                         <p className={`state ${getStoreStateClass(store.state)}`}>{formatState(store.state)}</p>
//                         <div className="actions">
//                             <span>
//                                 <span className="icon-heart">좋아요</span>{store.likes}
//                             </span>
//                             <span>
//                                 <span className="icon-views">조회수</span>{store.views}
//                             </span>
//                             <span>
//                                 <span className="icon-chat">채팅</span>{store.chats}
//                             </span>
//                         </div>
//                     </div>
//                 </div>
//             </Link>
//         </div>

//         // </div>
//     );
// }
// export default Store;

// import React from 'react';
// import { useNavigate, Link } from 'react-router-dom';
// import {
//   Card,
//   CardActionArea,
//   CardMedia,
//   CardContent,
//   Typography,
//   Box,
//   Chip,
//   CardActions,
//   Button,
// } from '@mui/material';
// import FavoriteIcon from '@mui/icons-material/Favorite';
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import ChatIcon from '@mui/icons-material/Chat';
// import StorefrontIcon from '@mui/icons-material/Storefront';
// import InfoIcon from '@mui/icons-material/Info';
// import moment from 'moment';

// moment.locale('ko');

// const Store = ({ store }) => {
//   const navigate = useNavigate();
// const formatState = (state) => {
// switch (state) {
//   case 0:
//     return t('closed');
//   case 1:
//     return t('open');
//   case 2:
//     return t('out');
//   default:
//     return t('open');
// }
// };

//   const getStoreStateClass = (state) => {
//     switch (state) {
//       case 0:
//         return 'store-open';
//       case 1:
//         return 'store-closed';
//       case 2:
//         return 'store-away';
//       default:
//         return 'store-open';
//     }
//   };

//   const mainImagesClick = (index) => {
//     navigate('/imageviewer', {
//       state: { files: store.mainImages, index: index },
//     });
//   };

//   const subImagesClick = (index) => {
//     navigate('/imageviewer', {
//       state: { files: store.subImages, index: index },
//     });
//   };
//   const handleViewStore = () => {
//     navigate(`/store_home/${store.id}`);
//   };

//   // Format operational status
//   const formatStatus = (status) => {
//     switch (status) {
//       case 0:
//         return { label: 'Closed', color: 'error' };
//       case 1:
//         return { label: 'Open', color: 'success' };
//       case 2:
//         return { label: 'Away', color: 'warning' };
//       default:
//         return { label: 'Open', color: 'success' };
//     }
//   };

//   const status = formatStatus(store.state);
//   return (
//     <Card sx={{ maxWidth: 345, m: 1, boxShadow: 3 }}>
//       <CardMedia
//         component="img"
//         height="140"
// image={
//   store.mainImages && store.mainImages.length > 0
//     ? store.mainImages[0]
//     : 'default_store_image.jpg'
// }
// alt={`${store.storeName} image`}
//       />
//       <CardContent>
//         <Typography gutterBottom variant="h5" component="div">
//           {store.storeName}
//         </Typography>
//         <Typography variant="body2" color="text.secondary">
//           {store.description}
//         </Typography>
//         <Box sx={{ my: 1 }}>
//           <Chip
//             icon={<StorefrontIcon />}
//             label={status.label}
//             color={status.color}
//           />
//         </Box>
//       </CardContent>
//       <CardActions>
//         <Button
//           size="small"
//           color="primary"
//           startIcon={<InfoIcon />}
//           onClick={handleViewStore}
//         >
//           Visit Store
//         </Button>
//       </CardActions>
//     </Card>
//   );
// };

// export default Store;

// import React from 'react';
// import { useNavigate, Link } from 'react-router-dom';
// import PropTypes from 'prop-types';
// import {
//   Card,
//   CardActionArea,
//   CardMedia,
//   CardContent,
//   Typography,
//   Box,
//   Chip,
//   styled,
//   IconButton,
// } from '@mui/material';
// import { useDispatch, useSelector } from 'react-redux';
// import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
// import StarIcon from '@mui/icons-material/Star';
// import FavoriteIcon from '@mui/icons-material/Favorite';
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import ChatIcon from '@mui/icons-material/Chat';
// import StorefrontIcon from '@mui/icons-material/Storefront';
// import InfoIcon from '@mui/icons-material/Info';
// import moment from 'moment';
// import { useTranslation } from 'react-i18next';

// // 스타일 컴포넌트 생성
// const StyledCard = styled(Card)(({ theme }) => ({
//   borderRadius: '8px',
//   margin: '5px',
//   padding: '5px',
//   boxShadow: theme.shadows[3],
//   overflow: 'visible',
// }));

// const StyledCardMedia = styled(CardMedia)({
//   height: 'auto',
//   paddingTop: '56.25%', // 16:9 비율 유지
//   objectFit: 'cover',
//   backgroundPosition: 'center',
//   borderRadius: '8px',
// });

// const Store = ({ store }) => {
//   const { t, i18n } = useTranslation();
//   const navigate = useNavigate();
//   const currentUser = useSelector((state) => state.auth.currentUser);
//   const handleGoToCart = (store) => {
//     navigate(`/cartlist/${currentUser.uid}/${store.id}`);
//   };

//   const formatStatus = (state) => {
//     switch (state) {
//       case 0:
//         return { label: 'Closed', color: 'error' };
//       case 1:
//         return { label: 'Open', color: 'success' };
//       case 2:
//         return { label: 'Away', color: 'warning' };
//       default:
//         return { label: 'Open', color: 'success' };
//     }
//   };

//   const status = formatStatus(store.state);

//   return (
//     <Card
//       sx={{
//         maxWidth: '100%',
//         borderRadius: '8px',
//         m: 0,
//         margin: '5px',
//         padding: '5px',
//         boxShadow: 3,
//         overflow: 'visible',
//       }}
//     >
//       <CardActionArea component={Link} to={`/store_home/${store.id}`}>
//         <CardMedia
//           component="img"
//           image={
//             store.mainImages && store.mainImages.length > 0
//               ? store.mainImages[0]
//               : process.env.REACT_APP_STORE_DEFAULT_IMAGE_URL
//           }
//           sx={{
//             height: 'auto',
//             aspectRatio: '16 / 9',
//             objectFit: 'cover',
//             width: '100%',
//             borderRadius: '8px',
//           }}
//           alt={t('storeImageAlt', { storeName: store.storeName })}
//         />
//         <CardContent
//           sx={{
//             '&:last-child': {
//               pb: 1,
//             },
//           }}
//         >
//           <Typography gutterBottom variant="h5" component="div">
//             {store.storeName}
//           </Typography>
//           <Typography variant="subtitle1" color="text.secondary">
//             {store.name}
//           </Typography>
//           <Typography variant="body2" color="text.secondary">
//             {store.description}
//           </Typography>

//           <Box
//             sx={{
//               display: 'flex',
//               alignItems: 'center',
//               justifyContent: 'space-between',
//             }}
//           >
//             <Box sx={{ display: 'flex', alignItems: 'center' }}>
//               <StarIcon color="error" />
//               <Typography variant="body2" sx={{ ml: 0.5 }}>
//                 {store.likes}
//               </Typography>
//             </Box>
//             <Box sx={{ display: 'flex', alignItems: 'center' }}>
//               <VisibilityIcon color="action" />
//               <Typography variant="body2" sx={{ ml: 0.5 }}>
//                 {store.views}
//               </Typography>
//             </Box>
//             <Box sx={{ display: 'flex', alignItems: 'center' }}>
//               <ChatIcon color="primary" />
//               <Typography variant="body2" sx={{ ml: 0.5 }}>
//                 {store.chats}
//               </Typography>
//             </Box>
//           </Box>
//         </CardContent>
//       </CardActionArea>
//       <Box
//         sx={{
//           display: 'flex',
//           alignItems: 'center',
//           justifyContent: 'space-between',
//           my: 1,
//         }}
//       >
//         <Chip
//           icon={<StorefrontIcon />}
//           label={t(status.label)}
//           color={status.color}
//         />
//         <IconButton
//           color="primary"
//           aria-label={t('addToCart')}
//           onClick={(event) => {
//             event.stopPropagation();
//             handleGoToCart(store);
//           }}
//         >
//           <ShoppingCartIcon />
//         </IconButton>
//       </Box>
//     </Card>
//   );
// };

// Store.propTypes = {
//   store: PropTypes.object.isRequired,
// };

// export default Store;

import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  Box,
  Chip,
  styled,
  IconButton,
  useMediaQuery,
} from '@mui/material';
import { useSelector } from 'react-redux';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import StarIcon from '@mui/icons-material/Star';
import FavoriteIcon from '@mui/icons-material/Favorite';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ChatIcon from '@mui/icons-material/Chat';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import StoreRatings from './StoreRatings';

// 스타일 컴포넌트 생성
const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: '8px',
  margin: '5px',
  padding: '5px',
  boxShadow: theme.shadows[3],
  overflow: 'visible',
}));

const StyledCardMedia = styled(CardMedia)({
  height: 'auto',
  paddingTop: '56.25%', // 16:9 비율 유지
  objectFit: 'cover',
  backgroundPosition: 'center',
  borderRadius: '8px',
});

const formatTime = (timestamp) => {
  if (!timestamp || !timestamp.toDate) return 'N/A';
  const date = timestamp.toDate();
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  return `${hours}:${minutes}`;
};

const isOpen = (startTime, endTime) => {
  const now = new Date();
  const start = new Date(startTime.toDate());
  const end = new Date(endTime.toDate());
  start.setFullYear(now.getFullYear(), now.getMonth(), now.getDate());
  end.setFullYear(now.getFullYear(), now.getMonth(), now.getDate());

  return now >= start && now <= end;
};

const Store = ({ store, horizontal }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [statusLabel, setStatusLabel] = useState('');

  useEffect(() => {
    if (!store) return;

    const now = new Date();
    const day = now.getDay();
    const open =
      day === 0 || day === 6
        ? isOpen(store.weekendStartTime, store.weekendEndTime)
        : isOpen(store.weekdayStartTime, store.weekdayEndTime);

    setStatusLabel(open ? 'Open' : 'Closed');
  }, [store]);

  const handleGoToCart = (store) => {
    navigate(`/cartlist/${currentUser.uid}/${store.id}`);
  };

  return (
    <StyledCard
      sx={{
        maxWidth: '100%',
        m: '5px',
        p: '5px',
        boxShadow: 3,
        borderRadius: 2,
        overflow: 'hidden',
        '@media (max-width: 600px)': {
          m: '2px',
          p: '2px',
        },
      }}
    >
      <CardActionArea component={Link} to={`/store_home/${store.id}`}>
        {horizontal && isMobile ? (
          <Box display="flex" flexDirection="row" sx={{ height: '150px' }}>
            <Box
              sx={{
                width: '40%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <StyledCardMedia
                component="img"
                image={
                  store.mainImages && store.mainImages.length > 0
                    ? store.mainImages[0]
                    : process.env.REACT_APP_STORE_DEFAULT_IMAGE_URL
                }
                sx={{
                  width: '100%',
                  height: '100%',
                  backgroundImage: `url(${
                    store.mainImages && store.mainImages.length > 0
                      ? store.mainImages[0]
                      : process.env.REACT_APP_STORE_DEFAULT_IMAGE_URL
                  })`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
                alt={t('storeImageAlt', { storeName: store.storeName })}
              />
            </Box>
            <CardContent
              sx={{
                width: '60%',
                padding: '8px',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                marginTop: 'auto',
                marginBottom: 'auto',
              }}
            >
              <Typography
                gutterBottom
                variant="h6"
                component="div"
                noWrap
                sx={{
                  lineHeight: '1.5',
                  maxHeight: '3em',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {store.storeName}
              </Typography>

              <Typography
                variant="body2"
                color="text.secondary"
                noWrap
                sx={{
                  lineHeight: '1.5',
                  maxHeight: '3em',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {store.description}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-end',
                  gap: 2,
                  mt: 'auto',
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <StarIcon color="error" />
                  <Typography variant="body2" sx={{ ml: 0.5 }}>
                    {store.likes}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <VisibilityIcon color="action" />
                  <Typography variant="body2" sx={{ ml: 0.5 }}>
                    {store.views}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <ChatIcon color="primary" />
                  <Typography variant="body2" sx={{ ml: 0.5 }}>
                    {store.chats}
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Box>
        ) : (
          <>
            <StyledCardMedia
              component="img"
              image={
                store.mainImages && store.mainImages.length > 0
                  ? store.mainImages[0]
                  : process.env.REACT_APP_STORE_DEFAULT_IMAGE_URL
              }
              sx={{
                height: 0,
                paddingTop: '56.25%',
                backgroundImage: `url(${
                  store.mainImages && store.mainImages.length > 0
                    ? store.mainImages[0]
                    : process.env.REACT_APP_STORE_DEFAULT_IMAGE_URL
                })`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                borderRadius: '8px',
                '@media (max-width: 600px)': {
                  height: '150px',
                  paddingTop: 0,
                },
              }}
              alt={t('storeImageAlt', { storeName: store.storeName })}
            />
            <CardContent
              sx={{
                paddingTop: '16px',
                paddingBottom: '16px',
              }}
            >
              <Typography
                gutterBottom
                variant="h6"
                component="div"
                noWrap
                sx={{
                  lineHeight: '1.5',
                  maxHeight: '3em',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {store.storeName}
              </Typography>

              <Typography
                variant="body2"
                color="text.secondary"
                noWrap
                sx={{
                  lineHeight: '1.5',
                  maxHeight: '3em',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {store.description}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  mt: 1,
                  mb: 2,
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <StarIcon color="error" />
                  <Typography variant="body2" sx={{ ml: 0.5 }}>
                    {store.likes}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <VisibilityIcon color="action" />
                  <Typography variant="body2" sx={{ ml: 0.5 }}>
                    {store.views}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <ChatIcon color="primary" />
                  <Typography variant="body2" sx={{ ml: 0.5 }}>
                    {store.chats}
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </>
        )}
      </CardActionArea>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          my: 1,
          paddingLeft: horizontal && isMobile ? '16px' : '2px',
        }}
      >
        <Chip
          icon={<StorefrontIcon />}
          label={statusLabel}
          color={statusLabel === 'Open' ? 'success' : 'error'}
        />
        <StoreRatings storeId={store.id} />
        <IconButton
          color="primary"
          aria-label={t('addToCart')}
          onClick={(event) => {
            event.stopPropagation();
            handleGoToCart(store);
          }}
        >
          <ShoppingCartIcon />
        </IconButton>
      </Box>
    </StyledCard>
  );
};

Store.propTypes = {
  store: PropTypes.object.isRequired,
  horizontal: PropTypes.bool,
  isMobile: PropTypes.bool,
};

export default Store;

// const Store = ({ store, horizontal }) => {
//   const { t } = useTranslation();
//   const navigate = useNavigate();
//   const theme = useTheme();
//   const currentUser = useSelector((state) => state.auth.currentUser);
//   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

//   const handleGoToCart = (store) => {
//     navigate(`/cartlist/${currentUser.uid}/${store.id}`);
//   };

//   const formatStatus = (state) => {
//     switch (state) {
//       case 0:
//         return { label: 'Closed', color: 'error' };
//       case 1:
//         return { label: 'Open', color: 'success' };
//       case 2:
//         return { label: 'Away', color: 'warning' };
//       default:
//         return { label: 'Open', color: 'success' };
//     }
//   };

//   const status = formatStatus(store.state);

//   return (
//     <StyledCard
//       sx={{
//         maxWidth: '100%',
//         m: '5px',
//         p: '5px',
//         boxShadow: 3,
//         borderRadius: 2,
//         overflow: 'hidden',
//         '@media (max-width: 600px)': {
//           m: '2px',
//           p: '2px',
//         },
//       }}
//     >
//       <CardActionArea component={Link} to={`/store_home/${store.id}`}>
//         {horizontal && isMobile ? (
//           <Box display="flex" flexDirection="row" sx={{ height: '150px' }}>
//             <Box
//               sx={{
//                 width: '40%',
//                 height: '100%',
//                 display: 'flex',
//                 alignItems: 'center',
//                 justifyContent: 'center',
//               }}
//             >
//               <StyledCardMedia
//                 component="img"
//                 image={
//                   store.mainImages && store.mainImages.length > 0
//                     ? store.mainImages[0]
//                     : process.env.REACT_APP_STORE_DEFAULT_IMAGE_URL
//                 }
//                 sx={{
//                   width: '100%',
//                   height: '100%',
//                   backgroundImage: `url(${
//                     store.mainImages && store.mainImages.length > 0
//                       ? store.mainImages[0]
//                       : process.env.REACT_APP_STORE_DEFAULT_IMAGE_URL
//                   })`,
//                   backgroundSize: 'cover',
//                   backgroundPosition: 'center',
//                 }}
//                 alt={t('storeImageAlt', { storeName: store.storeName })}
//               />
//             </Box>
//             <CardContent
//               sx={{
//                 width: '60%',
//                 padding: '8px',
//                 height: '100%',
//                 display: 'flex',
//                 flexDirection: 'column',
//                 justifyContent: 'center',
//                 marginTop: 'auto',
//                 marginBottom: 'auto',
//               }}
//             >
//               <Typography
//                 gutterBottom
//                 variant="h6"
//                 component="div"
//                 noWrap
//                 sx={{
//                   lineHeight: '1.5',
//                   maxHeight: '3em',
//                   overflow: 'hidden',
//                   textOverflow: 'ellipsis',
//                 }}
//               >
//                 {store.storeName}
//               </Typography>
//               <Typography
//                 variant="body2"
//                 color="text.secondary"
//                 noWrap
//                 sx={{
//                   lineHeight: '1.5',
//                   maxHeight: '3em',
//                   overflow: 'hidden',
//                   textOverflow: 'ellipsis',
//                 }}
//               >
//                 {store.name}
//               </Typography>
//               <Typography
//                 variant="body2"
//                 color="text.secondary"
//                 noWrap
//                 sx={{
//                   lineHeight: '1.5',
//                   maxHeight: '3em',
//                   overflow: 'hidden',
//                   textOverflow: 'ellipsis',
//                 }}
//               >
//                 {store.description}
//               </Typography>
//               <Box
//                 sx={{
//                   display: 'flex',
//                   alignItems: 'flex-end',
//                   justifyContent: 'flex-end',
//                   gap: 2,
//                   mt: 'auto',
//                 }}
//               >
//                 <Box sx={{ display: 'flex', alignItems: 'center' }}>
//                   <StarIcon color="error" />
//                   <Typography variant="body2" sx={{ ml: 0.5 }}>
//                     {store.likes}
//                   </Typography>
//                 </Box>
//                 <Box sx={{ display: 'flex', alignItems: 'center' }}>
//                   <VisibilityIcon color="action" />
//                   <Typography variant="body2" sx={{ ml: 0.5 }}>
//                     {store.views}
//                   </Typography>
//                 </Box>
//                 <Box sx={{ display: 'flex', alignItems: 'center' }}>
//                   <ChatIcon color="primary" />
//                   <Typography variant="body2" sx={{ ml: 0.5 }}>
//                     {store.chats}
//                   </Typography>
//                 </Box>
//               </Box>
//             </CardContent>
//           </Box>
//         ) : (
//           <>
//             <StyledCardMedia
//               component="img"
//               image={
//                 store.mainImages && store.mainImages.length > 0
//                   ? store.mainImages[0]
//                   : process.env.REACT_APP_STORE_DEFAULT_IMAGE_URL
//               }
//               sx={{
//                 height: 0,
//                 paddingTop: '56.25%',
//                 backgroundImage: `url(${
//                   store.mainImages && store.mainImages.length > 0
//                     ? store.mainImages[0]
//                     : process.env.REACT_APP_STORE_DEFAULT_IMAGE_URL
//                 })`,
//                 backgroundSize: 'cover',
//                 backgroundPosition: 'center',
//                 borderRadius: '8px',
//                 '@media (max-width: 600px)': {
//                   height: '150px',
//                   paddingTop: 0,
//                 },
//               }}
//               alt={t('storeImageAlt', { storeName: store.storeName })}
//             />
//             <CardContent
//               sx={{
//                 paddingTop: '16px',
//                 paddingBottom: '16px',
//               }}
//             >
//               <Typography
//                 gutterBottom
//                 variant="h6"
//                 component="div"
//                 noWrap
//                 sx={{
//                   lineHeight: '1.5',
//                   maxHeight: '3em',
//                   overflow: 'hidden',
//                   textOverflow: 'ellipsis',
//                 }}
//               >
//                 {store.storeName}
//               </Typography>
//               <Typography
//                 gutterBottom
//                 variant="subtitle1"
//                 color="text.secondary"
//                 component="div"
//                 noWrap
//                 sx={{
//                   lineHeight: '1.5',
//                   maxHeight: '3em',
//                   overflow: 'hidden',
//                   textOverflow: 'ellipsis',
//                 }}
//               >
//                 {store.name}
//               </Typography>
//               <Typography
//                 variant="body2"
//                 color="text.secondary"
//                 noWrap
//                 sx={{
//                   lineHeight: '1.5',
//                   maxHeight: '3em',
//                   overflow: 'hidden',
//                   textOverflow: 'ellipsis',
//                 }}
//               >
//                 {store.description}
//               </Typography>
//               <Box
//                 sx={{
//                   display: 'flex',
//                   alignItems: 'center',
//                   justifyContent: 'space-between',
//                   mt: 1,
//                   mb: 2,
//                 }}
//               >
//                 <Box sx={{ display: 'flex', alignItems: 'center' }}>
//                   <StarIcon color="error" />
//                   <Typography variant="body2" sx={{ ml: 0.5 }}>
//                     {store.likes}
//                   </Typography>
//                 </Box>
//                 <Box sx={{ display: 'flex', alignItems: 'center' }}>
//                   <VisibilityIcon color="action" />
//                   <Typography variant="body2" sx={{ ml: 0.5 }}>
//                     {store.views}
//                   </Typography>
//                 </Box>
//                 <Box sx={{ display: 'flex', alignItems: 'center' }}>
//                   <ChatIcon color="primary" />
//                   <Typography variant="body2" sx={{ ml: 0.5 }}>
//                     {store.chats}
//                   </Typography>
//                 </Box>
//               </Box>
//             </CardContent>
//           </>
//         )}
//       </CardActionArea>
//       <Box
//         sx={{
//           display: 'flex',
//           alignItems: 'center',
//           justifyContent: 'space-between',
//           my: 1,
//           paddingLeft: horizontal && isMobile ? '16px' : 'inherit',
//         }}
//       >
//         <Chip
//           icon={<StorefrontIcon />}
//           label={t(status.label)}
//           color={status.color}
//         />
//         <IconButton
//           color="primary"
//           aria-label={t('addToCart')}
//           onClick={(event) => {
//             event.stopPropagation();
//             handleGoToCart(store);
//           }}
//         >
//           <ShoppingCartIcon />
//         </IconButton>
//       </Box>
//     </StyledCard>
//   );
// };

// Store.propTypes = {
//   store: PropTypes.object.isRequired,
//   horizontal: PropTypes.bool,
//   isMobile: PropTypes.bool,
// };

// export default Store;
