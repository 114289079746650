// import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import {
//   Container,
//   Box,
//   Typography,
//   CircularProgress,
//   Button,
//   Card,
//   CardContent,
//   CardMedia,
// } from '@mui/material';
// import { doc, getDoc } from 'firebase/firestore';
// import { db } from '../firebaseConfig';
// import axios from 'axios';

// function MyTownWeather() {
//   const [loading, setLoading] = useState(true);
//   const [locationName, setLocationName] = useState('');
//   const [weatherData, setWeatherData] = useState(null);
//   const navigate = useNavigate();
//   const currentUser = useSelector((state) => state.auth.currentUser);

//   useEffect(() => {
//     const fetchLocation = async () => {
//       if (currentUser) {
//         const userRef = doc(db, 'users', currentUser.uid);
//         const userDoc = await getDoc(userRef);
//         if (userDoc.exists()) {
//           const userData = userDoc.data();
//           const selectedTown = userData.selectedTown;
//           const locationData =
//             selectedTown === 1 ? userData.town1 : userData.town2;
//           if (locationData) {
//             setLocationName(locationData.name);
//             fetchWeather(locationData.lat, locationData.lng);
//           }
//         }
//       }
//     };

//     //     const fetchWeather = async (lat, lon) => {
//     //       try {
//     //         const apiKey = process.env.REACT_APP_OPENWEATHERMAP_API_KEY;
//     //         const response = await axios.get(
//     //           `https://api.openweathermap.org/data/2.5/weather`,
//     //           {
//     //             params: {
//     //               lat,
//     //               lon,
//     //               units: 'metric',
//     //               appid: apiKey,
//     //             },
//     //           }
//     //         );

//     //         setWeatherData(response.data);
//     //       } catch (error) {
//     //         console.error('Failed to fetch weather data', error);
//     //       } finally {
//     //         setLoading(false);
//     //       }
//     //     };

//     //     fetchLocation();
//     //   }, [currentUser]);

//     const fetchWeather = async (latitude, longitude) => {
//       try {
//         const serverUrl =
//           'https://asia-northeast3-apple-market-af8c6.cloudfunctions.net/getWeatherByLocation';
//         const response = await axios.post(serverUrl, {
//           myLocation: { latitude, longitude },
//         });
//         setWeatherData(response.data);
//       } catch (error) {
//         console.error('Failed to fetch weather data', error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchLocation();
//   }, [currentUser]);

//   if (loading) {
//     return (
//       <Container maxWidth="sm">
//         <Box
//           display="flex"
//           justifyContent="center"
//           alignItems="center"
//           minHeight="100vh"
//         >
//           <CircularProgress />
//         </Box>
//       </Container>
//     );
//   }

//   return (
//     <Container
//       maxWidth="sm"
//       sx={{
//         height: '30vh',
//         display: 'flex',
//         flexDirection: 'column',
//         justifyContent: 'center',
//         alignItems: 'center',
//       }}
//     >
//       <Typography variant="h5" component="h1" gutterBottom>
//         {locationName ? `내 동네: ${locationName}` : '내 동네를 설정해주세요'}
//       </Typography>
//       {/* {weatherData && (
//         <Card sx={{ display: 'flex', marginTop: 2 }}>
//           <CardMedia
//             component="img"
//             sx={{ width: 151 }}
//             image={`http://openweathermap.org/img/wn/${weatherData.weather[0].icon}@2x.png`}
//             alt="Weather icon"
//           />
//           <Box sx={{ display: 'flex', flexDirection: 'column' }}>
//             <CardContent sx={{ flex: '1 0 auto' }}>
//               <Typography component="div" variant="h5">
//                 {weatherData.main.temp}°C
//               </Typography>
//               <Typography
//                 variant="subtitle1"
//                 color="text.secondary"
//                 component="div"
//               >
//                 {weatherData.weather[0].description}
//               </Typography>
//             </CardContent>
//           </Box>
//         </Card>
//       )} */}
//       {weatherData && (
//         <Card sx={{ display: 'flex', marginTop: 2 }}>
//           <Box sx={{ display: 'flex', flexDirection: 'column' }}>
//             <CardContent sx={{ flex: '1 0 auto' }}>
//               <Typography component="div" variant="h5">
//                 온도: {weatherData.temperature}
//               </Typography>
//               <Typography
//                 variant="subtitle1"
//                 color="text.secondary"
//                 component="div"
//               >
//                 습도: {weatherData.humidity}
//               </Typography>
//               <Typography
//                 variant="subtitle1"
//                 color="text.secondary"
//                 component="div"
//               >
//                 하늘 상태: {weatherData.skyCondition}
//               </Typography>
//               <Typography
//                 variant="subtitle1"
//                 color="text.secondary"
//                 component="div"
//               >
//                 강수량: {weatherData.rainfall}
//               </Typography>
//             </CardContent>
//           </Box>
//         </Card>
//       )}

//       <Button
//         variant="contained"
//         color="primary"
//         onClick={() => navigate('/settings/mytown')}
//       >
//         동네 설정하기
//       </Button>
//     </Container>
//   );
// }

// export default MyTownWeather;

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Box,
  Typography,
  CircularProgress,
  Button,
  Card,
  CardContent,
} from '@mui/material';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import axios from 'axios';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

const scroll = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
`;

const ScrollingBar = styled(Typography)`
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  animation: ${scroll} 10s linear infinite;
`;

function MyTownWeather() {
  const [loading, setLoading] = useState(true);
  const [locationName, setLocationName] = useState('');
  const [weatherData, setWeatherData] = useState(null);
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.auth.currentUser);

  useEffect(() => {
    const fetchLocation = async () => {
      if (currentUser) {
        const userRef = doc(db, 'users', currentUser.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          const selectedTown = userData.selectedTown;
          const locationData =
            selectedTown === 1 ? userData.town1 : userData.town2;
          if (locationData) {
            setLocationName(locationData.name);
            fetchWeather(locationData.lat, locationData.lng);
          }
        }
      }
    };

    const fetchWeather = async (latitude, longitude) => {
      try {
        const serverUrl =
          'https://asia-northeast3-apple-market-af8c6.cloudfunctions.net/getWeatherByLocation';
        const response = await axios.post(serverUrl, {
          myLocation: { latitude, longitude },
        });
        setWeatherData(response.data);
      } catch (error) {
        console.error('Failed to fetch weather data', error);
      } finally {
        setLoading(false);
      }
    };

    fetchLocation();
  }, [currentUser]);

  if (loading) {
    return (
      <Container maxWidth="sm">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  return (
    <Container
      maxWidth="sm"
      sx={{
        height: '30vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography variant="h5" component="h1" gutterBottom>
        {locationName ? `내 동네: ${locationName}` : '내 동네를 설정해주세요'}
      </Typography>
      {weatherData && (
        <Box
          sx={{
            width: '100%',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            border: '1px solid #ccc',
            padding: '10px',
            marginTop: 2,
          }}
        >
          <ScrollingBar variant="h6">
            온도: {weatherData.temperature} | 습도: {weatherData.humidity} |
            하늘 상태: {weatherData.skyCondition} | 강수량:{' '}
            {weatherData.rainfall}
          </ScrollingBar>
        </Box>
      )}
      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate('/settings/mytown')}
        sx={{ marginTop: 2 }}
      >
        동네 설정하기
      </Button>
    </Container>
  );
}

export default MyTownWeather;

// import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import {
//   Container,
//   Box,
//   Typography,
//   CircularProgress,
//   Button,
//   Card,
//   CardContent,
// } from '@mui/material';
// import { doc, getDoc } from 'firebase/firestore';
// import { db } from '../firebaseConfig';
// import axios from 'axios';
// import { keyframes } from '@emotion/react';
// import styled from '@emotion/styled';

// const scrollVertical = keyframes`
//   0% {
//     transform: translateY(100%);
//   }
//   100% {
//     transform: translateY(-100%);
//   }
// `;

// const ScrollingBox = styled(Box)`
//   display: inline-block;
//   white-space: nowrap;
//   overflow: hidden;
//   animation: ${scrollVertical} 10s linear infinite;
// `;

// function MyTownWeather() {
//   const [loading, setLoading] = useState(true);
//   const [locationName, setLocationName] = useState('');
//   const [weatherData, setWeatherData] = useState(null);
//   const navigate = useNavigate();
//   const currentUser = useSelector((state) => state.auth.currentUser);

//   useEffect(() => {
//     const fetchLocation = async () => {
//       if (currentUser) {
//         const userRef = doc(db, 'users', currentUser.uid);
//         const userDoc = await getDoc(userRef);
//         if (userDoc.exists()) {
//           const userData = userDoc.data();
//           const selectedTown = userData.selectedTown;
//           const locationData =
//             selectedTown === 1 ? userData.town1 : userData.town2;
//           if (locationData) {
//             setLocationName(locationData.name);
//             fetchWeather(locationData.lat, locationData.lng);
//           }
//         }
//       }
//     };

//     const fetchWeather = async (latitude, longitude) => {
//       try {
//         const serverUrl =
//           'https://asia-northeast3-apple-market-af8c6.cloudfunctions.net/getWeatherByLocation';
//         const response = await axios.post(serverUrl, {
//           myLocation: { latitude, longitude },
//         });
//         setWeatherData(response.data);
//       } catch (error) {
//         console.error('Failed to fetch weather data', error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchLocation();
//   }, [currentUser]);

//   if (loading) {
//     return (
//       <Container maxWidth="sm">
//         <Box
//           display="flex"
//           justifyContent="center"
//           alignItems="center"
//           minHeight="100vh"
//         >
//           <CircularProgress />
//         </Box>
//       </Container>
//     );
//   }

//   return (
//     <Container
//       maxWidth="sm"
//       sx={{
//         height: '30vh',
//         display: 'flex',
//         flexDirection: 'column',
//         justifyContent: 'center',
//         alignItems: 'center',
//       }}
//     >
//       <Typography variant="h5" component="h1" gutterBottom>
//         {locationName ? `내 동네: ${locationName}` : '내 동네를 설정해주세요'}
//       </Typography>
//       {weatherData && (
//         <Box
//           sx={{
//             width: '100%',
//             height: '20px', // 높이 설정
//             overflow: 'hidden',
//             position: 'relative',
//             marginTop: 2,
//           }}
//         >
//           <ScrollingBox>
//             <Typography variant="h6" component="div">
//               온도: {weatherData.temperature}
//             </Typography>
//             <Typography variant="h6" component="div">
//               습도: {weatherData.humidity}
//             </Typography>
//             <Typography variant="h6" component="div">
//               하늘 상태: {weatherData.skyCondition}
//             </Typography>
//             <Typography variant="h6" component="div">
//               강수량: {weatherData.rainfall}
//             </Typography>
//           </ScrollingBox>
//         </Box>
//       )}
//       <Button
//         variant="contained"
//         color="primary"
//         onClick={() => navigate('/settings/mytown')}
//         sx={{ marginTop: 2 }}
//       >
//         동네 설정하기
//       </Button>
//     </Container>
//   );
// }

// export default MyTownWeather;
