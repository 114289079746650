import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import '../css/navbar.css';

function MiniNavBar(currentUser) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const handleCartClick = () => {
    // '장바구니' 클릭 이벤트 핸들러
    navigate(`/allcartlist/${currentUser.uid}`);
  };
  const handleMyPageClick = () => {
    // '장바구니' 클릭 이벤트 핸들러
    navigate(`/mypage/${currentUser.uid}/${currentUser.uid}`);
  };
  return (
    <div className="mini-navbar">
      {currentUser ? (
        <>
          <a href="#" onClick={handleCartClick}>
            {t('cart')}
          </a>
          <a href="#" onClick={handleMyPageClick}>
            {t('myPage')}
          </a>
          {/* <LanguageSwitcher /> */}
        </>
      ) : (
        <>{/* <LanguageSwitcher /> */}</>
      )}
    </div>
  );
}
export default MiniNavBar;
