// import { useEffect, useRef, useState } from "react";
// import { loadPaymentWidget } from "@tosspayments/payment-widget-sdk";
// import { nanoid } from "nanoid";

// import "../../../App.css";

// const widgetClientKey = "test_gck_KNbdOvk5rkDOkeDeZzg48n07xlzm";
// const customerKey = "test_gsk_pP2YxJ4K87aXp1Ygp2xpVRGZwXLO";

// export default function TossPayments({ paymentData, onSuccess, onFailure, isProcessing }) {
//   const [paymentWidget, setPaymentWidget] = useState(null);
//   const paymentMethodsWidgetRef = useRef(null);
//   const [price, setPrice] = useState(paymentData.amount);

//   useEffect(() => {
//     const fetchPaymentWidget = async () => {
//       try {
//         const loadedWidget = await loadPaymentWidget(widgetClientKey, customerKey);
//         setPaymentWidget(loadedWidget);
//       } catch (error) {
//         console.error("Error fetching payment widget:", error);
//       }
//     };

//     fetchPaymentWidget();
//   }, []);

//   useEffect(() => {
//     if (paymentWidget == null) {
//       return;
//     }

//     const paymentMethodsWidget = paymentWidget.renderPaymentMethods(
//       "#payment-widget",
//       { value: price },
//       { variantKey: "DEFAULT" }
//     );

//     paymentWidget.renderAgreement(
//       "#agreement",
//       { variantKey: "AGREEMENT" }
//     );

//     paymentMethodsWidgetRef.current = paymentMethodsWidget;
//   }, [paymentWidget, price]);

//   useEffect(() => {
//     const paymentMethodsWidget = paymentMethodsWidgetRef.current;

//     if (paymentMethodsWidget == null) {
//       return;
//     }

//     paymentMethodsWidget.updateAmount(price);
//   }, [price]);

//   const handlePaymentRequest = async () => {
//     if (isProcessing) return; // 중복 결제 방지
//     // 결제를 요청하기 전에 orderId, amount를 서버에 저장하세요.
//     // 결제 과정에서 악의적으로 결제 금액이 바뀌는 것을 확인하는 용도입니다.
//     try {
//       await paymentWidget?.requestPayment({
//         orderId: nanoid(),
//         orderName: "토스 티셔츠 외 2건",
//         customerName: "김토스",
//         customerEmail: "customer123@gmail.com",
//         customerMobilePhone: "01012341234",
//         successUrl: `${window.location.origin}/success`,
//         failUrl: `${window.location.origin}/fail`,
//         _skipAuth: "FORCE_SUCCESS",
//       });
//     } catch (error) {
//       console.error("Error requesting payment:", error);
//       onFailure(error);
//     }
//   };

//   return (
//     <div>
//       {/* 할인 쿠폰 */}
//       <label htmlFor="coupon-box">
//         <input
//           id="coupon-box"
//           type="checkbox"
//           onChange={(event) => {
//             setPrice(event.target.checked ? price - 5_000 : price + 5_000);
//           }}
//         />
//         <span>5,000원 쿠폰 적용</span>
//       </label>
//       {isProcessing && <p>결제 처리 중...</p>}
//       {/* 결제 UI, 이용약관 UI 영역 */}
//       <div id="payment-widget" />
//       <div id="agreement" />
//       {/* 결제하기 버튼 */}
//       <button onClick={handlePaymentRequest}>결제하기</button>
//     </div>
//     );
// }

import { useEffect, useRef, useState } from 'react';
import { loadPaymentWidget } from '@tosspayments/payment-widget-sdk';
import { nanoid } from 'nanoid';
import {
  Checkbox,
  FormControlLabel,
  Button,
  CircularProgress,
  Box,
} from '@mui/material';

const widgetClientKey = 'test_gck_KNbdOvk5rkDOkeDeZzg48n07xlzm';
const customerKey = 'test_gsk_pP2YxJ4K87aXp1Ygp2xpVRGZwXLO';

export default function TossPayments({
  paymentData,
  onSuccess,
  onFailure,
  isProcessing,
}) {
  const [paymentWidget, setPaymentWidget] = useState(null);
  const paymentMethodsWidgetRef = useRef(null);
  const [price, setPrice] = useState(paymentData.amount);

  useEffect(() => {
    const fetchPaymentWidget = async () => {
      try {
        const loadedWidget = await loadPaymentWidget(
          widgetClientKey,
          customerKey
        );
        setPaymentWidget(loadedWidget);
      } catch (error) {
        console.error('Error fetching payment widget:', error);
      }
    };

    fetchPaymentWidget();
  }, []);

  useEffect(() => {
    if (paymentWidget == null) {
      return;
    }

    const paymentMethodsWidget = paymentWidget.renderPaymentMethods(
      '#payment-widget',
      { value: price },
      { variantKey: 'DEFAULT' }
    );

    paymentWidget.renderAgreement('#agreement', { variantKey: 'AGREEMENT' });

    paymentMethodsWidgetRef.current = paymentMethodsWidget;
  }, [paymentWidget, price]);

  useEffect(() => {
    const paymentMethodsWidget = paymentMethodsWidgetRef.current;

    if (paymentMethodsWidget == null) {
      return;
    }

    paymentMethodsWidget.updateAmount(price);
  }, [price]);

  const handlePaymentRequest = async () => {
    if (isProcessing) return; // Prevent duplicate payments
    try {
      await paymentWidget?.requestPayment({
        orderId: nanoid(),
        orderName: '토스 티셔츠 외 2건',
        customerName: '김토스',
        customerEmail: 'customer123@gmail.com',
        customerMobilePhone: '01012341234',
        successUrl: `${window.location.origin}/success`,
        failUrl: `${window.location.origin}/fail`,
        _skipAuth: 'FORCE_SUCCESS',
      });
    } catch (error) {
      console.error('Error requesting payment:', error);
      onFailure(error);
    }
  };

  return (
    <Box>
      <FormControlLabel
        control={
          <Checkbox
            id="coupon-box"
            onChange={(event) => {
              setPrice(event.target.checked ? price - 5000 : price + 5000);
            }}
          />
        }
        label="5,000원 쿠폰 적용"
      />
      {isProcessing && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      )}
      <div id="payment-widget" />
      <div id="agreement" />
      <Button
        variant="contained"
        color="primary"
        onClick={handlePaymentRequest}
        disabled={isProcessing}
      >
        결제하기
      </Button>
    </Box>
  );
}
