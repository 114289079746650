// import React, { useState } from 'react';
// import { useSelector } from 'react-redux';
// import {
//   db,
//   auth,
//   doc,
//   setDoc,
//   updateDoc,
//   updateProfile,
//   sendEmailVerification,
//   createUserWithEmailAndPassword,
//   signInWithEmailAndPassword,
//   EmailAuthProvider,
//   updatePassword,
//   reauthenticateWithCredential,
// } from '../../firebaseConfig';
// import { useNavigate } from 'react-router-dom';
// import { Button, TextField, CircularProgress } from '@mui/material';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import '../css/SignUpFlow.css';

// function SignUpEditPassword({ onPrev, onNext, setIsStepCompleted }) {
//   const currentUser = useSelector((state) => state.auth.currentUser);
//   const [name, setName] = useState('');
//   const [email, setEmail] = useState('');
//   // const [password, setPassword] = useState('');
//   const [currentPassword, setCurrentPassword] = useState('');
//   const [newPassword, setNewPassword] = useState('');
//   const [confirmNewPassword, setConfirmNewPassword] = useState('');
//   const [isVerificationSent, setIsVerificationSent] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState('');
//   const navigate = useNavigate();

//   // Utility function to handle errors
//   const handleError = (errorMessage, logMessage) => {
//     setError(errorMessage);
//     console.error(logMessage);
//   };

//   // const handleRegister = async () => {
//   //     console.log("handleRegister called");
//   //     setLoading(true);
//   //     try {
//   //         await createUserWithEmailAndPassword(auth, email, password);
//   //         await sendVerificationEmail();
//   //     } catch (error) {
//   //         if (error.code === "auth/email-already-in-use") {
//   //             const userResponse = window.confirm("이미 일반계정으로 가입된 유저입니다. 해당 계정으로 비즈계정 가입을 진행할까요??");
//   //             userResponse ? handleLogin() : setError('Email already registered.');
//   //         } else {
//   //             handleError('Failed to sign up.', error.message);
//   //         }
//   //     }
//   //     setLoading(false);
//   // };

//   const sendVerificationEmail = async () => {
//     try {
//       await sendEmailVerification(auth.currentUser);
//       setIsVerificationSent(true);
//       toast.success('Verification email sent. Please verify to continue.');
//     } catch (error) {
//       handleError('Error sending verification email.', error.message);
//     }
//   };

//   // const handleLogin = async () => {
//   //     try {
//   //         await signInWithEmailAndPassword(auth, email, password);
//   //         handleVerificationComplete();
//   //     } catch (error) {
//   //         handleError('Failed to login.', error.message);
//   //     }
//   // };

//   const handleVerificationComplete = async () => {
//     const user = auth.currentUser;
//     await user.reload();
//     if (user.emailVerified) {
//       const userInfo = {
//         email: user.email,
//         name: name,
//       };
//       await upsertUserData(user, userInfo);
//       const userInfo2 = {
//         email: user.email,
//         name: name,
//         step: process.env.REACT_APP_CREATE_ACCOUNT_VERIFIED,
//       };
//       await updatePendingRegistrations(user, userInfo2);

//       try {
//         await updateProfile(user, { displayName: name });
//         toast.success('Email verified successfully.');
//         if (setIsStepCompleted) {
//           setIsStepCompleted(true);
//         }

//         //onNext(userInfo);
//       } catch (error) {
//         handleError('Failed to update profile.', error.message);
//       }
//     } else {
//       await sendVerificationEmail();
//     }
//   };

//   const reauthenticate = async (currentPassword) => {
//     const user = auth.currentUser;
//     const credential = EmailAuthProvider.credential(
//       user.email,
//       currentPassword
//     );
//     return reauthenticateWithCredential(user, credential);
//   };

//   const handleUpdatePassword = async () => {
//     if (newPassword !== confirmNewPassword) {
//       setError('Passwords do not match.');
//       return;
//     } else {
//       setError('');
//     }

//     if (!currentPassword || !newPassword) {
//       setError('Please enter both current and new passwords.');
//       return;
//     }

//     setLoading(true);
//     try {
//       await reauthenticate(currentPassword);
//       const user = auth.currentUser;
//       await updatePassword(user, newPassword);
//       toast.success('Password updated successfully.');
//       setError('Password updated successfully.');
//       setCurrentPassword('');
//       setNewPassword('');
//       setConfirmNewPassword('');
//     } catch (error) {
//       if (error.code === 'auth/wrong-password') {
//         setError('Incorrect current password.');
//       } else {
//         handleError('Failed to update password.', error.message);
//       }
//     }
//     setLoading(false);
//   };

//   const upsertUserData = async (user, userInfo) => {
//     const userRef = doc(db, 'users', user.uid);
//     await setDoc(
//       userRef,
//       {
//         ...userInfo,
//         createdAt: new Date(),
//         modifiedAt: new Date(),
//       },
//       { merge: true }
//     );
//   };

//   const updatePendingRegistrations = async (user, userInfo) => {
//     const userRef = doc(db, 'users', user.uid);
//     await updateDoc(userRef, {
//       // ...userInfo,
//       pendingRegistrations: {
//         ...userInfo,
//         step: userInfo.step,
//         createdAt: new Date(),
//         modifiedAt: new Date(),
//       },
//     });
//   };

//   return (
//     <div>
//       <div className="container">
//         <div className="d-flex justify-content-between align-items-center mb-4">
//           {/* <img src={logo} alt="Logo" style={{width: '100px'}} />  */}
//           <h3>비밀번호 변경</h3>
//         </div>
//         <ToastContainer />
//         <div>
//           <div>
//             <TextField
//               variant="outlined"
//               margin="normal"
//               fullWidth
//               id="name-new"
//               label="Name"
//               placeholder="Enter your name"
//               value={name || ''}
//               style={{ display: 'none' }}
//               onChange={(e) => setName(e.target.value)}
//             />

//             <TextField
//               variant="outlined"
//               margin="normal"
//               fullWidth
//               id="email-new"
//               label="Email"
//               placeholder="Enter your email"
//               value={email || ''}
//               style={{ display: 'none' }}
//               onChange={(e) => setEmail(e.target.value)}
//             />

//             <TextField
//               variant="outlined"
//               margin="normal"
//               fullWidth
//               id="pw-current"
//               label="Current Password"
//               placeholder="Enter your current password"
//               type="password"
//               value={currentPassword || ''}
//               onChange={(e) => setCurrentPassword(e.target.value)}
//             />

//             <TextField
//               variant="outlined"
//               margin="normal"
//               fullWidth
//               id="pw-new"
//               label="New Password"
//               placeholder="Enter your new password"
//               type="password"
//               value={newPassword || ''}
//               onChange={(e) => setNewPassword(e.target.value)}
//             />
//             <TextField
//               variant="outlined"
//               margin="normal"
//               fullWidth
//               id="confirm-new-pw"
//               label="Confirm New Password"
//               placeholder="Confirm your new password"
//               type="password"
//               value={confirmNewPassword || ''}
//               onChange={(e) => setConfirmNewPassword(e.target.value)}
//             />

//             {error && <div style={{ color: 'red' }}>{error}</div>}
//             <br />
//             <br />
//             {/* <Button
//                             type="submit"
//                             fullWidth
//                             variant="contained"
//                             color="primary"
//                             onClick={handleRegister}
//                             disabled={loading}
//                         >
//                             {loading ? <CircularProgress size={24} /> : '가입하기'}
//                         </Button> */}

//             {isVerificationSent && (
//               <Button
//                 fullWidth
//                 variant="contained"
//                 color="secondary"
//                 style={{ marginTop: '10px' }}
//                 onClick={handleVerificationComplete}
//               >
//                 인증 완료
//               </Button>
//             )}
//             <Button
//               fullWidth
//               variant="contained"
//               color="secondary"
//               style={{ marginTop: '10px' }}
//               onClick={handleUpdatePassword}
//               disabled={loading}
//             >
//               {loading ? <CircularProgress size={24} /> : '비밀번호 변경'}
//             </Button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default SignUpEditPassword;

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  db,
  auth,
  doc,
  setDoc,
  updateDoc,
  updateProfile,
  sendEmailVerification,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from '../firebaseConfig';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  TextField,
  CircularProgress,
  Box,
  Typography,
  Paper,
} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';

function SignUpEditPassword({ onPrev, onNext, setIsStepCompleted }) {
  const { t } = useTranslation();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [isVerificationSent, setIsVerificationSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  // Utility function to handle errors
  const handleError = (errorMessage, logMessage) => {
    setError(errorMessage);
    console.error(logMessage);
  };

  const sendVerificationEmail = async () => {
    try {
      await sendEmailVerification(auth.currentUser);
      setIsVerificationSent(true);
      toast.success(t('verificationEmailSent'));
    } catch (error) {
      handleError(t('errorSendingVerificationEmail'), error.message);
    }
  };

  const handleVerificationComplete = async () => {
    const user = auth.currentUser;

    try {
      await user.reload();

      if (user.emailVerified) {
        const userInfo = {
          email: user.email,
          name: name,
        };

        await upsertUserData(user, userInfo);

        const userInfoWithStep = {
          ...userInfo,
          step: process.env.REACT_APP_CREATE_ACCOUNT_VERIFIED,
        };

        await updateRegistrations(user, userInfoWithStep);

        try {
          await updateProfile(user, { displayName: name });
          toast.success(t('emailVerified'));

          if (setIsStepCompleted) {
            setIsStepCompleted(true);
          }
        } catch (profileError) {
          handleError(t('failedToUpdateProfile'), profileError.message);
        }
      } else {
        await sendVerificationEmail();
      }
    } catch (error) {
      console.error('Error handling verification:', error.message);
      handleError(t('errorVerifyingEmail'), error.message);
    }
  };

  const reauthenticate = async (currentPassword) => {
    const user = auth.currentUser;
    const credential = EmailAuthProvider.credential(
      user.email,
      currentPassword
    );
    return reauthenticateWithCredential(user, credential);
  };

  const handleUpdatePassword = async () => {
    if (newPassword !== confirmNewPassword) {
      setError(t('passwordsDoNotMatch'));
      return;
    } else {
      setError('');
    }

    if (!currentPassword || !newPassword) {
      setError(t('enterBothPasswords'));
      return;
    }

    setLoading(true);
    try {
      await reauthenticate(currentPassword);
      const user = auth.currentUser;
      await updatePassword(user, newPassword);
      toast.success(t('passwordUpdated'));
    } catch (error) {
      if (error.code === 'auth/wrong-password') {
        setError(t('incorrectCurrentPassword'));
      } else {
        handleError(t('failedToUpdatePassword'), error.message);
      }
    } finally {
      setError('');
      setCurrentPassword('');
      setNewPassword('');
      setConfirmNewPassword('');
    }
    setLoading(false);
  };

  const upsertUserData = async (user, userInfo) => {
    const userRef = doc(db, 'users', user.uid);
    await setDoc(
      userRef,
      {
        ...userInfo,
        createdAt: new Date(),
        modifiedAt: new Date(),
      },
      { merge: true }
    );
  };

  const updateRegistrations = async (user, userInfo) => {
    try {
      const userRef = doc(db, 'users', user.uid);

      await updateDoc(userRef, {
        ...userInfo,
        createdAt: new Date(),
        modifiedAt: new Date(),
      });
    } catch (error) {
      console.error('Error updating pending registrations:', error.message);
      handleError(t('errorUpdatingRegistrations'), error.message);
    }
  };

  return (
    <Box sx={{ maxWidth: 600, mx: 'auto', p: 3 }}>
      <ToastContainer />
      <Paper elevation={3} sx={{ p: 3, borderRadius: 2 }}>
        <Typography variant="h5" gutterBottom>
          {t('changePassword')}
        </Typography>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="pw-current"
          label={t('currentPassword')}
          placeholder={t('enterCurrentPassword')}
          type="password"
          value={currentPassword || ''}
          onChange={(e) => setCurrentPassword(e.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="pw-new"
          label={t('newPassword')}
          placeholder={t('enterNewPassword')}
          type="password"
          value={newPassword || ''}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="confirm-new-pw"
          label={t('confirmNewPassword')}
          placeholder={t('confirmNewPassword')}
          type="password"
          value={confirmNewPassword || ''}
          onChange={(e) => setConfirmNewPassword(e.target.value)}
        />

        {error && <Typography color="error">{error}</Typography>}

        {isVerificationSent && (
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            sx={{ mt: 2 }}
            onClick={handleVerificationComplete}
          >
            {t('completeVerification')}
          </Button>
        )}

        <Button
          fullWidth
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
          onClick={handleUpdatePassword}
          disabled={loading}
        >
          {loading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            t('changePassword')
          )}
        </Button>
      </Paper>
    </Box>
  );
}

export default SignUpEditPassword;
