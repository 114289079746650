import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { db, doc, getDoc } from '../firebaseConfig';
import {
  Container,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

function UserSignUpComplete({ onClose, setIsStepCompleted }) {
  const { t, i18n } = useTranslation();
  const [userData, setUserData] = useState(null);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const navigate = useNavigate();

  useEffect(() => {
    const loadUserData = async () => {
      try {
        const userRef = doc(db, 'users', currentUser.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          setUserData(userDoc.data());
        }
      } catch (error) {
        console.error(t('errorFetchingUserData'), error);
      }
    };
    loadUserData();
  }, [currentUser, setIsStepCompleted, t]);

  const handleGoToHome = () => {
    navigate(`/`);
    onClose();
  };

  return (
    <Container>
      <Box textAlign="center" p={4}>
        <Typography variant="h4" gutterBottom>
          {t('signupComplete')}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {t('userSignupComplete')}
        </Typography>

        <Button variant="contained" color="primary" onClick={handleGoToHome}>
          {t('goToHome')}
        </Button>
      </Box>
    </Container>
  );
}

UserSignUpComplete.propTypes = {
  onClose: PropTypes.func.isRequired,
  setIsStepCompleted: PropTypes.func.isRequired,
};

export default UserSignUpComplete;
