import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DateFormatter from '../../../Utils/DateFormatter';
import {
  collection,
  db,
  doc,
  getDoc,
  getDocs,
  runTransaction,
  where,
  query,
  Timestamp,
} from '../../../firebaseConfig';

function useCoupon(storeId, productId, userId) {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const [couponPrice, setCouponPrice] = useState(0);
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [product, setProduct] = useState(null);
  const [downloadedCoupons, setDownloadedCoupons] = useState([]);
  const [downloadableCoupons, setDownloadableCoupons] = useState([]);
  const [discount, setDiscount] = useState(0);
  const [quantity, setQuantity] = useState(10);
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (productId) {
      fetchProduct();
    }
  }, [storeId, productId]);

  useEffect(() => {
    fetchAllCoupons();
  }, [storeId, productId]);

  useEffect(() => {
    if (product) {
      fetchCoupons();
    }
  }, [product]);

  async function fetchProduct() {
    try {
      const productRef = doc(db, 'stores', storeId, 'products', productId);
      const productSnapshot = await getDoc(productRef);
      const productData = productSnapshot.data();

      if (!productData) {
        throw new Error('Product not found');
      }

      const formattedData = {
        id: productSnapshot.id,
        ...productData,
        formattedDate: DateFormatter.formatTimeStamp(
          productData.createDate ?? new Date()
        ),
      };
      setProduct(formattedData);
    } catch (err) {
      console.error('Error fetching product:', err.message);
    }
  }

  const fetchAllCoupons = async () => {
    try {
      const applicableCoupons = await getApplicableAllCoupons();
      const downloadedCouponIds = await getDownloadedCouponIds(userId);

      const filteredCoupons = applicableCoupons.filter(
        (coupon) => !downloadedCouponIds.has(coupon.id)
      );

      setDownloadableCoupons(filteredCoupons);
    } catch (error) {
      console.error('Error fetching applicable coupons:', error.message);
      setMessage('Failed to fetch coupons: ' + error.message);
    }
  };

  const getDownloadedCouponIds = async (userId) => {
    const storeUserDocRef = doc(db, 'stores', storeId, 'users', userId);
    const storeUserDocSnapshot = await getDoc(storeUserDocRef);
    const userData = storeUserDocSnapshot.data();
    const downloadedCoupons = userData?.downloadedCoupons || {};

    // couponId만 추출하여 Set으로 반환
    return new Set(Object.keys(downloadedCoupons));
  };

  const getApplicableAllCoupons = async () => {
    const couponsCollection = collection(db, 'stores', storeId, 'coupons');
    const now = Timestamp.now();
    const allCouponsSnapshot = await getDocs(
      query(couponsCollection, where('endDate', '>', now))
    );

    return allCouponsSnapshot.docs.reduce((applicableCoupons, doc) => {
      const data = doc.data();
      const isApplicable = productId
        ? Array.isArray(data.applicableProducts) &&
          data.applicableProducts.some((p) => p.id === productId)
        : data.targetProducts === 'all' ||
          !Array.isArray(data.applicableProducts);

      if (isApplicable) {
        applicableCoupons.push({
          ...data,
          id: doc.id,
          startDate: data.startDate.toDate(),
          endDate: data.endDate.toDate(),
        });
      }
      return applicableCoupons;
    }, []);
  };

  const fetchCoupons = async () => {
    let downloadedCoupons = [];
    let applicableCoupons = [];

    try {
      downloadedCoupons =
        (await getUserDownloadedCoupons(userId, productId)) ?? [];

      const unusedCouponsArray = downloadedCoupons.filter(
        (coupon) =>
          (coupon.productId === productId || coupon.productId === 'all') &&
          !coupon.used
      );

      setDownloadedCoupons(unusedCouponsArray);
    } catch (error) {
      console.error('Error fetching downloaded coupons:', error.message);
      setMessage(error.message);
    }

    // try {
    //   applicableCoupons = (await getApplicableCoupons(productId)) ?? [];
    // } catch (error) {
    //   console.error('Error fetching applicable coupons:', error.message);
    //   setMessage(error.message);
    // }

    // const downloadedCouponIds = new Set(
    //   downloadedCoupons.map((coupon) => coupon.couponId)
    // );
    // const downloadableCoupons = applicableCoupons.filter(
    //   (coupon) => !downloadedCouponIds.has(coupon.id)
    // );

    // setDownloadableCoupons(downloadableCoupons);

    const result = calculateBestCoupon(downloadedCoupons);

    if (result.bestCoupon) {
      setSelectedCoupon(result.bestCoupon);
      setCouponPrice(result.bestDiscount);
    }
  };

  function calculateBestCoupon(downloadedCoupons, productPrice) {
    if (!downloadedCoupons || downloadedCoupons.length === 0) {
      return 0;
    }

    function calculateDiscount(coupon) {
      if (coupon.discountType === 'percentage') {
        return productPrice * (coupon.discount / 100);
      } else if (coupon.discountType === 'amount') {
        return coupon.discount;
      } else {
        return 0;
      }
    }

    const bestCoupon = downloadedCoupons.reduce((maxCoupon, currentCoupon) => {
      return calculateDiscount(currentCoupon) > calculateDiscount(maxCoupon)
        ? currentCoupon
        : maxCoupon;
    }, downloadedCoupons[0]);

    return {
      bestCoupon,
      bestDiscount: calculateDiscount(bestCoupon),
    };
  }

  const getUserDownloadedCoupons = async (userId, targetProductId) => {
    if (!userId) {
      throw new Error('Invalid user ID');
    }
    if (!targetProductId) {
      throw new Error('Invalid Product ID');
    }
    const storeUserDocRef = doc(db, 'stores', storeId, 'users', userId);
    const storeUserDocSnapshot = await getDoc(storeUserDocRef);

    const userData = storeUserDocSnapshot.data();
    if (!userData || !userData.downloadedCoupons) {
      throw new Error('No downloaded coupons');
    }

    const downloadedCoupons = userData.downloadedCoupons;
    if (typeof downloadedCoupons !== 'object') {
      throw new Error('Downloaded coupons data is malformed');
    }

    const couponsArray = [];
    for (let couponId in downloadedCoupons) {
      for (let storeIdKey in downloadedCoupons[couponId]) {
        if (storeIdKey === storeId) {
          for (let productId in downloadedCoupons[couponId][storeIdKey]) {
            if (productId === targetProductId || productId === 'all') {
              let couponDetail =
                downloadedCoupons[couponId][storeIdKey][productId];

              couponsArray.push({
                couponId: couponId,
                productId: productId,
                couponName: couponDetail.couponName,
                discountType: couponDetail.discountType,
                discount: couponDetail.discount,
                minPurchaseAmount: couponDetail.minPurchaseAmount,
                issuedQuantity: couponDetail.issuedQuantity,
                startDate: couponDetail.startDate,
                endDate: couponDetail.endDate,
                used: couponDetail.used,
              });
            }
          }
        }
      }
    }
    return couponsArray;
  };

  const getApplicableCoupons = async (productId) => {
    const couponsCollection = collection(db, 'stores', storeId, 'coupons');
    const allCouponsSnapshot = await getDocs(couponsCollection);
    if (!productId) {
      return allCouponsSnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
    }
    return allCouponsSnapshot.docs.reduce((applicableCoupons, doc) => {
      const data = doc.data();
      const hasProduct =
        Array.isArray(data.applicableProducts) &&
        data.applicableProducts.some(
          (applicableProduct) => applicableProduct.id === productId
        );

      if (hasProduct) {
        applicableCoupons.push({
          ...data,
          id: doc.id,
        });
      }

      return applicableCoupons;
    }, []);
  };

  async function downloadCoupon(couponId, productId = 'all') {
    try {
      await requestDownloadCoupon(couponId, productId);
      setMessage('쿠폰을 다운로드 하였습니다.');

      const targetCouponIndex = downloadableCoupons.findIndex(
        (c) => c.id === couponId
      );
      if (targetCouponIndex !== -1) {
        const updatedDownloadableCoupons = [...downloadableCoupons];
        const [targetCoupon] = updatedDownloadableCoupons.splice(
          targetCouponIndex,
          1
        );

        if (productId !== 'all') {
          targetCoupon.applicableProducts =
            targetCoupon.applicableProducts.filter((p) => p.id !== productId);
          if (targetCoupon.applicableProducts.length > 0) {
            updatedDownloadableCoupons.push(targetCoupon);
          }
        }

        setDownloadedCoupons((prev) => [...prev, targetCoupon]);
        setDownloadableCoupons(updatedDownloadableCoupons);
      }
    } catch (e) {
      setMessage(`쿠폰을 다운로드하지 못했습니다.: ${e.message}`);
    }
  }

  const requestDownloadCoupon = async (couponId, productId = 'all') => {
    if (!couponId || !userId) {
      throw new Error('Required fields are missing.');
    }

    const storeUserDocRef = doc(db, 'stores', storeId, 'users', userId);
    const couponDocRef = doc(db, 'stores', storeId, 'coupons', couponId);

    try {
      await runTransaction(db, async (transaction) => {
        const storeUserDocSnapshot = await transaction.get(storeUserDocRef);
        const couponDocSnapshot = await transaction.get(couponDocRef);

        if (!couponDocSnapshot.exists()) {
          throw new Error('쿠폰이 존재하지 않습니다.');
        }

        const couponData = couponDocSnapshot.data();

        if (couponData.targetProducts !== 'all' && !productId) {
          throw new Error('Product ID is required for this coupon.');
        }

        let productCouponQuantity = 0;
        let productIssuedQuantity = 0;

        if (couponData.targetProducts !== 'all') {
          if (
            !couponData.applicableProducts ||
            couponData.applicableProducts.length === 0
          ) {
            throw new Error('해당 상품에는 쿠폰을 사용할 수 없습니다.');
          }

          const productIndex = couponData.applicableProducts.findIndex(
            (applicableProduct) => applicableProduct.id === productId
          );
          if (productIndex === -1) {
            throw new Error('해당 상품에는 쿠폰을 사용할 수 없습니다.');
          }

          productCouponQuantity =
            couponData.applicableProducts[productIndex].quantity;
          productIssuedQuantity =
            couponData.applicableProducts[productIndex].issuedQuantity || 0;

          if (productCouponQuantity <= 0) {
            throw new Error('다운로드 할수 있는 쿠폰이 없습니다.');
          }
        } else {
          productIssuedQuantity = couponData.issuedQuantity || 0;
        }

        const downloadedCoupons = storeUserDocSnapshot.exists()
          ? storeUserDocSnapshot.data().downloadedCoupons || {}
          : {};

        if (
          downloadedCoupons[couponId] &&
          downloadedCoupons[couponId][storeId] &&
          downloadedCoupons[couponId][storeId][productId]
        ) {
          throw new Error('이미 다운로드 된 쿠폰입니다.');
        }

        let newDownloadedCoupon = {
          couponId: couponId,
          storeId: storeId,
          couponCode: couponData.couponCode,
          couponName: couponData.couponName,
          discountType: couponData.discountType,
          minPurchaseAmount: couponData.minPurchaseAmount,
          discount: couponData.discount,
          issuedQuantity: productIssuedQuantity,
          startDate: couponData.startDate,
          endDate: couponData.endDate,
          used: false,
        };

        if (!downloadedCoupons[couponId]) {
          downloadedCoupons[couponId] = {};
        }
        if (!downloadedCoupons[couponId][storeId]) {
          downloadedCoupons[couponId][storeId] = {};
        }
        downloadedCoupons[couponId][storeId][productId] = newDownloadedCoupon;

        // If the user document does not exist, create it
        if (!storeUserDocSnapshot.exists()) {
          transaction.set(
            storeUserDocRef,
            {
              downloadedCoupons: {
                [couponId]: {
                  [storeId]: {
                    [productId]: newDownloadedCoupon,
                  },
                },
              },
            },
            { merge: true }
          );
        } else {
          transaction.set(
            storeUserDocRef,
            {
              downloadedCoupons: {
                ...downloadedCoupons,
                [couponId]: {
                  ...(downloadedCoupons[couponId] || {}),
                  [storeId]: {
                    ...(downloadedCoupons[couponId][storeId] || {}),
                    [productId]: newDownloadedCoupon,
                  },
                },
              },
            },
            { merge: true }
          );
        }

        if (couponData.targetProducts !== 'all') {
          let applicableProducts = couponDocSnapshot.data().applicableProducts;
          const productIndex = applicableProducts.findIndex(
            (applicableProduct) => applicableProduct.id === productId
          );
          if (
            applicableProducts[productIndex] &&
            applicableProducts[productIndex].quantity
          ) {
            applicableProducts[productIndex].quantity -= 1;
            if (!applicableProducts[productIndex].downloadedUsers) {
              applicableProducts[productIndex].downloadedUsers = [];
            }
            applicableProducts[productIndex].downloadedUsers.push(userId);

            transaction.update(couponDocRef, {
              applicableProducts: applicableProducts,
            });
          }
        } else {
          transaction.update(couponDocRef, {
            issuedQuantity: (couponData.issuedQuantity || 0) - 1,
            downloadedUsers: [...(couponData.downloadedUsers || []), userId],
          });
        }
      });
      console.log('Coupon downloaded successfully.');
    } catch (error) {
      console.error('Failed to download coupon:', error.message);
      throw error;
    }
  };

  function applyCouponToPrice(originalPrice, coupon) {
    if (!coupon || !coupon.discountType) {
      setMessage('쿠폰오류');
      return;
    }
    if (originalPrice < coupon.minPurchaseAmount) {
      setMessage('쿠폰을 사용하기 위한 최소 구매 금액이 충족되지 않았습니다.');
      return;
    }

    let couponPrice = 0;
    if (coupon.discountType === 'percentage') {
      couponPrice = originalPrice * (coupon.discount / 100);
    } else if (coupon.discountType === 'amount') {
      couponPrice = coupon.discount;
    } else {
      throw new Error('Invalid discount type');
    }
    setSelectedCoupon(coupon);
    setCouponPrice(couponPrice);
  }

  return {
    downloadedCoupons,
    downloadableCoupons,
    couponPrice,
    selectedCoupon,
    quantity,
    message,
    downloadCoupon,
    applyCouponToPrice,
  };
}

export default useCoupon;
