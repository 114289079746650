import React, { useState } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Paper,
} from '@mui/material';
import countries from './Country/all-countries.json';
import { Add as AddIcon } from '@mui/icons-material';

const TranslateComponent = () => {
  const [file, setFile] = useState(null);
  const [srcLang, setSrcLang] = useState('en');
  const [destLang, setDestLang] = useState('ko');
  const [translatedFileUrl, setTranslatedFileUrl] = useState('');
  const [loading, setLoading] = useState(false);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('srcLang', srcLang);
    formData.append('destLang', destLang);

    setLoading(true);

    try {
      const response = await axios.post(
        'http://localhost:5000/translate',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      setTranslatedFileUrl(response.data.url);
    } catch (error) {
      console.error('Error uploading file:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box p={2} sx={{ width: '100%', maxWidth: '800px', margin: 'auto' }}>
      <Typography variant="h4" gutterBottom>
        Translate Document
      </Typography>
      <Box mb={2}>
        <img
          src="path/to/your/ad.jpg"
          alt="Advertisement"
          style={{ width: '100%' }}
        />
      </Box>
      <Paper elevation={3} sx={{ padding: 2, textAlign: 'center' }}>
        <input type="file" onChange={handleFileChange} />
        <Grid container spacing={2} sx={{ marginTop: 2 }}>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>Source Language</InputLabel>
              <Select
                value={srcLang}
                onChange={(e) => setSrcLang(e.target.value)}
              >
                {countries.map((country) => (
                  <MenuItem key={country.code} value={country.code}>
                    <span
                      className={`flag-icon flag-icon-${country.flag.toLowerCase()}`}
                      style={{ marginRight: '10px' }}
                    ></span>
                    {country.nativeName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>Destination Language</InputLabel>
              <Select
                value={destLang}
                onChange={(e) => setDestLang(e.target.value)}
              >
                {countries.map((country) => (
                  <MenuItem key={country.code} value={country.code}>
                    <span
                      className={`flag-icon flag-icon-${country.flag.toLowerCase()}`}
                      style={{ marginRight: '10px' }}
                    ></span>
                    {country.nativeName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Button
          variant="contained"
          color="primary"
          sx={{ marginTop: 2 }}
          onClick={handleUpload}
          disabled={!file || loading}
        >
          {loading ? <CircularProgress size={24} /> : 'Upload and Translate'}
        </Button>
      </Paper>
      {translatedFileUrl && (
        <Box mt={2}>
          <Typography variant="h6">Translated Document</Typography>
          <a href={translatedFileUrl} download>
            <Button variant="contained" color="secondary">
              Download Translated Document
            </Button>
          </a>
        </Box>
      )}
      <Box mt={2}>
        <img
          src="path/to/another/ad.jpg"
          alt="Advertisement"
          style={{ width: '100%' }}
        />
      </Box>
    </Box>
  );
};

export default TranslateComponent;
