import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Container,
  TextField,
  Button,
  CircularProgress,
  Alert,
  Typography,
  Paper,
} from '@mui/material';
import { db, doc, updateDoc, setDoc } from '../firebaseConfig';

import { getFunctions, httpsCallable } from 'firebase/functions';
import axios from 'axios';

function PhoneNumberVerification({ onPrev, onNext, setIsStepCompleted }) {
  const { t } = useTranslation();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [timeLeft, setTimeLeft] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const functions = getFunctions();

  const sendOTP = httpsCallable(functions, 'sendOTP');
  const verifyOTP = httpsCallable(functions, 'verifyOTP');

  const requestVerificationCode = async () => {
    setErrorMessage('');
    if (!phoneNumber) {
      setErrorMessage('Please enter a valid phone number.');
      return;
    }
    setIsLoading(true);

    try {
      const functionUrl =
        'https://asia-northeast3-apple-market-af8c6.cloudfunctions.net/sendOTP';
      const response = await axios.post(
        functionUrl,
        {
          phoneNumber,
          uid: currentUser?.uid,
        },
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );
      setTimeLeft(180); // 타이머 시작
      setSuccessMessage('Verification code sent. Please check your phone.');
      setIsCodeSent(true);
    } catch (error) {
      console.error('검증 중 오류가 발생했습니다:', error);
    } finally {
      setIsLoading(false);
    }

    // try {
    //   await sendOTP({ phoneNumber });
    //   setSuccessMessage('Verification code sent. Please check your phone.');
    //   setIsCodeSent(true);
    // } catch (error) {
    //   setErrorMessage('Failed to send verification code. Please try again.');
    // } finally {
    //   setIsLoading(false);
    // }
  };

  const verifyPhoneNumber = async () => {
    setErrorMessage('');
    setIsLoading(true);
    try {
      const functionUrl =
        'https://asia-northeast3-apple-market-af8c6.cloudfunctions.net/verifyOTP';
      const response = await axios.post(
        functionUrl,
        {
          phoneNumber,
          code: verificationCode,
        },
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );
      setTimeLeft(null); // 인증 성공 시 타이머 종료
      setSuccessMessage('Verification code sent. Please check your phone.');
      await updatePhoneNumber({ phoneNumber });
      recordStepAndMoveNext();
    } catch (error) {
      setErrorMessage('Failed to verify phone number. Please try again.');
    } finally {
      setIsLoading(false);
    }
    // try {
    //   await verifyOTP({ phoneNumber, otp: verificationCode });
    //   setSuccessMessage('Phone number successfully verified.');
    //   recordStepAndMoveNext();
    // } catch (error) {
    //   setErrorMessage('Failed to verify phone number. Please try again.');
    // } finally {
    //   setIsLoading(false);
    // }
  };
  const updatePhoneNumber = async (userInfo) => {
    try {
      const userRef = doc(db, 'users', currentUser?.uid);

      await updateDoc(userRef, {
        ...userInfo,
        createdAt: new Date(),
        modifiedAt: new Date(),
      });
    } catch (error) {
      console.error('Error updating pending registrations:', error.message);
      // handleError(t('errorUpdatingRegistrations'), error.message);
    }
  };

  const upsertUserData = async (user, userInfo) => {
    try {
      const userRef = doc(db, 'users', user.uid);
      await setDoc(
        userRef,
        {
          ...userInfo,
          createdAt: new Date(),
          modifiedAt: new Date(),
        },
        { merge: true }
      );
    } catch (error) {
      setErrorMessage('Failed to update user data.', error.message);
    }
  };

  const recordStepAndMoveNext = async () => {
    try {
      const userInfo = {
        step: process.env.REACT_APP_PHONE_NUMBER_VERIFIED,
      };
      await upsertUserData(currentUser, userInfo);
      if (setIsStepCompleted) {
        setIsStepCompleted(true);
      }
      if (onNext) {
        onNext();
      }
    } catch (error) {
      console.error('Error completing step:', error);
      setErrorMessage('Error completing step. Please try again.');
    }
  };
  // 타이머 처리
  useEffect(() => {
    let timer = null;
    if (timeLeft > 0) {
      timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
    } else if (timeLeft === 0) {
      alert('인증번호 유효 시간이 만료되었습니다.');
    }
    return () => clearTimeout(timer);
  }, [timeLeft]);
  return (
    <Container
      p={3}
      maxWidth="sm"
      sx={{ marginTop: 10, height: '100vh', alignItems: 'center' }}
    >
      <Paper
        elevation={3}
        sx={{ padding: 3, width: '100%', overflowY: 'auto' }}
      >
        <Typography variant="h5" gutterBottom>
          {t('verifyPhoneNumber')}
        </Typography>
        <Typography variant="body2" sx={{ mb: 2 }}>
          {t('enterPhoneNumberFormat', {
            format: '+CountryCode PhoneNumber',
            example: '+8210xxxxxxxx, +82010xxxxxxxx',
          })}
        </Typography>
        <TextField
          fullWidth
          label="Phone Number"
          variant="outlined"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          margin="normal"
          error={errorMessage.includes('phone number')}
        />
        <Button
          variant="contained"
          onClick={requestVerificationCode}
          disabled={!phoneNumber}
          sx={{ mb: 2 }}
        >
          {t('requestVerificationCode')}
        </Button>
        {isCodeSent && (
          <>
            <TextField
              fullWidth
              label="Verification Code"
              variant="outlined"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              margin="normal"
            />
            {timeLeft > 0 && (
              <Typography variant="body1">
                유효시간 {Math.floor(timeLeft / 60)}:
                {(timeLeft % 60).toString().padStart(2, '0')}
              </Typography>
            )}
            <Button
              variant="contained"
              onClick={verifyPhoneNumber}
              disabled={!timeLeft || timeLeft <= 0}
            >
              {t('verifyPhoneNumber')}
            </Button>
          </>
        )}
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        {successMessage && <Alert severity="success">{successMessage}</Alert>}
        {isLoading && <CircularProgress />}
      </Paper>
    </Container>
  );
}

export default PhoneNumberVerification;
