// import React, { createContext, useContext, useState, useEffect } from 'react';

// const GlobalContext = createContext();

// export const useGlobalContext = () => {
//     return useContext(GlobalContext);
// };

// export const GlobalProvider = ({ children }) => {
//     const [navbarHeight, setNavbarHeight] = useState('90px');
//     const [tabbarHeight, setTabbarHeight] = useState('0px');
//     const [textInputHeight, setTextInputHeight] = useState('70px');

//     // 화면 크기가 변경될 때마다 상태를 업데이트
//     useEffect(() => {
//         const updateDimensions = () => {
//             setNavbarHeight(window.innerWidth <= 768 ? '90px' : '110px');
//             setTabbarHeight(window.innerWidth <= 768 ? '0px' : '0px');
//             setTextInputHeight(window.innerWidth <= 768 ? '50px' : '70px');

//         };

//         window.addEventListener('resize', updateDimensions);

//         return () => {
//             window.removeEventListener('resize', updateDimensions);
//         };
//     }, []);

//     // navbarHeight와 tabbarHeight가 변경될 때마다, CSS 변수를 업데이트
//     useEffect(() => {
//         document.documentElement.style.setProperty('--navbar-height', navbarHeight);
//         document.documentElement.style.setProperty('--tabbar-height', tabbarHeight);
//         document.documentElement.style.setProperty('--text-input-height', textInputHeight);
//     }, [navbarHeight, tabbarHeight, textInputHeight]);

//     return (
//         <GlobalContext.Provider value={{ navbarHeight, tabbarHeight, textInputHeight}}>
//             {children}
//         </GlobalContext.Provider>
//     );
// };

// GlobalContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';

const GlobalContext = createContext();

export const useGlobalContext = () => {
  return useContext(GlobalContext);
};

export const GlobalProvider = ({ children }) => {
  const [navbarHeight, setNavbarHeight] = useState('90px');
  const [tabbarHeight, setTabbarHeight] = useState('0px');
  const [textInputHeight, setTextInputHeight] = useState('70px');

  useEffect(() => {
    const updateDimensions = () => {
      setNavbarHeight(window.innerWidth <= 768 ? '90px' : '110px');
      setTabbarHeight(window.innerWidth <= 768 ? '50px' : '0px'); // Adjusted tabbarHeight for mobile
      setTextInputHeight(window.innerWidth <= 768 ? '50px' : '70px');
    };

    updateDimensions(); // Initial call to set dimensions
    window.addEventListener('resize', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  return (
    <GlobalContext.Provider
      value={{ navbarHeight, tabbarHeight, textInputHeight }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
