// import React, { useState, useEffect } from 'react';
// import { useParams, useNavigate } from 'react-router-dom';
// import { useSelector } from 'react-redux';
// import {
//   db,
//   doc,
//   updateDoc,
//   getDoc,
//   storage,
//   ref,
//   uploadBytes,
//   getDownloadURL
// } from '../firebaseConfig';

// const EditMyStore = () => {
//   const [store, setStore] = useState({ storeName: '', description: '' });
//   const [newMainImages, setNewMainImages] = useState([]);
//   const [previewMainImages, setPreviewMainImages] = useState([]);
//   const [newSubImages, setNewSubImages] = useState([]);
//   const [previewSubImages, setPreviewSubImages] = useState([]);

//   const [error, setError] = useState(null);
//   const { storeId } = useParams();
//   const navigate = useNavigate();
//   const currentUser = useSelector(state => state.auth.currentUser);

//   useEffect(() => {
//     const fetchStore = async () => {
//       try {
//         const storeRef = doc(db, 'stores', storeId);
//         const storeDoc = await getDoc(storeRef);

//         if (storeDoc.exists()) {
//           const storeData = storeDoc.data();
//           setStore(storeData);
//           // 저장된 이미지 URL을 상태에 초기화합니다.
//           if (storeData.mainImages) {
//             setNewMainImages(storeData.mainImages);
//           }
//           if (storeData.subImages) {
//             setNewSubImages(storeData.subImages);
//           }
//         } else {
//           setError('Store not found');
//         }
//       } catch (err) {
//         setError(`Error fetching store: ${err.message}`);
//       }
//     };

//     fetchStore();
//   }, [storeId]);

//   const handleMainImageChange = (e) => {
//     const selectedFiles = Array.from(e.target.files);
//     const newPreviewUrls = selectedFiles.map(file => URL.createObjectURL(file));
//     // 기존 파일들에 새로운 파일들을 추가
//     const updatedFiles = [...newMainImages, ...selectedFiles];
//     // 미리보기 URL 업데이트
//     const updatedPreviewUrls = [...previewMainImages, ...newPreviewUrls];
//     setNewMainImages(updatedFiles);
//     setPreviewMainImages(updatedPreviewUrls);
//   };

//   const handleSubImagesChange = (e) => {
//     const selectedFiles = Array.from(e.target.files);
//     const newPreviewUrls = selectedFiles.map(file => URL.createObjectURL(file));
//     // 기존 파일들에 새로운 파일들을 추가
//     const updatedFiles = [...newSubImages, ...selectedFiles];
//     // 미리보기 URL 업데이트
//     const updatedPreviewUrls = [...previewSubImages, ...newPreviewUrls];
//     setNewSubImages(updatedFiles);
//     setPreviewSubImages(updatedPreviewUrls);
//   };

//   const removeMainImage = (index) => {
//     setNewMainImages(newMainImages.filter((_, i) => i !== index));
//   };

//   const removeSubImage = (index) => {
//     setNewSubImages(newSubImages.filter((_, i) => i !== index));
//   };

//   const removePreviewMainImage = (index) => {
//     // 미리보기 이미지 목록에서 제거
//     const updatedPreviewUrls = previewMainImages.filter((_, i) => i !== index);
//     setPreviewMainImages(updatedPreviewUrls);

//     // 선택한 파일 목록에서도 제거
//     const updatedFiles = newMainImages.filter((_, i) => i !== index);
//     setNewMainImages(updatedFiles);
//   };

//   const removePreviewSubImage = (index) => {
//     // 미리보기 이미지 목록에서 제거
//     const updatedPreviewUrls = previewSubImages.filter((_, i) => i !== index);
//     setPreviewSubImages(updatedPreviewUrls);

//     // 선택한 파일 목록에서도 제거
//     const updatedFiles = newSubImages.filter((_, i) => i !== index);
//     setNewSubImages(updatedFiles);
//   };

//   const uploadImages = async () => {
//     let mainImageUrls = [...store.mainImages];
//     let subImageUrls = [...store.subImages];

//     if (newMainImages.length > 0) {
//       const uploadPromises = newMainImages.map((image, index) => {
//         const mainImageRef = ref(storage, `stores/${storeId}/mainImages${index}`);
//         return uploadBytes(mainImageRef, image)
//           .then(() => getDownloadURL(mainImageRef));
//       });
//       mainImageUrls = await Promise.all(uploadPromises);
//     }

//     if (newSubImages.length > 0) {
//       const uploadPromises = newSubImages.map((image, index) => {
//         const subImageRef = ref(storage, `stores/${storeId}/subImages${index}`);
//         return uploadBytes(subImageRef, image)
//           .then(() => getDownloadURL(subImageRef));
//       });
//       subImageUrls = await Promise.all(uploadPromises);
//     }

//     return { mainImageUrls, subImageUrls };
//   };

//   const handleChange = (e) => {
//     setStore({ ...store, [e.target.name]: e.target.value });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const { mainImageUrls, subImageUrls } = await uploadImages();
//       const storeRef = doc(db, 'stores', storeId);
//       await updateDoc(storeRef, {
//         storeName: store.storeName,
//         name: store.name,
//         description: store.description,
//         mainImages: mainImageUrls,
//         subImages: subImageUrls
//       });
//       navigate(`/mystore/${storeId}`);
//     } catch (err) {
//       setError(`Error updating store: ${err.message}`);
//     }
//   };

//   if (error) {
//     return <div>Error: {error}</div>;
//   }

//   return (
//     <div className='edit-store'>
//       <h2>스토어 정보 수정</h2>
//       <form onSubmit={handleSubmit}>
//         <div>
//           <label>스토어 이름:</label>
//           <input type='text' name='storeName' value={store.storeName} onChange={handleChange} />
//         </div>
//         <div>
//           <label>이름:</label>
//           <input type='text' name='name' value={store.name} onChange={handleChange} />
//         </div>
//         <div>
//           <label>내용:</label>
//           <textarea name='description' value={store.description} onChange={handleChange} />
//         </div>

//         {/* Main Images Upload */}
//         <div>
//           <label>메인 이미지:</label>
//           <input type='file' multiple onChange={handleMainImageChange} />
//           <div>
//             {newMainImages.map((url, index) => (
//               <div key={index} style={{ position: 'relative', display: 'inline-block', marginRight: '10px' }}>
//                 <img src={url} alt={`New Main Image ${index + 1}`} style={{ width: '100px', height: '100px' }} />
//                 <button onClick={() => removeMainImage(index)} style={{ position: 'absolute', top: 0, right: 0 }}>X</button>
//               </div>
//             ))}
//           </div>
//           <div>
//             {previewMainImages.map((url, index) => (
//               <div key={index} style={{ position: 'relative', display: 'inline-block', marginRight: '10px' }}>
//                 <img src={url} alt={`Preview Main Image ${index + 1}`} style={{ width: '100px', height: '100px' }} />
//                 <button onClick={() => removePreviewMainImage(index)} style={{ position: 'absolute', top: 0, right: 0 }}>X</button>
//               </div>
//             ))}
//           </div>
//         </div>

//         {/* Sub Images Upload */}
//         <div>
//           <label>부가 이미지:</label>
//           <input type='file' multiple onChange={handleSubImagesChange} />
//           <div>
//           {newSubImages.map((url, index) => (
//               <div key={index} style={{ position: 'relative', display: 'inline-block', marginRight: '10px' }}>
//                 <img src={url} alt={`New Sub Image ${index + 1}`} style={{ width: '100px', height: '100px' }} />
//                 <button onClick={() => removeSubImage(index)} style={{ position: 'absolute', top: 0, right: 0 }}>X</button>
//               </div>
//             ))}
//           </div>
//           <div>
//             {previewSubImages.map((url, index) => (
//               <div key={index} style={{ position: 'relative', display: 'inline-block', marginRight: '10px' }}>
//                 <img src={url} alt={`Preview Sub Image ${index + 1}`} style={{ width: '100px', height: '100px' }} />
//                 <button onClick={() => removePreviewSubImage(index)} style={{ position: 'absolute', top: 0, right: 0 }}>X</button>
//               </div>
//             ))}
//           </div>
//         </div>
//         <button type='submit'>저장</button>
//       </form>
//     </div>
//   );
// };

// export default EditMyStore;

// import React, { useState, useEffect } from 'react';
// import { useParams, useNavigate } from 'react-router-dom';
// import { useSelector } from 'react-redux';
// import {
//   db,
//   doc,
//   updateDoc,
//   getDoc,
//   storage,
//   ref,
//   uploadBytes,
//   getDownloadURL
// } from '../firebaseConfig';

// const EditMyStore = () => {
//   const [store, setStore] = useState({ storeName: '', description: '', mainImages: [], subImages: [] });
//   const [newMainImages, setNewMainImages] = useState([]);
//   const [previewMainImages, setPreviewMainImages] = useState([]);
//   const [newSubImages, setNewSubImages] = useState([]);
//   const [previewSubImages, setPreviewSubImages] = useState([]);

//   const [error, setError] = useState(null);
//   const { storeId } = useParams();
//   const navigate = useNavigate();
//   const currentUser = useSelector(state => state.auth.currentUser);

//   useEffect(() => {
//     const fetchStore = async () => {
//       try {
//         const storeRef = doc(db, 'stores', storeId);
//         const storeDoc = await getDoc(storeRef);

//         if (storeDoc.exists()) {
//           const storeData = storeDoc.data();
//           setStore(storeData);
//           // 기존 이미지 미리보기 로드
//           if (storeData.mainImages) {
//             setPreviewMainImages(storeData.mainImages);
//           }
//           if (storeData.subImages) {
//             setPreviewSubImages(storeData.subImages);
//           }
//         } else {
//           setError('Store not found');
//         }
//       } catch (err) {
//         setError(`Error fetching store: ${err.message}`);
//       }
//     };

//     fetchStore();
//   }, [storeId]);

//   const handleMainImageChange = (e) => {
//     const selectedFiles = Array.from(e.target.files);
//     const newPreviewUrls = selectedFiles.map(file => URL.createObjectURL(file));
//     setNewMainImages([...newMainImages, ...selectedFiles]);
//     setPreviewMainImages([...previewMainImages, ...newPreviewUrls]);
//   };

//   const handleSubImagesChange = (e) => {
//     const selectedFiles = Array.from(e.target.files);
//     const newPreviewUrls = selectedFiles.map(file => URL.createObjectURL(file));
//     setNewSubImages([...newSubImages, ...selectedFiles]);
//     setPreviewSubImages([...previewSubImages, ...newPreviewUrls]);
//   };

//   const removeExistingMainImage = (index) => {
//     const updatedMainImages = store.mainImages.filter((_, i) => i !== index);
//     setStore({ ...store, mainImages: updatedMainImages });
//     const updatedPreviewMainImages = previewMainImages.filter((_, i) => i !== index);
//     setPreviewMainImages(updatedPreviewMainImages);
//   };

//   const removeExistingSubImage = (index) => {
//     const updatedSubImages = store.subImages.filter((_, i) => i !== index);
//     setStore({ ...store, subImages: updatedSubImages });
//     const updatedPreviewSubImages = previewSubImages.filter((_, i) => i !== index);
//     setPreviewSubImages(updatedPreviewSubImages);
//   };

//   const uploadImages = async () => {
//     let mainImageUrls = store.mainImages || [];
//     let subImageUrls = store.subImages || [];

//     const newMainImagePromises = newMainImages.filter(file => typeof file === "object").map(async (file, index) => {
//       const mainImageRef = ref(storage, `stores/${storeId}/mainImages/${file.name}-${index}`);
//       await uploadBytes(mainImageRef, file);
//       return getDownloadURL(mainImageRef);
//     });

//     const newSubImagePromises = newSubImages.filter(file => typeof file === "object").map(async (file, index) => {
//       const subImageRef = ref(storage, `stores/${storeId}/subImages/${file.name}-${index}`);
//       await uploadBytes(subImageRef, file);
//       return getDownloadURL(subImageRef);
//     });

//     mainImageUrls = [...mainImageUrls, ...(await Promise.all(newMainImagePromises))];
//     subImageUrls = [...subImageUrls, ...(await Promise.all(newSubImagePromises))];

//     return { mainImageUrls, subImageUrls };
//   };

//   const handleChange = (e) => {
//     setStore({ ...store, [e.target.name]: e.target.value });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const { mainImageUrls, subImageUrls } = await uploadImages();
//       const storeRef = doc(db, 'stores', storeId);
//       await updateDoc(storeRef, {
//         storeName: store.storeName,
//         name: store.name,
//         description: store.description,
//         mainImages: mainImageUrls,
//         subImages: subImageUrls
//       });
//       navigate(`/mystore/${storeId}`);
//     } catch (err) {
//       setError(`Error updating store: ${err.message}`);
//     }
//   };

//   if (error) {
//     return <div>Error: {error}</div>;
//   }

//   return (
//     <div className='edit-store'>
//       <h2>스토어 정보 수정</h2>
//       <form onSubmit={handleSubmit}>
//         <div>
//           <label>스토어 이름:</label>
//           <input type='text' name='storeName' value={store.storeName} onChange={handleChange} />
//         </div>
//         <div>
//           <label>이름:</label>
//           <input type='text' name='name' value={store.name || ''} onChange={handleChange} />
//         </div>
//         <div>
//           <label>내용:</label>
//           <textarea name='description' value={store.description} onChange={handleChange} />
//         </div>

//         {/* Main Images Upload and Preview */}
//         <div>
//           <label>메인 이미지:</label>
//           <input type='file' multiple onChange={handleMainImageChange} />
//           <div>
//             {previewMainImages.map((url, index) => (
//               <div key={index} style={{ position: 'relative', display: 'inline-block', marginRight: '10px' }}>
//                 <img src={url} alt={`Preview Main Image ${index}`} style={{ width: '100px', height: '100px' }} />
//                 <button onClick={() => removeExistingMainImage(index)} style={{ position: 'absolute', top: 0, right: 0 }}>X</button>
//               </div>
//             ))}
//           </div>
//         </div>

//         {/* Sub Images Upload and Preview */}
//         <div>
//           <label>부가 이미지:</label>
//           <input type='file' multiple onChange={handleSubImagesChange} />
//           <div>
//             {previewSubImages.map((url, index) => (
//               <div key={index} style={{ position: 'relative', display: 'inline-block', marginRight: '10px' }}>
//                 <img src={url} alt={`Preview Sub Image ${index}`} style={{ width: '100px', height: '100px' }} />
//                 <button onClick={() => removeExistingSubImage(index)} style={{ position: 'absolute', top: 0, right: 0 }}>X</button>
//               </div>
//             ))}
//           </div>
//         </div>

//         <button type='submit'>저장</button>
//       </form>
//     </div>
//   );
// };

// export default EditMyStore;

// import React, { useState, useEffect } from 'react';
// import { useParams, useNavigate } from 'react-router-dom';
// import { useSelector } from 'react-redux';
// import {
//   db,
//   doc,
//   updateDoc,
//   getDoc,
//   storage,
//   ref,
//   uploadBytes,
//   getDownloadURL,
// } from '../firebaseConfig';
// import '../css/detail.css';
// const EditMyStore = () => {
//   const [store, setStore] = useState({
//     storeName: '',
//     description: '',
//     mainImages: [],
//     subImages: [],
//   });
//   const [newMainImages, setNewMainImages] = useState([]);
//   const [previewMainImages, setPreviewMainImages] = useState([]);
//   const [newSubImages, setNewSubImages] = useState([]);
//   const [previewSubImages, setPreviewSubImages] = useState([]);
//   const [deleteMainImageIndices, setDeleteMainImageIndices] = useState([]);
//   const [deleteSubImageIndices, setDeleteSubImageIndices] = useState([]);

//   const [error, setError] = useState(null);
//   const { storeId } = useParams();
//   const navigate = useNavigate();
//   const currentUser = useSelector((state) => state.auth.currentUser);

//   useEffect(() => {
//     const fetchStore = async () => {
//       try {
//         const storeRef = doc(db, 'stores', storeId);
//         const storeDoc = await getDoc(storeRef);

//         if (storeDoc.exists()) {
//           const storeData = storeDoc.data();
//           setStore(storeData);
//           setPreviewMainImages(storeData.mainImages || []);
//           setPreviewSubImages(storeData.subImages || []);
//         } else {
//           setError('Store not found');
//         }
//       } catch (err) {
//         setError(`Error fetching store: ${err.message}`);
//       }
//     };

//     fetchStore();
//   }, [storeId]);

//   const handleMainImageChange = (e) => {
//     const selectedFiles = Array.from(e.target.files);
//     const newPreviewUrls = selectedFiles.map((file) =>
//       URL.createObjectURL(file)
//     );
//     setNewMainImages([...newMainImages, ...selectedFiles]);
//     setPreviewMainImages([...previewMainImages, ...newPreviewUrls]);
//   };

//   const handleSubImagesChange = (e) => {
//     const selectedFiles = Array.from(e.target.files);
//     const newPreviewUrls = selectedFiles.map((file) =>
//       URL.createObjectURL(file)
//     );
//     setNewSubImages([...newSubImages, ...selectedFiles]);
//     setPreviewSubImages([...previewSubImages, ...newPreviewUrls]);
//   };

//   const markDeleteMainImage = (index) => {
//     setDeleteMainImageIndices([...deleteMainImageIndices, index]);
//   };

//   const markDeleteSubImage = (index) => {
//     setDeleteSubImageIndices([...deleteSubImageIndices, index]);
//   };
//   const uploadImages = async () => {
//     // Ensure store.mainImages and store.subImages are initialized properly
//     const mainImages = store.mainImages || [];
//     const subImages = store.subImages || [];

//     // Filter out images marked for deletion
//     const filteredMainImages = mainImages.filter(
//       (_, index) => !deleteMainImageIndices.includes(index)
//     );
//     const filteredSubImages = subImages.filter(
//       (_, index) => !deleteSubImageIndices.includes(index)
//     );

//     // Helper function to upload new images and return their URLs
//     const uploadNewImages = async (images) => {
//       if (images.length === 0) {
//         return []; // Return empty array if no images to upload
//       }
//       return Promise.all(
//         images.map(async (file) => {
//           const imageRef = ref(storage, `stores/${storeId}/${file.name}`);
//           await uploadBytes(imageRef, file);
//           return getDownloadURL(imageRef);
//         })
//       );
//     };

//     // Upload new images if there are any
//     const newMainImageUrls = newMainImages
//       ? await uploadNewImages(newMainImages)
//       : [];
//     const newSubImageUrls = newSubImages
//       ? await uploadNewImages(newSubImages)
//       : [];

//     // Combine old filtered images with new uploaded images URLs
//     return {
//       mainImageUrls: [...filteredMainImages, ...newMainImageUrls],
//       subImageUrls: [...filteredSubImages, ...newSubImageUrls],
//     };
//   };

//   const handleChange = (e) => {
//     setStore({ ...store, [e.target.name]: e.target.value });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const { mainImageUrls, subImageUrls } = await uploadImages();
//       const storeRef = doc(db, 'stores', storeId);
//       await updateDoc(storeRef, {
//         storeName: store.storeName,
//         name: store.name,
//         description: store.description,
//         mainImages: mainImageUrls,
//         subImages: subImageUrls,
//       });
//       navigate(`/mystore/${storeId}`);
//     } catch (err) {
//       setError(`Error updating store: ${err.message}`);
//     }
//   };

//   if (error) {
//     return <div>Error: {error}</div>;
//   }

//   return (
//     <div className="detail">
//       <div className="container ">
//         <h2>스토어 정보 수정</h2>
//         <form onSubmit={handleSubmit}>
//           <div>
//             <label>스토어 이름:</label>
//             <input
//               type="text"
//               name="storeName"
//               value={store.storeName}
//               onChange={handleChange}
//             />
//           </div>
//           <div>
//             <label>이름:</label>
//             <input
//               type="text"
//               name="name"
//               value={store.name || ''}
//               onChange={handleChange}
//             />
//           </div>
//           <div>
//             <label>내용:</label>
//             <textarea
//               name="description"
//               value={store.description}
//               onChange={handleChange}
//             />
//           </div>

//           <div>
//             <label>메인 이미지:</label>
//             <input type="file" multiple onChange={handleMainImageChange} />
//             <div
//               style={{
//                 display: 'flex',
//                 overflowX: 'auto',
//                 alignItems: 'center',
//               }}
//             >
//               {previewMainImages.map((url, index) => (
//                 <div
//                   key={index}
//                   style={{
//                     display: 'flex',
//                     flexDirection: 'row',
//                     alignItems: 'center',
//                     marginRight: '50px',
//                   }}
//                 >
//                   <img
//                     src={url}
//                     alt={`Main Image ${index}`}
//                     style={{ width: '200px', height: '160px' }}
//                   />
//                   <button
//                     style={{
//                       marginTop: '5px',
//                       padding: '5px 10px', // 버튼의 크기를 조정
//                       backgroundColor: '#f44336', // 빨간색 배경
//                       color: 'white', // 흰색 텍스트
//                       border: 'none', // 테두리 없음
//                       borderRadius: '5px', // 모서리 둥글게
//                       cursor: 'pointer', // 마우스 오버 시 커서 변경
//                       fontSize: '0.8rem', // 폰트 크기 작게
//                     }}
//                     onClick={() => markDeleteMainImage(index)}
//                   >
//                     X
//                   </button>
//                 </div>
//               ))}
//             </div>
//           </div>
//           <br />

//           {/* Sub Images Upload and Preview */}
//           <div>
//             <label>부가 이미지:</label>
//             <input type="file" multiple onChange={handleSubImagesChange} />
//             <div
//               style={{
//                 display: 'flex',
//                 overflowX: 'auto',
//                 alignItems: 'center',
//               }}
//             >
//               {previewSubImages.map((url, index) => (
//                 <div
//                   key={index}
//                   style={{
//                     display: 'flex',
//                     flexDirection: 'row',
//                     alignItems: 'center',
//                     marginRight: '50px',
//                   }}
//                 >
//                   <img
//                     src={url}
//                     alt={`Sub Image ${index}`}
//                     style={{ width: '200px', height: '160px' }}
//                   />
//                   <button
//                     style={{
//                       marginTop: '5px',
//                       padding: '5px 10px', // 버튼의 크기를 조정
//                       backgroundColor: '#f44336', // 빨간색 배경
//                       color: 'white', // 흰색 텍스트
//                       border: 'none', // 테두리 없음
//                       borderRadius: '5px', // 모서리 둥글게
//                       cursor: 'pointer', // 마우스 오버 시 커서 변경
//                       fontSize: '0.8rem', // 폰트 크기 작게
//                     }}
//                     onClick={() => markDeleteSubImage(index)}
//                   >
//                     X
//                   </button>
//                 </div>
//               ))}
//             </div>
//           </div>
//           <br />
//           <button type="submit">저장</button>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default EditMyStore;

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  db,
  doc,
  updateDoc,
  getDoc,
  storage,
  ref,
  uploadBytes,
  getDownloadURL,
} from '../firebaseConfig';
import { Container, TextField, Button, Box, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import '../css/detail.css';

const EditMyStore = () => {
  const [store, setStore] = useState({
    storeName: '',
    name: '',
    description: '',
    mainImages: [],
    subImages: [],
  });
  const [newMainImages, setNewMainImages] = useState([]);
  const [previewMainImages, setPreviewMainImages] = useState([]);
  const [newSubImages, setNewSubImages] = useState([]);
  const [previewSubImages, setPreviewSubImages] = useState([]);
  const [deleteMainImageIndices, setDeleteMainImageIndices] = useState([]);
  const [deleteSubImageIndices, setDeleteSubImageIndices] = useState([]);

  const [error, setError] = useState(null);
  const { storeId } = useParams();
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.auth.currentUser);

  useEffect(() => {
    const fetchStore = async () => {
      try {
        const storeRef = doc(db, 'stores', storeId);
        const storeDoc = await getDoc(storeRef);

        if (storeDoc.exists()) {
          const storeData = storeDoc.data();
          setStore(storeData);
          setPreviewMainImages(storeData.mainImages || []);
          setPreviewSubImages(storeData.subImages || []);
        } else {
          setError('Store not found');
        }
      } catch (err) {
        setError(`Error fetching store: ${err.message}`);
      }
    };

    fetchStore();
  }, [storeId]);

  const uploadImages = async () => {
    let mainImageUrls = store.mainImages || [];
    let subImageUrls = store.subImages || [];

    const newMainImagePromises = newMainImages
      .filter((file) => typeof file === 'object')
      .map(async (file, index) => {
        const mainImageRef = ref(
          storage,
          `stores/${storeId}/mainImages/${file.name}-${index}`
        );
        await uploadBytes(mainImageRef, file);
        return getDownloadURL(mainImageRef);
      });

    const newSubImagePromises = newSubImages
      .filter((file) => typeof file === 'object')
      .map(async (file, index) => {
        const subImageRef = ref(
          storage,
          `stores/${storeId}/subImages/${file.name}-${index}`
        );
        await uploadBytes(subImageRef, file);
        return getDownloadURL(subImageRef);
      });

    mainImageUrls = [
      ...mainImageUrls,
      ...(await Promise.all(newMainImagePromises)),
    ];
    subImageUrls = [
      ...subImageUrls,
      ...(await Promise.all(newSubImagePromises)),
    ];

    return { mainImageUrls, subImageUrls };
  };
  const handleMainImageChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const newPreviewUrls = selectedFiles.map((file) =>
      URL.createObjectURL(file)
    );
    setNewMainImages([...newMainImages, ...selectedFiles]);
    setPreviewMainImages([...previewMainImages, ...newPreviewUrls]);
  };

  const handleSubImagesChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const newPreviewUrls = selectedFiles.map((file) =>
      URL.createObjectURL(file)
    );
    setNewSubImages([...newSubImages, ...selectedFiles]);
    setPreviewSubImages([...previewSubImages, ...newPreviewUrls]);
  };

  const markDeleteMainImage = (index) => {
    setDeleteMainImageIndices([...deleteMainImageIndices, index]);
  };

  const markDeleteSubImage = (index) => {
    setDeleteSubImageIndices([...deleteSubImageIndices, index]);
  };

  const handleChange = (e) => {
    setStore({ ...store, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { mainImageUrls, subImageUrls } = await uploadImages();
      const storeRef = doc(db, 'stores', storeId);
      await updateDoc(storeRef, {
        storeName: store.storeName,
        description: store.description,
        mainImages: mainImageUrls,
        subImages: subImageUrls,
      });
      navigate(`/mystore/${storeId}`);
    } catch (err) {
      setError(`Error updating store: ${err.message}`);
    }
  };

  if (error) {
    return <Typography color="error">Error: {error}</Typography>;
  }

  return (
    <Container maxWidth="md" className="detail">
      <Typography variant="h4" gutterBottom>
        스토어 정보 수정
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="스토어 이름"
          name="storeName"
          value={store.storeName}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          fullWidth
          label="내용"
          name="description"
          multiline
          rows={4}
          value={store.description}
          onChange={handleChange}
          margin="normal"
        />

        <Typography variant="h6" gutterBottom>
          메인 이미지
        </Typography>
        <Button variant="contained" component="label">
          업로드
          <input type="file" multiple hidden onChange={handleMainImageChange} />
        </Button>
        <Box
          sx={{
            display: 'flex',
            overflowX: 'auto',
            alignItems: 'center',
            mt: 2,
          }}
        >
          {previewMainImages.map((url, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                mr: 5,
              }}
            >
              <img
                src={url}
                alt={`Main Image ${index}`}
                style={{ width: '200px', height: '160px' }}
              />
              <IconButton
                onClick={() => markDeleteMainImage(index)}
                color="error"
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          ))}
        </Box>

        <Typography variant="h6" gutterBottom>
          부가 이미지
        </Typography>
        <Button variant="contained" component="label">
          업로드
          <input type="file" multiple hidden onChange={handleSubImagesChange} />
        </Button>
        <Box
          sx={{
            display: 'flex',
            overflowX: 'auto',
            alignItems: 'center',
            mt: 2,
          }}
        >
          {previewSubImages.map((url, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                mr: 5,
              }}
            >
              <img
                src={url}
                alt={`Sub Image ${index}`}
                style={{ width: '200px', height: '160px' }}
              />
              <IconButton
                onClick={() => markDeleteSubImage(index)}
                color="error"
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          ))}
        </Box>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ mt: 3 }}
        >
          저장
        </Button>
      </form>
    </Container>
  );
};

export default EditMyStore;
