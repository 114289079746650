import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  items: [],
  itemsCount: 0,
  totalAmount: 0,
  loading: false,
  error: null,
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addItemToCart(state, action) {
      const newItem = action.payload;
      const existingItem = state.items.find((item) => item.id === newItem.id);

      if (existingItem) {
        existingItem.quantity += 1;
      } else {
        state.items.push({ ...newItem, quantity: 1 });
      }

      state.itemsCount += 1;
      state.totalAmount += newItem.price;
    },
    removeItemFromCart(state, action) {
      const id = action.payload;
      const existingItem = state.items.find((item) => item.id === id);

      if (existingItem) {
        state.totalAmount -= existingItem.price;
        state.itemsCount -= existingItem.quantity;

        state.items = state.items.filter((item) => item.id !== id);
      }
    },
    updateCartItemsCount(state, action) {
      state.itemsCount = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    clearCart(state) {
      state.items = [];
      state.itemsCount = 0;
      state.totalAmount = 0;
    },
  },
});

export const {
  addItemToCart,
  removeItemFromCart,
  updateCartItemsCount,
  setLoading,
  setError,
  clearCart,
} = cartSlice.actions;

export default cartSlice.reducer;
