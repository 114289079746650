// store.js

import { configureStore } from '@reduxjs/toolkit';
import authReducer from './reducers/authReducer';
import searchKeywordReducer from './reducers/searchReducer';
import editUserReducer from './reducers/editUserReducer';
import orderReducer from './reducers/orderReducer';
import floatingSidebarReducer from './reducers/floatingSidebarReducer';
import cartReducer from './reducers/cartReducer';

const store = configureStore({
  reducer: {
    auth: authReducer,
    search: searchKeywordReducer,
    edit: editUserReducer,
    order: orderReducer,
    floatingSidebar: floatingSidebarReducer,
    cart: cartReducer,
  },
});

export default store;
