import React, { useState, useEffect } from 'react';
import {
  db,
  collection,
  getDocs,
  query,
  where,
  orderBy,
} from '../../firebaseConfig';
import {
  Container,
  Typography,
  Button,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Paper,
  Breadcrumbs,
  Link,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { googleTranslate } from '../../Utils/GoogleTranslation';
import axios from 'axios';

function PrivacyList() {
  const { t, i18n } = useTranslation();
  const [versions, setVersions] = useState([]);
  const [version, setVersion] = useState(null);
  const [privacyList, setPrivacyList] = useState([]);
  const [selectedPrivacyId, setSelectedPrivacyId] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);

  useEffect(() => {
    const fetchVersions = async () => {
      const privacyListCollection = collection(db, 'privacy');
      const privacyListSnapshot = await getDocs(privacyListCollection);
      const allVersions = privacyListSnapshot.docs.map(
        (doc) => doc.data().version
      );
      const uniqueVersions = [...new Set(allVersions)].sort((a, b) => b - a);
      setVersions(uniqueVersions);
      if (uniqueVersions.length > 0) {
        setVersion(uniqueVersions[0]);
      }
    };
    fetchVersions();
  }, []);

  useEffect(() => {
    if (version != null) {
      fetchPrivacyList(version);
    }
  }, [version]);

  // const fetchPrivacyList = async (version) => {
  //   const privacyListCollection = collection(db, 'privacy');
  //   const q = query(
  //     privacyListCollection,
  //     where('version', '==', version),
  //     orderBy('created_at')
  //   );
  //   const privacyListSnapshot = await getDocs(q);
  //   const privacyListData = privacyListSnapshot.docs.map((doc) => ({
  //     ...doc.data(),
  //     id: doc.id,
  //     items: doc
  //       .data()
  //       .items.map((item, index) => ({ ...item, id: `${doc.id}_${index}` })),
  //   }));
  //   setPrivacyList(privacyListData);

  //   if (privacyListData.length > 0) {
  //     setSelectedPrivacyId(privacyListData[0].id); // 첫 번째 용어 자동 선택
  //     if (privacyListData[0].items.length > 0) {
  //       setSelectedItemId(privacyListData[0].items[0].id); // 첫 번째 용어의 첫 번째 아이템 자동 선택
  //     }
  //   }
  // };

  const fetchPrivacyList = async (version) => {
    const privacyListCollection = collection(db, 'privacy');
    const q = query(
      privacyListCollection,
      where('version', '==', version),
      orderBy('created_at')
    );
    const privacyListSnapshot = await getDocs(q);

    const privacyData = await Promise.all(
      privacyListSnapshot.docs.map(async (doc) => {
        const translatedItems = await Promise.all(
          doc.data().items.map(async (item, index) => {
            const translatedTitle = await googleTranslate(item.title, i18n);
            const translatedContent = await googleTranslate(item.content, i18n);
            return {
              ...item,
              title: translatedTitle,
              content: translatedContent,
              id: `${doc.id}_${index}`,
            };
          })
        );

        const translatedPrivacyName = await googleTranslate(
          doc.data().name,
          i18n
        );

        return {
          ...doc.data(),
          name: translatedPrivacyName,
          id: doc.id,
          items: translatedItems,
        };
      })
    );

    setPrivacyList(privacyData);

    if (privacyData.length > 0) {
      setSelectedPrivacyId(privacyData[0].id); // 첫 번째 항목 자동 선택
      if (privacyData[0].items.length > 0) {
        setSelectedItemId(privacyData[0].items[0].id); // 첫 번째 항목의 첫 번째 아이템 자동 선택
      }
    }
  };

  // const fetchPrivacyList = async (version) => {
  //   try {
  //     const privacyListCollection = collection(db, 'privacy');
  //     const q = query(
  //       privacyListCollection,
  //       where('version', '==', version),
  //       orderBy('created_at')
  //     );
  //     const privacyListSnapshot = await getDocs(q);

  //     const privacyData = await Promise.all(
  //       privacyListSnapshot.docs.map(async (doc) => {
  //         const translatedItems = await Promise.all(
  //           doc.data().items.map(async (item, index) => {
  //             try {
  //               const translatedTitle = await googleTranslate(item.title, i18n);
  //               const translatedContent = await googleTranslate(
  //                 item.content,
  //                 i18n
  //               );
  //               return {
  //                 ...item,
  //                 title: translatedTitle,
  //                 content: translatedContent,
  //                 id: `${doc.id}_${index}`,
  //               };
  //             } catch (translateError) {
  //               console.error(
  //                 `Error translating item ${index}:`,
  //                 translateError
  //               );
  //               return { ...item, id: `${doc.id}_${index}` };
  //             }
  //           })
  //         );

  //         try {
  //           const translatedPrivacyName = await googleTranslate(
  //             doc.data().name,
  //             i18n
  //           );
  //           return {
  //             ...doc.data(),
  //             name: translatedPrivacyName,
  //             id: doc.id,
  //             items: translatedItems,
  //           };
  //         } catch (translateError) {
  //           console.error('Error translating privacy name:', translateError);
  //           return {
  //             ...doc.data(),
  //             id: doc.id,
  //             items: translatedItems,
  //           };
  //         }
  //       })
  //     );

  //     setPrivacyList(privacyData);

  //     if (privacyData.length > 0) {
  //       setSelectedPrivacyId(privacyData[0].id); // 첫 번째 항목 자동 선택
  //       if (privacyData[0].items.length > 0) {
  //         setSelectedItemId(privacyData[0].items[0].id); // 첫 번째 항목의 첫 번째 아이템 자동 선택
  //       }
  //     }
  //   } catch (error) {
  //     if (
  //       error.code === 'failed-precondition' ||
  //       error.code === 'unavailable'
  //     ) {
  //       console.error('Firestore index error:', error.message);
  //       // 필요한 경우 사용자에게 알림을 표시하거나 추가 처리를 수행할 수 있습니다.
  //     } else {
  //       console.error('Error fetching privacy data:', error);
  //     }
  //   }
  // };

  const handleVersionChange = (event) => {
    setVersion(event.target.value);
    setSelectedPrivacyId(null);
    setSelectedItemId(null);
  };

  const handleItemClick = (itemId) => {
    setSelectedItemId(itemId);
  };

  const selectedPrivacy = privacyList.find(
    (privacy) => privacy.id === selectedPrivacyId
  );
  const selectedItem = selectedPrivacy?.items.find(
    (item) => item.id === selectedItemId
  );

  return (
    <Container sx={{ mt: 5 }}>
      <Typography variant="h4" gutterBottom>
        {t('privacy-policy.title')}
      </Typography>
      <Box display="flex" justifyContent="flex-end">
        <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2, mt: 2 }}>
          <Link
            color="inherit"
            href="/"
            onClick={(event) => event.preventDefault()}
          >
            {t('home')}
          </Link>
          <Link
            color="inherit"
            href="/support"
            onClick={(event) => event.preventDefault()}
          >
            {t('customer-service.title')}
          </Link>
          <Typography color="textPrimary">
            {t('privacy-policy.title')}
          </Typography>
        </Breadcrumbs>
      </Box>

      {selectedPrivacy && (
        <Paper elevation={0} sx={{ mt: 1, p: 0 }}>
          <Typography variant="h6">{selectedPrivacy.title}</Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}
          >
            {selectedPrivacy.items.map((item) => (
              <Button
                key={item.id}
                onClick={() => handleItemClick(item.id)}
                variant={item.id === selectedItemId ? 'contained' : 'outlined'}
                sx={{
                  flexGrow: 1,
                  margin: '4px',
                  flexBasis: `calc(${
                    100 / selectedPrivacy.items.length
                  }% - 10px)`,
                }}
              >
                {item.title}
              </Button>
            ))}
          </Box>
        </Paper>
      )}

      {selectedItem && (
        <Paper
          elevation={2}
          sx={{ mt: 2, p: 2, whiteSpace: 'pre-wrap', border: '1px solid #ccc' }}
        >
          <Typography variant="subtitle1" sx={{ textDecoration: 'underline' }}>
            {selectedItem.title}
          </Typography>
          <Typography>{selectedItem.content}</Typography>
        </Paper>
      )}

      <FormControl fullWidth margin="normal">
        <InputLabel>Version</InputLabel>
        <Select
          value={version || ''}
          onChange={handleVersionChange}
          label="Version"
        >
          {versions.map((v) => (
            <MenuItem key={v} value={v}>
              {v}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Container>
  );
}

export default PrivacyList;
