// import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import { useParams } from 'react-router-dom';
// import {
//   Container,
//   Typography,
//   CircularProgress,
//   Alert,
//   Snackbar,
//   TextField,
//   Button,
//   Table,
//   TableBody,
//   TableCell,
//   TableHead,
//   TableRow,
//   Box,
//   Select,
//   MenuItem,
//   InputLabel,
//   FormControl,
// } from '@mui/material';
// import OrderDetails from './OrderDetails';
// import TrackShipment from './TrackShipment';
// import {
//   db,
//   doc,
//   getDoc,
//   updateDoc,
//   collection,
//   query,
//   where,
//   getDocs,
// } from '../../firebaseConfig';
// import './orders.css';

// function Orders() {
//   const { userId } = useParams();
//   const [orders, setOrders] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [selectedOrder, setSelectedOrder] = useState(null);
//   const [searchTerm, setSearchTerm] = useState('');
//   const [orderStatusFilter, setOrderStatusFilter] = useState('all');
//   const [filteredOrders, setFilteredOrders] = useState([]);

//   useEffect(() => {
//     const fetchOrders = async () => {
//       setLoading(true);
//       try {
//         const fetchedOrders = await getAllOrdersForUser(userId);
//         setOrders(fetchedOrders);
//         setLoading(false);
//       } catch (err) {
//         setError(`Failed to fetch orders: ${err.message}`);
//         setLoading(false);
//       }
//     };
//     fetchOrders();
//   }, [userId]);

//   useEffect(() => {
//     setFilteredOrders(
//       orderStatusFilter === 'all'
//         ? orders
//         : orders.filter((order) => order.orderStatus === orderStatusFilter)
//     );
//   }, [orderStatusFilter, orders]);

//   const getAllOrdersForUser = async (userId) => {
//     try {
//       const allOrders = [];
//       const storesRef = collection(db, 'stores');
//       const storesSnapshot = await getDocs(storesRef);
//       // const stores = storesSnapshot.docs.map((doc) => doc.id);
//       // for (let storeId of stores) {
//       const stores = storesSnapshot.docs.map((doc) => ({
//         id: doc.id,
//         ...doc.data(), // 문서의 데이터를 객체로 확장
//       }));
//       for (let store of stores) {
//         const ordersRef = collection(db, 'stores', store.id, 'orders');
//         const q = query(ordersRef, where('userId', '==', userId));
//         const querySnapshot = await getDocs(q);
//         const orders = querySnapshot.docs.map((doc) => ({
//           id: doc.id,
//           storeName: store.storeName,
//           ...doc.data(),
//         }));
//         allOrders.push(...orders);
//       }

//       return allOrders;
//     } catch (error) {
//       console.error('Error fetching orders:', error);
//       throw error;
//     }
//   };
//   const handleSearch = (event) => {
//     event.preventDefault();
//     setFilteredOrders(orders.filter((order) => order.id.includes(searchTerm)));
//   };

//   if (loading) {
//     return (
//       <Container>
//         <CircularProgress />
//       </Container>
//     );
//   }

//   if (error) {
//     return (
//       <Container>
//         <Alert severity="error">{error}</Alert>
//       </Container>
//     );
//   }

//   function FilterSection() {
//     return (
//       <Box sx={{ my: 2 }}>
//         <FormControl fullWidth>
//           <InputLabel>Status</InputLabel>
//           <Select
//             value={orderStatusFilter}
//             label="Status"
//             onChange={(e) => setOrderStatusFilter(e.target.value)}
//           >
//             <MenuItem value="all">All Orders</MenuItem>
//             <MenuItem value="pending">Pending</MenuItem>
//             <MenuItem value="shipped">Shipped</MenuItem>
//             <MenuItem value="delivered">Delivered</MenuItem>
//             <MenuItem value="cancelled">Cancelled</MenuItem>
//             <MenuItem value="returned">Returned</MenuItem>
//           </Select>
//         </FormControl>
//         <Box
//           component="form"
//           onSubmit={handleSearch}
//           sx={{ display: 'flex', mt: 1 }}
//         >
//           <TextField
//             fullWidth
//             label="Search by Order ID"
//             variant="outlined"
//             value={searchTerm}
//             onChange={(e) => setSearchTerm(e.target.value)}
//           />
//           <Button type="submit" variant="contained" sx={{ ml: 1 }}>
//             Search
//           </Button>
//         </Box>
//       </Box>
//     );
//   }

//   return (
//     <React.Fragment>
//       <Container>
//         <Typography variant="h4" sx={{ my: 2 }}>
//           나의 주문 목록
//         </Typography>
//         <FilterSection />
//         {selectedOrder ? (
//           <OrderDetails
//             order={selectedOrder}
//             onBack={() => setSelectedOrder(null)}
//           />
//         ) : (
//           <Table>
//             <TableHead>
//               <TableRow>
//                 <TableCell>Order ID</TableCell>
//                 <TableCell>Date</TableCell>
//                 <TableCell>Status</TableCell>
//                 <TableCell>InvoiceNumber</TableCell>
//                 <TableCell>Actions</TableCell>
//                 <TableCell>StoreName</TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {filteredOrders.map((order) => (
//                 <TableRow key={order.id}>
//                   <TableCell>{order.id}</TableCell>
//                   <TableCell>{order.date}</TableCell>
//                   <TableCell>{order.orderStatus}</TableCell>
//                   <TableCell>
//                     <TrackShipment trackingNumber={order.invoiceNumber} />
//                   </TableCell>
//                   <TableCell>
//                     <Button
//                       onClick={() => setSelectedOrder(order)}
//                       variant="contained"
//                     >
//                       View Details
//                     </Button>
//                   </TableCell>
//                   <TableCell>{order.storeName}</TableCell>
//                 </TableRow>
//               ))}
//             </TableBody>
//           </Table>
//         )}
//       </Container>
//     </React.Fragment>
//   );
// }

// export default Orders;

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Container,
  Typography,
  CircularProgress,
  Alert,
  Snackbar,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Grid,
  ButtonGroup,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import {
  db,
  doc,
  getDoc,
  updateDoc,
  collection,
  query,
  where,
  getDocs,
} from '../firebaseConfig';
import { useTranslation } from 'react-i18next';
import useOrder from './StoreAdmin/useOrder';
import OrderDetails from './OrderDetails';
import TrackShipment from './StoreAdmin/TrackShipment';

function Orders() {
  const { t, i18n } = useTranslation();
  const { userId } = useParams();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [orderStatusFilter, setOrderStatusFilter] = useState('all');
  const [filteredOrders, setFilteredOrders] = useState([]);
  const { cancelOrder, returnOrder } = useOrder(userId);

  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      try {
        const fetchedOrders = await getAllOrdersForUser(userId);
        setOrders(fetchedOrders);
        setLoading(false);
      } catch (err) {
        setError(`Failed to fetch orders: ${err.message}`);
        setLoading(false);
      }
    };
    fetchOrders();
  }, [userId]);

  useEffect(() => {
    setFilteredOrders(
      orderStatusFilter === 'all'
        ? orders
        : orders.filter((order) => order.orderStatus === orderStatusFilter)
    );
  }, [orderStatusFilter, orders]);

  const getAllOrdersForUser = async (userId) => {
    try {
      const allOrders = [];
      const storesRef = collection(db, 'stores');
      const storesSnapshot = await getDocs(storesRef);
      // const stores = storesSnapshot.docs.map((doc) => doc.id);
      // for (let storeId of stores) {
      const stores = storesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(), // 문서의 데이터를 객체로 확장
      }));
      for (let store of stores) {
        const ordersRef = collection(db, 'stores', store.id, 'orders');
        const q = query(ordersRef, where('userId', '==', userId));
        const querySnapshot = await getDocs(q);
        const orders = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          storeName: store.storeName,
          ...doc.data(),
        }));
        allOrders.push(...orders);
      }

      return allOrders;
    } catch (error) {
      console.error('Error fetching orders:', error);
      throw error;
    }
  };
  const handleSearch = (event) => {
    event.preventDefault();
    setFilteredOrders(orders.filter((order) => order.id.includes(searchTerm)));
  };
  const handleCancelOrder = async (orderData) => {
    if (loading) return; // 이미 결제 중인 경우 무시
    setLoading(true); // 로딩 상태 시작

    try {
      await cancelOrder(orderData);
    } catch (error) {
      console.error('Error during payment:', error);
    } finally {
      setLoading(false); // 로딩 상태 종료
    }
  };
  const handleReturnOrder = async (orderData) => {
    if (loading) return; // 이미 결제 중인 경우 무시
    setLoading(true); // 로딩 상태 시작

    try {
      await returnOrder(orderData);
    } catch (error) {
      console.error('Error during payment:', error);
    } finally {
      setLoading(false); // 로딩 상태 종료
    }
  };
  function FilterSection() {
    return (
      <Grid item xs={12}>
        <Box sx={{ my: 2 }}>
          <FormControl fullWidth>
            <InputLabel>{t('status')}</InputLabel>
            <Select
              value={orderStatusFilter}
              label={t('status')}
              onChange={(e) => setOrderStatusFilter(e.target.value)}
            >
              <MenuItem value="all">{t('allOrders')}</MenuItem>
              <MenuItem value="pending">{t('pending')}</MenuItem>
              <MenuItem value="shipped">{t('shipped')}</MenuItem>
              <MenuItem value="delivered">{t('delivered')}</MenuItem>
              <MenuItem value="canceled">{t('canceled')}</MenuItem>
              <MenuItem value="returned">{t('returned')}</MenuItem>
            </Select>
          </FormControl>
          <Box
            component="form"
            onSubmit={handleSearch}
            sx={{ display: 'flex', mt: 1 }}
          >
            <TextField
              fullWidth
              label={t('searchByOrderId')}
              variant="outlined"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Button type="submit" variant="contained" sx={{ ml: 1 }}>
              {t('search')}
            </Button>
          </Box>
        </Box>
      </Grid>
    );
  }

  if (loading) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  return (
    <React.Fragment>
      <Container>
        <Typography variant="h4" sx={{ my: 2 }}>
          {t('myOrderList')}
        </Typography>
        <Grid container spacing={2}>
          <FilterSection />
          {selectedOrder ? (
            <OrderDetails
              order={selectedOrder}
              onBack={() => setSelectedOrder(null)}
            />
          ) : (
            <Grid item xs={12}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('productName')}</TableCell>
                    <TableCell>{t('storeName')}</TableCell>
                    <TableCell>{t('date')}</TableCell>
                    <TableCell>{t('status')}</TableCell>
                    <TableCell>{t('invoiceNumber')}</TableCell>
                    <TableCell>{t('actions')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredOrders.map((order) => (
                    <TableRow key={order.id}>
                      <TableCell>
                        {order.items?.length > 0 ? (
                          <List>
                            {order.items.map((item, index) => (
                              <ListItem key={index}>
                                <ListItemText
                                  primary={
                                    <Typography variant="body2">
                                      {item.productDetails?.name ||
                                        t('noNameAvailable')}
                                    </Typography>
                                  }
                                />
                              </ListItem>
                            ))}
                          </List>
                        ) : (
                          <Typography>{t('noItems')}</Typography>
                        )}
                      </TableCell>
                      <TableCell>{order.storeName}</TableCell>
                      <TableCell>{order.date}</TableCell>
                      <TableCell>{order.orderStatus}</TableCell>
                      <TableCell>
                        <TrackShipment trackingNumber={order.invoiceNumber} />
                      </TableCell>
                      <TableCell>
                        <Box sx={{ '& > *': { m: 1 } }}>
                          <ButtonGroup
                            variant="contained"
                            aria-label="outlined primary button group"
                          >
                            <Button
                              onClick={() => setSelectedOrder(order)}
                              color="primary"
                            >
                              {t('viewDetails')}
                            </Button>
                            <Button
                              onClick={() => handleCancelOrder(order)}
                              color="secondary"
                              disabled={
                                order.orderStatus !== 'pending' &&
                                order.orderStatus === 'canceled'
                              }
                            >
                              {t('cancel')}
                            </Button>
                            <Button
                              onClick={() => handleReturnOrder(order)}
                              color="error"
                              disabled={order.orderStatus !== 'delivered'}
                            >
                              {t('return')}
                            </Button>
                          </ButtonGroup>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          )}
        </Grid>
      </Container>
    </React.Fragment>
  );
}

export default Orders;
