// import React from 'react';
// import './css/ProductSellerInfoTab.css';

// const ProductSellerInfoTab = ({ seller }) => {
//   return (
//     <div className="seller-info">
//       <div className="seller-details">
//         <h2 className="info-title">판매자 정보</h2>
//         {/* <p className="info-item">이름: {seller.name}</p> */}
//         <p className="info-item">이메일: {seller.email}</p>
//         <p className="info-item">전화번호: {seller.phone}</p>
//       </div>
//       <div className="seller-description">
//         <h2 className="info-title">판매자 소개</h2>
//         <p className="info-item description">{seller.description}</p>
//       </div>
//     </div>
//   );
// };

// export default ProductSellerInfoTab;

import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Grid } from '@mui/material';

const ProductSellerInfoTab = ({ seller }) => {
  if (!seller) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography variant="body2">판매자 정보가 없습니다.</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            판매자 정보
          </Typography>
          <Typography variant="body1" className="info-item">
            이메일: {seller.email}
          </Typography>
          <Typography variant="body1" className="info-item">
            전화번호: {seller.phone}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            판매자 소개
          </Typography>
          <Typography variant="body1" className="info-item description">
            {seller.description}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

ProductSellerInfoTab.propTypes = {
  seller: PropTypes.shape({
    email: PropTypes.string,
    phone: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
};

export default ProductSellerInfoTab;
