import React, { useEffect, useState } from 'react';
import {
  db,
  collection,
  doc,
  setDoc,
  arrayUnion,
  Timestamp,
} from '../firebaseConfig';

function useStoreUserActivity(currentUser) {
  const [anonymousId, setAnonymousId] = useState(
    () => sessionStorage.getItem('anonymousId') || generateAnonymousId()
  );

  useEffect(() => {
    if (!sessionStorage.getItem('anonymousId')) {
      sessionStorage.setItem('anonymousId', anonymousId);
    }
  }, [anonymousId]);

  function generateAnonymousId() {
    return `anon-${Math.random().toString(36).substring(2, 15)}`;
  }

  async function logActivity(storeId, activityType, data) {
    const userId = currentUser?.uid || anonymousId;
    if (!userId || !storeId) {
      console.error('Invalid store ID or user identifier');
      return;
    }

    const userActivitiesRef = collection(
      db,
      'stores',
      storeId,
      'userActivities'
    );
    const userActivityDocRef = doc(userActivitiesRef, userId);
    const updateData = {
      [activityType]: arrayUnion(data),
      timestamp: Timestamp.now(),
    };

    try {
      await setDoc(userActivityDocRef, updateData, { merge: true });
    } catch (error) {
      console.error('Error logging store activity:', error);
      throw new Error('Failed to log activity due to an unexpected error.');
    }
  }

  const logViewedStoreProduct = (storeId, productId) =>
    logActivity(storeId, 'viewedProducts', productId);
  const logPurchasedStoreProduct = (storeId, productId) =>
    logActivity(storeId, 'purchasedProducts', productId);
  const logSearchedStoreKeyword = (storeId, keyword) =>
    logActivity(storeId, 'searchedKeyword', keyword);
  const logSearchedStoreCategory = (storeId, keyword) =>
    logActivity(storeId, 'searchedCategory', keyword);

  return {
    logViewedStoreProduct,
    logPurchasedStoreProduct,
    logSearchedStoreKeyword,
    logSearchedStoreCategory,
  };
}

export default useStoreUserActivity;
