import { useEffect } from 'react';

const useOutsideClick = (ref, callback) => {
  useEffect(() => {
    // Define the function to handle the click event
    const handleClickOutside = (event) => {
      // Check if the ref is null or if the click was outside the ref's current element
      if (ref?.current && !ref.current.contains(event.target)) {
        callback();
      }
    };

    // Add the event listener if ref is not null
    if (ref) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    // Cleanup function to remove the event listener
    return () => {
      if (ref) {
        document.removeEventListener('mousedown', handleClickOutside);
      }
    };
  }, [ref, callback]); // Depend on ref and callback
};

export default useOutsideClick;
