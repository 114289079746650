// import React, { useState, useEffect, useRef } from 'react';
// import {
//   TextField,
//   Button,
//   Box,
//   Typography,
//   CircularProgress,
//   Snackbar,
//   Alert,
//   Avatar,
//   Tooltip,
//   IconButton,
// } from '@mui/material';
// import {
//   getAuth,
//   updateProfile,
//   updateEmail,
//   onAuthStateChanged,
// } from 'firebase/auth';
// import { Edit as EditIcon } from '@mui/icons-material';
// import { useDispatch } from 'react-redux';
// import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
// import { db, doc, updateDoc } from './firebaseConfig'; // Firestore 관련 임포트 추가
// import { setCurrentUser } from './Redux/reducers/authReducer';
// import { useTranslation } from 'react-i18next';
// import UserRatings from './UserRating';

// function MyProfile() {
//   const auth = getAuth();
//   const fileInputRef = useRef(null);
//   const { t } = useTranslation();
//   const dispatch = useDispatch();

//   const [profile, setProfile] = useState({
//     displayName: '',
//     email: '',
//     photoURL: '',
//   });

//   const [loadingImage, setLoadingImage] = useState(false);
//   const [loadingProfile, setLoadingProfile] = useState(true);
//   const [previewImage, setPreviewImage] = useState('');
//   const [snackbar, setSnackbar] = useState({
//     open: false,
//     message: '',
//     severity: 'info',
//   });

//   useEffect(() => {
//     const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
//       if (currentUser) {
//         setProfile({
//           displayName: currentUser.displayName || '',
//           email: currentUser.email || '',
//           photoURL: currentUser.photoURL || '',
//         });
//         setPreviewImage(currentUser.photoURL || '');
//       }
//       setLoadingProfile(false);
//     });
//     return () => unsubscribe();
//   }, [auth]);

//   const handleInputChange = (event) => {
//     setProfile({ ...profile, [event.target.name]: event.target.value });
//   };

//   const handleFileChange = async (event) => {
//     const file = event.target.files[0];
//     if (!file) return;

//     const reader = new FileReader();
//     reader.onloadend = () => {
//       setPreviewImage(reader.result);
//     };
//     reader.readAsDataURL(file);

//     setLoadingImage(true);
//     const downloadURL = await uploadImage(file);
//     setLoadingImage(false);

//     if (downloadURL) {
//       await updateProfileImage(downloadURL);
//       setProfile((prev) => ({ ...prev, photoURL: downloadURL }));
//       showSnackbar(t('userProfileDetails.profileImageUpdated'), 'success');
//     }
//   };

//   const updateProfileImage = async (imageUrl) => {
//     try {
//       await updateProfile(auth.currentUser, { photoURL: imageUrl });
//       await auth.currentUser.reload(); // 현재 사용자 정보 새로 고침
//       dispatch(setCurrentUser({ ...auth.currentUser, photoURL: imageUrl }));
//       await updateFirestoreProfile({ photoURL: imageUrl });
//     } catch (error) {
//       console.error('Error updating profile image:', error);
//       showSnackbar(t('userProfileDetails.failedToUpdateProfileImage'), 'error');
//     }
//   };

//   const uploadImage = async (file) => {
//     const storage = getStorage();
//     const storageRef = ref(storage, `profileImages/${file.name}`);

//     try {
//       const snapshot = await uploadBytes(storageRef, file);
//       return await getDownloadURL(snapshot.ref);
//     } catch (error) {
//       console.error('Failed to upload image:', error);
//       showSnackbar(t('userProfileDetails.failedToUploadImage'), 'error');
//       return null;
//     }
//   };

//   const isUserAuthenticated = () => {
//     const user = auth.currentUser;
//     return user && user.uid;
//   };

//   const handleSubmit = async () => {
//     setLoadingProfile(true);
//     try {
//       const newProfile = {
//         ...profile,
//         displayName: profile.displayName,
//         photoURL: profile.photoURL,
//       };
//       if (!isUserAuthenticated()) {
//         console.error('User is not authenticated or currentUser is not valid.');
//         showSnackbar(
//           'User is not authenticated or currentUser is not valid.',
//           'error'
//         );
//         setLoadingProfile(false);
//         return;
//       }
//       const user = auth.currentUser;
//       if (user) {
//         // 개별적으로 속성을 업데이트
//         if (user.displayName !== newProfile.displayName) {
//           await updateProfile(user, { displayName: newProfile.displayName });
//         }
//         if (user.photoURL !== newProfile.photoURL) {
//           await updateProfile(user, { photoURL: newProfile.photoURL });
//         }
//         await auth.currentUser.reload(); // 프로필 업데이트 후 현재 사용자 정보 새로 고침
//         dispatch(
//           setCurrentUser({
//             ...auth.currentUser,
//             displayName: newProfile.displayName,
//             photoURL: newProfile.photoURL,
//           })
//         );
//       }
//       if (auth.currentUser.email !== newProfile.email) {
//         await handleUpdateEmail(newProfile.email);
//       }
//       await updateFirestoreProfile({
//         displayName: newProfile.displayName,
//         photoURL: newProfile.photoURL,
//       });
//       showSnackbar(t('userProfileDetails.profileUpdated'), 'success');
//     } catch (error) {
//       console.error('Failed to update profile:', error);
//       showSnackbar(t('userProfileDetails.failedToUpdateProfile'), 'error');
//     }
//     setLoadingProfile(false);
//   };

//   const handleUpdateEmail = async (newEmail) => {
//     try {
//       await updateEmail(auth.currentUser, newEmail);
//       await updateFirestoreProfile({ email: newEmail });
//     } catch (error) {
//       console.error('Failed to update email:', error);
//       showSnackbar(t('userProfileDetails.failedToUpdateEmail'), 'error');
//     }
//   };

//   const updateFirestoreProfile = async (updateData) => {
//     try {
//       const userRef = doc(db, 'users', auth.currentUser.uid);
//       await updateDoc(userRef, updateData);
//       console.log('User profile updated in Firestore successfully!');
//     } catch (error) {
//       console.error('Error updating user profile in Firestore:', error);
//     }
//   };

//   const showSnackbar = (message, severity) => {
//     setSnackbar({ open: true, message, severity });
//   };

//   const handleCloseSnackbar = () => {
//     setSnackbar({ open: false, message: '', severity: 'info' });
//   };

//   const triggerFileInput = () => {
//     fileInputRef.current.click();
//   };

//   if (loadingProfile) {
//     return (
//       <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
//         <CircularProgress />
//       </Box>
//     );
//   }

//   return (
//     <Box sx={{ maxWidth: 480, mx: 'auto', my: 4 }}>
//       <Typography variant="h5" component="h2" align="center">
//         {profile.email}
//       </Typography>
//       <Box
//         sx={{
//           display: 'flex',
//           justifyContent: 'center',
//           position: 'relative',
//           mt: 2,
//           mb: 2,
//         }}
//       >
//         <input
//           type="file"
//           onChange={handleFileChange}
//           style={{ display: 'none' }}
//           ref={fileInputRef}
//         />
//         <Avatar src={previewImage} sx={{ width: 140, height: 140 }} />
//         <Tooltip title={t('userProfileDetails.uploadAndUpdatePhoto')}>
//           <span>
//             <IconButton
//               sx={{
//                 position: 'absolute',
//                 right: '40%',
//                 bottom: '10%',
//                 transform: 'translateX(50%) translateY(50%)',
//                 backgroundColor: 'white',
//                 color: 'primary',
//                 '&:hover': {
//                   backgroundColor: '#f0f0f0',
//                 },
//                 boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
//               }}
//               onClick={triggerFileInput}
//             >
//               {loadingImage ? (
//                 <CircularProgress size={24} color="inherit" />
//               ) : (
//                 <EditIcon />
//               )}
//             </IconButton>
//           </span>
//         </Tooltip>
//       </Box>
//       <Typography variant="h6" component="h2" align="center">
//         {profile.displayName}
//       </Typography>
//       <UserRatings userId={auth.currentUser.uid} />
//       <TextField
//         fullWidth
//         label={t('userProfileDetails.name')}
//         name="displayName"
//         value={profile.displayName}
//         onChange={handleInputChange}
//         margin="normal"
//         variant="outlined"
//       />
//       <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
//         <Button
//           variant="contained"
//           onClick={handleSubmit}
//           disabled={loadingProfile}
//           fullWidth
//           sx={{
//             borderRadius: '20px',
//             maxWidth: '100%',
//             width: '100%',
//           }}
//         >
//           {loadingProfile ? (
//             <CircularProgress size={24} color="inherit" />
//           ) : (
//             t('userProfileDetails.saveChanges')
//           )}
//         </Button>
//       </Box>

//       <Snackbar
//         open={snackbar.open}
//         autoHideDuration={6000}
//         onClose={handleCloseSnackbar}
//       >
//         <Alert
//           onClose={handleCloseSnackbar}
//           severity={snackbar.severity}
//           sx={{ width: '100%' }}
//         >
//           {snackbar.message}
//         </Alert>
//       </Snackbar>
//     </Box>
//   );
// }

// export default MyProfile;

import React, { useState, useEffect, useRef } from 'react';
import {
  TextField,
  Button,
  Box,
  Typography,
  CircularProgress,
  Snackbar,
  Alert,
  Avatar,
  Tooltip,
  IconButton,
} from '@mui/material';
import {
  getAuth,
  updateProfile,
  updateEmail,
  onAuthStateChanged,
} from 'firebase/auth';
import { Edit as EditIcon } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, doc, updateDoc } from './firebaseConfig';
import { setCurrentUser } from './Redux/reducers/authReducer';
import { useTranslation } from 'react-i18next';
import UserRatings from './UserRating';

function MyProfile() {
  const auth = getAuth();
  const fileInputRef = useRef(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [profile, setProfile] = useState({
    displayName: '',
    email: '',
    photoURL: '',
  });

  const [loadingImage, setLoadingImage] = useState(false);
  const [loadingProfile, setLoadingProfile] = useState(true);
  const [previewImage, setPreviewImage] = useState('');
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info',
  });

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setProfile({
          displayName: currentUser.displayName || '',
          email: currentUser.email || '',
          photoURL: currentUser.photoURL || '',
        });
        setPreviewImage(currentUser.photoURL || '');
      }
      setLoadingProfile(false);
    });
    return () => unsubscribe();
  }, [auth]);

  const handleInputChange = (event) => {
    setProfile({ ...profile, [event.target.name]: event.target.value });
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(file);

    setLoadingImage(true);
    const downloadURL = await uploadImage(file);
    setLoadingImage(false);

    if (downloadURL) {
      await updateProfileImage(downloadURL);
      setProfile((prev) => ({ ...prev, photoURL: downloadURL }));
      showSnackbar(t('userProfileDetails.profileImageUpdated'), 'success');
    }
  };

  const updateProfileImage = async (imageUrl) => {
    try {
      await updateProfile(auth.currentUser, { photoURL: imageUrl });
      await auth.currentUser.reload();
      dispatch(setCurrentUser({ ...auth.currentUser, photoURL: imageUrl }));
      await updateFirestoreProfile({ photoURL: imageUrl });
    } catch (error) {
      console.error('Error updating profile image:', error);
      showSnackbar(t('userProfileDetails.failedToUpdateProfileImage'), 'error');
    }
  };

  const uploadImage = async (file) => {
    const storage = getStorage();
    const storageRef = ref(storage, `profileImages/${file.name}`);

    try {
      const snapshot = await uploadBytes(storageRef, file);
      return await getDownloadURL(snapshot.ref);
    } catch (error) {
      console.error('Failed to upload image:', error);
      showSnackbar(t('userProfileDetails.failedToUploadImage'), 'error');
      return null;
    }
  };

  const isUserAuthenticated = () => {
    const user = auth.currentUser;
    return user && user.uid;
  };

  const handleSubmit = async () => {
    setLoadingProfile(true);
    try {
      const newProfile = {
        ...profile,
        displayName: profile.displayName,
        photoURL: profile.photoURL,
      };
      if (!isUserAuthenticated()) {
        console.error('User is not authenticated or currentUser is not valid.');
        showSnackbar(
          'User is not authenticated or currentUser is not valid.',
          'error'
        );
        setLoadingProfile(false);
        return;
      }
      const user = auth.currentUser;
      if (user) {
        if (user.displayName !== newProfile.displayName) {
          await updateProfile(user, { displayName: newProfile.displayName });
        }
        if (user.photoURL !== newProfile.photoURL) {
          await updateProfile(user, { photoURL: newProfile.photoURL });
        }
        await auth.currentUser.reload();
        dispatch(
          setCurrentUser({
            ...auth.currentUser,
            displayName: newProfile.displayName,
            photoURL: newProfile.photoURL,
          })
        );
      }
      if (auth.currentUser.email !== newProfile.email) {
        await handleUpdateEmail(newProfile.email);
      }
      await updateFirestoreProfile({
        displayName: newProfile.displayName,
        photoURL: newProfile.photoURL,
      });
      showSnackbar(t('userProfileDetails.profileUpdated'), 'success');
    } catch (error) {
      console.error('Failed to update profile:', error);
      showSnackbar(t('userProfileDetails.failedToUpdateProfile'), 'error');
    }
    setLoadingProfile(false);
  };

  const handleUpdateEmail = async (newEmail) => {
    try {
      await updateEmail(auth.currentUser, newEmail);
      await updateFirestoreProfile({ email: newEmail });
    } catch (error) {
      console.error('Failed to update email:', error);
      showSnackbar(t('userProfileDetails.failedToUpdateEmail'), 'error');
    }
  };

  const updateFirestoreProfile = async (updateData) => {
    try {
      const userRef = doc(db, 'users', auth.currentUser.uid);
      await updateDoc(userRef, updateData);
      console.log('User profile updated in Firestore successfully!');
    } catch (error) {
      console.error('Error updating user profile in Firestore:', error);
    }
  };

  const showSnackbar = (message, severity) => {
    setSnackbar({ open: true, message, severity });
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ open: false, message: '', severity: 'info' });
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  if (loadingProfile) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ maxWidth: 480, mx: 'auto', my: 4 }}>
      <Typography variant="h5" component="h2" align="center">
        {profile.email}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          position: 'relative',
          mt: 2,
          mb: 2,
        }}
      >
        <input
          type="file"
          onChange={handleFileChange}
          style={{ display: 'none' }}
          ref={fileInputRef}
        />
        <Avatar src={previewImage} sx={{ width: 140, height: 140 }} />
        <Tooltip title={t('userProfileDetails.uploadAndUpdatePhoto')}>
          <span>
            <IconButton
              sx={{
                position: 'absolute',
                right: '40%',
                bottom: '10%',
                transform: 'translateX(50%) translateY(50%)',
                backgroundColor: 'white',
                color: 'primary',
                '&:hover': {
                  backgroundColor: '#f0f0f0',
                },
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
              }}
              onClick={triggerFileInput}
            >
              {loadingImage ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <EditIcon />
              )}
            </IconButton>
          </span>
        </Tooltip>
      </Box>
      <Typography variant="h6" component="h2" align="center">
        {profile.displayName}
      </Typography>
      <UserRatings userId={auth.currentUser.uid} />
      <TextField
        fullWidth
        label={t('userProfileDetails.name')}
        name="displayName"
        value={profile.displayName}
        onChange={handleInputChange}
        margin="normal"
        variant="outlined"
      />
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={loadingProfile}
          fullWidth
          sx={{
            borderRadius: '20px',
            maxWidth: '100%',
            width: '100%',
          }}
        >
          {loadingProfile ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            t('userProfileDetails.saveChanges')
          )}
        </Button>
      </Box>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default MyProfile;
