import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Avatar,
  Stack,
  IconButton,
  useMediaQuery,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Chip,
  Menu,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EventIcon from '@mui/icons-material/Event';
import PhoneIcon from '@mui/icons-material/Phone';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { formatState } from '../Utils/ProductState';

export default function StoreChatHeader({
  isOwner,
  imageUrl,
  title,
  onPhoneCallClick,
  appointments,
  onImageClick,
}) {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();
  const [shortTitle, setShortTitle] = useState(title);
  const [anchorEl, setAnchorEl] = useState(null);

  const MOBILE_MAX_TITLE_LENGTH = 3; // 모바일에서의 최대 제목 길이
  const DESKTOP_MAX_TITLE_LENGTH = 40; // 데스크톱에서의 최대 제목 길이

  useEffect(() => {
    const maxTitleLength = isMobile
      ? MOBILE_MAX_TITLE_LENGTH
      : DESKTOP_MAX_TITLE_LENGTH;
    if (title) {
      if (title.length > maxTitleLength) {
        setShortTitle(title.substring(0, maxTitleLength) + '...');
      } else {
        setShortTitle(title);
      }
    }
  }, [title, isMobile]);

  return (
    <Box
      sx={{
        padding: '1px',
        borderBottom: '1px solid #e0e0e0',
        backgroundColor: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
        <Avatar
          src={imageUrl}
          alt={title}
          onClick={onImageClick}
          sx={{
            width: 40,
            height: 40,
            cursor: 'pointer',
            mx: 2,
          }}
        />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flex: 1,
            minWidth: 0, // Ensures the box shrinks if needed
          }}
        >
          <Typography
            variant="h6"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              flex: 1,
              minWidth: 0, // Ensures the text ellipsis works
              marginRight: 2,
            }}
          >
            {shortTitle}
          </Typography>
          {!isMobile && appointments && (
            <Typography variant="body1" color="primary" sx={{ flexShrink: 0 }}>
              {t('appointmentTime', { time: appointments })}
            </Typography>
          )}
        </Box>
      </Box>
      <Stack direction="row" spacing={2} alignItems="center">
        <IconButton onClick={onPhoneCallClick}>
          <PhoneIcon />
        </IconButton>
      </Stack>
    </Box>
  );
}
