// import React, { useState } from 'react';
// import {
//   Box,
//   Button,
//   Typography,
//   IconButton,
//   Tooltip,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogContentText,
//   DialogTitle,
//   TextField,
//   Rating,
// } from '@mui/material';
// import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
// import { db } from '../firebaseConfig';
// import {
//   collection,
//   doc,
//   setDoc,
//   getDoc,
//   updateDoc,
//   serverTimestamp,
// } from 'firebase/firestore';

// const weights = {
//   productQuality: 0.25,
//   customerService: 0.25,
//   deliverySpeed: 0.25,
//   overallExperience: 0.25,
// };

// const StoreRatingForm = ({ open, handleClose, ratedStoreId, raterUserId }) => {
//   const [productQuality, setProductQuality] = useState(0);
//   const [customerService, setCustomerService] = useState(0);
//   const [deliverySpeed, setDeliverySpeed] = useState(0);
//   const [overallExperience, setOverallExperience] = useState(0);
//   const [comment, setComment] = useState('');
//   const [openDialog, setOpenDialog] = useState(null);

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const overallScore = (
//       productQuality * weights.productQuality +
//       customerService * weights.customerService +
//       deliverySpeed * weights.deliverySpeed +
//       overallExperience * weights.overallExperience
//     ).toFixed(2);

//     const ratingRef = doc(db, `stores/${ratedStoreId}/ratings/${raterUserId}`);
//     const ratingSnapshot = await getDoc(ratingRef);

//     try {
//       if (ratingSnapshot.exists()) {
//         await updateDoc(ratingRef, {
//           productQuality,
//           customerService,
//           deliverySpeed,
//           overallExperience,
//           overallScore,
//           comment,
//           timestamp: serverTimestamp(),
//         });
//       } else {
//         await setDoc(ratingRef, {
//           ratedStoreId,
//           raterUserId,
//           productQuality,
//           customerService,
//           deliverySpeed,
//           overallExperience,
//           overallScore,
//           comment,
//           timestamp: serverTimestamp(),
//         });
//       }
//       setProductQuality(0);
//       setCustomerService(0);
//       setDeliverySpeed(0);
//       setOverallExperience(0);
//       setComment('');
//     } catch (error) {
//       console.error('Error submitting rating:', error);
//     }
//   };

//   const handleOpenDialog = (type) => {
//     setOpenDialog(type);
//   };

//   const handleCloseDialog = () => {
//     setOpenDialog(null);
//   };

//   return (
//     <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
//       {[
//         'productQuality',
//         'customerService',
//         'deliverySpeed',
//         'overallExperience',
//       ].map((type) => (
//         <Box key={type} sx={{ mb: 2 }}>
//           <Box sx={{ display: 'flex', alignItems: 'center' }}>
//             <Typography variant="body1" sx={{ mr: 1 }}>
//               {type.charAt(0).toUpperCase() + type.slice(1)}:
//             </Typography>
//             <Tooltip title="Click for more info">
//               <IconButton onClick={() => handleOpenDialog(type)} size="small">
//                 <InfoOutlinedIcon fontSize="small" />
//               </IconButton>
//             </Tooltip>
//           </Box>
//           <Rating
//             name={type}
//             value={eval(type)}
//             onChange={(e, newValue) =>
//               eval(`set${type.charAt(0).toUpperCase() + type.slice(1)}`)(
//                 newValue
//               )
//             }
//             precision={0.5}
//           />
//         </Box>
//       ))}
//       <TextField
//         label="Comment"
//         value={comment}
//         onChange={(e) => setComment(e.target.value)}
//         multiline
//         rows={4}
//         fullWidth
//         required
//       />
//       <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
//         Submit Rating
//       </Button>

//       <Dialog open={openDialog !== null} onClose={handleCloseDialog}>
//         <DialogTitle>
//           {openDialog &&
//             openDialog.charAt(0).toUpperCase() + openDialog.slice(1)}
//         </DialogTitle>
//         <DialogContent>
//           <DialogContentText>
//             {openDialog === 'productQuality' &&
//               '평가 대상의 상품 품질을 평가합니다.'}
//             {openDialog === 'customerService' &&
//               '고객 서비스의 품질을 평가합니다.'}
//             {openDialog === 'deliverySpeed' && '배송 속도를 평가합니다.'}
//             {openDialog === 'overallExperience' &&
//               '전체 거래 경험에 대한 전반적인 만족도를 평가합니다.'}
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleCloseDialog} color="primary">
//             Close
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </Box>
//   );
// };

// export default StoreRatingForm;

// import React, { useState } from 'react';
// import {
//   Box,
//   Button,
//   Typography,
//   IconButton,
//   Tooltip,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogContentText,
//   DialogTitle,
//   TextField,
//   Rating,
// } from '@mui/material';
// import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
// import { db } from '../firebaseConfig';
// import {
//   collection,
//   doc,
//   setDoc,
//   getDoc,
//   updateDoc,
//   serverTimestamp,
// } from 'firebase/firestore';

// const weights = {
//   productQuality: 0.25,
//   customerService: 0.25,
//   deliverySpeed: 0.25,
//   overallExperience: 0.25,
// };

// const StoreRatingForm = ({ open, handleClose, ratedStoreId, raterUserId }) => {
//   const [productQuality, setProductQuality] = useState(0);
//   const [customerService, setCustomerService] = useState(0);
//   const [deliverySpeed, setDeliverySpeed] = useState(0);
//   const [overallExperience, setOverallExperience] = useState(0);
//   const [comment, setComment] = useState('');
//   const [openDialog, setOpenDialog] = useState(null);

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const overallScore = (
//       productQuality * weights.productQuality +
//       customerService * weights.customerService +
//       deliverySpeed * weights.deliverySpeed +
//       overallExperience * weights.overallExperience
//     ).toFixed(2);

//     const ratingRef = doc(db, `stores/${ratedStoreId}/ratings/${raterUserId}`);
//     const ratingSnapshot = await getDoc(ratingRef);

//     try {
//       if (ratingSnapshot.exists()) {
//         await updateDoc(ratingRef, {
//           productQuality,
//           customerService,
//           deliverySpeed,
//           overallExperience,
//           overallScore,
//           comment,
//           timestamp: serverTimestamp(),
//         });
//       } else {
//         await setDoc(ratingRef, {
//           ratedStoreId,
//           raterUserId,
//           productQuality,
//           customerService,
//           deliverySpeed,
//           overallExperience,
//           overallScore,
//           comment,
//           timestamp: serverTimestamp(),
//         });
//       }
//       setProductQuality(0);
//       setCustomerService(0);
//       setDeliverySpeed(0);
//       setOverallExperience(0);
//       setComment('');
//       handleClose();
//     } catch (error) {
//       console.error('Error submitting rating:', error);
//     }
//   };

//   const handleOpenDialog = (type) => {
//     setOpenDialog(type);
//   };

//   const handleCloseDialog = () => {
//     setOpenDialog(null);
//   };

//   return (
//     <Dialog open={open} onClose={handleClose}>
//       <DialogTitle>Rate the Store</DialogTitle>
//       <DialogContent>
//         <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
//           {[
//             'productQuality',
//             'customerService',
//             'deliverySpeed',
//             'overallExperience',
//           ].map((type) => (
//             <Box key={type} sx={{ mb: 2 }}>
//               <Box sx={{ display: 'flex', alignItems: 'center' }}>
//                 <Typography variant="body1" sx={{ mr: 1 }}>
//                   {type.charAt(0).toUpperCase() + type.slice(1)}:
//                 </Typography>
//                 <Tooltip title="Click for more info">
//                   <IconButton
//                     onClick={() => handleOpenDialog(type)}
//                     size="small"
//                   >
//                     <InfoOutlinedIcon fontSize="small" />
//                   </IconButton>
//                 </Tooltip>
//               </Box>
//               <Rating
//                 name={type}
//                 value={eval(type)}
//                 onChange={(e, newValue) =>
//                   eval(`set${type.charAt(0).toUpperCase() + type.slice(1)}`)(
//                     newValue
//                   )
//                 }
//                 precision={0.5}
//               />
//             </Box>
//           ))}
//           <TextField
//             label="Comment"
//             value={comment}
//             onChange={(e) => setComment(e.target.value)}
//             multiline
//             rows={4}
//             fullWidth
//             required
//           />
//           <Button
//             type="submit"
//             variant="contained"
//             color="primary"
//             sx={{ mt: 2 }}
//           >
//             Submit Rating
//           </Button>
//         </Box>

//         <Dialog open={openDialog !== null} onClose={handleCloseDialog}>
//           <DialogTitle>
//             {openDialog &&
//               openDialog.charAt(0).toUpperCase() + openDialog.slice(1)}
//           </DialogTitle>
//           <DialogContent>
//             <DialogContentText>
//               {openDialog === 'productQuality' &&
//                 'Evaluate the quality of the products offered by the store.'}
//               {openDialog === 'customerService' &&
//                 'Evaluate the quality of the customer service provided by the store.'}
//               {openDialog === 'deliverySpeed' &&
//                 'Evaluate the speed of the delivery service provided by the store.'}
//               {openDialog === 'overallExperience' &&
//                 'Evaluate your overall experience with the store.'}
//             </DialogContentText>
//           </DialogContent>
//           <DialogActions>
//             <Button onClick={handleCloseDialog} color="primary">
//               Close
//             </Button>
//           </DialogActions>
//         </Dialog>
//       </DialogContent>
//     </Dialog>
//   );
// };

// export default StoreRatingForm;

// import React, { useState, useEffect } from 'react';
// import {
//   Box,
//   Button,
//   Typography,
//   IconButton,
//   Tooltip,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogContentText,
//   DialogTitle,
//   TextField,
//   Rating,
// } from '@mui/material';
// import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
// import { db } from '../firebaseConfig';
// import {
//   doc,
//   setDoc,
//   getDoc,
//   updateDoc,
//   serverTimestamp,
// } from 'firebase/firestore';

// const weights = {
//   quality: 0.2,
//   customerService: 0.2,
//   valueForMoney: 0.2,
//   timeliness: 0.2,
//   overallSatisfaction: 0.2,
// };

// const StoreRatingForm = ({ open, handleClose, ratedStoreId, raterUserId }) => {
//   const [quality, setQuality] = useState(0);
//   const [customerService, setCustomerService] = useState(0);
//   const [valueForMoney, setValueForMoney] = useState(0);
//   const [timeliness, setTimeliness] = useState(0);
//   const [overallSatisfaction, setOverallSatisfaction] = useState(0);
//   const [comment, setComment] = useState('');
//   const [openDialog, setOpenDialog] = useState(null);

//   useEffect(() => {
//     const fetchRating = async () => {
//       const ratingRef = doc(
//         db,
//         `stores/${ratedStoreId}/ratings/${raterUserId}`
//       );
//       const ratingSnapshot = await getDoc(ratingRef);

//       if (ratingSnapshot.exists()) {
//         const ratingData = ratingSnapshot.data();
//         setQuality(ratingData.quality);
//         setCustomerService(ratingData.customerService);
//         setValueForMoney(ratingData.valueForMoney);
//         setTimeliness(ratingData.timeliness);
//         setOverallSatisfaction(ratingData.overallSatisfaction);
//         setComment(ratingData.comment);
//       }
//     };

//     if (open) {
//       fetchRating();
//     }
//   }, [open, ratedStoreId, raterUserId]);

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const overallScore = (
//       quality * weights.quality +
//       customerService * weights.customerService +
//       valueForMoney * weights.valueForMoney +
//       timeliness * weights.timeliness +
//       overallSatisfaction * weights.overallSatisfaction
//     ).toFixed(2);

//     const ratingRef = doc(db, `stores/${ratedStoreId}/ratings/${raterUserId}`);
//     const ratingSnapshot = await getDoc(ratingRef);

//     try {
//       if (ratingSnapshot.exists()) {
//         await updateDoc(ratingRef, {
//           quality,
//           customerService,
//           valueForMoney,
//           timeliness,
//           overallSatisfaction,
//           overallScore,
//           comment,
//           timestamp: serverTimestamp(),
//         });
//       } else {
//         await setDoc(ratingRef, {
//           ratedStoreId,
//           raterUserId,
//           quality,
//           customerService,
//           valueForMoney,
//           timeliness,
//           overallSatisfaction,
//           overallScore,
//           comment,
//           timestamp: serverTimestamp(),
//         });
//       }
//       handleClose();
//     } catch (error) {
//       console.error('Error submitting rating:', error);
//     }
//   };

//   const handleOpenDialog = (type) => {
//     setOpenDialog(type);
//   };

//   const handleCloseDialog = () => {
//     setOpenDialog(null);
//   };

//   return (
//     <Dialog open={open} onClose={handleClose}>
//       <DialogTitle>Rate this Store</DialogTitle>
//       <DialogContent>
//         <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
//           {[
//             'quality',
//             'customerService',
//             'valueForMoney',
//             'timeliness',
//             'overallSatisfaction',
//           ].map((type) => (
//             <Box key={type} sx={{ mb: 2 }}>
//               <Box sx={{ display: 'flex', alignItems: 'center' }}>
//                 <Typography variant="body1" sx={{ mr: 1 }}>
//                   {type.charAt(0).toUpperCase() + type.slice(1)}:
//                 </Typography>
//                 <Tooltip title="Click for more info">
//                   <IconButton
//                     onClick={() => handleOpenDialog(type)}
//                     size="small"
//                   >
//                     <InfoOutlinedIcon fontSize="small" />
//                   </IconButton>
//                 </Tooltip>
//               </Box>
//               <Rating
//                 name={type}
//                 value={eval(type)}
//                 onChange={(e, newValue) =>
//                   eval(`set${type.charAt(0).toUpperCase() + type.slice(1)}`)(
//                     newValue
//                   )
//                 }
//                 precision={0.5}
//               />
//             </Box>
//           ))}
//           <TextField
//             label="Comment"
//             value={comment}
//             onChange={(e) => setComment(e.target.value)}
//             multiline
//             rows={4}
//             fullWidth
//             required
//           />
//           <DialogActions>
//             <Button onClick={handleClose} color="primary">
//               Cancel
//             </Button>
//             <Button type="submit" variant="contained" color="primary">
//               Submit Rating
//             </Button>
//           </DialogActions>
//         </Box>
//       </DialogContent>

//       <Dialog open={openDialog !== null} onClose={handleCloseDialog}>
//         <DialogTitle>
//           {openDialog &&
//             openDialog.charAt(0).toUpperCase() + openDialog.slice(1)}
//         </DialogTitle>
//         <DialogContent>
//           <DialogContentText>
//             {openDialog === 'quality' && '평가 대상의 상품 품질을 평가합니다.'}
//             {openDialog === 'customerService' &&
//               '고객 서비스 수준을 평가합니다.'}
//             {openDialog === 'valueForMoney' && '가격 대비 가치를 평가합니다.'}
//             {openDialog === 'timeliness' && '서비스의 신속성을 평가합니다.'}
//             {openDialog === 'overallSatisfaction' &&
//               '전반적인 만족도를 평가합니다.'}
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleCloseDialog} color="primary">
//             Close
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </Dialog>
//   );
// };

// export default StoreRatingForm;

import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  IconButton,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Rating,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { db } from '../firebaseConfig';
import {
  doc,
  setDoc,
  getDoc,
  updateDoc,
  serverTimestamp,
} from 'firebase/firestore';
import { useTranslation } from 'react-i18next';

const weights = {
  quality: 0.2,
  customerService: 0.2,
  valueForMoney: 0.2,
  timeliness: 0.2,
  overallSatisfaction: 0.2,
};

const StoreRatingForm = ({ open, handleClose, ratedStoreId, raterUserId }) => {
  const { t } = useTranslation();
  const [quality, setQuality] = useState(0);
  const [customerService, setCustomerService] = useState(0);
  const [valueForMoney, setValueForMoney] = useState(0);
  const [timeliness, setTimeliness] = useState(0);
  const [overallSatisfaction, setOverallSatisfaction] = useState(0);
  const [comment, setComment] = useState('');
  const [openDialog, setOpenDialog] = useState(null);

  useEffect(() => {
    const fetchRating = async () => {
      const ratingRef = doc(
        db,
        `stores/${ratedStoreId}/ratings/${raterUserId}`
      );
      const ratingSnapshot = await getDoc(ratingRef);

      if (ratingSnapshot.exists()) {
        const ratingData = ratingSnapshot.data();
        setQuality(ratingData.quality);
        setCustomerService(ratingData.customerService);
        setValueForMoney(ratingData.valueForMoney);
        setTimeliness(ratingData.timeliness);
        setOverallSatisfaction(ratingData.overallSatisfaction);
        setComment(ratingData.comment);
      }
    };

    if (open) {
      fetchRating();
    }
  }, [open, ratedStoreId, raterUserId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const overallScore =
      quality * weights.quality +
      customerService * weights.customerService +
      valueForMoney * weights.valueForMoney +
      timeliness * weights.timeliness +
      overallSatisfaction * weights.overallSatisfaction;

    const ratingRef = doc(db, `stores/${ratedStoreId}/ratings/${raterUserId}`);
    const ratingSnapshot = await getDoc(ratingRef);

    try {
      if (ratingSnapshot.exists()) {
        await updateDoc(ratingRef, {
          quality,
          customerService,
          valueForMoney,
          timeliness,
          overallSatisfaction,
          overallScore,
          comment,
          timestamp: serverTimestamp(),
        });
      } else {
        await setDoc(ratingRef, {
          ratedStoreId,
          raterUserId,
          quality,
          customerService,
          valueForMoney,
          timeliness,
          overallSatisfaction,
          overallScore,
          comment,
          timestamp: serverTimestamp(),
        });
      }
      handleClose();
    } catch (error) {
      console.error('Error submitting rating:', error);
    }
  };

  const handleOpenDialog = (type) => {
    setOpenDialog(type);
  };

  const handleCloseDialog = () => {
    setOpenDialog(null);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t('storeRatingForm.rateThisStore')}</DialogTitle>
      <DialogContent>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
          {[
            'quality',
            'customerService',
            'valueForMoney',
            'timeliness',
            'overallSatisfaction',
          ].map((type) => (
            <Box key={type} sx={{ mb: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1" sx={{ mr: 1 }}>
                  {t(`storeRatingForm.${type}`)}:
                </Typography>
                <Tooltip title={t(`storeRatingForm.${type}Info`)}>
                  <IconButton
                    onClick={() => handleOpenDialog(type)}
                    size="small"
                  >
                    <InfoOutlinedIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Box>
              <Rating
                name={type}
                value={eval(type)}
                onChange={(e, newValue) =>
                  eval(`set${type.charAt(0).toUpperCase() + type.slice(1)}`)(
                    newValue
                  )
                }
                precision={0.5}
              />
            </Box>
          ))}
          <TextField
            label={t('storeRatingForm.comment')}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            multiline
            rows={4}
            fullWidth
            required
          />
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              {t('storeRatingForm.cancel')}
            </Button>
            <Button type="submit" variant="contained" color="primary">
              {t('storeRatingForm.submitRating')}
            </Button>
          </DialogActions>
        </Box>
      </DialogContent>

      <Dialog open={openDialog !== null} onClose={handleCloseDialog}>
        <DialogTitle>
          {openDialog && t(`storeRatingForm.${openDialog}`)}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {openDialog && t(`storeRatingForm.${openDialog}Info`)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            {t('storeRatingForm.close')}
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

export default StoreRatingForm;
