// import { db } from '../firebaseConfig';
// import {
//   doc,
//   getDoc,
//   collection,
//   query,
//   where,
//   getDocs,
//   limit,
//   startAfter,
//   orderBy,
// } from 'firebase/firestore';
// import { haversineDistance } from './Haversine';

// // 사용자 제품을 페이지 단위로 가져오는 함수 (Map 형태로 반환)
// export const exportFetchUserProductsByLastDocAsMap = async (
//   pageSize,
//   lastDoc = null,
//   latitude,
//   longitude,
//   radius,
//   currentUserId
// ) => {
//   try {
//     // 위치 기반 사용자 필터링
//     const usersSnapshot = await exportFetchUsersByCoordinatesAsArray(
//       latitude,
//       longitude,
//       radius,
//       currentUserId
//     );
//     if (!usersSnapshot.length) {
//       return { products: {}, lastDoc: null, totalCount: 0 };
//     }

//     const userIds = usersSnapshot.map((user) => user.id);
//     let productsQuery;

//     if (lastDoc) {
//       productsQuery = query(
//         collection(db, 'products'),
//         where('uid', 'in', userIds),
//         startAfter(lastDoc),
//         limit(pageSize)
//       );
//     } else {
//       productsQuery = query(
//         collection(db, 'products'),
//         where('uid', 'in', userIds),
//         limit(pageSize)
//       );
//     }

//     const productsSnapshot = await getDocs(productsQuery);
//     const products = productsSnapshot.docs.map((doc) => ({
//       id: doc.id,
//       ...doc.data(),
//     }));

//     const totalProductsQuery = query(
//       collection(db, 'products'),
//       where('uid', 'in', userIds)
//     );
//     const totalProductsSnapshot = await getDocs(totalProductsQuery);

//     return {
//       products,
//       lastDoc: productsSnapshot.docs[productsSnapshot.docs.length - 1] || null,
//       totalCount: totalProductsSnapshot.size,
//     };
//   } catch (error) {
//     console.error('Error fetching all user products:', error);
//     return {
//       products: {},
//       lastDoc: null,
//       totalCount: 0,
//     };
//   }
// };

// // 가게 제품을 페이지 단위로 가져오는 함수 (Map 형태로 반환)
// export const exportFetchStoresByLastDocAsMap = async (
//   pageSize,
//   lastDoc = null,
//   latitude,
//   longitude,
//   radius,
//   currentUserId
// ) => {
//   try {
//     // 위치 기반 가게 필터링
//     const storesSnapshot = await exportFetchStoresByCoordinatesAsMap(
//       latitude,
//       longitude,
//       radius,
//       currentUserId
//     );
//     if (!storesSnapshot.stores.length) {
//       return { products: {}, lastDoc: null, totalCount: 0 };
//     }

//     const storeIds = storesSnapshot.stores.map((store) => store.id);
//     let storeProducts = [];
//     let lastProductDoc = null;

//     for (const storeId of storeIds) {
//       let storeProductsQuery;

//       if (lastDoc) {
//         storeProductsQuery = query(
//           collection(db, 'stores', storeId, 'products'),
//           startAfter(lastDoc),
//           limit(pageSize)
//         );
//       } else {
//         storeProductsQuery = query(
//           collection(db, 'stores', storeId, 'products'),
//           limit(pageSize)
//         );
//       }

//       const storeProductsSnapshot = await getDocs(storeProductsQuery);
//       storeProductsSnapshot.docs.forEach((doc) => {
//         storeProducts.push({
//           id: doc.id,
//           ...doc.data(),
//         });
//       });

//       if (storeProductsSnapshot.docs.length > 0) {
//         lastProductDoc =
//           storeProductsSnapshot.docs[storeProductsSnapshot.docs.length - 1];
//       }
//     }

//     const totalStoreProductsSnapshot = await Promise.all(
//       storeIds.map((storeId) =>
//         getDocs(collection(db, 'stores', storeId, 'products'))
//       )
//     );

//     const totalCount = totalStoreProductsSnapshot.reduce(
//       (acc, snapshot) => acc + snapshot.size,
//       0
//     );

//     return {
//       products: storeProducts,
//       lastDoc: lastProductDoc,
//       totalCount,
//     };
//   } catch (error) {
//     console.error('Error fetching all store products:', error);
//     return {
//       products: {},
//       lastDoc: null,
//       totalCount: 0,
//     };
//   }
// };

// // 페이지별 가게를 Map 형태로 반환
// export const exportFetchStoresPerPageAsMap = async (
//   pageSize,
//   page,
//   latitude,
//   longitude,
//   radius,
//   currentUserId
// ) => {
//   try {
//     let storesQuery;
//     let lastDoc = null;

//     if (page > 0) {
//       const previousPageDocCount = pageSize * page;
//       const previousQuery = query(
//         collection(db, 'stores'),
//         orderBy('name'),
//         limit(previousPageDocCount)
//       );
//       const previousSnapshot = await getDocs(previousQuery);
//       lastDoc = previousSnapshot.docs[previousSnapshot.docs.length - 1];

//       storesQuery = query(
//         collection(db, 'stores'),
//         orderBy('name'),
//         startAfter(lastDoc),
//         limit(pageSize)
//       );
//     } else {
//       storesQuery = query(
//         collection(db, 'stores'),
//         orderBy('name'),
//         limit(pageSize)
//       );
//     }

//     const storesSnapshot = await getDocs(storesQuery);
//     const stores = storesSnapshot.docs.map((doc) => ({
//       id: doc.id,
//       ...doc.data(),
//     }));

//     const filteredStores = stores.filter((store) => {
//       const { storePosition } = store;
//       if (storePosition && storePosition.lat && storePosition.lng) {
//         const distance = haversineDistance(
//           { latitude, longitude },
//           {
//             latitude: storePosition.lat,
//             longitude: storePosition.lng,
//           }
//         );
//         return distance <= radius && store.id !== currentUserId;
//       }
//       return false;
//     });

//     const totalCount = filteredStores.length;

//     return { stores: filteredStores, totalCount, lastDoc };
//   } catch (error) {
//     console.error('Error fetching stores:', error);
//     return { stores: [], totalCount: 0, lastDoc: null };
//   }
// };

// // 좌표에 따른 사용자 제품을 배열 형태로 반환
// export const exportFetchUserProductsByCoordinatesAsArray = async (
//   latitude,
//   longitude,
//   radius,
//   currentUserId
// ) => {
//   try {
//     // Step 1: Fetch users near the given location
//     const users = await exportFetchUsersByCoordinatesAsArray(
//       latitude,
//       longitude,
//       radius,
//       currentUserId
//     );
//     if (!users.length) {
//       return [];
//     }

//     // Step 2: Extract user IDs
//     const userIds = users.map((user) => user.id);

//     // Step 3: Fetch products from those users
//     const products = await exportFetchUserProductsFromUsersAsArray(userIds);

//     // Step 4: Sort products by likes and then by creation date
//     const sortedProducts = products.sort((a, b) => {
//       return b.createDate.seconds - a.createDate.seconds;
//     });

//     // Ensure the result is always an array
//     return Array.isArray(sortedProducts) ? sortedProducts : [];
//   } catch (error) {
//     console.error('Error fetching products by coordinates:', error);
//     return [];
//   }
// };

// // 좌표에 따른 사용자 배열을 반환
// const exportFetchUsersByCoordinatesAsArray = async (
//   latitude,
//   longitude,
//   radius,
//   currentUserId
// ) => {
//   try {
//     const usersSnapshot = await getDocs(collection(db, 'users'));
//     const users = usersSnapshot.docs
//       .map((doc) => {
//         const userData = doc.data();
//         const { selectedTown, town1, town2 } = userData;
//         let userLocation = null;

//         if (selectedTown === 1 && town1) {
//           userLocation = { latitude: town1.lat, longitude: town1.lng };
//         } else if (selectedTown === 2 && town2) {
//           userLocation = { latitude: town2.lat, longitude: town2.lng };
//         }

//         if (userLocation) {
//           const distance = haversineDistance(
//             { latitude, longitude },
//             userLocation
//           );
//           return {
//             id: doc.id,
//             ...userData,
//             distance,
//           };
//         }
//         return null;
//       })
//       .filter(
//         (user) =>
//           user !== null && user.distance <= radius && user.id !== currentUserId
//       )
//       .sort((a, b) => a.distance - b.distance);

//     return users;
//   } catch (error) {
//     console.error('Error fetching users:', error);
//     return [];
//   }
// };

// // 사용자로부터 제품 배열을 반환
// const exportFetchUserProductsFromUsersAsArray = async (userIds) => {
//   try {
//     const q = query(collection(db, 'products'), where('uid', 'in', userIds));
//     const productsSnapshot = await getDocs(q);

//     const products = productsSnapshot.docs.map((doc) => ({
//       id: doc.id,
//       ...doc.data(),
//     }));

//     return products;
//   } catch (error) {
//     console.error('Error fetching products:', error);
//     return [];
//   }
// };

// // 사용자 ID로 선택된 마을을 반환
// export const exportFetchSelectedTownByUserId = async (userId) => {
//   try {
//     const userRef = doc(db, 'users', userId);
//     const userDoc = await getDoc(userRef);
//     if (userDoc.exists()) {
//       const userData = userDoc.data();
//       const { selectedTown, town1, town2 } = userData;

//       if (selectedTown === 1 && town1) {
//         return { lat: town1.lat, lng: town1.lng, radius: town1.radius };
//       } else if (selectedTown === 2 && town2) {
//         return { lat: town2.lat, lng: town2.lng, radius: town2.radius };
//       }
//     }
//     return null;
//   } catch (error) {
//     console.error('Error getting town location:', error);
//     return null;
//   }
// };

// // 좌표에 따른 모든 상점 제품을 배열로 반환
// export const exportFetchStoreAllProductsByCoordinatesAsArray = async (
//   latitude,
//   longitude,
//   radius,
//   currentUserId
// ) => {
//   try {
//     const stores = await exportFetchStoresByCoordinatesAsMap(
//       latitude,
//       longitude,
//       radius,
//       currentUserId
//     );
//     if (!stores.stores.length) {
//       return [];
//     }

//     const storeIds = stores.stores.map((store) => store.id);
//     const storeProducts = [];

//     for (const storeId of storeIds) {
//       const productsQuery = query(
//         collection(db, 'stores', storeId, 'products')
//       );
//       const productsSnapshot = await getDocs(productsQuery);
//       productsSnapshot.docs.forEach((doc) => {
//         storeProducts.push({
//           id: doc.id,
//           ...doc.data(),
//         });
//       });
//     }

//     return storeProducts;
//   } catch (error) {
//     console.error('Error fetching products:', error);
//     return [];
//   }
// };

// // 좌표에 따른 상점 제품을 배열로 반환
// export const exportFetchStoreProductsByCoordinatesAsMap = async (
//   latitude,
//   longitude,
//   radius,
//   currentUserId
// ) => {
//   try {
//     const { stores, totalCount, lastDoc } =
//       await exportFetchStoresByCoordinatesAsMap(
//         latitude,
//         longitude,
//         radius,
//         currentUserId
//       );

//     if (!stores.length) {
//       return { products: {}, lastDoc: null, totalCount: 0 };
//     }

//     const storeIds = stores.map((store) => store.id);
//     const promotionProducts = {};

//     for (const storeId of storeIds) {
//       const productsQuery = query(
//         collection(db, 'stores', storeId, 'products')
//       );

//       const productsSnapshot = await getDocs(productsQuery);
//       productsSnapshot.docs.forEach((doc) => {
//         promotionProducts[doc.id] = doc.data();
//       });
//     }

//     return {
//       products: promotionProducts,
//       lastDoc,
//       totalCount: Object.keys(promotionProducts).length,
//     };
//   } catch (error) {
//     console.error('Error fetching promotion products:', error);
//     return { products: {}, lastDoc: null, totalCount: 0 };
//   }
// };
// // 좌표에 따른 상점을 Map으로 반환
// export const exportFetchStoresByCoordinatesAsMap = async (
//   latitude,
//   longitude,
//   radius,
//   currentUserId,
//   lastDoc = null,
//   limitCount = 10
// ) => {
//   try {
//     let storesQuery = query(
//       collection(db, 'stores'),
//       orderBy('storePosition.lat'),
//       limit(limitCount)
//     );

//     if (lastDoc) {
//       storesQuery = query(
//         collection(db, 'stores'),
//         orderBy('storePosition.lat'),
//         startAfter(lastDoc),
//         limit(limitCount)
//       );
//     }

//     const storesSnapshot = await getDocs(storesQuery);
//     const stores = storesSnapshot.docs
//       .map((doc) => {
//         const storeData = doc.data();
//         if (
//           storeData.storePosition &&
//           storeData.storePosition.lat &&
//           storeData.storePosition.lng
//         ) {
//           const distance = haversineDistance(
//             { latitude, longitude },
//             {
//               latitude: storeData.storePosition.lat,
//               longitude: storeData.storePosition.lng,
//             }
//           );
//           return {
//             id: doc.id,
//             ...storeData,
//             distance,
//           };
//         }
//         return null;
//       })
//       .filter(
//         (store) =>
//           store !== null &&
//           store.distance <= radius &&
//           store.id !== currentUserId
//       )
//       .sort((a, b) => a.distance - b.distance);

//     const totalStoresSnapshot = await getDocs(collection(db, 'stores'));
//     const totalCount = totalStoresSnapshot.docs.filter((doc) => {
//       const storeData = doc.data();
//       if (
//         storeData.storePosition &&
//         storeData.storePosition.lat &&
//         storeData.storePosition.lng
//       ) {
//         const distance = haversineDistance(
//           { latitude, longitude },
//           {
//             latitude: storeData.storePosition.lat,
//             longitude: storeData.storePosition.lng,
//           }
//         );
//         return distance <= radius && doc.id !== currentUserId;
//       }
//       return false;
//     }).length;

//     return {
//       stores,
//       lastDoc: storesSnapshot.docs[storesSnapshot.docs.length - 1],
//       totalCount,
//     };
//   } catch (error) {
//     console.error('Error fetching stores:', error);
//     return { stores: [], lastDoc: null, totalCount: 0 };
//   }
// };

// export const exportFetchUserProductsWithCurrentPosition = async (radius) => {
//   if (navigator.geolocation) {
//     navigator.geolocation.getCurrentPosition(
//       async (position) => {
//         const latitude = position.coords.latitude;
//         const longitude = position.coords.longitude;
//         return exportFetchUserProductsByCoordinatesAsArray(
//           latitude,
//           longitude,
//           radius
//         );
//       },
//       (error) => {
//         console.error('Error getting location:', error);
//         return [];
//       }
//     );
//   } else {
//     console.error('Geolocation is not supported by this browser.');
//     return [];
//   }
// };

// export const exportFetchStoreProductsWithCurrentPosition = async (
//   radius,
//   currentUserId
// ) => {
//   if (navigator.geolocation) {
//     navigator.geolocation.getCurrentPosition(
//       async (position) => {
//         const latitude = position.coords.latitude;
//         const longitude = position.coords.longitude;
//         return exportFetchStoreProductsByCoordinatesAsMap(
//           latitude,
//           longitude,
//           radius,
//           currentUserId
//         );
//       },
//       (error) => {
//         console.error('Error getting location:', error);
//         return [];
//       }
//     );
//   } else {
//     console.error('Geolocation is not supported by this browser.');
//     return [];
//   }
// };

// export const exportFilterAndSortProducts = (
//   products,
//   sortBy = 'createDate',
//   filterFn = null
// ) => {
//   // 필터링 조건이 제공된 경우 필터링합니다.
//   let filteredProducts = products;
//   if (filterFn) {
//     filteredProducts = products.filter(filterFn);
//   }

//   // 정렬 기준에 따라 제품을 정렬합니다.
//   return filteredProducts.sort((a, b) => {
//     if (sortBy === 'createDate') {
//       return b.createDate.seconds - a.createDate.seconds;
//     } else if (sortBy === 'popularity') {
//       return b.likes - a.likes;
//     }
//     return 0;
//   });
// };

import { db } from '../firebaseConfig';
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  limit,
  startAfter,
  orderBy,
} from 'firebase/firestore';
import { haversineDistance } from './Haversine';

const handleFirestoreError = (error, functionName) => {
  if (error.code === 'failed-precondition') {
    console.error(
      `${functionName}: Firestore index required - ${error.message}`
    );
  } else {
    console.error(`${functionName}: Error fetching data - ${error.message}`);
  }
};
export const exportFetchUserProductsByNotLoggedIn = async (
  pageSize,
  lastDoc = null
) => {
  try {
    // Firestore에서 'products' 컬렉션을 쿼리
    let q = query(
      collection(db, 'products'),
      orderBy('createDate', 'desc'),
      limit(pageSize)
    );

    // 마지막 문서가 제공되면 페이징을 위해 startAfter를 추가
    if (lastDoc) {
      q = query(q, startAfter(lastDoc));
    }

    // 쿼리 실행
    const productsSnapshot = await getDocs(q);

    // 문서 데이터 배열로 변환
    const products = productsSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    // 마지막 문서를 반환하여 다음 페이지 쿼리할 때 사용할 수 있도록 함
    const lastVisibleDoc =
      productsSnapshot.docs[productsSnapshot.docs.length - 1];

    return { products, lastDoc: lastVisibleDoc };
  } catch (error) {
    handleFirestoreError(
      error,
      'exportFetchUserProductsByLastDocAsMapNotLoggedIn'
    );
    return { products: [], lastDoc: null };
  }
};
export const exportFetchStoresByNotLoggedIn = async (
  pageSize,
  lastDoc = null
) => {
  try {
    // Firestore에서 'stores' 컬렉션을 쿼리
    let q = query(
      collection(db, 'stores'),
      orderBy('createDate', 'desc'),
      limit(pageSize)
    );

    // 마지막 문서가 제공되면 페이징을 위해 startAfter를 추가
    if (lastDoc) {
      q = query(q, startAfter(lastDoc));
    }

    // 쿼리 실행
    const storesSnapshot = await getDocs(q);

    // 문서 데이터 배열로 변환
    const stores = storesSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    // 마지막 문서를 반환하여 다음 페이지 쿼리할 때 사용할 수 있도록 함
    const lastVisibleDoc = storesSnapshot.docs[storesSnapshot.docs.length - 1];

    return { stores, lastDoc: lastVisibleDoc };
  } catch (error) {
    handleFirestoreError(error, 'exportFetchStoresByNotLoggedIn');
    return { stores: [], lastDoc: null };
  }
};
// 사용자 제품을 페이지 단위로 가져오는 함수 (Map 형태로 반환)
export const exportFetchUserProductsByLastDocAsMap = async (
  pageSize,
  lastDoc = null,
  latitude,
  longitude,
  radius,
  currentUserId
) => {
  try {
    const usersSnapshot = await exportFetchUsersByCoordinatesAsArray(
      latitude,
      longitude,
      radius,
      currentUserId
    );
    if (!usersSnapshot.length) {
      return { products: {}, lastDoc: null, totalCount: 0 };
    }

    const userIds = usersSnapshot.map((user) => user.id);
    let productsQuery;

    if (lastDoc) {
      productsQuery = query(
        collection(db, 'products'),
        where('uid', 'in', userIds),
        startAfter(lastDoc),
        limit(pageSize)
      );
    } else {
      productsQuery = query(
        collection(db, 'products'),
        where('uid', 'in', userIds),
        limit(pageSize)
      );
    }

    const productsSnapshot = await getDocs(productsQuery);
    const products = productsSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    const totalProductsQuery = query(
      collection(db, 'products'),
      where('uid', 'in', userIds)
    );
    const totalProductsSnapshot = await getDocs(totalProductsQuery);

    return {
      products,
      lastDoc: productsSnapshot.docs[productsSnapshot.docs.length - 1] || null,
      totalCount: totalProductsSnapshot.size,
    };
  } catch (error) {
    handleFirestoreError(error, 'exportFetchUserProductsByLastDocAsMap');
    return {
      products: {},
      lastDoc: null,
      totalCount: 0,
    };
  }
};

// 가게 제품을 페이지 단위로 가져오는 함수 (Map 형태로 반환)
export const exportFetchStoresByLastDocAsMap = async (
  pageSize,
  lastDoc = null,
  latitude,
  longitude,
  radius,
  currentUserId
) => {
  try {
    const storesSnapshot = await exportFetchStoresByCoordinatesAsMap(
      latitude,
      longitude,
      radius,
      currentUserId
    );
    if (!storesSnapshot.stores.length) {
      return { products: {}, lastDoc: null, totalCount: 0 };
    }

    const storeIds = storesSnapshot.stores.map((store) => store.id);
    let storeProducts = [];
    let lastProductDoc = null;

    for (const storeId of storeIds) {
      let storeProductsQuery;

      if (lastDoc) {
        storeProductsQuery = query(
          collection(db, 'stores', storeId, 'products'),
          startAfter(lastDoc),
          limit(pageSize)
        );
      } else {
        storeProductsQuery = query(
          collection(db, 'stores', storeId, 'products'),
          limit(pageSize)
        );
      }

      const storeProductsSnapshot = await getDocs(storeProductsQuery);
      storeProductsSnapshot.docs.forEach((doc) => {
        storeProducts.push({
          id: doc.id,
          ...doc.data(),
        });
      });

      if (storeProductsSnapshot.docs.length > 0) {
        lastProductDoc =
          storeProductsSnapshot.docs[storeProductsSnapshot.docs.length - 1];
      }
    }

    const totalStoreProductsSnapshot = await Promise.all(
      storeIds.map((storeId) =>
        getDocs(collection(db, 'stores', storeId, 'products'))
      )
    );

    const totalCount = totalStoreProductsSnapshot.reduce(
      (acc, snapshot) => acc + snapshot.size,
      0
    );

    return {
      products: storeProducts,
      lastDoc: lastProductDoc,
      totalCount,
    };
  } catch (error) {
    handleFirestoreError(error, 'exportFetchStoresByLastDocAsMap');
    return {
      products: {},
      lastDoc: null,
      totalCount: 0,
    };
  }
};

// 페이지별 가게를 Map 형태로 반환
export const exportFetchStoresPerPageAsMap = async (
  pageSize,
  page,
  latitude,
  longitude,
  radius,
  currentUserId
) => {
  try {
    let storesQuery;
    let lastDoc = null;

    if (page > 0) {
      const previousPageDocCount = pageSize * page;
      const previousQuery = query(
        collection(db, 'stores'),
        orderBy('name'),
        limit(previousPageDocCount)
      );
      const previousSnapshot = await getDocs(previousQuery);
      lastDoc = previousSnapshot.docs[previousSnapshot.docs.length - 1];

      storesQuery = query(
        collection(db, 'stores'),
        orderBy('name'),
        startAfter(lastDoc),
        limit(pageSize)
      );
    } else {
      storesQuery = query(
        collection(db, 'stores'),
        orderBy('name'),
        limit(pageSize)
      );
    }

    const storesSnapshot = await getDocs(storesQuery);
    const stores = storesSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    const filteredStores = stores.filter((store) => {
      const { storePosition } = store;
      if (storePosition && storePosition.lat && storePosition.lng) {
        const distance = haversineDistance(
          { latitude, longitude },
          {
            latitude: storePosition.lat,
            longitude: storePosition.lng,
          }
        );
        return distance <= radius && store.id !== currentUserId;
      }
      return false;
    });

    const totalCount = filteredStores.length;

    return { stores: filteredStores, totalCount, lastDoc };
  } catch (error) {
    handleFirestoreError(error, 'exportFetchStoresPerPageAsMap');
    return { stores: [], totalCount: 0, lastDoc: null };
  }
};

// 좌표에 따른 사용자 제품을 배열 형태로 반환
export const exportFetchUserProductsByCoordinatesAsArray = async (
  latitude,
  longitude,
  radius,
  currentUserId
) => {
  try {
    const users = await exportFetchUsersByCoordinatesAsArray(
      latitude,
      longitude,
      radius,
      currentUserId
    );
    if (!users.length) {
      return [];
    }

    const userIds = users.map((user) => user.id);
    const products = await exportFetchUserProductsFromUsersAsArray(userIds);

    const sortedProducts = products.sort((a, b) => {
      return b.createDate.seconds - a.createDate.seconds;
    });

    return Array.isArray(sortedProducts) ? sortedProducts : [];
  } catch (error) {
    handleFirestoreError(error, 'exportFetchUserProductsByCoordinatesAsArray');
    return [];
  }
};

// 좌표에 따른 사용자 배열을 반환
const exportFetchUsersByCoordinatesAsArray = async (
  latitude,
  longitude,
  radius,
  currentUserId
) => {
  try {
    const usersSnapshot = await getDocs(collection(db, 'users'));
    const users = usersSnapshot.docs
      .map((doc) => {
        const userData = doc.data();
        const { selectedTown, town1, town2 } = userData;
        let userLocation = null;

        if (selectedTown === 1 && town1) {
          userLocation = { latitude: town1.lat, longitude: town1.lng };
        } else if (selectedTown === 2 && town2) {
          userLocation = { latitude: town2.lat, longitude: town2.lng };
        }

        if (userLocation) {
          const distance = haversineDistance(
            { latitude, longitude },
            userLocation
          );
          return {
            id: doc.id,
            ...userData,
            distance,
          };
        }
        return null;
      })
      .filter(
        (user) =>
          user !== null && user.distance <= radius && user.id !== currentUserId
      )
      .sort((a, b) => a.distance - b.distance);

    return users;
  } catch (error) {
    handleFirestoreError(error, 'exportFetchUsersByCoordinatesAsArray');
    return [];
  }
};

// 사용자로부터 제품 배열을 반환
const exportFetchUserProductsFromUsersAsArray = async (userIds) => {
  try {
    const productPromises = userIds.map(async (userId) => {
      const q = query(collection(db, 'products'), where('uid', '==', userId));
      const productsSnapshot = await getDocs(q);
      return productsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    });

    const productsArrays = await Promise.all(productPromises);
    return productsArrays.flat();
  } catch (error) {
    handleFirestoreError(error, 'exportFetchUserProductsFromUsersAsArray');
    return [];
  }
};
// 사용자 ID로 선택된 마을을 반환
export const exportFetchSelectedTownByUserId = async (userId) => {
  try {
    const userRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userRef);
    if (userDoc.exists()) {
      const userData = userDoc.data();
      const { selectedTown, town1, town2 } = userData;

      if (selectedTown === 1 && town1) {
        return { lat: town1.lat, lng: town1.lng, radius: town1.radius };
      } else if (selectedTown === 2 && town2) {
        return { lat: town2.lat, lng: town2.lng, radius: town2.radius };
      }
    }
    return null;
  } catch (error) {
    handleFirestoreError(error, 'exportFetchSelectedTownByUserId');
    return null;
  }
};

// 좌표에 따른 모든 상점 제품을 배열로 반환
export const exportFetchStoreAllProductsByCoordinatesAsArray = async (
  latitude,
  longitude,
  radius,
  currentUserId
) => {
  try {
    const stores = await exportFetchStoresByCoordinatesAsMap(
      latitude,
      longitude,
      radius,
      currentUserId
    );
    if (!stores.stores.length) {
      return [];
    }

    const storeIds = stores.stores.map((store) => store.id);
    const storeProducts = [];

    for (const storeId of storeIds) {
      const productsQuery = query(
        collection(db, 'stores', storeId, 'products')
      );
      const productsSnapshot = await getDocs(productsQuery);
      productsSnapshot.docs.forEach((doc) => {
        storeProducts.push({
          id: doc.id,
          ...doc.data(),
        });
      });
    }

    return storeProducts;
  } catch (error) {
    handleFirestoreError(
      error,
      'exportFetchStoreAllProductsByCoordinatesAsArray'
    );
    return [];
  }
};

// 좌표에 따른 상점 제품을 배열로 반환
export const exportFetchStoreProductsByCoordinatesAsMap = async (
  latitude,
  longitude,
  radius,
  currentUserId
) => {
  try {
    const { stores, totalCount, lastDoc } =
      await exportFetchStoresByCoordinatesAsMap(
        latitude,
        longitude,
        radius,
        currentUserId
      );

    if (!stores.length) {
      return { products: {}, lastDoc: null, totalCount: 0 };
    }

    const storeIds = stores.map((store) => store.id);
    const promotionProducts = {};

    for (const storeId of storeIds) {
      const productsQuery = query(
        collection(db, 'stores', storeId, 'products')
      );

      const productsSnapshot = await getDocs(productsQuery);
      productsSnapshot.docs.forEach((doc) => {
        promotionProducts[doc.id] = { id: doc.id, ...doc.data() };
      });
    }

    return {
      products: promotionProducts,
      lastDoc,
      totalCount: Object.keys(promotionProducts).length,
    };
  } catch (error) {
    handleFirestoreError(error, 'exportFetchStoreProductsByCoordinatesAsMap');
    return { products: {}, lastDoc: null, totalCount: 0 };
  }
};

// 좌표에 따른 상점을 Map으로 반환
export const exportFetchStoresByCoordinatesAsMap = async (
  latitude,
  longitude,
  radius,
  currentUserId,
  lastDoc = null,
  limitCount = 10
) => {
  try {
    let storesQuery = query(
      collection(db, 'stores'),
      orderBy('storePosition.lat'),
      limit(limitCount)
    );

    if (lastDoc) {
      storesQuery = query(
        collection(db, 'stores'),
        orderBy('storePosition.lat'),
        startAfter(lastDoc),
        limit(limitCount)
      );
    }

    const storesSnapshot = await getDocs(storesQuery);
    const stores = storesSnapshot.docs
      .map((doc) => {
        const storeData = doc.data();
        if (
          storeData.storePosition &&
          storeData.storePosition.lat &&
          storeData.storePosition.lng
        ) {
          const distance = haversineDistance(
            { latitude, longitude },
            {
              latitude: storeData.storePosition.lat,
              longitude: storeData.storePosition.lng,
            }
          );
          return {
            id: doc.id,
            ...storeData,
            distance,
          };
        }
        return null;
      })
      .filter(
        (store) =>
          store !== null &&
          store.distance <= radius &&
          store.id !== currentUserId
      )
      .sort((a, b) => a.distance - b.distance);

    const totalStoresSnapshot = await getDocs(collection(db, 'stores'));
    const totalCount = totalStoresSnapshot.docs.filter((doc) => {
      const storeData = doc.data();
      if (
        storeData.storePosition &&
        storeData.storePosition.lat &&
        storeData.storePosition.lng
      ) {
        const distance = haversineDistance(
          { latitude, longitude },
          {
            latitude: storeData.storePosition.lat,
            longitude: storeData.storePosition.lng,
          }
        );
        return distance <= radius && doc.id !== currentUserId;
      }
      return false;
    }).length;

    return {
      stores,
      lastDoc: storesSnapshot.docs[storesSnapshot.docs.length - 1],
      totalCount,
    };
  } catch (error) {
    handleFirestoreError(error, 'exportFetchStoresByCoordinatesAsMap');
    return { stores: [], lastDoc: null, totalCount: 0 };
  }
};

export const exportFetchUserProductsWithCurrentPosition = async (radius) => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        return exportFetchUserProductsByCoordinatesAsArray(
          latitude,
          longitude,
          radius
        );
      },
      (error) => {
        console.error('Error getting location:', error);
        return [];
      }
    );
  } else {
    console.error('Geolocation is not supported by this browser.');
    return [];
  }
};

export const exportFetchStoreProductsWithCurrentPosition = async (
  radius,
  currentUserId
) => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        return exportFetchStoreProductsByCoordinatesAsMap(
          latitude,
          longitude,
          radius,
          currentUserId
        );
      },
      (error) => {
        console.error('Error getting location:', error);
        return [];
      }
    );
  } else {
    console.error('Geolocation is not supported by this browser.');
    return [];
  }
};

export const exportFilterAndSortProducts = (
  products,
  sortBy = 'createDate',
  filterFn = null
) => {
  let filteredProducts = products;
  if (filterFn) {
    filteredProducts = products.filter(filterFn);
  }

  return filteredProducts.sort((a, b) => {
    if (sortBy === 'createDate') {
      return b.createDate.seconds - a.createDate.seconds;
    } else if (sortBy === 'popularity') {
      return b.likes - a.likes;
    }
    return 0;
  });
};
