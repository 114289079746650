import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './css/ImageViewer.css';
import DateFormatter from './Utils/DateFormatter';

export default function ImageViewer({ onGoToAlbum }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);

  const toggleHeaders = () => {
    setIsHeaderVisible(!isHeaderVisible);
  };
  // location.state에서 files와 index를 직접 추출
  const { files: imageFiles = [], index: initialIndex = 0 } =
    location.state || {};

  const [selectedFileIndex, setSelectedFileIndex] = useState(initialIndex);

  const selectedFile = imageFiles[selectedFileIndex];

  const handleClose = () => {
    navigate(-1); // 이전 페이지로 돌아갑니다.
  };
  const handlePrev = (event) => {
    event.stopPropagation(); // 이벤트 전파 중지
    if (selectedFileIndex > 0) {
      setSelectedFileIndex(selectedFileIndex - 1);
    }
  };

  const handleNext = (event) => {
    event.stopPropagation(); // 이벤트 전파 중지
    if (selectedFileIndex < imageFiles.length - 1) {
      setSelectedFileIndex(selectedFileIndex + 1);
    }
  };

  if (!selectedFile) {
    // selectedMedia가 없는 경우 빈 div 반환
    return <div />;
  }

  return (
    <div className="image-viewer" onClick={toggleHeaders}>
      <div
        className={`image-viewer-header ${
          isHeaderVisible ? 'visible' : 'hidden'
        }`}
      >
        {/* <button onClick={onGoToAlbum}>📷</button> */}
        <div className="header-center">
          <span>보낸사람 : {selectedFile.senderName}</span>
          <small>{DateFormatter.formatTimeStamp(selectedFile.date)}</small>
        </div>
        <button
          className="btn btn-outline-danger action-button"
          onClick={handleClose}
        >
          X
        </button>
      </div>

      <div className="image-viewer-content">
        {selectedFileIndex > 0 && (
          <button
            className="navigate-button prev"
            onClick={(e) => handlePrev(e)}
          >
            ←
          </button>
        )}

        {selectedFile.type === 'image' ? (
          <img
            src={selectedFile.fileUrl}
            alt="Preview"
            className="preview-image"
          />
        ) : (
          <div className="video-container">
            <video controls>
              <source src={selectedFile.fileUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        )}

        {selectedFileIndex < imageFiles.length - 1 && (
          <button
            className="navigate-button next"
            onClick={(e) => handleNext(e)}
          >
            →
          </button>
        )}
      </div>
      {/*
            <div className={`image-viewer-footer ${isHeaderVisible ? 'visible' : 'hidden'}`}>
                <a href={selectedFile.fileUrl} download target="_blank" rel="noopener noreferrer">
                    <button className="btn btn-outline-danger action-button">⬇️</button>
                </a>


                 <button className="btn btn-outline-danger action-button" onClick={() => {  }}>🔗</button>
                <button className="btn btn-outline-danger action-button" onClick={() => {
                    if (window.confirm("선택한 사진 또는 동영상을 삭제하시겠습니까? 묶음 사진은 전체 사진이 함께 삭제됩니다.")) {
                        
                    }
                }}>🗑️</button> 
            </div>*/}
    </div>
  );
}
