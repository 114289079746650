// import React, { useState, useEffect, useCallback } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { useNavigate, Link as RouterLink } from 'react-router-dom';
// import {
//   Box,
//   Typography,
//   Link,
//   List,
//   ListItem,
//   Tabs,
//   Tab,
//   Select,
//   MenuItem,
//   Button,
// } from '@mui/material';
// import { useTranslation } from 'react-i18next';
// import { setEditStep } from './Redux/reducers/editUserReducer';
// import UserSignUpFlow from './SignUp/UserSignUpFlow';
// import StoreSignUpFlow from './SignUp/StoreSignUpFlow';
// import ProductCategories from './Stores/ProductCategories';
// import countries from './Country/countries.json';
// import HeartList from './HeartList';
// import SellList from './SellList';
// import BuyList from './BuyList';
// import { auth } from './firebaseConfig';

// import useMessageBox from './Common/useMessageBox';

// function Settings() {
//   const dispatch = useDispatch();
//   const [mode, setMode] = useState(1);
//   const [isUserModal, setIsUserModal] = useState(false);
//   const [isStoreModal, setIsStoreModal] = useState(false);
//   const storedTabIndex = parseInt(localStorage.getItem('tabIndex'), 10) || 0;
//   const [tabIndex, setTabIndex] = useState(storedTabIndex);
//   const { t, i18n } = useTranslation();
//   const storedLanguage = localStorage.getItem('language') || 'ko';
//   const [activeLanguage, setActiveLanguage] = useState(storedLanguage);
//   const currentUser = useSelector((state) => state.auth.currentUser);
//   const navigate = useNavigate();
//   const [MessageBoxInform, MessageBoxConfirm, MessageBoxRender] =
//     useMessageBox();

//   // useEffect(() => {
//   //   if (storedLanguage) {
//   //     i18n.changeLanguage(storedLanguage);
//   //   }
//   // }, [i18n, storedLanguage]);

//   useEffect(() => {
//     const savedLanguage = localStorage.getItem('language') || 'en';
//     if (savedLanguage && savedLanguage !== activeLanguage) {
//       setActiveLanguage(savedLanguage);
//       i18n.changeLanguage(savedLanguage);
//       localStorage.setItem('language', savedLanguage);
//     }
//   }, [i18n, activeLanguage]);

//   useEffect(() => {
//     const onLanguageChanged = (lng) => {
//       setActiveLanguage(lng);
//     };

//     i18n.on('languageChanged', onLanguageChanged);

//     return () => {
//       i18n.off('languageChanged', onLanguageChanged);
//     };
//   }, [i18n]);

//   const handleTabChange = (event, newValue) => {
//     setTabIndex(newValue);
//     localStorage.setItem('tabIndex', newValue);
//   };

//   const handleLanguageChange = useCallback(
//     (event) => {
//       const newLanguage = event.target.value;
//       setActiveLanguage(newLanguage);
//       i18n.changeLanguage(newLanguage);
//       localStorage.setItem('language', newLanguage);
//     },
//     [i18n]
//   );

//   const handleUserRegisterClick = () => {
//     setMode(2);
//     setIsUserModal(true);
//     dispatch(setEditStep(1));
//   };

//   const handleUserEditPasswordClick = () => {
//     navigate('/editPassword');
//   };

//   const handleUserEditPhoneNumberClick = () => {
//     navigate('/phoneNumberVerify');
//   };

//   const handleUserEditAddressClick = () => {
//     navigate(`/user_address/${currentUser.uid}`);
//   };

//   const handleUserUnRegisterClick = () => {};

//   const handleTermsAgreement = () => {
//     navigate('/terms_agreement');
//   };

//   const handleTermsList = () => {
//     navigate('/terms_list');
//   };

//   const handleRegisterTerms = () => {
//     navigate('/register_terms');
//   };

//   const handlePrivacyAgreement = () => {
//     navigate('/privacy_agreement');
//   };

//   const handlePrivacyList = () => {
//     navigate('/privacy_list');
//   };

//   const handleRegisterPrivacy = () => {
//     navigate('/register_privacy');
//   };

//   const handleTranslate = () => {
//     navigate('/translate');
//   };

//   const handleRegisterKeyword = () => {
//     navigate('/settings/keywords');
//   };

//   const handleRegisterMyTown = () => {
//     navigate('/settings/mytown');
//   };

//   const handleShowHeartList = () => {
//     navigate('/heartlist');
//   };

//   const handleShowSellList = () => {
//     navigate('/selllist');
//   };

//   const handleShowBuyList = () => {
//     navigate('/buylist');
//   };

//   const handleShoppingCartClick = () => {
//     navigate(`/cartlist/${currentUser?.uid}/${currentUser?.uid}`);
//   };

//   const handleBizUserRegisterClick = () => {
//     setMode(1);
//     setIsStoreModal(true);
//     dispatch(setEditStep(1));
//   };

//   const handleBizUserEditPasswordClick = () => {
//     navigate('/editPassword');
//   };

//   const handleBizUserEditPhoneNumberClick = () => {
//     navigate('/phoneNumberVerify');
//   };

//   const handleBizUserEditDeliveryAreaClick = () => {
//     navigate('/storeDeliveryArea');
//   };

//   const handleBizUserEditStorePositionClick = () => {
//     navigate('/storePosition');
//   };

//   const handleBizUserUnRegisterClick = () => {};

//   const handleBizUserEditProfileClick = () => {
//     navigate(`/storeProfile`);
//   };

//   const handleLogout = async () => {
//     const isConfirmed = await MessageBoxConfirm(
//       t('logout'),
//       t('settings.logout-confirm')
//     );
//     if (isConfirmed) {
//       try {
//         await auth.signOut();
//         navigate('/');
//       } catch (error) {
//         console.error('Error during sign out:', error);
//       }
//     }
//   };

//   const onSelectCategory = async (category) => {};
//   const onSelectedCategoryPath = async (categoryPath) => {};

//   const renderTabContent = () => {
//     switch (tabIndex) {
//       case 0:
//         return (
//           <List>
//             <ListItem button onClick={handleShowHeartList}>
//               <Link component="button" variant="body1">
//                 {t('settings.interestList')}
//               </Link>
//             </ListItem>
//             <ListItem button onClick={handleShowSellList}>
//               <Link component="button" variant="body1">
//                 {t('settings.sellList')}
//               </Link>
//             </ListItem>
//             <ListItem button onClick={handleShowBuyList}>
//               <Link component="button" variant="body1">
//                 {t('settings.buyList')}
//               </Link>
//             </ListItem>
//             <ListItem button onClick={handleRegisterKeyword}>
//               <Link component="button" variant="body1">
//                 {t('settings.registerKeyword')}
//               </Link>
//             </ListItem>
//             <ListItem button onClick={handleRegisterMyTown}>
//               <Link component="button" variant="body1">
//                 {t('settings.myTown')}
//               </Link>
//             </ListItem>
//             <ListItem>
//               <Link component="button" variant="body1">
//                 {t('settings.languageSettings')}
//               </Link>
//               <Select
//                 value={activeLanguage}
//                 onChange={handleLanguageChange}
//                 displayEmpty
//                 inputProps={{ 'aria-label': 'Without label' }}
//               >
//                 {countries.map((country) => (
//                   <MenuItem key={country.code} value={country.code}>
//                     <span
//                       className={`flag-icon flag-icon-${country.flag.toLowerCase()}`}
//                     ></span>
//                     {country.name}
//                   </MenuItem>
//                 ))}
//               </Select>
//             </ListItem>
//           </List>
//         );
//       case 1:
//         return (
//           <List>
//             {currentUser ? (
//               <ListItem button onClick={handleLogout}>
//                 <Link component="button" variant="body1">
//                   {t('settings.logout')}
//                 </Link>
//               </ListItem>
//             ) : (
//               <ListItem button onClick={() => navigate('/login')}>
//                 <Link component="button" variant="body1">
//                   {t('settings.login')}
//                 </Link>
//               </ListItem>
//             )}
//             <ListItem button onClick={handleUserRegisterClick}>
//               <Link component="button" variant="body1">
//                 {t('settings.userSignUp')}
//               </Link>
//             </ListItem>
//             <ListItem button onClick={handleUserEditPasswordClick}>
//               <Link component="button" variant="body1">
//                 {t('settings.editPassword')}
//               </Link>
//             </ListItem>
//             <ListItem button onClick={handleUserEditAddressClick}>
//               <Link component="button" variant="body1">
//                 {t('settings.editAddress')}
//               </Link>
//             </ListItem>
//             <ListItem button onClick={handleUserEditPhoneNumberClick}>
//               <Link component="button" variant="body1">
//                 {t('settings.editPhoneNumber')}
//               </Link>
//             </ListItem>
//             <ListItem button onClick={handleUserUnRegisterClick}>
//               <Link component="button" variant="body1">
//                 {t('settings.unregister')}
//               </Link>
//             </ListItem>
//           </List>
//         );
//       case 2:
//         return (
//           <List>
//             {currentUser ? (
//               <ListItem button onClick={handleLogout}>
//                 <Link component="button" variant="body1">
//                   {t('settings.logout')}
//                 </Link>
//               </ListItem>
//             ) : (
//               <ListItem button onClick={() => navigate('/login')}>
//                 <Link component="button" variant="body1">
//                   {t('settings.login')}
//                 </Link>
//               </ListItem>
//             )}
//             <ListItem button onClick={handleBizUserRegisterClick}>
//               <Link component="button" variant="body1">
//                 {t('settings.bizUserSignUp')}
//               </Link>
//             </ListItem>
//             <ListItem button onClick={handleBizUserEditPasswordClick}>
//               <Link component="button" variant="body1">
//                 {t('settings.editPassword')}
//               </Link>
//             </ListItem>
//             <ListItem button onClick={handleBizUserEditProfileClick}>
//               <Link component="button" variant="body1">
//                 {t('settings.editStoreProfile')}
//               </Link>
//             </ListItem>
//             <ListItem button onClick={handleBizUserEditDeliveryAreaClick}>
//               <Link component="button" variant="body1">
//                 {t('settings.editDeliveryArea')}
//               </Link>
//             </ListItem>
//             <ListItem button onClick={handleBizUserEditStorePositionClick}>
//               <Link component="button" variant="body1">
//                 {t('settings.editStorePosition')}
//               </Link>
//             </ListItem>
//             <ListItem button onClick={handleBizUserEditPhoneNumberClick}>
//               <Link component="button" variant="body1">
//                 {t('settings.editPhoneNumber')}
//               </Link>
//             </ListItem>
//             {currentUser && (
//               <ListItem button>
//                 <Link
//                   component={RouterLink}
//                   variant="body1"
//                   to={`/store_orders/${currentUser?.uid}`}
//                 >
//                   {t('myStoreOrders')}
//                 </Link>
//               </ListItem>
//             )}
//             <ListItem button onClick={handleBizUserUnRegisterClick}>
//               <Link component="button" variant="body1">
//                 {t('settings.unregister')}
//               </Link>
//             </ListItem>
//           </List>
//         );
//       case 3:
//         return (
//           <List>
//             <ListItem button onClick={handleTermsList}>
//               <Link component="button" variant="body1">
//                 {t('settings.viewTermsList')}
//               </Link>
//             </ListItem>
//             <ListItem button onClick={handleTermsAgreement}>
//               <Link component="button" variant="body1">
//                 {t('settings.termsAgreementScreen')}
//               </Link>
//             </ListItem>
//             <ListItem button onClick={handlePrivacyList}>
//               <Link component="button" variant="body1">
//                 {t('settings.viewPrivacyList')}
//               </Link>
//             </ListItem>
//             <ListItem button onClick={handlePrivacyAgreement}>
//               <Link component="button" variant="body1">
//                 {t('settings.privacyAgreementScreen')}
//               </Link>
//             </ListItem>
//           </List>
//         );
//       case 4:
//         return (
//           <List>
//             <ListItem button onClick={handleRegisterTerms}>
//               <Link component="button" variant="body1">
//                 {t('settings.registerTerms')}
//               </Link>
//             </ListItem>
//             <ListItem button onClick={handleRegisterPrivacy}>
//               <Link component="button" variant="body1">
//                 {t('settings.registerPrivacy')}
//               </Link>
//             </ListItem>

//             <ListItem button onClick={handleTranslate}>
//               <Link component="button" variant="body1">
//                 {t('settings.translate')}
//               </Link>
//             </ListItem>
//           </List>
//         );
//       default:
//         return null;
//     }
//   };

//   return (
//     <Box
//       p={3}
//       sx={{
//         width: '100%',
//         maxWidth: '100%',
//         margin: 'auto',
//         marginBottom: '80px',
//       }}
//     >
//       {MessageBoxRender()}
//       <Typography variant="h4" gutterBottom>
//         {t('settings.title')}
//       </Typography>

//       <Tabs
//         value={tabIndex}
//         onChange={handleTabChange}
//         aria-label="settings tabs"
//       >
//         <Tab label={t('settings.common')} />
//         <Tab label={t('settings.userSettings')} />
//         <Tab label={t('settings.bizUserSettings')} />
//         <Tab label={t('settings.viewTerms')} />
//         <Tab label={t('settings.adminMenu')} />
//       </Tabs>
//       {renderTabContent()}

//       {isUserModal && (
//         <UserSignUpFlow mode={mode} closeModal={() => setIsUserModal(false)} />
//       )}

//       {isStoreModal && (
//         <StoreSignUpFlow
//           mode={mode}
//           closeModal={() => setIsStoreModal(false)}
//         />
//       )}
//     </Box>
//   );
// }

// export default Settings;

// import React, { useState, useEffect, useCallback } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { useNavigate, Link as RouterLink } from 'react-router-dom';
// import {
//   Box,
//   Typography,
//   Link,
//   List,
//   ListItem,
//   Tabs,
//   Tab,
//   Select,
//   MenuItem,
//   Button,
//   useMediaQuery,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
// } from '@mui/material';
// import { useTranslation } from 'react-i18next';
// import { setEditStep } from './Redux/reducers/editUserReducer';
// import UserSignUpFlow from './SignUp/UserSignUpFlow';
// import StoreSignUpFlow from './SignUp/StoreSignUpFlow';
// import ProductCategories from './Stores/ProductCategories';
// import countries from './Country/countries.json';
// import HeartList from './HeartList';
// import SellList from './SellList';
// import BuyList from './BuyList';
// import { auth } from './firebaseConfig';

// import useMessageBox from './Common/useMessageBox';

// function Settings() {
//   const dispatch = useDispatch();
//   const [mode, setMode] = useState(1);
//   const [isUserModal, setIsUserModal] = useState(false);
//   const [isStoreModal, setIsStoreModal] = useState(false);
//   const storedTabIndex = parseInt(localStorage.getItem('tabIndex'), 10) || 0;
//   const [tabIndex, setTabIndex] = useState(storedTabIndex);
//   const { t, i18n } = useTranslation();
//   const storedLanguage = localStorage.getItem('language') || 'ko';
//   const [activeLanguage, setActiveLanguage] = useState(storedLanguage);
//   const currentUser = useSelector((state) => state.auth.currentUser);
//   const navigate = useNavigate();
//   const [MessageBoxInform, MessageBoxConfirm, MessageBoxRender] =
//     useMessageBox();
//   const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
//   const [languageDialogOpen, setLanguageDialogOpen] = useState(false);

//   useEffect(() => {
//     const savedLanguage = localStorage.getItem('language') || 'en';
//     if (savedLanguage && savedLanguage !== activeLanguage) {
//       setActiveLanguage(savedLanguage);
//       i18n.changeLanguage(savedLanguage);
//       localStorage.setItem('language', savedLanguage);
//     }
//   }, [i18n, activeLanguage]);

//   useEffect(() => {
//     const onLanguageChanged = (lng) => {
//       setActiveLanguage(lng);
//     };

//     i18n.on('languageChanged', onLanguageChanged);

//     return () => {
//       i18n.off('languageChanged', onLanguageChanged);
//     };
//   }, [i18n]);

//   const handleTabChange = (event, newValue) => {
//     setTabIndex(newValue);
//     localStorage.setItem('tabIndex', newValue);
//   };

//   const handleLanguageChange = useCallback(
//     (event) => {
//       const newLanguage = event.target.value;
//       setActiveLanguage(newLanguage);
//       i18n.changeLanguage(newLanguage);
//       localStorage.setItem('language', newLanguage);
//       setLanguageDialogOpen(false);
//     },
//     [i18n]
//   );

//   const handleUserRegisterClick = () => {
//     setMode(2);
//     setIsUserModal(true);
//     dispatch(setEditStep(1));
//   };

//   const handleUserEditPasswordClick = () => {
//     navigate('/editPassword');
//   };

//   const handleUserEditPhoneNumberClick = () => {
//     navigate('/phoneNumberVerify');
//   };

//   const handleUserEditAddressClick = () => {
//     navigate(`/user_address/${currentUser.uid}`);
//   };

//   const handleUserUnRegisterClick = () => {};

//   const handleTermsAgreement = () => {
//     navigate('/terms_agreement');
//   };

//   const handleTermsList = () => {
//     navigate('/terms_list');
//   };

//   const handleRegisterTerms = () => {
//     navigate('/register_terms');
//   };

//   const handlePrivacyAgreement = () => {
//     navigate('/privacy_agreement');
//   };

//   const handlePrivacyList = () => {
//     navigate('/privacy_list');
//   };

//   const handleRegisterPrivacy = () => {
//     navigate('/register_privacy');
//   };

//   const handleTranslate = () => {
//     navigate('/translate');
//   };

//   const handleRegisterKeyword = () => {
//     navigate('/settings/keywords');
//   };

//   const handleRegisterMyTown = () => {
//     navigate('/settings/mytown');
//   };

//   const handleShowHeartList = () => {
//     navigate('/heartlist');
//   };

//   const handleShowSellList = () => {
//     navigate('/selllist');
//   };

//   const handleShowBuyList = () => {
//     navigate('/buylist');
//   };

//   const handleShoppingCartClick = () => {
//     navigate(`/cartlist/${currentUser?.uid}/${currentUser?.uid}`);
//   };

//   const handleBizUserRegisterClick = () => {
//     setMode(1);
//     setIsStoreModal(true);
//     dispatch(setEditStep(1));
//   };

//   const handleBizUserEditPasswordClick = () => {
//     navigate('/editPassword');
//   };

//   const handleBizUserEditPhoneNumberClick = () => {
//     navigate('/phoneNumberVerify');
//   };

//   const handleBizUserEditDeliveryAreaClick = () => {
//     navigate('/storeDeliveryArea');
//   };

//   const handleBizUserEditStorePositionClick = () => {
//     navigate('/storePosition');
//   };

//   const handleBizUserUnRegisterClick = () => {};

//   const handleBizUserEditProfileClick = () => {
//     navigate(`/storeProfile`);
//   };

//   const handleLogout = async () => {
//     const isConfirmed = await MessageBoxConfirm(
//       t('logout'),
//       t('settings.logout-confirm')
//     );
//     if (isConfirmed) {
//       try {
//         await auth.signOut();
//         navigate('/');
//       } catch (error) {
//         console.error('Error during sign out:', error);
//       }
//     }
//   };

//   const onSelectCategory = async (category) => {};
//   const onSelectedCategoryPath = async (categoryPath) => {};

//   const renderTabContent = () => {
//     switch (tabIndex) {
//       case 0:
//         return (
//           <List>
//             <ListItem button onClick={handleShowHeartList}>
//               <Link component="button" variant="body1">
//                 {t('settings.interestList')}
//               </Link>
//             </ListItem>
//             <ListItem button onClick={handleShowSellList}>
//               <Link component="button" variant="body1">
//                 {t('settings.sellList')}
//               </Link>
//             </ListItem>
//             <ListItem button onClick={handleShowBuyList}>
//               <Link component="button" variant="body1">
//                 {t('settings.buyList')}
//               </Link>
//             </ListItem>
//             <ListItem button onClick={handleRegisterKeyword}>
//               <Link component="button" variant="body1">
//                 {t('settings.registerKeyword')}
//               </Link>
//             </ListItem>
//             <ListItem button onClick={handleRegisterMyTown}>
//               <Link component="button" variant="body1">
//                 {t('settings.myTown')}
//               </Link>
//             </ListItem>
//             <ListItem button onClick={() => setLanguageDialogOpen(true)}>
//               <Link component="button" variant="body1">
//                 {t('settings.languageSettings')}
//               </Link>
//             </ListItem>
//           </List>
//         );
//       case 1:
//         return (
//           <List>
//             {currentUser ? (
//               <ListItem button onClick={handleLogout}>
//                 <Link component="button" variant="body1">
//                   {t('settings.logout')}
//                 </Link>
//               </ListItem>
//             ) : (
//               <ListItem button onClick={() => navigate('/login')}>
//                 <Link component="button" variant="body1">
//                   {t('settings.login')}
//                 </Link>
//               </ListItem>
//             )}
//             <ListItem button onClick={handleUserRegisterClick}>
//               <Link component="button" variant="body1">
//                 {t('settings.userSignUp')}
//               </Link>
//             </ListItem>
//             <ListItem button onClick={handleUserEditPasswordClick}>
//               <Link component="button" variant="body1">
//                 {t('settings.editPassword')}
//               </Link>
//             </ListItem>
//             <ListItem button onClick={handleUserEditAddressClick}>
//               <Link component="button" variant="body1">
//                 {t('settings.editAddress')}
//               </Link>
//             </ListItem>
//             <ListItem button onClick={handleUserEditPhoneNumberClick}>
//               <Link component="button" variant="body1">
//                 {t('settings.editPhoneNumber')}
//               </Link>
//             </ListItem>
//             <ListItem button onClick={handleUserUnRegisterClick}>
//               <Link component="button" variant="body1">
//                 {t('settings.unregister')}
//               </Link>
//             </ListItem>
//           </List>
//         );
//       case 2:
//         return (
//           <List>
//             {currentUser ? (
//               <ListItem button onClick={handleLogout}>
//                 <Link component="button" variant="body1">
//                   {t('settings.logout')}
//                 </Link>
//               </ListItem>
//             ) : (
//               <ListItem button onClick={() => navigate('/login')}>
//                 <Link component="button" variant="body1">
//                   {t('settings.login')}
//                 </Link>
//               </ListItem>
//             )}
//             <ListItem button onClick={handleBizUserRegisterClick}>
//               <Link component="button" variant="body1">
//                 {t('settings.bizUserSignUp')}
//               </Link>
//             </ListItem>
//             <ListItem button onClick={handleBizUserEditPasswordClick}>
//               <Link component="button" variant="body1">
//                 {t('settings.editPassword')}
//               </Link>
//             </ListItem>
//             <ListItem button onClick={handleBizUserEditProfileClick}>
//               <Link component="button" variant="body1">
//                 {t('settings.editStoreProfile')}
//               </Link>
//             </ListItem>
//             <ListItem button onClick={handleBizUserEditDeliveryAreaClick}>
//               <Link component="button" variant="body1">
//                 {t('settings.editDeliveryArea')}
//               </Link>
//             </ListItem>
//             <ListItem button onClick={handleBizUserEditStorePositionClick}>
//               <Link component="button" variant="body1">
//                 {t('settings.editStorePosition')}
//               </Link>
//             </ListItem>
//             <ListItem button onClick={handleBizUserEditPhoneNumberClick}>
//               <Link component="button" variant="body1">
//                 {t('settings.editPhoneNumber')}
//               </Link>
//             </ListItem>
//             {currentUser && (
//               <ListItem button>
//                 <Link
//                   component={RouterLink}
//                   variant="body1"
//                   to={`/store_orders/${currentUser?.uid}`}
//                 >
//                   {t('myStoreOrders')}
//                 </Link>
//               </ListItem>
//             )}
//             <ListItem button onClick={handleBizUserUnRegisterClick}>
//               <Link component="button" variant="body1">
//                 {t('settings.unregister')}
//               </Link>
//             </ListItem>
//           </List>
//         );
//       case 3:
//         return (
//           <List>
//             <ListItem button onClick={handleTermsList}>
//               <Link component="button" variant="body1">
//                 {t('settings.viewTermsList')}
//               </Link>
//             </ListItem>
//             <ListItem button onClick={handleTermsAgreement}>
//               <Link component="button" variant="body1">
//                 {t('settings.termsAgreementScreen')}
//               </Link>
//             </ListItem>
//             <ListItem button onClick={handlePrivacyList}>
//               <Link component="button" variant="body1">
//                 {t('settings.viewPrivacyList')}
//               </Link>
//             </ListItem>
//             <ListItem button onClick={handlePrivacyAgreement}>
//               <Link component="button" variant="body1">
//                 {t('settings.privacyAgreementScreen')}
//               </Link>
//             </ListItem>
//           </List>
//         );
//       case 4:
//         return (
//           <List>
//             <ListItem button onClick={handleRegisterTerms}>
//               <Link component="button" variant="body1">
//                 {t('settings.registerTerms')}
//               </Link>
//             </ListItem>
//             <ListItem button onClick={handleRegisterPrivacy}>
//               <Link component="button" variant="body1">
//                 {t('settings.registerPrivacy')}
//               </Link>
//             </ListItem>
//             <ListItem button onClick={handleTranslate}>
//               <Link component="button" variant="body1">
//                 {t('settings.translate')}
//               </Link>
//             </ListItem>
//           </List>
//         );
//       default:
//         return null;
//     }
//   };

//   return (
//     <Box
//       p={3}
//       sx={{
//         width: '100%',
//         maxWidth: '100%',
//         margin: 'auto',
//         marginBottom: '80px',
//       }}
//     >
//       {MessageBoxRender()}
//       <Typography variant="h4" gutterBottom>
//         {t('settings.title')}
//       </Typography>

//       <Tabs
//         value={tabIndex}
//         onChange={handleTabChange}
//         aria-label="settings tabs"
//         variant={isMobile ? 'scrollable' : 'standard'}
//         scrollButtons={isMobile ? 'auto' : 'off'}
//         allowScrollButtonsMobile
//       >
//         <Tab label={t('settings.common')} />
//         <Tab label={t('settings.userSettings')} />
//         <Tab label={t('settings.bizUserSettings')} />
//         <Tab label={t('settings.viewTerms')} />
//         <Tab label={t('settings.adminMenu')} />
//       </Tabs>
//       {renderTabContent()}

//       {isUserModal && (
//         <UserSignUpFlow mode={mode} closeModal={() => setIsUserModal(false)} />
//       )}

//       {isStoreModal && (
//         <StoreSignUpFlow
//           mode={mode}
//           closeModal={() => setIsStoreModal(false)}
//         />
//       )}

//       <Dialog
//         open={languageDialogOpen}
//         onClose={() => setLanguageDialogOpen(false)}
//       >
//         <DialogTitle>{t('settings.languageSettings')}</DialogTitle>
//         <DialogContent>
//           <Select
//             value={activeLanguage}
//             onChange={handleLanguageChange}
//             fullWidth
//           >
//             {countries.map((country) => (
//               <MenuItem key={country.code} value={country.code}>
//                 <span
//                   className={`flag-icon flag-icon-${country.flag.toLowerCase()}`}
//                   style={{ marginRight: 8 }}
//                 ></span>
//                 {country.name}
//               </MenuItem>
//             ))}
//           </Select>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setLanguageDialogOpen(false)}>
//             {t('settings.close')}
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </Box>
//   );
// }

// export default Settings;

import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Typography,
  Link,
  List,
  ListItem,
  Tabs,
  Tab,
  Button,
  Select,
  MenuItem,
  useMediaQuery,
  IconButton,
  ListItemText,
  ListItemSecondaryAction,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { setEditStep } from './Redux/reducers/editUserReducer';
import countries from './Country/countries.json';
import UserSignUpFlow from './SignUp/UserSignUpFlow';
import StoreSignUpFlow from './SignUp/StoreSignUpFlow';
import HeartList from './HeartList';
import SellList from './SellList';
import BuyList from './BuyList';
import { auth, signOut } from './firebaseConfig';
import SettingsIcon from '@mui/icons-material/Settings';

import useMessageBox from './Common/useMessageBox';
import LanguageSettingsModal from './LanguageSettingsModal';
import MyPage from './Stores/MyPage';

function Settings() {
  const dispatch = useDispatch();
  const [mode, setMode] = useState(1);
  const [isUserModal, setIsUserModal] = useState(false);
  const [isStoreModal, setIsStoreModal] = useState(false);
  const storedTabIndex = parseInt(localStorage.getItem('tabIndex'), 10) || 0;
  const [tabIndex, setTabIndex] = useState(storedTabIndex);
  const { t, i18n } = useTranslation();
  const defaultLanguage = process.env.REACT_APP_DEFAULT_LANGUAGE || 'en';
  const storedLanguage = localStorage.getItem('language') || defaultLanguage;
  const [activeLanguage, setActiveLanguage] = useState(storedLanguage);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const navigate = useNavigate();
  const [MessageBoxInform, MessageBoxConfirm, MessageBoxRender] =
    useMessageBox();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [languageDialogOpen, setLanguageDialogOpen] = useState(false);

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language') || defaultLanguage;
    setActiveLanguage(savedLanguage); // 여기에서 명시적으로 호출하여 상태를 업데이트
    i18n.changeLanguage(savedLanguage);
    localStorage.setItem('language', savedLanguage);
  }, []); // 이 useEffect는 컴포넌트가 처음 마운트될 때만 실행됩니다.

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language') || defaultLanguage;
    if (savedLanguage && savedLanguage !== activeLanguage) {
      setActiveLanguage(savedLanguage);
      i18n.changeLanguage(savedLanguage);
      localStorage.setItem('language', savedLanguage);
    }
  }, [i18n, activeLanguage]);

  useEffect(() => {
    const onLanguageChanged = (lng) => {
      setActiveLanguage(lng);
    };

    i18n.on('languageChanged', onLanguageChanged);

    return () => {
      i18n.off('languageChanged', onLanguageChanged);
    };
  }, [i18n]);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
    localStorage.setItem('tabIndex', newValue);
  };

  const handleLanguageChange = useCallback(
    (event) => {
      const newLanguage = event.target.value;
      setActiveLanguage(newLanguage);
      i18n.changeLanguage(newLanguage);
      localStorage.setItem('language', newLanguage);
    },
    [i18n]
  );

  const handleUserRegisterClick = () => {
    setMode(2);
    setIsUserModal(true);
    dispatch(setEditStep(1));
  };

  const handleUserEditPasswordClick = () => {
    navigate('/editPassword');
  };

  const handleUserEditPhoneNumberClick = () => {
    navigate('/phoneNumberVerify');
  };

  const handleUserEditAddressClick = () => {
    navigate(`/user_address/${currentUser.uid}`);
  };

  const handleUserUnRegisterClick = () => {
    navigate('/delete-user-account');
  };

  const handleTermsAgreement = () => {
    navigate('/terms_agreement');
  };

  const handleTermsList = () => {
    navigate('/terms_list');
  };

  const handleRegisterTerms = () => {
    navigate('/register_terms');
  };

  const handlePrivacyAgreement = () => {
    navigate('/privacy_agreement');
  };

  const handlePrivacyList = () => {
    navigate('/privacy_list');
  };

  const handleRegisterPrivacy = () => {
    navigate('/register_privacy');
  };

  const handleTranslate = () => {
    navigate('/translate');
  };

  const handleRegisterKeyword = () => {
    navigate('/settings/keywords');
  };

  const handleRegisterMyTown = () => {
    navigate('/settings/mytown');
  };
  const handleMyPageClick = () => {
    navigate(`/mypage/${currentUser.uid}/${currentUser.uid}`);
  };

  const handleStoreListClick = () => {
    navigate('/storelist');
  };

  const handleShowHeartList = () => {
    navigate('/heartlist');
  };

  const handleShowSellList = () => {
    navigate('/selllist');
  };

  const handleShowBuyList = () => {
    navigate('/buylist');
  };

  const handleShoppingCartClick = () => {
    navigate(`/cartlist/${currentUser?.uid}/${currentUser?.uid}`);
  };

  const handleBizUserRegisterClick = () => {
    setMode(1);
    setIsStoreModal(true);
    dispatch(setEditStep(1));
  };

  const handleBizUserEditPasswordClick = () => {
    navigate('/editPassword');
  };

  const handleBizUserEditPhoneNumberClick = () => {
    navigate('/phoneNumberVerify');
  };

  const handleBizUserEditDeliveryAreaClick = () => {
    navigate('/storeDeliveryArea');
  };

  const handleBizUserEditStorePositionClick = () => {
    navigate('/storePosition');
  };

  const handleBizUserUnRegisterClick = () => {
    navigate('/delete-store-account');
  };

  const handleBizUserEditProfileClick = () => {
    navigate(`/storeProfile`);
  };

  const clearAuthState = async () => {
    await signOut(auth);
    // Clear local storage or any other storage you are using to save authentication state
    localStorage.clear();
    sessionStorage.clear();
    // Optionally, clear cookies if used
    document.cookie.split(';').forEach((c) => {
      document.cookie = c
        .replace(/^ +/, '')
        .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
    });
    // Introduce a delay to ensure sign out is processed completely
    return new Promise((resolve) => setTimeout(resolve, 1000));
  };
  const handleLogout = async () => {
    const isConfirmed = await MessageBoxConfirm(
      t('logout'),
      t('settings.logout-confirm')
    );
    if (isConfirmed) {
      try {
        await clearAuthState();
        navigate('/');
      } catch (error) {
        console.error('Error during sign out:', error);
      }
    }
  };

  const onSelectCategory = async (category) => {};
  const onSelectedCategoryPath = async (categoryPath) => {};

  const renderTabContent = () => {
    switch (tabIndex) {
      case 0:
        return (
          <List>
            <ListItem button onClick={handleShowHeartList}>
              <Link component="button" variant="body1">
                {t('settings.interestList')}
              </Link>
            </ListItem>
            <ListItem button onClick={handleShowSellList}>
              <Link component="button" variant="body1">
                {t('settings.sellList')}
              </Link>
            </ListItem>
            <ListItem button onClick={handleShowBuyList}>
              <Link component="button" variant="body1">
                {t('settings.buyList')}
              </Link>
            </ListItem>
            <ListItem button onClick={handleRegisterKeyword}>
              <Link component="button" variant="body1">
                {t('settings.registerKeyword')}
              </Link>
            </ListItem>
            <ListItem button onClick={handleRegisterMyTown}>
              <Link component="button" variant="body1">
                {t('settings.myTown')}
              </Link>
            </ListItem>
            <ListItem button onClick={handleMyPageClick}>
              <Link component="button" variant="body1">
                {t('myPage')}
              </Link>
            </ListItem>
            <ListItem button onClick={handleStoreListClick}>
              <Link component="button" variant="body1">
                {t('localStores')}
              </Link>
            </ListItem>
            <ListItem
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <ListItemText primary={t('settings.languageSettings')} />
              <Select
                value={activeLanguage}
                onChange={handleLanguageChange}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                sx={{ minWidth: 120 }}
              >
                {countries.map((country) => (
                  <MenuItem key={country.code} value={country.code}>
                    <span
                      className={`flag-icon flag-icon-${country.flag.toLowerCase()}`}
                      style={{ marginRight: '8px' }}
                    ></span>
                    {country.name}
                  </MenuItem>
                ))}
              </Select>
            </ListItem>
            {/* 다이얼로그 팝업으로 언어를 선택하는 메뉴이지만 사용안함
            <ListItem button onClick={() => setLanguageDialogOpen(true)}>
              <ListItemText primary={t('settings.languageSettings')} />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  onClick={() => setLanguageDialogOpen(true)}
                >
                  <SettingsIcon />
                </IconButton>
                <Typography variant="body2">
                  {
                    countries.find((country) => country.code === activeLanguage)
                      ?.name
                  }
                </Typography>
              </ListItemSecondaryAction>
            </ListItem> */}
          </List>
        );
      case 1:
        return (
          <List>
            {currentUser ? (
              <ListItem button onClick={handleLogout}>
                <Link component="button" variant="body1">
                  {t('settings.logout')}
                </Link>
              </ListItem>
            ) : (
              <ListItem button onClick={() => navigate('/login')}>
                <Link component="button" variant="body1">
                  {t('settings.login')}
                </Link>
              </ListItem>
            )}
            <ListItem button onClick={handleUserRegisterClick}>
              <Link component="button" variant="body1">
                {t('settings.userSignUp')}
              </Link>
            </ListItem>
            <ListItem button onClick={handleUserEditPasswordClick}>
              <Link component="button" variant="body1">
                {t('settings.editPassword')}
              </Link>
            </ListItem>
            <ListItem button onClick={handleUserEditAddressClick}>
              <Link component="button" variant="body1">
                {t('settings.editAddress')}
              </Link>
            </ListItem>
            <ListItem button onClick={handleUserEditPhoneNumberClick}>
              <Link component="button" variant="body1">
                {t('settings.editPhoneNumber')}
              </Link>
            </ListItem>
            <ListItem button onClick={handleUserUnRegisterClick}>
              <Link component="button" variant="body1">
                {t('settings.unregister')}
              </Link>
            </ListItem>
          </List>
        );
      case 2:
        return (
          <List>
            {currentUser ? (
              <ListItem button onClick={handleLogout}>
                <Link component="button" variant="body1">
                  {t('settings.logout')}
                </Link>
              </ListItem>
            ) : (
              <ListItem button onClick={() => navigate('/login')}>
                <Link component="button" variant="body1">
                  {t('settings.login')}
                </Link>
              </ListItem>
            )}
            <ListItem button onClick={handleBizUserRegisterClick}>
              <Link component="button" variant="body1">
                {t('settings.bizUserSignUp')}
              </Link>
            </ListItem>
            <ListItem button onClick={handleBizUserEditPasswordClick}>
              <Link component="button" variant="body1">
                {t('settings.editPassword')}
              </Link>
            </ListItem>
            <ListItem button onClick={handleBizUserEditProfileClick}>
              <Link component="button" variant="body1">
                {t('settings.editStoreProfile')}
              </Link>
            </ListItem>
            <ListItem button onClick={handleBizUserEditDeliveryAreaClick}>
              <Link component="button" variant="body1">
                {t('settings.editDeliveryArea')}
              </Link>
            </ListItem>
            <ListItem button onClick={handleBizUserEditStorePositionClick}>
              <Link component="button" variant="body1">
                {t('settings.editStorePosition')}
              </Link>
            </ListItem>
            <ListItem button onClick={handleBizUserEditPhoneNumberClick}>
              <Link component="button" variant="body1">
                {t('settings.editPhoneNumber')}
              </Link>
            </ListItem>
            {currentUser && (
              <ListItem button>
                <Link
                  component={RouterLink}
                  variant="body1"
                  to={`/store_orders/${currentUser?.uid}`}
                >
                  {t('myStoreOrders')}
                </Link>
              </ListItem>
            )}
            <ListItem button onClick={handleBizUserUnRegisterClick}>
              <Link component="button" variant="body1">
                {t('settings.unregister')}
              </Link>
            </ListItem>
          </List>
        );
      case 3:
        return (
          <List>
            <ListItem button onClick={handleTermsList}>
              <Link component="button" variant="body1">
                {t('settings.viewTermsList')}
              </Link>
            </ListItem>
            <ListItem button onClick={handleTermsAgreement}>
              <Link component="button" variant="body1">
                {t('settings.termsAgreementScreen')}
              </Link>
            </ListItem>
            <ListItem button onClick={handlePrivacyList}>
              <Link component="button" variant="body1">
                {t('settings.viewPrivacyList')}
              </Link>
            </ListItem>
            <ListItem button onClick={handlePrivacyAgreement}>
              <Link component="button" variant="body1">
                {t('settings.privacyAgreementScreen')}
              </Link>
            </ListItem>
          </List>
        );
      case 4:
        return (
          <List>
            <ListItem button onClick={handleRegisterTerms}>
              <Link component="button" variant="body1">
                {t('settings.registerTerms')}
              </Link>
            </ListItem>
            <ListItem button onClick={handleRegisterPrivacy}>
              <Link component="button" variant="body1">
                {t('settings.registerPrivacy')}
              </Link>
            </ListItem>
            {/* <ListItem button onClick={handleTranslate}>
              <Link component="button" variant="body1">
                {t('settings.translate')}
              </Link>
            </ListItem> */}
          </List>
        );
      default:
        return null;
    }
  };

  return (
    <Box
      p={3}
      sx={{
        width: '100%',
        maxWidth: '100%',
        margin: 'auto',
        marginBottom: '80px',
      }}
    >
      {MessageBoxRender()}
      <Typography variant="h4" gutterBottom>
        {t('settings.title')}
      </Typography>

      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        aria-label="settings tabs"
        variant={isMobile ? 'scrollable' : 'standard'}
        scrollButtons={isMobile ? 'auto' : 'off'}
        allowScrollButtonsMobile
      >
        <Tab label={t('settings.common')} />
        <Tab label={t('settings.userSettings')} />
        <Tab label={t('settings.bizUserSettings')} />
        <Tab label={t('settings.viewTerms')} />
        <Tab label={t('settings.adminMenu')} />
      </Tabs>
      {renderTabContent()}

      {isUserModal && (
        <UserSignUpFlow mode={mode} closeModal={() => setIsUserModal(false)} />
      )}

      {isStoreModal && (
        <StoreSignUpFlow
          mode={mode}
          closeModal={() => setIsStoreModal(false)}
        />
      )}

      <LanguageSettingsModal
        open={languageDialogOpen}
        handleClose={() => setLanguageDialogOpen(false)}
        activeLanguage={activeLanguage}
        setActiveLanguage={setActiveLanguage}
      />
    </Box>
  );
}

export default Settings;
