import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { auth, db, getDoc, doc } from './firebaseConfig';

const CheckPasswordSetup = ({ children }) => {
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkPasswordSetup = async () => {
      if (currentUser) {
        try {
          const user = auth.currentUser;
          if (!user) {
            console.error('No user is currently signed in.');
            setLoading(false);
            return;
          }

          const providers = user.providerData.map(
            (provider) => provider.providerId
          );

          console.log('Providers:', providers);

          if (providers.includes('google.com')) {
            const hasPasswordProvider = providers.includes('password');
            if (!hasPasswordProvider) {
              navigate('/setpassword');
            }
          } else if (!user.providerData || user.providerData.length === 0) {
            // providerData가 null이거나 빈 배열인 경우
            const userRef = doc(db, 'users', user.uid);
            const userDoc = await getDoc(userRef);
            if (userDoc.exists()) {
              const userData = userDoc.data();
              if (
                !userData.setPassword &&
                (userData.providerId === 'naver.com' ||
                  userData.providerId === 'kakao.com')
              ) {
                navigate('/setpassword');
              }
            } else {
              // 사용자가 Firestore에 존재하지 않는 경우
              console.error('No user data found in Firestore.');
              navigate('/setpassword');
            }
          }
        } catch (error) {
          console.error('Error checking password setup:', error);
        }
      }
      setLoading(false);
    };

    checkPasswordSetup();
  }, [currentUser, navigate]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return <>{children}</>;
};

export default CheckPasswordSetup;

// import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import { auth, db, getDoc, doc } from './firebaseConfig';

// const CheckPasswordSetup = ({ children }) => {
//   const navigate = useNavigate();
//   const currentUser = useSelector((state) => state.auth.currentUser);
//   const [loading, setLoading] = useState(true);
//   const [navigated, setNavigated] = useState(false); // 추가된 상태 변수

//   useEffect(() => {
//     const checkPasswordSetup = async () => {
//       if (currentUser && !navigated) {
//         try {
//           const user = auth.currentUser;
//           if (!user) {
//             console.error('No user is currently signed in.');
//             setLoading(false);
//             return;
//           }

//           const providers = user.providerData.map(
//             (provider) => provider.providerId
//           );

//           console.log('Providers:', providers);

//           if (providers.includes('google.com')) {
//             const hasPasswordProvider = providers.includes('password');
//             if (!hasPasswordProvider) {
//               navigate('/setpassword');
//               setNavigated(true); // navigate 호출 후 상태 업데이트
//             }
//           } else if (!user.providerData || user.providerData.length === 0) {
//             // providerData가 null이거나 빈 배열인 경우
//             const userRef = doc(db, 'users', user.uid);
//             const userDoc = await getDoc(userRef);
//             if (userDoc.exists()) {
//               const userData = userDoc.data();
//               if (
//                 !userData.setPassword &&
//                 (userData.providerId === 'naver.com' ||
//                   userData.providerId === 'kakao.com')
//               ) {
//                 navigate('/setpassword');
//                 setNavigated(true); // navigate 호출 후 상태 업데이트
//               }
//             } else {
//               // 사용자가 Firestore에 존재하지 않는 경우
//               console.error('No user data found in Firestore.');
//               navigate('/setpassword');
//               setNavigated(true); // navigate 호출 후 상태 업데이트
//             }
//           }
//         } catch (error) {
//           console.error('Error checking password setup:', error);
//         }
//       }
//       setLoading(false);
//     };

//     checkPasswordSetup();
//   }, [currentUser, navigate, navigated]);

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   return <>{children}</>;
// };

// export default CheckPasswordSetup;
