import React from 'react';

import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  Container,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import DateFormatter from '../../../Utils/DateFormatter';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';

const CouponCard = ({ coupon, onDelete, isSeller }) => {
  const { t, i18n } = useTranslation();

  const formatDate = (date) => {
    return date ? new Date(date).toLocaleDateString() : t('noDateInfo');
  };

  return (
    <Card raised>
      <CardContent>
        <Typography variant="h5" component="h2">
          {coupon.couponName}
        </Typography>
        <Typography color="textSecondary">
          {t('discountType')}:{' '}
          {coupon.discountType === 'percentage'
            ? `${coupon.discount}%`
            : `${coupon.discount}${t('currency')}`}
        </Typography>
        <Typography color="textSecondary">
          {t('minPurchaseAmount')}: {coupon.minPurchaseAmount}
          {t('currency')}
        </Typography>
        <Typography color="textSecondary">
          {t('validPeriod')}: {formatDate(coupon.startDate)} -{' '}
          {formatDate(coupon.endDate)}
        </Typography>
        <Typography color="textSecondary">
          {t('applicableProducts')}:{' '}
          {coupon.targetProducts === 'all'
            ? t('allProducts')
            : coupon.applicableProducts
                .map((product) => product.productName)
                .join(', ')}
        </Typography>
        {isSeller && (
          <IconButton
            onClick={() => onDelete(coupon.id)}
            edge="end"
            aria-label="delete"
          >
            <DeleteIcon />
          </IconButton>
        )}
      </CardContent>
    </Card>
  );
};
export default CouponCard;
