import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  checkNotificationPermission,
  requestNotificationPermission,
} from './NotificationHelper';

const NotificationPrompt = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const permission = checkNotificationPermission();
    if (permission === 'default') {
      setOpen(true);
    }
  }, []);

  const handleClose = async (accept) => {
    setOpen(false);
    if (accept) {
      await requestNotificationPermission();
    }
  };

  return (
    <Dialog open={open} onClose={() => handleClose(false)}>
      <DialogTitle>{t('notification.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('notification.content')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)} color="primary">
          {t('notification.cancel')}
        </Button>
        <Button onClick={() => handleClose(true)} color="primary">
          {t('notification.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NotificationPrompt;
