import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  db,
  auth,
  storage,
  collection,
  onSnapshot,
  updateDoc,
  doc,
  getDoc,
  addDoc,
  query,
  orderBy,
} from './firebaseConfig';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import {
  Box,
  Button,
  TextField,
  Modal,
  Typography,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
} from '@mui/material';
import { createUserTransaction } from './userTransactionUtils';
import requestPermission from './requestPermission';
import AddIcon from '@mui/icons-material/Add';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import { useParams } from 'react-router-dom';
import ChatHeader from './ChatHeader';
import ChatMessage from './ChatMessage';
import moment from 'moment';
import ScheduleAppointment from './ScheduleAppointment';
import useMessageBox from './Common/useMessageBox';
import { useNavigate } from 'react-router-dom';
import ModalStyles from './Common/ModalStyles';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { filterMessage, filterImage } from './Utils/ContentsFilterClient';
import { filterImageTF } from './Utils/TensorFlowFilter';

function ChatView() {
  const { t } = useTranslation();
  const [MessageBoxInform, MessageBoxConfirm, MessageBoxRender] =
    useMessageBox();
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const chatBoxRef = useRef();
  const inputRef = useRef();
  const inputContainerRef = useRef();
  const [product, setProduct] = useState([]);
  const [chatMessages, setChatMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [appointments, setAppointments] = useState([]);
  const { productId, chatRoomId } = useParams();
  const [isOpenScheduleModal, setIsOpenScheduleModal] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadingFiles, setUploadingFiles] = useState([]);
  const [totalFileSize, setTotalFileSize] = useState(0);
  const [uploadedFileSize, setUploadedFileSize] = useState(0);
  const [inputHeight, setInputHeight] = useState(70);
  const [error, setError] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const tabHeight = 56; // Assuming 56px for the tab bar height on mobile

  useEffect(() => {
    fetchProductData();
    requestPermission();
  }, [productId]);

  const fetchProductData = async () => {
    try {
      const productRef = doc(db, 'products', productId);
      const productSnapshot = await getDoc(productRef);

      if (productSnapshot.exists()) {
        const rawData = productSnapshot.data();
        const formattedProduct = {
          id: productSnapshot.id,
          ...rawData,
          formattedDate: moment(rawData.createDate?.toDate()).fromNow(),
        };
        setProduct(formattedProduct);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // useEffect(() => {
  //   const messagesQuery = query(
  //     collection(doc(db, 'chatroom', chatRoomId), 'messages'),
  //     orderBy('date', 'asc')
  //   );

  //   const unsubscribe = onSnapshot(messagesQuery, (snapshot) => {
  //     const messagesData = snapshot.docs.map((doc) => doc.data());
  //     if (messagesData.some((msg) => msg.type === 'change-sales-status')) {
  //       fetchProductData();
  //     }
  //     const filteredMessages = messagesData.filter(
  //       (msg) => msg.type !== 'change-sales-status'
  //     );
  //     setChatMessages(filteredMessages);

  //     // Show browser notification for new messages
  //     if (filteredMessages.length > 0) {
  //       const latestMessage = filteredMessages[filteredMessages.length - 1];
  //       if (
  //         Notification.permission === 'granted' &&
  //         latestMessage.uid !== currentUser?.uid
  //       ) {
  //         sendNotification(
  //           'New Message',
  //           latestMessage.content,
  //           latestMessage.photoURL || '/path/to/default/icon.png'
  //         );
  //       }
  //     }
  //   });

  //   return () => unsubscribe();
  // }, [chatRoomId]);

  useEffect(() => {
    let unsubscribe = null;

    const fetchMessages = async () => {
      try {
        const messagesQuery = query(
          collection(doc(db, 'chatroom', chatRoomId), 'messages'),
          orderBy('date', 'asc')
        );

        unsubscribe = onSnapshot(
          messagesQuery,
          (snapshot) => {
            const messagesData = snapshot.docs.map((doc) => doc.data());
            if (
              messagesData.some((msg) => msg.type === 'change-sales-status')
            ) {
              fetchProductData();
            }
            const filteredMessages = messagesData.filter(
              (msg) => msg.type !== 'change-sales-status'
            );
            setChatMessages(filteredMessages);

            // Show browser notification for new messages
            if (filteredMessages.length > 0) {
              const latestMessage =
                filteredMessages[filteredMessages.length - 1];
              if (
                Notification.permission === 'granted' &&
                latestMessage.uid !== currentUser?.uid
              ) {
                sendNotification(
                  'New Message',
                  latestMessage.content,
                  latestMessage.photoURL || '/path/to/default/icon.png'
                );
              }
            }
          },
          (err) => {
            if (
              err.code === 'failed-precondition' ||
              err.code === 'unavailable'
            ) {
              console.error('Firestore index error:', err);
              setError(
                '필요한 인덱스가 없습니다. Firestore 콘솔에서 인덱스를 생성하세요.'
              );
            } else {
              console.error('Error fetching messages:', err);
              setError(`Error fetching messages: ${err.message}`);
            }
          }
        );
      } catch (err) {
        console.error('Error initializing messages query:', err);
        setError(`Error initializing messages query: ${err.message}`);
      }
    };

    fetchMessages();

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [chatRoomId, currentUser]);

  useEffect(() => {
    scrollToBottom();
  }, [chatMessages]);

  const scrollToBottom = () => {
    const chatBox = chatBoxRef.current;
    if (chatBox) {
      chatBox.scrollTop = chatBox.scrollHeight;
    }
  };

  const sendNotification = (title, body, icon) => {
    if (Notification.permission === 'granted') {
      new Notification(title, {
        body,
        icon,
      });
    }
  };

  const sendMessage = async (e) => {
    e.preventDefault();

    if (!chatRoomId || (!inputValue && !selectedFiles.length)) return;

    // 필터링 적용
    let isMessageOffensive = false;
    if (inputValue) {
      // 필터링 적용
      isMessageOffensive = await filterMessage(inputValue);
    }

    const areImagesOffensive = await Promise.all(
      selectedFiles.map((file) => filterImageTF(file))
    );

    if (
      isMessageOffensive ||
      areImagesOffensive.some((isOffensive) => isOffensive)
    ) {
      MessageBoxInform(t('offensiveContentDetected'));
      return;
    }

    const currentUserId = auth.currentUser.uid;
    const photoURL = auth.currentUser.photoURL;
    let fileUrls = [];

    if (selectedFiles.length > 0) {
      fileUrls = await uploadFiles();
    }

    const baseMessageData = {
      type: selectedFiles.length > 0 ? 'text|image' : 'text',
      content: inputValue,
      date: new Date(),
      uid: currentUserId,
      photoURL,
      fileUrls: fileUrls.length ? fileUrls : [],
    };

    const messagesCollectionRef = collection(
      doc(db, 'chatroom', chatRoomId),
      'messages'
    );
    await addDoc(messagesCollectionRef, baseMessageData);
    resetChatInput();
    setTimeout(() => {
      scrollToBottom();
    }, 100); // Ensure the function is called after state updates
  };

  const resetChatInput = () => {
    setInputValue('');
    setSelectedFiles([]);
    setUploadingFiles([]);
    setUploadedFileSize(0);
    setTotalFileSize(0);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const uploadSingleFile = async (file) => {
    const fileRef = ref(
      storage,
      `${process.env.REACT_APP_CHAT_FILE_PATH}/${Date.now()}_${file.name}`
    );
    const uploadTask = uploadBytesResumable(fileRef, file);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const fileProgress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

          setUploadingFiles((prev) => {
            return prev.map((item) => {
              if (item.file.name === file.name) {
                return {
                  ...item,
                  progress: fileProgress,
                };
              }
              return item;
            });
          });
        },
        (error) => {
          reject(error);
        },
        async () => {
          const downloadURL = await getDownloadURL(fileRef);
          setUploadedFileSize((prev) => prev + file.size);
          resolve(downloadURL);
        }
      );
    });
  };

  const uploadFiles = async () => {
    const urls = [];
    setUploadingFiles(selectedFiles.map((file) => ({ file, progress: 0 })));
    const totalSize = selectedFiles.reduce((acc, file) => acc + file.size, 0);
    setTotalFileSize(totalSize);

    for (const selectedFile of selectedFiles) {
      const url = await uploadSingleFile(selectedFile);
      urls.push(url);
    }

    return urls;
  };

  const handleScheduleClick = async () => {
    openScheduleModal();
  };

  const handleReservationClick = async () => {
    try {
      await updateProductState(productId, currentUser.uid, 1);
      MessageBoxInform(t('changedToReserved'));
    } catch (error) {
      console.error('Error updating document: ', error);
    }
  };

  const handlePhoneCallClick = async () => {
    await MessageBoxInform(t('implementing'));
  };

  const handleChangeStatus = async (status) => {
    try {
      let isConfirmed = false;
      if (status === 0) {
        isConfirmed = await MessageBoxConfirm(t('changeToOnSale'));
        if (isConfirmed) {
          await updateProductState(productId, currentUser.uid, 0, status);
          await MessageBoxInform(t('changedToOnSale'), '');
        }
      } else if (status === 1) {
        isConfirmed = await MessageBoxConfirm(t('changeToReserved'));
        if (isConfirmed) {
          await updateProductState(productId, currentUser.uid, 1, status);
          await MessageBoxInform(t('changedToReserved'), '');
        }
      } else if (status === 2) {
        isConfirmed = await MessageBoxConfirm(t('changeToSoldOut'), '');
        if (isConfirmed) {
          await updateProductState(productId, currentUser.uid, 2, status);
          await handleCreateUserTransaction();
          await MessageBoxInform(t('changedToSoldOut'), '');
        }
      }
    } catch (error) {
      console.error('Error updating document: ', error);
    }
  };

  const updateProductState = async (
    productId,
    currentUserId,
    stateValue,
    status
  ) => {
    const productRef = doc(collection(db, 'products'), productId);

    try {
      const docSnapshot = await getDoc(productRef);

      if (docSnapshot.exists()) {
        await updateDoc(productRef, {
          state: stateValue,
          endUsers: { give: docSnapshot.data().uid, take: currentUserId },
        });
        console.log('updateProductState successfully updated!');

        const messageData = {
          type: 'change-sales-status',
          content: status,
          date: new Date(),
          uid: currentUserId,
        };

        const messagesCollectionRef = collection(
          doc(db, 'chatroom', chatRoomId),
          'messages'
        );
        await addDoc(messagesCollectionRef, messageData);
      } else {
        console.log('updateProductState: No such document!');
      }
    } catch (error) {
      console.error('Error updating updateProductState: ', error);
    }
  };

  const handleImageClick = async () => {
    try {
      navigate(`/detail/${productId}`);
    } catch (error) {}
  };

  const handleCreateUserTransaction = async () => {
    const sellerId = product.uid;
    const buyerId = currentUser.uid;
    const transactionId = await createUserTransaction(sellerId, buyerId, {
      /* 기타 거래 데이터 */
      price: product.price, // 거래 가격
      productId: product.id, // 제품 ID
    });
    if (transactionId) {
      navigate(`/userTransaction/${transactionId}`);
    }
  };

  const openScheduleModal = () => {
    setIsOpenScheduleModal(true);
  };

  const closeScheduleModal = () => {
    setIsOpenScheduleModal(false);
  };

  const handleAppointmentSet = async (appointments) => {
    const formatDateAndTime = (date) => {
      const year = date.getFullYear();
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const day = ('0' + date.getDate()).slice(-2);
      const hour = ('0' + date.getHours()).slice(-2);
      const minute = ('0' + date.getMinutes()).slice(-2);

      return `${year}-${month}-${day} ${hour}:${minute}`;
    };

    const selectedDateTime = new Date(
      `${appointments.date}T${appointments.time}`
    );
    const formattedDateTime = formatDateAndTime(selectedDateTime);

    const messageData = {
      type: 'appointments',
      content: formattedDateTime || '',
      date: new Date(),
      uid: currentUser.uid,
      appointments: appointments,
    };

    await addDoc(
      collection(db, 'chatroom', chatRoomId, 'messages'),
      messageData
    );
    resetChatInput();
    setChatMessages((prev) => [...prev, messageData]);
    closeScheduleModal();
    setTimeout(() => {
      scrollToBottom();
    }, 100); // Ensure the function is called after state updates
  };

  const removeFileFromPreview = (indexToRemove) => {
    const updatedFiles = selectedFiles.filter(
      (_, index) => index !== indexToRemove
    );
    setSelectedFiles(updatedFiles);
  };

  return (
    <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Modal
        open={isOpenScheduleModal}
        onClose={closeScheduleModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={
            {
              /* your ModalStyles here */
            }
          }
        >
          <ScheduleAppointment
            onAppointmentSet={handleAppointmentSet}
            onClose={closeScheduleModal}
          />
        </Box>
      </Modal>

      {MessageBoxRender()}

      <Box
        sx={{
          flexShrink: 0,
          position: 'sticky',
          top: 0,
          zIndex: 1000,
          backgroundColor: 'background.paper',
          borderBottom: '1px solid',
          borderColor: 'divider',
        }}
      >
        <ChatHeader
          isOwner={currentUser?.uid === product.uid}
          imageUrl={product?.icon_images ? product.icon_images[0] : ''}
          title={product.title}
          state={product.state}
          product={product}
          onScheduleClick={handleScheduleClick}
          onChangeStatus={handleChangeStatus}
          onPhoneCallClick={handlePhoneCallClick}
          appointments={
            appointments.length > 0
              ? appointments[appointments.length - 1]
              : null
          }
          onImageClick={handleImageClick}
        />
      </Box>

      <Box
        ref={chatBoxRef}
        sx={{
          flexGrow: 1,
          overflowY: 'auto',
          minHeight: 0,
          paddingBottom: isMobile
            ? `${inputHeight + tabHeight - 30}px`
            : `${inputHeight + 20}px`,
          paddingX: 2, // Add horizontal padding if needed
        }}
      >
        {chatMessages.map((msg, index) => (
          <ChatMessage key={index} message={msg} />
        ))}
      </Box>

      <Box
        component="form"
        ref={inputContainerRef}
        onSubmit={sendMessage}
        sx={{
          position: 'fixed', // Fixing the position
          bottom: isMobile ? `${tabHeight}px` : 0, // Considering tab bar height in mobile
          left: 0,
          right: 0,
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          padding: '10px',
          boxShadow: '0 -5px 10px rgba(0,0,0,0.1)',
          backgroundColor: 'background.paper',
          borderTop: '1px solid',
          borderColor: 'divider',
        }}
      >
        {selectedFiles.length > 0 && (
          <Box
            sx={{
              display: 'flex',
              overflowX: 'auto',
              gap: 1,
              padding: '10px 0',
              borderBottom: '1px solid',
              borderColor: 'divider',
              backgroundColor: 'rgba(0, 0, 0, 0.05)', // Semi-transparent background
              justifyContent: 'center', // Center align images
            }}
          >
            {selectedFiles.map((file, index) => (
              <Box
                key={index}
                sx={{
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                {file.type.startsWith('image/') ? (
                  <img
                    src={URL.createObjectURL(file)}
                    alt="Preview"
                    style={{
                      height: 100,
                      width: 'auto',
                      marginBottom: '4px',
                      borderRadius: '4px',
                    }}
                  />
                ) : (
                  <Typography>📄</Typography>
                )}
                <IconButton
                  size="small"
                  onClick={() => removeFileFromPreview(index)}
                  sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    backgroundColor: 'rgba(255,255,255,0.7)',
                  }}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Box>
            ))}
          </Box>
        )}

        <Box sx={{ display: 'flex', gap: 1 }}>
          <Button variant="contained" component="label">
            <AddIcon />
            <input
              type="file"
              multiple
              hidden
              onChange={(e) => setSelectedFiles([...e.target.files])}
            />
          </Button>

          <TextField
            fullWidth
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder={t('enterMessage')}
            inputProps={{
              maxLength: process.env.REACT_APP_SEND_TEXT_MAX_LEN,
            }}
            onKeyPress={async (e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                await sendMessage(e);
                e.preventDefault(); // Prevent newline
              }
            }}
            inputRef={inputRef}
            sx={{
              height: '100%',
              '.MuiInputBase-root': {
                height: '100%',
              },
              '.MuiInputBase-input': {
                height: '100%',
                padding: '18px',
                boxSizing: 'border-box',
              },
            }}
          />

          <Button
            variant="contained"
            type="submit"
            disabled={!inputValue && selectedFiles.length === 0}
            endIcon={<SendIcon />}
            onClick={async (e) => {
              await sendMessage(e);
              inputRef.current.focus();
            }}
          >
            {/* {t('send')} */}
          </Button>
        </Box>
      </Box>

      {uploadingFiles.length > 0 && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1300,
          }}
        >
          <Box
            sx={{
              width: '80%',
              maxWidth: '400px',
              backgroundColor: '#fff',
              padding: '20px',
              borderRadius: '8px',
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <Typography variant="h6">{t('uploadProgress')}</Typography>
            <List>
              {uploadingFiles.map(({ file, progress }, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={file.name}
                    secondary={`${Math.round(progress)}%`}
                  />
                  <LinearProgress
                    variant="determinate"
                    value={progress}
                    sx={{ width: '100px', marginLeft: '10px' }}
                  />
                </ListItem>
              ))}
            </List>
            <Button
              variant="contained"
              onClick={() => setUploadingFiles([])}
              disabled={uploadingFiles.some(({ progress }) => progress < 100)}
            >
              {t('close')}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default ChatView;
