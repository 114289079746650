import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebaseConfig'; // Ensure you import your Firebase configuration and SDK properly
import {
  Box,
  Alert,
  CircularProgress,
  Typography,
  Divider,
} from '@mui/material';
import { useTranslation } from 'react-i18next'; // i18n import
import CartList from './CartList'; // Assuming CartList is a component that takes store items
import { useParams, useNavigate } from 'react-router-dom';

const AllCartList = () => {
  const { t, i18n } = useTranslation();
  const [stores, setStores] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const { userId } = useParams();

  useEffect(() => {
    const fetchStores = async () => {
      try {
        setLoading(true);
        const storesCollection = collection(db, 'stores');
        const storeSnapshot = await getDocs(storesCollection);
        const storeData = storeSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setStores(storeData);
      } catch (error) {
        console.error('Error fetching stores:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchStores();
  }, []);

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
  if (error) {
    return (
      <Box textAlign="center" mt={5}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }
  if (!stores.length) {
    return (
      <Box textAlign="center" mt={5}>
        <Typography>No stores found.</Typography>
      </Box>
    );
  }
  return (
    <Box
      p={3}
      sx={{
        width: '100%',
        maxWidth: '100%',
        margin: 'auto',
      }}
    >
      <Box
        sx={{
          display: 'inline-block',
          border: '0px solid #d3d3d3', // 연한 회색 테두리
          borderRadius: '16px', // 둥근 테두리
          padding: '8px 16px', // 안쪽 여백
          marginBottom: '16px', // 아래쪽 여백
          backgroundColor: 'transparent', // 연한 회색 배경색
          // boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // 그림자 효과
          transition: 'transform 0.2s', // 호버 시 애니메이션
          '&:hover': {
            transform: 'scale(1.02)', // 호버 시 크기 증가
          },
        }}
      >
        <Typography variant="h4" gutterBottom>
          {t('cartListByStore')}
        </Typography>
      </Box>
      {stores.map((store) => (
        <Box key={store.id} sx={{ marginBottom: 4 }}>
          <CartList userIdProp={userId} storeIdProp={store.id} />
          <Divider sx={{ marginTop: 2 }} />
        </Box>
      ))}
    </Box>
  );
};

export default AllCartList;

// import React, { useState, useEffect } from 'react';
// import { collection, getDocs, query, where } from 'firebase/firestore';
// import { db } from '../firebaseConfig'; // Your Firebase configuration and SDK
// import { Box, CircularProgress, Typography, Divider } from '@mui/material';
// import CartList from './CartList'; // Assuming CartList is a component that accepts cart items
// import { useParams, useNavigate } from 'react-router-dom';

// const AllCartList = () => {
//   const { userId } = useParams();
//   const [stores, setStores] = useState([]);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const fetchStoresAndCarts = async () => {
//       try {
//         setLoading(true);
//         // Fetch stores
//         const storesCollection = collection(db, 'stores');
//         const storeSnapshot = await getDocs(storesCollection);
//         let storeData = storeSnapshot.docs.map((doc) => ({
//           id: doc.id,
//           ...doc.data(),
//         }));

//         // For each store, fetch the cart items for the specific user
//         const cartDataPromises = storeData.map(async (store) => {
//           const cartCollection = collection(db, 'stores', store.id, 'carts');
//           const cartQuery = query(
//             cartCollection,
//             where('userId', '==', userId)
//           );
//           const cartSnapshot = await getDocs(cartQuery);
//           const cartItems = cartSnapshot.docs.map((doc) => doc.data());
//           return {
//             ...store,
//             cartItems, // Add cart items to the store object
//           };
//         });

//         // Wait for all promises to resolve and set the data
//         storeData = await Promise.all(cartDataPromises);
//         setStores(storeData);
//       } catch (error) {
//         console.error('Error fetching stores and carts:', error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchStoresAndCarts();
//   }, [userId]); // Dependency on userId to re-fetch if it changes

//   if (loading) {
//     return (
//       <Box
//         sx={{
//           display: 'flex',
//           justifyContent: 'center',
//           alignItems: 'center',
//           height: '100vh',
//         }}
//       >
//         <CircularProgress />
//       </Box>
//     );
//   }

//   return (
//     <Box sx={{ padding: 2 }}>
//       {stores.map((store) => (
//         <Box key={store.id} sx={{ marginBottom: 4 }}>
//           <Typography variant="h6" sx={{ marginBottom: 1 }}>
//             {store.storeName}
//           </Typography>
//           <CartList cartItems={store.cartItems} />
//           <Divider sx={{ marginTop: 2 }} />
//         </Box>
//       ))}
//     </Box>
//   );
// };

// export default AllCartList;
