// import React, { useState, useCallback, useEffect } from 'react';
// import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';

// const containerStyle = {
//   width: '100%',
//   height: '400px',
// };

// const GoogleMapMarker = ({ onLocationSelect }) => {
//   const { isLoaded } = useJsApiLoader({
//     id: 'google-map-script',
//     googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY, // 여기에 Google Maps API 키를 입력하세요
//   });

//   const [markerPosition, setMarkerPosition] = useState(null);

//   useEffect(() => {
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         const newCenter = {
//           lat: position.coords.latitude,
//           lng: position.coords.longitude,
//         };
//         setMarkerPosition(newCenter); // Update marker position to center
//         onLocationSelect(newCenter);
//       },
//       () => console.error('Error: Unable to retrieve your location'),
//       { enableHighAccuracy: true }
//     );
//   }, []);

//   const handleMapClick = useCallback(
//     (event) => {
//       const clickedLocation = {
//         lat: event.latLng.lat(),
//         lng: event.latLng.lng(),
//       };
//       setMarkerPosition(clickedLocation);
//       onLocationSelect(clickedLocation);
//     },
//     [onLocationSelect]
//   );
//   const onMarkerDragEnd = (e) => {
//     const newPosition = { lat: e.latLng.lat(), lng: e.latLng.lng() };
//     setMarkerPosition(newPosition);
//     onLocationSelect(newPosition);
//     // setMessage('마커 위치가 업데이트되었습니다.');
//   };
//   return isLoaded ? (
//     <GoogleMap
//       mapContainerStyle={containerStyle}
//       center={markerPosition}
//       zoom={15}
//       onClick={handleMapClick}
//     >
//       {markerPosition && (
//         <Marker
//           position={markerPosition}
//           draggable={true}
//           onDragEnd={onMarkerDragEnd}
//         />
//       )}
//     </GoogleMap>
//   ) : (
//     <div>Loading...</div>
//   );
// };

// export default GoogleMapMarker;

import React, { useState, useEffect, useCallback } from 'react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '400px',
};

const GoogleMapMarker = ({ onLocationSelect, initialPosition }) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY, // 여기에 Google Maps API 키를 입력하세요
  });

  const [markerPosition, setMarkerPosition] = useState(initialPosition);
  const [map, setMap] = useState(null);

  useEffect(() => {
    if (initialPosition) {
      setMarkerPosition(initialPosition);

      if (map) {
        map.panTo(initialPosition);
      }
    }
  }, [initialPosition, map]);

  const handleMapClick = useCallback(
    (event) => {
      const clickedLocation = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      setMarkerPosition(clickedLocation);
      onLocationSelect(clickedLocation);
    },
    [onLocationSelect]
  );

  const onMarkerDragEnd = (event) => {
    const newPosition = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };
    setMarkerPosition(newPosition);
    onLocationSelect(newPosition);
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={markerPosition || { lat: 37.5642135, lng: 127.0016985 }}
      zoom={15}
      onLoad={(mapInstance) => setMap(mapInstance)}
      onClick={handleMapClick}
    >
      {markerPosition && (
        <Marker
          position={markerPosition}
          draggable={true}
          onDragEnd={onMarkerDragEnd}
        />
      )}
    </GoogleMap>
  ) : (
    <div>Loading...</div>
  );
};

export default GoogleMapMarker;
