// import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import { db } from './firebaseConfig';
// import {
//   doc,
//   getDoc,
//   updateDoc,
//   addDoc,
//   collection,
//   getDocs,
//   where,
//   query,
//   increment,
//   arrayUnion,
//   orderBy,
// } from 'firebase/firestore';
// import {
//   Button,
//   Select,
//   MenuItem,
//   FormControl,
//   InputLabel,
//   CircularProgress,
//   Box,
//   Typography,
//   RadioGroup,
//   FormControlLabel,
//   Radio,
//   TextField,
//   List,
//   ListItem,
//   ListItemText,
//   ListItemSecondaryAction,
//   IconButton,
// } from '@mui/material';
// import { Check as CheckIcon, Chat as ChatIcon } from '@mui/icons-material';
// import useMessageBox from './Common/useMessageBox';
// import { useNavigate } from 'react-router-dom';
// import moment from 'moment';
// import { useTranslation } from 'react-i18next';
// import { googleTranslate } from './Utils/GoogleTranslation';

// const UserProductPurchase = ({ product }) => {
//   const { t, i18n } = useTranslation();
//   const navigate = useNavigate();
//   const [message, setMessage] = useState('');
//   const [presetMessages, setPresetMessages] = useState([]);
//   const currentUser = useSelector((state) => state.auth.currentUser);
//   const [MessageBoxInform, MessageBoxConfirm, MessageBoxRender] =
//     useMessageBox();
//   const [loading, setLoading] = useState(false);
//   const [chatRooms, setChatRooms] = useState([]);
//   const [chatRoomMessages, setChatRoomMessages] = useState({});
//   const [deliveryMethod, setDeliveryMethod] = useState('direct');
//   const [chatRoomId, setChatRoomId] = useState(null);

//   useEffect(() => {
//     moment.locale(i18n.language);
//   }, [i18n.language]);

//   useEffect(() => {
//     if (product && product.id) {
//       fetchChatRooms();
//       initializeChatRoom();
//     }
//   }, [product, currentUser]);

//   useEffect(() => {
//     fetchPresetMessages();
//   }, []);

//   const fetchPresetMessages = async () => {
//     try {
//       const messagesSnapshot = await getDocs(collection(db, 'presetMessages'));
//       const messagesList = await Promise.all(
//         messagesSnapshot.docs.map(async (doc) => {
//           const message = doc.data().text;
//           const translatedMessage = await googleTranslate(message);
//           return translatedMessage;
//         })
//       );
//       setPresetMessages(messagesList);
//     } catch (error) {
//       console.error('Error fetching preset messages:', error);
//     }
//   };

//   const fetchChatRooms = async () => {
//     if (!product.id) return;

//     setLoading(true);
//     try {
//       const chatroomsQuery = query(
//         collection(db, 'chatroom'),
//         where('productId', '==', product.id)
//       );

//       const chatroomsSnapshot = await getDocs(chatroomsQuery);
//       const chatRoomsList = chatroomsSnapshot.docs.map((doc) => ({
//         id: doc.id,
//         ...doc.data(),
//       }));

//       setChatRooms(chatRoomsList);

//       const allMessages = {};
//       for (const chatRoom of chatRoomsList) {
//         const messagesQuery = query(
//           collection(db, 'chatroom', chatRoom.id, 'messages'),
//           where('type', '==', 'purchase_request')
//         );

//         const messagesSnapshot = await getDocs(messagesQuery);
//         if (!messagesSnapshot.empty) {
//           const messages = messagesSnapshot.docs
//             .map((messageDoc) => ({
//               id: messageDoc.id,
//               ...messageDoc.data(),
//             }))
//             .filter((message) => message.content && message.content.length > 0);
//           messages.sort((a, b) => a.date - b.date); // 클라이언트 측에서 정렬
//           if (messages.length > 0) {
//             allMessages[chatRoom.id] = messages;
//           }
//         }
//       }

//       setChatRoomMessages(allMessages);
//     } catch (error) {
//       console.error(t('error-fetching-all-messages'), error);
//       MessageBoxInform(t('error-fetching-all-messages'));
//     }
//     setLoading(false);
//   };

//   const isLoggedIn = async () => {
//     if (!currentUser) {
//       const isConfirmed = await MessageBoxConfirm(
//         t('login-required'),
//         t('goto-login')
//       );
//       if (isConfirmed) {
//         navigate('/login');
//       }
//       return false;
//     }
//     return true;
//   };

//   const initializeChatRoom = async () => {
//     if (!currentUser) {
//       return;
//     }
//     const productOwnerId = product.uid;
//     let participants;

//     try {
//       if (currentUser.uid === productOwnerId) {
//         participants = [currentUser.uid];
//       } else {
//         participants = [currentUser.uid, productOwnerId];
//       }

//       const chatroomQuery = query(
//         collection(db, 'chatroom'),
//         where('productId', '==', product.id),
//         where('who', 'array-contains', currentUser.uid)
//       );

//       const chatroomSnapshot = await getDocs(chatroomQuery);

//       if (chatroomSnapshot.docs.length > 0) {
//         const existingChatRoomId = chatroomSnapshot.docs[0].id;
//         setChatRoomId(existingChatRoomId);

//         if (currentUser.uid !== productOwnerId) {
//           const chatRoomRef = doc(db, 'chatroom', existingChatRoomId);
//           await updateDoc(chatRoomRef, {
//             who: arrayUnion(productOwnerId),
//           });
//         }
//         fetchChatRooms();
//       } else {
//         const chatRoomData = {
//           who: participants,
//           productId: product.id,
//           productTitle: product.title,
//           date: new Date(),
//         };

//         const newChatRoomRef = await addDoc(
//           collection(db, 'chatroom'),
//           chatRoomData
//         );
//         setChatRoomId(newChatRoomRef.id);

//         const productRef = doc(db, 'products', product.id);
//         await updateDoc(productRef, {
//           chats: increment(1),
//         });
//         fetchChatRooms();
//       }
//     } catch (err) {
//       console.error(t('error-initializing-chat-room'), err);
//       MessageBoxInform(t('error-initializing-chat-room'));
//     }
//   };

//   const handlePlaceOrder = async () => {
//     if (!(await isLoggedIn())) return;
//     if (currentUser.uid === product.uid) {
//       MessageBoxInform(t('seller-cannot-buy'));
//       return;
//     }

//     if (product.state === 1) {
//       MessageBoxInform(t('product-reserved'));
//       return;
//     }

//     if (product.state === 2) {
//       MessageBoxInform(t('product-sold'));
//       return;
//     }

//     setLoading(true);
//     try {
//       const chatroomRef = doc(db, 'chatroom', chatRoomId);
//       const messagesRef = collection(chatroomRef, 'messages');

//       const existingRequestQuery = query(
//         messagesRef,
//         where('uid', '==', currentUser.uid),
//         where('type', '==', 'purchase_request')
//       );
//       const existingRequestSnapshot = await getDocs(existingRequestQuery);

//       let messageType = 'purchase_request';
//       if (!existingRequestSnapshot.empty) {
//         messageType = 'text|image';
//       }

//       const messageData = {
//         content: message,
//         date: new Date(),
//         fileUrls: [],
//         photoURL: currentUser.photoURL,
//         uid: currentUser.uid,
//         type: messageType,
//         deliveryMethod,
//       };
//       await addDoc(messagesRef, messageData);
//       await MessageBoxInform(t('purchase-request-registered'));
//       fetchChatRooms();
//     } catch (error) {
//       console.error(t('error-registering-purchase-request'), error);
//       await MessageBoxInform(t('error-registering-purchase-request'));
//     }
//     setLoading(false);
//   };

//   const handlePresetMessageChange = (event) => {
//     setMessage(event.target.value);
//   };

//   const handleSelectBuyer = async (buyer) => {
//     if (currentUser.uid !== product.uid) {
//       await MessageBoxInform(t('only-seller-can-select-buyer'));
//       return;
//     }
//     try {
//       await updateDoc(doc(db, 'products', product.id), {
//         selectedBuyer: buyer,
//       });
//       await MessageBoxInform(`${buyer.name} ${t('selected-as-buyer')}`);
//     } catch (error) {
//       console.error(t('error-selecting-buyer'), error);
//       await MessageBoxInform(t('error-selecting-buyer'));
//     }
//   };

//   const goToChat = async (chatRoom) => {
//     if (!(await isLoggedIn())) return;

//     if (!chatRoom.id) {
//       await MessageBoxInform(t('chat-room-not-initialized'));
//       return;
//     }

//     navigate(`/chatview/${product.id}/${chatRoom.id}`);
//   };

//   const getDeliveryMethodLabel = (deliveryMethod) => {
//     const deliveryMethodLabels = {
//       direct: t('direct'),
//       quickService: t('quickService'),
//       convenienceStore: t('convenienceStore'),
//     };

//     return deliveryMethodLabels[deliveryMethod] || t('unknown-method');
//   };

//   return (
//     <Box sx={{ p: 3 }}>
//       {MessageBoxRender()}
//       {loading ? (
//         <Box display="flex" justifyContent="center">
//           <CircularProgress />
//         </Box>
//       ) : (
//         <>
//           <FormControl fullWidth sx={{ mb: 2 }}>
//             <InputLabel id="preset-message-label">
//               {t('preset-message')}
//             </InputLabel>
//             <Select
//               labelId="preset-message-label"
//               value={message || ' '}
//               onChange={handlePresetMessageChange}
//               displayEmpty
//               label={t('preset-message')}
//               renderValue={(selected) => {
//                 if (!selected) {
//                   return <em>{t('direct-input')}</em>;
//                 }
//                 return <Typography noWrap>{selected}</Typography>;
//               }}
//               MenuProps={{
//                 PaperProps: {
//                   style: {
//                     maxHeight: 200,
//                   },
//                 },
//               }}
//             >
//               <MenuItem value=" ">
//                 <em>{t('direct-input')}</em>
//               </MenuItem>
//               {presetMessages.map((presetMessage, index) => (
//                 <MenuItem
//                   key={index}
//                   value={presetMessage}
//                   sx={{ whiteSpace: 'normal', lineHeight: 1.5 }}
//                 >
//                   {presetMessage}
//                 </MenuItem>
//               ))}
//             </Select>
//           </FormControl>

//           <TextField
//             fullWidth
//             multiline
//             rows={4}
//             variant="outlined"
//             label={t('purchase-request-message')}
//             value={message || ''}
//             onChange={(e) => setMessage(e.target.value)}
//             sx={{ mb: 2 }}
//           />
//           <FormControl component="fieldset" sx={{ mb: 2 }}>
//             <RadioGroup
//               row
//               aria-label="deliveryMethod"
//               name="deliveryMethod"
//               value={deliveryMethod}
//               onChange={(e) => setDeliveryMethod(e.target.value)}
//             >
//               <FormControlLabel
//                 value="direct"
//                 control={<Radio />}
//                 label={t('direct')}
//               />
//               <FormControlLabel
//                 value="quickService"
//                 control={<Radio />}
//                 label={t('quickService')}
//               />
//               {/* <FormControlLabel
//                 value="convenienceStore"
//                 control={<Radio />}
//                 label={t('convenienceStore')}
//               /> */}
//             </RadioGroup>
//           </FormControl>

//           <Button
//             variant="contained"
//             color="primary"
//             fullWidth
//             onClick={handlePlaceOrder}
//             sx={{
//               fontWeight: 'normal',
//               fontSize: '1.2rem',
//               backgroundColor: 'grey.100',
//               color: 'grey.600',
//               '&:hover': { backgroundColor: 'blue.300' },
//             }}
//             disabled={loading}
//           >
//             {loading ? (
//               <CircularProgress size={24} />
//             ) : (
//               t('send-purchase-request')
//             )}
//           </Button>
//           <Box mt={4}>
//             <Typography variant="h6">{t('purchase-request-list')}</Typography>
//             {chatRooms.length > 0 ? (
//               <List>
//                 {chatRooms.map((chatRoom) => (
//                   <ListItem key={chatRoom.id}>
//                     <ListItemText
//                       primary={`${t('message')}: ${
//                         chatRoomMessages[chatRoom.id] &&
//                         chatRoomMessages[chatRoom.id][0]
//                           ? chatRoomMessages[chatRoom.id][0].content
//                           : ''
//                       }`}
//                       secondary={
//                         chatRoomMessages[chatRoom.id] &&
//                         chatRoomMessages[chatRoom.id][0]
//                           ? `${moment(
//                               chatRoomMessages[chatRoom.id][0].date?.toDate()
//                             ).fromNow()} - ${getDeliveryMethodLabel(
//                               chatRoomMessages[chatRoom.id][0].deliveryMethod
//                             )}`
//                           : ''
//                       }
//                     />
//                     {currentUser && (
//                       <ListItemSecondaryAction>
//                         {currentUser.uid === product.uid && (
//                           <IconButton
//                             edge="end"
//                             onClick={() => handleSelectBuyer(chatRoom)}
//                           >
//                             <CheckIcon />
//                           </IconButton>
//                         )}
//                         <IconButton
//                           edge="end"
//                           onClick={() => goToChat(chatRoom)}
//                         >
//                           <ChatIcon />
//                         </IconButton>
//                       </ListItemSecondaryAction>
//                     )}
//                   </ListItem>
//                 ))}
//               </List>
//             ) : (
//               <Typography>{t('no-purchase-requests')}</Typography>
//             )}
//           </Box>
//         </>
//       )}
//     </Box>
//   );
// };

// export default UserProductPurchase;

// import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import { db } from './firebaseConfig';
// import {
//   doc,
//   getDoc,
//   updateDoc,
//   addDoc,
//   collection,
//   getDocs,
//   where,
//   query,
//   increment,
//   arrayUnion,
//   orderBy,
// } from 'firebase/firestore';
// import {
//   Button,
//   Select,
//   MenuItem,
//   FormControl,
//   InputLabel,
//   CircularProgress,
//   Box,
//   Typography,
//   RadioGroup,
//   FormControlLabel,
//   Radio,
//   TextField,
//   List,
//   ListItem,
//   ListItemText,
//   ListItemSecondaryAction,
//   IconButton,
//   Avatar,
//   Tooltip,
// } from '@mui/material';
// import { Check as CheckIcon, Chat as ChatIcon } from '@mui/icons-material';
// import useMessageBox from './Common/useMessageBox';
// import { useNavigate } from 'react-router-dom';
// import moment from 'moment';
// import { useTranslation } from 'react-i18next';
// import { googleTranslate } from './Utils/GoogleTranslation';

// const UserProductPurchase = ({ product }) => {
//   const { t, i18n } = useTranslation();
//   const navigate = useNavigate();
//   const [message, setMessage] = useState('');
//   const [presetMessages, setPresetMessages] = useState([]);
//   const currentUser = useSelector((state) => state.auth.currentUser);
//   const [MessageBoxInform, MessageBoxConfirm, MessageBoxRender] =
//     useMessageBox();
//   const [loading, setLoading] = useState(false);
//   const [chatRooms, setChatRooms] = useState([]);
//   const [chatRoomMessages, setChatRoomMessages] = useState({});
//   const [deliveryMethod, setDeliveryMethod] = useState('direct');
//   const [chatRoomId, setChatRoomId] = useState(null);

//   useEffect(() => {
//     moment.locale(i18n.language);
//   }, [i18n.language]);

//   useEffect(() => {
//     if (product && product.id) {
//       fetchChatRooms();
//       initializeChatRoom();
//     }
//   }, [product, currentUser]);

//   useEffect(() => {
//     fetchPresetMessages();
//   }, []);

//   const fetchPresetMessages = async () => {
//     try {
//       const messagesSnapshot = await getDocs(collection(db, 'presetMessages'));
//       const messagesList = await Promise.all(
//         messagesSnapshot.docs.map(async (doc) => {
//           const message = doc.data().text;
//           const translatedMessage = await googleTranslate(message);
//           return translatedMessage;
//         })
//       );
//       setPresetMessages(messagesList);
//     } catch (error) {
//       console.error('Error fetching preset messages:', error);
//     }
//   };

//   const fetchChatRooms = async () => {
//     if (!product.id) return;

//     setLoading(true);
//     try {
//       const chatroomsQuery = query(
//         collection(db, 'chatroom'),
//         where('productId', '==', product.id)
//       );

//       const chatroomsSnapshot = await getDocs(chatroomsQuery);
//       const chatRoomsList = chatroomsSnapshot.docs.map((doc) => ({
//         id: doc.id,
//         ...doc.data(),
//       }));

//       setChatRooms(chatRoomsList);

//       const allMessages = {};
//       for (const chatRoom of chatRoomsList) {
//         const messagesQuery = query(
//           collection(db, 'chatroom', chatRoom.id, 'messages'),
//           where('type', '==', 'purchase_request')
//         );

//         const messagesSnapshot = await getDocs(messagesQuery);
//         if (!messagesSnapshot.empty) {
//           const messages = await Promise.all(
//             messagesSnapshot.docs.map(async (messageDoc) => {
//               const messageData = messageDoc.data();
//               const userRef = doc(db, 'users', messageData.uid);
//               const userDoc = await getDoc(userRef);
//               const userData = userDoc.exists() ? userDoc.data() : {};
//               return {
//                 id: messageDoc.id,
//                 ...messageData,
//                 senderName: userData.name,
//                 senderAvatar: userData.photoURL,
//               };
//             })
//           );

//           messages.sort((a, b) => a.date - b.date); // 클라이언트 측에서 정렬
//           if (messages.length > 0) {
//             allMessages[chatRoom.id] = messages;
//           }
//         }
//       }

//       setChatRoomMessages(allMessages);
//     } catch (error) {
//       console.error(t('error-fetching-all-messages'), error);
//       MessageBoxInform(t('error-fetching-all-messages'));
//     }
//     setLoading(false);
//   };

//   const isLoggedIn = async () => {
//     if (!currentUser) {
//       const isConfirmed = await MessageBoxConfirm(
//         t('login-required'),
//         t('goto-login')
//       );
//       if (isConfirmed) {
//         navigate('/login');
//       }
//       return false;
//     }
//     return true;
//   };

//   const initializeChatRoom = async () => {
//     if (!currentUser) {
//       return;
//     }
//     const productOwnerId = product.uid;
//     let participants;

//     try {
//       if (currentUser.uid === productOwnerId) {
//         participants = [currentUser.uid];
//       } else {
//         participants = [currentUser.uid, productOwnerId];
//       }

//       const chatroomQuery = query(
//         collection(db, 'chatroom'),
//         where('productId', '==', product.id),
//         where('who', 'array-contains', currentUser.uid)
//       );

//       const chatroomSnapshot = await getDocs(chatroomQuery);

//       if (chatroomSnapshot.docs.length > 0) {
//         const existingChatRoomId = chatroomSnapshot.docs[0].id;
//         setChatRoomId(existingChatRoomId);

//         if (currentUser.uid !== productOwnerId) {
//           const chatRoomRef = doc(db, 'chatroom', existingChatRoomId);
//           await updateDoc(chatRoomRef, {
//             who: arrayUnion(productOwnerId),
//           });
//         }
//         fetchChatRooms();
//       } else {
//         const chatRoomData = {
//           who: participants,
//           productId: product.id,
//           productTitle: product.title,
//           date: new Date(),
//         };

//         const newChatRoomRef = await addDoc(
//           collection(db, 'chatroom'),
//           chatRoomData
//         );
//         setChatRoomId(newChatRoomRef.id);

//         const productRef = doc(db, 'products', product.id);
//         await updateDoc(productRef, {
//           chats: increment(1),
//         });
//         fetchChatRooms();
//       }
//     } catch (err) {
//       console.error(t('error-initializing-chat-room'), err);
//       MessageBoxInform(t('error-initializing-chat-room'));
//     }
//   };

//   const handlePlaceOrder = async () => {
//     if (!(await isLoggedIn())) return;
//     if (currentUser.uid === product.uid) {
//       MessageBoxInform(t('seller-cannot-buy'));
//       return;
//     }

//     if (product.state === 1) {
//       MessageBoxInform(t('product-reserved'));
//       return;
//     }

//     if (product.state === 2) {
//       MessageBoxInform(t('product-sold'));
//       return;
//     }

//     setLoading(true);
//     try {
//       const chatroomRef = doc(db, 'chatroom', chatRoomId);
//       const messagesRef = collection(chatroomRef, 'messages');

//       const existingRequestQuery = query(
//         messagesRef,
//         where('uid', '==', currentUser.uid),
//         where('type', '==', 'purchase_request')
//       );
//       const existingRequestSnapshot = await getDocs(existingRequestQuery);

//       let messageType = 'purchase_request';
//       if (!existingRequestSnapshot.empty) {
//         messageType = 'text|image';
//       }

//       const messageData = {
//         content: message,
//         date: new Date(),
//         fileUrls: [],
//         photoURL: currentUser.photoURL,
//         uid: currentUser.uid,
//         type: messageType,
//         deliveryMethod,
//       };
//       await addDoc(messagesRef, messageData);
//       await MessageBoxInform(t('purchase-request-registered'));
//       fetchChatRooms();
//     } catch (error) {
//       console.error(t('error-registering-purchase-request'), error);
//       await MessageBoxInform(t('error-registering-purchase-request'));
//     }
//     setLoading(false);
//   };

//   const handlePresetMessageChange = (event) => {
//     setMessage(event.target.value);
//   };

//   const handleSelectBuyer = async (buyer) => {
//     if (currentUser.uid !== product.uid) {
//       await MessageBoxInform(t('only-seller-can-select-buyer'));
//       return;
//     }
//     try {
//       await updateDoc(doc(db, 'products', product.id), {
//         selectedBuyer: buyer,
//       });
//       await MessageBoxInform(`${buyer.name} ${t('selected-as-buyer')}`);
//     } catch (error) {
//       console.error(t('error-selecting-buyer'), error);
//       await MessageBoxInform(t('error-selecting-buyer'));
//     }
//   };

//   const goToChat = async (chatRoom) => {
//     if (!(await isLoggedIn())) return;

//     if (!chatRoom.id) {
//       await MessageBoxInform(t('chat-room-not-initialized'));
//       return;
//     }

//     navigate(`/chatview/${product.id}/${chatRoom.id}`);
//   };

//   const getDeliveryMethodLabel = (deliveryMethod) => {
//     const deliveryMethodLabels = {
//       direct: t('direct'),
//       quickService: t('quickService'),
//       convenienceStore: t('convenienceStore'),
//     };

//     return deliveryMethodLabels[deliveryMethod] || t('unknown-method');
//   };

//   return (
//     <Box sx={{ p: 3 }}>
//       {MessageBoxRender()}
//       {loading ? (
//         <Box display="flex" justifyContent="center">
//           <CircularProgress />
//         </Box>
//       ) : (
//         <>
//           <FormControl fullWidth sx={{ mb: 2 }}>
//             <InputLabel id="preset-message-label">
//               {t('preset-message')}
//             </InputLabel>
//             <Select
//               labelId="preset-message-label"
//               value={message || ' '}
//               onChange={handlePresetMessageChange}
//               displayEmpty
//               label={t('preset-message')}
//               renderValue={(selected) => {
//                 if (!selected) {
//                   return <em>{t('direct-input')}</em>;
//                 }
//                 return <Typography noWrap>{selected}</Typography>;
//               }}
//               MenuProps={{
//                 PaperProps: {
//                   style: {
//                     maxHeight: 200,
//                   },
//                 },
//               }}
//             >
//               <MenuItem value=" ">
//                 <em>{t('direct-input')}</em>
//               </MenuItem>
//               {presetMessages.map((presetMessage, index) => (
//                 <MenuItem
//                   key={index}
//                   value={presetMessage}
//                   sx={{ whiteSpace: 'normal', lineHeight: 1.5 }}
//                 >
//                   {presetMessage}
//                 </MenuItem>
//               ))}
//             </Select>
//           </FormControl>

//           <TextField
//             fullWidth
//             multiline
//             rows={4}
//             variant="outlined"
//             label={t('purchase-request-message')}
//             value={message || ''}
//             onChange={(e) => setMessage(e.target.value)}
//             sx={{ mb: 2 }}
//           />
//           <FormControl component="fieldset" sx={{ mb: 2 }}>
//             <RadioGroup
//               row
//               aria-label="deliveryMethod"
//               name="deliveryMethod"
//               value={deliveryMethod}
//               onChange={(e) => setDeliveryMethod(e.target.value)}
//             >
//               <FormControlLabel
//                 value="direct"
//                 control={<Radio />}
//                 label={t('direct')}
//               />
//               <FormControlLabel
//                 value="quickService"
//                 control={<Radio />}
//                 label={t('quickService')}
//               />
//               {/* <FormControlLabel
//                 value="convenienceStore"
//                 control={<Radio />}
//                 label={t('convenienceStore')}
//               /> */}
//             </RadioGroup>
//           </FormControl>

//           <Button
//             variant="contained"
//             color="primary"
//             fullWidth
//             onClick={handlePlaceOrder}
//             sx={{
//               fontWeight: 'normal',
//               fontSize: '1.2rem',
//               backgroundColor: 'grey.100',
//               color: 'grey.600',
//               '&:hover': { backgroundColor: 'blue.300' },
//             }}
//             disabled={loading}
//           >
//             {loading ? (
//               <CircularProgress size={24} />
//             ) : (
//               t('send-purchase-request')
//             )}
//           </Button>
//           <Box mt={4}>
//             <Typography variant="h6">{t('purchase-request-list')}</Typography>
//             {chatRooms.length > 0 ? (
//               <List>
//                 {chatRooms.map((chatRoom) => {
//                   const latestMessage =
//                     chatRoomMessages[chatRoom.id] &&
//                     chatRoomMessages[chatRoom.id][0];
//                   const senderAvatar = latestMessage
//                     ? latestMessage.senderAvatar
//                     : '';
//                   const senderName = latestMessage
//                     ? latestMessage.senderName
//                     : '';

//                   return (
//                     <ListItem key={chatRoom.id}>
//                       {
//                         <Tooltip title={senderName} arrow>
//                           <Avatar src={senderAvatar} />
//                         </Tooltip>
//                       }
//                       <ListItemText
//                         primary={`${t('message')}: ${
//                           latestMessage ? latestMessage.content : ''
//                         }`}
//                         secondary={
//                           latestMessage
//                             ? `${moment(
//                                 latestMessage.date?.toDate()
//                               ).fromNow()} - ${getDeliveryMethodLabel(
//                                 latestMessage.deliveryMethod
//                               )}`
//                             : ''
//                         }
//                       />
//                       {currentUser && (
//                         <ListItemSecondaryAction>
//                           {currentUser.uid === product.uid && (
//                             <IconButton
//                               edge="end"
//                               onClick={() => handleSelectBuyer(chatRoom)}
//                             >
//                               <CheckIcon />
//                             </IconButton>
//                           )}
//                           <IconButton
//                             edge="end"
//                             onClick={() => goToChat(chatRoom)}
//                           >
//                             <ChatIcon />
//                           </IconButton>
//                         </ListItemSecondaryAction>
//                       )}
//                     </ListItem>
//                   );
//                 })}
//               </List>
//             ) : (
//               <Typography>{t('no-purchase-requests')}</Typography>
//             )}
//           </Box>
//         </>
//       )}
//     </Box>
//   );
// };

// export default UserProductPurchase;

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { db } from './firebaseConfig';
import {
  doc,
  getDoc,
  updateDoc,
  addDoc,
  collection,
  getDocs,
  where,
  query,
  increment,
  arrayUnion,
  orderBy,
} from 'firebase/firestore';
import {
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  Box,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Avatar,
  Tooltip,
} from '@mui/material';
import { Check as CheckIcon, Chat as ChatIcon } from '@mui/icons-material';
import useMessageBox from './Common/useMessageBox';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { googleTranslate } from './Utils/GoogleTranslation';

const UserProductPurchase = ({ product }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [message, setMessage] = useState('');
  const [presetMessages, setPresetMessages] = useState([]);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const [MessageBoxInform, MessageBoxConfirm, MessageBoxRender] =
    useMessageBox();
  const [loading, setLoading] = useState(false);
  const [chatRooms, setChatRooms] = useState([]);
  const [chatRoomMessages, setChatRoomMessages] = useState({});
  const [deliveryMethod, setDeliveryMethod] = useState('direct');
  const [chatRoomId, setChatRoomId] = useState(null);

  useEffect(() => {
    moment.locale(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    if (product && product.id) {
      fetchChatRooms();
      initializeChatRoom();
    }
  }, [product, currentUser]);

  useEffect(() => {
    fetchPresetMessages();
  }, []);

  const fetchPresetMessages = async () => {
    try {
      const messagesSnapshot = await getDocs(collection(db, 'presetMessages'));
      const messagesList = await Promise.all(
        messagesSnapshot.docs.map(async (doc) => {
          const message = doc.data().text;
          const translatedMessage = await googleTranslate(message);
          return translatedMessage;
        })
      );
      setPresetMessages(messagesList);
    } catch (error) {
      console.error('Error fetching preset messages:', error);
    }
  };

  const fetchChatRooms = async () => {
    if (!product.id) return;

    setLoading(true);
    try {
      const chatroomsQuery = query(
        collection(db, 'chatroom'),
        where('productId', '==', product.id)
      );

      const chatroomsSnapshot = await getDocs(chatroomsQuery);
      const chatRoomsList = chatroomsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setChatRooms(chatRoomsList);

      const allMessages = {};
      for (const chatRoom of chatRoomsList) {
        const messagesQuery = query(
          collection(db, 'chatroom', chatRoom.id, 'messages'),
          where('type', '==', 'purchase_request')
        );

        const messagesSnapshot = await getDocs(messagesQuery);
        if (!messagesSnapshot.empty) {
          const messages = await Promise.all(
            messagesSnapshot.docs.map(async (messageDoc) => {
              const messageData = messageDoc.data();
              const userRef = doc(db, 'users', messageData.uid);
              const userDoc = await getDoc(userRef);
              const userData = userDoc.exists() ? userDoc.data() : {};
              return {
                id: messageDoc.id,
                ...messageData,
                senderName: userData.name,
                senderAvatar: userData.photoURL,
              };
            })
          );

          messages.sort((a, b) => a.date - b.date); // 클라이언트 측에서 정렬
          if (messages.length > 0) {
            allMessages[chatRoom.id] = messages;
          }
        }
      }

      setChatRoomMessages(allMessages);
    } catch (error) {
      console.error(t('error-fetching-all-messages'), error);
      MessageBoxInform(t('error-fetching-all-messages'));
    }
    setLoading(false);
  };

  const getLatestValidMessage = (messages) => {
    for (const message of messages) {
      if (message.content && message.content.trim() !== '') {
        return message;
      }
    }
    return null;
  };

  const isLoggedIn = async () => {
    if (!currentUser) {
      const isConfirmed = await MessageBoxConfirm(
        t('login-required'),
        t('goto-login')
      );
      if (isConfirmed) {
        navigate('/login');
      }
      return false;
    }
    return true;
  };

  const initializeChatRoom = async () => {
    if (!currentUser) {
      return;
    }
    const productOwnerId = product.uid;
    let participants;

    try {
      if (currentUser.uid === productOwnerId) {
        participants = [currentUser.uid];
      } else {
        participants = [currentUser.uid, productOwnerId];
      }

      const chatroomQuery = query(
        collection(db, 'chatroom'),
        where('productId', '==', product.id),
        where('who', 'array-contains', currentUser.uid)
      );

      const chatroomSnapshot = await getDocs(chatroomQuery);

      if (chatroomSnapshot.docs.length > 0) {
        const existingChatRoomId = chatroomSnapshot.docs[0].id;
        setChatRoomId(existingChatRoomId);

        if (currentUser.uid !== productOwnerId) {
          const chatRoomRef = doc(db, 'chatroom', existingChatRoomId);
          await updateDoc(chatRoomRef, {
            who: arrayUnion(productOwnerId),
          });
        }
        fetchChatRooms();
      } else {
        const chatRoomData = {
          who: participants,
          productId: product.id,
          productTitle: product.title,
          date: new Date(),
        };

        const newChatRoomRef = await addDoc(
          collection(db, 'chatroom'),
          chatRoomData
        );
        setChatRoomId(newChatRoomRef.id);

        const productRef = doc(db, 'products', product.id);
        await updateDoc(productRef, {
          chats: increment(1),
        });
        fetchChatRooms();
      }
    } catch (err) {
      console.error(t('error-initializing-chat-room'), err);
      MessageBoxInform(t('error-initializing-chat-room'));
    }
  };

  const handlePlaceOrder = async () => {
    if (!(await isLoggedIn())) return;
    if (currentUser.uid === product.uid) {
      MessageBoxInform(t('seller-cannot-buy'));
      return;
    }

    if (product.state === 1) {
      MessageBoxInform(t('product-reserved'));
      return;
    }

    if (product.state === 2) {
      MessageBoxInform(t('product-sold'));
      return;
    }

    setLoading(true);
    try {
      const chatroomRef = doc(db, 'chatroom', chatRoomId);
      const messagesRef = collection(chatroomRef, 'messages');

      const existingRequestQuery = query(
        messagesRef,
        where('uid', '==', currentUser.uid),
        where('type', '==', 'purchase_request')
      );
      const existingRequestSnapshot = await getDocs(existingRequestQuery);

      let messageType = 'purchase_request';
      if (!existingRequestSnapshot.empty) {
        messageType = 'text|image';
      }

      const messageData = {
        content: message,
        date: new Date(),
        fileUrls: [],
        photoURL: currentUser.photoURL,
        uid: currentUser.uid,
        type: messageType,
        deliveryMethod,
      };
      await addDoc(messagesRef, messageData);
      await MessageBoxInform(t('purchase-request-registered'));
      fetchChatRooms();
    } catch (error) {
      console.error(t('error-registering-purchase-request'), error);
      await MessageBoxInform(t('error-registering-purchase-request'));
    }
    setLoading(false);
  };

  const handlePresetMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleSelectBuyer = async (buyer) => {
    if (currentUser.uid !== product.uid) {
      await MessageBoxInform(t('only-seller-can-select-buyer'));
      return;
    }
    try {
      await updateDoc(doc(db, 'products', product.id), {
        selectedBuyer: buyer,
      });
      await MessageBoxInform(`${buyer.name} ${t('selected-as-buyer')}`);
    } catch (error) {
      console.error(t('error-selecting-buyer'), error);
      await MessageBoxInform(t('error-selecting-buyer'));
    }
  };

  const goToChat = async (chatRoom) => {
    if (!(await isLoggedIn())) return;

    if (!chatRoom.id) {
      await MessageBoxInform(t('chat-room-not-initialized'));
      return;
    }

    navigate(`/chatview/${product.id}/${chatRoom.id}`);
  };

  const getDeliveryMethodLabel = (deliveryMethod) => {
    const deliveryMethodLabels = {
      direct: t('direct'),
      quickService: t('quickService'),
      convenienceStore: t('convenienceStore'),
    };

    return deliveryMethodLabels[deliveryMethod] || t('unknown-method');
  };

  return (
    <Box sx={{ p: 3 }}>
      {MessageBoxRender()}
      {loading ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="preset-message-label">
              {t('preset-message')}
            </InputLabel>
            <Select
              labelId="preset-message-label"
              value={message || ' '}
              onChange={handlePresetMessageChange}
              displayEmpty
              label={t('preset-message')}
              renderValue={(selected) => {
                if (!selected) {
                  return <em>{t('direct-input')}</em>;
                }
                return <Typography noWrap>{selected}</Typography>;
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 200,
                  },
                },
              }}
            >
              <MenuItem value=" ">
                <em>{t('direct-input')}</em>
              </MenuItem>
              {presetMessages.map((presetMessage, index) => (
                <MenuItem
                  key={index}
                  value={presetMessage}
                  sx={{ whiteSpace: 'normal', lineHeight: 1.5 }}
                >
                  {presetMessage}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            label={t('purchase-request-message')}
            value={message || ''}
            onChange={(e) => setMessage(e.target.value)}
            sx={{ mb: 2 }}
          />
          <FormControl component="fieldset" sx={{ mb: 2 }}>
            <RadioGroup
              row
              aria-label="deliveryMethod"
              name="deliveryMethod"
              value={deliveryMethod}
              onChange={(e) => setDeliveryMethod(e.target.value)}
            >
              <FormControlLabel
                value="direct"
                control={<Radio />}
                label={t('direct')}
              />
              <FormControlLabel
                value="quickService"
                control={<Radio />}
                label={t('quickService')}
              />
              {/* <FormControlLabel
                value="convenienceStore"
                control={<Radio />}
                label={t('convenienceStore')}
              /> */}
            </RadioGroup>
          </FormControl>

          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handlePlaceOrder}
            sx={{
              fontWeight: 'normal',
              fontSize: '1.2rem',
              backgroundColor: 'grey.100',
              color: 'grey.600',
              '&:hover': { backgroundColor: 'blue.300' },
            }}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={24} />
            ) : (
              t('send-purchase-request')
            )}
          </Button>
          <Box mt={4}>
            <Typography variant="h6">{t('purchase-request-list')}</Typography>
            {chatRooms.length > 0 ? (
              <List>
                {chatRooms.map((chatRoom) => {
                  const messages = chatRoomMessages[chatRoom.id] || [];
                  const latestMessage = getLatestValidMessage(messages);
                  const senderAvatar = latestMessage
                    ? latestMessage.senderAvatar
                    : '';
                  const senderName = latestMessage
                    ? latestMessage.senderName
                    : '';

                  return (
                    latestMessage && (
                      <ListItem key={chatRoom.id}>
                        {
                          <Tooltip title={senderName} arrow>
                            <Avatar src={senderAvatar} />
                          </Tooltip>
                        }
                        <ListItemText
                          primary={`${t('message')}: ${
                            latestMessage ? latestMessage.content : ''
                          }`}
                          secondary={
                            latestMessage
                              ? `${moment(
                                  latestMessage.date?.toDate()
                                ).fromNow()} - ${getDeliveryMethodLabel(
                                  latestMessage.deliveryMethod
                                )}`
                              : ''
                          }
                        />
                        {currentUser && (
                          <ListItemSecondaryAction>
                            {currentUser.uid === product.uid && (
                              <IconButton
                                edge="end"
                                onClick={() => handleSelectBuyer(chatRoom)}
                              >
                                <CheckIcon />
                              </IconButton>
                            )}
                            <IconButton
                              edge="end"
                              onClick={() => goToChat(chatRoom)}
                            >
                              <ChatIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        )}
                      </ListItem>
                    )
                  );
                })}
              </List>
            ) : (
              <Typography>{t('no-purchase-requests')}</Typography>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default UserProductPurchase;
