// requestPermission.js
import { getToken } from 'firebase/messaging';
import { messaging } from './firebaseConfig'; // Firebase 초기화 파일에서 가져오기
import { db, auth } from './firebaseConfig';
import { doc, updateDoc } from 'firebase/firestore';

const requestPermission = async () => {
  try {
    const currentUser = auth.currentUser;
    if (!currentUser) {
      console.log('User is not logged in.');
      return;
    }
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      const token = await getToken(messaging, {
        vapidKey:
          'BOWJwiBc9S4FZ7YsXgq8e_rrbwbBFhJOGKc0gN_qxJllbTk3cu6zbqUup9YlS0Dyw8M8n2VgIGK6OF42gBf5F8k',
      });
      console.log('FCM token:', token);
      const userRef = doc(db, 'users', currentUser.uid);
      await updateDoc(userRef, {
        fcmToken: token,
      });
      return token;
    } else {
      console.log('Permission not granted for Notification');
    }
  } catch (error) {
    console.error('Error getting FCM token', error);
  }
};

export default requestPermission;
