
import React, { useEffect, useState, createContext } from 'react';

export const CategoryContext = createContext();

export const CategoryProvider = ({ children }) => {
    const [categories, setCategories] = useState([]);
    const [currentCategory, setCurrentCategory] = useState(null);
  
    const value = {
      categories,
      setCategories,
      currentCategory,
      setCurrentCategory
    };
  
    return (
      <CategoryContext.Provider value={value}>
        {children}
      </CategoryContext.Provider>
    );
  };
  