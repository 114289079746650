import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Route, Routes } from 'react-router-dom';
import {
  db,
  doc,
  updateDoc,
  addDoc,
  collection,
  runTransaction,
} from '../../../firebaseConfig';

async function UsePoints(storeId, userId, pointsToUse) {
  const storeUserRef = doc(db, 'users', userId);
  let response = { success: false, message: '', newPoints: null };

  try {
    await runTransaction(db, async (transaction) => {
      const storeUserDoc = await transaction.get(storeUserRef);
      if (!storeUserDoc.exists()) {
        throw new Error('User does not exist!');
      }

      const currentPoints = storeUserDoc.data().points || 0;

      if (currentPoints < pointsToUse) {
        throw new Error('Not enough points!');
      }

      // Deduct points
      const newPoints = currentPoints - pointsToUse;
      transaction.update(storeUserRef, { points: newPoints });

      // Log the usage in a sub-collection (e.g., 'pointsLog')
      const pointsLogCollection = collection(
        db,
        'stores',
        storeId,
        'users',
        userId,
        'pointsLog'
      );
      const newLogDoc = doc(pointsLogCollection); // This generates a new document reference with a random ID
      const logData = {
        pointsUsed: pointsToUse,
        date: new Date(),
      };
      transaction.set(newLogDoc, logData);

      // Update the response object
      response.success = true;
      response.newPoints = newPoints;
      response.usedPoints = pointsToUse;
    });

    response.message = 'Points used successfully!';
  } catch (error) {
    console.error('Error using points:', error);
    response.message = error.message;
  }

  return response; // Return the response object outside the transaction
}

export default UsePoints;
