import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Container,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';

const DiscountCard = ({ discount, onDelete, isSeller }) => {
  const { t, i18n } = useTranslation();
  const formatDate = (date) => {
    return date ? new Date(date).toLocaleDateString() : '날짜 정보 없음';
  };
  return (
    <Card raised>
      <CardContent>
        <Typography variant="h5" component="h2">
          {discount.discountName}
        </Typography>
        {discount.discountType === 'percentage' ? (
          <Typography color="textSecondary">
            {t('discountRate')}: {discount.discount}%
          </Typography>
        ) : (
          <Typography color="textSecondary">
            {t('discountAmount')}: {discount.discount} {t('currency')}
          </Typography>
        )}
        <Typography color="textSecondary">
          {t('validPeriod')}: {formatDate(discount.startDate)} -{' '}
          {formatDate(discount.endDate)}
        </Typography>
        {discount.applicableProducts &&
        discount.applicableProducts.length > 0 ? (
          <React.Fragment>
            <Typography color="textSecondary" sx={{ mt: 1 }}>
              {t('applicableProducts')}:
            </Typography>
            <List dense>
              {discount.applicableProducts.map((product, index) => (
                <ListItem key={index}>
                  <ListItemText primary={product.productName} />
                </ListItem>
              ))}
            </List>
          </React.Fragment>
        ) : (
          <Typography color="textSecondary">
            {t('applicableProducts')}: {t('allProducts')}
          </Typography>
        )}
        {isSeller && (
          <IconButton
            onClick={() => onDelete(discount.id)}
            edge="end"
            aria-label="delete"
          >
            <DeleteIcon />
          </IconButton>
        )}
      </CardContent>
    </Card>
  );
};

export default DiscountCard;
